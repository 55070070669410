import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { Select } from 'antd';
import PersonalizeSelect from './PersonalizeSelect.styled';
import { toast } from '../../../../redux/action';
import { getCustomFields } from '../../../../redux/services';
import { selectedOrganizationSelector } from '../../../../redux/selectors/organization';

const extraPersonalizationOptions = [
  {
    value: '{{full_name}}',
    display: 'Contact Full Name',
  },
  {
    value: '{{preference_form_url}}',
    display: 'Preference Form URL',
  },
  {
    value: '{{organization_name}}',
    display: 'Organization Name',
  },
  {
    value: '{{organization_acronym}}',
    display: 'Organization Acronym',
  },
  {
    value: '{{organization_phone}}',
    display: 'Organization Phone',
  },
  {
    value: '{{organization_email}}',
    display: 'Organization Email',
  },
  {
    value: '{{organization_website}}',
    display: 'Organization Website',
  },
];

const PersonalizeSelectDropDown = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const [personalizeOptions, setPersonalizeOptions] = useState([]);

  useEffect(() => {
    // IIFE to allow async functions;
    (async () => {
      if (personalizeOptions.length < 3) {
        const fields = await getCustomFields(
          selectedOrganization.id,
          selectedOrganization.orgType
        );

        const personalizationOptions = fields
          .map(field => {
            return {
              value: `{{${field.personalizeDisplay}}}`,
              display: field.name,
            };
          })
          .concat(extraPersonalizationOptions)
          .sort((a, b) => a.display.localeCompare(b.display));
        setPersonalizeOptions(personalizationOptions);
      }
    })();
  }, []);

  const copyToClipboard = selection => {
    navigator.clipboard.writeText(selection);
    dispatch(toast('success', 'Copied to clipboard'));
  };

  return (
    <PersonalizeSelect
      value={null}
      optionFilterProp="children"
      placeholder={
        <>
          <AccountCircleOutlinedIcon /> Personalize
        </>
      }
      onSelect={copyToClipboard}
      showSearch
      dropdownMatchSelectWidth={false}
    >
      {personalizeOptions.map(option => {
        return (
          <Option key={option.value} value={option.value}>
            {option.display}
          </Option>
        );
      })}
    </PersonalizeSelect>
  );
};

export default PersonalizeSelectDropDown;
