import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  CircularProgress,
  Tooltip,
  createTheme,
  Switch,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AccessTimeOutlined, HighlightOffOutlined } from '@material-ui/icons';
import * as moment from 'moment/moment';
import {
  Container,
  Box,
  Wrapper,
  Title,
  Text,
  Highlight,
} from './MessageTable.styled';
import { Button } from './MessageDetails.styled';
import DatesFilter from './DatesFilter';
import { SEVEN_DAYS_VAL } from '../constants';

const defaultTheme = createTheme();
const useStyles = makeStyles(
  () => ({
    root: {
      '& .MuiDataGrid-columnHeaderWrapper > :first-child': {
        visibility: 'hidden',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: '600',
      },
      '& .MuiDataGrid-columnHeaderWrapper': {
        backgroundColor: '#EDEDED',
      },
      '& .MuiDataGrid-footerContainer': {
        justifyContent: 'center',
      },
      '& .MuiDataGrid-row': {
        cursor: 'pointer',
      },
    },
    subjectHeader: {
      paddingTop: '5px',
    },
  }),
  { defaultTheme }
);
const MessageTable = ({
  isLoadingData,
  scheduledMessagesHidden,
  setHideScheduledMessages,
  generateTableMessageData,
  selectMessage,
  selectedMessage,
  handleBackToTimeline,
  loadMessages,
  orgId,
}) => {
  const [selectedRange, setSelectedRange] = useState(SEVEN_DAYS_VAL);

  useEffect(() => {
    setSelectedRange(SEVEN_DAYS_VAL);
  }, [orgId]);

  const classes = useStyles();
  const columns = [
    {
      field: 'status',
      headerName: 'Status',
      width: 30,
      sortable: false,
      renderCell: ({ value }) => {
        if (value === 'Scheduled') {
          return (
            <Tooltip
              title="scheduled"
              placement="top"
              style={{ justifyContent: 'center' }}
            >
              <AccessTimeOutlined
                style={{ fontSize: '18px', color: '#F7C604' }}
              />
            </Tooltip>
          );
        } else if (value === 'Cancelled') {
          return (
            <Tooltip
              title="cancelled"
              placement="top"
              style={{ justifyContent: 'center' }}
            >
              <HighlightOffOutlined
                style={{ fontSize: '18px', color: '#E63946' }}
              />
            </Tooltip>
          );
        } else {
          return '';
        }
      },
    },
    {
      field: 'messageSubject',
      headerName: 'Messages',
      flex: 4,
      minWidth: 200,
      sortable: false,
      renderCell: ({ value: { draftName, messageSubject, AB } }) => (
        <Box>
          <Title>
            {draftName} {AB && AB.version && <Highlight>(A/B)</Highlight>}
          </Title>
          <span className={classes.subjectHeader}>{messageSubject}</span>
        </Box>
      ),
    },
    {
      field: 'sendTime',
      headerName: 'Date',
      flex: 2,
      minWidth: 100,
      sortable: false,
      renderCell: ({ value: { date, time } }) => (
        <Box>
          <span>{date}</span>
          <span>{time}</span>
        </Box>
      ),
    },
  ];

  const rows = generateTableMessageData?.map(msg => ({
    id: msg._id,
    status: msg.status,
    messageSubject: {
      draftName: msg.draftName,
      messageSubject: msg.messageSubject,
      AB: msg.AB,
    },
    sendTime: {
      date: moment.unix(msg.sendTime).format('MM/DD/YY'),
      time: moment.unix(msg.sendTime).format('hh:mm a'),
    },
  }));

  const selectRange = range => {
    setSelectedRange(range);
    loadMessages(range);
  };

  return (
    <Container>
      <>
        <Wrapper>
          {selectedMessage ? (
            <Button onClick={() => handleBackToTimeline(selectedRange)}>
              Back to timeline
            </Button>
          ) : (
            <DatesFilter
              selectRange={selectRange}
              selectedRange={selectedRange}
            />
          )}
          <span>
            <Text removeAtMaxWidth="1700px">Hide Scheduled Messages:</Text>
            <Switch
              checked={scheduledMessagesHidden}
              onChange={setHideScheduledMessages}
              color="primary"
              name="scheduledMessage"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </span>
        </Wrapper>
        {isLoadingData ? (
          <CircularProgress style={{ margin: 'auto', color: '#007ADE' }} />
        ) : (
          <DataGrid
            hideFooterSelectedRowCount
            className={classes.root}
            rows={rows}
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[25]}
            disableColumnMenu={true}
            onSelectionModelChange={selectMessage}
          />
        )}
      </>
    </Container>
  );
};

export default MessageTable;
