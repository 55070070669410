import React from 'react';
import {
  PeopleAlt,
  EnhancedEncryption,
  HighlightOff,
} from '@material-ui/icons';
import Stats from './Components/stats';
import FilterStats from './Components/filterStats';
import Content from '../Content/Components/_shared/content';
import {
  Underline,
  Wrapper,
  Bold,
  AnalyticsContainer,
} from './Components/Footer.styled';

const TargetedAudienceForAnalytics = ({ message, topics, groups }) => {
  const {
    totalUniqueTargeted,
    topicIds,
    requiredGroupIds,
    selectedGroupIds,
    filterData,
    includedMemberIds,
    excludedGroupIds,
    excludedMemberIds,
    membersList,
  } = message;
  return (
    <AnalyticsContainer>
      <Content style={{ fontSize: 15, paddingBottom: 12 }}>
        <PeopleAlt style={{ margin: '0 10px' }} />
        <b>Targeted Audience:</b>
        <Underline>{totalUniqueTargeted}</Underline>
      </Content>
      <Wrapper>
        <Stats
          Icon={EnhancedEncryption}
          color="#007ADE"
          text={
            <>
              Anyone who is subscribed to your <Bold>Topic(s)</Bold>
            </>
          }
          orList={topicIds.map(topicId => topics[topicId])}
          backColor="#F2F8FD"
        />
        {requiredGroupIds?.length || selectedGroupIds.length ? (
          <Stats
            Icon={EnhancedEncryption}
            color="#007ADE"
            text={
              <>
                and who belongs to the <Bold>Group</Bold>
              </>
            }
            andList={requiredGroupIds.map(groupId => groups[groupId])}
            orList={selectedGroupIds.map(groupId => groups[groupId])}
            backColor="#F2F8FD"
          />
        ) : (
          ''
        )}
        {Object.keys(filterData || {}).length ? (
          <FilterStats
            Icon={EnhancedEncryption}
            color="#007ADE"
            text={
              <>
                and who meets <Bold paddingRight={'5px'}>Filter</Bold> criteria
              </>
            }
            filters={filterData}
            backColor="#F7FBF4"
          />
        ) : (
          ''
        )}
        {includedMemberIds?.length ? (
          <Stats
            Icon={EnhancedEncryption}
            color="#007ADE"
            text={
              <>
                and specifically include <Bold>Contacts</Bold>
              </>
            }
            orList={includedMemberIds.map(memberId => membersList[memberId])}
            backColor="#F2F8FD"
          />
        ) : (
          ''
        )}
        {excludedGroupIds?.length ? (
          <Stats
            Icon={HighlightOff}
            color="#E63946"
            backColor="#FFF7F7"
            text={
              <>
                but exclude anyone who belongs the <Bold>Group</Bold>
              </>
            }
            orList={excludedGroupIds.map(groupId => groups[groupId])}
          />
        ) : (
          ''
        )}
        {excludedMemberIds?.length ? (
          <Stats
            Icon={HighlightOff}
            color="#E63946"
            backColor="#FFF7F7"
            text={
              <>
                and specifically exclude <Bold>Contacts</Bold>
              </>
            }
            andList={excludedMemberIds.map(memberId => membersList[memberId])}
          />
        ) : (
          ''
        )}
      </Wrapper>
    </AnalyticsContainer>
  );
};

export default TargetedAudienceForAnalytics;
