import React from 'react';
import { CONTACT_UPLOAD, GROUPING_TOOL } from './constants';
import ContactUpload from './Components/ContactUpload';
import GroupingTool from './Components/GroupingTool';
import { progressTypeSelector } from '../../../../../../redux/selectors/ui';
import { bindActionCreators } from 'redux';
import * as action from '../../../../../../redux/action';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const Summary = ({ results, progressType }) => {
  const renderSummary = () => {
    switch (progressType) {
      case GROUPING_TOOL:
        return <GroupingTool results={results} />;
      case CONTACT_UPLOAD:
        return <ContactUpload results={results} />;
    }
  };
  return <span>{renderSummary()}</span>;
};

const mapStateToProps = state => ({
  progressType: progressTypeSelector(state),
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(action, dispatch),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Summary)
);
