import React from 'react';
import {
  AddCircleOutline,
  HighlightOff,
  EnhancedEncryption,
} from '@material-ui/icons';
import ActionIcon from './actionIcon';
import { ADD, EXCLUDE, REQUIRE } from '../../../../constants';

const ActionBtns = (
  handleSelect,
  selectedItems,
  includeRequiredAction = true
) => {
  return {
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    minWidth: 140,
    flex: 0.3,
    disableClickEventBubbling: true,
    renderCell: params => {
      return (
        <>
          <ActionIcon
            Icon={AddCircleOutline}
            title={ADD}
            colorOnSelect={'#04A777'}
            id={params.id}
            onSelected={action => handleSelect(params.row, action)}
            selected={selectedItems[params.id]?.action === ADD}
          />
          <ActionIcon
            Icon={HighlightOff}
            title={EXCLUDE}
            colorOnSelect={'#E63946'}
            id={params.id}
            onSelected={action => handleSelect(params.row, action)}
            selected={selectedItems[params.id]?.action === EXCLUDE}
          />
          {includeRequiredAction && (
            <ActionIcon
              Icon={EnhancedEncryption}
              title={REQUIRE}
              colorOnSelect={'#007ADE'}
              id={params.id}
              onSelected={action => handleSelect(params.row, action)}
              selected={selectedItems[params.id]?.action === REQUIRE}
            />
          )}
        </>
      );
    },
  };
};

export default ActionBtns;
