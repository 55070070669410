import React, { Component } from 'react';
import './View.css';
import '../../globalcss.css';
import { Box, Text } from 'grommet';
import {
  List,
  Button,
  Skeleton,
  Modal,
  Input,
  Table,
  Spin,
  Row,
  Col,
} from 'antd';
import {
  Contact,
  Send,
  History,
  StatusUnknown,
  Actions,
  Java,
} from 'grommet-icons';
import moon from '../../Images/moon.png';
import * as action from '../../redux/action';
import * as services from '../../redux/services';
import { DeleteOutlined, BellFilled } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authUserHelper } from '../../redux/helpers/index';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import SnBTable from './SnBTable';
import NRModal from '../Modal';

const msgStatusTableCols = [
  {
    title: 'Draft Name',
    dataIndex: 'draftName',
    key: 'draftName',
    // eslint-disable-next-line
    render: text => <div>{text}</div>,
  },
  {
    title: 'Send time',
    dataIndex: 'sendTime',
    key: 'sendTime',
    // eslint-disable-next-line
    render: text => (
      <div style={{ padding: 0 }}>
        {typeof text === 'number'
          ? moment.unix(text).format('MM-DD-YY HH:mm')
          : text}
      </div>
    ),
  },
  {
    title: '# Targeted Emails',
    dataIndex: ['totalEmailTargeted'],
    key: 'totalEmailTargeted',
    // eslint-disable-next-line
    render: text => <div>{text ? text : '-'}</div>,
  },
  {
    title: '# Targeted Texts',
    dataIndex: ['totalTextTargeted'],
    key: 'totalTextTargeted',
    // eslint-disable-next-line
    render: text => <div>{text ? text : '-'}</div>,
  },
  {
    title: 'Status',
    dataIndex: ['status'],
    key: 'status',
    // eslint-disable-next-line
    render: status => {
      if (status) {
        return <div>{status === 'standby' ? 'In Progress' : status}</div>;
      } else {
        return <div>Archived</div>;
      }
    },
  },
];

class View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlertModal: false,
      showCancelMessageModal: false,
      showEditMessageModal: false,
      showDelAlertModal: false,
      uniqueClick: 0,
      emailDelivered: 0,
      uniqueOpen: 0,
      textDelivered: 0,
      messageQueueLength: 0,
      textQueueLength: 0,
      messageStatuses: [],
      selectedMessage: {},
      msgStatusLoading: false,
      alertsLoading: true,
      alerts: [],
      alertText: '',
      alertHeader: '',
      stat: {
        emailDelivered: false,
        uniqueOpen: false,
        textDelivered: false,
        messageQueueLength: false,
        textQueueLength: false,
      },
      tableSize:
        JSON.parse(localStorage.getItem('pendingMessages'))?.tableSize || 10,
      tableSizeOptions: ['10', '15', '25', '50', '100'],
    };
  }

  componentDidMount() {
    authUserHelper(this.props);
    this.getAlerts();
    if (
      this.props.user.detail.organizationId ||
      (this.props.organization.selected && this.props.organization.selected.id)
    ) {
      this.getMessageStatuses(true);
      this.getStats('click', 'unique', number => {
        this.setState({
          uniqueClick: number,
          stat: { ...this.state.stat, uniqueClick: true },
        });
      });
      this.getStats('open', 'unique', number => {
        this.setState({
          uniqueOpen: number,
          stat: { ...this.state.stat, uniqueOpen: true },
        });
      });
      this.getStats('delivered', 'email', number => {
        this.setState({
          emailDelivered: number,
          stat: { ...this.state.stat, emailDelivered: true },
        });
      });
      this.getStats('delivered', 'text', number => {
        this.setState({
          textDelivered: number,
          stat: { ...this.state.stat, textDelivered: true },
        });
      });
      this.getQueueLength();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.organization.selected.id !==
        this.props.organization.selected.id &&
      this.props.organization.selected.id
    ) {
      this.getAlerts();
      this.getMessageStatuses(true);
      this.getStats('click', 'unique', number => {
        this.setState({
          uniqueClick: number,
          stat: { ...this.state.stat, uniqueClick: true },
        });
      });
      this.getStats('open', 'unique', number => {
        this.setState({
          uniqueOpen: number,
          stat: { ...this.state.stat, uniqueOpen: true },
        });
      });
      this.getStats('delivered', 'email', number => {
        this.setState({
          emailDelivered: number,
          stat: { ...this.state.stat, emailDelivered: true },
        });
      });
      this.getStats('delivered', 'text', number => {
        this.setState({
          textDelivered: number,
          stat: { ...this.state.stat, textDelivered: true },
        });
      });
      this.getQueueLength();
    }
  }

  getQueueLength = async () => {
    let orgId = this.props.organization.selected.id;
    if (!orgId) return;
    const lengths = await services.getQueueLength(orgId);
    this.setState({
      messageQueueLength: lengths.emailCount,
      stat: { ...this.state.stat, messageQueueLength: true },
    });
    this.setState({
      textQueueLength: lengths.textCount,
      stat: { ...this.state.stat, textQueueLength: true },
    });
  };

  getStats = async (status, text, cb) => {
    let number = await this.getNumber(status, text);
    cb(number);
  };

  getMessageStatuses = async setLoader => {
    this.setState({ msgStatusLoading: setLoader });
    const msgStatuses = await services.getOrgMsgStatus(
      this.props.organization.selected.id
        ? this.props.organization.selected.id
        : this.props.user.detail.organizationId
    );
    if (!msgStatuses) return this.setState({ msgStatusLoading: !setLoader });
    let messages = msgStatuses.map(msg => {
      msg.key = msg.id;
      return msg;
    });

    this.setState({ messageStatuses: messages, msgStatusLoading: false });
  };

  getNumber = async (status, text) => {
    let orgId = this.props.organization.selected.id
      ? this.props.organization.selected.id
      : this.props.user.detail.organizationId;
    const date = moment().format('YYYY-MM-DD');
    if (text && text === 'text')
      return await services.getTextEventCount(orgId, date);
    else if (text && text === 'unique')
      return await services.getUniqueEventCount(orgId, status, date);
    else if (text && text === 'email')
      return await services.getEmailEventCount(orgId, date);
    else return 0;
  };

  getAlerts = async () => {
    const alerts = await services.getAlerts();
    if (alerts) {
      if (this.props?.organization?.selected?.acronym === 'MetroTex') {
        alerts.unshift({
          id: 'MetroTextProjectAlert',
          time: 1636358400,
          text: 'Hi there! To check out progress on the NoteRouter + MetroTex expansion project at any time, visit https://trello.com/b/l5JWE9x5/metrotex-expansion',
          header: 'NoteRouter Expansion Project',
        });
      }
      this.setState({ alerts: alerts, alertsLoading: false });
    }
  };

  updateInput = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  changeShowAlert = e => {
    this.setState({
      showAlertModal: e,
    });
  };

  saveAlert = async () => {
    if (this.state.alertText === '') return;
    const newAlert = {
      header: this.state.alertHeader,
      text: this.state.alertText,
      time: moment().unix(),
      id: uuidv4(),
    };
    const alertRes = await services.addAlert(newAlert);
    if (!alertRes.error) {
      this.changeShowAlert(false);
      this.getAlerts();
    }
  };

  showAlertDeleteModal = id => {
    this.setState({
      showDelAlertModal: true,
      alertDeleteId: id,
    });
  };

  deleteAlert = async () => {
    const alertRes = await services.deleteAlert(this.state.alertDeleteId);
    if (alertRes) {
      this.getAlerts();
      this.hideDelAlertModal();
    }
  };

  hideDelAlertModal = () => {
    this.setState({ showDelAlertModal: false });
  };

  consoleLog = event => {
    console.log(event);
  };

  backToEdit = async () => {
    const { id } = this.state.selectedMessage;

    const message = await services.getMsgById(id);
    if (!message) {
      this.toggleEditMessageModal();
      return this.props.actions.toast('error', 'Could not find this message.');
    }

    await services.updateDraft({
      _id: message.draftId,
      status: 'Active',
      editMsgId: id,
    });

    await services.updateMsg({
      msgId: id,
      cancelled: true,
    });

    this.toggleEditMessageModal();
    this.props.history.push(`/dashboard/sendmessage?draft=${message.draftId}`);
  };

  cancelExistingMessage = async () => {
    if (this.state.selectedMessage.status === 'Sent') {
      this.toggleCancelMessageModal();
      return this.props.actions.toast(
        'error',
        'Cannot cancel a message that is already sent'
      );
    }
    let messageId = this.state.selectedMessage.id;
    if (!messageId) {
      this.toggleCancelMessageModal();
      return this.props.actions.toast('error', 'No valid message selected');
    }
    this.props.actions.toast(
      'success',
      'Cancelling your message, this may take a minute.',
      5
    );
    let nonCancelled = this.state.messageStatuses.filter(
      message => message.id !== messageId
    );
    if (nonCancelled.length === 0) {
      nonCancelled.push({
        sendTime: '-',
        draftName: '-',
        key: 'dudMsg',
      });
    }
    this.setState({ messageStatuses: nonCancelled });
    await services.updateMsg({ msgId: messageId, cancelled: true });
    await services.cancelEmails(messageId);
    this.toggleCancelMessageModal();
    this.props.actions.toast('success', 'Emails successfully cancelled.', 4);
    await services.cancelTexts(messageId);
    this.props.actions.toast('success', 'Texts successfully cancelled.', 4);
    this.getQueueLength();
  };

  toggleCancelMessageModal = () => {
    this.setState(previousState => ({
      showCancelMessageModal: !previousState.showCancelMessageModal,
    }));
  };

  canEditMessage = () => {
    const { status, id } = this.state.selectedMessage;
    if (status === 'In Progress' || status === 'Sent') {
      this.props.actions.toast(
        'error',
        'Cannot edit a message that is sending or already sent.'
      );
      return false;
    }
    if (!id) {
      this.props.actions.toast('error', 'No valid message selected');
      return false;
    }
    return true;
  };

  toggleEditMessageModal = () => {
    this.setState(previousState => ({
      showEditMessageModal: !previousState.showEditMessageModal,
    }));
  };

  saveTableSize = size => {
    localStorage.setItem(
      'pendingMessages',
      JSON.stringify({ tableSize: size })
    );
  };

  render() {
    const {
      uniqueClick,
      emailDelivered,
      uniqueOpen,
      textDelivered,
      textQueueLength,
      messageQueueLength,
      stat,
      showCancelMessageModal,
      showEditMessageModal,
    } = this.state;
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedMessage: selectedRows[0] });
      },
    };

    const uniqueOpenDisplay =
      emailDelivered > 0
        ? ((uniqueOpen / emailDelivered) * 100).toFixed(1) + '%'
        : '0%';
    const uniqueClickDisplay =
      emailDelivered > 0
        ? ((uniqueClick / emailDelivered) * 100).toFixed(1) + '%'
        : '0%';

    const headerGreeting = (() => {
      const now = moment(); // get the current time using moment
      const hour = now.hour();

      const dayText = hour < 12 ? 'morning' : 'afternoon';
      const dayIcon = hour < 12 ? <Java /> : <Actions />;

      const eveningIcon = (
        <img src={moon} alt="moon" style={{ maxWidth: '35px' }} />
      );
      const eveningText = 'evening';

      return (
        <span>
          {hour < 18 ? dayIcon : eveningIcon}
          <span style={{ paddingLeft: '10px' }}>
            Good {hour < 18 ? dayText : eveningText}
          </span>
        </span>
      );
    })();

    return (
      <div>
        {this.props.user.detail.firstName && headerGreeting && (
          <div
            style={{
              paddingLeft: '50px',
              paddingTop: '30px',
            }}
          >
            <h1 style={{ fontWeight: 'bold', fontSize: '25px' }}>
              {headerGreeting}, {this.props.user.detail.firstName}!
            </h1>
          </div>
        )}
        <div className="viewsec">
          <div className="gutter-example">
            <Row gutter={24}>
              <Col span={24}>
                <div className="gutter-example" style={{ paddingTop: '10px' }}>
                  <Row gutter={16}>
                    <Col className="gutter-row" xs={12} sm={6} md={6} lg={6}>
                      <div className="cardsecrow">
                        <div className="gutter-box">
                          <Box
                            pad="large"
                            align="center"
                            gap="small"
                            className="firstbox"
                            round="medium"
                          >
                            <Box className="iconsection">
                              <Send size="large" />
                            </Box>
                            <Box className="textsec">
                              <Text className="sectxt">
                                {stat.emailDelivered && stat.textDelivered
                                  ? emailDelivered + textDelivered
                                  : 'Loading...'}{' '}
                              </Text>
                              <Text className="firsttxt">
                                Messages Delivered
                              </Text>
                            </Box>
                          </Box>
                        </div>
                      </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={6} md={6} lg={6}>
                      <div className="cardsecrow">
                        <div className="gutter-box">
                          <Box
                            pad="large"
                            align="center"
                            round
                            gap="small"
                            className="firstbox"
                          >
                            <Box className="iconsection">
                              <Contact size="large" />
                            </Box>
                            <Box className="textsec">
                              <Text className="sectxt">
                                {stat.emailDelivered
                                  ? uniqueOpenDisplay
                                  : 'Loading...'}
                              </Text>
                              <Text className="firsttxt">Unique Opens</Text>
                            </Box>
                          </Box>
                        </div>
                      </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={6} md={6} lg={6}>
                      <div className="cardsecrow">
                        <div className="gutter-box">
                          <Box
                            pad="large"
                            align="center"
                            round
                            gap="small"
                            className="firstbox"
                          >
                            <Box className="iconsection">
                              <StatusUnknown size="large" />
                            </Box>
                            <Box className="textsec">
                              <Text className="sectxt">
                                {stat.emailDelivered
                                  ? uniqueClickDisplay
                                  : 'Loading...'}
                              </Text>
                              <Text className="firsttxt">Unique Clicks</Text>
                            </Box>
                          </Box>
                        </div>
                      </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={6} md={6} lg={6}>
                      <div className="cardsecrow">
                        <div className="gutter-box">
                          <Box
                            pad="large"
                            align="center"
                            round
                            gap="small"
                            className="firstbox"
                          >
                            <Box className="iconsection">
                              <History size="large" />
                            </Box>
                            <Box className="textsec">
                              <Text className="sectxt">
                                {stat.textQueueLength && stat.messageQueueLength
                                  ? textQueueLength + messageQueueLength
                                  : 'Loading...'}
                              </Text>
                              <Text className="firsttxt">Queued Messages</Text>
                            </Box>
                          </Box>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={12}>
                <div
                  style={{
                    padding: '10px',
                    backgroundColor: 'white',
                    borderRadius: '15px',
                    height: '100%',
                  }}
                >
                  <div>
                    <p className="mainheadtext">
                      NoteRouter Alerts
                      {this.props.user.userPrivileges.isSDA && (
                        <span style={{ float: 'right' }}>
                          <a
                            className="addAlertBtn"
                            onClick={() => this.changeShowAlert(true)}
                          >
                            Add Alert
                          </a>
                        </span>
                      )}
                      <div style={{ fontSize: '12px', color: 'gray' }}>
                        Systemwide alerts provided to everybody
                      </div>
                    </p>
                  </div>
                  <div className="noterouterAlert">
                    <Spin
                      spinning={this.state.alertsLoading}
                      tip="Loading System Alerts"
                    >
                      <List
                        style={{ padding: '10px' }}
                        itemLayout="horizontal"
                        dataSource={this.state.alerts}
                        renderItem={item => (
                          <List.Item
                            style={{
                              backgroundColor: '#C6E4EE',
                              padding: '10px',
                              marginBottom: '3px',
                              borderRadius: '20px',
                              border: '.5px',
                            }}
                            actions={
                              this.props.user.userPrivileges.isSDA
                                ? [
                                    <p key={item.id}>
                                      {moment
                                        .unix(item.time)
                                        .format('MM-DD-YYYY hh:mm A')}
                                    </p>,
                                    <button
                                      key={item.id}
                                      onClick={() => {
                                        this.setState({
                                          alertDeleteId: item.id,
                                          showDelAlertModal: true,
                                        });
                                      }}
                                      style={{
                                        backgroundColor: '#C6E4EE',
                                        border: '1px gray',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <DeleteOutlined type="delete" />
                                    </button>,
                                  ]
                                : [
                                    <p key={item.id}>
                                      {moment
                                        .unix(item.time)
                                        .format('MM-DD-YYYY hh:mm A')}
                                    </p>,
                                  ]
                            }
                          >
                            <Skeleton
                              avatar
                              title={false}
                              loading={item.loading}
                              active
                            >
                              <List.Item.Meta
                                avatar={<BellFilled />}
                                title={item.header || 'Notification'}
                                description={item.text}
                              />
                            </Skeleton>
                          </List.Item>
                        )}
                      />
                    </Spin>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div
                  style={{
                    padding: '10px',
                    backgroundColor: 'white',
                    borderRadius: '15px',
                    height: '100%',
                  }}
                >
                  <p className="mainheadtext">
                    Recent Delivery Issues
                    <div style={{ fontSize: '12px', color: 'gray' }}>
                      These contacts may have had trouble receiving messages
                    </div>
                  </p>
                  <SnBTable orgId={this.props.organization.selected.id} />
                </div>
              </Col>
            </Row>
            <div
              style={{
                marginTop: '20px',
                padding: '10px',
                backgroundColor: 'white',
                borderRadius: '15px',
              }}
            >
              {this.props.user.userPrivileges.messageApproval && (
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <p className="scheduledHeadText"> Pending Messages </p>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="scheduledMessageStyle">
                      <h5>Actions: </h5>
                      <Button
                        label="Save"
                        size={'small'}
                        type="submit"
                        className="deleteScheduledMessageButton"
                        onClick={this.toggleCancelMessageModal}
                      >
                        {' '}
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          if (this.canEditMessage()) {
                            this.toggleEditMessageModal();
                          }
                        }}
                        size={'small'}
                      >
                        Edit Message
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Spin
                    spinning={this.state.msgStatusLoading}
                    tip="Loading Recent and Pending Messages"
                  >
                    <Table
                      dataSource={this.state.messageStatuses}
                      columns={msgStatusTableCols}
                      showTotal={total => `${total} - Messages`}
                      title={undefined}
                      pagination={{
                        size: 'small',
                        pageSizeOptions: this.state.tableSizeOptions,
                        pageSize: Number(this.state.tableSize),
                        showSizeChanger: true,
                        onShowSizeChange: (_, size) => {
                          this.saveTableSize(size);
                          this.setState({ tableSize: size });
                        },
                      }}
                      rowSelection={{
                        type: 'radio',
                        ...rowSelection,
                      }}
                      onRow={record => {
                        return {
                          onClick: () => {
                            this.consoleLog(record);
                          },
                        };
                      }}
                      className={'bob'}
                    />
                  </Spin>
                </Col>
              </Row>
            </div>
          </div>
          <Modal
            title="Create a new alert"
            className="templatemodal"
            centered
            visible={this.state.showAlertModal}
            onOk={e => this.saveAlert(e)}
            onCancel={() => this.changeShowAlert(false)}
            footer={[
              <Button
                className="draftbtn"
                key="back"
                onClick={e => {
                  this.saveAlert(e);
                }}
              >
                OK
              </Button>,
              <Button
                key="submit"
                className="continuebtn"
                type="primary"
                onClick={() => {
                  this.changeShowAlert(false);
                }}
              >
                CANCEL
              </Button>,
            ]}
          >
            <Input
              type="text"
              name="alertHeader"
              placeholder="Alert Header"
              onChange={this.updateInput}
            />
            <hr />
            <Input.TextArea
              autoSize={{ minRows: 4, maxRows: 15 }}
              type="text"
              name="alertText"
              placeholder="Write here..."
              onChange={this.updateInput}
            />
          </Modal>

          {/* delete modal html start */}
          <Modal
            title="Confirm Delete"
            centered
            className="delmodal"
            wrapClassName="vertical-center-modal"
            visible={this.state.showDelAlertModal}
            onOk={e => {
              this.deleteAlert(e);
            }}
            onCancel={this.hideDelAlertModal}
            footer={[
              <Button
                className="nobtn"
                key="back"
                onClick={this.hideDelAlertModal}
              >
                NO
              </Button>,
              <Button
                key="submit"
                className="continuebtn"
                type="primary"
                onClick={e => {
                  this.deleteAlert(e);
                }}
              >
                YES
              </Button>,
            ]}
          >
            <Row type="flex" justify="space-around">
              <Col lg={24}>
                <p className="modaltxt" style={{ padding: '20px 0' }}>
                  <b>Are you, sure you want to delete this Alert?</b>
                </p>
              </Col>
            </Row>
          </Modal>

          {showCancelMessageModal && (
            <NRModal
              title={'Confirm Cancel'}
              onClose={this.toggleCancelMessageModal}
              primaryButton={{
                label: 'YES',
                onClick: this.cancelExistingMessage,
              }}
              secondaryButton={{
                label: 'NO',
              }}
            >
              <div>
                <p>
                  Are you sure you would like to cancel this message? Once you
                  confirm, you will not be able to undo this.
                </p>
              </div>
            </NRModal>
          )}
          {showEditMessageModal && (
            <NRModal
              title={'Confirm Edit'}
              onClose={this.toggleEditMessageModal}
              primaryButton={{
                label: 'YES',
                onClick: this.backToEdit,
              }}
              secondaryButton={{
                label: 'NO',
              }}
            >
              <div>
                <p>
                  Are you sure you would like to edit this message? Editing this
                  message will cancel it. Once you confirm, you will not be able
                  to undo this.
                </p>
              </div>
            </NRModal>
          )}
          {/* delete modal html end */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  //console.log("Event list screen info", state);
  return state;
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(action, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(View);
