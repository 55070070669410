import styled from 'styled-components';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';

export const DataGrid = styled(MuiDataGrid)`
  .MuiDataGrid-row {
    cursor: pointer;
  }

  .descriptionCell {
    // Turning off MUI data-grid cells custom text overflow rules, because they
    // conflict with custom rules needed for description column.
    white-space: initial !important;
  }
`;

const Cell = styled.span`
  font-family: 'Montserrat', sans-serif;
`;

DataGrid.Name = styled(Cell)`
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
`;

DataGrid.Description = styled(Cell)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.3;
  color: #616161;
  font-size: 18px;
`;

DataGrid.Rate = styled.div`
  padding: 4px 8px;
`;

DataGrid.Status = styled(Cell)`
  color: #616161;
  font-size: 18px;
`;

DataGrid.Date = styled(Cell)`
  color: #616161;
  font-size: 18px;
`;
