import React, { useState, useMemo, useRef } from 'react';
import { CustomSelect } from './ContactsSelector.styled';
import debounce from 'lodash/debounce';
import { fetchContactsFullNameAndIds } from '../api';

const ContactSelector = ({
  matchToContact,
  placeholder,
  fetchedContactsRef,
}) => {
  const { Option } = CustomSelect;
  const [contactData, setContactData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const pageRef = useRef(0);
  const debounceTimeout = 800;
  const isMounted = useRef(true);

  const fetchContactData = async searchText => {
    setLoading(true);

    const currentValues = searchText ? [] : contactData;
    const searchVal = searchText || searchValue;
    const index = `${pageRef.current}-${searchVal}`;
    if (searchText) {
      setContactData([]);
      pageRef.current = 0;
    }

    try {
      let normalizedContactData;

      if (fetchedContactsRef.current[index]) {
        normalizedContactData = fetchedContactsRef.current[index];
      } else {
        const fetchedContactData = await fetchContactsFullNameAndIds({
          page: pageRef.current,
          searchValue: searchVal,
        });

        normalizedContactData = fetchedContactData
          .filter(contact => contact.firstName && contact.noteRouterId)
          .map(transformContactData);
        fetchedContactsRef.current[index] = normalizedContactData;
      }

      if (isMounted.current) {
        setContactData([...currentValues, ...normalizedContactData]);
        setLoading(false);
        pageRef.current += 1;
      }
    } catch (err) {
      console.error(
        'Error fetching and formatting contact data for dropdown',
        err
      );
      if (isMounted.current) {
        setContactData([...contactData]);
        setLoading(false);
        pageRef.current += 1;
      }
    }
  };

  const transformContactData = contact => {
    return {
      key: contact._id,
      value: contact.noteRouterId,
      display: `${contact.firstName} ${contact.lastName}`,
    };
  };

  const handleSearch = useMemo(() => {
    const fetchData = async value => {
      setSearchValue(value);
      await fetchContactData(value);
    };

    return debounce(fetchData, debounceTimeout);
  }, [fetchContactData, debounceTimeout]);

  const handleScroll = async ({ target }) => {
    if (
      !loading &&
      // doing this trick with '+ 2' to trigger scrolling when mouse is close to the end of data in dropdown select
      target.scrollTop + target.offsetHeight + 2 > target.scrollHeight
    ) {
      target.scrollTo(0, target.scrollHeight);
      fetchContactData();
    }
  };

  return (
    <CustomSelect
      placeholder={placeholder}
      filterOption={false}
      showSearch
      onSearch={handleSearch}
      onPopupScroll={handleScroll}
      loading={loading}
      onChange={noteRouterId => {
        matchToContact(noteRouterId);
      }}
      dropdownMatchSelectWidth={false}
      allowClear
      onClick={fetchContactData}
    >
      {contactData.map(({ key, value, display }) => {
        return (
          <Option key={key} value={value}>
            {display}
          </Option>
        );
      })}
    </CustomSelect>
  );
};

export default ContactSelector;
