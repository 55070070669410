import React, { Component } from 'react';
import { Row, Col, Button } from 'antd';
import {
  LaptopOutlined,
  TabletOutlined,
  MobileOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { CircularProgress } from '@material-ui/core';
import './PreviewDesign.css';
import * as Handlebars from 'handlebars/dist/cjs/handlebars';

class PreviewDesign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      device: 'laptop',
      iframeFormatting:
        '<style type="text/css">body { padding: 0 12px 0 0 !important; background-color: white !important; } body::-webkit-scrollbar { width: 8px; } body::-webkit-scrollbar-track { background: rgba(255, 255, 255, 0.1); border-radius: 5px } body::-webkit-scrollbar-thumb { background-color: #DFDFDF; border-radius: 5px; }</style></head>',
      iframeRef: React.createRef(),
      laptop: 'activetog',
      tablet: '',
      mobile: '',
    };
  }

  backToEdit = () => this.props.close();

  toggleMobile = () => {
    this.setState({
      device: 'mobile',
      laptop: '',
      mobile: 'activetog',
      tablet: '',
    });
  };

  toggleTablet = () => {
    this.setState({
      device: 'tablet',
      laptop: '',
      mobile: '',
      tablet: 'activetog',
    });
  };

  toggleLaptop = () => {
    this.setState({
      device: 'laptop',
      laptop: 'activetog',
      mobile: '',
      tablet: '',
    });
  };

  render() {
    let preview = '';
    Handlebars.registerHelper('if', function (context, options) {
      return `<div style="border: 3px solid #FC9E3A;">${options.fn(
        context
      )}</div>`;
    });

    Handlebars.registerHelper('subscribedToGroups', function () {
      return;
    });

    if (this.props.html?.length > 0 && this.props.html !== '""') {
      preview = Handlebars.compile(
        (' ' + this.props.html)
          .slice(1)
          .replace(/<\/head>/g, this.state.iframeFormatting)
      )();
    }

    return (
      <div className="SendMessagecomp">
        <Row gutter={16} className="previewTopBar">
          <div className="desk_tab_mobicon preview-design-bar">
            <div className="preview-design-header">
              <Button
                icon={<LeftOutlined />}
                onClick={this.props.close}
                className="backtoedit"
              >
                Back to Edit
              </Button>
              <div className="message-preview-container">
                <h5>Design Preview</h5>

                {this.props.name && <p>({this.props.name})</p>}
              </div>
            </div>

            <div className="infoframe">
              <div className="messageInfoText device-switcher">
                <Button
                  onClick={() => this.toggleLaptop()}
                  className={'resolutionbtn ' + this.state.laptop}
                >
                  <LaptopOutlined />
                </Button>
                <Button
                  onClick={() => this.toggleTablet()}
                  className={'resolutionbtn ' + this.state.tablet}
                >
                  <TabletOutlined />
                </Button>
                <Button
                  onClick={() => this.toggleMobile()}
                  className={'resolutionbtn ' + this.state.mobile}
                >
                  <MobileOutlined />
                </Button>
              </div>
            </div>
          </div>
        </Row>
        <Row gutter={16} style={{ padding: '0px' }}>
          <Col span={18} className="gutter-row" id="preview-design-col">
            <div className="previewiframe preview-design">
              <div className={`${this.state.device} iframe-container`}>
                {!preview ? (
                  <CircularProgress size={40} style={{ margin: '33%' }} />
                ) : (
                  <iframe
                    ref={this.state.iframeRef}
                    title="Design Preview"
                    id={this.state.device}
                    className="preview-message-iframe"
                    srcDoc={preview}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PreviewDesign;
