import React from 'react';
import Modal from '../../../../../Component/Modal';
import { userDelete } from '../../../../../redux/services';
import { useToast, useUpdateUIState } from '../../../../../redux/action';

const DeleteUserModal = ({
  setDeleteUserModalVisibility,
  userIdsToDelete,
  setSelectedUser,
  selectedOrg,
  updateUserList,
}) => {
  const { errorToast, successToast } = useToast();
  const { fullLoader } = useUpdateUIState();

  const handleConfirm = async () => {
    handleClose();
    const didDelete = await userDelete(userIdsToDelete, selectedOrg.id);
    if (didDelete) {
      setSelectedUser([]);
      successToast('User successfully deleted.');
      fullLoader(true, 'Loading Account Users');

      await updateUserList();
    } else {
      errorToast('Error deleting user.');
    }
    fullLoader(false);
  };

  const handleClose = () => {
    setDeleteUserModalVisibility(false);
  };

  return (
    <Modal
      title="Confirm Delete"
      onClose={handleClose}
      primaryButton={{
        onClick: handleConfirm,
      }}
      secondaryButton={{
        onClick: handleClose,
      }}
    >
      Are you, sure you want to delete
      {userIdsToDelete.length > 1 ? ' these Users' : ' this User'}?
    </Modal>
  );
};

export default DeleteUserModal;
