import styled from 'styled-components';
import { Select } from 'antd';
import { List, ListItem } from '@material-ui/core';

export const StyledList = styled(List)`
  padding: 0 !important;
  max-height: 190px;
  overflow: scroll;
  overflow-x: hidden;
  width: 400px;
  color: #6c7075;
`;

export const StyledListItem = styled(ListItem)`
  &:hover {
    cursor: pointer;
  }
`;

export const StyledSelect = styled(Select)`
  width: 400px;
  margin-bottom: 4px;
`;

export const SelectDropDownFooter = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e7e7e7;
  padding: 12px 12px 8px;
`;

export const CloseDropdownButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: none;
  color: #1890ff;
  transition: color 250ms;
  &:hover {
    color: #40a9ff;
  }
`;

export const CreateNewGroupButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: none;
  color: #1890ff;
  transition: color 250ms;
  &:hover {
    color: #40a9ff;
  }
`;

export const NoAvailableOptionsMessage = styled.span`
  display: block;
  text-align: center;
`;

export const SelectionList = styled.div`
  padding: 0 !important;
  height: 300px;
  overflow: auto;
`;

export const Epmty = styled.span`
  width: 5px;
`;
