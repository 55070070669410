import '../../globalcss.css';

import { Button, Drawer, Menu, MenuItem } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid';
import { Col, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from '../../redux/actions/UI';
import { selectedOrganizationSelector } from '../../redux/selectors/organization';
import {
  deleteCustomFields,
  getCustomFields,
  getMemberCustomFieldData,
} from '../../redux/services';
import CSVUploadDrawer from './CSVUploadDrawer';
import CustomFieldDrawer from './CustomFieldDrawer';
import DeleteFieldModal from './DeleteFieldModal';
import { FieldColumns } from './FieldColumns';
import { userPrivilegesSelector } from '../../redux/selectors/user';
import { LoadingOutlined } from '@ant-design/icons';

const FieldsTable = props => {
  const [AddFieldVisibility, setAddFieldVisibility] = useState(false);
  const [SelectedField, setSelectedField] = useState(null);
  const [EditFieldVisibility, setEditFieldVisibility] = useState(false);
  const [CSVUploadVisibility, setCSVUploadVisibility] = useState(false);
  const [FieldList, setFieldList] = useState(props.tableData);
  const [ContactFieldMapping, setContactFieldMapping] = useState({});
  const [PageSize, setPageSize] = useState(25);
  const [SelectedFields, setSelectedFields] = useState([]);
  const [SelectedCustomFields, setSelectedCustomFields] = useState([]);
  const [CSVUploadStep, setCSVUploadStep] = useState('File Upload');
  const [OpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [addMenuAnchor, setAddMenuAnchor] = useState(false);
  const [isLoading, setIsLoading] = useState({ value: false, text: '' });

  const dispatch = useDispatch();
  const selectedOrgnization = useSelector(selectedOrganizationSelector);
  const userPrivileges = useSelector(userPrivilegesSelector);
  useEffect(() => {
    setFieldList(props.tableData);
  }, [props.tableData]);

  useEffect(() => {
    refreshTableContent();
  }, [selectedOrgnization.id]);

  const getCustomFieldsMemberData = async () => {
    const contactData = await getMemberCustomFieldData(selectedOrgnization.id);
    let fieldMapping = {};
    contactData.forEach(contact => {
      fieldMapping[contact.id] = contact.customData;
    });
    setContactFieldMapping(fieldMapping);
  };
  const refreshTableContent = async () => {
    setIsLoading({ value: true, text: 'Fetching Fields & Data' });
    const fields = await getCustomFields(
      selectedOrgnization.id,
      selectedOrgnization.orgType
    );
    setFieldList(fields);
    getCustomFieldsMemberData();
    setIsLoading({ value: false, text: '' });
  };

  const checkForSelectedFields = useMemo(() => {
    //Return whether or not the delete button should be disabled
    if (!SelectedFields.length) return true;
    const selectedFieldsAllowedToBeDeleted = []; //Only custom fields allowed to be deleted
    for (let fieldId of SelectedFields) {
      const fieldInfo = FieldList.find(field => field.id === fieldId);
      if (fieldInfo.category === 'Custom')
        selectedFieldsAllowedToBeDeleted.push(fieldId);
    }
    setSelectedCustomFields(selectedFieldsAllowedToBeDeleted);
    return !selectedFieldsAllowedToBeDeleted.length;
  }, [SelectedFields]);

  const deleteSelectedFields = async fieldToDelete => {
    const deletePayload = {
      orgId: selectedOrgnization.id,
      ids: fieldToDelete ? fieldToDelete : SelectedCustomFields,
    };
    await deleteCustomFields(deletePayload);
    setSelectedFields([]);
    setSelectedCustomFields([]);
    await refreshTableContent();
    dispatch(
      toast(
        'success',
        `Successfully removed ${deletePayload.ids.length} fields.`
      )
    );
  };

  const defaultTheme = createTheme();
  const useStyles = makeStyles(
    () => {
      return {
        root: {
          '& .super-app-theme--general': {
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
      };
    },
    { defaultTheme }
  );
  const classes = useStyles();

  const addMenu = (
    <>
      <Button
        id="add-member-menu-button"
        aria-controls="add-menu"
        aria-haspopup="true"
        aria-expanded={addMenuAnchor ? 'true' : undefined}
        size={'small'}
        variant="outlined"
        startIcon={<AddCircleIcon />}
        onClick={event => setAddMenuAnchor(event.currentTarget)}
        style={{ background: '#5EAB00', color: '#fff', borderColor: '#5EAB00' }}
      >
        Add
      </Button>
      <Menu
        className={'addMenuContainer'}
        id="add-menu"
        aria-labelledby="add-member-menu-button"
        anchorEl={addMenuAnchor}
        getContentAnchorEl={null} //Need this to have the menu open up in the correct spot with anchorOrigin and transformOrigin
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(addMenuAnchor)}
        onClose={() => setAddMenuAnchor(null)}
      >
        <MenuItem
          onClick={() => {
            setAddMenuAnchor(null);
            setAddFieldVisibility(true);
          }}
        >
          Create a Field
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAddMenuAnchor(null);
            setCSVUploadVisibility(true);
          }}
        >
          Upload from CSV
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <>
      <Row gutter={16} style={{ padding: '0 30px' }}>
        <Col span={12}>
          <div className={'lastSyncSpan'} />
        </Col>
        <Col span={12} style={{ textAlign: 'right', marginTop: '-38px' }}>
          {userPrivileges.manageTags && (
            <Button
              size={'small'}
              variant="contained"
              startIcon={<DeleteIcon />}
              onClick={() => setOpenDeleteModal(true)}
              disabled={checkForSelectedFields}
              style={{
                background: checkForSelectedFields ? '#CACACA' : '#FF6161',
                marginRight: '16px',
                color: checkForSelectedFields ? '' : '#fff',
              }}
            >
              Delete
            </Button>
          )}
          {addMenu}
        </Col>
      </Row>
      <div className="DataGridContainer ManageTableContainer">
        <div className={'ManageTagsDataGrid'}>
          <DataGrid
            className={classes.root}
            stickyHeader
            rows={FieldList}
            columns={FieldColumns}
            sortingOrder={['asc', 'desc']}
            pageSize={PageSize}
            onPageSizeChange={size => setPageSize(size)}
            checkboxSelection
            disableSelectionOnClick
            getRowClassName={() => {
              return 'super-app-theme--general';
            }}
            onRowClick={data => {
              setEditFieldVisibility(true);
              setSelectedField(data.row);
            }}
            onSelectionModelChange={newSelectionModel => {
              setSelectedFields(newSelectionModel);
            }}
            selectionModel={SelectedFields}
            loading={!FieldList.length}
          />
        </div>

        {SelectedField && (
          <Drawer
            anchor={'right'}
            onClose={() => {
              setEditFieldVisibility(false);
              setSelectedField(null);
            }}
            open={EditFieldVisibility}
            className={'fieldDrawer'}
          >
            <CustomFieldDrawer
              closeDrawer={() => {
                setEditFieldVisibility(false);
                setSelectedField(null);
              }}
              drawerTitle={
                <span style={{ paddingLeft: '10px' }}>
                  Field Details: &nbsp;&nbsp;{SelectedField.name}
                </span>
              }
              FieldData={SelectedField}
              NRFields={FieldList}
              ExistingField={true}
              deleteField={id => {
                setEditFieldVisibility(false);
                deleteSelectedFields([id]);
              }}
              closeAndRefresh={() => {
                setEditFieldVisibility(false);
                refreshTableContent();
              }}
              contactCustomData={ContactFieldMapping}
              contactList={props.contacts}
              refreshManage={() => props.refreshManagePage()}
            />
          </Drawer>
        )}
        <Drawer
          anchor={'right'}
          onClose={() => {
            setCSVUploadVisibility(false);
          }}
          open={CSVUploadVisibility}
          className={'fieldDrawer'}
        >
          <CSVUploadDrawer
            closeDrawer={() => {
              setCSVUploadVisibility(false);
            }}
            drawerTitle={
              <span style={{ paddingLeft: '10px' }}>
                Fields & Data: &nbsp;&nbsp;Add from CSV - {CSVUploadStep}
              </span>
            }
            closeAndRefresh={() => {
              setCSVUploadVisibility(false);
              refreshTableContent();
            }}
            updateUploadStep={step => {
              setCSVUploadStep(step);
            }}
            NRFields={FieldList}
            contactGenericData={props.contacts}
            contactCustomData={ContactFieldMapping}
            refreshManage={() => props.refreshManagePage()}
          />
        </Drawer>
        <Drawer
          anchor={'right'}
          onClose={() => setAddFieldVisibility(false)}
          open={AddFieldVisibility}
          className={'fieldDrawer'}
        >
          <CustomFieldDrawer
            closeDrawer={() => {
              setAddFieldVisibility(false);
            }}
            drawerTitle={
              <span style={{ paddingLeft: '10px' }}>
                Fields & Data: &nbsp;&nbsp;Add new field
              </span>
            }
            closeAndRefresh={() => {
              setAddFieldVisibility(false);
              refreshTableContent();
            }}
          />
        </Drawer>
        {OpenDeleteModal && (
          <DeleteFieldModal
            closeModal={() => setOpenDeleteModal(false)}
            deleteList={SelectedCustomFields.map(fieldId => {
              return FieldList.find(field => field.id === fieldId).name;
            })}
            deleteFields={() => {
              deleteSelectedFields();
            }}
          />
        )}
      </div>
      <div
        className="ContactFieldsLoading"
        style={{ display: isLoading.value ? 'flex' : 'none' }}
      >
        <div className="contentDiv">
          <LoadingOutlined />
          <p>{isLoading.text}</p>
        </div>
      </div>
    </>
  );
};

export default FieldsTable;
