import React from 'react';
import styled from 'styled-components';
import {
  TextField,
  InputAdornment,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/SearchRounded';

export const TextFieldStyled = styled(TextField)`
  && {
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    margin-left: auto;
    padding: 0 8px;
    width: 100%;

    & button {
      width: 24px;
      height: 24px;

      & svg {
        color: rgba(0, 0, 0, 0.54);
        width: 100%;
        height: 100%;
      }
    }

    & svg {
      color: rgba(0, 0, 0, 0.54);
    }
  }
`;

export const SearchBox = ({
  search,
  setSearch,
  loading,
  placeholder = 'Search',
}) => {
  return (
    <TextFieldStyled
      placeholder={placeholder}
      value={search}
      onChange={e => setSearch(e.target.value)}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            size="small"
            style={{ visibility: loading && search ? 'visible' : 'hidden' }}
          >
            <CircularProgress fontSize="small" />
          </IconButton>
        ),
      }}
    />
  );
};
