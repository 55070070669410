import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from '../../../redux/action';
import { Archive, SettingsBackupRestoreOutlined } from '@material-ui/icons';
import { updateMessageStatus } from '../api';
import { Button } from './TextingHub.styled';

const ArchiveBtnForText = ({
  textMessages,
  setTextMessages,
  selectedIds,
  status,
  resetSelectedIds,
}) => {
  const dispatch = useDispatch();
  const ACTIVE = 'active';
  const ARCHIVED = 'archived';
  const handleMessagesActionBtn = async () => {
    try {
      const newStatus = status === ACTIVE ? ARCHIVED : ACTIVE;
      await updateMessageStatus(selectedIds, newStatus);

      const updatedTextMessages = textMessages.filter(message => {
        if (selectedIds.includes(message._id)) {
          return false;
        }
        return true;
      });

      setTextMessages(updatedTextMessages);
      const successStatusText =
        status === ACTIVE
          ? 'Successfully archived messages'
          : 'Successfully unarchived messages';
      dispatch(toast('success', successStatusText));
      resetSelectedIds();
    } catch (error) {
      console.error(error);
      const failedStatusText =
        status === ACTIVE
          ? 'Failed to archive messages'
          : 'Failed to unarchive messages';
      dispatch(toast('error', failedStatusText));
    }
  };

  return (
    <>
      {status === ARCHIVED && (
        <Button
          color={'#FC9E3A'}
          disabled={!selectedIds.length}
          onClick={handleMessagesActionBtn}
        >
          <SettingsBackupRestoreOutlined
            style={{ fontSize: '18px', marginRight: '5px' }}
          />
          Unarchive
        </Button>
      )}
      {status !== ARCHIVED && (
        <Button
          color={'#ff6161'}
          disabled={!selectedIds.length}
          onClick={handleMessagesActionBtn}
        >
          <Archive style={{ fontSize: '18px', marginRight: '5px' }} />
          Archive
        </Button>
      )}
    </>
  );
};

export default ArchiveBtnForText;
