import { _memoize } from 'underscore-es';

export const selectedOrganizationSelector = _memoize(state => {
  const selectedOrganization = state.organization.selected;
  const types = state.organization.orgTypes;
  const type = types[selectedOrganization.orgType];
  return {
    ...selectedOrganization,
    type,
  };
}, JSON.stringify);

export const organizationUsersSelector = state => state.organization.users;

export const organizationListSelector = state => state.organization.list;
