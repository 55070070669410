import { useToggle as useBasicToggle } from 'react-use';
import { useCallback } from 'react';

const useToggle = (initialState = false) => {
  const [state, toggleState] = useBasicToggle(initialState);

  const toggle = useCallback(() => {
    toggleState();
  }, [toggleState]);

  const set = useCallback(
    newState => {
      if (newState === undefined) {
        throw new Error(
          `'newState' must be provided to '${useToggle.name}.set()'`
        );
      }
      toggleState(newState);
    },
    [toggleState]
  );

  const on = useCallback(() => {
    set(true);
  }, [set]);

  const off = useCallback(() => {
    set(false);
  }, [set]);

  return [state, { on, off, toggle, set }];
};

export default useToggle;
