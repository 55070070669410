import React, { useRef } from 'react';
import {
  Checkbox,
  ClickAwayListener,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
} from '@material-ui/core';
import { _without } from 'underscore-es';

import { useToggle } from '../../../../libs/react-use-extra';
import { Button } from './StatusFilter.styled';

const STATUSES = [
  { value: 'active', label: 'Active' },
  { value: 'paused', label: 'Paused' },
  { value: 'archived', label: 'Archived' },
];

const StatusFilter = ({ value, onChange }) => {
  const statusFilterButtonRef = useRef();
  const [isStatusFilterOpen, { on: openStatusFilter, off: closeStatusFilter }] =
    useToggle();

  const isStatusChecked = status => value.includes(status);

  const notifyStatusToggle = status => {
    const newValue = value.includes(status)
      ? _without(value, status)
      : [...value, status];
    onChange(newValue);
  };

  return (
    <>
      <Button ref={statusFilterButtonRef} onClick={openStatusFilter}>
        Filter
      </Button>
      <Popper
        open={isStatusFilterOpen}
        anchorEl={statusFilterButtonRef.current}
      >
        <ClickAwayListener onClickAway={closeStatusFilter}>
          <Paper elevation={3}>
            <List dense disablePadding>
              {STATUSES.map(({ value, label }) => (
                <ListItem
                  key={value}
                  dense
                  button
                  onClick={() => notifyStatusToggle(value)}
                >
                  <ListItemIcon style={{ minWidth: 0 }}>
                    <Checkbox
                      size="small"
                      edge="start"
                      checked={isStatusChecked(value)}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={label} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default StatusFilter;
