// REDUCER FOR TASK LIST
export function auth(state = {}, action) {
  console.log(action);
  switch (action.type) {
    case 'AUTH_LOGIN':
      return Object.assign({}, state, {
        isAuthenticated: true,
      });
    case 'AUTH_LOGOUT':
      return Object.assign({}, state, {
        isAuthenticated: false,
      });
    default:
      return state;
  }
}
