import styled from 'styled-components';
import { Select as AntdSelect } from 'antd';

export const Select = styled(AntdSelect)`
  // increasing specificity to override antd's default styling which has specificity of 0-0-3-0
  .ant-select-selector.ant-select-selector {
    border-radius: 5px;
    border: 1px solid #a3a3a3;
    background-color: #f7f7f7;
  }
`;
