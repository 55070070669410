import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import { StarFilled, StarOutlined } from '@ant-design/icons';

import BaseAudienceCard from '../../../AudienceCard';
import {
  applyAudienceToNewDraftAction,
  applyEditStandAloneAudienceAction,
  fullloader,
  toast,
} from '../../../../redux/action';
import { updateAudience } from '../../../../redux/services';
import { CardHeader } from '../_CardUI/Card.styled';
import {
  ArchiveButton,
  EditButton,
  FavoriteButton,
  UnarchiveButton,
} from '../_CardUI/CardActionButtons.styled';

const AudienceCard = ({
  audience,
  reRender,
  applyAudience,
  isApplyingAudience,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [starred, setStarred] = useState(audience.starred);

  const toggleFavoriteButton = async isFavorite => {
    try {
      await updateAudience({ ...audience, starred: isFavorite });
      let text = '';
      if (isFavorite) {
        setStarred(true);
        text = 'added to';
      } else {
        setStarred(false);
        text = 'removed from';
      }

      dispatch(toast('success', `Audience ${text} Favorites`));
    } catch (error) {
      dispatch(toast('error', 'Failed To Update Audience'));
    }
  };

  const toggleArchiveIcon = async () => {
    try {
      const archived = !audience.archived;
      dispatch(
        fullloader(
          true,
          `${archived ? 'Archiving' : 'Retrieving'} Audience ...`
        )
      );

      await updateAudience({ ...audience, archived });
      reRender();

      dispatch(fullloader(false));
      dispatch(
        toast('success', `Audience is ${archived ? 'Archived' : 'Active'}`)
      );
    } catch (error) {
      dispatch(fullloader(false));
      dispatch(toast('error', 'Failed To Update Audience'));
    }
  };

  const applyAudienceToNewDraft = () => {
    dispatch(applyAudienceToNewDraftAction(audience));
    history.push('/dashboard/sendmessage?draft=new');
  };

  const goToEdit = async () => {
    dispatch(applyEditStandAloneAudienceAction(audience));
    history.push(`/dashboard/audience?audienceId=${audience._id}`);
  };

  const getActionIcons = () => {
    if (isApplyingAudience) return '';

    return (
      <>
        {audience.archived ? (
          <>
            <CardHeader.Left />
            <CardHeader.Right>
              <UnarchiveButton onClick={toggleArchiveIcon} />
            </CardHeader.Right>
          </>
        ) : (
          <>
            <CardHeader.Left>
              <FavoriteButton
                as={starred ? StarFilled : StarOutlined}
                $starred={starred ? true : false}
                onClick={() => {
                  if (starred) {
                    toggleFavoriteButton(false);
                  } else {
                    toggleFavoriteButton(true);
                  }
                }}
              />
            </CardHeader.Left>
            <CardHeader.Right>
              <Tooltip title={`Edit: "${audience.name}"`}>
                <EditButton onClick={goToEdit} />
              </Tooltip>
              <ArchiveButton onClick={toggleArchiveIcon} />
            </CardHeader.Right>
          </>
        )}
      </>
    );
  };

  const getTitle = () => {
    return isApplyingAudience
      ? `Applying audience "${audience.name}" to existing draft.`
      : `Create a new Draft with Audience: "${audience.name}"`;
  };

  return (
    <BaseAudienceCard
      audience={audience}
      tooltip={getTitle()}
      header={getActionIcons()}
      onClick={isApplyingAudience ? applyAudience : applyAudienceToNewDraft}
    />
  );
};

export default AudienceCard;
