import styled from 'styled-components';

export const DisplayAlert = styled.p`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  background-color: #fc9e3a;
  padding: 6px 8px;
  border-radius: 8px;
  color: #fff;
  font-weight: 400;
  max-width: 500px;
  font-family: 'Montserrat';
  margin: auto;
  text-align: center;
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 8px;
  line-height: 1.3;
`;
