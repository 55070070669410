import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { useToggle } from '../../../libs/react-use-extra';
import { fetchOrgTypes } from '../../../redux/services/organization';
import { setOrgTypes } from '../../../redux/actions/organizations';
import withAuthentication from '../../../Component/withAuthentication';
import CampaignsIndex from '../CampaignsIndex';
import CreateCampaign from '../CreateCampaign';
import EditCampaign from '../EditCampaign';
import { Container } from './View.styled';

const useLoadOrgTypes = () => {
  const [isLoading, { on: markLoading, off: unmarkLoading }] = useToggle();
  const dispatch = useDispatch();

  useEffect(() => {
    markLoading();
    fetchOrgTypes().then(orgTypes => {
      dispatch(setOrgTypes(orgTypes));
      unmarkLoading();
    });
  }, [dispatch, markLoading, unmarkLoading]);

  return isLoading;
};

const View = () => {
  const areOrgTypesLoading = useLoadOrgTypes();
  const route = useRouteMatch();

  if (areOrgTypesLoading) {
    return null;
  }

  return (
    <Container>
      <Switch>
        <Route exact path={`${route.path}/`}>
          <CampaignsIndex />
        </Route>
        <Route path={`${route.path}/new`}>
          <CreateCampaign />
        </Route>
        <Route path={`${route.path}/edit/:id`}>
          <EditCampaign />
        </Route>
      </Switch>
    </Container>
  );
};

export default withAuthentication(View);
