import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import { groupsSelector } from '../../redux/selectors/tags';
import AddToGroupModal from '../AddToGroupModal';

const useVisibility = () => {
  const [isVisible, setIsVisible] = useState(false);

  const open = useCallback(() => {
    setIsVisible(true);
  }, []);

  const close = useCallback(() => {
    setIsVisible(false);
  }, []);

  return { isVisible, open, close };
};

const BulkGroupAssignmentButton = ({
  className = '',
  selectedMemberIds,
  onAssignGroups = () => {},
  onClose = () => {},
}) => {
  const groups = useSelector(groupsSelector);
  const visibility = useVisibility();

  const handleClose = ({ hasCreatedNewGroup }) => {
    visibility.close();
    onClose({ hasCreatedNewGroup });
  };

  const handleAssignGroups = () => {
    visibility.close();
    onAssignGroups();
  };

  return (
    <>
      <Button
        className={className}
        style={{
          color: selectedMemberIds.length === 0 ? '' : '#6C7075',
          background: selectedMemberIds.length === 0 ? '#cacaca' : '#fff',
          border: '1px solid #D4D7D9',
        }}
        variant="outlined"
        size="small"
        startIcon={<GroupAddIcon />}
        disabled={selectedMemberIds.length === 0}
        onClick={visibility.open}
      >
        Add to Group
      </Button>
      {visibility.isVisible && (
        <AddToGroupModal
          initialGroups={groups}
          memberIds={selectedMemberIds}
          onAssignGroups={handleAssignGroups}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default BulkGroupAssignmentButton;
