import React, {
  useState,
  useMemo,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import BeePlugin from '@mailupinc/bee-plugin';
import { useSelector } from 'react-redux';
import { selectedOrganizationSelector } from '../../../redux/selectors/organization';
import { BeeEditor, Container } from '../Editor.styled';

const BaseEditor = (
  {
    className = null,
    Header = null,
    data,
    onSave,
    saveStatus,
    isTextStatusSaved,
    onUnsavedStatus,
    design,
    setDesign,
    html,
    setHtml = () => {},
    setDraft,
  },
  ref
) => {
  const beeEditor = useMemo(() => new BeePlugin(), []);
  const saveListenerRef = useRef(null);
  const proceedToNextPage = useRef(() => {});

  useImperativeHandle(
    ref,
    () => ({
      async getHtml() {
        return new Promise(resolve => {
          beeEditor.save();
          saveListenerRef.current = html => {
            saveListenerRef.current = null;
            resolve(html);
          };
        });
      },
    }),
    [beeEditor]
  );

  const [isBeeLoading, setIsBeeLoading] = useState(true);
  const organizationId = useSelector(selectedOrganizationSelector).id;

  const onBeeChange = async designJson => {
    setDesign(designJson);
    onSave({ design: designJson });
  };

  const onBeeSave = async (designJson, htmlFile) => {
    if (setDraft) setDraft({ ...data, html: htmlFile, design: designJson });
    await onSave({
      html: htmlFile,
      design: designJson,
    });

    if (saveListenerRef.current) {
      saveListenerRef.current(htmlFile);
    }

    proceedToNextPage.current();
  };

  const onBeeComment = ({ comments }) => {
    setDesign(design => {
      const jsonDesign =
        typeof design === 'string' ? JSON.parse(design) : design;

      const designWithNewComments = { ...jsonDesign, comments };
      const stringifiedDesignForSaving = JSON.stringify(designWithNewComments);
      onSave({
        design: stringifiedDesignForSaving,
      });
      return designWithNewComments;
    });
  };

  const onBeeSend = htmlFile => {
    setHtml(htmlFile);
    onSave({
      html: htmlFile,
    });
  };

  const renderBeeEditor = () => {
    if (!organizationId || !design) return;
    // DOCS: https://docs.beefree.io/methods-and-events/
    return (
      <BeeEditor.Wrapper>
        <BeeEditor
          beeEditor={beeEditor}
          clientUid={organizationId}
          currentDesign={design}
          onLoad={() => {
            setIsBeeLoading(false);
          }}
          onChange={onBeeChange}
          onSend={onBeeSend}
          onComment={onBeeComment}
          // Triggers on beeEditor.save() // used for final updating when continuing
          onSave={onBeeSave}
          AB={data?.AB}
        />
      </BeeEditor.Wrapper>
    );
  };

  return (
    <Container className={className}>
      {Header && (
        <Header
          proceedToNextPage={proceedToNextPage}
          isDesignLoading={isBeeLoading}
          data={data}
          beeEditor={beeEditor}
          saveStatus={saveStatus}
          isTextStatusSaved={isTextStatusSaved}
          onUnsavedStatus={onUnsavedStatus}
          html={html}
          design={design}
          onSave={onSave}
        />
      )}
      {renderBeeEditor()}
    </Container>
  );
};

export default forwardRef(BaseEditor);
