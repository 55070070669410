import React from 'react';
import styled from 'styled-components';
import { Button as MuiButton } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';

export const Button = styled(MuiButton).attrs({
  endIcon: <FilterList />,
})`
  font-family: 'Montserrat', sans-serif;
`;
