import React, { Component } from 'react';
import './upload.css';
import { Upload, Button, Row, Col } from 'antd';
import upload from '../../Images/upload.png';
import { v4 as uuidv4 } from 'uuid';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { getLogoUrl } from '../../redux/services/content';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
class Uploads extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: null,
    };
    this.getLogo = this.getLogo.bind(this);
  }

  componentDidMount() {
    this.getLogo(this.props.organization.id);
  }

  componentDidUpdate(prevProps) {
    if (!Object.is(prevProps.user, this.props.user)) {
      this.getLogo(this.props.organization.id);
    }

    if (prevProps.organization.id !== this.props.organization.id) {
      this.getLogo(this.props.organization.id);
    }
  }

  async getLogo(id) {
    if (id) {
      const logoUrl = await getLogoUrl(id);
      this.setState({
        imageUrl: logoUrl,
      });
    }
  }

  handleChange = info => {
    this.props.setChangedLogo(true);
    if (info.file.status === 'done' || info.file.status === 'error') {
      console.log(info.file);
      this.handleUploadSuccess(info.file.originFileObj);
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
        })
      );
    }
  };

  handleUploadSuccess = file => {
    const name = uuidv4();
    const storage = getStorage();
    const storageRef = ref(storage, `images/${name}`);
    uploadBytes(storageRef, file).then(snapshot => {
      console.log(snapshot);
      this.props.setFile(name);
    });
  };

  render() {
    const uploadButton = (
      <div>
        <img src={upload} className="uploadimg" alt="upload" />
        <div className="ant-upload-text">Drag image or click to upload</div>
      </div>
    );
    const imageUrl = this.state.imageUrl;
    return (
      <div>
        <Row>
          <Col>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              onChange={this.handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" className="uploadedimg" />
              ) : (
                uploadButton
              )}
            </Upload>
            <div>
              <Upload showUploadList={false} onChange={this.handleChange}>
                <Button>Upload Photo</Button>
              </Upload>
            </div>
            {imageUrl && (
              <a
                style={{ fontSize: '12px' }}
                onClick={() => {
                  this.setState({
                    imageUrl: null,
                  });
                }}
              >
                Remove Photo
              </a>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Uploads;
