import http from '../../../../utils/andromeda-api';

export const smartResend = async ({
  messageId,
  scheduleTime,
  messageSubject,
  selectedOrganization,
}) => {
  const { data } = await http.post(`/smartResend/schedule/${messageId}`, {
    scheduleTime,
    messageSubject,
    selectedOrganization,
  });

  return data;
};

export const cancelSmartResend = async messageId => {
  const { data } = await http.delete(`/smartResend/${messageId}`);

  return data;
};
