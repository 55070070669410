import React from 'react';
import { useToggle } from 'react-use';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { BaseSider } from './Sider.styled';

const Sider = ({ children }) => {
  const [isCollapsed, toggleIsCollapsed] = useToggle(false);

  return (
    <BaseSider trigger={null} collapsible collapsed={isCollapsed}>
      <BaseSider.Collapse
        as={isCollapsed ? RightOutlined : LeftOutlined}
        onClick={toggleIsCollapsed}
      />

      {children(isCollapsed)}
    </BaseSider>
  );
};

export default Sider;
