import styled from 'styled-components';

export const ImageHolder = styled.div`
  width: 100%;
  height: 75%;
  overflow: hidden;
  margin-bottom: 10px;
`;

export const Image = styled.img`
  width: 100%;
`;
