import React, { useState } from 'react';
import { connect } from 'react-redux';
import GenerateRow from '../GenerateFilterRow';
import GeoFilter from '../GeoFilter';
import {
  GEOGRAPHIC,
  HOME_CITY,
  HOME_COUNTY,
  HOME_ZIP_CODE,
  HOME_STATE,
  OFFICE_CITY,
  OFFICE_COUNTY,
  OFFICE_ZIP_CODE,
  OFFICE_STATE,
} from '../_shared/constants';

const ShowGeoFilters = ({ audienceFilters, filterTypes, selectedFilters }) => {
  const geoFilterType = filterTypes.filter(
    filter => filter.name === GEOGRAPHIC
  )[0];
  const geoFilters = audienceFilters.filter(
    filter => filter.filterType === geoFilterType.id
  );

  const geoFilterExists = name => {
    return geoFilters.some(filter => filter.name === name);
  };

  const [checkedHomeCity, setHomeCity] = useState(!!selectedFilters[HOME_CITY]);
  const [checkedHomeCounty, setHomeCounty] = useState(
    !!selectedFilters[HOME_COUNTY]
  );
  const [checkedHomeZipCode, setHomeZipCode] = useState(
    !!selectedFilters[HOME_ZIP_CODE]
  );
  const [checkedHomeState, setHomeState] = useState(
    !!selectedFilters[HOME_STATE]
  );
  const [checkedOfficeCity, setOfficeCity] = useState(
    !!selectedFilters[OFFICE_CITY]
  );
  const [checkedOfficeCounty, setOfficeCounty] = useState(
    !!selectedFilters[OFFICE_COUNTY]
  );
  const [checkedOfficeZipCode, setOfficeZipCode] = useState(
    !!selectedFilters[OFFICE_ZIP_CODE]
  );
  const [checkedOfficeState, setOfficeState] = useState(
    !!selectedFilters[OFFICE_STATE]
  );

  const geoFiltersList = [
    {
      filterName: HOME_CITY,
      checked: checkedHomeCity,
      setChecked: setHomeCity,
    },
    {
      filterName: HOME_COUNTY,
      checked: checkedHomeCounty,
      setChecked: setHomeCounty,
    },
    {
      filterName: HOME_ZIP_CODE,
      checked: checkedHomeZipCode,
      setChecked: setHomeZipCode,
    },
    {
      filterName: HOME_STATE,
      checked: checkedHomeState,
      setChecked: setHomeState,
    },
    {
      filterName: OFFICE_CITY,
      checked: checkedOfficeCity,
      setChecked: setOfficeCity,
    },
    {
      filterName: OFFICE_COUNTY,
      checked: checkedOfficeCounty,
      setChecked: setOfficeCounty,
    },
    {
      filterName: OFFICE_ZIP_CODE,
      checked: checkedOfficeZipCode,
      setChecked: setOfficeZipCode,
    },
    {
      filterName: OFFICE_STATE,
      checked: checkedOfficeState,
      setChecked: setOfficeState,
    },
  ];

  return (
    <>
      {geoFiltersList.map(
        ({ filterName, checked, setChecked }) =>
          geoFilterExists(filterName) && (
            <GenerateRow
              key={filterName}
              check={checked}
              setCheck={setChecked}
              label={filterName}
            >
              <GeoFilter
                filterName={filterName}
                checked={checked}
                selectedFilters={selectedFilters[filterName]}
              />
            </GenerateRow>
          )
      )}
    </>
  );
};

const mapStateToProps = state => ({
  audienceFilters: state.audience.audienceFilters,
  filterTypes: state.audience.filterTypes,
  selectedFilters: state.audience.selectedFilters,
});

export default connect(mapStateToProps)(ShowGeoFilters);
