import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 20px;
  gap: 10px;
`;

export const Column = styled.div`
  width: 58%;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Menu = styled.nav`
  display: flex;
`;

export const MenuItem = styled.button`
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  padding: 3px 0;
  margin-right: 6px;
  border-radius: 2px;
  text-align: center;
  width: 100px;
  color: #007ade;
  border: 1px solid #509ee5;
  transition: all 0.3s ease-in;
  background-color: #ebf0f5;
  &:hover,
  &.active {
    color: white;
    background-color: #007ade;
    border: 1px solid #007ade;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 10px;
`;
