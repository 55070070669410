//Different features that use the results drawer
export const GROUPING_TOOL = 'Grouping_Tool';
export const CONTACT_UPLOAD = 'Contact_Upload';
export const EXCEPTIONS_COLUMNS = {
  [GROUPING_TOOL]: [
    {
      headerName: 'Identifier',
      field: 'identifier',
      minWidth: 25,
      flex: 0.25,
    },
    {
      headerName: 'Reason',
      field: 'message',
      minWidth: 110,
      flex: 1,
    },
  ],
  [CONTACT_UPLOAD]: [
    {
      headerName: 'Code',
      field: 'code',
      minWidth: 25,
      flex: 0.25,
    },
    {
      headerName: 'Notes',
      field: 'notes',
      minWidth: 110,
      flex: 1,
    },
  ],
};
export const CSV_HEADERS = {
  [GROUPING_TOOL]: [
    {
      label: 'UID',
      key: 'identifier',
    },
    {
      label: 'Message',
      key: 'message',
    },
  ],
  [CONTACT_UPLOAD]: [
    {
      label: 'UID',
      key: 'UID',
    },
    {
      label: 'Notes',
      key: 'notes',
    },
    {
      label: 'Code',
      key: 'code',
    },
  ],
};
