import './CustomFields.css';

import { Button, IconButton, Modal, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Col, Input, Row, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { selectedOrganizationSelector } from '../../redux/selectors/organization';
import { toast } from '../../redux/actions/UI';
import * as services from '../../redux/services';
const { Option } = Select;
const { TextArea } = Input;

const CreateFieldModal = props => {
  const [modalVisible, setModalVisible] = useState(false);
  const [newFieldName, setNewFieldName] = useState('');
  const [newFieldDescription, setNewFieldDescription] = useState('');
  const [newFieldType, setNewFieldType] = useState('General');
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  useEffect(() => {
    handleOpen();
  }, []);

  const handleOpen = () => setModalVisible(true);
  const handleClose = () => {
    props.clearModalValue();
    setModalVisible(false);
  };

  const saveNewField = async () => {
    const customFieldData = {
      category: 'Custom',
      dataType: newFieldType,
      description: newFieldDescription,
      id: uuidv4(),
      lastUpdatedTimestamp: moment().unix(),
      createdTimestamp: moment().unix(),
      name: newFieldName,
      organizationId: selectedOrganization.id,
    };
    const newField = await services.addCustomFields(customFieldData);
    if (newField) {
      props.addFieldToMatching(customFieldData);
    } else {
      handleClose();
      dispatch(
        toast('error', `Error adding new field: ${customFieldData.name}.`)
      );
    }
  };

  return (
    <div>
      <Modal open={modalVisible} onClose={handleClose}>
        <div className={'modalStyling'} style={{ height: '294px' }}>
          <div
            className={'modalHeader'}
            style={{ justifyContent: 'space-between', paddingRight: '15px' }}
          >
            <IconButton
              onClick={() => {
                handleClose();
              }}
              style={{ color: 'white' }}
            >
              <CloseIcon />
            </IconButton>
            <span style={{ fontSize: '16px' }}>Create New Field</span>
            <Button
              className={'FieldSaveButtons'}
              variant="outlined"
              onClick={() => saveNewField()}
            >
              Save
            </Button>
          </div>
          <div className={'modalBody'}>
            <div style={{ height: 'calc(100% - 60px)' }}>
              <Row gutter={24} style={{ paddingBottom: '10px' }}>
                <Col span={18}>
                  <Input
                    placeholder={'Field Name'}
                    onChange={event => {
                      setNewFieldName(event.target.value);
                    }}
                    type={'text'}
                    style={{ padding: '5px 9px' }}
                  />
                </Col>
                <Col span={6}>
                  <Tooltip
                    placement={'left-start'}
                    title={
                      <span>
                        <p>
                          {
                            'General: Also known as string values, this data type works for just about everything.'
                          }
                        </p>
                        <p>
                          {
                            'Number: When a field will only be populated with numbers (no letters or other characters), use this option to increase performance.'
                          }
                        </p>
                      </span>
                    }
                  >
                    <Select
                      placeholder={'Data Type'}
                      value={newFieldType}
                      onChange={value => {
                        setNewFieldType(value);
                      }}
                      style={{ width: '100%' }}
                    >
                      <Option value={'General'}>General</Option>
                      <Option value={'Number'}>Number</Option>
                    </Select>
                  </Tooltip>
                </Col>
              </Row>
              <Row gutter={24} style={{ paddingBottom: '10px' }}>
                <Col span={24}>
                  <TextArea
                    placeholder={'Notes'}
                    onChange={event => {
                      setNewFieldDescription(event.target.value);
                    }}
                    rows="3"
                    cols="25"
                  />
                </Col>
              </Row>
              <Row gutter={24} style={{ paddingBottom: '10px' }}>
                <Col span={24}>
                  <Select
                    defaultValue={'Custom'}
                    style={{ width: '100%' }}
                    disabled={true}
                  >
                    <Option value={'Custom'}>Custom</Option>
                  </Select>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Input placeholder={'Created'} disabled={true} type="text" />
                </Col>
                <Col span={12}>
                  <Input
                    placeholder={'Last Updated'}
                    type="text"
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateFieldModal;
