import React from 'react';
import { Body, Container, Header } from './Step.styled';

const Step = ({
  className = null,
  title,
  description = null,
  headerCenter = null,
  headerRightAddon = null,
  onBack = null,
  onNext = () => {},
  nextText = null,
  isNextDisabled = false,
  withoutVerticalPaddingInBody = false,
  withoutHorizontalPaddingInBody = false,
  children,
}) => {
  const backButton =
    typeof onBack === 'string' ? (
      <Header.BackButton to={onBack} />
    ) : (
      <Header.BackButton onClick={onBack} />
    );

  return (
    <Container>
      <Header>
        <Header.Left>
          {backButton}
          <Header.TitleContainer>
            <Header.Title>
              {'New Campaign > '}
              <Header.Title.Step>{title}</Header.Title.Step>
            </Header.Title>
            <Header.Description>{description}</Header.Description>
          </Header.TitleContainer>
        </Header.Left>
        {headerCenter && <Header.Center>{headerCenter}</Header.Center>}
        <Header.Right>
          {headerRightAddon && <div>{headerRightAddon}</div>}
          {nextText && (
            <Header.NextButton onClick={onNext} disabled={isNextDisabled}>
              {nextText}
            </Header.NextButton>
          )}
        </Header.Right>
      </Header>
      <Body
        withoutVerticalPadding={withoutVerticalPaddingInBody}
        withoutHorizontalPadding={withoutHorizontalPaddingInBody}
      >
        <div className={className}>{children}</div>
      </Body>
    </Container>
  );
};

export default Step;
