import React from 'react';
import { Card } from '../_CardUI/Card.styled';
import { ImageHolder, Image } from './TemplateCard.styled';
import { formatTimestampToDate, trimTextIfNeeded } from '../_utils';

const TemplateCard = ({ template, onClick }) => {
  return (
    <Card isTall pointer onClick={onClick}>
      <ImageHolder>
        <Image src={template.thumbnail} alt="template" />
      </ImageHolder>
      <Card.Date>{formatTimestampToDate(template.saveTime)}</Card.Date>
      <Card.Name>{trimTextIfNeeded(template.name, 25)}</Card.Name>
    </Card>
  );
};

export default TemplateCard;
