import React from 'react';
import { AddOutlined } from '@material-ui/icons';
import { Group, Container, Tab, Row, AddButton } from './Tabs.styled';
import { AMS_SYNC_LOGS, TAB_2, TAB_3, TAB_4 } from './constants';

const Tabs = ({ selectedTab, onSelectTab, setAddOrgDrawerVisibility }) => {
  const TABS = [AMS_SYNC_LOGS, TAB_2, TAB_3, TAB_4];

  const renderRightMenu = () => {
    switch (selectedTab) {
      case AMS_SYNC_LOGS:
        return (
          <Row>
            <AddButton
              onClick={() => {
                setAddOrgDrawerVisibility(true);
              }}
            >
              <AddOutlined />
              Add
            </AddButton>
          </Row>
        );
      case TAB_2:
        return <></>;
      case TAB_3:
        return <></>;
      case TAB_4:
        return <></>;
      default:
        return null;
    }
  };

  return (
    <Container>
      <Group>
        {TABS.map((tab, index) => (
          <Tab
            isActive={selectedTab === tab}
            key={index}
            onClick={() => onSelectTab(tab)}
          >
            {tab}
          </Tab>
        ))}
      </Group>
      <Group>{renderRightMenu()}</Group>
    </Container>
  );
};

export default Tabs;
