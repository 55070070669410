import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { Prompt } from 'react-router-dom';

import { useToggle } from '../../../../libs/react-use-extra';
import { audienceSelector } from '../../../../redux/selectors/audience';
import { convertAudienceDataForDraft } from '../../../../Component/_shared/utils';
import {
  resetAudienceBuilder,
  saveStandAloneAudienceName,
  setAudienceData,
} from '../../../../redux/actions/audience';
import { groupsSelector } from '../../../../redux/selectors/tags';
import { fetchContactFullNames } from '../../../../redux/services/contact';
import { selectedOrganizationSelector } from '../../../../redux/selectors/organization';
import Audience from '../../../../Component/Sendmessage/Audience';
import {
  ADD,
  EXCLUDE,
  REQUIRE,
} from '../../../../Component/Sendmessage/Audience/constants';
import { useEditor } from '../CampaignEditor';
import Step from '../Step';
import { useFullScreenSpinner } from '../../../../redux/actions/UI';

const LEAVE_CONFIRMATION_MESSAGE =
  'Are you sure you want to leave? Any unsaved progress will be lost.';

const useResetAudienceBuilder = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetAudienceBuilder());

    return () => {
      dispatch(resetAudienceBuilder());
    };
  }, [dispatch]);
};

const useApplyDefaultAudienceName = () => {
  const editor = useEditor();
  const dispatch = useDispatch();

  return () => {
    const campaignName = editor.getField('name');
    const audienceDefaultName = `${campaignName ?? 'Campaign'} Audience`;
    dispatch(saveStandAloneAudienceName(audienceDefaultName));
  };
};

const useInitializeAudience = () => {
  useResetAudienceBuilder();

  const editor = useEditor();
  const dispatch = useDispatch();
  const applyDefaultAudienceName = useApplyDefaultAudienceName();

  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const groups = useSelector(groupsSelector);

  const [isLoading, { off: markLoaded }] = useToggle(true);

  const calcSelectedGroups = audience => {
    const selectedGroups = {};

    const addSelectedGroups = (groupId, action) => {
      const group = groups.find(group => group.id === groupId);
      selectedGroups[groupId] = { name: group.name, action };
    };

    const iterateThroughGroupsIfNeeded = (groupIds, action) => {
      if (groupIds?.length) {
        groupIds.forEach(groupId => {
          addSelectedGroups(groupId, action);
        });
      }
    };

    iterateThroughGroupsIfNeeded(audience.selectedGroupIds, ADD);
    iterateThroughGroupsIfNeeded(audience.excludedGroupIds, EXCLUDE);
    iterateThroughGroupsIfNeeded(audience.requiredGroupIds, REQUIRE);

    return selectedGroups;
  };

  const calcSelectedMembers = async audience => {
    const selectedMembers = {};

    const rawSelectedContactIds = [
      ...audience.includedMemberIds,
      ...audience.excludedMemberIds,
    ];
    const selectedContactIdsSet = new Set(rawSelectedContactIds);
    const selectedContactIds = Array.from(selectedContactIdsSet);

    if (selectedContactIds.length === 0) {
      return selectedMembers;
    }

    const fullNamesByContact = await fetchContactFullNames(selectedContactIds, {
      organizationId: selectedOrganization.id,
    });

    const addSelectedMember = (contactId, action) => {
      const fullName = fullNamesByContact[contactId];
      selectedMembers[contactId] = {
        name: fullName,
        action,
      };
    };

    const iterateThroughMembersIfNeeded = (memberIds, action) => {
      if (memberIds?.length) {
        memberIds.forEach(memberId => {
          addSelectedMember(memberId, action);
        });
      }
    };

    iterateThroughMembersIfNeeded(audience.includedMemberIds, ADD);
    iterateThroughMembersIfNeeded(audience.excludedMemberIds, EXCLUDE);

    return selectedMembers;
  };

  useMount(async () => {
    const haveAudience = editor.hasParam('audience');
    if (haveAudience) {
      const audience = editor.getParam('audience');
      dispatch(
        setAudienceData({
          ...audience,
          selectedGroups: calcSelectedGroups(audience),
          selectedMembers: await calcSelectedMembers(audience),
        })
      );
    } else {
      applyDefaultAudienceName();
    }

    markLoaded();
  });

  return isLoading;
};

const AudienceEditor = () => {
  const isLoading = useInitializeAudience();
  useFullScreenSpinner('Loading audience data...', isLoading);

  const editor = useEditor();

  const goBack = () => {
    const isConfirmed = window.confirm(LEAVE_CONFIRMATION_MESSAGE);
    if (isConfirmed) {
      editor.goToStep('audience/picker');
    }
  };

  const audienceData = useSelector(audienceSelector);
  const isAudienceReady = audienceData.selectedTopicsList.length === 0;

  const handleConfirm = async () => {
    editor.setField('audience', {
      name: audienceData.name,
      description: audienceData.description,
      ...convertAudienceDataForDraft(audienceData),
    });
    editor.goToStep('overview');
  };

  return (
    <>
      <Step
        title="Audience"
        description="Select the Audience that your new Campaign should watch"
        onBack={goBack}
        nextText="Confirm"
        isNextDisabled={isAudienceReady}
        onNext={handleConfirm}
        withoutHorizontalPaddingInBody
      >
        <Audience
          withoutPadding
          withoutTitle
          withoutPreview
          withoutApplyFileMenu
          alignTitleToLeft
          centerTabs
          isStandaloneAudience
          disableAutosave
        />
      </Step>
      <Prompt message={LEAVE_CONFIRMATION_MESSAGE} />
    </>
  );
};

export default AudienceEditor;
