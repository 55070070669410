import React, { useState } from 'react';
import * as moment from 'moment';
import { convertToCSVFormat } from '../../../utils';
import CardABversion from './CardABversion';
import FailedDeliveryReport from '../../MessageAnalytics/Components/FailedDeliveryReport';
import SpamReport from '../../MessageAnalytics/Components/SpamReport';

const EmailABversion = ({
  emailEvents,
  message,
  generateRatio,
  generateAverage,
  isLoadingData,
  startDate,
  isDataAvailable,
  setClickActivityVisibility,
  smartResendEmailEvents,
  isSmartResendAvailable,
}) => {
  const [isFailedDeliveryDrawerVisible, setFailedDeliveryDrawerVisibility] =
    useState(false);
  const [isSpamDrawerVisible, setSpamDrawerVisibility] = useState(false);
  const [data, setData] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [failedDeliveryReportTitle, setFailedDeliveryReportTitle] =
    useState('');

  const handleEmailBounceRateClick = () => {
    const dataVersionA = emailEvents.bounceEvents.map(bounce => ({
      ...bounce,
      time: moment.unix(bounce.time).format('MM-DD-YYYY hh:mm A'),
      contact: bounce.email,
    }));

    const dataVersionB = emailEvents.versionB.bounceEvents.map(bounce => ({
      ...bounce,
      time: moment.unix(bounce.time).format('MM-DD-YYYY hh:mm A'),
      contact: bounce.email,
    }));

    const data = [...dataVersionA, ...dataVersionB];

    setData(data);
    setCSVData(convertToCSVFormat(data));
    setFailedDeliveryDrawerVisibility(true);
    setFailedDeliveryReportTitle('Bounce');
  };

  const handleEmailDropRateClick = () => {
    const dataVersionA = emailEvents.droppedEvents.map(dropped => ({
      ...dropped,
      time: moment.unix(dropped.time).format('MM-DD-YYYY hh:mm A'),
      contact: dropped.email,
    }));

    const dataVersionB = emailEvents.versionB.droppedEvents.map(dropped => ({
      ...dropped,
      time: moment.unix(dropped.time).format('MM-DD-YYYY hh:mm A'),
      contact: dropped.email,
    }));

    const data = [...dataVersionA, ...dataVersionB];

    setData(data);
    setCSVData(convertToCSVFormat(data));
    setFailedDeliveryDrawerVisibility(true);
    setFailedDeliveryReportTitle('Dropped Emails');
  };

  const handleSpamRateClick = () => {
    const dataVersionA = emailEvents.spamEvents.map(bounce => ({
      ...bounce,
      time: moment.unix(bounce.time).format('MM-DD-YYYY hh:mm A'),
      contact: bounce.email,
    }));

    const dataVersionB = emailEvents.versionB.spamEvents.map(bounce => ({
      ...bounce,
      time: moment.unix(bounce.time).format('MM-DD-YYYY hh:mm A'),
      contact: bounce.email,
    }));
    const data = [...dataVersionA, ...dataVersionB];

    setData(data);
    setCSVData(convertToCSVFormat(data));
    setSpamDrawerVisibility(true);
  };

  const showClickActivity = () => {
    setClickActivityVisibility(true);
  };

  const calcSmartResendClickThroughRateForVersionA = () => {
    const oldRatio = generateRatio(
      emailEvents.uniqueclick,
      emailEvents.uniqueopen
    );

    const newRatio = generateRatio(
      emailEvents.uniqueclick + smartResendEmailEvents.emailEventsA.uniqueclick,
      emailEvents.uniqueopen + smartResendEmailEvents.emailEventsA.uniqueopen
    );

    return parseInt(newRatio) - parseInt(oldRatio) + '%';
  };

  const calcSmartResendClickThroughRateForVersionB = () => {
    const oldRatio = generateRatio(
      emailEvents.versionB.uniqueclick,
      emailEvents.versionB.uniqueopen
    );

    const newRatio = generateRatio(
      emailEvents.versionB.uniqueclick +
        smartResendEmailEvents.emailEventsB.uniqueclick,
      emailEvents.versionB.uniqueopen +
        smartResendEmailEvents.emailEventsB.uniqueopen
    );

    return parseInt(newRatio) - parseInt(oldRatio) + '%';
  };

  return (
    <>
      <CardABversion
        name="Deliveries"
        valueA={emailEvents.delivered}
        valueB={emailEvents.versionB.delivered}
        smartResendValueA={
          smartResendEmailEvents?.emailEventsA
            ? smartResendEmailEvents.emailEventsA.delivered
            : null
        }
        smartResendValueB={
          smartResendEmailEvents?.emailEventsB
            ? smartResendEmailEvents.emailEventsB.delivered
            : null
        }
        generateAverage={generateAverage}
        title="The number of unique contacts that successfully received their email. If multiple contacts have the same email address, only one of them will be emailed."
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
      />
      <CardABversion
        name="Delivery Rate"
        valueA={generateRatio(emailEvents.delivered, emailEvents.sent)}
        valueB={generateRatio(
          emailEvents.versionB.delivered,
          emailEvents.versionB.sent
        )}
        smartResendValueA={
          smartResendEmailEvents?.emailEventsA
            ? generateRatio(
                smartResendEmailEvents.emailEventsA.delivered,
                smartResendEmailEvents.emailEventsA.sent
              )
            : null
        }
        smartResendValueB={
          smartResendEmailEvents?.emailEventsB
            ? generateRatio(
                smartResendEmailEvents.emailEventsB.delivered,
                smartResendEmailEvents.emailEventsB.sent
              )
            : null
        }
        generateAverage={generateAverage}
        title="Contacts who were successfully delivered to."
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
      />
      <CardABversion
        name="Open Rate"
        valueA={generateRatio(
          emailEvents.uniqueopen +
            (smartResendEmailEvents?.emailEventsA?.uniqueopen || 0),
          emailEvents.delivered
        )}
        valueB={generateRatio(
          emailEvents.versionB.uniqueopen +
            (smartResendEmailEvents?.emailEventsB?.uniqueopen || 0),
          emailEvents.versionB.delivered
        )}
        smartResendValueA={
          smartResendEmailEvents?.emailEventsA
            ? generateRatio(
                smartResendEmailEvents.emailEventsA.uniqueopen,
                emailEvents.delivered
              )
            : null
        }
        smartResendValueB={
          smartResendEmailEvents?.emailEventsB
            ? generateRatio(
                smartResendEmailEvents.emailEventsB.uniqueopen,
                emailEvents.versionB.delivered
              )
            : null
        }
        generateAverage={generateAverage}
        title="The number of contacts who opened your email at least
        once. (It's a great indicator of subject line quality.)"
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
        showComparativeAnalytics
      />
      <CardABversion
        name="Click-Through Rate"
        valueA={generateRatio(
          emailEvents.uniqueclick +
            (smartResendEmailEvents?.emailEventsA?.uniqueclick || 0),
          emailEvents.uniqueopen +
            (smartResendEmailEvents?.emailEventsA?.uniqueopen || 0)
        )}
        valueB={generateRatio(
          emailEvents.versionB.uniqueclick +
            (smartResendEmailEvents?.emailEventsB?.uniqueclick || 0),
          emailEvents.versionB.uniqueopen +
            (smartResendEmailEvents?.emailEventsB?.uniqueopen || 0)
        )}
        smartResendValueA={
          smartResendEmailEvents?.emailEventsA
            ? calcSmartResendClickThroughRateForVersionA()
            : null
        }
        smartResendValueB={
          smartResendEmailEvents?.emailEventsB
            ? calcSmartResendClickThroughRateForVersionB()
            : null
        }
        generateAverage={generateAverage}
        title="The number of contacts who clicked a link in your email after opening it. (It's a great indicator of content quality.)"
        isDataAvailable={isDataAvailable}
        onClick={setClickActivityVisibility ? showClickActivity : null}
        isSmartResendAvailable={isSmartResendAvailable}
        showComparativeAnalytics
      />
      <CardABversion
        name="Bounce Rate"
        valueA={generateRatio(emailEvents.bounce, emailEvents.sent)}
        valueB={generateRatio(
          emailEvents.versionB.bounce,
          emailEvents.versionB.sent
        )}
        smartResendValueA={
          smartResendEmailEvents?.emailEventsA
            ? generateRatio(
                smartResendEmailEvents.emailEventsA.bounce,
                smartResendEmailEvents.emailEventsA.sent
              )
            : null
        }
        smartResendValueB={
          smartResendEmailEvents?.emailEventsB
            ? generateRatio(
                smartResendEmailEvents.emailEventsB.bounce,
                smartResendEmailEvents.emailEventsB.sent
              )
            : null
        }
        generateAverage={generateAverage}
        title="Usually a result of sending to a bad email address."
        onClick={handleEmailBounceRateClick}
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
      />
      <CardABversion
        name="Drop Rate"
        valueA={generateRatio(emailEvents.dropped, emailEvents.sent)}
        valueB={generateRatio(
          emailEvents.versionB.dropped,
          emailEvents.versionB.sent
        )}
        smartResendValueA={
          smartResendEmailEvents?.emailEventsA
            ? generateRatio(
                smartResendEmailEvents.emailEventsA.dropped,
                smartResendEmailEvents.emailEventsA.sent
              )
            : null
        }
        smartResendValueB={
          smartResendEmailEvents?.emailEventsB
            ? generateRatio(
                smartResendEmailEvents.emailEventsB.dropped,
                smartResendEmailEvents.emailEventsB.sent
              )
            : null
        }
        generateAverage={generateAverage}
        title='"Dropped" means that an email was not sent because of an unresolved "Bounce" or "Spam Report" issue for that contact.'
        onClick={handleEmailDropRateClick}
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
      />
      <CardABversion
        name="Spam Report Rate"
        valueA={generateRatio(emailEvents.spamReport, emailEvents.sent)}
        valueB={generateRatio(
          emailEvents.versionB.spamReport,
          emailEvents.versionB.sent
        )}
        smartResendValueA={
          smartResendEmailEvents?.emailEventsA
            ? generateRatio(
                smartResendEmailEvents.emailEventsA.spamReport,
                smartResendEmailEvents.emailEventsA.sent
              )
            : null
        }
        smartResendValueB={
          smartResendEmailEvents?.emailEventsB
            ? generateRatio(
                smartResendEmailEvents.emailEventsB.spamReport,
                smartResendEmailEvents.emailEventsB.sent
              )
            : null
        }
        generateAverage={generateAverage}
        title="The number of contacts who reported your message as spam."
        onClick={handleSpamRateClick}
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
      />
      <FailedDeliveryReport
        onCloseDrawer={() => setFailedDeliveryDrawerVisibility(false)}
        isVisible={isFailedDeliveryDrawerVisible}
        isLoading={isLoadingData}
        message={message}
        startDate={startDate}
        data={data}
        csvData={csvData}
        title={failedDeliveryReportTitle}
      />
      <SpamReport
        onCloseDrawer={() => setSpamDrawerVisibility(false)}
        isVisible={isSpamDrawerVisible}
        isLoading={isLoadingData}
        message={message}
        startDate={startDate}
        data={data}
        csvData={csvData}
      />
    </>
  );
};

export default EmailABversion;
