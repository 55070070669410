import styled from 'styled-components';
import {
  EditOutlined,
  CopyOutlined,
  StarFilled,
  FolderOpenOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

const createAction = (BaseIcon, { hoverColor = '#007ade' } = {}) =>
  styled(BaseIcon)`
    transition: color 0.5s;

    &:hover {
      color: ${hoverColor};
    }
  `;

export const EditButton = styled(createAction(EditOutlined))`
  color: rgba(24, 144, 255, 0.75);
`;

export const FavoriteButton = styled(
  createAction(StarFilled, { hoverColor: 'gold' })
)`
  ${({ $starred }) => $starred && 'color: gold'}
`;

export const MoveButton = createAction(FolderOpenOutlined);

export const CopyButton = createAction(CopyOutlined);

export const ArchiveButton = createAction(CloseCircleOutlined, {
  hoverColor: 'red',
});

export const UnarchiveButton = createAction(CheckCircleOutlined, {
  hoverColor: 'green',
});
