import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: calc(100vh - 100px);
`;

export const HalfContainer = styled.div`
  width: calc(50% - 10px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  background-color: white;
  max-height: 100%;
  overflow-y: auto;
`;

export const TransparentContainer = styled.div`
  width: calc(50% - 10px);
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  gap: 20px;
`;

export const KeyContactsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: ${props => props.height || 'calc(100vh - 100px)'};
  overflow-y: auto;
  border-radius: 8px;
  background-color: white;
  padding: 20px;
`;

export const TechnicalSettingsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(40vh - 50px);
  overflow-y: auto;
  border-radius: 8px;
  background-color: white;
  padding: 20px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  color: #007ade;
  font-weight: bold;
`;

export const EditBtn = styled.button`
  font-size: 14px;
  border-radius: 2px;
  align-items: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-family: Poppins, sans-serif;
  padding: 1px 25px;
  color: #6c7075;
  border: 1px solid #6c7075;
  background-color: white;
  display: flex;
  gap: 10px;
  &:hover {
    color: white;
    background-color: #007ade;
    border: 1px solid #007ade;
    stroke: white;
  }
`;

export const DetailsContainer = styled.div`
  border-radius: 8px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
`;

export const Cell = styled.span`
  display: flex;
  background-color: white;
  width: ${props => props.width || '100%'};
  padding: 5px 10px;
  color: #6c7075;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 14px;
`;

export const Bold = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

export const BoldTitle = styled.span`
  font-weight: bold;
  font-size: 16px;
  margin-bottom: -5px;
`;

export const Center = styled.div`
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
`;

export const SmallGapContent = styled.div`
  display: flex;
  flex-direction: column;
`;
