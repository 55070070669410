import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  loadGroups,
  removeGroup,
  selectGroup,
} from '../../../../../../../redux/action';
import { REMOVE } from '../../../../constants';
import { fetchOrgGroups } from '../../../../../../../redux/services/groups';
import generateActionBtns from '../_shared/generateActionBtns';
import Container from '../Filters/Components/Table/container';
import Header from '../Filters/Components/Table/header';

const GroupsTable = ({
  groups,
  selectedGroups,
  orgId,
  selectedSubMenuItemName,
  actions,
}) => {
  const loadGroups = async () => {
    const fetchedGroups = await fetchOrgGroups(orgId);
    actions.loadGroups(fetchedGroups);
  };

  useEffect(() => {
    loadGroups();
  }, [orgId]);

  const handleGroupSelect = ({ id, Group }, action) => {
    if (action === REMOVE) {
      actions.removeGroup(id);
    } else {
      actions.selectGroup({ [id]: { action, name: Group } });
    }
  };

  const columns = ['Group', 'Contacts', 'Description'];
  const rows = ['name', 'subscribedMembers', 'description'];
  const flex = [0.6, 0.3, 1];
  const minWidth = [250, 125, 420];

  const generatedColumns = [
    generateActionBtns(handleGroupSelect, selectedGroups),
  ];

  generatedColumns.push(
    ...columns.map((text, index) => ({
      field: text,
      headerName: text,
      flex: flex[index],
      minWidth: minWidth[index],
    }))
  );

  const generatedRows = groups
    .filter(row => row.groupTypeName === selectedSubMenuItemName)
    .map(row => {
      const generatedRow = { id: row.id };
      rows.forEach((field, index) => {
        generatedRow[columns[index]] = row[field];
      });
      return generatedRow;
    });

  if (!generatedRows.length) {
    return (
      <Container>
        <Header>{selectedSubMenuItemName} groups</Header>
        <p style={{ padding: '18px' }}>No groups available.</p>
      </Container>
    );
  }

  return (
    <DataGrid
      rows={generatedRows}
      columns={generatedColumns}
      pageSize={15}
      rowsPerPageOptions={[15]}
      selectionModel={Object.keys(selectedGroups)}
    />
  );
};

const mapStateToProps = state => ({
  groups: state.groups.sort((a, b) => a.name.localeCompare(b.name)),
  selectedGroups: state.audience.selectedGroups,
  selectedSubMenuItemName: state.audience.selectedSubMenuItemName,
  orgId: state.organization.selected.id,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { loadGroups, removeGroup, selectGroup },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsTable);
