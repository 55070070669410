import React from 'react';
import getDisplayName from './getDisplayName';

const withProps = (props, Component) => {
  const WrappedComponent = directProps => {
    return <Component {...directProps} {...props} />;
  };

  WrappedComponent.displayName = `withProps(${getDisplayName(Component)})`;

  return WrappedComponent;
};

export default withProps;
