import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, SwipeableDrawer } from '@material-ui/core';
import { selectedOrganizationSelector } from '../../../redux/selectors/organization';
import EventsTable from './EventsTable';
import { escapeRegExp } from '../../../redux/helpers/helpers';
import ExportBtn from '../ExportBtn';
import { Container, Row, Button, Group } from './Events.styled';
import EventDetailsDrawer from '../EventDetailsDrawer';
import { formatEventsData } from '../utils';
import { fetchEvents, fetchEventRegistrations } from '../api';

const Events = () => {
  const orgId = useSelector(selectedOrganizationSelector).id;
  const [initialEventsAndClassesData, setInitialEventsAndClassesData] =
    useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isDrawerLoading, setDrawerLoading] = useState(true);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [eventsAndClassesData, setEventsAndClassesData] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventsVisible, setEventsVisible] = useState(true);
  const [classesVisible, setClassesVisible] = useState(true);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setEventsVisible(true);
    setClassesVisible(true);
    setSearchText('');
    setEventsAndClassesData([]);
    setInitialEventsAndClassesData([]);
    setLoading(true);
    const getEvents = async () => {
      const { result } = await fetchEvents();
      const eventsData = formatEventsData(result);

      setInitialEventsAndClassesData(eventsData);
      setEventsAndClassesData(eventsData);
      setLoading(false);
    };
    getEvents();
  }, [orgId]);

  const requestSearch = searchValue => {
    setSearchText(searchValue);
    if (!searchValue) {
      setEventsAndClassesData(
        initialEventsAndClassesData.filter(applyVisibilityFilters)
      );
    } else {
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredEventsAndClassesData = initialEventsAndClassesData.filter(
        row => {
          if (!applyVisibilityFilters(row)) {
            return false;
          }
          return Object.keys(row).some(field => {
            return searchRegex.test(row[field]?.toString());
          });
        }
      );
      setEventsAndClassesData(filteredEventsAndClassesData);
    }
  };

  const applyVisibilityFilters = row => {
    if (!eventsVisible && row.type === 'E') {
      return false;
    }
    if (!classesVisible && row.type === 'S') {
      return false;
    }
    return true;
  };

  const toggleDrawer = isOpen => {
    setDrawerOpen(isOpen);
  };

  useEffect(() => {
    if (eventsVisible && classesVisible) {
      setEventsAndClassesData(initialEventsAndClassesData);
    } else if (eventsVisible && !classesVisible) {
      setEventsAndClassesData(
        initialEventsAndClassesData.filter(data => data.type != 'S')
      );
    } else if (!eventsVisible && classesVisible) {
      setEventsAndClassesData(
        initialEventsAndClassesData.filter(data => data.type != 'E')
      );
    } else {
      setEventsAndClassesData([]);
    }
  }, [eventsVisible, classesVisible]);

  const openEventDetailsDrawer = async ({ row: event }) => {
    setDrawerLoading(true);
    setContacts([]);
    setSelectedEvent(event);
    toggleDrawer(true);
    const { result } = await fetchEventRegistrations(event.id);
    const mapOfUniqueIds = {};
    const filteredContactDetails = result?.filter(contact => {
      // this filter is applied to remove duplicates
      if (mapOfUniqueIds[contact.contactAMSID]) return false;
      mapOfUniqueIds[contact.contactAMSID] = true;
      return true;
    });
    setContacts(filteredContactDetails);
    setDrawerLoading(false);
  };

  return (
    <Container>
      <Row>
        <Button>Events</Button>
        <ExportBtn eventsAndClassesData={eventsAndClassesData} />
      </Row>
      <Row>
        <Group>
          <Checkbox
            checked={eventsVisible}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onClick={() => setEventsVisible(!eventsVisible)}
          />
          Show Events
          <Checkbox
            checked={classesVisible}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onClick={() => setClassesVisible(!classesVisible)}
          />
          Show Classes
        </Group>
      </Row>
      <EventsTable
        data={eventsAndClassesData}
        isLoading={isLoading}
        onEventClick={openEventDetailsDrawer}
        requestSearch={requestSearch}
        searchText={searchText}
      />
      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <EventDetailsDrawer
          onClose={() => toggleDrawer(false)}
          contacts={contacts}
          event={selectedEvent}
          isDrawerLoading={isDrawerLoading}
        />
      </SwipeableDrawer>
    </Container>
  );
};

export default Events;
