import React from 'react';
import Modal from '../../../../../Component/Modal';

const DeleteGroupMappingModal = ({
  setDeleteGroupMappingModalVisibility,
  selectedMapIds,
  deleteMaps,
}) => {
  const handleClose = () => {
    setDeleteGroupMappingModalVisibility(false);
  };

  return (
    <Modal
      title="Confirm Delete"
      onClose={handleClose}
      primaryButton={{
        onClick: deleteMaps,
      }}
      secondaryButton={{
        onClick: handleClose,
      }}
    >
      Are you, sure you want to delete {selectedMapIds.length}
      {selectedMapIds.length > 1 ? ' maps' : ' map'} ?
    </Modal>
  );
};

export default DeleteGroupMappingModal;
