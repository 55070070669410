import React, { Component } from 'react';
import './Dashboard.css';
import '../../globalcss.css';
import { withRouter } from 'react-router-dom';
// Page Components
import ViewMessage from '../../Component/ViewMessage/ViewMessage';
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Layout, Progress } from 'antd';
import { Grommet } from 'grommet';
import { grommet } from 'grommet/themes';
import { IdleTimerProvider } from 'react-idle-timer';
import * as services from '../../redux/services';
import { CSVLink } from 'react-csv';
import { logout } from '../../redux/actions/auth';
import {
  fullloader,
  privilegesList,
  setProgressType,
  storeRoleData,
  updateNavProgressMeter,
  updateProgressMeterResults,
} from '../../redux/actions/UI';
import {
  organizationList,
  userOrganization,
} from '../../redux/actions/organizations';
import { userPrivileges } from '../../redux/actions/user';
import {
  userDetailSelector,
  userPrivilegesSelector,
} from '../../redux/selectors/user';
import { selectedOrganizationSelector } from '../../redux/selectors/organization';
import { privilegesListSelector } from '../../redux/selectors/privileges';
import {
  fullloaderSelector,
  loaderMsgSelector,
  progressMeterSelector,
  progressResultsSelector,
  progressTypeSelector,
} from '../../redux/selectors/ui';
import { ChildDrawer } from '../../Component/ChildDrawer';
import Container from '../../Component/ResultsDrawer/Components/Container';
import { withFeatures } from '../../libs/feature-toggles/react';
import Feature from '../../infrastructure/features';
import Sidenav from '../../Component/Sidenav';
import Audience from '../../Component/Sendmessage/Audience';
import Sendmessage from '../../Component/Sendmessage';
import MemberList from '../../Component/MemberList/memberList';

const { Content } = Layout;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state = {
      resultsDrawer: false,
      ResultsDrawerVisible: false,
    };
  }

  componentDidMount() {
    this.props.fullloader(true, 'Authenticating...');
    services.getPrivilegesList().then(priviledges => {
      this.props.privilegesList(priviledges);

      const privileges = this.props.privilegesListData;
      const orgId = this.props.selectedOrganization.id;
      const userId = this.props.userDetail.id;

      const privilegesToCheck = [
        { preferences: privileges.MANAGE_PREFERENCES },
        { engagement: privileges.MANAGE_ENGAGEMENT },
        { orgHeader: privileges.MANAGE_ORG_HEADER },
        { editor: privileges.SEND_MESSAGE_TEST_ONLY },
        { analytics: privileges.MANAGE_ANALYTICS },
        { manageOrgAdmin: privileges.MANAGE_ORG_USERS_ADMIN },
        { manageAuthOrgs: privileges.MANAGE_AUTHORIZED_ORGS },
        { manageMembers: privileges.MANAGE_MEMBERS },
        { manageTags: privileges.MANAGE_TAGS },
        { messageApproval: privileges.SEND_MESSAGE_FULL_ACCESS },
        { isSU: privileges.MANAGE_EXECUTIVE_ADMINS },
        { isSA: privileges.MANAGE_COMPANY_ITEMS },
        { manageUser_Admin: privileges.MANAGE_ORG_USERS_ADMIN },
        { manageUser_Exec: privileges.MANAGE_ORG_USERS_EXEC },
        { viewLast_Syncs: privileges.VIEW_LAST_SYNCS },
        { beeAdmin: privileges.nr_bee_admin },
      ];

      if (userId && orgId && privilegesToCheck) {
        services
          .validatePrivilegeBatch(userId, orgId, privilegesToCheck)
          .then(res => {
            this.props.userPrivileges(
              res && res.data ? res.data.userPrivileges : {}
            );
            this.props.fullloader(false);
          })
          .catch(error => {
            console.log(error);
          });
      }
    });

    services.getRoles().then(roles => {
      this.props.storeRoleData(roles);
      this.props.fullloader(false);
    });
  }

  componentDidUpdate(prevProps) {
    if (!this.props.userDetail || !this.props.selectedOrganization) {
      return this.props.history.push('/login');
    }

    if (!Object.is(prevProps.userDetail, this.props.userDetail)) {
      if (prevProps.userDetail.multiOrgUser !== 'admin') {
        if (
          !prevProps.selectedOrganization ||
          (prevProps.selectedOrganization &&
            prevProps.selectedOrganization.id !==
              prevProps.userDetail.organizationId)
        ) {
          if (prevProps.userDetail.organizationId) {
            this.props.userOrganization(prevProps.userDetail.organizationId);
          }
        }
      }
    }

    if (
      this.props.selectedOrganization?.id !==
        prevProps.selectedOrganization?.id &&
      !this.props.userPrivilegesData.isSU
    ) {
      services.getPrivilegesList().then(priviledges => {
        this.props.privilegesList(priviledges);

        const privileges = this.props.privilegesListData;
        const orgId = this.props.selectedOrganization.id;
        const userId = this.props.userDetail.id;

        const privilegesToCheck = [
          { preferences: privileges.MANAGE_PREFERENCES },
          { engagement: privileges.MANAGE_ENGAGEMENT },
          { orgHeader: privileges.MANAGE_ORG_HEADER },
          { editor: privileges.SEND_MESSAGE_TEST_ONLY },
          { analytics: privileges.MANAGE_ANALYTICS },
          { manageOrgAdmin: privileges.MANAGE_ORG_USERS_ADMIN },
          { manageAuthOrgs: privileges.MANAGE_AUTHORIZED_ORGS },
          { manageMembers: privileges.MANAGE_MEMBERS },
          { manageTags: privileges.MANAGE_TAGS },
          { messageApproval: privileges.SEND_MESSAGE_FULL_ACCESS },
          { isSU: privileges.MANAGE_EXECUTIVE_ADMINS },
          { isSA: privileges.MANAGE_COMPANY_ITEMS },
          { isSDA: privileges.MANAGE_SUPER_ADMINS },
          { manageUser_Admin: privileges.MANAGE_ORG_USERS_ADMIN },
          { manageUser_Exec: privileges.MANAGE_ORG_USERS_EXEC },
          { viewLast_Syncs: privileges.VIEW_LAST_SYNCS },
        ];

        if (userId && orgId && privilegesToCheck) {
          services
            .validatePrivilegeBatch(userId, orgId, privilegesToCheck)
            .then(async res => {
              if (!res.data.userPrivileges.isSU) {
                const userOrgList = await services.getMultiUserOrgList(
                  this.props.userDetail.organizations
                );
                this.props.organizationList(userOrgList);
              } else {
                const userOrgList = await services.getOrganizationList();
                this.props.organizationList(userOrgList);
              }

              this.props.userPrivileges(res.data.userPrivileges);
              this.props.fullloader(false);
            })
            .catch(error => {
              console.log(error);
            });
        }
      });

      services.getRoles().then(roles => {
        this.props.storeRoleData(roles);
        this.props.fullloader(false);
      });
    }
  }

  getAudiencePath() {
    return `${this.props.match.url}/audience`;
  }

  onLogout = () => {
    this.props.logout(this.props.history);
  };

  closeDisplayResults = () => {
    this.props.updateNavProgressMeter(0);
    this.props.updateProgressMeterResults({});
    this.setState({ resultsDrawer: false });
  };

  render() {
    return (
      <Grommet theme={grommet}>
        <IdleTimerProvider
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onIdle={this.onLogout}
          timeout={1000 * 60 * 60 * 2}
        >
          <Layout className="mainlayout" theme={grommet}>
            <Sidenav
              footer={
                this.props.progressMeter && this.props.progressType ? (
                  this.props.progressType === 'ANALYTICS_CSV' ? (
                    <div className="progressMeter">
                      <CSVLink
                        data={
                          this.props.progressResults.messages
                            ? this.props.progressResults.messages
                            : []
                        }
                        filename={this.props.progressResults.filename}
                      >
                        <p>
                          {this.props.progressMeter === 100
                            ? 'Finished. Click for results:'
                            : 'In progress ...'}
                        </p>
                        <Progress
                          percent={this.props.progressMeter}
                          size={'small'}
                          status={
                            this.props.progressMeter === 100
                              ? 'success'
                              : 'active'
                          }
                          strokeColor={
                            this.props.progressMeter === 100
                              ? 'green'
                              : '#007ADE'
                          }
                          showInfo={this.props.progressMeter === 100}
                        />
                      </CSVLink>
                    </div>
                  ) : (
                    <div
                      className="progressMeter"
                      onClick={() => {
                        this.props.progressMeter === 100
                          ? this.setState({ ResultsDrawerVisible: true })
                          : console.log('Not complete yet.');
                      }}
                    >
                      <p>
                        {this.props.progressMeter === 100
                          ? 'Finished. Click for results:'
                          : 'In progress ...'}
                      </p>
                      <Progress
                        percent={this.props.progressMeter}
                        size={'small'}
                        status={
                          this.props.progressMeter === 100
                            ? 'success'
                            : 'active'
                        }
                        strokeColor={
                          this.props.progressMeter === 100 ? 'green' : '#007ADE'
                        }
                        showInfo={this.props.progressMeter === 100}
                      />
                    </div>
                  )
                ) : null
              }
              renderContent={content => (
                <Layout style={{ position: 'relative', height: '100%' }}>
                  <div
                    className="fullLoader"
                    style={{
                      display: this.props.fullloaderData ? 'flex' : 'none',
                    }}
                  >
                    <div className="contentDiv">
                      <LoadingOutlined />
                      <p>{this.props.loaderMsg}</p>
                    </div>
                  </div>
                  <Content
                    style={{
                      background: '#f7f7f7',
                      height: '100%',
                    }}
                  >
                    {content}
                  </Content>
                </Layout>
              )}
              extraRoutes={[
                {
                  path: `${this.props.match.url}/preview`,
                  content: ViewMessage,
                },
                {
                  path: `${this.props.match.url}/audience`,
                  content: Audience,
                },
                {
                  path: `${this.props.match.url}/sendmessage`,
                  content: Sendmessage,
                },
                {
                  path: `${this.props.match.url}/memberlist`,
                  content: MemberList,
                },
              ]}
            />

            <ChildDrawer
              anchor="right"
              className="UploadCSVResultsDrawer"
              open={this.state.ResultsDrawerVisible}
              onClose={() => {
                this.setState({ ResultsDrawerVisible: false });
                this.props.updateProgressMeterResults(null);
                this.props.updateNavProgressMeter(0);
              }}
              drawerTitle={this.state.progressResults?.drawerTitle || 'Results'}
              Component={<Container results={this.props.progressResults} />}
            />
          </Layout>
        </IdleTimerProvider>
      </Grommet>
    );
  }
}

const mapStateToProps = state => ({
  userDetail: userDetailSelector(state),
  selectedOrganization: selectedOrganizationSelector(state),
  userPrivilegesData: userPrivilegesSelector(state),
  privilegesListData: privilegesListSelector(state),
  progressMeter: progressMeterSelector(state),
  progressType: progressTypeSelector(state),
  progressResults: progressResultsSelector(state),
  fullloaderData: fullloaderSelector(state),
  loaderMsg: loaderMsgSelector(state),
});

const mapDispatchToProps = {
  fullloader,
  logout,
  updateProgressMeterResults,
  organizationList,
  privilegesList,
  setProgressType,
  storeRoleData,
  updateNavProgressMeter,
  userOrganization,
  userPrivileges,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withFeatures(
      {
        isAutomatedCampaignsFeatureEnabled: Feature.AUTOMATED_CAMPAIGNS,
      },
      Dashboard
    )
  )
);
