import React, { useState } from 'react';

import Wrapper from './Components/Wrapper';
import { DataGrid } from '@mui/x-data-grid';
import { progressTypeSelector } from '../../../../../../redux/selectors/ui';
import { useSelector } from 'react-redux';
import { EXCEPTIONS_COLUMNS } from '../Summary/constants';

const ExceptionsTable = ({ exceptions }) => {
  const [pageSize, setPageSize] = useState(20);
  const progressType = useSelector(progressTypeSelector);

  const tableColumnSelection = () => {
    return EXCEPTIONS_COLUMNS[progressType];
  };

  return (
    <Wrapper>
      <DataGrid
        rows={exceptions}
        columns={tableColumnSelection()}
        sortingOrder={['asc', 'desc']}
        pageSize={pageSize}
        onPageSizeChange={size => setPageSize(size)}
        componentsProps={{ panel: { disablePortal: true } }} //There is a weird issue where the filter text field will not work if this is not present for datagrids in drawers.
      />
    </Wrapper>
  );
};

export default ExceptionsTable;
