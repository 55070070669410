import { useCallback, useReducer } from 'react';
export const Status = {
  SAVED: Symbol('saved'),
  SAVING: Symbol('saving'),
  UNSAVED: Symbol('unsaved'),
};

const ACTIONS = {
  SAVE_CHANGES: 'SAVE_CHANGES',
  UNSAVE_CHANGES: 'UNSAVE_CHANGES',
  SAVING_CHANGES: 'SAVING_CHANGES',
};

const useSaveStatus = () => {
  const [saveStatus, dispatch] = useReducer((state, action) => {
    switch (action) {
      case ACTIONS.SAVE_CHANGES: {
        return Status.SAVED;
      }
      case ACTIONS.SAVING_CHANGES: {
        return Status.SAVING;
      }
      case ACTIONS.UNSAVE_CHANGES: {
        return Status.UNSAVED;
      }
      default: {
        return state;
      }
    }
  }, Status.SAVED);

  const onStartSavingStatus = useCallback(() => {
    dispatch(ACTIONS.SAVING_CHANGES);
  }, []);

  const onFinishSavingStatus = useCallback(() => {
    dispatch(ACTIONS.SAVE_CHANGES);
  }, []);

  const onMarkChangesUnsavedStatus = useCallback(() => {
    dispatch(ACTIONS.UNSAVE_CHANGES);
  }, []);

  const isTextStatusSaved = saveStatus === Status.SAVED;

  return [
    saveStatus,
    isTextStatusSaved,
    { onStartSavingStatus, onFinishSavingStatus, onMarkChangesUnsavedStatus },
  ];
};

export default useSaveStatus;
