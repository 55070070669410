// Table redered by TableType
import { Drawer } from 'antd';
import { Box } from 'grommet';
import MaterialTable, { MTableCell } from 'material-table';
import * as moment from 'moment';
import React, { useState } from 'react';

import { getMessagesByOrg } from '../../redux/services';
import ContactsTable from './ContactsTable';

const MsgTable = props => {
  moment.locale('en');
  const [pageSize, setPageSize] = useState(25);
  const [visible, setVisible] = useState(false);
  const [orgId] = useState(props.orgId);
  const [selectedUser] = useState({});

  const [selectedContactData, setSelectedContactData] = useState();

  const showpersonaltestDrawer = data => {
    setSelectedContactData(data);
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const columnsData = [
    {
      title: 'Date/Time',
      field: 'date',
      defaultSort: 'desc',
      render: rowData => {
        const newDate = moment
          .unix(rowData.sendTime)
          .format('YYYY-MM-DD hh:mm A');
        return newDate;
      },
    },
    { title: 'Subject Line', field: 'messageSubject' },
  ];

  return (
    <div className="engTable">
      <Box className="noterouter_managetable btnseparation">
        <MaterialTable
          components={{
            // eslint-disable-next-line react/display-name
            Cell: props => <MTableCell {...props} />,
            OverlayLoading: _props => <div />,
          }}
          columns={columnsData}
          data={query => {
            props.props.actions.fullloader(true, 'Loading Messages...');
            return new Promise(resolve => {
              getMessagesByOrg(
                orgId,
                query.page + 1,
                query.pageSize,
                query.search
              ).then(({ result, pagination }) => {
                props.props.actions.fullloader(false);
                resolve({
                  data: result,
                  page: pagination.page - 1,
                  totalCount: pagination.total,
                });
              });
            });
          }}
          className="Manage_materialtable"
          title=""
          options={{
            pageSize: pageSize,
            pageSizeOptions: [10, 25, 50],
            debounceInterval: 500,
          }}
          onRowClick={(e, data) => showpersonaltestDrawer(data)}
          onChangeRowsPerPage={e => {
            localStorage.setItem('pageSize', e);
            setPageSize(e);
          }}
          onPageChange={_e => null}
        />
        {selectedUser && visible && (
          <Drawer
            title={'Message sent to the following members'}
            width={800}
            onClose={onClose}
            visible={visible}
            style={{
              overflow: 'auto',
              paddingLeft: '0',
              paddingRight: '0',
            }}
          >
            <ContactsTable orgId={orgId} contactData={selectedContactData} />
          </Drawer>
        )}
      </Box>
    </div>
  );
};

export default MsgTable;
