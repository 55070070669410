import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
  overflow: auto;
`;

export default Container;
