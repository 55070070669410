import styled from 'styled-components';

export const HalfContainer = styled.div`
  max-width: 50%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px;
  gap: 20px;
`;
