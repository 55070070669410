import React from 'react';
import { Card } from '../../../../../../Component/Drafts/Components/_CardUI/Card.styled';
import { EditIcon, TemplateName, Title } from './EditActiveCard.styled';

const EditActiveCard = ({ templateName, onClick }) => {
  return (
    <Card onClick={onClick}>
      <Card.Body>
        <EditIcon />
        <Title>Edit Active Template</Title>
        <TemplateName>{templateName}</TemplateName>
      </Card.Body>
    </Card>
  );
};

export default EditActiveCard;
