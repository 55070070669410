import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  word-wrap: break-word;
`;

export const Text = styled.span`
  width: 190px;
`;

export const BubbleContainer = styled.footer`
  max-width: calc(100% - 190px);
  word-wrap: break-word;
`;

export const Bubble = styled.span`
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  border: 1px solid #ab005e;
  padding: 0 12px;
  color: #ab005e;
  background: #fbf2f7;
  border-radius: 20px;
  margin: 0 3px 5px;
`;
