import styled from 'styled-components';

export const ErrorMsg = styled.div`
  font-weight: bold;
  color: #ff6161;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

export const Header = styled.th`
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  padding: 10px;
`;

export const Data = styled.td`
  border: 1px solid #ddd;
  color: #212121;
  padding: 10px;
`;
