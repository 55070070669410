import styled from 'styled-components';
import { Color } from '../../../../infrastructure/ui/theming';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FilterContainer = styled.div`
  padding: 10px;
  display: flex;
  gap: 10px;
`;

export const Menu = styled.ul`
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: end;
`;

export const MenuItem = styled.li`
  color: white;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  &.active {
    border-bottom: 4px solid orange;
    padding-bottom: 6px;
  }
`;

export const Wrapper = styled.div`
  background-color: ${Color.PRIMARY_DARK};
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 40px;
`;
