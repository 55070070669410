import { withProps } from '../../../libs/react-utils';
import RateMeter from '../RateMeter';

export default withProps(
  {
    breakpoints: {
      0: '#fa7470',
      1: '#f5a44d',
      4: '#fcd908',
      6: '#c3dc3d',
      8: '#54c242',
      10: '#ff85dc',
    },
    targetValue: 0.1,
  },
  RateMeter
);
