import React, { useState, useMemo } from 'react';
import { IconButton, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import { Search, Clear } from '@material-ui/icons';
import { fetchEventsData } from '../../api';
import { toast } from '../../../../../../redux/actions/UI';

const InputSearch = ({ messageId, setLoading, setRows }) => {
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const debounceTimeout = 800;

  const fetchEvents = async text => {
    try {
      setLoading(true);

      const search = text ? { search: text } : {};
      const data = await fetchEventsData({ messageId, ...search });
      const rows = data?.activities?.reverse().map(clickActivity => ({
        _id: `${clickActivity.link}-${clickActivity.index}`,
        link: clickActivity.link,
        linkIndex: clickActivity.linkIndex + 1,
        totalClicks: clickActivity.totalClicks,
        uniqueClicks: clickActivity.uniqueClicks,
      }));

      setLoading(false);
      setRows(rows);
    } catch (e) {
      console.error(e);
      dispatch(toast('error', 'Failed to fetch events data'));
    }
  };

  const handleSearch = useMemo(() => {
    const fetchData = async () => {
      await fetchEvents(searchText);
    };

    return debounce(fetchData, debounceTimeout);
  }, [fetchEvents, debounceTimeout]);

  const handleClear = () => {
    setSearchText('');
    fetchEvents('');
  };

  const handleChange = e => {
    const text = e.target.value;

    const fetchData = async () => {
      await fetchEvents(text);
    };

    setSearchText(text);
    debounce(fetchData, debounceTimeout)();
  };

  return (
    <TextField
      label="Quick Search"
      variant="outlined"
      size="small"
      value={searchText}
      onChange={handleChange}
      InputProps={{
        style: { width: '250px' },
        endAdornment: (
          <>
            {searchText && (
              <IconButton size="small" onClick={handleClear}>
                <Clear fontSize="small" />
              </IconButton>
            )}
            <IconButton size="small" onClick={handleSearch}>
              <Search fontSize="small" />
            </IconButton>
          </>
        ),
      }}
    />
  );
};

export default InputSearch;
