import React, { useEffect, useState } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { TimeZoneStyled } from './timezone.styled';

const zones = [
  { value: 'PST', label: 'PST' },
  { value: 'EST', label: 'EST' },
  { value: 'CST', label: 'CST' },
  { value: 'MST', label: 'MST' },
];

const zonesInfo = {
  PST: { zone: 'PST', vsUTC: 8, region: 'America/Los_Angeles' },
  EST: { zone: 'EST', vsUTC: 5, region: 'America/New_York' },
  CST: { zone: 'CST', vsUTC: 6, region: 'America/Chicago' },
  MST: { zone: 'MST', vsUTC: 7, region: 'America/Phoenix' },
};

export const TimeZoneDropdown = ({ handleChange, timezone }) => {
  const [tz, setTimezone] = useState({
    zone: '',
    vsUTC: 0,
    region: '',
  });

  const handleZoneChange = event => {
    const { value } = event.target;
    const defaultZone = {
      zone: value,
      vsUTC: 0,
      region: '',
    };
    const tz = zonesInfo[value] ?? defaultZone;
    setTimezone(tz);
    handleChange && handleChange(tz);
  };

  useEffect(() => {
    timezone && setTimezone(timezone);
  }, [timezone]);

  return (
    <TimeZoneStyled fullWidth>
      <label>Time Zone</label>
      <Select
        value={tz.zone}
        onChange={handleZoneChange}
        size="small"
        variant="outlined"
      >
        {zones.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </TimeZoneStyled>
  );
};
