import styled from 'styled-components';

export const ExportButton = styled.button`
  color: #6c7075;
  font-size: 14px;
  min-width: 120px;
  border: 1px solid #d4d7d9;
  border-radius: 4px;
  transition: all 0.3s ease-in;
  background-color: #ffffff;
  &:hover,
  &.active {
    color: white;
    cursor: pointer;
    background-color: #fa9546;
    border: 1px solid #fa9546;
  }
`;
