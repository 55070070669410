import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  loadTopics,
  selectTopics,
  resetMembersList,
} from '../../../../../../../redux/action';
import { getOrganizationTopics } from '../../../../../../../redux/services/groups';
import { TOPICS_SUBMENU_TO_FIELD_MAP } from '../../../../constants';

const Topics = ({
  topics,
  selectedTopicsList,
  orgId,
  actions,
  selectedSubMenuItemName,
}) => {
  const columns = ['Topic', 'Subscribers', 'Description'];
  const rowNames = ['name', 'subscribedMembers', 'description'];
  const flex = [0.6, 0.3, 1];

  const generateRows = () =>
    topics
      .filter(topic => {
        // if 'For Members' submenu is active then show only those topics that are relevant for members only
        // same logic applies if 'For Nonmembers' submenu is active
        return topic.availableTo[
          TOPICS_SUBMENU_TO_FIELD_MAP[selectedSubMenuItemName]
        ];
      })
      .map(row => {
        const generatedRow = { id: row.id };
        rowNames.forEach((name, index) => {
          generatedRow[columns[index]] = row[name];
        });
        return generatedRow;
      });

  const [selectedTopics, setSelectedTopics] = useState(selectedTopicsList);
  const [generatedRows, setGeneratedRows] = useState(generateRows());
  const [topicIdsMapInCurrentSubMenu, setTopicIdsMapInCurrentSubMenu] =
    useState({});

  const loadTopics = async () => {
    const fetchedTopics = await getOrganizationTopics(orgId);
    actions.loadTopics(fetchedTopics);
  };

  useEffect(() => {
    const rows = generateRows();
    setGeneratedRows(rows);
    const topicIdsMap = {};
    rows.forEach(row => {
      topicIdsMap[row.id] = true;
    });
    setTopicIdsMapInCurrentSubMenu(topicIdsMap);
  }, [topics, selectedSubMenuItemName]);

  useEffect(() => {
    loadTopics();
  }, [orgId]);

  useEffect(() => {
    setSelectedTopics(selectedTopicsList);
  }, [selectedTopicsList]);

  const generatedColumns = columns.map((text, index) => ({
    field: text,
    headerName: text,
    flex: flex[index],
  }));

  const handleItemsSelected = ids => {
    if (topics.length) {
      // filter out all selected topic ids that are not present in current submenu
      // and add them back to the final list so that we do not wipe out previously selected items
      const idsRelatedToOtherCategory = selectedTopics.filter(
        id => !topicIdsMapInCurrentSubMenu[id]
      );
      const updatedIds = new Set([...ids, ...idsRelatedToOtherCategory]);
      setSelectedTopics([...updatedIds]);
      actions.selectTopics([...updatedIds]);
    }
    actions.resetMembersList();
  };

  return (
    <DataGrid
      rows={generatedRows}
      columns={generatedColumns}
      pageSize={15}
      rowsPerPageOptions={[15]}
      checkboxSelection
      disableSelectionOnClick
      onSelectionModelChange={ids => handleItemsSelected(ids)}
      selectionModel={selectedTopics}
    />
  );
};

const mapStateToProps = state => ({
  topics: state.topics.sort((a, b) => a.name.localeCompare(b.name)),
  selectedTopicsList: state.audience.selectedTopicsList,
  orgId: state.organization.selected.id,
  selectedSubMenuItemName: state.audience.selectedSubMenuItemName,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { loadTopics, selectTopics, resetMembersList },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Topics);
