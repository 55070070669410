import React from 'react';
import CardABversion from './CardABversion';

const AllChannelsABversion = ({
  emailEvents,
  generateAverage,
  generateRatio,
  textEvents,
  isDataAvailable,
  smartResendEmailEvents,
  isSmartResendAvailable,
}) => {
  const calcSmartResendClickThroughRateForVersionA = () => {
    const oldRatio = generateRatio(
      emailEvents.uniqueclick,
      emailEvents.uniqueopen
    );

    const newRatio = generateRatio(
      emailEvents.uniqueclick + smartResendEmailEvents.emailEventsA.uniqueclick,
      emailEvents.uniqueopen + smartResendEmailEvents.emailEventsA.uniqueopen
    );

    return parseInt(newRatio) - parseInt(oldRatio) + '%';
  };

  const calcSmartResendClickThroughRateForVersionB = () => {
    const oldRatio = generateRatio(
      emailEvents.versionB.uniqueclick,
      emailEvents.versionB.uniqueopen
    );

    const newRatio = generateRatio(
      emailEvents.versionB.uniqueclick +
        smartResendEmailEvents.emailEventsB.uniqueclick,
      emailEvents.versionB.uniqueopen +
        smartResendEmailEvents.emailEventsB.uniqueopen
    );

    return parseInt(newRatio) - parseInt(oldRatio) + '%';
  };

  return (
    <>
      <CardABversion
        name="Deliveries"
        valueA={emailEvents.delivered + textEvents.delivered}
        valueB={emailEvents.versionB.delivered + textEvents.versionB.delivered}
        smartResendValueA={
          smartResendEmailEvents?.emailEventsA
            ? smartResendEmailEvents.emailEventsA.delivered
            : null
        }
        smartResendValueB={
          smartResendEmailEvents?.emailEventsB
            ? smartResendEmailEvents.emailEventsB.delivered
            : null
        }
        generateAverage={generateAverage}
        title="Number of unique recipients delivered to."
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
      />
      <CardABversion
        name="Delivery Rate"
        valueA={generateRatio(
          emailEvents.delivered + textEvents.delivered,
          emailEvents.sent + textEvents.sent
        )}
        valueB={generateRatio(
          emailEvents.versionB.delivered + textEvents.versionB.delivered,
          emailEvents.versionB.sent + textEvents.versionB.sent
        )}
        smartResendValueA={
          smartResendEmailEvents?.emailEventsA
            ? generateRatio(
                smartResendEmailEvents.emailEventsA.delivered,
                smartResendEmailEvents.emailEventsA.sent
              )
            : null
        }
        smartResendValueB={
          smartResendEmailEvents?.emailEventsB
            ? generateRatio(
                smartResendEmailEvents.emailEventsB.delivered,
                smartResendEmailEvents.emailEventsB.sent
              )
            : null
        }
        generateAverage={generateAverage}
        title="The average of your email and text successful deliveries."
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
      />
      <CardABversion
        name="Open Rate"
        valueA={generateRatio(
          emailEvents.uniqueopen +
            (smartResendEmailEvents?.emailEventsA?.uniqueopen || 0),
          emailEvents.delivered
        )}
        valueB={generateRatio(
          emailEvents.versionB.uniqueopen +
            (smartResendEmailEvents?.emailEventsB?.uniqueopen || 0),
          emailEvents.versionB.delivered
        )}
        smartResendValueA={
          smartResendEmailEvents?.emailEventsA
            ? generateRatio(
                smartResendEmailEvents.emailEventsA.uniqueopen,
                emailEvents.delivered
              )
            : null
        }
        smartResendValueB={
          smartResendEmailEvents?.emailEventsB
            ? generateRatio(
                smartResendEmailEvents.emailEventsB.uniqueopen,
                emailEvents.versionB.delivered
              )
            : null
        }
        generateAverage={generateAverage}
        title="The average of your email unique open rates."
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
        showComparativeAnalytics
      />
      <CardABversion
        name="Click-Through Rate"
        valueA={generateRatio(
          emailEvents.uniqueclick +
            (smartResendEmailEvents?.emailEventsA?.uniqueclick || 0),
          emailEvents.uniqueopen +
            (smartResendEmailEvents?.emailEventsA?.uniqueopen || 0)
        )}
        valueB={generateRatio(
          emailEvents.versionB.uniqueclick +
            (smartResendEmailEvents?.emailEventsB?.uniqueclick || 0),
          emailEvents.versionB.uniqueopen +
            (smartResendEmailEvents?.emailEventsB?.uniqueopen || 0)
        )}
        smartResendValueA={
          smartResendEmailEvents?.emailEventsA
            ? calcSmartResendClickThroughRateForVersionA()
            : null
        }
        smartResendValueB={
          smartResendEmailEvents?.emailEventsB
            ? calcSmartResendClickThroughRateForVersionB()
            : null
        }
        generateAverage={generateAverage}
        title="The average of your email and text click-to-open rates."
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
        showComparativeAnalytics
      />
    </>
  );
};

export default AllChannelsABversion;
