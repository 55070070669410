import React from 'react';
import { ArrowForward } from '@material-ui/icons';
import {
  Container,
  Title,
  Text,
  Column,
  Button,
  CelebrationGif,
} from './Step.styled';
import celebration from './img/celebration.gif';

const Success = ({ onClick }) => {
  return (
    <Container>
      <Column>
        <br />
        <Title>You&apos;re all set to support your members via text!</Title>
        <Text>
          You can now view and reply to text messages your members have sent in.
          Our team will reach out shortly to upgrade your account so that you
          can use your main office number for all sent and received texts.
          <br />
          <br />
          Additionally, you can now set up unlimited custom text keywords to
          help automatically support contacts with important information 24/7.
          <br />
          <br />
          Ready to start?
        </Text>
        <br />
        <Button onClick={onClick}>
          Jump right in! <ArrowForward />
        </Button>
      </Column>
      <CelebrationGif src={celebration} alt="Success!" />
    </Container>
  );
};

export default Success;
