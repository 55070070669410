import { Table, Tooltip } from 'antd';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../Analytics/utils';

import * as services from '../../redux/services';

const cutChars = (string, record) => {
  const charLimit = 26;
  if (record.eventName === 'dropped') {
    return 'Previously Bounced Email';
  } else if (string.length <= charLimit) {
    return string;
  }
  const newString = string.substring(0, charLimit - 1);
  return <Tooltip title={string}>{`${newString}...`}</Tooltip>;
};

const SnBTable = props => {
  const [reports, setReports] = useState([]);
  const [orgId, setOrgId] = useState(props.orgId);

  const dataColumns = [
    {
      title: 'Event',
      dataIndex: 'eventName',
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
    },
    {
      title: 'Time/Date',
      dataIndex: 'time',
    },
    {
      title: 'Reason',
      dataIndex: 'errorMessage',
      render: (text, record) => cutChars(text, record),
    },
  ];

  useEffect(() => {
    const date = moment(Date.now()).subtract(7, 'days').format('YYYY-MM-DD');
    const getEvnt = async (date, orgId) => {
      const res = await services.getRangeOfSummaries(date, orgId);
      const filteredErrors = [
        'spamreport',
        'bounce',
        'dropped',
        'undelivered',
        'failed',
      ];
      const filteredGoods = res.errors.filter(error =>
        filteredErrors.includes(error.eventName)
      );

      const formattedEventNames = filteredGoods.map(event => {
        const { bounceType } = event;
        if (!bounceType)
          event.eventName =
            event.eventName === 'spamreport'
              ? 'Spam'
              : capitalizeFirstLetter(event.eventName);
        else {
          if (bounceType === 'bounce') event.eventName = 'Permanent Bounce';
          else event.eventName = 'Temporary Bounce';
        }
        return event;
      });

      const noDuplicates = Object.values(
        formattedEventNames.reduce((unique, obj, index) => {
          if (!unique[obj.contact]) {
            unique[obj.contact] = obj;
          } else if (
            unique[obj.contact] &&
            unique[obj.contact].eventName !== obj.eventName
          ) {
            unique[`${obj.contact + index}`] = obj;
          }
          return unique;
        }, {})
      );
      noDuplicates.reverse();

      setReports(noDuplicates);
    };
    setOrgId(props.orgId);
    if (props.orgId) {
      getEvnt(date, orgId);
    }
  }, [orgId, props.orgId]);

  return (
    <Table
      columns={dataColumns}
      dataSource={reports}
      size="small"
      pagination={{ pageSize: 5, hideOnSinglePage: true }}
    />
  );
};

export default SnBTable;
