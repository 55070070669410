import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authUserCheck } from '../redux/action';
import { useLocation, useHistory } from 'react-router-dom';

const useUserAuth = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      authUserCheck({
        location,
        history,
      })
    );
  }, []);
};

export default useUserAuth;
