import { Group } from '@material-ui/icons';
import React from 'react';

export const Nonmember = () => {
  return (
    <>
      <Group />
      <span style={{ paddingLeft: '5px' }}>Nonmember</span>
    </>
  );
};
