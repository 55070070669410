import './index.css';
import 'antd/dist/antd.css';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';

import { queryClient } from './infrastructure/queries';
import { setupCssAdapter } from './infrastructure/ui/theming';
import Feature from './infrastructure/features';
import { FeatureToggler } from './libs/feature-toggles';
import { Provider as FeatureTogglerProvider } from './libs/feature-toggles/react';
import App from './App';
import ErrorBoundary from './Pages/ErrorPage';

import { initializeApp } from 'firebase/app';
import { config } from './firebaseConfig';
import store from './redux/store';
import { userDetailSelector } from './redux/selectors/user';
import { selectedOrganizationSelector } from './redux/selectors/organization';

setupCssAdapter();
initializeApp(config);

const FEATURE_TOGGLE_MATCHERS = {
  domain: {
    identify: config => config.startsWith('@'),
    match: (config, { user }) => {
      if (!user.email) {
        return false;
      }

      return user.email.endsWith(config);
    },
  },
  organization: {
    identify: config => config.startsWith('org:'),
    match: (config, { organization }) => organization.id === config.slice(4),
  },
  exact: {
    identify: () => true,
    match: (config, { user }) => user.email === config,
  },
};

const FEATURE_TOGGLE_MATCHERS_ORDERED_BY_PRIORITY = [
  FEATURE_TOGGLE_MATCHERS.domain,
  FEATURE_TOGGLE_MATCHERS.organization,
  FEATURE_TOGGLE_MATCHERS.exact,
];

const isFeatureEnabledFor = (config, context) => {
  return config.some(value => {
    const matcher = FEATURE_TOGGLE_MATCHERS_ORDERED_BY_PRIORITY.find(matcher =>
      matcher.identify(value)
    );
    return matcher.match(value, context);
  });
};

const featureToggler = new FeatureToggler();

const RAW_AC_FEATURE_TOGGLE_CONFIG = process.env.REACT_APP_AC_USERS;
const IS_AC_ENABLED_FOR_ALL = RAW_AC_FEATURE_TOGGLE_CONFIG === '*';
if (IS_AC_ENABLED_FOR_ALL) {
  featureToggler.set(Feature.AUTOMATED_CAMPAIGNS, true);
} else {
  const AC_FEATURE_TOGGLE_CONFIG = (RAW_AC_FEATURE_TOGGLE_CONFIG ?? '')
    .split(',')
    .map(value => value.trim());

  store.observe(
    state => {
      const user = userDetailSelector(state);
      const activeOrganization = selectedOrganizationSelector(state);
      if (!user || !activeOrganization) {
        return false;
      }

      const context = { user, organization: activeOrganization };
      return isFeatureEnabledFor(AC_FEATURE_TOGGLE_CONFIG, context);
    },
    isACEnabled => {
      featureToggler.set(Feature.AUTOMATED_CAMPAIGNS, isACEnabled);
    }
  );
}

const RAW_AB_FEATURE_TOGGLE_CONFIG = process.env.REACT_APP_AB_USERS;
if (RAW_AB_FEATURE_TOGGLE_CONFIG === '*') {
  featureToggler.set(Feature.AB, true);
} else if (RAW_AB_FEATURE_TOGGLE_CONFIG) {
  const AB_FEATURE_TOGGLE_CONFIG = RAW_AB_FEATURE_TOGGLE_CONFIG.split(',').map(
    value => value.trim()
  );

  store.observe(
    state => {
      const user = userDetailSelector(state);
      const activeOrganization = selectedOrganizationSelector(state);
      if (!user || !activeOrganization) {
        return false;
      }

      const context = { user, organization: activeOrganization };
      return isFeatureEnabledFor(AB_FEATURE_TOGGLE_CONFIG, context);
    },
    isABEnabled => {
      featureToggler.set(Feature.AB, isABEnabled);
    }
  );
}

store.observe(
  state => {
    const activeOrganization = selectedOrganizationSelector(state);

    return activeOrganization.eventsEnabled || false;
  },
  isEventsAndClassesEnabled => {
    featureToggler.set(Feature.EVENTS_AND_CLASSES, isEventsAndClassesEnabled);
  }
);

const RAW_TEXTING_HUB_FEATURE_TOGGLE_CONFIG =
  process.env.REACT_APP_TEXTING_HUB_USERS;

if (RAW_TEXTING_HUB_FEATURE_TOGGLE_CONFIG === '*') {
  featureToggler.set(Feature.TEXTING_HUB, true);
} else if (RAW_TEXTING_HUB_FEATURE_TOGGLE_CONFIG) {
  const TEXTING_HUB_FEATURE_TOGGLE_CONFIG =
    RAW_TEXTING_HUB_FEATURE_TOGGLE_CONFIG.split(',').map(value => value.trim());

  store.observe(
    state => {
      const user = userDetailSelector(state);
      const activeOrganization = selectedOrganizationSelector(state);
      if (!user || !activeOrganization) {
        return false;
      }

      const context = { user, organization: activeOrganization };
      return isFeatureEnabledFor(TEXTING_HUB_FEATURE_TOGGLE_CONFIG, context);
    },
    isTextingHubEnabled => {
      featureToggler.set(Feature.TEXTING_HUB, isTextingHubEnabled);
    }
  );
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  denyUrls: ['http://localhost:3000'], //So that our dev env's dont throw unneeded errors
  integrations: [new Integrations.BrowserTracing()],
  ignoreErrors: [
    'ResizeObserver loop limit exceeded', //Ignore error that doesn't affect anything as far as we know
  ],
  tracesSampleRate: 0.25, //Reduces performance metric logging to 25%
});

ReactDOM.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <FeatureTogglerProvider featureToggler={featureToggler}>
        <App />
      </FeatureTogglerProvider>
    </QueryClientProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);
