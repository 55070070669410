import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Dialog, DialogContent } from '@material-ui/core';
import DialogTitle from './dialogComponents/dialogTitle.styled';
import DialogActions from './dialogComponents/dialogActions.styled';
import SolidButton from './solidBtn';

function ApplyStandAloneAudienceDialog({ isOpen, close }) {
  const history = useHistory();
  const location = useLocation();
  const { draft: draftId, audienceId } = queryString.parse(location.search);

  const handleApplyDialog = () => {
    const query = draftId ? `draft=${draftId}` : `audienceId=${audienceId}`;
    history.push(`/dashboard/drafts?apply=1&tab=audiences&${query}`);
  };

  const closeDialogBox = () => {
    close();
  };

  return (
    <>
      <Dialog open={isOpen} onClose={closeDialogBox}>
        <DialogTitle>Applying a Standalone Audience</DialogTitle>
        <DialogContent>
          <p style={{ paddingTop: '20px' }}>
            Applying a standalone audience will overwrite your current audience.
          </p>
        </DialogContent>
        <DialogActions>
          <SolidButton onClick={closeDialogBox} color="grey">
            Cancel
          </SolidButton>
          <SolidButton onClick={handleApplyDialog}>Ok</SolidButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ApplyStandAloneAudienceDialog;
