import React, { useState, useEffect } from 'react';
import { Title, Row } from '../UploadContactDrawerStepTwo/StepTwo.styled';
import { ErrorMsg, Table, Header, Data } from './Results.styled';

const Results = ({ results }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (results.errorMsgs) {
      const data = results.errorMsgs.map(({ code, notes }) => ({
        code,
        notes,
      }));
      setRows(data);
    }
  }, [results]);

  return (
    <span>
      <Row>
        <Title size="17px">Upload Results</Title>
      </Row>
      <Row>
        {results.erroredImport ? (
          <ErrorMsg>
            Failed to upload contacts due to an unspecified error. Please
            contact NoteRouter support via the &ldquo;Help&ldquo; button or at
            support@noterouter.com to assist you with this upload.
          </ErrorMsg>
        ) : (
          ''
        )}
        {results.totalAdded ? (
          <span>
            {results.totalAdded}{' '}
            {results.totalAdded > 1 || results.totalAdded === 0
              ? 'contacts were'
              : 'contact was'}{' '}
            successfully added.
          </span>
        ) : (
          ''
        )}
        {results.totalFailed ? (
          <>
            <ErrorMsg>Exceptions:</ErrorMsg>
            <span>
              {results.totalFailed}{' '}
              {results.totalFailed > 1 || results.totalFailed === 0
                ? 'contacts'
                : 'contact'}{' '}
              had issues that are noted below:
            </span>
          </>
        ) : (
          ''
        )}
      </Row>
      {rows.length ? (
        <Table>
          <thead>
            <tr>
              <Header>Code</Header>
              <Header>Notes</Header>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <Data>{row.code}</Data>
                <Data>{row.notes}</Data>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        ''
      )}
    </span>
  );
};
export default Results;
