import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userDetailSelector } from '../../../redux/selector';
import {
  toast,
  resetUnreadCount,
  useUpdateUIState,
} from '../../../redux/action';
import {
  NotInterested,
  SettingsBackupRestoreOutlined,
} from '@material-ui/icons';
import {
  updateMessageStatus,
  saveTextMessage,
  getUnreadTextCount,
} from '../api';
import { Button } from './TextingHub.styled';
import Modal from '../../../../src/Component/Modal';

const SpamBtn = ({
  textMessages,
  setTextMessages,
  selectedIds,
  isTextingHubDisabled,
  status,
  resetSelectedIds,
}) => {
  const dispatch = useDispatch();
  const userDetails = useSelector(userDetailSelector);
  const { fullLoader } = useUpdateUIState();

  const ACTIVE = 'active';
  const SPAMMED = 'spammed';
  const NEW_STATUS = status === ACTIVE ? SPAMMED : ACTIVE;

  const [isConfirmSpamModalVisible, setConfirmSpamModalVisible] =
    useState(false);

  const transformTextMessage = message => {
    let systemMessage = `Conversation flagged as spam by ${userDetails.firstName} ${userDetails.lastName}.`;
    if (NEW_STATUS !== 'spammed') {
      systemMessage = `Conversation removed from spam by ${userDetails.firstName} ${userDetails.lastName}.`;
    }

    const textMessage = {
      senderType: 'org',
      organizationId: message.organizationId,
      userId: null,
      body: '',
      systemMessage,
      numSegments: 0,
      orgPhone: message.orgPhone,
      contactPhone: message.contactPhone,
      conversationId: message._id,
    };

    return textMessage;
  };

  const handleSpam = async () => {
    if (isTextingHubDisabled) return;
    setConfirmSpamModalVisible(false);
    try {
      fullLoader(true, `Marking messages as ${NEW_STATUS}`);
      const selectedMessages = [];

      const updatedTextMessages = textMessages.filter(message => {
        if (selectedIds.includes(message._id)) {
          selectedMessages.push(message);
          return false;
        }
        return true;
      });
      const transformedSelectedMsgs = selectedMessages.map(message =>
        transformTextMessage(message)
      );

      await updateMessageStatus(selectedIds, NEW_STATUS);

      await saveTextMessage(transformedSelectedMsgs);

      if (NEW_STATUS === 'spammed') {
        const unreadCount = await getUnreadTextCount();
        dispatch(resetUnreadCount(unreadCount));
      }

      setTextMessages(updatedTextMessages);
      const successStatusText =
        status === ACTIVE
          ? 'Successfully spammed messages'
          : 'Successfully unspammed messages';
      dispatch(toast('success', successStatusText));
      resetSelectedIds();
      fullLoader(false);
    } catch (error) {
      const failedStatusText =
        status === ACTIVE
          ? 'Failed to spam messages'
          : 'Failed to unspam messages';
      dispatch(toast('error', failedStatusText));
      fullLoader(false);
    }
  };

  const getText = () => {
    let matchedContactsExist = false;
    let text = `Are you sure you want to send ${selectedIds.length} conversation(s) to Spam?`;
    for (let message of textMessages) {
      if (
        selectedIds.includes(message._id) &&
        (message?.contact?.firstName || message?.contact?.lastName)
      ) {
        matchedContactsExist = true;
        break;
      }
    }

    if (matchedContactsExist) {
      text =
        'One or more of the selected conversations belongs to a known contact. Are you sure you want to mark them as spam?';
    }

    return text;
  };

  return (
    <>
      {status === SPAMMED && (
        <Button
          color={'#fc9e3a'}
          disabled={!selectedIds.length}
          onClick={handleSpam}
        >
          <SettingsBackupRestoreOutlined
            style={{ fontSize: '18px', marginRight: '5px' }}
          />
          Unspam
        </Button>
      )}
      {status === ACTIVE && (
        <Button
          color={'#ff6161'}
          disabled={!selectedIds.length}
          onClick={() => {
            if (isTextingHubDisabled) return;
            setConfirmSpamModalVisible(true);
          }}
        >
          <NotInterested style={{ fontSize: '18px', marginRight: '5px' }} />
          Spam
        </Button>
      )}
      {isConfirmSpamModalVisible && (
        <Modal
          title="Marking conversation as Spammed"
          primaryButton={{ label: 'Yes, spam', onClick: handleSpam }}
          onClose={() => setConfirmSpamModalVisible(false)}
        >
          {getText()}
        </Modal>
      )}
    </>
  );
};

export default SpamBtn;
