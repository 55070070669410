import socketIOClient from 'socket.io-client';

export const connectClient = (
  userId,
  connectionType,
  orgId,
  connectionUrl = process.env.REACT_APP_andromedaAPI
) => {
  if (connectionUrl === process.env.REACT_APP_andromedaAPI) {
    const socket = socketIOClient(connectionUrl);
    socket.on('connect', () => {
      socket.emit('initializeUserSession', {
        userId: userId,
        type: connectionType,
      });
    });
    return socket;
  } else {
    const socket = socketIOClient(connectionUrl, {
      transports: ['websocket', 'polling', 'flashsocket'], //TODO remove flashsocket and replace xhr-polling
      query: {
        userId,
        type: connectionType,
        orgId,
      },
    });
    return socket;
  }
};
