import styled from 'styled-components';
import { InfoOutlined } from '@material-ui/icons';
import BaseStep from '../Step';
import { TextField } from '../../forms';

export const Step = styled(BaseStep)`
  display: flex;
  gap: 185px;
`;

export const DescriptionArea = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 450px;
  color: #616161;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
`;

DescriptionArea.Icon = styled(InfoOutlined)`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-100%, -50%);
  color: #f86f00;
  font-size: 20px !important;
`;

DescriptionArea.Paragraph = styled.p`
  font: inherit;
  margin-bottom: 0;
`;

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 680px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

Section.Header = styled.div`
  display: grid;
  align-items: center;
  grid-template-areas: 'icon title';
  grid-template-columns: auto 1fr;
  grid-auto-rows: auto;
  gap: 15px 10px;
  color: #007ade;
`;

Section.Icon = styled.span`
  grid-area: icon;
`;

Section.Title = styled.b`
  grid-area: title;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
`;

Section.Description = styled.span`
  grid-column: 1 / -1;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #616161;
`;

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const AudienceField = styled(TextField)`
  .MuiInputBase-root {
    font-weight: 600;
  }
`;
