import React, { useState } from 'react';
import { AddCircleOutline } from '@material-ui/icons';
import { Modal } from '@material-ui/core';
import { Wrap, Button, Highlight, ModalBox, Center } from './Footer.styled';
import ContentForFilters from './contentForFilters';
import { DESIGNATION_SHOULD_EXIST } from '../../Content/Components/Filters/Components/_shared/constants';

const nameMapper = {
  [DESIGNATION_SHOULD_EXIST]: 'Any/All Member Designations.',
};

const FilterStats = ({ Icon, color, text, filters, backColor }) => {
  const [open, setOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState('');

  const handleOpen = name => {
    setActiveFilter(name);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getHighlightedData = () => {
    const listOfNames = Object.keys(filters);

    return listOfNames.map((name, index) => {
      const wordDelimiter = index !== listOfNames.length - 1 ? 'and' : '';
      const displayName = nameMapper[name] ?? name;
      return (
        <b key={name}>
          <Button onClick={() => handleOpen(name)} backColor={backColor}>
            {displayName}{' '}
            <AddCircleOutline
              style={{ fontSize: 14, position: 'absolute', top: 2, right: 4 }}
            />
          </Button>
          {wordDelimiter}
          <Modal open={open} onClose={handleClose} aria-labelledby={name}>
            <ModalBox style={{ overflow: 'scroll', maxHeight: '240px' }}>
              <Center>
                <Highlight
                  padding="2px 10px"
                  color="#5EAB00"
                  backColor="#E6EAE2"
                >
                  {nameMapper[activeFilter] ?? activeFilter}
                </Highlight>
              </Center>
              <ContentForFilters filterName={activeFilter} />
            </ModalBox>
          </Modal>
        </b>
      );
    });
  };

  return (
    <Wrap>
      <Icon style={{ color: color, fontSize: 16, marginRight: 7 }} />
      {text}: {getHighlightedData()}
    </Wrap>
  );
};

export default FilterStats;
