import React from 'react';
import { TextField } from '@material-ui/core';

const SingleMessageDetails = ({
  message,
  classes,
  phoneNumber,
  isSuperUser,
}) => {
  return (
    <>
      {isSuperUser && ( //Display message _id for super users - helpful for debugging and auditing.
        <TextField
          className={classes.textField}
          disabled
          label="ID"
          variant="outlined"
          size="small"
          value={message.id}
          style={{ width: 'auto' }}
        />
      )}
      <TextField
        className={classes.textField}
        disabled
        label="From Name"
        variant="outlined"
        size="small"
        value={message.sender || 'N/A'}
        style={{ width: '50%' }}
      />
      <TextField
        className={classes.textField}
        disabled
        label="From Phone"
        variant="outlined"
        size="small"
        value={phoneNumber || 'Error - Invalid Phone Number'}
        inputProps={{
          style: {
            color: !phoneNumber && '#FF6161',
          },
        }}
        style={{ width: '50%' }}
      />
      <TextField
        className={classes.textField}
        disabled
        label="From Email"
        variant="outlined"
        size="small"
        value={message.from || 'N/A'}
        style={{ width: '50%' }}
      />
      <TextField
        className={classes.textField}
        disabled
        label="Subject"
        variant="outlined"
        size="small"
        value={message.messageSubject || 'N/A'}
        style={{ width: '50%' }}
      />
      <TextField
        className={classes.textField}
        disabled
        label="Preheader"
        variant="outlined"
        size="small"
        value={message.preheader || 'N/A'}
        style={{ width: '50%' }}
      />
    </>
  );
};

export default SingleMessageDetails;
