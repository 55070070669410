import styled from 'styled-components';

const Box = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  width: 185px;
`;

export default Box;
