import React from 'react';
import { CircularProgress } from '@material-ui/core';

import { InfiniteScroll } from '../../../../../libs/react-infinite-scroll';
import { Container, FullLengthContainer } from '../TemplatePicker.styled';

const TemplateList = ({
  isLoaderVisible,
  fixedCards,
  data,
  TemplateCard,
  onEdit,
  onLoadNext,
}) => {
  return (
    <>
      <InfiniteScroll
        data={data}
        renderList={children => {
          return (
            <Container>
              {fixedCards}
              {children}
            </Container>
          );
        }}
        renderItem={template => {
          return (
            <TemplateCard
              template={template}
              onClick={() => {
                onEdit(template._id);
              }}
            />
          );
        }}
        getKey={template => template._id}
        onLoadNext={onLoadNext}
      />
      {isLoaderVisible && (
        <FullLengthContainer>
          <CircularProgress size={50} />
        </FullLengthContainer>
      )}
    </>
  );
};

TemplateList.defaultProps = {
  fixedCards: [],
  onLoadNext: () => {},
  isLoaderVisible: false,
};

export default TemplateList;
