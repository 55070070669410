import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  ArrowBackIos,
  Visibility,
  Brush,
  Send,
  Alarm,
} from '@material-ui/icons';
import { TextField, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TargetedAudience from '../../Sendmessage/Audience/Components/Footer/TargetedAudience';
import { fullloader, toast } from '../../../redux/action';
import {
  selectedOrganizationSelector,
  userDetailSelector,
} from '../../../redux/selector';
import {
  saveNewDraftService,
  mergeABdraftsService,
} from '../../../redux/services';
import { formatPhoneNumber } from '../utils';
import {
  Container,
  Background,
  Wrapper,
  Title,
  Menu,
  Header,
  Button,
  Wrap,
  Center,
  MenuItem,
  EventIdMessageWrapper,
  Highlight,
  SpaceBetween,
  ScheduledResend,
  Underline,
  CancelBtn,
} from './MessageDetails.styled';
import ABmessageDetails from './ABmessageDetails';
import SingleMessageDetails from './SingleMessageDetails';
import Feature from '../../../infrastructure/features';
import { useIsFeatureEnabled } from '../../../libs/feature-toggles/react';
import EventIdMessageUpdateSelector from '../EventIdMessageUpdateSelector';
import ClickActivity from './ClickActivity/ClickActivity';
import SmartResendDrawer from './SmartResendDrawer';
import { cancelSmartResend } from './SmartResendDrawer/api';

const statusToColor = {
  Sent: '#5eab00',
  Scheduled: '#f86f00',
  Cancelled: '#ff6161',
};

const useStyles = makeStyles(() => ({
  textField: {
    '& input:disabled': {
      color: '#212121',
    },
  },
}));

const MessageDetails = ({
  message,
  isSuperUser,
  topics,
  groups,
  isLoading,
  isClickActivityVisible,
  setClickActivityVisibility,
  setSelectedMessage,
  addStatusesToMessage,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const organization = useSelector(selectedOrganizationSelector);
  const user = useSelector(userDetailSelector);
  const classes = useStyles();
  const phoneNumber = formatPhoneNumber(organization.fromPhone);
  const [eventId, setEventId] = useState(null);
  const [isSmartResendDrawerVisible, setSmartResendDrawerVisibility] =
    useState(false);
  const [isSmartResendAvailable, setSmartResendAvailability] = useState(false);
  const [hasAlreadyUsedSmartResend, setAlreadyUsedSmartResend] =
    useState(false);
  const [smartResendScheduledDate, setSmartResendScheduledDate] =
    useState(null);
  const [smartResendScheduledDateAndTime, setSmartResendScheduledDateAndTime] =
    useState(null);
  const [isCancellingSmartResend, setCancellingSmartResend] = useState(false);

  const eventIdSelectionRef = useRef();
  const isEventsAndClassesFeatureEnabled = useIsFeatureEnabled(
    Feature.EVENTS_AND_CLASSES
  );
  const isABmessage = message && message.AB && message.AB.linkedMessageId;

  useEffect(() => {
    // if message was sent out within a week, there is still a chance to resend email to non-openers
    if (message?.sendTime) {
      const sendTimeInMilliseconds = message.sendTime * 1000;
      const sendTime = moment(sendTimeInMilliseconds);
      const tenDaysAgo = moment().subtract(10, 'days');
      setSmartResendAvailability(sendTime.isAfter(tenDaysAgo));
    }

    // if resend was already sent then there is no option to resend it again
    if (
      message?.smartResendScheduleTime &&
      moment(message.smartResendScheduleTime * 1000).isBefore(moment())
    ) {
      setAlreadyUsedSmartResend(true);
    } else {
      setAlreadyUsedSmartResend(false);
    }

    if (message?.smartResendScheduleTime) {
      const date = moment(message.smartResendScheduleTime * 1000).format(
        'MM/DD/YY'
      );
      const dateAndTime = moment(message.smartResendScheduleTime * 1000).format(
        'MM/DD/YY [at] hh:mm a'
      );
      setSmartResendScheduledDate(date);
      setSmartResendScheduledDateAndTime(dateAndTime);
    } else {
      setSmartResendScheduledDate(null);
      setSmartResendScheduledDateAndTime(null);
    }
  }, [message]);

  useEffect(() => {
    setEventId(message?.eventId);
  }, [message?.eventId]);

  const convertMessageToDraft = message => {
    const draft = {
      name: message.draftName,
      subject: message.messageSubject,
      sender: message.from,
      senderName: message.sender,
      replyTo: message.replyTo,
      preheader: message.preheader,
      html: message.messageBody,
      design: message.messageDesign,
      saveTime: moment().unix(),
      userId: user.id,
      organizationId: organization.id,
      topicIds: message.topicIds || [],
      selectedGroupIds: message.selectedGroupIds || [],
      requiredGroupIds: message.requiredGroupIds || [],
      excludedGroupIds: message.excludedGroupIds || [],
      includedMemberIds: message.includedMemberIds || [],
      excludedMemberIds: message.excludedMemberIds || [],
      filterData: { ...(message.filterData || {}) },
      status: 'Active',
    };

    return draft;
  };

  const editMessage = async () => {
    dispatch(fullloader(true, 'Loading edited message...'));
    const draftData = convertMessageToDraft(message);
    const draft = await saveNewDraftService(draftData);
    if (isABmessage) {
      const draftBdata = convertMessageToDraft({
        ...message.versionB,
        draftName: draft.name,
      });

      await mergeABdraftsService(draft._id, draftBdata);
    }
    dispatch(fullloader(false));
    history.push(`/dashboard/sendmessage?draft=${draft._id}`);
  };

  const smartResend = () => {
    setSmartResendDrawerVisibility(true);
  };

  const viewMessage = () => {
    window.open(
      `${process.env.REACT_APP_webUrl}/message?id=${message.id}`,
      '_blank'
    );

    if (isABmessage) {
      window.open(
        `${process.env.REACT_APP_webUrl}/message?id=${message.versionB.id}`,
        '_blank'
      );
    }
  };

  const getContent = () => {
    if (isLoading) {
      return (
        <Center>
          <CircularProgress
            style={{ margin: 'auto', alignItems: 'center', color: '#007ADE' }}
          />
        </Center>
      );
    }

    // if there is no message selected
    return (
      <Center>
        <ArrowBackIos style={{ color: '#007ADE' }} />
        Select a message
      </Center>
    );
  };

  const handleDownload = (blob, fileName) => {
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL (helps prevent memory leaks)
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const downloadMessageFile = (messageData, fileName) => {
    const messageJson = JSON.stringify(messageData.messageDesign, null, 2);
    const jsonBlob = new Blob([messageJson], { type: 'application/json' });

    const jsonFile = fileName + '.json';
    handleDownload(jsonBlob, jsonFile);

    const htmlBlob = new Blob([messageData.messageBody], {
      type: 'text/plain',
    });
    const htmlFile = fileName + ' (html)' + '.txt';
    handleDownload(htmlBlob, htmlFile);
  };

  const exportMessageDesign = () => {
    const fileName = message.messageSubject;
    downloadMessageFile(message, fileName);

    //If there is a B version, also download the B version
    if (message.versionB._id) {
      const versionBFileName = message.versionB.messageSubject;
      downloadMessageFile(message.versionB, versionBFileName);
    }
  };

  const cancelScheduledSmartResend = async () => {
    try {
      setCancellingSmartResend(true);
      const { updatedMsg } = await cancelSmartResend(message._id);
      setSelectedMessage(addStatusesToMessage(updatedMsg));
      setCancellingSmartResend(false);
      dispatch(toast('success', 'Successfully cancelled a smart resend'));
    } catch (e) {
      dispatch(toast('error', 'Error cancelling a smart resend'));
    }
  };

  return (
    <Container>
      {message ? (
        <Header>
          <Title>1 Message Selected</Title>
          <Menu>
            <MenuItem className="active">Details</MenuItem>
          </Menu>
        </Header>
      ) : (
        <Header />
      )}
      <Background>
        {isLoading || !message ? (
          getContent()
        ) : (
          <Wrapper>
            <SpaceBetween>
              <Wrap style={{ paddingBottom: 5 }}>
                <Button onClick={viewMessage}>
                  View <Visibility fontSize="inherit" />
                </Button>
                <Button onClick={editMessage}>
                  Open in Editor <Brush fontSize="inherit" />
                </Button>
                <Button
                  disabled={
                    !!smartResendScheduledDate ||
                    !isSmartResendAvailable ||
                    hasAlreadyUsedSmartResend
                  }
                  onClick={smartResend}
                >
                  {smartResendScheduledDate
                    ? `Resent on ${smartResendScheduledDate}`
                    : 'Resend to Non-Openers'}
                  <Send fontSize="inherit" />
                </Button>
                {isSuperUser && (
                  <Button onClick={exportMessageDesign}>
                    Export HTML/JSON
                  </Button>
                )}
              </Wrap>
              {isABmessage && <Highlight>(A/B)</Highlight>}
            </SpaceBetween>
            {hasAlreadyUsedSmartResend ||
              (isSmartResendAvailable && smartResendScheduledDateAndTime && (
                <ScheduledResend>
                  <Alarm fontSize="small" style={{ color: '#F97E2B' }} /> Resend
                  to non-openers scheduled:{' '}
                  <Underline>{smartResendScheduledDateAndTime}</Underline>
                  <CancelBtn onClick={cancelScheduledSmartResend}>
                    {isCancellingSmartResend ? (
                      <>
                        Cancelling{' '}
                        <CircularProgress size={16} color="inherit" />
                      </>
                    ) : (
                      'Cancel'
                    )}
                  </CancelBtn>
                </ScheduledResend>
              ))}
            <TextField
              className={classes.textField}
              disabled
              label="Message"
              variant="outlined"
              size="small"
              value={message.draftName || 'N/A'}
            />
            <Wrap>
              <TextField
                className={classes.textField}
                disabled
                label="Status"
                variant="outlined"
                size="small"
                value={message.status || 'N/A'}
                inputProps={{
                  style: { color: statusToColor[message.status] },
                }}
                style={{
                  width: '50%',
                }}
              />
              <TextField
                className={classes.textField}
                disabled
                label="Send Date"
                variant="outlined"
                size="small"
                value={
                  moment.unix(message.sendTime).format('MMMM Do, YYYY') || 'N/A'
                }
                style={{ width: '50%' }}
              />
            </Wrap>
            {isEventsAndClassesFeatureEnabled && (
              <EventIdMessageWrapper>
                <EventIdMessageUpdateSelector
                  isEventIdRequired={true}
                  eventIdSelectionRef={eventIdSelectionRef}
                  eventId={eventId}
                  setEventId={setEventId}
                  message={message}
                />
              </EventIdMessageWrapper>
            )}
            {isABmessage ? (
              <ABmessageDetails
                classes={classes}
                phoneNumber={phoneNumber}
                message={message}
                isSuperUser={isSuperUser}
              />
            ) : (
              <SingleMessageDetails
                classes={classes}
                phoneNumber={phoneNumber}
                message={message}
                isSuperUser={isSuperUser}
              />
            )}
            <TargetedAudience
              message={message}
              topics={topics}
              groups={groups}
            />
          </Wrapper>
        )}
      </Background>
      <ClickActivity
        isClickActivityVisible={isClickActivityVisible}
        setClickActivityVisibility={setClickActivityVisibility}
        initialMessage={message}
      />
      {isSmartResendDrawerVisible && (
        <SmartResendDrawer
          isDrawerVisible={isSmartResendDrawerVisible}
          setDrawerdVisibility={setSmartResendDrawerVisibility}
          message={message}
          setSelectedMessage={setSelectedMessage}
          addStatusesToMessage={addStatusesToMessage}
        />
      )}
    </Container>
  );
};

export default MessageDetails;
