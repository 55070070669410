import React, { useState, useEffect } from 'react';
import { SwipeableDrawer } from '@material-ui/core';
import KeywordsTable from '../KeywordsTable';
import KeywordDrawer from '../KeywordDrawer';
import NewKeywordDrawer from '../NewKeywordDrawer';
import { fetchKeywords } from '../api';

const Keywords = ({
  isNewKeywordDialogOpen,
  setNewKeywordDialogOpen,
  onRowSelected,
  keywords,
  setKeywords,
  orgId,
  keywordStatus,
  setKeywordStatus,
}) => {
  const [isKeywordDrawerOpen, setKeywordDrawerOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [keywordsMap, setKeywordsMap] = useState({});

  useEffect(() => {
    if (keywords?.length) {
      const keywordsMap = {};
      keywords.forEach(keyword => {
        keywordsMap[keyword.keyword] = keyword._id;
      });

      setKeywordsMap(keywordsMap);
    }
  }, [keywords]);

  const getKeywords = async () => {
    setLoading(true);
    setKeywords([]);
    const keywords = await fetchKeywords({ status: keywordStatus });
    setKeywords(keywords);
    setLoading(false);
  };

  useEffect(() => {
    getKeywords();
  }, [orgId, keywordStatus]);

  const toggleKeywordDrawer = isOpen => {
    setKeywordDrawerOpen(isOpen);
  };

  const openKeywordsDrawer = async ({ row: keyword }) => {
    setSelectedKeyword(keyword);
    toggleKeywordDrawer(true);
  };

  return (
    <>
      <KeywordsTable
        onRowSelected={onRowSelected}
        onEventClick={openKeywordsDrawer}
        keywords={keywords}
        isLoading={isLoading}
        setStatus={setKeywordStatus}
        status={keywordStatus}
      />
      <SwipeableDrawer
        anchor="right"
        open={isKeywordDrawerOpen}
        onClose={() => toggleKeywordDrawer(false)}
        onOpen={() => toggleKeywordDrawer(true)}
      >
        <KeywordDrawer
          fetchData={getKeywords}
          onClose={() => toggleKeywordDrawer(false)}
          keywordData={selectedKeyword}
          existingKeywordsMap={keywordsMap}
        />
      </SwipeableDrawer>
      {/* New Keyboad Drawer */}
      <SwipeableDrawer
        anchor="right"
        open={isNewKeywordDialogOpen}
        onClose={() => setNewKeywordDialogOpen(false)}
        onOpen={() => setNewKeywordDialogOpen(true)}
      >
        <NewKeywordDrawer
          fetchData={getKeywords}
          onClose={() => setNewKeywordDialogOpen(false)}
          existingKeywordsMap={keywordsMap}
        />
      </SwipeableDrawer>
    </>
  );
};

export default Keywords;
