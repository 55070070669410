import 'antd/dist/antd.css';
import './CreateTag.css';
import '../../globalcss.css';

import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { Checkbox, Col, Form, Input, Row, Select, Switch, Tooltip } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { fullloader, toast } from '../../redux/actions/UI';
import {
  createGroup,
  resetOrganizationGroup,
  updateContactsWithMandatoryTopic,
  updateMongoGroup,
} from '../../redux/services/groups';
import { topicsSelector, groupTypesSelector } from '../../redux/selectors/tags';
import { userPrivilegesSelector } from '../../redux/selectors/user';
import { selectedOrganizationSelector } from '../../redux/selectors/organization';
import Modal from '../Modal';
import { AddContactsButton, ResetButton } from './buttons.styled';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const { TextArea } = Input;
const Option = Select.Option;

const CreateTag = props => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [organizationId, setOrganizationId] = useState('');
  const [groupTypeList, setGroupTypeList] = useState([]);
  const [taskTypeId, setTaskTypeId] = useState('');
  const [mandatory, setMandatory] = useState(false);
  const [member, setMember] = useState(false);
  const [nonMember, setNonMember] = useState(false);
  const [highlightTopic, setHighlightTopic] = useState(false);
  const [emailOption, setEmailOption] = useState(true);
  const [textOption, setTextOption] = useState(true);
  const [takenNumbers, setTakenNumbers] = useState([]);
  const [totalTopics, setTotalTopics] = useState([]);
  const [valueOfTopic, setValueOfTopic] = useState('');
  const [groupTypeName, setGroupTypeName] = useState('');
  const [GroupName, setGroupName] = useState('');
  const [GroupDescription, setGroupDescription] = useState('');
  const [GroupListCategory, setGroupListCategory] = useState('');
  const [GroupListCode, setGroupListCode] = useState('');
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const { formLayout } = 'vertical';
  const formItemLayout =
    formLayout === 'vertical'
      ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 },
        }
      : null;

  const topicTags = useSelector(topicsSelector);
  const groupTypes = useSelector(groupTypesSelector);
  const userPrivileges = useSelector(userPrivilegesSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const history = useHistory();

  const groupTypesFetch = async creationType => {
    let types = [...(groupTypes || [])];
    let typeInfo = {};
    if (types) {
      types.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        if (b.name.toLowerCase() > a.name.toLowerCase()) return -1;
        return 0;
      });
      if (creationType === 'topics') {
        formRef.current.setFieldsValue({
          groupTypeName: 'Topic',
        });
        typeInfo = types.find(type => type.name === 'Topic');
        types = [];
      } else {
        typeInfo = types.find(type => type.name === 'Custom');
        formRef.current.setFieldsValue({
          groupTypeName: 'Custom',
        });
        types = types.filter(type => {
          if (userPrivileges.isSU) {
            return type.name !== 'Topic';
          } else {
            return type.name === 'Custom';
          }
        });
      }

      setGroupTypeList(types);
      setTaskTypeId(typeInfo?.id || '');
      setGroupTypeName(typeInfo?.name || 'Topic');
    }
  };

  const getTopicCount = async () => {
    if (topicTags) {
      let simpleArray = [];
      let takenNumbers = [];
      let count = 0;
      topicTags.forEach(tag => {
        count++;
        if (tag.priorityNumber) takenNumbers.push(tag.priorityNumber);
        simpleArray.push(count);
      });
      if (!props.data) simpleArray.push(++count);
      setTotalTopics(simpleArray);
      setTakenNumbers(takenNumbers);
    }
  };

  useEffect(() => {
    groupTypesFetch(props.creationType);
    if (props.data && props.data.organizationId) {
      if (formRef.current && props.data) {
        formRef.current.setFieldsValue({
          nametag: props.data.name,
          description: props.data.description,
          groupTypeName: props.data.groupTypeName
            ? props.data.groupTypeName
            : 'Topic',
          listCategory: props.data.listCategory || '',
          listCode: props.data.listCode || '',
          mandatorytype:
            props.data.mandatory === 1 || props.data.mandatory === true
              ? setMandatory(true)
              : setMandatory(false),
          highlightTopic:
            props.data.highlightTopic === 1 ||
            props.data.highlightTopic === true
              ? setHighlightTopic(true)
              : setHighlightTopic(false),
          membertype: props.data?.availableTo?.member
            ? setMember(true)
            : setMember(false),
          nonmembertype: props.data?.availableTo?.nonMember
            ? setNonMember(true)
            : setNonMember(false),
        });
      }
      setOrganizationId(props.data.organizationId);
      setGroupName(props.data.name);
      setGroupDescription(props.data.description);
      setGroupListCategory(props.data.listCategory || '');
      setGroupListCode(props.data.listCode || '');
      setGroupTypeName(
        props.data.groupTypeName ? props.data.groupTypeName : 'Topic'
      );
      setTaskTypeId(props.data.groupTypeId);
      getTopicCount(props.data.organizationId);
      if (
        props.data.editEmailPreference !== undefined &&
        props.data.editTextPreference !== undefined
      ) {
        setEmailOption(props.data.editEmailPreference);
        setTextOption(props.data.editTextPreference);
      }
      if (props.data.priorityNumber) setValueOfTopic(props.data.priorityNumber);
    } else {
      setOrganizationId(props.organizationId);
    }
    window.scrollTo(0, 0);
  }, [props]);

  const handleSubmit = async () => {
    const date = new Date();
    if (props.data && props.data.name) {
      let groupDetail = {
        listTag: groupTypeName === 'ListMaker',
        organizationId: organizationId,
        name: GroupName.trim(),
        groupTypeId: taskTypeId,
        description: GroupDescription,
        enable: 1,
        updatedDate: date.toString(),
        listCategory: GroupListCategory || '',
        listCode: GroupListCode || '',
        id: props.data.id,
      };
      if (props.creationType === 'topics') {
        groupDetail.editEmailPreference = emailOption;
        groupDetail.editTextPreference = textOption;
        groupDetail.priorityNumber = valueOfTopic;
        groupDetail.mandatory = mandatory;
        groupDetail.highlightTopic = highlightTopic;
        delete groupDetail.listTag;
        delete groupDetail.listCategory;
        delete groupDetail.listCode;
        delete groupDetail.groupTypeId;
        groupDetail.availableTo = {
          member,
          nonMember,
        };
      }
      const updateGroupData = {
        oldTag: props.data,
        newTag: groupDetail,
      };
      const updatedTag = await updateMongoGroup(updateGroupData);
      if (groupDetail.mandatory) {
        dispatch(fullloader(true, 'Updating contacts with mandatory topic'));
        props.closeDrawer();
        const addMandatoryPayload = {
          newTag: false,
          tagId: groupDetail.id,
          orgId: groupDetail.organizationId,
        };
        await updateContactsWithMandatoryTopic(addMandatoryPayload);
      }
      if (updatedTag && !updatedTag.error) {
        dispatch(fullloader(true, 'Fetching Groups')); //Need to add this here otherwise there is a gap without a loader, then a random loader appear)s
        props.closeDrawer();
        props.update(organizationId);
        dispatch(toast('success', 'Group Updated.'));
      } else dispatch(toast('error', 'Error updating your group'));
    } else {
      let groupData = {
        listTag: groupTypeName === 'ListMaker',
        organizationId: organizationId,
        delete: false,
        listCategory: GroupListCategory || '',
        listCode: GroupListCode || '',
        name: GroupName.trim(),
        groupTypeId: taskTypeId,
        description: GroupDescription,
        createdDate: date.toString(),
        updatedDate: date.toString(),
        enable: 1,
        id: uuidv4(),
        subscribedMembers: 0,
      };
      if (props.creationType === 'topics') {
        groupData.textSubscribedCount = 0;
        groupData.emailSubscribedCount = 0;
        groupData.editEmailPreference = emailOption;
        groupData.editTextPreference = textOption;
        groupData.priorityNumber = valueOfTopic;
        groupData.mandatory = mandatory;
        groupData.highlightTopic = highlightTopic;
        delete groupData.listTag;
        delete groupData.listCategory;
        delete groupData.listCode;
        delete groupData.type;
        if (!groupData?.availableTo) {
          groupData.availableTo = {
            member,
            nonMember,
          };
        } else {
          groupData.availableTo.member = member;
          groupData.availableTo.nonMember = nonMember;
        }
      }

      if (userPrivileges.manageOrgAdmin) {
        formRef.current.setFieldsValue({
          nametag: '',
          description: '',
          tagtype: '',
          listCategory: '',
          listCode: '',
        });
      } else {
        formRef.current.setFieldsValue({
          nametag: '',
          description: '',
          tagtype: '',
        });
      }

      setMandatory(false);
      const newGroup = await createGroup(groupData);
      if (groupData.mandatory) {
        dispatch(fullloader(true, 'Applying new mandatory topic'));
        const addMandatoryPayload = {
          newTag: true,
          tagId: groupData.id,
          orgId: groupData.organizationId,
        };
        await updateContactsWithMandatoryTopic(addMandatoryPayload);
      }
      if (!newGroup.error) {
        dispatch(fullloader(true, 'Fetching Groups')); //Need to add this here otherwise there is a gap without a loader, then a random loader appears
        props.closeDrawer();
        props.update(organizationId);
        dispatch(toast('success', 'Group added.'));
      } else dispatch(toast('error', 'Error adding your group'));
    }
  };

  const onMandatoryChange = ({ target }) => {
    if (target.checked === true) {
      setMandatory(true);
      if (nonMember === true) {
        setNonMember(false);
      }
      if (member === false) {
        setMember(true);
      }
    } else setMandatory(false);
  };

  const onGroupChange = value => {
    setMember(value.includes('member'));
    setNonMember(value.includes('nonMember'));
    if (mandatory === true && value.includes('nonMember')) {
      setMandatory(false);
    }
  };

  const closeResetModal = () => {
    setIsResetModalOpen(false);
  };

  const changeHighlightTopic = ({ target }) =>
    setHighlightTopic(target.checked);

  const changeGroupType = id => {
    const typeName = groupTypeList.find(type => type.id === id);
    setTaskTypeId(id);
    setGroupTypeName(typeName.name);
  };

  const applyToContacts = data => {
    history.push({
      pathname: 'memberlist',
      data: data,
    });
  };

  const changeEmailOption = e => {
    if (!textOption)
      dispatch(toast('error', 'You must have at least one channel on'));
    else setEmailOption(e);
  };

  const changeTextOption = e => {
    if (!emailOption)
      dispatch(toast('error', 'You must have at least one channel on'));
    else setTextOption(e);
  };

  const checkDisabled = type => {
    return (
      (type === 'email' && emailOption && !textOption) ||
      (type === 'text' && textOption && !emailOption)
    );
  };

  const handleTopicValue = value => {
    if (takenNumbers.indexOf(value) === -1) setValueOfTopic(value);
    else dispatch(toast('error', 'Choose a value you are not using already'));
  };

  const resetGroup = async () => {
    const type = groupTypeName === 'Topic' ? 'Topic' : 'Group';
    if (props.data.id) {
      dispatch(fullloader(true, `Resetting ${type}`));
      const id = props.data.id;
      const resetOptions = {
        orgId: selectedOrganization.id,
        tagId: id,
        type: props.data.groupTypeName ? props.data.groupTypeName : 'Topic',
      };
      const reset = await resetOrganizationGroup(resetOptions);
      if (!reset.error) {
        dispatch(fullloader(true, `Fetching ${type}s`));
        props.closeDrawer();
        props.update(selectedOrganization.id);
        dispatch(
          toast(
            'success',
            `Reset ${type} successfully, removed from ${reset.result} contacts`,
            5
          )
        );
      } else {
        dispatch(
          toast('error', `Something went wrong resetting your ${type}.`)
        );
      }
    } else {
      dispatch(toast('error', `Invalid ${type} to reset.`));
    }
  };

  const checkedBoxes = useMemo(
    () =>
      [member ? 'member' : null, nonMember ? 'nonMember' : null].filter(
        Boolean
      ),
    [member, nonMember]
  );

  return (
    <div style={{ width: '100%', height: '100%', margin: '0' }}>
      <Form layout="vertical" ref={formRef} onFinish={handleSubmit}>
        <div className={'GroupDrawerHeader'}>
          <IconButton
            onClick={() => {
              props.closeDrawer();
            }}
            style={{ color: 'white' }}
          >
            <CloseIcon />
          </IconButton>
          {props.drawerTitle}
          <Form.Item>
            <Button
              style={{ marginTop: '25px' }}
              variant="outlined"
              label="Save"
              type="submit"
              className={'GroupSaveButtons'}
            >
              Save
            </Button>
          </Form.Item>
        </div>
        <div
          style={{
            height: 'calc(100% - 60px)',
            padding: '12px 24px 24px 24px',
          }}
        >
          {props.data && props.data.id && (
            <Row gutter={16}>
              <Col span={24} className="tagselect">
                <p>{`ID: ${props.data.id}`}</p>
              </Col>
            </Row>
          )}
          {groupTypeName === 'Topic' && (
            <Row gutter={16}>
              <Col span={12}>
                {/* only show the checkbox if it's a mandatory topic or if user is a "super user" */}
                {(userPrivileges.isSU || mandatory) && (
                  <Form.Item>
                    <Checkbox
                      disabled={!userPrivileges.isSU} // only super users and above can change topics to be mandatory
                      checked={mandatory}
                      onChange={onMandatoryChange}
                    >
                      <span className="mandatorytext">Mandatory Topic?</span>
                    </Checkbox>
                  </Form.Item>
                )}
              </Col>
            </Row>
          )}
          <Row gutter={16} style={{ display: 'flex' }}>
            {groupTypeName !== 'Topic' && (
              <Col span={12} className="tagselect">
                <Form.Item
                  label="Group Type"
                  name="groupTypeName"
                  rules={[
                    { required: true, message: 'Please select group type' },
                  ]}
                  {...formItemLayout}
                >
                  <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select a group type"
                    optionFilterProp="children"
                    onChange={changeGroupType}
                    disabled={props.data} //so that you cannot change tag type on existing tags
                    defaultValue={
                      props.creationType === 'topics' ? 'Topic' : 'Custom'
                    }
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {groupTypeList.length > 0
                      ? groupTypeList.map(item => {
                          return (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          );
                        })
                      : ''}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {groupTypeName === 'Topic' && (
              <Col span={12} className="availableTo">
                <Form.Item
                  label={<span>{'Available to:'}</span>}
                  name="member"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (
                          !value?.length &&
                          [member, nonMember].every(element => !element)
                        ) {
                          return Promise.reject(
                            'At least one option must be selected'
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Checkbox.Group
                    options={[
                      { label: 'Members', value: 'member' },
                      {
                        label: (
                          <span>
                            {'Nonmembers'}
                            <Tooltip
                              placement="right"
                              title={
                                'Topics enabled for nonmembers are automatically enabled for AMS-synced event registrants until they opt out'
                              }
                            >
                              <InfoOutlinedIcon
                                style={{ width: '15px', marginLeft: '5px' }}
                              />
                            </Tooltip>
                          </span>
                        ),
                        value: 'nonMember',
                      },
                    ]}
                    value={checkedBoxes}
                    onChange={onGroupChange}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Name"
                name="nametag"
                rules={[{ required: true, message: 'Please enter name' }]}
                {...formItemLayout}
              >
                <Input
                  onChange={event => {
                    setGroupName(event.target.value);
                  }}
                  type="text"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your description',
                  },
                ]}
                {...formItemLayout}
              >
                <TextArea
                  onChange={event => {
                    setGroupDescription(event.target.value);
                  }}
                  rows="3"
                  cols="25"
                />
              </Form.Item>
            </Col>
          </Row>

          {groupTypeName === 'ListMaker' && (
            <Row>
              <Col span={12}>
                <Form.Item
                  label="List Category Acronym"
                  name="listCategory"
                  rules={[
                    { required: false, message: 'Please enter category' },
                  ]}
                  {...formItemLayout}
                >
                  <Input
                    onChange={event => {
                      setGroupListCategory(event.target.value);
                    }}
                    type="text"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="List Code with <"
                  name="listCode"
                  rules={[{ required: false, message: 'Please enter code' }]}
                  {...formItemLayout}
                >
                  <Input
                    onChange={event => {
                      setGroupListCode(event.target.value);
                    }}
                    type="text"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          {groupTypeName === 'Topic' && (
            <Row gutter={16}>
              <Col span={20}>
                <p>
                  <b>Available to Contacts via:</b>
                  <div>
                    <span style={{ fontSize: '12px', color: 'gray' }}>
                      Must have at least one selected
                    </span>
                  </div>
                </p>
              </Col>
            </Row>
          )}
          {groupTypeName === 'Topic' && (
            <Row>
              <Col span={6}>
                <Form.Item label={'Email'} {...formItemLayout}>
                  <Switch
                    checked={emailOption}
                    disabled={checkDisabled('email')}
                    onChange={changeEmailOption}
                    className="topicswitch"
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="Text" {...formItemLayout}>
                  <Switch
                    checked={textOption}
                    disabled={checkDisabled('text')}
                    onChange={changeTextOption}
                    className="topicswitch"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {groupTypeName === 'Topic' && (
            <Row>
              <Col span={20}>
                <p>
                  <b>Preference Form Order:</b>
                </p>
              </Col>
            </Row>
          )}
          {groupTypeName === 'Topic' && (
            <Row>
              <Col span={12}>
                <Form.Item
                  className="preferenceFormSelectOrder"
                  {...formItemLayout}
                >
                  <Select
                    showSearch
                    className="Emailcheck"
                    optionFilterProp="children"
                    onChange={handleTopicValue}
                    defaultValue={valueOfTopic}
                  >
                    {totalTopics.length > 0
                      ? totalTopics.map((item, index) => {
                          return (
                            <Option selected={item} value={item} key={index}>
                              {item}
                            </Option>
                          );
                        })
                      : ''}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          {groupTypeName === 'Topic' && (
            <Form.Item>
              <Checkbox
                checked={highlightTopic}
                onChange={changeHighlightTopic}
              >
                <span className="mandatorytext">Highlight Topic</span>
              </Checkbox>
            </Form.Item>
          )}
          <Row style={{ margin: '15px 0' }}>
            {props.data &&
              ((props.data.groupTypeName &&
                props.data.groupTypeName === 'Custom') ||
                props.creationType === 'topics') && (
                <Col>
                  <AddContactsButton
                    size={'small'}
                    variant="outlined"
                    startIcon={<GroupAddIcon />}
                    onClick={() => applyToContacts(props.data)}
                  >
                    Add Contacts
                  </AddContactsButton>
                </Col>
              )}
            {props.data && props.data.id && !mandatory && (
              <ResetButton
                onClick={() => setIsResetModalOpen(true)}
                size={'small'}
                variant="outlined"
                startIcon={<RotateLeftIcon />}
              >
                Reset
              </ResetButton>
            )}
          </Row>
        </div>
        {isResetModalOpen && (
          <Modal
            title={`Reset ${groupTypeName === 'Topic' ? 'Topic' : 'Group'}: "${
              props?.data?.name || ''
            }"`}
            onClose={closeResetModal}
            primaryButton={{
              label: 'Reset',
              onClick: resetGroup,
            }}
            secondaryButton={{
              label: 'Cancel',
              onClick: closeResetModal,
            }}
          >
            <div>
              <p>
                {`Are you sure you want to reset this ${
                  groupTypeName === 'Topic' ? 'Topic' : 'Group'
                }? Resetting this ${
                  groupTypeName === 'Topic' ? 'Topic' : 'Group'
                } will remove it from any contacts it is currently assigned to.`}
              </p>
            </div>
          </Modal>
        )}
      </Form>
    </div>
  );
};

export default CreateTag;
