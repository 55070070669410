import React, { useState } from 'react';
import { Input, Switch } from 'antd';
import { Member } from './member';
import { Nonmember } from './nonMember';
import Modal from './../Modal/index';

export const NonmemberToggle = ({
  onToggle,
  updateMemberId,
  memberId,
  isMember,
  amsContact,
}) => {
  //True means member and false means nonmember.
  const [switchState, setSwitchState] = useState(isMember); //If memberId, should be true
  const [editMode, setEditMode] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleSwitchUpdate = val => {
    if (val === false && memberId) {
      //Throw up a modal to ask the user if they are sure they want to remove the member flag if the contact has a member id.
      setConfirmationModal(true);
    }
    updateContactValue(val);
  };

  const updateEditMode = isEditMode => {
    setEditMode(isEditMode);
  };

  const cancelConfirmationModal = () => {
    // Close modal and set back to member
    setConfirmationModal(false);
    setSwitchState(true);
  };

  const acceptConfirmationModal = () => {
    //Close modal after setting
    updateContactValue(false);
    updateMemberId('');
    setConfirmationModal(false);
  };

  const updateContactValue = val => {
    setSwitchState(val);
    onToggle(val);
    if (!val && editMode) setEditMode(false); //Cancel edit mode if toggled off
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'normal',
        alignItems: 'center',
      }}
    >
      {/*Show the proper contact type depending on if there is a member id or not*/}
      {switchState ? (
        <Member
          memberId={memberId}
          enableEdit={updateEditMode}
          amsContact={amsContact}
        />
      ) : (
        <Nonmember />
      )}
      {/*If the toggle is on, edit mode is on, and the contact is not AMS sourced*/}
      {switchState && editMode && !amsContact && (
        <Input
          onChange={event => {
            updateMemberId(event.target.value);
          }}
          defaultValue={memberId || ''}
          type="text"
          size={'small'}
          style={{ marginRight: '5px', marginLeft: '5px', padding: '1px 5px' }}
        />
      )}
      {!amsContact && (
        <Switch
          size={'small'}
          checked={switchState}
          onClick={handleSwitchUpdate}
          style={{ marginLeft: '5px' }}
        />
      )}
      {confirmationModal && (
        <Modal
          title={'Confirm'}
          primaryButton={{ label: 'Yes', onClick: acceptConfirmationModal }}
          secondaryButton={{ label: 'Cancel' }}
          onClose={cancelConfirmationModal}
        >
          Changing this contact to be a nonmember will delete any saved Member
          ID for the contact. Proceed?
        </Modal>
      )}
    </div>
  );
};
