import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding: 20px 30px;
  height: 100%;
  background-color: #ffffff;
  overflow-y: auto;
`;
