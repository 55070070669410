import axios from 'axios';
import queryString from 'query-string';
import store from '../../../redux/store';
import { selectedOrganizationSelector } from '../../../redux/selectors/organization';

const http = axios.create({
  baseURL: process.env.REACT_APP_EC2API,
  paramsSerializer: params => queryString.stringify(params),
});

const getOrganizationId = () => {
  return selectedOrganizationSelector(store.getState()).id;
};

export const resyncEvent = async event => {
  const organizationId = getOrganizationId();
  const {
    data: { result },
    status,
  } = await http.post('/import/events/job/magic', {
    organizationId,
    eventId: event.id,
  });

  if (status !== 200) {
    throw Error('error staring resync job');
  }

  return result;
};

export const getEventResyncStatus = async event => {
  const organizationId = getOrganizationId();
  const {
    data: { result },
  } = await http.get(
    `/import/events/eventregistrationsJob/magic/${organizationId}/${event.id}`
  );

  return result;
};
