import React, { useState, useEffect } from 'react';
import {
  Container,
  Menu,
  Content,
  MenuItem,
  Row,
  InfoText,
  InfoIcon,
} from './MessageAnalytics.styled';
import AllChannels from './Components/AllChannels';
import Email from './Components/Email';
import Text from './Components/Text';
import { CircularProgress } from '@material-ui/core';
import { ALL_CHANNELS, EMAIL, TEXT, STATISTICS_TABS } from '../constants';

const MessageAnalytics = ({
  isLoadingData,
  textEvents,
  emailEvents,
  spamReport,
  errorLogs,
  message,
  startDate,
  isDataAvailable,
  setClickActivityVisibility,
  smartResendEmailEvents,
  isSmartResendAvailable,
  setSmartResendAvailability,
}) => {
  const [currentTab, setCurrentTab] = useState(STATISTICS_TABS[0]);

  useEffect(() => {
    setSmartResendAvailability(
      message && smartResendEmailEvents ? true : false
    );
  }, [message, smartResendEmailEvents]);

  const handleTabClick = tab => {
    setCurrentTab(tab);
  };

  const generateRatio = (numerator, denominator) => {
    if (isNaN(numerator) || isNaN(denominator) || !numerator || !denominator)
      return '0%';

    return `${((numerator / denominator) * 100).toFixed(1)}%`;
  };

  const renderContent = currentTab => {
    switch (currentTab) {
      case ALL_CHANNELS:
        return (
          <AllChannels
            emailEvents={emailEvents}
            smartResendEmailEvents={smartResendEmailEvents?.emailEvents}
            generateRatio={generateRatio}
            textEvents={textEvents}
            isDataAvailable={isDataAvailable}
            isSmartResendAvailable={isSmartResendAvailable}
            isSingleMsgSelected={!!message}
          />
        );
      case EMAIL:
        return (
          <Email
            emailEvents={emailEvents}
            smartResendEmailEvents={smartResendEmailEvents?.emailEvents}
            errorLogs={errorLogs}
            generateRatio={generateRatio}
            isLoadingData={isLoadingData}
            message={message}
            spamReport={spamReport}
            startDate={startDate}
            isDataAvailable={isDataAvailable}
            setClickActivityVisibility={setClickActivityVisibility}
            isSmartResendAvailable={isSmartResendAvailable}
            isSingleMsgSelected={!!message}
          />
        );
      case TEXT:
        return (
          <Text
            errorLogs={errorLogs}
            generateRatio={generateRatio}
            isLoadingData={isLoadingData}
            message={message}
            startDate={startDate}
            textEvents={textEvents}
            isDataAvailable={isDataAvailable}
          />
        );
    }
  };

  return (
    <Container>
      <Row>
        <Menu>
          {STATISTICS_TABS.map(title => (
            <MenuItem
              onClick={() => handleTabClick(title)}
              key={title}
              className={title === currentTab && 'active'}
            >
              {title}
            </MenuItem>
          ))}
        </Menu>
        <InfoText>
          <InfoIcon />
          <>Click a stat for more details</>
        </InfoText>
      </Row>
      {isLoadingData ? (
        <Content style={{ height: '85%' }}>
          <CircularProgress
            style={{ margin: 'auto', alignItems: 'center', color: '#007ADE' }}
          />
        </Content>
      ) : (
        <Content>{renderContent(currentTab)}</Content>
      )}
    </Container>
  );
};

export default MessageAnalytics;
