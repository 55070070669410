import React, { useState } from 'react';
import * as moment from 'moment';
import { convertToCSVFormat } from '../../../utils';
import Card from './Card';
import FailedDeliveryReport from './FailedDeliveryReport';

const Text = ({
  errorLogs,
  generateRatio,
  isLoadingData,
  message,
  startDate,
  textEvents,
  isDataAvailable,
}) => {
  const [isBounceDrawerVisible, setBounceDrawerVisibility] = useState(false);
  const [data, setData] = useState([]);
  const [csvData, setCSVData] = useState([]);

  const handleTextBounceRateClick = () => {
    const data = message
      ? // if a message is selected, use textEvents bounce events
        textEvents.bounceEvents.map(bounce => ({
          ...bounce,
          time: moment.unix(bounce.time).format('MM-DD-YYYY hh:mm A'),
          contact: bounce.phone,
        }))
      : // else, get bounce events from errorlogs
        errorLogs.filter(log => !log?.contact?.includes('@'));

    setData(data);
    setCSVData(convertToCSVFormat(data));
    setBounceDrawerVisibility(true);
  };

  return (
    <>
      <Card
        name="Deliveries"
        value={textEvents.delivered}
        title="The number of unique members that received a text."
        isDataAvailable={isDataAvailable}
      />
      <Card
        name="Delivery Rate"
        value={generateRatio(textEvents.delivered, textEvents.sent)}
        title="The % of members successfully delivered to (Delivered / Recipients)"
        isDataAvailable={isDataAvailable}
      />
      <Card
        name="Bounce Rate"
        value={generateRatio(textEvents.bounced, textEvents.sent)}
        title="Usually a result of sending to a bad phone number."
        onClick={handleTextBounceRateClick}
        isDataAvailable={isDataAvailable}
      />
      <FailedDeliveryReport
        onCloseDrawer={() => setBounceDrawerVisibility(false)}
        isVisible={isBounceDrawerVisible}
        isLoading={isLoadingData}
        message={message}
        startDate={startDate}
        data={data}
        csvData={csvData}
      />
    </>
  );
};

export default Text;
