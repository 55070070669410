import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  DownOutlined,
  ExclamationCircleFilled,
  FileTextOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Popover,
  Tooltip,
  Checkbox,
} from 'antd';
import { toast } from '../../../../redux/action';
import { validateAnotherVersionMessageInfoData } from '../../../../redux/services';
import { validateEmail } from '../../../../redux/helpers';
import { selectedOrganizationSelector } from '../../../../redux/selectors/organization';
import useAutosave from '../Autosave';
import {
  DefaultMessage,
  FromFieldWrapper,
  FromInput,
  Wrapper,
  HelpBadge,
  InputRow,
  MessageInfoButton,
  Panel,
  ValidationStatusBadge,
  Overlay,
  RowWrapper,
  EventIdWrapper,
} from './MessageInfo.styled';
import { trimTextIfNeeded } from '../../../Drafts/Components/_utils';
import { useIsFeatureEnabled } from '../../../../libs/feature-toggles/react';
import EventsAndClassesIdSelector from './EventsAndClassesIdSelector';
import Feature from '../../../../infrastructure/features';

const MessageInfo = (
  {
    data: {
      subject: initalSubject,
      replyTo: initialReplyTo,
      sender: initialSender,
      senderName: initialSenderName,
      preheader: initialPreheader,
      eventId: initialEventId,
      AB,
    },
    onUnsavedStatus,
    onSave,
    autosaveInterval = null,
  },
  ref
) => {
  const location = useLocation();
  const { draft: draftId } = queryString.parse(location.search);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const dispatch = useDispatch();
  const defaultReplyTo =
    selectedOrganization && selectedOrganization.domains
      ? `${selectedOrganization.fromEmail}@${selectedOrganization.domains[0]}`
      : '';
  const defaultSenderName =
    selectedOrganization?.senderName ||
    selectedOrganization?.organizationName ||
    '';
  const [subject, setSubject] = useState('');
  const [preheader, setPreheader] = useState('');
  const [senderName, setSenderName] = useState(defaultSenderName);
  const [sender, setSender] = useState('');
  const [replyTo, setReplyTo] = useState(defaultReplyTo);
  const [isMessageInfoPanelOpen, setIsMessageInfoPanelOpen] = useState(false);
  const [validationTimeoutId, setValidationTimeoutId] = useState(null);
  const [isEventIdRequired, setIsEventIdRequired] = useState(false);
  const [eventId, setEventId] = useState(null);

  const subjectInputRef = useRef();
  const fromInputRef = useRef();
  const replyToInputRef = useRef();
  const eventIdSelectionRef = useRef();
  const isSubjectInvalidRef = useRef();
  const isFromEmailInvalidRef = useRef();
  const isReplyToEmailInvalidRef = useRef();
  const isEventIdInvalidRef = useRef();
  const isNotBversion = AB ? AB.version === 'A' : true;
  isSubjectInvalidRef.current = !subject;
  isFromEmailInvalidRef.current = !validateEmail(sender);
  isReplyToEmailInvalidRef.current = !validateEmail(replyTo);
  isEventIdInvalidRef.current = isEventIdRequired && !eventId;

  const isInvalid =
    isSubjectInvalidRef.current ||
    isFromEmailInvalidRef.current ||
    isReplyToEmailInvalidRef.current ||
    isEventIdInvalidRef.current;
  const senderSeparatorIndex = sender.indexOf('@');
  const senderEmailName =
    sender.slice(0, senderSeparatorIndex) || selectedOrganization.fromEmail;
  if (!sender.slice(0, senderSeparatorIndex)) {
    setSender(`${senderEmailName}@${sender.slice(senderSeparatorIndex + 1)}`);
  }
  const senderEmailDomain = sender.slice(senderSeparatorIndex + 1);

  const isEventsAndClassesFeatureEnabled = useIsFeatureEnabled(
    Feature.EVENTS_AND_CLASSES
  );

  useEffect(() => {
    setSubject(initalSubject || '');
    setPreheader(initialPreheader || '');
    setSenderName(initialSenderName || defaultSenderName);
    setSender(initialSender || '');
    setReplyTo(initialReplyTo || defaultReplyTo);
    setEventId(initialEventId);
    setIsEventIdRequired(!!initialEventId);
  }, [
    initalSubject,
    initialReplyTo,
    initialSender,
    initialSenderName,
    initialPreheader,
    initialEventId,
    AB,
  ]);

  useEffect(() => {
    if (validationTimeoutId === null) {
      return;
    }

    return () => {
      clearTimeout(validationTimeoutId);
    };
  }, [validationTimeoutId]);

  const saveMessageInfo = async () => {
    await onSave({
      sender,
      subject,
      senderName,
      replyTo,
      preheader,
      eventId,
    });
  };

  useAutosave({
    dependancyList: [sender, subject, senderName, replyTo, preheader, eventId],
    saveFunc: saveMessageInfo,
    onUnsavedStatus,
    autosaveInterval,
  });

  useImperativeHandle(ref, () => ({
    validate: async () => {
      const errorCheckResults = [
        isSubjectInvalidRef.current,
        isFromEmailInvalidRef.current,
        isReplyToEmailInvalidRef.current,
        isEventIdInvalidRef.current,
      ];

      const errorMessages = [
        'Please enter a subject line',
        'Please enter a valid "from" address',
        'Please enter a valid "Reply-to" address, or leave it empty',
        'Please select Event/Course ID or uncheck Event/Course ID checkbox',
      ];

      if (
        isSubjectInvalidRef.current ||
        isFromEmailInvalidRef.current ||
        isReplyToEmailInvalidRef.current ||
        isEventIdInvalidRef.current
      ) {
        setIsMessageInfoPanelOpen(true);

        const newValidationTimeoutId = setTimeout(() => {
          if (isSubjectInvalidRef.current) {
            subjectInputRef.current.focus();
          } else if (isFromEmailInvalidRef.current) {
            fromInputRef.current.focus();
          } else if (isReplyToEmailInvalidRef.current) {
            replyToInputRef.current.focus();
          } else if (isEventIdInvalidRef.current) {
            eventIdSelectionRef.current.focus();
          }
        }, 300);

        setValidationTimeoutId(newValidationTimeoutId);
      }
      if (errorCheckResults.includes(true)) {
        const indexOfMessage = errorCheckResults.indexOf(true);
        dispatch(toast('error', errorMessages[indexOfMessage]));
        return false;
      } else if (AB && AB.version) {
        // validate if the other version of MessageInfo is correct as well
        const { isValid, errorMsg } =
          await validateAnotherVersionMessageInfoData(draftId);
        if (isValid) return true;
        dispatch(toast('error', errorMsg));
        return false;
      } else {
        return true;
      }
    },
  }));

  const domainMenu = (
    <Menu
      onClick={e => {
        setSender(`${senderEmailName}@${e.key}`);
      }}
    >
      {selectedOrganization && selectedOrganization.domains
        ? selectedOrganization.domains.map(domain => {
            return (
              <Menu.Item key={domain} value={domain}>
                {domain}
              </Menu.Item>
            );
          })
        : ''}
    </Menu>
  );

  const emailDomainDisplay = () => {
    const domainMaxLength = 20;
    if (senderEmailDomain) {
      return trimTextIfNeeded(senderEmailDomain, domainMaxLength);
    } else if (
      selectedOrganization.domains &&
      selectedOrganization.domains.length
    ) {
      setSender(`${senderEmailName}@${selectedOrganization.domains[0]}`);
      return trimTextIfNeeded(selectedOrganization.domains[0], domainMaxLength);
    }
    return '';
  };

  const handleEventIdCheckboxClick = () => {
    if (isEventIdRequired) {
      setEventId(null);
    }
    setIsEventIdRequired(!isEventIdRequired);
  };

  return (
    <Wrapper>
      <Popover
        getPopupContainer={node => node.parentElement}
        open={isMessageInfoPanelOpen}
        onOpenChange={setIsMessageInfoPanelOpen}
        content={
          <Panel>
            <Tooltip trigger="focus" title="Subject (Required)">
              <ValidationStatusBadge
                count={
                  isSubjectInvalidRef.current ? <ExclamationCircleFilled /> : 0
                }
              >
                <Input
                  ref={subjectInputRef}
                  value={subject}
                  onChange={({ target }) => {
                    setSubject(target.value);
                  }}
                  placeholder="Subject (Required)"
                />
              </ValidationStatusBadge>
            </Tooltip>
            <Tooltip trigger="focus" title="Preheader">
              <HelpBadge
                count={
                  <Tooltip title="A pre-header is a short text snippet that appears after your subject line in an email preview, but isn't actually visible in your message.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                }
              >
                <Input
                  value={preheader}
                  onChange={({ target }) => {
                    setPreheader(target.value);
                  }}
                  placeholder="Preheader"
                />
              </HelpBadge>
            </Tooltip>
            <InputRow>
              <Tooltip
                trigger="focus"
                title={
                  <>
                    <div>Sender name</div>
                    {senderName.trim() === '' && (
                      <DefaultMessage>
                        Defaults to:{' '}
                        <DefaultMessage.Value>
                          {defaultSenderName}
                        </DefaultMessage.Value>
                      </DefaultMessage>
                    )}
                  </>
                }
              >
                <Input
                  value={senderName}
                  onChange={({ target }) => {
                    setSenderName(target.value);
                  }}
                  placeholder="Sender name"
                  onBlur={() => {
                    if (senderName === '') {
                      setSenderName(defaultSenderName);
                    }
                  }}
                />
              </Tooltip>
              <Tooltip
                trigger="focus"
                title={
                  <>
                    <div>Reply-to address</div>
                    {replyTo.trim() === '' && (
                      <DefaultMessage>
                        Defaults to:{' '}
                        <DefaultMessage.Value>
                          {defaultReplyTo}
                        </DefaultMessage.Value>
                      </DefaultMessage>
                    )}
                  </>
                }
              >
                <ValidationStatusBadge
                  count={
                    isReplyToEmailInvalidRef.current ? (
                      <ExclamationCircleFilled />
                    ) : (
                      0
                    )
                  }
                >
                  <Input
                    ref={replyToInputRef}
                    value={replyTo}
                    onChange={({ target }) => {
                      setReplyTo(target.value);
                    }}
                    placeholder="Reply-to address"
                    onBlur={() => {
                      if (replyTo === '') {
                        setReplyTo(defaultReplyTo);
                      }
                    }}
                  />
                </ValidationStatusBadge>
              </Tooltip>
            </InputRow>
            <FromFieldWrapper>
              <Tooltip trigger="focus" title={'"From" Email Prefix'}>
                <ValidationStatusBadge
                  count={
                    isFromEmailInvalidRef.current ? (
                      <ExclamationCircleFilled />
                    ) : (
                      0
                    )
                  }
                >
                  <FromInput
                    ref={fromInputRef}
                    value={senderEmailName}
                    onChange={({ target }) => {
                      setSender(`${target.value.trim()}@${senderEmailDomain}`);
                    }}
                    placeholder={'"From" Email Prefix'}
                  />
                </ValidationStatusBadge>
              </Tooltip>
              <span>@</span>
              <Dropdown overlay={domainMenu}>
                <Button>{emailDomainDisplay()}</Button>
              </Dropdown>
            </FromFieldWrapper>
            {isEventsAndClassesFeatureEnabled && isNotBversion && (
              <EventIdWrapper>
                <RowWrapper onClick={handleEventIdCheckboxClick}>
                  <Checkbox
                    checked={isEventIdRequired}
                    onChange={handleEventIdCheckboxClick}
                  />
                  This message is for an event or class
                </RowWrapper>
                {isEventIdRequired && (
                  <EventsAndClassesIdSelector
                    isEventIdRequired={isEventIdRequired}
                    eventIdSelectionRef={eventIdSelectionRef}
                    eventId={eventId}
                    setEventId={setEventId}
                  />
                )}
              </EventIdWrapper>
            )}
          </Panel>
        }
        trigger="click"
      >
        <ValidationStatusBadge
          count={isInvalid ? <ExclamationCircleFilled /> : 0}
        >
          <MessageInfoButton>
            <FileTextOutlined /> Message Info <DownOutlined />
          </MessageInfoButton>
        </ValidationStatusBadge>
      </Popover>
      <Overlay isVisible={isMessageInfoPanelOpen} />
    </Wrapper>
  );
};

export default forwardRef(MessageInfo);
