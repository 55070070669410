import React from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowBackSharp } from '@material-ui/icons';
import { IconButton } from './BackButton.styled';

const BackButton = ({ targetUrl }) => {
  const history = useHistory();

  return (
    <IconButton
      size="small"
      onClick={() => {
        history.push(targetUrl);
      }}
    >
      <ArrowBackSharp />
    </IconButton>
  );
};

export default BackButton;
