import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  fetchPrefPageData,
  updateContactByIdForPreference,
  updateContactByIdForPreferenceVisited,
} from '../contact';
import _ from 'underscore-es';
import { useToast } from '../../actions/UI';
import { updateTopicsSubscriberCounts } from '../subscriberCount';
import { PreferenceContext } from '.';
import { getOrgByPrefPage } from '../organization';
import { getQueryStringValue } from '../../helpers';
import { getLogoUrlForPreferencePage, orgContent } from '../content';
import { postPreferencePageAPI } from './preferences.api';

moment.locale('en');

export const useUpdateMemberPreferences = () => {
  const { successToast, errorToast } = useToast();
  const {
    member,
    setMember,
    newEmailTopics,
    newTextTopics,
    topics,
    organization,
  } = useContext(PreferenceContext);

  const updateTopicsSubscription = async (oldEmailTags, oldTextTags) => {
    const emailTopicCounts = {},
      textTopicCounts = {},
      totalTopicCounts = {};

    const emailTopics = oldEmailTags.concat(Object.values(newEmailTopics));
    const textTopics = oldTextTags.concat(Object.values(newTextTopics));

    for (const emailTopic of emailTopics) {
      let flag;
      if (newEmailTopics[emailTopic]) flag = 1;
      else flag = -1;
      emailTopicCounts[emailTopic] = flag;
      totalTopicCounts[emailTopic] = flag;
    }

    for (const textTopic of textTopics) {
      let flag;
      if (newTextTopics[textTopic]) flag = 1;
      else flag = -1;
      textTopicCounts[textTopic] = flag;
      totalTopicCounts[textTopic] = flag;
    }

    const topicSubscriberUpdateCounts = {
      totalTopicCounts,
      emailTopicCounts,
      textTopicCounts,
    };

    await updateTopicsSubscriberCounts(topicSubscriberUpdateCounts);
  };

  const updateMemberPreferences = useCallback(
    async updatedTimezone => {
      const updatedTextTopics = Object.values(newTextTopics);
      const updatedEmailTopics = Object.values(newEmailTopics);

      if (member.phoneNumber?.length < 10 && updatedTextTopics.length) {
        errorToast(
          'No phone number on record. Please contact your association and update your record in order to opt-in to texting.'
        );
      } else {
        const mandatoryIds = topics
          .filter(value => value.mandatory)
          ?.find(
            value => !newEmailTopics[value.id] && !newTextTopics[value.id]
          );
        if (!mandatoryIds) {
          const memberData = { ...member };
          const oldEmailTags = memberData.emailTags;
          const oldTextTags = memberData.textTags;
          memberData.emailTags = updatedEmailTopics;
          memberData.timezone = updatedTimezone;

          const { tempTags, textTags } = memberData;

          let toastMsg = 'Your preferences have been saved successfully';
          if (!textTags?.length && updatedTextTopics.length) {
            memberData.tempTags = _.union(tempTags, updatedTextTopics);
            toastMsg = 'A Confirmation message has been sent to you.';
          } else if (textTags?.length && updatedTextTopics.length) {
            memberData.textTags = updatedTextTopics;
          } else if (textTags?.length && !updatedTextTopics?.length) {
            memberData.textTags = [];
            memberData.tempTags = [];
          }

          const updatedMember = await updateContactByIdForPreference(
            memberData
          );
          if (!updatedMember.error) {
            successToast(toastMsg);
          }

          await updateTopicsSubscription(oldEmailTags, oldTextTags);
          setMember(updatedMember);
        } else {
          errorToast('Mandatory Topics must have at least one channel open');
        }
      }
    },
    [member, organization, newEmailTopics, newTextTopics]
  );

  return { updateMemberPreferences };
};

export const useGetPreferenceDetails = () => {
  const {
    member,
    setMember,
    setEmailTopics,
    setTextTopics,
    setLoading,
    setOrganization,
    setTopics,
  } = useContext(PreferenceContext);

  const { errorToast } = useToast();

  const getOptinMember = async () => {
    const id = getQueryStringValue('id');
    try {
      if (id) {
        setLoading(true);

        const { error, contact, organization, organizationTopics } =
          await fetchPrefPageData(id);
        if (!error) {
          setMember(contact);

          const newEmailTopics = {};
          contact.emailTags.forEach(tag => {
            newEmailTopics[tag] = tag;
          });
          setEmailTopics(newEmailTopics);

          const newTextTopics = {};
          contact.textTags?.forEach(tag => {
            newTextTopics[tag] = tag;
          });
          setTextTopics(newTextTopics);

          organization.displayPhone = organization.phoneNumber
            ? `(${organization.phoneNumber.substring(
                0,
                3
              )}) ${organization.phoneNumber.substring(
                3,
                6
              )}-${organization.phoneNumber.substring(6)}`
            : '';

          setOrganization(organization);
          sortTags(organizationTopics);

          setLoading(false);
        } else {
          errorToast(`noteRouterId not found for -> ${window.location.href}`);
        }
      }
    } catch (err) {
      console.error(err, window.location.href);
      errorToast(err);
    }
  };

  const sortTags = orgTopics => {
    const unprioritized = [];
    let priorityTags = [];
    for (const topic of orgTopics) {
      if (!topic.priorityNumber) {
        unprioritized.push(topic);
      } else if (topic.priorityNumber > 0) {
        priorityTags.push(topic);
      }
    }

    priorityTags.sort((a, b) => {
      if (a.priorityNumber > b.priorityNumber) return 1;
      if (b.priorityNumber > a.priorityNumber) return -1;
      return 0;
    });
    if (unprioritized.length > 0) {
      priorityTags = priorityTags.concat(unprioritized);
    }
    setTopics(priorityTags);
  };

  const parseOrgQueryString = async (acronym, orgHashId) => {
    const organization = await getOrgByPrefPage(acronym, orgHashId);
    if (organization && !organization.error) {
      setOrganization(organization);
    }
  };

  useEffect(() => {
    if (member?.noteRouterId) {
      updateContactByIdForPreferenceVisited({
        noteRouterId: member.noteRouterId,
        organizationId: member.organizationId || member.organization.id,
        lastPreferencePageVisited: moment().unix(),
      });
    }
  }, [member?.noteRouterId]);

  return { getOptinMember, parseOrgQueryString };
};

export const useOrganizationLogo = organizationId => {
  const [logo, setImageUrl] = useState('');

  const getOrganizationUrl = useCallback(async () => {
    const logoUrl = await getLogoUrlForPreferencePage(organizationId);
    setImageUrl(logoUrl);
  }, [organizationId]);

  useEffect(() => {
    if (organizationId) getOrganizationUrl(organizationId);
  }, [organizationId]);

  return { logo, getOrganizationUrl };
};

export const useOrganizationContent = ({ organizationId, isMember }) => {
  const [content, setCustomContent] = useState('');

  const getOrganizationContent = useCallback(async () => {
    const optinContent = await orgContent({
      orgId: organizationId,
      contactType: isMember ? 'member' : 'nonMember',
    });

    setCustomContent(optinContent.content);
  }, [organizationId]);

  useEffect(() => {
    if (organizationId) getOrganizationContent(organizationId);
  }, [organizationId]);

  return { content, getOrganizationContent };
};

export const useSendPreferencePage = () => {
  const [contact, setContact] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { errorToast } = useToast();

  const resetError = () => {
    setError('');
  };

  const sendPreferencePage = useCallback(
    async (organizationId, filter, value, token) => {
      setError();
      setLoading(true);
      try {
        const contact = await postPreferencePageAPI({
          organizationId,
          filter,
          value,
          token,
        });
        setContact(contact.data.data);
      } catch (error) {
        if (error.response?.status !== 500)
          setError(error.response?.data?.message);
        else {
          console.error(
            `Error while sending preference page to contact ${error}`
          );
          errorToast('Error while sending preference page to contact');
        }
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const resetContact = () => setContact();

  return {
    error,
    resetError,
    contact,
    loading,
    sendPreferencePage,
    resetContact,
  };
};
