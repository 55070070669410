import React from 'react';
import { AddContactNotes, ListContactNotes } from '../../ContactNotes';
import { ContactNotesViewStyled } from './contactNotes.styled';

export const ContactNotesView = () => {
  return (
    <ContactNotesViewStyled>
      <AddContactNotes />
      <ListContactNotes />
    </ContactNotesViewStyled>
  );
};
