import * as React from 'react';

const IconEmailCheck = props => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="24px"
      width="24px"
      {...props}
    >
      <path d="M13 19c0-3.31 2.69-6 6-6 1.1 0 2.12.3 3 .81V6a2 2 0 00-2-2H4c-1.11 0-2 .89-2 2v12a2 2 0 002 2h9.09c-.05-.33-.09-.66-.09-1M4 8V6l8 5 8-5v2l-8 5-8-5m13.75 14.16l-2.75-3L16.16 18l1.59 1.59L21.34 16l1.16 1.41-4.75 4.75" />
    </svg>
  );
};

export default IconEmailCheck;
