import React from 'react';
import StorageIcon from '@material-ui/icons/Storage';
import { TextField, Switch, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  TechnicalSettingsContainer,
  Row,
  Title,
  EditBtn,
  SmallGapContent,
} from '../GeneralInfo.styled';

const useStyles = makeStyles(() => ({
  textField: {
    '& input:disabled': {
      color: '#212121',
    },
  },
}));

const TechnicalSettings = ({
  selectedOrg,
  setEditTechnicalSettingsDrawerVisibility,
}) => {
  const classes = useStyles();

  return (
    <TechnicalSettingsContainer>
      <Row>
        <Title>
          <StorageIcon size="small" />⁂ Technical Settings
        </Title>
        <EditBtn
          onClick={() => {
            setEditTechnicalSettingsDrawerVisibility(true);
          }}
        >
          Edit
        </EditBtn>
      </Row>
      <TextField
        className={classes.textField}
        disabled
        label="Stripe Customer ID"
        variant="outlined"
        size="small"
        value={selectedOrg.stripe?.customerId || ''}
      />
      <TextField
        className={classes.textField}
        disabled
        label="Twilio SID"
        variant="outlined"
        size="small"
        value={selectedOrg.twilioSID || ''}
      />
      <TextField
        className={classes.textField}
        disabled
        label="Twilio Auth Token"
        variant="outlined"
        size="small"
        value={selectedOrg.twilioAuthToken || ''}
      />
      <SmallGapContent>
        <FormControlLabel
          control={
            <Switch
              checked={selectedOrg.textingHubEnabled || false}
              color="primary"
              style={{ cursor: 'default' }}
              disabled
            />
          }
          label="Texting Hub enabled"
          style={{ cursor: 'default' }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={selectedOrg.editTextPreferences || false}
              color="primary"
              style={{ cursor: 'default' }}
              disabled
            />
          }
          label="Users can edit contact texting preferences"
          style={{ cursor: 'default' }}
        />
      </SmallGapContent>
    </TechnicalSettingsContainer>
  );
};

export default TechnicalSettings;
