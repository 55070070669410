export const timezones = [
  'America/New_York',
  'America/Denver',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Chicago',
  'America/Regina',
  'Pacific/Honolulu',
];

export const getTimezoneInfo = timezone => {
  const defaultTimezone = {
    region: 'America/Los_Angeles',
    vsUTC: 8,
    zone: 'PST',
  };

  switch (timezone) {
    case 'Pacific/Honolulu':
      defaultTimezone.region = 'Pacific/Honolulu';
      defaultTimezone.vsUTC = 10;
      defaultTimezone.zone = 'HST';
      break;
    case 'America/Los_Angeles':
      defaultTimezone.region = 'America/Los_Angeles';
      defaultTimezone.vsUTC = 8;
      defaultTimezone.zone = 'PST';
      break;
    case 'America/Phoenix':
      defaultTimezone.region = 'America/Phoenix';
      defaultTimezone.vsUTC = 7;
      defaultTimezone.zone = 'MST';
      break;
    case 'America/Denver':
      defaultTimezone.region = 'America/Denver';
      defaultTimezone.vsUTC = 7;
      defaultTimezone.zone = 'MST';
      break;
    case 'America/Chicago':
      defaultTimezone.region = 'America/Chicago';
      defaultTimezone.vsUTC = 6;
      defaultTimezone.zone = 'CST';
      break;
    case 'America/Regina':
      defaultTimezone.region = 'America/Regina';
      defaultTimezone.vsUTC = 6;
      defaultTimezone.zone = 'CST';
      break;
    case 'America/New_York':
      defaultTimezone.region = 'America/New_York';
      defaultTimezone.vsUTC = 5;
      defaultTimezone.zone = 'EST';
      break;
    default:
      break;
  }

  return defaultTimezone;
};

export const limitCharacters = text => {
  if (text?.length > 15) return text.substring(0, 15);
  return text;
};

export const allowOnlyNumericValues = (inputValue, maxLength) => {
  let numericValue = inputValue.replace(/[^0-9]/g, '');

  if (maxLength && numericValue.length > maxLength) {
    numericValue = numericValue.slice(0, maxLength);
  }

  return numericValue;
};

export const isEmpty = value => {
  return value === '' || value === undefined || value === null;
};

export const isEmailValid = email => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidPhoneNumber = (phoneNumber, isEmptyOk) => {
  if (isEmptyOk && isEmpty(phoneNumber)) return true;
  const phoneRegex = /^\d{10}$/;
  return phoneRegex.test(phoneNumber);
};

export const isValidWebsite = url => {
  const urlRegex =
    /^(?:(?:https?|ftp):\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+)(?:\/[^\s]*)?$/i;
  return urlRegex.test(url);
};

export const getEmailErrorHelperText = email => {
  if (isEmpty(email)) return 'Required field';
  if (!isEmailValid(email)) return 'Please enter a valid email';
  return '';
};

export const getPhoneNumberErrorHelperText = (phoneNumber, isEmptyOk) => {
  if (isEmptyOk && isEmpty(phoneNumber)) {
    return '';
  }

  if (isEmpty(phoneNumber)) return 'Required field';

  if (!isValidPhoneNumber(phoneNumber))
    return 'Please enter a valid phone number';
  return '';
};

export const getWebsiteErrorHelperText = url => {
  if (isEmpty(url)) return 'Required field';
  if (!isValidWebsite(url)) return 'Please enter a valid website';
  return '';
};

export const reverseObject = obj => {
  const reversed = {};
  for (const [key, value] of Object.entries(obj)) {
    reversed[value] = key;
  }
  return reversed;
};
