import { DataGrid } from '../../../../features/automated-campaigns/CampaignsTable/CampaignsTable.styled';
import React, { useEffect, useState } from 'react';
import { escapeRegExp } from '../../../../redux/helpers/helpers';
import SearchBar from '../../../Manage/SearchBar';

const COLUMNS = [
  {
    field: 'acronym',
    headerName: 'Acronym',
    flex: 20,
    minWidth: 200,
    renderCell: ({ value }) => <DataGrid.Name>{value}</DataGrid.Name>,
    filterable: false,
  },
  {
    field: 'membershipDelta',
    headerName: 'Membership Delta',
    flex: 20,
    minWidth: 200,
    renderCell: ({ value }) => <DataGrid.Name>{value}</DataGrid.Name>,
    filterable: false,
  },
  {
    field: 'basicDelta',
    headerName: 'Basic Delta',
    flex: 20,
    minWidth: 200,
    renderCell: ({ value }) => <DataGrid.Name>{value}</DataGrid.Name>,
    filterable: false,
  },
  {
    field: 'contactDelta',
    headerName: 'Contact Delta',
    flex: 20,
    minWidth: 200,
    renderCell: ({ value }) => <DataGrid.Name>{value}</DataGrid.Name>,
    filterable: false,
  },
  {
    field: 'billingDelta',
    headerName: 'Billing Delta',
    flex: 20,
    minWidth: 200,
    renderCell: ({ value }) => <DataGrid.Name>{value}</DataGrid.Name>,
    filterable: false,
  },
  {
    field: 'officeDelta',
    headerName: 'Office Delta',
    flex: 20,
    minWidth: 200,
    renderCell: ({ value }) => <DataGrid.Name>{value}</DataGrid.Name>,
    filterable: false,
  },
  {
    field: 'eventsAndClassesDelta',
    headerName: 'Events And Classes Delta',
    flex: 20,
    minWidth: 200,
    renderCell: ({ value }) => <DataGrid.Name>{value}</DataGrid.Name>,
    filterable: false,
  },
  {
    field: 'eventsRegistrationsDelta',
    headerName: 'Events Registrations Delta',
    flex: 20,
    minWidth: 200,
    renderCell: ({ value }) => <DataGrid.Name>{value}</DataGrid.Name>,
    filterable: false,
  },
  {
    field: 'coursesDelta',
    headerName: 'Courses Delta',
    flex: 20,
    minWidth: 200,
    renderCell: ({ value }) => <DataGrid.Name>{value}</DataGrid.Name>,
    filterable: false,
  },
  {
    field: 'listDelta',
    headerName: 'List Delta',
    flex: 20,
    minWidth: 200,
    renderCell: ({ value }) => <DataGrid.Name>{value}</DataGrid.Name>,
    filterable: false,
  },
];
const PAGE_SIZES = [10, 25, 50, 100];

const SyncReportTable = ({ data, isLoading, onTableRowClick }) => {
  const [pageSize, setPageSize] = useState(25);
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    setTableRows(data);
  }, [data]);

  const handleRowClick = ({ id }) => {
    onTableRowClick(id);
  };

  const handleSearch = searchValue => {
    setQuickSearchValue(searchValue);
    if (!searchValue) {
      setTableRows(data);
    } else {
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = data.filter(row => {
        const rowAcronym = row.acronym || '';
        return searchRegex.test(rowAcronym.toString());
      });
      setTableRows(filteredRows);
    }
  };

  return (
    <>
      <DataGrid
        columns={COLUMNS}
        rows={tableRows}
        rowsPerPageOptions={PAGE_SIZES}
        pageSize={pageSize}
        onPageSizeChange={size => setPageSize(size)}
        sortingOrder={['asc', 'desc']}
        rowHeight={50}
        loading={isLoading}
        disableColumnMenu
        onRowClick={handleRowClick}
        components={{ Toolbar: SearchBar }}
        componentsProps={{
          toolbar: {
            value: quickSearchValue,
            onChange: event => handleSearch(event.target.value),
            clearSearch: () => handleSearch(''),
          },
        }}
        localeText={{
          noRowsLabel: 'No organizations',
        }}
      />
    </>
  );
};

export default SyncReportTable;
