export const committeeInitState = {
  committees: [],
};

export function committees(state = committeeInitState, action) {
  const { detail } = action;
  switch (action.type) {
    case 'LOAD_COMMITTEES':
      return {
        ...state,
        committees: detail,
      };
    case 'RESET_COMMITTEES':
      return {
        ...state,
        committees: {},
      };
    default:
      return state;
  }
}
