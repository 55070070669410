import api from '../../utils/andromeda-api';

export async function orgContent({ orgId, contactType }) {
  try {
    const response = await api.post('/preference/getOptinContent', {
      orgId,
      contactType,
    });
    console.log('Retrieved optin content');
    if (response?.data?.result) {
      return response.data.result;
    }
  } catch (e) {
    console.error('Error getting optin content [orgContent]', e);
    return false;
  }
  return false;
}

export async function getLogoUrl(orgId) {
  try {
    const response = await api.get(`/organization/logo/${orgId}`);
    console.log('Retrieved logo');
    if (response?.data?.result) {
      return response.data.result.organizationImageUrl;
    }
  } catch (e) {
    console.error('Error getting logo [getLogoUrl]', e);
    return false;
  }
}

export async function getLogoUrlForPreferencePage(orgId) {
  try {
    const response = await api.get(`/preference/organization/logo/${orgId}`);
    console.log('Retrieved logo');
    if (response?.data?.result) {
      return response.data.result.organizationImageUrl;
    }
  } catch (e) {
    console.error('Error getting logo [getLogoUrlForPreference]', e);
    return false;
  }
}

export async function uploadLogoDoc(docData) {
  try {
    await api.post('/organization/uploadNewLogo', docData);
    console.log('Added logo');
    return true;
  } catch (e) {
    console.error('Error adding logo [uploadLogoDoc]', e);
    return false;
  }
}
