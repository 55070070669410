import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { Archive, Pause, PlayArrow } from '@material-ui/icons';
import { _noop } from 'underscore-es';

import { useToggle } from '../../../libs/react-use-extra';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { Select } from './StatusPicker.styled';

const LOADING_VALUE = Symbol('loading');

const StatusPicker = ({
  value,
  onChange = _noop,
  isDisabled = false,
  isLoading = false,
}) => {
  const [
    isArchiveConfirmationOpen,
    { on: openArchiveConfirmation, off: closeArchiveConfirmation },
  ] = useToggle();

  const archive = () => {
    onChange('archived');
  };

  const handleChange = event => {
    const newStatus = event.target.value;
    if (newStatus === 'archived') {
      openArchiveConfirmation();
    } else {
      onChange(newStatus);
    }
  };

  return (
    <>
      <Select
        value={isLoading ? LOADING_VALUE : value}
        onChange={handleChange}
        disabled={isLoading || isDisabled}
      >
        {isLoading && (
          <Select.Option value={LOADING_VALUE} disabled>
            Loading <CircularProgress size={16} />
          </Select.Option>
        )}
        <Select.Option value="active">
          Active <PlayArrow style={{ color: '#5eab00' }} />
        </Select.Option>
        <Select.Option value="paused">
          Paused <Pause style={{ color: '#da9206' }} />
        </Select.Option>
        <Select.Option value="archived">
          Archived <Archive style={{ color: '#ab0000' }} />
        </Select.Option>
      </Select>
      {isArchiveConfirmationOpen && (
        <ConfirmationDialog
          message="Are you sure you want to archive this campaign?"
          onConfirm={archive}
          onClose={closeArchiveConfirmation}
        />
      )}
    </>
  );
};

export default StatusPicker;
