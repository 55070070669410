import React, { memo } from 'react';
import { IconHeadingStyled } from './iconHeading.styled';

export const IconHeading = memo(function IconHeadingComponent({
  title,
  icon,
  className,
}) {
  return (
    <IconHeadingStyled className={className}>
      {icon}
      <h3>{title}</h3>
    </IconHeadingStyled>
  );
});
