import React, { useCallback, useRef, useState } from 'react';
import { TextField, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Modal from '../Modal';
import { createGroup } from '../../redux/services/groups';
import { selectedOrganizationSelector } from '../../redux/selectors/organization';
import { Form, Small, Row, Column } from './CreateTopicModal.styled';
import { fullloader, toast } from '../../redux/actions/UI';

const useTextInputState = () => {
  const [text, setText] = useState('');

  const handleChangeEvent = useCallback(event => {
    setText(event.target.value);
  }, []);

  return [text, handleChangeEvent];
};

const CreateTopicModal = ({
  onSuccess = () => {},
  onFailure = () => {},
  onClose,
  onCancel = onClose,
  availableTo,
}) => {
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(selectedOrganizationSelector);

  const nameFieldRef = useRef(null);

  const [name, handleNameChange] = useTextInputState();
  const isNameValid = name.trim().length > 0;
  const [isNameFieldTouched, setIsNameFieldTouched] = useState(false);
  const markNameFieldTouched = useCallback(() => {
    setIsNameFieldTouched(true);
  }, []);
  const isNameFieldErrorVisible = isNameFieldTouched && !isNameValid;

  const [description, handleDescriptionChange] = useTextInputState();

  const [isLoading, setIsLoading] = useState(false);
  const [isEmailOptionChecked, setEmailOption] = useState(true);
  const [isTextOptionChecked, setTextOption] = useState(true);

  const handleConfirm = useCallback(async () => {
    if (!isNameValid) {
      markNameFieldTouched();
      nameFieldRef.current.focus();
      return;
    }

    setIsLoading(true);
    dispatch(fullloader(true, 'Creating a new topic'));

    const normalizedDescription = description.trim();
    const { data, error } = await createGroup({
      textSubscribedCount: 0,
      emailSubscribedCount: 0,
      subscribedMembers: 0,
      editEmailPreference: isEmailOptionChecked,
      editTextPreference: isTextOptionChecked,
      availableTo,
      organizationId: selectedOrganization.id,
      name,
      description: normalizedDescription === '' ? null : normalizedDescription,
      enable: 1,
      id: uuidv4(),
      priorityNumber: 0,
      mandatory: false,
      highlightTopic: false,
      delete: false,
    });

    setIsLoading(false);
    dispatch(fullloader(false));

    if (error) {
      dispatch(toast('error', 'Failed to create a new topic'));
      onFailure();
    } else {
      dispatch(toast('success', 'Successfully created a new topic'));
      onSuccess(data);
    }
  }, [
    description,
    dispatch,
    isNameValid,
    markNameFieldTouched,
    name,
    onFailure,
    onSuccess,
    selectedOrganization.id,
  ]);

  const handleChangeEmailOption = () => {
    setEmailOption(!isEmailOptionChecked);
  };

  const handleChangeTextOption = () => {
    setTextOption(!isTextOptionChecked);
  };

  const checkDisabled = type => {
    return (
      (type === 'email' && isEmailOptionChecked && !isTextOptionChecked) ||
      (type === 'text' && isTextOptionChecked && !isEmailOptionChecked)
    );
  };

  return (
    <Modal
      title="Create New Topic"
      onClose={onClose}
      primaryButton={{
        label: 'Create',
        onClick: handleConfirm,
        isLoading,
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onCancel,
      }}
    >
      <Form>
        <TextField
          inputRef={nameFieldRef}
          placeholder="Name *"
          variant="outlined"
          size="small"
          value={name}
          onChange={handleNameChange}
          error={isNameFieldErrorVisible}
          helperText={isNameFieldErrorVisible ? 'Name is required' : ''}
          onBlur={markNameFieldTouched}
          fullWidth
        />
        <TextField
          placeholder="Description"
          variant="outlined"
          size="small"
          value={description}
          onChange={handleDescriptionChange}
          fullWidth
          multiline
          rows={3}
        />
        <span>Available to Contacts via:</span>
        <Small>Must have at least one selected</Small>
        <Row>
          <Column>
            <span>Email</span>
            <Switch
              checked={isEmailOptionChecked}
              onChange={handleChangeEmailOption}
              disabled={checkDisabled('email')}
              color="primary"
              name="email"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Column>
          <Column>
            <span>Text</span>
            <Switch
              checked={isTextOptionChecked}
              onChange={handleChangeTextOption}
              disabled={checkDisabled('text')}
              color="primary"
              name="text"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Column>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateTopicModal;
