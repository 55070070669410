import React, { Children, cloneElement, useState } from 'react';
import useIntersectionObserver from './useIntersectionObserver';

const IntersectionTarget = ({ onIntersect, children }) => {
  const target = Children.only(children);
  const [targetElement, setTargetElement] = useState(null);
  useIntersectionObserver(targetElement, onIntersect);
  return cloneElement(target, { ref: setTargetElement });
};

IntersectionTarget.defaultProps = {
  children: <div />,
};

export default IntersectionTarget;
