import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Button } from '@material-ui/core';
import { AddCircleOutline, CancelOutlined } from '@material-ui/icons';
import SelectDropDown from '../_shared/selectDropdown';
import Container from '../_shared/container';
import Text from '../_shared/text';
import Wrap from '../_shared/wrap';
import Bold from '../_shared/bold';
import EventsAndClassesIdSelector from '../_shared/EventsAndClassesSelector';
import Content from '../../../_shared/content';
import FitContent from '../_shared/fitContent';
import Space from '../_shared/space';
import { attendedStatuses } from '../_shared/constants';
import { capitalizeFirstLetter } from '../_shared/utils';
import {
  addFilter,
  removeFilter,
  toast,
} from '../../../../../../../../../redux/action';
import { fetchCourseIdsAndNamesService } from '../../../../../../../../../features/events-and-classes/api';

const CourseAttendance = ({ filterName, checked, selectedFilters }) => {
  const dispatch = useDispatch();
  const [attendanceStatus, setAttendanceStatus] = useState('');
  const [courseId, setCourseId] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalCourseAttendances, setTotalCourseAttendances] = useState(
    selectedFilters || []
  );

  useEffect(() => {
    if (totalCourseAttendances.length) {
      dispatch(addFilter({ [filterName]: totalCourseAttendances }));
    } else if (
      totalCourseAttendances.length === 0 &&
      selectedFilters &&
      selectedFilters.length !== 0
    ) {
      dispatch(removeFilter(filterName));
    }
  }, [totalCourseAttendances]);

  useEffect(() => {
    if (!checked && totalCourseAttendances.length) {
      dispatch(removeFilter(filterName));
    }
  }, [checked]);

  const handleEventOrClassIdChange = eventId => {
    setCourseId(eventId);
  };

  const handleAttendanceStatusChange = ({ target }) => {
    setAttendanceStatus(target.value);
  };

  const checkIfCourseIdIsPresent = () => {
    return totalCourseAttendances.find(filter => filter.courseId === courseId);
  };

  const handleAddCourseAttendance = () => {
    const errorMessage = [];
    if (!attendanceStatus) {
      errorMessage.push('Please select attendance status.');
    }
    if (!courseId) {
      errorMessage.push('Please add Event/Class ID.');
    }
    if (!startDate) {
      errorMessage.push('Please add start date.');
    }
    if (!endDate) {
      errorMessage.push('Please add end date.');
    }

    if (startDate >= endDate) {
      errorMessage.push('End date should be higher than start date.');
    }

    if (totalCourseAttendances.length && checkIfCourseIdIsPresent()) {
      errorMessage.push('Course ID should not be present in saved filters.');
    }

    if (errorMessage.length) {
      dispatch(toast('error', errorMessage.join('\n')));
    } else {
      setTotalCourseAttendances([
        ...totalCourseAttendances,
        { attendanceStatus, courseId, startDate, endDate },
      ]);
      setAttendanceStatus('');
      setCourseId('');
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleRemoveEventAndClassAttendance = index => {
    setTotalCourseAttendances([
      ...totalCourseAttendances.slice(0, index),
      ...totalCourseAttendances.slice(index + 1),
    ]);
  };

  const handleStartDateChange = datePicked => {
    const date = moment(datePicked);
    if (date.isValid()) {
      setStartDate(new Date(datePicked));
    } else {
      setStartDate(null);
    }
  };

  const handleEndDateChange = maxDatePicked => {
    const date = moment(maxDatePicked);
    if (date.isValid()) {
      setEndDate(new Date(maxDatePicked));
    } else {
      setEndDate(null);
    }
  };

  const renderDatePicker = (date, handleDateChange) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        style={{ width: '140px', top: '-4px' }}
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        value={date}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        openTo={'year'}
        views={['year', 'month', 'date']}
        autoOk={true}
      />
    </MuiPickersUtilsProvider>
  );

  return (
    <Container>
      {(selectedFilters || []).map(
        ({ attendanceStatus, courseId, startDate, endDate }, index) => (
          <Wrap key={index}>
            <FitContent>
              {index > 0 ? (
                <Space>or {attendanceStatus}</Space>
              ) : (
                <Space>- {capitalizeFirstLetter(attendanceStatus)}</Space>
              )}
              Course
            </FitContent>
            <Bold>ID: {courseId}</Bold>
            between
            <Bold>
              {moment(startDate).format('MM/DD/YYYY')}-
              {moment(endDate).format('MM/DD/YYYY')}
            </Bold>
            <CancelOutlined
              fontSize="small"
              style={{ fill: '#E63946' }}
              onClick={() => handleRemoveEventAndClassAttendance(index)}
            />
          </Wrap>
        )
      )}
      <Content>
        <Text>{totalCourseAttendances.length ? 'or' : ''}</Text>
        <SelectDropDown
          value={attendanceStatus}
          menuItemNames={attendedStatuses}
          style={{ width: 130 }}
          onChange={handleAttendanceStatusChange}
        />
        <Space />
        <FitContent>
          <Text>contacts for Course</Text>
        </FitContent>
        <EventsAndClassesIdSelector
          eventId={courseId}
          setEventId={handleEventOrClassIdChange}
          placeholder="Course ID"
          fetchDataFunc={fetchCourseIdsAndNamesService}
          shouldReturnUniqueValues
        />
        <Space />
        <Text>between</Text>
        {renderDatePicker(startDate, handleStartDateChange)}
        <Text>and</Text>
        {renderDatePicker(endDate, handleEndDateChange)}
      </Content>
      <Button
        variant="contained"
        size="small"
        startIcon={<AddCircleOutline />}
        style={{
          borderRadius: 50,
          backgroundColor: '#007ADE',
          margin: '0 25px',
          padding: '5px 15px',
          color: 'white',
        }}
        onClick={handleAddCourseAttendance}
      >
        Add
      </Button>
    </Container>
  );
};

export default CourseAttendance;
