import styled from 'styled-components';

export const Errors = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

Errors.Title = styled.span`
  font-size: 14px;
`;

Errors.List = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
`;

Errors.Error = styled.li`
  font-size: 12px;
  line-height: 1.4;
`;
