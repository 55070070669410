import React from 'react';

import { Variant } from '../types';
import { getLogoUrl } from '../logoUrls';
import { Image } from './Logo.styled';

// Aspect ratios are taken from original image sizes
const ASPECT_RATIOS_BY_VARIANT = {
  [Variant.PRIMARY]: [926, 150],
  [Variant.ICON]: [187, 150],
};

const Logo = ({ className = null, variant, color }) => {
  const imageUrl = getLogoUrl({ variant, color });
  const aspectRatio = ASPECT_RATIOS_BY_VARIANT[variant];
  return (
    <Image
      className={className}
      src={imageUrl}
      alt="NoteRouter"
      $aspectRatio={aspectRatio}
    />
  );
};

export default Logo;
