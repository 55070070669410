import React from 'react';
import { Wrap, Highlight } from './Footer.styled';

const Stats = ({ Icon, color, text, orList, andList, backColor }) => {
  const getList = (listOfNames, delimiter) => {
    return listOfNames.map((name, index) => {
      const wordDelimiter = index !== listOfNames.length - 1 ? delimiter : '';
      return (
        <b key={name + index}>
          <Highlight color={color} backColor={backColor}>
            {name || <i>[Deleted]</i>}{' '}
            {/*In case of no name, display as it was deleted. */}
          </Highlight>
          {wordDelimiter}
        </b>
      );
    });
  };

  const getHighlightedData = (orList, andList) => {
    const content = [];
    if (andList && andList.length) {
      content.push(...getList(andList, 'and'));
    }

    if (orList && orList.length) {
      if (content.length) {
        content.push(<b key="orListStart">or</b>);
      }
      content.push(getList(orList, 'or'));
    }

    return content;
  };

  return (
    <Wrap>
      <Icon style={{ color: color, fontSize: 16, marginRight: 7 }} />
      {text}: {getHighlightedData(orList, andList)}
    </Wrap>
  );
};

export default Stats;
