import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { createTheme } from '@material-ui/core';
import {
  getContactFullNameOrPhoneNumber,
  checkIfValidPhoneNumber,
  escapeRegExp,
  formatDate,
  formatTime,
} from '../utils';
import { Archive } from '@material-ui/icons';
import ContactSelector from '../ContactsSelector';
import {
  DataGrid,
  CellContainer,
  Line,
  EditMatchedContact,
  UnreadLabel,
  Row,
  Column,
  SmallText,
  ViewedIcon,
} from './TextMessagesTable.styled';
import QuickSearch from '../QuickSearch';
import { matchConversationToContact } from '../api';
import { useToast, useUpdateUIState } from '../../../redux/action';
import { textingHubViewers } from '../../../redux/selector';
import { CONVERSATIONS } from '../utils/constants';

const defaultTheme = createTheme();
const useStyles = makeStyles(
  () => ({
    root: {
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: '600',
      },
      '& .MuiDataGrid-columnHeaderWrapper': {
        backgroundColor: '#EDEDED',
      },
      '& .MuiDataGrid-footerContainer': {
        justifyContent: 'center',
      },
      '& .MuiDataGrid-row': {
        cursor: 'pointer',
      },
    },
    subjectHeader: {
      paddingTop: '5px',
    },
  }),
  { defaultTheme }
);

const TextMessagesTable = ({
  textMessages,
  setTextMessages,
  onEventClick,
  onRowSelected,
  isLoading,
  isTextingHubDisabled,
  status,
  setStatus,
}) => {
  const { fullLoader } = useUpdateUIState();
  const { errorToast, successToast } = useToast();
  const [pageSize, setPageSize] = useState(25);
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const fetchedContactsRef = useRef({});
  const viewers = useSelector(textingHubViewers) || {};

  const handleRowEnter = ({ id }) => {
    if (isTextingHubDisabled) return;
    setHoveredRow(id);
  };

  const handleRowLeave = () => {
    if (isTextingHubDisabled) return;
    setHoveredRow(null);
  };

  const matchToContact = async ({
    noteRouterId,
    messageId,
    conversationId,
  }) => {
    try {
      fullLoader(true);
      const contact = await matchConversationToContact({
        noteRouterId,
        conversationId,
      });
      const updatedData = rows.map(row => {
        if (row.id === messageId) {
          return { ...row, sender: `${contact.firstName} ${contact.lastName}` };
        }
        return row;
      });

      const updatedMessages = textMessages.map(message => {
        if (message._id === conversationId) {
          return { ...message, contact };
        } else {
          return message;
        }
      });

      setTextMessages(updatedMessages);

      setRows(updatedData);
      fullLoader(false);
      successToast('Successfully matched a contact to a conversation.');
    } catch (e) {
      errorToast('Error matching a contact to a conversation.');
      fullLoader(false);
    }
  };

  const handleEditMatchedContactBtnClick = id => {
    const updatedRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, isButtonVisible: true };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleEditMatchedContactBtnMouseLeave = id => {
    const updatedRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, isButtonVisible: false };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const renderMatchToContact = row => {
    if (isTextingHubDisabled) return;
    const { id, sender, isButtonVisible, unreadMsgsCount } = row;
    const shouldShowMatchToContactSelector =
      checkIfValidPhoneNumber(sender) || isButtonVisible;
    const matchToContactRender = (
      <span
        onClick={e => {
          e.stopPropagation();
        }}
        onMouseLeave={() => handleEditMatchedContactBtnMouseLeave(id)}
      >
        <ContactSelector
          placeholder={'Match to contact'}
          matchToContact={noteRouterId => {
            matchToContact({
              noteRouterId,
              messageId: row.id,
              conversationId: row.conversationId,
            });
          }}
          fetchedContactsRef={fetchedContactsRef}
        />
      </span>
    );

    const editMatchedContactRender = (
      <EditMatchedContact
        onClick={e => {
          e.stopPropagation();
          handleEditMatchedContactBtnClick(id);
        }}
        opacity={hoveredRow === id ? 1 : 0}
      >
        Edit matched contact
      </EditMatchedContact>
    );

    return (
      <CellContainer>
        <Row>
          {!!unreadMsgsCount && (
            <UnreadLabel>
              {unreadMsgsCount < 10 ? unreadMsgsCount : '9+'}
            </UnreadLabel>
          )}
          {sender}
        </Row>
        {shouldShowMatchToContactSelector
          ? matchToContactRender
          : editMatchedContactRender}
      </CellContainer>
    );
  };

  const columns = [
    {
      field: 'sender',
      headerName: 'Sender',
      width: 350,
      flex: 3,
      renderCell: ({ row }) => {
        return renderMatchToContact(row);
      },
    },
    {
      field: 'messagePreview',
      headerName: 'Message Preview',
      width: 350,
      flex: 4,
      renderCell: ({
        row: { messagePreview, systemMessage, keywordAutoreply },
      }) => {
        return messagePreview || keywordAutoreply || systemMessage;
      },
    },
    {
      field: 'lastActivity',
      headerName: 'Last Activity',
      minWidth: 180,
      flex: 2,
      renderCell: ({
        row: {
          lastActivity,
          status,
          senderType,
          systemMessage,
          keywordAutoreply,
          conversationId,
        },
      }) => (
        <CellContainer>
          <Column>
            <span>{formatDate(lastActivity)}</span>
            <SmallText>
              {formatTime(lastActivity)}
              {senderType === 'org' && !systemMessage && !keywordAutoreply
                ? ' - reply sent'
                : ''}
            </SmallText>
          </Column>
          <span>
            {status === 'archived' ? (
              <Line>
                <Archive style={{ fontSize: '18px', marginRight: '5px' }} />
                <>Archived</>
              </Line>
            ) : (
              ''
            )}
          </span>
          <span> {conversationId in viewers ? <ViewedIcon /> : ''} </span>
        </CellContainer>
      ),
    },
  ];

  const formatTextMessages = () => {
    return textMessages?.map(message => ({
      id: message._id,
      conversationId: message._id,
      sender: getContactFullNameOrPhoneNumber(message),
      messagePreview: message.body,
      status: message.status,
      lastActivity: message.createdAt,
      unreadMsgsCount: message.unreadMsgsCount,
      isMatchToContactVisible: false,
      senderType: message.senderType,
      systemMessage: message.systemMessage,
      keywordAutoreply: message.keywordAutoreply,
    }));
  };

  useEffect(() => {
    const rows = formatTextMessages();
    setRows(rows);
  }, [textMessages]);

  const requestSearch = searchValue => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const rows = formatTextMessages();
    const filteredRows = rows.filter(row => {
      // we need to search only by visible fields in the table
      return ['sender', 'messagePreview', 'lastActivity'].some(field => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows(filteredRows);
  };

  const classes = useStyles();

  const handleRowClick = event => {
    if (isTextingHubDisabled) return;
    onEventClick(event);
  };

  const handleRowSelection = selectedRow => {
    if (isTextingHubDisabled) return;
    setSelectedRows(selectedRow);
    onRowSelected(selectedRow);
  };

  return (
    <DataGrid
      className={classes.root}
      rows={rows}
      columns={columns}
      loading={isLoading}
      checkboxSelection
      onSelectionModelChange={handleRowSelection}
      selectionModel={selectedRows}
      disableSelectionOnClick
      pageSize={pageSize}
      onPageSizeChange={size => setPageSize(size)}
      onRowClick={handleRowClick}
      components={{ Toolbar: QuickSearch }}
      onRowEnter={handleRowEnter}
      onRowLeave={handleRowLeave}
      componentsProps={{
        toolbar: {
          value: searchText,
          onChange: event => requestSearch(event.target.value),
          clearSearch: () => requestSearch(''),
          status,
          setStatus,
          selectedMenuItemName: CONVERSATIONS,
        },
      }}
      getRowClassName={params => {
        if (params.row.status === 'archived' || params.row.status === 'spammed')
          return 'greyed-out-message';
        if (params.row.unreadMsgsCount) return 'unread-message';
      }}
    />
  );
};

export default TextMessagesTable;
