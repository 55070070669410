export function userOrganization(detail) {
  return {
    type: 'ORGANISATION',
    detail,
  };
}

//Dispatch organisation list to reducers
export function organizationList(detail) {
  return {
    type: 'ORGANIZATION_LIST',
    detail,
  };
}

//Dispatch organisation list to reducers
export function contentList(detail) {
  return {
    type: 'CONTENT_DETAILS',
    detail,
  };
}

export function orgUsers(detail) {
  return {
    type: 'ORG_USERS',
    detail,
  };
}

export function setOrgTypes(orgTypes) {
  return {
    type: 'SET_ORG_TYPES',
    detail: orgTypes,
  };
}
