import styled, { css } from 'styled-components';
import { Menu as AntdMenu } from 'antd';
import { Link, NavLink } from 'react-router-dom';

import { Color } from '../../theming';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const centerStyles = css`
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  ${({ $isCentered }) => $isCentered && centerStyles};
`;

export const FooterWrapper = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
`;

export const PrimaryMenu = styled(AntdMenu)`
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: transparent !important;
  overflow: hidden auto;
  border-right: none; // remove antd's default right border

  &.ant-menu-inline-collapsed {
    width: unset;
  }

  // Hide antd menu's default '::before' and '::after' which add-up 'gap' amount of space, because they become treated as flex-items
  &::before,
  &::after {
    display: none;
  }
`;

PrimaryMenu.Item = styled(PrimaryMenu.Item)`
  flex-shrink: 0;
  height: auto !important;
  padding: 8px 12px !important;
  border-radius: 10px;
  margin: 0 !important;

  &.ant-menu-item-selected {
    background-color: #ffffff !important;
  }

  &:is(&:focus, &:active, &:hover):not(.ant-menu-item-selected) {
    background-color: #ffffff33;
  }

  // hide default selected item border-ish indicator
  &::after {
    display: none;
  }
`;

PrimaryMenu.Item.Link = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  ${PrimaryMenu}.ant-menu-inline-collapsed & {
    padding-left: 0;
    padding-right: 0;
  }
`;

PrimaryMenu.Item.Icon = styled.span`
  color: #ffffff;
  transition: color 250ms;
  font-size: 30px !important;
  margin: 0 !important;

  ${PrimaryMenu.Item}.ant-menu-item-selected & {
    color: ${Color.PRIMARY};
  }
`;

PrimaryMenu.Item.Notification = styled.span`
  position: absolute;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  font-size: 9px;
  font-weight: 900;
  line-height: 18px;
  color: white;
  background-color: #ff6161;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 30px;
  top: 3px;
`;

PrimaryMenu.Item.Label = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  flex-grow: 1;
  color: #ffffff;
  transition: color 250ms;

  ${PrimaryMenu.Item}.ant-menu-item-selected & {
    font-weight: 600;
  }

  ${PrimaryMenu}.ant-menu-inline-collapsed && {
    display: none;
  }

  ${PrimaryMenu.Item}.ant-menu-item-selected & {
    color: ${Color.PRIMARY};
  }
`;

export const SecondaryMenu = styled.ul`
  margin-top: auto;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

SecondaryMenu.Item = styled.li`
  color: #ffffff;
`;

SecondaryMenu.Item.TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 5px 10px;
`;

const ClickableItemContainer = styled(SecondaryMenu.Item.TextContainer)`
  position: relative;
  cursor: pointer;
  transition: color 250ms;

  &[disabled] {
    color: #d3d3d3;
    cursor: not-allowed;
  }

  &:is(:hover, :focus, :active) {
    color: #c8e3ff;
  }
`;

SecondaryMenu.Item.LinkContainer = styled(ClickableItemContainer).attrs({
  as: Link,
})`
  color: inherit;
`;

SecondaryMenu.Item.ButtonContainer = styled(ClickableItemContainer).attrs({
  as: 'button',
})`
  background-color: transparent;
  border: none;
  outline: none;
  text-align: left;
`;

SecondaryMenu.Item.Icon = styled.span`
  font-size: 20px !important;
`;

SecondaryMenu.Item.Label = styled.span`
  flex-grow: 1;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: 400;
`;
