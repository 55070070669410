import styled from 'styled-components';

export const ButtonGroup = styled.span`
  display: flex;
  gap: 30px;
  padding: 0 10px;
`;

export const BackBtn = styled.button`
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  font-size: 14px;
  padding: 2px 15px;
  color: white;
  transition: all 0.3s ease-in;
  border-radius: 20px;
  &:hover,
  &.active {
    color: #238b22;
    cursor: pointer;
    background-color: white;
  }
`;

export const NextBtn = styled.button`
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  border: 1px solid #5eab00;
  padding: 5px 18px;
  background-color: #5eab00;
  color: white;
  transition: all 0.3s ease-in;
  border-radius: 20px;
  &:hover,
  &.active {
    color: #238b22;
    cursor: pointer;
    background-color: white;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: transparent;
  }
`;
