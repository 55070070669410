import React, { useState } from 'react';
import {
  Drawer,
  IconButton,
  TextField,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { Close, Storage } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { updateOrganization as updateOrganizationService } from '../../../../../redux/services';
import {
  Container,
  Header,
  Content,
  SaveBtn,
  Title,
  Group,
} from './EditTechnicalSettingsDrawer.styled';
import { Title as ContentTitle, SmallGapContent } from '../GeneralInfo.styled';
import {
  useToast,
  userOrganization,
  useUpdateUIState,
} from '../../../../../redux/action';
import { isEmpty } from '../../utils';

const EditTechnicalSettingsDrawer = ({
  selectedOrg,
  isEditTechnicalSettingsDrawerVisibile,
  setEditTechnicalSettingsDrawerVisibility,
}) => {
  const dispatch = useDispatch();
  const { errorToast, successToast } = useToast();
  const { fullLoader } = useUpdateUIState();
  const [textingHubEnabled, setTextingHubEnabled] = useState(
    selectedOrg?.textingHubEnabled || false
  );
  const [canEditTextPref, setEditTextPrefAbility] = useState(
    selectedOrg?.editTextPreferences || false
  );
  const [stripeCustomerID, setStripeCustomerID] = useState(
    selectedOrg?.stripe?.customerId || ''
  );
  const [twilioSID, setTwilioSID] = useState(selectedOrg?.twilioSID || '');
  const [twilioAuthToken, setTwilioAuthToken] = useState(
    selectedOrg?.twilioAuthToken || ''
  );

  const closeDrawer = () => {
    setEditTechnicalSettingsDrawerVisibility(false);
  };

  const checkIfRequiredFieldsAreMissing = () => {
    const missingRequiredFields = [];
    if (!twilioSID) {
      missingRequiredFields.push('Twilio SID');
    }

    if (!twilioAuthToken) {
      missingRequiredFields.push('Twilio Auth Token');
    }

    if (!missingRequiredFields.length) return false;

    errorToast(
      `Please fill out all the required fields: ${missingRequiredFields.join(
        ', '
      )}.`
    );

    return true;
  };

  const handleSubmit = async () => {
    if (checkIfRequiredFieldsAreMissing()) return;
    fullLoader(true, 'Updating technical settings...');
    closeDrawer();

    const orgData = {
      id: selectedOrg.id,
      textingHubEnabled,
      twilioSID: twilioSID.replace(/\s+/g, ''),
      twilioAuthToken: twilioAuthToken.replace(/\s+/g, ''),
      editTextPreferences: canEditTextPref,
      'stripe.customerId': stripeCustomerID.replace(/\s+/g, ''),
    };

    const updatedOrg = await updateOrganizationService(orgData);

    if (!updatedOrg) {
      errorToast('Failed to update technical settings.');
    } else {
      dispatch(userOrganization(updatedOrg));
      successToast('Successfully Updated Organization');
    }

    fullLoader(false);
  };

  return (
    <Drawer
      anchor={'right'}
      onClose={closeDrawer}
      open={isEditTechnicalSettingsDrawerVisibile}
    >
      <Container>
        <Header>
          <Group>
            <IconButton onClick={closeDrawer} style={{ color: 'white' }}>
              <Close />
            </IconButton>
            <Title>Edit: Technical Settings</Title>
          </Group>
          <SaveBtn onClick={handleSubmit}>Save</SaveBtn>
        </Header>
        <Content>
          <ContentTitle>
            <Storage size="small" />⁂ Technical Settings
          </ContentTitle>
          <TextField
            label="Stripe Customer ID"
            variant="outlined"
            size="small"
            value={stripeCustomerID}
            onChange={e => setStripeCustomerID(e.target.value)}
          />
          <TextField
            label="Twilio SID*"
            variant="outlined"
            size="small"
            value={twilioSID}
            onChange={e => setTwilioSID(e.target.value)}
            error={isEmpty(twilioSID)}
            helperText={isEmpty(twilioSID) ? 'Required field' : ''}
          />
          <TextField
            label="Twilio Auth Token*"
            variant="outlined"
            size="small"
            value={twilioAuthToken}
            onChange={e => setTwilioAuthToken(e.target.value)}
            error={isEmpty(twilioAuthToken)}
            helperText={isEmpty(twilioAuthToken) ? 'Required field' : ''}
          />
          <SmallGapContent>
            <FormControlLabel
              control={
                <Switch
                  checked={textingHubEnabled}
                  color="primary"
                  onChange={e => setTextingHubEnabled(e.target.checked)}
                />
              }
              label="Texting Hub enabled"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={canEditTextPref}
                  color="primary"
                  onChange={e => setEditTextPrefAbility(e.target.checked)}
                />
              }
              label="Users can edit contact texting preferences"
            />
          </SmallGapContent>
        </Content>
      </Container>
    </Drawer>
  );
};

export default EditTechnicalSettingsDrawer;
