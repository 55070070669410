import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import ErrorPage from './ErrorPage';
import store from '../../redux/store';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.configureScope(scope => {
      scope.setLevel('fatal');
      scope.setExtra('store', JSON.stringify(store.getState()));
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureMessage('EVENT BOUNDARY ERROR');
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
