import './OptinEditor.css';
import React from 'react';
import { Box } from 'grommet';
import { Col, Row } from 'antd';
import Quilview from '../../../../../Component/Quilview/Quilview';
import { Table } from 'grommet-icons';

const OptInEditor = ({ label, onTextChange, optinMessage }) => {
  return (
    <div>
      <div>
        <Table style={{ height: '15px' }} color={'#007ade'} />
        <h1
          style={{
            display: 'inline-flex',
            color: '#007ade',
            paddingLeft: '10px',
          }}
        >
          {label}
        </h1>
      </div>
      <Box className="quilsec">
        <Row>
          <Col span={8} />
        </Row>
        <Quilview
          isSimpleEditor
          value={optinMessage}
          ontextchange={newContent => onTextChange(newContent)}
          hasImageSupport
        />
      </Box>
    </div>
  );
};

export default OptInEditor;
