import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import EventAndClassRegistration from '../EventAndClassRegistration';
import CourseRegistration from '../CourseRegistration';
import EventAndClassAttendance from '../EventAndClassAttendance';
import CourseAttendance from '../CourseAttendance';
import GenerateRow from '../GenerateFilterRow';
import { getLabelBasedOnOrgType } from '../_shared/utils';
import {
  EVENT_AND_CLASS_REGISTRATION,
  COURSE_REGISTRATION,
  EVENT_AND_CLASSES,
  EVENT_AND_CLASS_ATTENDANCE,
  COURSE_ATTENDANCE,
} from '../_shared/constants';
import Container from './container.styled';
import { useIsFeatureEnabled } from '../../../../../../../../../libs/feature-toggles/react';
import Feature from '../../../../../../../../../infrastructure/features';

const ShowEventsAndClassesFilters = ({
  audienceFilters,
  filterTypes,
  selectedFilters,
  orgTypeName,
}) => {
  const isEventsAndClassesFeatureEnabled = useIsFeatureEnabled(
    Feature.EVENTS_AND_CLASSES
  );

  const eventAndClassesFilterType = filterTypes.filter(
    filter => filter.name === EVENT_AND_CLASSES
  )[0];

  const eventAndClassesFilters = audienceFilters.filter(
    filter => filter.filterType === eventAndClassesFilterType.id
  );

  const eventAndClassesExists = name => {
    return eventAndClassesFilters.some(filter => filter.name === name);
  };

  const [checkedEventAndClassRegistration, setEventAndClassRegistration] =
    useState(!!selectedFilters[EVENT_AND_CLASS_REGISTRATION]);
  const [checkedCourseRegistration, setCourseRegistration] = useState(
    !!selectedFilters[COURSE_REGISTRATION]
  );
  const [checkedEventAndClassAttendance, setEventAndClassAttendance] = useState(
    !!selectedFilters[EVENT_AND_CLASS_ATTENDANCE]
  );
  const [checkedCourseAttendance, setCourseAttendance] = useState(
    !!selectedFilters[COURSE_ATTENDANCE]
  );

  const eventAndClassesFiltersList = [
    {
      filterName: EVENT_AND_CLASS_REGISTRATION,
      checked: checkedEventAndClassRegistration,
      setChecked: setEventAndClassRegistration,
      ComponentToRender: EventAndClassRegistration,
    },
    {
      filterName: COURSE_REGISTRATION,
      checked: checkedCourseRegistration,
      setChecked: setCourseRegistration,
      ComponentToRender: CourseRegistration,
    },
    {
      filterName: EVENT_AND_CLASS_ATTENDANCE,
      checked: checkedEventAndClassAttendance,
      setChecked: setEventAndClassAttendance,
      ComponentToRender: EventAndClassAttendance,
    },
    {
      filterName: COURSE_ATTENDANCE,
      checked: checkedCourseAttendance,
      setChecked: setCourseAttendance,
      ComponentToRender: CourseAttendance,
    },
  ];

  return (
    <>
      {!isEventsAndClassesFeatureEnabled ? (
        <Container>
          To enable Event & Class registration, contact NoteRouter support using
          the &quot;Help&quot; button in the navigation bar, or via email at{' '}
          <Link
            to="#"
            onClick={e => {
              window.location.href = 'mailto:support@noterouter.com';
              e.preventDefault();
            }}
          >
            support@noterouter.com
          </Link>
          .
        </Container>
      ) : (
        eventAndClassesFiltersList.map(
          ({ filterName, checked, setChecked, ComponentToRender }) =>
            eventAndClassesExists(filterName) && (
              <GenerateRow
                key={filterName}
                check={checked}
                setCheck={setChecked}
                label={getLabelBasedOnOrgType(
                  filterName,
                  audienceFilters,
                  orgTypeName
                )}
              >
                <ComponentToRender
                  filterName={filterName}
                  checked={checked}
                  selectedFilters={selectedFilters[filterName]}
                />
              </GenerateRow>
            )
        )
      )}
    </>
  );
};

const mapStateToProps = state => ({
  audienceFilters: state.audience.audienceFilters,
  filterTypes: state.audience.filterTypes,
  selectedFilters: state.audience.selectedFilters,
  orgTypeName: state.audience.orgTypeName,
});

export default connect(mapStateToProps)(ShowEventsAndClassesFilters);
