import { useEffect, useRef } from 'react';

const DEFAULT_AUTOSAVE_INTERVAL = 1500;

const useAutosave = ({
  dependancyList,
  saveFunc,
  onUnsavedStatus,
  autosaveInterval = DEFAULT_AUTOSAVE_INTERVAL,
}) => {
  const isMounted = useRef(null);
  useEffect(() => {
    if (dependancyList.some(value => !!value)) {
      if (isMounted.current) {
        onUnsavedStatus();
        const nextSaveDelay = autosaveInterval ?? DEFAULT_AUTOSAVE_INTERVAL;
        if (nextSaveDelay) {
          const autosaveTimer = setTimeout(saveFunc, nextSaveDelay);

          return () => clearTimeout(autosaveTimer);
        } else {
          saveFunc();
        }
      } else {
        isMounted.current = true;
      }
    }
  }, dependancyList);
};

export default useAutosave;
