import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { Brush, EventAvailable } from '@material-ui/icons';
import { _sortBy } from 'underscore-es';

import { MenuItem } from '@material-ui/core';

import { useToggle } from '../../../../../libs/react-use-extra';
import { isDefined, mergeDeep } from '../../../../../libs/js-utils';
import {
  getOrgAssociations,
  hasOrgAssociations,
  isAmsSourcedOrg,
  OrganizationType,
} from '../../../../../redux/services/organization';
import { selectedOrganizationSelector } from '../../../../../redux/selectors/organization';
import { Order } from '../../../api';
import Button from '../../Button';
import { useEditor } from '../../CampaignEditor';
import {
  Body,
  ConditionPicker,
  ConditionPickerField,
  Container,
  EditButton,
  ExpandButton,
  Header,
  NameInput,
  Section,
  TemplateButtonWrapper,
  TemplateName,
  TemplatePicker,
} from './Stage.styled';
import { ValidityIndicator } from '../../../forms';
import { useToast } from '../../../../../redux/actions/UI';
import { useMeasure } from 'react-use';

const Unit = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

const Event = {
  JOIN_DATE: 'joinDate',
  BIRTH_DATE: 'birthDate',
  ORIENTATION_DATE: 'orientationDate',
  LICENSE_EXPIRATION_DATE: 'licenseExpirationDate',
  CREDIT_CARD_EXPIRATION_DATE: 'creditCardExpirationDate',
  LAST_PREFERENCE_PAGE_VISITED_DATE: 'lastPreferencePageVisitedDate',
  ADDED_DATE: 'addedDate',
  COE_DATE: 'coeDate',
};

const OrderVariant = {
  BOTH: 'both',
  BEFORE_ONLY: 'before-only',
  AFTER_ONLY: 'after-only',
};

const ORDERS_BY_ORDER_VARIANT = {
  [OrderVariant.BOTH]: [Order.BEFORE, Order.AFTER],
  [OrderVariant.BEFORE_ONLY]: [Order.BEFORE],
  [OrderVariant.AFTER_ONLY]: [Order.AFTER],
};

const ORDER_VARIANT_BY_EVENT = {
  [Event.LAST_PREFERENCE_PAGE_VISITED_DATE]: OrderVariant.AFTER_ONLY,
  [Event.JOIN_DATE]: OrderVariant.AFTER_ONLY,
  [Event.ORIENTATION_DATE]: OrderVariant.AFTER_ONLY,
  [Event.ADDED_DATE]: OrderVariant.AFTER_ONLY,
};

const LABELS_BY_EVENT = {
  [Event.JOIN_DATE]: 'Join Date',
  [Event.BIRTH_DATE]: 'Birthday',
  [Event.ORIENTATION_DATE]: 'Orientation Date',
  [Event.LICENSE_EXPIRATION_DATE]: 'License Expiration Date',
  [Event.CREDIT_CARD_EXPIRATION_DATE]: 'Credit Card Expiration Date',
  [Event.LAST_PREFERENCE_PAGE_VISITED_DATE]: 'Last Preference Page Visit Date',
  [Event.ADDED_DATE]: 'Added Date',
  [Event.COE_DATE]: 'Code of Ethics Expiration Date',
};

const AVAILABILITY_BY_EVENT = {
  [Event.JOIN_DATE]: [isAmsSourcedOrg, hasOrgAssociations],
  [Event.BIRTH_DATE]: true,
  [Event.ADDED_DATE]: true,
  [Event.COE_DATE]: false, //org => org.type === OrganizationType.RAMCO,  TODO TEMPORARILY DISABLED FOR PUSH TO PROD
  [Event.ORIENTATION_DATE]: [isAmsSourcedOrg, hasOrgAssociations],
  [Event.LICENSE_EXPIRATION_DATE]: isAmsSourcedOrg,
  [Event.LAST_PREFERENCE_PAGE_VISITED_DATE]: true,
  [Event.CREDIT_CARD_EXPIRATION_DATE]: org =>
    org.type === OrganizationType.MAGIC,
};

const getOrgAssociationsAsOptions = org => {
  const associations = getOrgAssociations(org);
  return associations.map(association => ({
    label: association,
    value: association,
  }));
};

const OPTION_GETTERS_BY_EVENT = {
  [Event.JOIN_DATE]: getOrgAssociationsAsOptions,
  [Event.ORIENTATION_DATE]: getOrgAssociationsAsOptions,
};

const useEvents = () => {
  const org = useSelector(selectedOrganizationSelector);
  return Object.values(Event).filter(event => {
    const availability = AVAILABILITY_BY_EVENT[event];
    if (Array.isArray(availability)) {
      return availability.every(isAvailable => isAvailable(org));
    } else if (typeof availability === 'function') {
      return availability(org);
    } else if (typeof availability === 'boolean') {
      return availability;
    }

    return false;
  });
};

const useOptions = event => {
  const org = useSelector(selectedOrganizationSelector);

  if (!event) {
    return [];
  }

  const optionGetter = OPTION_GETTERS_BY_EVENT[event];
  if (optionGetter) {
    return optionGetter(org);
  }

  return [];
};

const useUpdater = (value, onChange) => {
  const update = useCallback(
    updates => {
      onChange(mergeDeep(value, updates));
    },
    [onChange, value]
  );

  const updateName = useCallback(
    newName => {
      update({ name: newName });
    },
    [update]
  );

  const updateDelay = useCallback(
    newDelay => {
      update({
        conditions: [
          { delay: { value: newDelay ? Math.abs(newDelay) : newDelay } },
        ],
      });
    },
    [update]
  );

  const updateOrder = useCallback(
    newOrder => {
      update({
        conditions: [{ delay: { order: newOrder } }],
      });
    },
    [update]
  );

  const updateUnit = useCallback(
    newUnit => {
      update({
        conditions: [{ delay: { unit: newUnit } }],
      });
    },
    [update]
  );

  const updateEvent = useCallback(
    newEvent => {
      const { order, value: delayValue } = value.conditions[0].delay;
      const newOrderVariant =
        ORDER_VARIANT_BY_EVENT[newEvent] ?? OrderVariant.BOTH;
      const newAllowedOrders = ORDERS_BY_ORDER_VARIANT[newOrderVariant];

      const conditionUpdates = { name: newEvent };
      if (!newAllowedOrders.includes(order)) {
        const newOrder = newAllowedOrders[0];
        conditionUpdates.delay = { value: delayValue, order: newOrder };
      }
      update({
        conditions: [conditionUpdates],
      });
    },
    [update, value.conditions]
  );

  const updateOption = useCallback(
    newOption => {
      update({
        conditions: [{ option: { name: newOption } }],
      });
    },
    [update]
  );

  return useMemo(
    () => ({
      name: updateName,
      delay: updateDelay,
      unit: updateUnit,
      order: updateOrder,
      event: updateEvent,
      option: updateOption,
    }),
    [
      updateDelay,
      updateEvent,
      updateName,
      updateOption,
      updateOrder,
      updateUnit,
    ]
  );
};

const useValidator = ({ name, condition, template }) => {
  function validateName(value) {
    return typeof value === 'string' && value.trim().length > 0;
  }

  function validateCondition(value) {
    const isNameValid =
      typeof value.name === 'string' && value.name.trim().length > 0;
    const isDelayValid =
      typeof value.delay.value === 'number' &&
      isDefined(value.delay.unit) &&
      isDefined(value.delay.order);
    const isOptionValid =
      !value.option ||
      (typeof value.option.name === 'string' && value.option.name.length > 0);
    return isNameValid && isDelayValid && isOptionValid;
  }

  function validateTemplate(value) {
    return typeof value === 'object' && value !== null;
  }

  const fieldValidators = {
    name: validateName,
    template: validateTemplate,
    condition: validateCondition,
  };

  const getValidatorFor = field => {
    return fieldValidators[field];
  };

  const validStates = {
    name: getValidatorFor('name')(name),
    template: getValidatorFor('template')(template),
    condition: getValidatorFor('condition')(condition),
  };

  const check = (field = null) => {
    if (!field) {
      return check('name') && check('template') && check('condition');
    }

    return validStates[field];
  };

  return { check, for: getValidatorFor };
};

const Stage = (
  {
    className,
    value,
    onChange,
    isValidationVisible: _isValidationVisible = false,
  },
  ref
) => {
  const updater = useUpdater(value, onChange);
  const editor = useEditor();
  const { errorToast } = useToast();

  const nameInputRef = useRef(null);
  const [conditionPickerWrapperRef, { width: conditionPickerWidth }] =
    useMeasure();

  const [isExpanded, { toggle: toggleExpanded }] = useToggle(true);
  const [isCollapseTried, { on: markCollapseTried }] = useToggle(false);
  const isValidationVisible = _isValidationVisible || isCollapseTried;

  const {
    name,
    template,
    conditions: [condition],
  } = value;

  const validator = useValidator({ name, condition, template });

  useImperativeHandle(
    ref,
    () => ({
      isValid() {
        return validator.check();
      },
    }),
    [validator]
  );

  const { delay: compositeDelay, name: event } = condition;
  const option = condition.option?.name;

  const { value: delay, order, unit } = compositeDelay;
  const orderVariant = event
    ? ORDER_VARIANT_BY_EVENT[event] ?? OrderVariant.BOTH
    : OrderVariant.BOTH;
  const allowedOrders = ORDERS_BY_ORDER_VARIANT[orderVariant];
  const isBeforeOrderAllowed = allowedOrders.includes(Order.BEFORE);
  const isAfterOrderAllowed = allowedOrders.includes(Order.AFTER);

  const handleNameChange = useCallback(
    event => {
      const newName = event.target.value;
      updater.name(newName.length === 0 ? null : newName);
    },
    [updater]
  );

  const handleDelayChange = useCallback(
    event => {
      const newDelayRaw = event.target.value;
      if (!newDelayRaw) {
        return;
      }

      const newDelay = Number(newDelayRaw);
      const isNewDelayValidNumber = !Number.isNaN(newDelay);
      if (isNewDelayValidNumber) {
        updater.delay(newDelay);
      }
    },
    [updater]
  );

  const handleUnitChange = useCallback(
    event => {
      const newUnit = event.target.value;
      updater.unit(newUnit);
    },
    [updater]
  );

  const handleOrderChange = useCallback(
    event => {
      const newOrder = event.target.value;
      updater.order(newOrder);
    },
    [updater]
  );

  const handleEventChange = useCallback(
    event => {
      updater.event(event.target.value);
    },
    [updater]
  );

  const handleOptionChange = useCallback(
    event => {
      updater.option(event.target.value);
    },
    [updater]
  );

  const openTemplatePicker = useCallback(() => {
    editor.goToStep('template/picker', { stage: value._id });
  }, [editor, value._id]);

  const handleExpandButtonClick = useCallback(() => {
    if (!isCollapseTried && isExpanded) {
      markCollapseTried();
    }

    const isValid = validator.check();
    if (isValid || !isExpanded) {
      toggleExpanded();
    }

    if (!isValid) {
      errorToast(
        "Can't collapse a stage with invalid fields. Please correct them first.",
        5
      );
    }
  }, [
    errorToast,
    isCollapseTried,
    isExpanded,
    markCollapseTried,
    toggleExpanded,
    validator,
  ]);

  const focusNameInput = useCallback(() => {
    nameInputRef.current.focus();
  }, []);

  const events = useEvents();
  const sortedEvents = _sortBy(events, event => LABELS_BY_EVENT[event]);
  const options = useOptions(event);

  return (
    <Container className={className}>
      <Header>
        <ExpandButton
          isExpanded={isExpanded}
          onClick={handleExpandButtonClick}
        />
        <EditButton onClick={focusNameInput} />
        <NameInput
          inputRef={nameInputRef}
          value={name ?? ''}
          onChange={handleNameChange}
          placeholder="Enter Stage Name"
        />
        <ValidityIndicator
          isValid={validator.check('name')}
          isVisible={isValidationVisible}
        />
      </Header>
      {isExpanded && (
        <Body>
          <Section>
            <Section.Icon as={EventAvailable} />
            <Section.Title>
              When the following condition is{' '}
              <Section.Title.Highlight>true</Section.Title.Highlight> for a
              contact in the Audience...
            </Section.Title>
            <Section.Content>
              <ConditionPickerField ref={conditionPickerWrapperRef}>
                <ConditionPicker>
                  <ConditionPicker.DelayInput
                    inputRef={(() => {
                      let node = null;

                      return _node => {
                        const focusListener = event => {
                          event.target.select();
                        };

                        if (_node) {
                          node = _node;
                          node.addEventListener('focus', focusListener);
                        } else {
                          node.removeEventListener('focus', focusListener);
                        }
                      };
                    })()}
                    value={delay ?? ''}
                    onChange={handleDelayChange}
                  />
                  <ConditionPicker.UnitSelect
                    value={unit ?? ''}
                    onChange={handleUnitChange}
                  >
                    <MenuItem value={Unit.DAY}>day(s)</MenuItem>
                    <MenuItem value={Unit.WEEK}>week(s)</MenuItem>
                    <MenuItem value={Unit.MONTH}>month(s)</MenuItem>
                    <MenuItem value={Unit.YEAR}>year(s)</MenuItem>
                  </ConditionPicker.UnitSelect>
                  <ConditionPicker.OrderSelect
                    value={order ?? ''}
                    onChange={handleOrderChange}
                  >
                    {isAfterOrderAllowed && (
                      <MenuItem value={Order.AFTER}>after</MenuItem>
                    )}
                    {isBeforeOrderAllowed && (
                      <MenuItem value={Order.BEFORE}>before</MenuItem>
                    )}
                  </ConditionPicker.OrderSelect>
                  <ConditionPicker.EventSelect
                    value={event ?? ''}
                    onChange={handleEventChange}
                    displayEmpty
                  >
                    <MenuItem style={{ display: 'none' }} value="">
                      [Event]
                    </MenuItem>
                    {sortedEvents.map(event => {
                      return (
                        <MenuItem key={event} value={event}>
                          {LABELS_BY_EVENT[event]}
                        </MenuItem>
                      );
                    })}
                  </ConditionPicker.EventSelect>
                  {options.length > 0 && (
                    <>
                      <ConditionPicker.OptionSeparator>
                        of
                      </ConditionPicker.OptionSeparator>
                      <ConditionPicker.OptionSelect
                        value={option ?? ''}
                        onChange={handleOptionChange}
                        placeholder="Option"
                        displayEmpty
                      >
                        {options.map(({ value, label }) => {
                          return (
                            <MenuItem key={value} value={value}>
                              {label}
                            </MenuItem>
                          );
                        })}
                      </ConditionPicker.OptionSelect>
                    </>
                  )}
                </ConditionPicker>
                <ValidityIndicator
                  isVisible={isValidationVisible}
                  isValid={validator.check('condition')}
                />
              </ConditionPickerField>
            </Section.Content>
          </Section>
          <Section>
            <Section.Icon as={Brush} />
            <Section.Title>
              ... Send a message to that contact using this template:
            </Section.Title>
            <Section.Content>
              <TemplatePicker>
                {template && (
                  <TemplateName
                    width={conditionPickerWidth}
                    value={template.name}
                    isValidationVisible={isValidationVisible}
                    validate={() => validator.for('template')(template)}
                  />
                )}
                <TemplateButtonWrapper>
                  <Button onClick={openTemplatePicker}>
                    {template ? 'Change' : 'Select'} Message Template
                  </Button>
                  {!validator.check('template') && (
                    <ValidityIndicator
                      isVisible={isValidationVisible}
                      isValid={validator.check('template')}
                    />
                  )}
                </TemplateButtonWrapper>
              </TemplatePicker>
            </Section.Content>
          </Section>
        </Body>
      )}
    </Container>
  );
};

export default forwardRef(Stage);
