import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Select } from 'antd';
import debounce from 'lodash/debounce';
import {
  fetchEventIdsAndNamesService,
  fetchEventById,
} from '../../../features/events-and-classes/api';
import { addEventId } from '../../../redux/services';
import { useDispatch } from 'react-redux';
import { toast } from '../../../redux/actions/UI';

const EventIdMessageUpdateSelector = ({
  isEventIdRequired,
  eventIdSelectionRef,
  eventId,
  setEventId,
  message,
}) => {
  const { Option } = Select;
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const pageRef = useRef(0);
  const debounceTimeout = 800;
  const dispatch = useDispatch();

  const fetchByEventId = async eventId => {
    const event = await fetchEventById(eventId);
    const normalizedEventData = transformEventData(event);

    setEventData([normalizedEventData]);
  };

  const fetchEventData = async searchText => {
    setLoading(true);

    if (searchText) {
      setEventData([]);
      pageRef.current = 0;
      setEventId(null);
    }

    const eventsandclassesData = await fetchEventIdsAndNamesService({
      page: pageRef.current,
      searchValue: searchText || searchValue,
    });

    const normalizedEventsAndClassesData =
      eventsandclassesData.map(transformEventData);

    const sortedEventsAndClassesData = [
      ...eventData,
      ...normalizedEventsAndClassesData,
    ].sort((a, b) => a.name.localeCompare(b.name));

    setEventData(sortedEventsAndClassesData);
    setLoading(false);
    pageRef.current += 1;
  };

  const getDisplayByEventId = eventId => {
    const event = eventData.filter(item => item.value === eventId)[0];
    return event ? `[ID: ${event.value}] ${event.name}` : eventId;
  };

  const transformEventData = event => {
    return {
      value: event.eventId,
      name: event.name,
      display: `[ID: ${event.eventId}] ${event.name}`,
    };
  };

  const handleSearch = useMemo(() => {
    const fetchData = async value => {
      setSearchValue(value);
      await fetchEventData(value);
    };

    return debounce(fetchData, debounceTimeout);
  }, [fetchEventData, debounceTimeout]);

  const handleScroll = async ({ target }) => {
    if (
      !loading &&
      // doing this trick with '+ 2' to trigger scrolling when mouse is close to the end of data in dropdown select
      target.scrollTop + target.offsetHeight + 2 > target.scrollHeight
    ) {
      target.scrollTo(0, target.scrollHeight);
      fetchEventData();
    }
  };

  const onSelectChange = async eventId => {
    setEventId(eventId);
    if (!eventId) {
      eventId = '';
    }
    await addEventId(message?.id, eventId);
    if (!eventId) {
      dispatch(toast('error', 'Event connection removed'));
    } else {
      dispatch(toast('success', 'Event connection selected'));
    }
  };

  useEffect(() => {
    if (eventId) {
      fetchByEventId(eventId);
    }
  }, []);

  useEffect(() => {
    if (isEventIdRequired) {
      fetchEventData();
    }
  }, [isEventIdRequired]);

  return (
    <Select
      ref={eventIdSelectionRef}
      placeholder="Connected Event/Class - click to select"
      filterOption={false}
      showSearch
      onSearch={handleSearch}
      onPopupScroll={handleScroll}
      loading={loading}
      value={
        eventId ? `Connected Event: ${getDisplayByEventId(eventId)}` : null
      }
      style={{
        width: 'content',
        color: 'black',
        border: '1px solid darkgray',
        borderRadius: '4px',
      }}
      size="large"
      onChange={eventId => onSelectChange(eventId)}
      dropdownMatchSelectWidth={false}
      allowClear
      bordered={false}
    >
      {eventData.map(({ value, display }) => {
        return (
          <Option key={value} value={value}>
            {display}
          </Option>
        );
      })}
    </Select>
  );
};

export default EventIdMessageUpdateSelector;
