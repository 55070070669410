import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import StarIcon from '@material-ui/icons/StarRounded';

import { PortalInfo } from './PortalInfo';
import { OptinFooter } from './OptinFooter';
import { TimeZoneDropdown } from '../TimeZone';
import { MissingPhone } from './MissingPhone';
import { OptinHeader } from './OptinHeader';
import { PreferenceTable } from '../PreferenceTable';
import { FullSpinner } from '../FullSpinner';

import { gAnalytics, getQueryStringValue } from '../../redux/helpers/index';
import {
  PreferenceContext,
  useUpdateMemberPreferences,
  useGetPreferenceDetails,
} from '../../redux/services';

import {
  FormContainer,
  MainContainer,
  StyledContactInfo,
  SaveBtnStyled,
} from './OptinMessage.styled';
import { RequestPreferenceModal } from './RequestPrefModal';

export const OptinMessage = () => {
  const {
    member,
    organization,
    topics,
    loading,
    setEmailTopics,
    setTextTopics,
  } = useContext(PreferenceContext);
  const [timezone, setTimezone] = useState();
  const [tabTitle, setTabTitle] = useState(
    `${organization?.acronym} - Communication Preferences`
  );

  const { getOptinMember, parseOrgQueryString } = useGetPreferenceDetails();

  const { updateMemberPreferences } = useUpdateMemberPreferences();

  //newEmailTopics
  const handleChange = (isAdded, id) => {
    if (isAdded) {
      setEmailTopics(tags => {
        tags[id] = id;
        return tags;
      });
    } else {
      setEmailTopics(tags => {
        const topics = { ...tags };
        delete topics[id];
        return topics;
      });
    }
  };

  //newTextTopics
  const handleTextChange = (isAdded, id) => {
    if (isAdded) {
      setTextTopics(texts => {
        texts[id] = id;
        return texts;
      });
    } else {
      setTextTopics(texts => {
        const topics = { ...texts };
        delete topics[id];
        return topics;
      });
    }
  };

  const handleOptinSubmit = () => {
    updateMemberPreferences(timezone);
  };

  const featuredTopics = useMemo(() => {
    return topics?.filter(
      topic =>
        (topic.highlightTopic && member.isMember && topic.availableTo.member) ||
        (topic.highlightTopic &&
          !member.isMember &&
          topic.availableTo.nonMember)
    );
  }, [topics]);

  const interestTopics = useMemo(() => {
    return topics?.filter(
      topic =>
        (!topic.highlightTopic &&
          member.isMember &&
          topic.availableTo.member) ||
        (!topic.highlightTopic &&
          !member.isMember &&
          topic.availableTo.nonMember)
    );
  }, [topics]);

  const getOrgAcronymFromPath = () => {
    return decodeURIComponent(window.location.pathname).substring(10).trim();
  };

  useEffect(() => {
    if (member?.timezone?.zone && member?.timezone?.vsUTC) {
      setTimezone(member.timezone);
    } else setTimezone(organization?.timezone);
  }, [member?.timezone, organization?.timezone]);

  useEffect(() => {
    gAnalytics('pageview', '/preference');
    const orgLandingPage = getQueryStringValue('org');
    if (orgLandingPage) {
      const orgAcronym = getOrgAcronymFromPath();
      parseOrgQueryString(orgAcronym, orgLandingPage);
      setTabTitle(`${orgAcronym} - Communication Preferences`);
    } else {
      getOptinMember();
    }
  }, []);

  useEffect(() => {
    document.title = tabTitle;
  }, [tabTitle]);

  if (loading) return <FullSpinner />;

  return (
    <MainContainer hasUserInfo={!!getQueryStringValue('id')}>
      <FormContainer hasUserInfo={!!getQueryStringValue('id')}>
        <OptinHeader />
        <PreferenceTable
          header={[
            {
              title: 'Featured Topics',
              icon: (
                <StarIcon
                  style={{
                    color: '#FFB300',
                    verticalAlign: 'middle',
                  }}
                />
              ),
            },
            { title: 'Email' },
            { title: 'Text' },
          ]}
          data={featuredTopics}
          handleChange={handleChange}
          handleTextChange={handleTextChange}
        />
        <PreferenceTable
          header={[
            { title: 'Topics of Interest' },
            { title: 'Email' },
            { title: 'Text' },
          ]}
          data={interestTopics}
          handleChange={handleChange}
          handleTextChange={handleTextChange}
        />
        <MissingPhone />
        <PortalInfo />
        <StyledContactInfo>
          <div>
            <label>Email</label>
            <TextField
              variant="outlined"
              disabled
              fullWidth
              size="small"
              value={member?.email}
            />
          </div>
          <div>
            <label>Cell Phone</label>
            <TextField
              variant="outlined"
              disabled
              fullWidth
              size="small"
              value={member?.phoneNumber}
            />
          </div>
          <TimeZoneDropdown
            handleChange={e => setTimezone(e)}
            timezone={timezone}
          />
        </StyledContactInfo>
        <SaveBtnStyled>
          <Button
            label="Save"
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleOptinSubmit}
          >
            Save Preferences
          </Button>
        </SaveBtnStyled>
        <OptinFooter organizationName={organization?.organizationName} />
      </FormContainer>
      <RequestPreferenceModal
        setTabTitle={setTabTitle}
        organization={organization}
      />
    </MainContainer>
  );
};
