import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableCell } from 'material-table';
import { Close, Mail, Smartphone } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import * as moment from 'moment';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Container,
  Header,
  Content,
  Title,
} from '../EventDetailsDrawer/EventDetailsDrawer.styled';
import { fetchContactEngagement } from '../api';
import {
  MessageDetailsContainer,
  MessageDetailsTitle,
  Box,
  MessageDetailsContent,
  Em,
} from './ContactDetailsDrawer.styled';

const RED = '#ff6161';
const GREEN = '#5eab00';
const BLUE = '#007ade';
const GREY = '#616161';

const statusToColor = {
  Clicked: GREEN,
  Opened: BLUE,
  Dropped: RED,
  Bounced: RED,
  Blocked: RED,
  Spammed: RED,
  'Not Delivered': RED,
  Undelivered: RED,
  Sent: GREY,
  Delivered: GREY,
};

const ContactDetails = ({ event, onClose, contact }) => {
  const [engagementData, setEngagementData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const { height } = useWindowDimensions();

  const columns = [
    {
      field: 'sendTime',
      title: 'Sent Date',
      width: '40%',
    },
    {
      field: 'messageSubject',
      title: 'Message Subject',
      width: '60%',
    },
  ];

  const colorIfMsgIsUnsuccessful = color => {
    return color === '#ff6161' ? '#ff6161' : '';
  };

  const formatTime = timestamp => {
    return moment(timestamp).format('MM/DD/YYYY [at] h:mm a');
  };

  const getSentTime = ({ emailLog, textLog }) => {
    const time =
      (emailLog?.engagement && emailLog.engagement[0].timestamp) ||
      (textLog?.engagement && textLog.engagement[0].timestamp);
    return moment(time).format('MM/DD/YYYY');
  };

  const getEmptyMessage = () => {
    return isLoading ? (
      <>No records to display</>
    ) : (
      <>
        No sent messages found for this event. <br />
        <br />
        To connect a previously-sent message to an event, select a connected
        Event/Class for the message in the Analytics screen.
      </>
    );
  };

  useEffect(() => {
    const fetchEngagement = async () => {
      const engagement = await fetchContactEngagement({
        noteRouterId: contact.NRContactId,
        eventId: event.id,
      });

      setEngagementData(engagement);

      const rows = engagement
        ?.filter(
          engagement => engagement.emailLog.status || engagement.textLog.status
        )
        .map(message => ({
          _id: message.id,
          sendTime: getSentTime(message),
          messageSubject: message.messageSubject,
        }));

      setRows(rows);
      setIsLoading(false);
    };

    fetchEngagement();
  }, []);

  const detailPanel = data => {
    const engagement = engagementData.find(
      engagement => engagement.id === data._id
    );

    return (
      <MessageDetailsContainer key={data._id}>
        <MessageDetailsTitle key="status" paddingTop="0">
          Status:
        </MessageDetailsTitle>
        {renderMessageStatus(engagement)}
        <MessageDetailsTitle key="engagement">Engagement:</MessageDetailsTitle>
        {renderEnagement(engagement)}
        <MessageDetailsTitle key="activity">Activity Log:</MessageDetailsTitle>
        {renderActivityLog(engagement)}
      </MessageDetailsContainer>
    );
  };

  const renderMessageStatus = ({ emailLog, textLog }) => {
    const mailColor =
      (emailLog.status && statusToColor[emailLog.status]) || GREY;
    const textColor = (textLog.status && statusToColor[textLog.status]) || GREY;
    return (
      <Box>
        <MessageDetailsContent width="200px" color={mailColor}>
          <Mail
            style={{
              color: mailColor,
              fontSize: '16px',
            }}
          />
          {emailLog.status || 'N/A'}
        </MessageDetailsContent>
        <MessageDetailsContent width="70%" color={textColor}>
          <Smartphone
            style={{
              color: textColor,
              fontSize: '16px',
              marginLeft: '-3px',
            }}
          />
          {textLog.status || 'N/A'}
        </MessageDetailsContent>
      </Box>
    );
  };

  const renderEnagement = ({ emailLog }) => {
    return (
      <Box>
        <MessageDetailsContent width="200px">
          Opens:
          {emailLog.engagement ? (
            <Em color={statusToColor['Opened']}>
              {
                emailLog.engagement.filter(
                  engagement => engagement.status === 'Opened'
                ).length
              }
            </Em>
          ) : (
            ' 0'
          )}
        </MessageDetailsContent>
        <MessageDetailsContent width="70%">
          Clicks:
          {emailLog.engagement ? (
            <Em color={statusToColor['Clicked']}>
              {
                emailLog.engagement.filter(
                  engagement => engagement.status === 'Clicked'
                ).length
              }
            </Em>
          ) : (
            ' 0'
          )}
        </MessageDetailsContent>
      </Box>
    );
  };

  const renderActivityLog = ({ emailLog, textLog }) => {
    const logs = [
      ...((emailLog.engagement &&
        emailLog.engagement.map(log => ({ ...log, email: true }))) ||
        []),
      ...((textLog.engagement &&
        textLog.engagement.map(log => ({ ...log, text: true }))) ||
        []),
    ].sort((logA, logB) => new Date(logA.timestamp) - new Date(logB.timestamp));

    return logs.map(({ status, timestamp, email }) => {
      const Icon = email ? (
        <Mail
          style={{
            color: statusToColor[status],
            fontSize: '16px',
          }}
        />
      ) : (
        <Smartphone
          style={{ color: statusToColor[status], fontSize: '16px' }}
        />
      );

      return (
        <Box key={timestamp - status}>
          <MessageDetailsContent
            width="200px"
            color={colorIfMsgIsUnsuccessful(statusToColor[status])}
          >
            {Icon}
            {status}
          </MessageDetailsContent>
          <MessageDetailsContent
            width="70%"
            color={colorIfMsgIsUnsuccessful(statusToColor[status])}
          >
            {formatTime(timestamp)}
          </MessageDetailsContent>
        </Box>
      );
    });
  };

  return (
    <Container width={'40vw'}>
      <Header>
        <IconButton onClick={onClose}>
          <Close style={{ color: 'white' }} />
        </IconButton>
        {event.name}: {contact.firstName} {contact.lastName}&apos;s Engagement
      </Header>
      <Content>
        <Title>Details</Title>
        <MaterialTable
          isLoading={isLoading}
          components={{
            Cell: props => <MTableCell {...props} />,
          }}
          detailPanel={data => detailPanel(data)}
          columns={columns}
          data={rows}
          options={{
            showTitle: false,
            search: false,
            pageSize: Math.round((height - 300) / 48),
            toolbar: false,
            headerStyle: {
              fontWeight: 'bold',
            },
            pageSizeOptions: [],
          }}
          onRowClick={(e, data, togglePanel) => togglePanel()}
          localization={{
            body: {
              emptyDataSourceMessage: getEmptyMessage(),
            },
          }}
        />
      </Content>
    </Container>
  );
};

export default ContactDetails;
