import React, { useState } from 'react';
import SyncIcon from '@material-ui/icons/Sync';
import { RightContainer, Row, Title, EditBtn } from '../AMSSync.styled';
import {
  Container,
  Text,
  BubbleContainer,
  Bubble,
} from './SyncSettings.styled';
import EditSyncSettingsDrawer from '../EditSyncSettingsDrawer';

const SyncSettings = ({ userPrivileges, organization, orgTypes }) => {
  const [isEditSyncSettingsDrawerVisible, setEditSyncSettingsDrawerVisibility] =
    useState(false);

  return (
    <RightContainer>
      <Row>
        <Title>
          <SyncIcon size="small" />
          Sync Settings
        </Title>
        {userPrivileges.isSU && (
          <EditBtn
            onClick={() => {
              setEditSyncSettingsDrawerVisibility(
                !isEditSyncSettingsDrawerVisible
              );
            }}
          >
            Edit
          </EditBtn>
        )}
      </Row>
      <br />
      {organization?.assocStatus?.length ? (
        <Container>
          <Text>Synced Statuses:</Text>
          <BubbleContainer>
            {organization.assocStatus.map(assocStatus => (
              <Bubble key={assocStatus}>{assocStatus}</Bubble>
            ))}
          </BubbleContainer>
        </Container>
      ) : (
        ''
      )}
      {organization?.associationIDs?.length ? (
        <Container>
          <Text>Synced Memberships:</Text>
          <BubbleContainer>
            {organization.associationIDs.map(assocID => (
              <Bubble key={assocID}>{assocID}</Bubble>
            ))}
          </BubbleContainer>
        </Container>
      ) : (
        ''
      )}
      {organization?.excludingBillTypes?.length ? (
        <Container>
          <Text>Excluded Subtypes:</Text>
          <BubbleContainer>
            {organization.excludingBillTypes.map(billType => (
              <Bubble key={billType}>{billType}</Bubble>
            ))}
          </BubbleContainer>
        </Container>
      ) : (
        ''
      )}
      {organization?.phoneType ? (
        <Container>
          <Text>Synced Phone Field:</Text>
          <Bubble>{organization.phoneType}</Bubble>
        </Container>
      ) : (
        ''
      )}
      {organization?.listCategories?.length ? (
        <Container>
          <Text>Synced AMS Lists:</Text>
          <BubbleContainer>
            {organization.listCategories.map(listCategory =>
              Object.entries(listCategory).map(([category, code]) => (
                <Bubble key={category}>
                  {category} - {code}
                </Bubble>
              ))
            )}
          </BubbleContainer>
        </Container>
      ) : (
        ''
      )}
      {isEditSyncSettingsDrawerVisible && (
        <EditSyncSettingsDrawer
          selectedOrg={organization}
          isEditSyncSettingsDrawerVisible={isEditSyncSettingsDrawerVisible}
          setEditSyncSettingsDrawerVisibility={
            setEditSyncSettingsDrawerVisibility
          }
          orgTypes={orgTypes}
        />
      )}
    </RightContainer>
  );
};

export default SyncSettings;
