import React, { useState, useEffect } from 'react';
import { SwipeableDrawer } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import TextMessagesTable from '../TextMessagesTable/TextMessagesTable';
import TextMessagesDrawer from '../TextMessagesDrawer';
import {
  fetchTextMessagesByConversationId,
  fetchOrgTextMessages,
  getUnreadTextCount,
  markMessageAsRead,
} from '../api';
import {
  unreadTextMessagesCountSelector,
  lastMessageIdSentByOrg,
} from '../../../redux/selector';
import { resetUnreadCount, fullloader } from '../../../redux/action';

const Conversations = ({
  onRowSelected,
  textMessages,
  setTextMessages,
  isTextingHubDisabled,
  orgId,
  status,
  setStatus,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [conversationMessages, setConversationMessages] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const unreadMessagesCount = useSelector(unreadTextMessagesCountSelector);
  const lastMsgIdSentByOrg = useSelector(lastMessageIdSentByOrg);

  const getTextMessages = async () => {
    setLoading(true);
    setTextMessages([]);
    const texts = await fetchOrgTextMessages({ status });
    setTextMessages(texts);
    setLoading(false);
  };

  const getTextMessagesWithoutLoading = async () => {
    const texts = await fetchOrgTextMessages({ status });
    setTextMessages(texts);
  };

  const resetUnreadMessages = async () => {
    const unreadCount = await getUnreadTextCount();
    dispatch(resetUnreadCount(unreadCount));
  };

  useEffect(() => {
    getTextMessages();
  }, [status]);

  useEffect(() => {
    getTextMessages();
    resetUnreadMessages();
  }, [orgId]);

  useEffect(() => {
    getTextMessagesWithoutLoading();
    resetUnreadMessages();
  }, [unreadMessagesCount, lastMsgIdSentByOrg]);

  const toggleDrawer = isOpen => {
    if (isTextingHubDisabled) return;
    setDrawerOpen(isOpen);
  };

  const openTextMessagesDrawer = async ({ row: message }) => {
    dispatch(fullloader(true, 'Loading text messages...'));

    const selectedMsg = textMessages.filter(msg => msg._id === message.id)[0];
    setSelectedMessage(selectedMsg);

    if (message.unreadMsgsCount) {
      await markMessageAsRead(message.conversationId);
      const unreadCount = await getUnreadTextCount();
      dispatch(resetUnreadCount(unreadCount));
    }
    const conversationMessages = await fetchTextMessagesByConversationId(
      message.conversationId
    );
    setConversationMessages(conversationMessages);
    toggleDrawer(true);
    dispatch(fullloader(false));
  };

  return (
    <>
      <TextMessagesTable
        onRowSelected={onRowSelected}
        textMessages={textMessages}
        setTextMessages={setTextMessages}
        onEventClick={openTextMessagesDrawer}
        isLoading={isLoading}
        isTextingHubDisabled={isTextingHubDisabled}
        setStatus={setStatus}
        status={status}
      />
      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <TextMessagesDrawer
          onClose={() => toggleDrawer(false)}
          conversationMessages={conversationMessages}
          message={selectedMessage}
          setTextMessages={setTextMessages}
          isDrawerOpen={isDrawerOpen}
        />
      </SwipeableDrawer>
    </>
  );
};

export default Conversations;
