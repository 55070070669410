import api from '../../utils/andromeda-api';
// const andromedaEndpoint =  'http://localhost:3333';

// Api for privileges list
export async function validatePrivilege(userId, orgId, requiredPrivilege) {
  try {
    const payload = { userId, orgId, requiredPrivilege };

    const hasAccess = await (
      await api.post('/privileges/doesUserHavePrivilege', payload)
    ).data.result;

    return hasAccess;
  } catch (error) {
    return false;
  }
}

export async function validatePrivilegeBatch(
  userId,
  orgId,
  requiredPrivileges
) {
  try {
    const payload = { userId, orgId, requiredPrivileges };

    const accessList = await api.post(
      '/privileges/doesUserHavePrivilegeBatch',
      payload
    );

    return accessList;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getPrivilegesList() {
  try {
    const priviledgesRes = await api.get('/privileges');

    if (priviledgesRes) {
      let data = {};
      await priviledgesRes.data.result.forEach(prvl => {
        data[prvl.name] = prvl.id;
      });
      return data;
    }
    return {};
  } catch (e) {
    console.error('Error retrieving privileges [getPrivilegesList]', e);
    return {};
  }
}

export async function getRoles() {
  try {
    const roles = await api.get('/privileges/getRoles');

    return roles.data.result;
  } catch (error) {
    console.log('Error getting roles', error);
    return false;
  }
}
