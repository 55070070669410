import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleButtonLeft = styled.button`
  color: ${props => (props.isActive ? '#ffffff' : '#5eab02')};
  border: 1px solid #888888;
  font-size: 20px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right-style: none;
  padding: 1px 14px;
  font-weight: bold;
  transition: all 0.3s ease-in;
  background-color: ${props => (props.isActive ? '#5eab02' : '#ffffff')};
  margin: 0;
  &:hover,
  &.active {
    color: ${props => (props.isActive ? '#ffffff' : '#5eab02')};
    cursor: pointer;
    background-color: ${props => (props.isActive ? '#5eab02' : '#ffffff')};
  }
`;

export const ToggleButtonRight = styled.button`
  color: ${props => (props.isActive ? '#ffffff' : '#5eab02')};
  border: 1px solid #888888;
  font-size: 20px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 1px 14px;
  font-weight: bold;
  transition: all 0.3s ease-in;
  background-color: ${props => (props.isActive ? '#5eab02' : '#ffffff')};
  margin: 0;
  &:hover,
  &.active {
    color: ${props => (props.isActive ? '#ffffff' : '#5eab02')};
    cursor: pointer;
    background-color: ${props => (props.isActive ? '#5eab02' : '#ffffff')};
  }
`;

export const ToggleButtonGroup = styled.div`
  display: flex;
`;
