import { useRef } from 'react';

const useStableCallback = cb => {
  const cbRef = useRef();
  if (cbRef.current !== cb) {
    cbRef.current = cb;
  }

  const ref = useRef((...args) => {
    return cbRef.current(...args);
  });
  return ref.current;
};

export default useStableCallback;
