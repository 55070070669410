import api from '../../utils/andromeda-api';

export async function sendMail(mail) {
  try {
    const response = await api.post('/email/send', mail);
    return response.data;
  } catch (e) {
    return console.error('Error inserting message');
  }
}

export async function sendTestMessage(mail) {
  try {
    const response = await api.post('/email/test-message', mail);
    return response.data;
  } catch (e) {
    return console.error('Error inserting message');
  }
}
