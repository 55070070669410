import styled from 'styled-components';

export const Card = styled.div`
  font-family: Poppins, sans-serif;
  min-width: 200px;
  max-width: calc((100% - 400px) * (1 / 6));
  aspect-ratio: 3/5;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.4);
  flex: 1 1 auto;
  display: block;
  background-color: #fff;
  border-radius: 4px;
  transition: 400ms ease;
  text-align: center;
  overflow: hidden;
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};
  &:hover {
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.8);
  }
  @media (min-width: 1820px) {
    width: calc((100% - 300px) * (1 / 7));
  }
  @media (min-width: 2250px) {
    aspect-ratio: 3/4;
  }
`;

Card.Body = styled.div`
  text-align: center;
  padding: 20% 20px;
  cursor: pointer;
`;

Card.Date = styled.p`
  font-size: 10px;
  height: 18px;
`;

Card.Name = styled.h5`
  height: 45px;
  font-weight: bold;
`;

Card.AB = styled.h6`
  color: green;
  position: absolute;
  z-index: 1;
  text-align: left;
`;

export const CardHeader = styled.div`
  padding: 8px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -26px;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

CardHeader.Left = HeaderActions;
CardHeader.Right = HeaderActions;
