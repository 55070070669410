import styled from 'styled-components';

export const Background = styled.div`
  z-index: 10;
  position: absolute;
  top: -20px;
  left: -25px;
  bottom: -20px;
  height: calc(100% + 50px);
  width: calc(100vw - 235px);
  background-color: black;
  opacity: 0.2;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: calc(100% - 235px);
`;
