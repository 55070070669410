import 'antd/dist/antd.css';
import React, { useEffect, useState } from 'react';
import { getTextUsage } from '../../../redux/services/text';
import { getTextSentThisMonth } from '../../../features/texting-hub/api';

export default function TextUsage({ orgId }) {
  const [CreateAndSendTotal, setCreateAndSendTotal] = useState('N/A');
  const [TextingHubSent, setTextingHubSent] = useState('N/A');
  const [TextingHubReceived, setTextingHubReceived] = useState('N/A');
  const [CSerror, setCSError] = useState(null);
  const [THerror, setTHError] = useState(null);

  useEffect(() => {
    fetchMonthlyTextUsage();
    fetchTHubUsage();
  }, [orgId]);

  const fetchMonthlyTextUsage = async () => {
    const usage = await getTextUsage(orgId);

    if (usage === 'N/A') {
      setCSError('Error loading Create & Send metrics');
    } else setCreateAndSendTotal(usage);
  };

  const fetchTHubUsage = async () => {
    try {
      const { inbound, outbound } = await getTextSentThisMonth();
      setTextingHubSent(outbound);
      setTextingHubReceived(inbound);
    } catch (err) {
      setTextingHubSent('N/A');
      setTextingHubReceived('N/A');
      console.error('[fetchTHubUsage]', err);
      setTHError('Error loading Texting Hub metrics');
    }
  };

  return (
    <>
      <h3>
        <u>
          <b>Monthly Text Volume:</b>
        </u>
      </h3>
      <p>
        Create & Send Sent: <b>{CreateAndSendTotal}</b>
      </p>
      <p>
        Texting Hub Sent: <b>{TextingHubSent}</b>
      </p>
      <p>
        Texting Hub Received: <b>{TextingHubReceived}</b>
      </p>
      {CSerror && (
        <p style={{ color: 'red', fontSize: '11px', marginTop: '4px' }}>
          {CSerror}
        </p>
      )}
      {THerror && (
        <p style={{ color: 'red', fontSize: '11px', marginTop: '4px' }}>
          {THerror}
        </p>
      )}
    </>
  );
}
