// Menu Keys

import { useDispatch } from 'react-redux';
import { useCallback, useLayoutEffect, useMemo } from 'react';

export function loaders(detail) {
  return {
    type: 'SET_LOADER',
    detail,
  };
}

export function fullloader(detail, message) {
  return {
    type: 'FULL_PAGE_LOADER',
    detail,
    message,
  };
}

export function setProgressType(detail) {
  return {
    type: 'PROGRESS_TYPE',
    detail,
  };
}

export function updateNavProgressMeter(detail) {
  return {
    type: 'UPDATE_PROGRESS_METER',
    detail,
  };
}

export function updateProgressMeterResults(detail) {
  return {
    type: 'UPDATE_PROGRESS_RESULTS',
    detail,
  };
}

export function updateResultsExceptions(detail) {
  return {
    type: 'UPDATE_PROGRESS_EXCEPTIONS',
    detail,
  };
}

//Dispatch user list to reducers
export function errorMessage(detail) {
  return {
    type: 'ERROR',
    detail,
  };
}

//function for privileges list
export function privilegesList(detail) {
  return {
    type: 'PRIVILEGE_DETAILS',
    detail,
  };
}

//function for roles data
export function storeRoleData(detail) {
  return {
    type: 'ROLE_DETAILS',
    detail,
  };
}

// SHOW TOAST NOTIFICATIONS
export function toast(type, message, duration = 2.7) {
  return { type: 'TOAST', toastype: type, message, duration };
}

export const useUpdateUIState = () => {
  const dispatch = useDispatch();

  const fullLoader = (isLoading, message) => {
    dispatch({ type: 'FULL_PAGE_LOADER', detail: isLoading, message });
  };

  const loader = detail => {
    dispatch({ type: 'SET_LOADER', detail });
  };

  const menuKeys = detail => {
    dispatch({ type: 'MENU_SELECTED_KEY', detail });
  };

  return { fullLoader, loader, menuKeys };
};

export const useFullLoader = () => {
  const dispatch = useDispatch();

  const fullLoader = useCallback(
    (isLoading, message) => {
      dispatch({ type: 'FULL_PAGE_LOADER', detail: isLoading, message });
    },
    [dispatch]
  );

  const showFullLoader = useCallback(
    (message = 'Loading...') => {
      fullLoader(true, message);
    },
    [fullLoader]
  );

  const hideFullLoader = useCallback(() => {
    fullLoader(false);
  }, [fullLoader]);

  const during = useCallback(
    async (message, fn) => {
      showFullLoader(message);
      try {
        return await fn();
      } finally {
        hideFullLoader();
      }
    },
    [hideFullLoader, showFullLoader]
  );

  return useMemo(
    () => ({ show: showFullLoader, hide: hideFullLoader, during }),
    [during, hideFullLoader, showFullLoader]
  );
};

export const useFullScreenSpinner = (message, isLoading) => {
  const fullLoader = useFullLoader();
  useLayoutEffect(() => {
    if (isLoading) {
      fullLoader.show(message);
      return fullLoader.hide;
    }
  }, [fullLoader, isLoading, message]);
};

export const useToast = () => {
  const dispatch = useDispatch();

  const successToast = (message, duration = 2.7) => {
    dispatch({ type: 'TOAST', toastype: 'success', message, duration });
  };

  const errorToast = (message, duration = 2.7) => {
    dispatch({ type: 'TOAST', toastype: 'error', message, duration });
  };

  return { successToast, errorToast };
};
