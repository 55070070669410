import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Dialog, DialogContent } from '@material-ui/core';
import DialogTitle from './dialogComponents/dialogTitle.styled';
import DialogActions from './dialogComponents/dialogActions.styled';
import DialogContainer from './dialogComponents/dialogContainer.styled';
import SolidButton from './solidBtn';
import {
  saveNewStandAloneAuidenceAction,
  saveStandAloneAudienceDescription,
  saveStandAloneAudienceName,
} from '../../../../../../redux/action';
import {
  audienceSelector,
  selectedOrganizationSelector,
} from '../../../../../../redux/selector';

const newAudienceDialogTitle = 'Create New Saved Audience';
const savedAudienceDialogTitle = 'Update Audience Name and Description';

function NameDescriptionDialog({ isOpen, restrictTextSize, disableAutosave }) {
  const dispatch = useDispatch();
  const audience = useSelector(audienceSelector);

  const getAudienceDialogTitle = () => {
    return audience._id ? savedAudienceDialogTitle : newAudienceDialogTitle;
  };

  const organizationId = useSelector(selectedOrganizationSelector).id;
  const [open, setOpen] = useState(isOpen);
  const [dialogTitle, setDialogTitle] = useState(getAudienceDialogTitle());
  const [name, setName] = useState(audience.name || '');
  const [error, setError] = useState(false);
  const [description, setDescription] = useState(audience.description || '');

  useEffect(() => {
    setName(audience.name);
  }, [audience.name]);

  useEffect(() => {
    setDescription(audience.description);
  }, [audience.description]);

  useEffect(() => {
    setDialogTitle(getAudienceDialogTitle());
  }, [audience._id]);

  const handleNameChange = ({ target }) => {
    setName(target.value);
  };

  const handleDescriptionChange = ({ target }) => {
    setDescription(target.value);
  };

  const handleSaveDialog = () => {
    if (!name) {
      return setError(true);
    }
    setError(false);
    setOpen(false);
    if (!disableAutosave && !audience._id) {
      dispatch(
        saveNewStandAloneAuidenceAction(
          {
            ...audience,
            name,
            description,
          },
          organizationId
        )
      );
    }
    dispatch(saveStandAloneAudienceName(name));

    if (description) {
      dispatch(saveStandAloneAudienceDescription(description));
    }
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <DialogContainer>
      <TextField
        size={'small'}
        value={restrictTextSize(name) || 'Audience name'}
        onClick={handleOpenDialog}
        style={{ width: 180 }}
      />
      <span
        style={{
          position: 'absolute',
          fontSize: '8px',
          top: '25px',
          left: '0px',
        }}
      >
        {restrictTextSize(description, 40)}
      </span>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            inputProps={{ style: { fontSize: 13 } }}
            id="name"
            label="Audience Name"
            value={name}
            fullWidth
            required
            error={!name && error === true}
            helperText={!name && error && 'Please fill in audience name.'}
            onChange={handleNameChange}
          />
          <TextField
            margin="dense"
            inputProps={{ style: { fontSize: 13 } }}
            id="description"
            label="Audience Description"
            value={description}
            multiline
            rows={4}
            fullWidth
            onChange={handleDescriptionChange}
          />
        </DialogContent>
        <DialogActions>
          <SolidButton onClick={handleCloseDialog} color="grey">
            Close
          </SolidButton>
          <SolidButton onClick={handleSaveDialog}>Save</SolidButton>
        </DialogActions>
      </Dialog>
    </DialogContainer>
  );
}

export default NameDescriptionDialog;
