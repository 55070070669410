import React, { useState, useEffect } from 'react';
import { SettingsInputComponent } from '@material-ui/icons';
import {
  LeftContainer,
  Row,
  TextContainer,
  ValueContainer,
  Text,
  Title,
  EditBtn,
} from '../AMSSync.styled';
import EditAPIConnectionDrawer from '../EditAPIConnectionDrawer';
import {
  RAPATTONI,
  RAMCO,
  TANGILLA,
  REVERSE_MAP_OF_AMS_NAMES,
} from '../EditAPIConnectionDrawer/constants';

const APIConnection = ({
  userPrivileges,
  organization,
  orgTypes,
  selectedOrgType,
  setSelectedOrgType,
}) => {
  const [isEditAMSDrawerVisible, setEditAMSDrawerVisibility] = useState(false);

  useEffect(() => {
    if (orgTypes.length && organization) {
      const orgType = orgTypes.find(type => type.id === organization.orgType);
      setSelectedOrgType(orgType.name);
    }
  }, [organization]);

  const maskAPIKey = key => {
    if (!key || key?.length <= 2) return key;

    const middlePart = key.slice(1, -1);
    const maskedMiddle = '*'.repeat(middlePart.length);

    return key[0] + maskedMiddle + key.slice(-1);
  };

  return (
    <LeftContainer>
      <Row>
        <Title>
          <SettingsInputComponent size="small" />
          API Connection
        </Title>
        {userPrivileges.isSU && (
          <EditBtn
            onClick={() => {
              setEditAMSDrawerVisibility(!isEditAMSDrawerVisible);
            }}
          >
            Edit
          </EditBtn>
        )}
      </Row>
      <br />
      <TextContainer>
        <Text>
          <b>AMS:</b>
        </Text>
        <ValueContainer>
          <b>{REVERSE_MAP_OF_AMS_NAMES[selectedOrgType]}</b>
        </ValueContainer>
      </TextContainer>
      {REVERSE_MAP_OF_AMS_NAMES[selectedOrgType] !== RAMCO &&
        REVERSE_MAP_OF_AMS_NAMES[selectedOrgType] !== TANGILLA && (
          <TextContainer>
            <Text>API URL: </Text>
            <ValueContainer>{organization.url || 'NA'}</ValueContainer>
          </TextContainer>
        )}
      <TextContainer>
        <Text>API KEY: </Text>
        <ValueContainer>{maskAPIKey(organization.key)}</ValueContainer>
      </TextContainer>
      {(REVERSE_MAP_OF_AMS_NAMES[selectedOrgType] === RAPATTONI ||
        REVERSE_MAP_OF_AMS_NAMES[selectedOrgType] === TANGILLA) && (
        <TextContainer>
          <Text>CLIENT ID: </Text>
          <ValueContainer>{organization.clientID || 'NA'}</ValueContainer>
        </TextContainer>
      )}
      {isEditAMSDrawerVisible && (
        <EditAPIConnectionDrawer
          orgTypes={orgTypes}
          selectedOrgType={selectedOrgType}
          selectedOrg={organization}
          isEditAMSDrawerVisible={isEditAMSDrawerVisible}
          setEditAMSDrawerVisibility={setEditAMSDrawerVisibility}
        />
      )}
    </LeftContainer>
  );
};

export default APIConnection;
