import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 360px;
  width: 41%;
  height: 100%;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 14px;
  font-size: 14px;
`;

export const Title = styled.div`
  font-weight: 600;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
`;

export const Text = styled.span`
  @media (max-width: ${props => props.removeAtMaxWidth}) {
    display: none;
  }
`;

export const Highlight = styled.span`
  color: #5eab00;
`;
