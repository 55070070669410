class InvalidDataError extends Error {
  #validationErrors = [];

  constructor(validationErrors) {
    super('Invalid data was provided');
    this.name = this.constructor.name;

    if (validationErrors) {
      this.#validationErrors = validationErrors;
    }
  }

  getValidationErrors() {
    return this.#validationErrors;
  }
}

export default InvalidDataError;
