import styled from 'styled-components';

const Button = styled.button`
  position: relative;
  color: #007ade;
  font-size: 14px;
  padding: 0px 20px;
  border: 1px solid #2087e1;
  border-radius: 4px;
  height: 28px;
  transition: all 0.3s ease-in;
  background-color: #eaeff4;
  &:hover:enabled,
  &.active:enabled {
    color: white;
    cursor: pointer;
    background-color: #007ade;
    border: 1px solid #2087e1;
  }
  &:disabled {
    color: grey;
    border-color: grey;
    background-color: transparent;
  }
`;

export default Button;
