import React, { useContext, useEffect } from 'react';

import {
  DateStyled,
  ListContactNotesStyled,
  NoteListStyled,
  ProgressBoxStyled,
  TextFieldStyled,
} from './listContactNotes.styled';
import {
  Button,
  InputAdornment,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { useGetNotes } from '../../../redux/services/notes';
import { NoteView } from './contactNoteView';
import moment from 'moment';
import CancelRounded from '@material-ui/icons/CancelRounded';
import { NotesContext } from '../../../redux/services/notes/notes.context';
import { DateField } from '../../DateField';
import { IconHeading } from '../../Heading';
import Event from '@material-ui/icons/LibraryBooks';
import SearchIcon from '@material-ui/icons/SearchRounded';

export const ListContactNotes = () => {
  const { startDate, endDate, setStartDate, setEndDate, search, setSearch } =
    useContext(NotesContext);

  const { loading, notes, isDisabled, getNotes, getNextNotes } = useGetNotes();

  const validDate = date => moment(date, 'MM-DD-YYYY').isValid();

  useEffect(() => {
    if (validDate(startDate) && validDate(endDate)) {
      getNotes(0, startDate, endDate);
    }
  }, [startDate, endDate]);

  const clearFilter = () => {
    setEndDate(null);
    setStartDate(null);
    getNotes(0, null, null);
  };

  return (
    <ListContactNotesStyled>
      <IconHeading icon={<Event />} title="Notes Timeline" className="header" />
      <DateStyled>
        <DateField value={startDate} onChange={setStartDate} />
        <span className="mx-5">-</span>
        <DateField value={endDate} onChange={setEndDate} />
        {(!!startDate || !!endDate) && (
          <CancelRounded className="cancel-btn" onClick={clearFilter} />
        )}
        <TextFieldStyled
          placeholder="Search Notes"
          value={search}
          onChange={e => setSearch(e.target.value)}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton
                size="small"
                style={{ visibility: loading && search ? 'visible' : 'hidden' }}
              >
                <CircularProgress fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </DateStyled>
      {!!notes?.length && (
        <>
          <NoteListStyled>
            {notes?.map((note, idx) => (
              <NoteView
                key={`note-${idx}`}
                date={moment(note.updatedAt).format('MMMM Do YYYY, h:mm a')}
                note={note.note}
                id={note._id}
              />
            ))}
          </NoteListStyled>
          <Button
            style={{ display: isDisabled ? 'none' : 'inline-flex' }}
            disabled={loading}
            className="view-more"
            onClick={getNextNotes}
          >
            View More...
          </Button>
        </>
      )}
      {loading && !search && (
        <ProgressBoxStyled>
          <CircularProgress />
        </ProgressBoxStyled>
      )}

      {!loading && !notes?.length && (
        <ProgressBoxStyled>
          <h3>No Notes Found!</h3>
        </ProgressBoxStyled>
      )}
    </ListContactNotesStyled>
  );
};
