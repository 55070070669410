import React, { useState } from 'react';
import { NotesContext } from '../../../redux/services/notes/notes.context';
import { ContactNotesMain } from './contactNotes';

export const ContactNotes = ({ parentId, noteRouterId, username }) => {
  const [notes, setNotes] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState('');

  return (
    <NotesContext.Provider
      value={{
        noteRouterId,
        username,
        notes,
        setNotes,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        search,
        setSearch,
      }}
    >
      <ContactNotesMain
        parentId={parentId}
        drawerTitle={`Notes: ${username}`}
      />
    </NotesContext.Provider>
  );
};
