import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Description } from '@material-ui/icons';
import { TextField, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  selectedOrganizationSelector,
  userPrivilegesSelector,
} from '../../../../redux/selector';
import {
  Container,
  HalfContainer,
  Row,
  Title,
  EditBtn,
  Bold,
  BoldTitle,
  Center,
  TransparentContainer,
} from './GeneralInfo.styled';
import EditOrgDetailsDrawer from './EditOrgDetailsDrawer';
import { limitCharacters } from '../utils';
import OrganizationContacts from './OrganizationContacts';
import TechnicalSettings from './TechnicalSettings';
import EditTechnicalSettingsDrawer from './EditTechnicalSettingsDrawer';

const useStyles = makeStyles(() => ({
  textField: {
    '& input:disabled': {
      color: '#212121',
    },
  },
  selectLabel: {
    background: 'white',
  },
  selectField: {
    color: '#212121',
    '&:focus': {
      backgroundColor: 'transparent',
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
}));

const GeneralInfo = () => {
  const classes = useStyles();
  const selectedOrg = useSelector(selectedOrganizationSelector);
  const userPrivileges = useSelector(userPrivilegesSelector);
  const { isSU } = userPrivileges;
  const [isEditDetailsDrawerVisible, setEditDetailsDrawerVisibility] =
    useState(false);
  const [
    isEditTechnicalSettingsDrawerVisibile,
    setEditTechnicalSettingsDrawerVisibility,
  ] = useState(false);
  const [shouldPageRefresh, setPageShouldRefresh] = useState(false);

  useEffect(() => {
    setPageShouldRefresh(true);
    const timer = setTimeout(() => {
      setPageShouldRefresh(false);
    }, 10);

    return () => clearTimeout(timer);
  }, [selectedOrg]);

  if (!selectedOrg.id || shouldPageRefresh)
    return (
      <Center>
        <CircularProgress
          style={{ margin: 'auto', alignItems: 'center', color: '#007ADE' }}
        />
      </Center>
    );

  return (
    <Container>
      <HalfContainer>
        <Row>
          <Title>
            <Description size="small" />
            Details
          </Title>
          <EditBtn
            onClick={() => {
              setEditDetailsDrawerVisibility(!isEditDetailsDrawerVisible);
            }}
          >
            Edit
          </EditBtn>
        </Row>
        <TextField
          className={classes.textField}
          disabled
          label="Organization Legal Name"
          variant="outlined"
          size="small"
          value={selectedOrg.organizationName}
        />
        <TextField
          className={classes.textField}
          disabled
          label="Acronym"
          variant="outlined"
          size="small"
          value={selectedOrg.acronym}
        />
        <TextField
          className={classes.textField}
          disabled
          label="Public Email Address"
          variant="outlined"
          size="small"
          value={selectedOrg.email}
        />
        <TextField
          className={classes.textField}
          disabled
          label="Public Phone Number"
          variant="outlined"
          size="small"
          value={selectedOrg.phoneNumber}
        />
        {selectedOrg.address?.map((address, index) => (
          <Row key={index}>
            <TextField
              className={classes.textField}
              disabled
              label="Street Address"
              variant="outlined"
              size="small"
              value={address.address}
              style={{ width: '45%' }}
            />
            <TextField
              className={classes.textField}
              disabled
              label="City"
              variant="outlined"
              size="small"
              value={address.city}
              style={{ width: '25%' }}
            />
            <TextField
              className={classes.textField}
              disabled
              label="State"
              variant="outlined"
              size="small"
              value={address.state}
              style={{ width: '12%' }}
            />
            <TextField
              className={classes.textField}
              disabled
              label="Zip"
              variant="outlined"
              size="small"
              value={address.zipcode}
              style={{ width: '12%' }}
            />
          </Row>
        ))}
        <TextField
          className={classes.textField}
          disabled
          label="Website"
          variant="outlined"
          size="small"
          value={selectedOrg.website}
        />
        {selectedOrg.memberPortalURL ? (
          <TextField
            className={classes.textField}
            disabled
            label="Member Portal URL"
            variant="outlined"
            size="small"
            value={selectedOrg.memberPortalURL}
          />
        ) : (
          ''
        )}
        <TextField
          className={classes.textField}
          disabled
          label="Time Zone"
          variant="outlined"
          size="small"
          value={selectedOrg.timezone?.region}
        />
        <TextField
          className={classes.textField}
          disabled
          label="Corporation Type"
          variant="outlined"
          size="small"
          value={selectedOrg.corporationType}
        />
        <TextField
          className={classes.textField}
          disabled
          label="Federal Employer ID Number (EIN)"
          variant="outlined"
          size="small"
          value={selectedOrg.federalEIN}
        />
        <BoldTitle>Default Sender Info</BoldTitle>
        <TextField
          className={classes.textField}
          disabled
          label="Email 'From' Name"
          variant="outlined"
          size="small"
          value={selectedOrg.senderName}
        />
        <TextField
          className={classes.textField}
          disabled
          label="Texting 'From' Name (15 character limit)"
          variant="outlined"
          size="small"
          value={
            selectedOrg.textSenderName || limitCharacters(selectedOrg.acronym)
          }
        />
        <Row>
          <TextField
            className={classes.textField}
            disabled
            label="'From' Email Prefix"
            variant="outlined"
            size="small"
            value={selectedOrg.fromEmail}
            style={{ width: '47%' }}
          />
          <Bold>@</Bold>
          <TextField
            className={classes.textField}
            disabled
            label="'From' Email Domain"
            variant="outlined"
            size="small"
            value={selectedOrg?.domains[0] || ''}
            style={{ width: '47%' }}
          />
        </Row>
        <TextField
          className={classes.textField}
          disabled
          label="'From' Texting Number"
          variant="outlined"
          size="small"
          value={selectedOrg.fromPhone}
        />
      </HalfContainer>
      <TransparentContainer>
        <OrganizationContacts
          height={isSU ? 'calc(60vh - 50px)' : 'calc(100vh - 100px)'}
        />
        {isSU && (
          <TechnicalSettings
            selectedOrg={selectedOrg}
            setEditTechnicalSettingsDrawerVisibility={
              setEditTechnicalSettingsDrawerVisibility
            }
          />
        )}
      </TransparentContainer>
      {isEditDetailsDrawerVisible && (
        <EditOrgDetailsDrawer
          selectedOrg={selectedOrg}
          isEditDetailsDrawerVisible={isEditDetailsDrawerVisible}
          setEditDetailsDrawerVisibility={setEditDetailsDrawerVisibility}
        />
      )}
      {isSU && isEditTechnicalSettingsDrawerVisibile && (
        <EditTechnicalSettingsDrawer
          selectedOrg={selectedOrg}
          isEditTechnicalSettingsDrawerVisibile={
            isEditTechnicalSettingsDrawerVisibile
          }
          setEditTechnicalSettingsDrawerVisibility={
            setEditTechnicalSettingsDrawerVisibility
          }
        />
      )}
    </Container>
  );
};

export default GeneralInfo;
