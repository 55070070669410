import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  toast,
  fullloader,
  updateAudienceFromDraft,
} from '../../../redux/action';
import {
  updateDraft,
  getDraftBy_id,
  saveNewDraftService,
} from '../../../redux/services';
import useSaveStatus from '../Components/SavingStatus';
import { selectedOrganizationSelector } from '../../../redux/selectors/organization';
import { userDetailSelector } from '../../../redux/selectors/user';
import { DraftHeader } from '../Components/Header';
import BaseEditor from '../BaseEditor';

const DraftEditor = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const draftId = queryString.parse(location.search).draft;
  const [
    saveStatus,
    isTextStatusSaved,
    { onStartSavingStatus, onFinishSavingStatus, onMarkChangesUnsavedStatus },
  ] = useSaveStatus();
  const [design, setDesign] = useState(null);

  const organizationId = useSelector(selectedOrganizationSelector).id;
  const userId = useSelector(userDetailSelector).id;
  const [draft, setDraft] = useState({});

  const loadDraft = async () => {
    try {
      dispatch(fullloader('true', 'Loading draft.'));
      const fetchedDraft = await getDraftBy_id(draftId);

      const parsedDesign =
        typeof fetchedDraft.design === 'string'
          ? JSON.parse(fetchedDraft.design)
          : fetchedDraft.design;
      setDesign(parsedDesign || {});
      setDraft(fetchedDraft);

      dispatch(fullloader(false));
      dispatch(updateAudienceFromDraft(fetchedDraft));
      dispatch(toast('success', `Loaded ${fetchedDraft.name}`));
    } catch (e) {
      console.error(e);
      dispatch(toast('error', 'Error fetching draft'));
      history.replace('/dashboard/drafts');
    }
  };

  const saveNewDraft = async (data = {}) => {
    try {
      setDesign(data.design || {});
      const result = await saveNewDraftService({
        design: '{}',
        organizationId,
        userId,
        ...data,
      });

      history.replace(`/dashboard/sendmessage?draft=${result._id}`);
      setDraft(result);
      dispatch(toast('success', 'Loaded a new draft'));
    } catch (e) {
      dispatch(toast('error', 'Error while saving a new draft'));
    }
  };

  const handleDraftSave = async data => {
    onStartSavingStatus();
    if (draftId === 'new') {
      await saveNewDraft(data);
    } else {
      await updateDraft({ _id: draftId, ...data });
    }
    onFinishSavingStatus();
  };

  useEffect(() => {
    if (draftId === 'new') {
      setDesign({});
    } else {
      loadDraft();
    }
  }, [draftId]);

  return (
    <BaseEditor
      Header={DraftHeader}
      data={draft}
      onSave={handleDraftSave}
      saveStatus={saveStatus}
      isTextStatusSaved={isTextStatusSaved}
      onUnsavedStatus={onMarkChangesUnsavedStatus}
      design={design}
      setDesign={setDesign}
      setDraft={setDraft}
    />
  );
};

export default DraftEditor;
