const DEFAULT_IS_EQUAL = (a, b) => a === b;

const observeEnhancer =
  next =>
  (...args) => {
    const store = next(...args);

    return {
      ...store,
      observe(selector, callback, { isEqual = DEFAULT_IS_EQUAL } = {}) {
        const select = () => {
          return selector(store.getState());
        };

        let latestValue = select();
        callback(latestValue);
        store.subscribe(() => {
          const newValue = select();
          const isSame = isEqual(latestValue, newValue);
          const hasChanged = !isSame;
          if (hasChanged) {
            latestValue = newValue;
            callback(latestValue);
          }
        });
      },
    };
  };

export default observeEnhancer;
