import styled from 'styled-components';
import { Button as AntdButton, Menu as AntdMenu } from 'antd';

export const Button = styled(AntdButton).attrs({ size: 'large' })`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6c7375;
  width: 90px;
  text-align: left;
  border-radius: 5px;

  > span {
    font-family: 'Montserrat', sans-serif;
  }
`;

export const Menu = styled(AntdMenu)`
  font-family: 'Montserrat', sans-serif;
`;

Menu.Item = styled(Menu.Item)`
  font-size: 16px;
`;
