import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';
import { ExportButton } from './ExportBtn.styled';
import { exportEvents } from '../api';
import { toast } from '../../../../../redux/actions/UI';

const ExportBtn = ({ selectedRows, message }) => {
  const dispatch = useDispatch();
  const [csvData, setCsvData] = useState('');
  const csvInstance = useRef(null);

  useEffect(() => {
    if (
      csvData &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData('');
      });
    }
  }, [csvData]);

  const handleExport = async () => {
    try {
      const urlIndexes = selectedRows.map(event => event.linkIndex - 1);
      const data = await exportEvents({
        messageId: message.id,
        urlIndexes,
      });
      setCsvData(data);
    } catch (e) {
      console.error(e);
      dispatch(toast('error', 'Failed to export click events data'));
    }
  };

  return (
    <>
      <ExportButton onClick={handleExport} disabled={!selectedRows.length}>
        Export
      </ExportButton>
      {csvData.length ? (
        <CSVLink
          data={csvData}
          filename={`Click Activity Report for ${message.messageSubject}.csv`.replaceAll(
            '/',
            '.'
          )}
          ref={csvInstance}
        />
      ) : undefined}
    </>
  );
};

export default ExportBtn;
