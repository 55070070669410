export const NONE = 'None';
export const RAPATTONI = 'Rapattoni';
export const RAMCO = 'RAMCO';
export const GROWTH_ZONE = 'GrowthZone';
export const TANGILLA = 'Tangilla';

export const MAP_OF_AMS_NAMES = {
  [RAPATTONI]: 'magic',
  [RAMCO]: 'ramco',
  [GROWTH_ZONE]: 'gz',
  [TANGILLA]: 'tangilla',
};

export const REVERSE_MAP_OF_AMS_NAMES = {
  magic: RAPATTONI,
  ramco: RAMCO,
  gz: GROWTH_ZONE,
  tangilla: TANGILLA,
};
