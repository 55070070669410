import styled from 'styled-components';
import { IconButton as MuiIconButton } from '@material-ui/core';

export const IconButton = styled(MuiIconButton)`
  background-color: rgba(0, 0, 0, 0.04) !important;

  :hover {
    color: #ffffff !important;
    background-color: #40a9ff !important;
  }
`;
