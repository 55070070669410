import React, { Fragment, memo, useContext } from 'react';
import { LogoStyled, HeaderMsgStyled } from './optinHeader.styled';
import {
  PreferenceContext,
  useOrganizationContent,
  useOrganizationLogo,
} from '../../../redux/services';

export const OptinHeader = memo(function OptinHeaderComponent() {
  const {
    member: { firstName, organizationId, isMember },
  } = useContext(PreferenceContext);

  const { logo } = useOrganizationLogo(organizationId);
  const { content } = useOrganizationContent({ organizationId, isMember });

  return (
    <Fragment>
      {logo && (
        <LogoStyled>
          <img src={logo} alt="Logo" />
        </LogoStyled>
      )}

      <HeaderMsgStyled>
        <p className="greetings">Hi {firstName}!</p>
        <p dangerouslySetInnerHTML={{ __html: content }} />
      </HeaderMsgStyled>
    </Fragment>
  );
});
