import { withProps } from '../../../libs/react-utils';
import RateMeter from '../RateMeter';

export default withProps(
  {
    breakpoints: {
      0: '#fa7470',
      10: '#f5a44d',
      15: '#fcd908',
      20: '#c3dc3d',
      30: '#54c242',
      50: '#ff85dc',
    },
    targetValue: 0.4,
  },
  RateMeter
);
