import React, { useEffect } from 'react';
import { DrawerHeader } from '../DrawerComponents';
import { DrawerBody, DrawerStyled } from './childDrawer.styled';

export const ChildDrawer = ({
  anchor,
  open,
  parentId,
  id,
  Component,
  onClose,
  className,
  drawerTitle,
  onSave,
}) => {
  useEffect(() => {
    if (parentId) {
      if (open) {
        document.getElementById(parentId)?.classList.add('parent-margin');
      } else {
        document.getElementById(parentId)?.classList.remove('parent-margin');
      }
    }
  }, [open]);

  return (
    <DrawerStyled
      id={id}
      className={className}
      anchor={anchor}
      open={open}
      onClose={onClose}
    >
      {drawerTitle && onClose && (
        <DrawerHeader
          onClose={onClose}
          title={drawerTitle}
          onSave={onSave}
          fixed
        />
      )}
      <DrawerBody fixed={drawerTitle && onClose}>{Component}</DrawerBody>
    </DrawerStyled>
  );
};
