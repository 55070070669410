import React from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Row, Title, Paragraph } from './TextCopy.styled';

const TextCopy = () => {
  return (
    <>
      <Row>
        <InfoOutlinedIcon fontSize="small" style={{ color: '#005092' }} />
        <Title>Summary of User Roles</Title>
      </Row>
      <Paragraph>
        <Title>Staff Support:</Title>
        Can do everything in NoteRouter except sending or scheduling messages
        and editing organization-level info, including users.
      </Paragraph>
      <Paragraph>
        <Title>Staff Editor:</Title>
        Can do everything in NoteRouter except editing organization-level info,
        including users.
      </Paragraph>
      <Paragraph>
        <Title>Staff Admin:</Title>
        Can do everything in NoteRouter.
      </Paragraph>
    </>
  );
};

export default TextCopy;
