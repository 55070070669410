import styled from 'styled-components';

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 0;

  @media screen and (min-width: 525px) {
    display: table;
    border-collapse: separate;
    width: 100%;
    margin-top: 40px;
  }
`;

export const TableCell = styled.div`
  display: flex;
  flex: 1 1 50%;
  max-width: 50%;
  justify-content: flex-start;
  padding: 16px;

  button,
  label {
    visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  }

  svg {
    margin-right: 8px;
    margin-bottom: 2px;
  }

  ${({ isAction }) =>
    isAction &&
    `
      label {
        margin-right: 10px;
      }`}
  & p {
    margin-bottom: 0;

    ${({ isColHeader }) =>
      isColHeader &&
      `
      display: flex;
      color: #0076ff;
      font-size: 18px;
      font-weight: 600;
    `}

    & span {
      color: #f5222d;
      margin-left: 5px;
    }
  }

  ${({ isHeader }) =>
    isHeader &&
    `
      flex-direction: row;
      justify-content: flex-start;
      font-size: 18px;
      font-weight: 400;
      padding: 12px 0;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: #616161;
    `}

  &:first-child {
    flex: 1 1 100%;
    max-width: 100%;
    flex-direction: column;
    padding-left: 16px;

    ${({ isHeader }) => isHeader && 'flex-direction: row;'}
  }

  .subHeader {
    color: #616161;
    font-size: 14px;
    font-weight: 400;
  }

  @media screen and (min-width: 525px) {
    display: table-cell;
    border-top: 1px solid #d1d1d1;
    vertical-align: middle;

    ${({ isAction }) =>
      isAction &&
      `
      label {
        display: none;
      }`}

    &:last-child {
      border-right: 1px solid #d1d1d1;
    }

    &:first-child {
      border-left: 1px solid #d1d1d1;
      width: 70%;
    }
  }
`;

export const TableRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  border: 1px solid #d1d1d1;
  border-bottom: none;

  &:first-child {
    border: none;

    & div {
      &:not(:first-child) {
        ${({ isHeader }) => isHeader && 'display: none;'}
      }
    }
  }

  &:nth-child(2) {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 1px solid #d1d1d1;
  }

  @media screen and (min-width: 525px) {
    display: table-row;
    border: none;

    &:first-child {
      display: table-row;

      & div {
        &:not(:first-child) {
          ${({ isHeader }) => isHeader && 'display: table-cell;'}
        }
      }
    }

    &:nth-child(1) {
      & div {
        border: none;
      }
    }

    &:nth-child(2) {
      & div {
        &:last-child {
          border-top-right-radius: 10px;
        }

        &:first-child {
          border-top-left-radius: 10px;
        }
      }
    }

    &:last-child {
      & div {
        border-bottom: 1px solid #d1d1d1;

        &:last-child {
          border-bottom-right-radius: 10px;
        }

        &:first-child {
          border-bottom-left-radius: 10px;
        }
      }
    }
  }
`;

export const CellContent = styled.span`
  display: flex;
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'flex-start')};
  align-items: center;
`;
