import React from 'react';
import { Tooltip } from '@material-ui/core';
import {
  Container,
  Title,
  Statistics,
  ResendStats,
  Bold,
  ArrowUpIcon,
  ArrowDownIcon,
} from './Card.styled';

const Card = ({
  name,
  value,
  title,
  onClick = null,
  isDataAvailable,
  isSmartResendAvailable,
  smartResendValue,
  showComparativeAnalytics,
}) => {
  const isNotZero = value => {
    return value !== '0%' && value !== '0.0%' && value !== 0 && value !== null;
  };

  const isIncreasedInValue =
    smartResendValue && !String(smartResendValue).startsWith('-');

  const renderIncrease = (
    <>
      <ArrowUpIcon />
      <Bold>+{smartResendValue}</Bold>
      from resend
    </>
  );

  const renderDecrease = (
    <>
      <ArrowDownIcon />
      <Bold>{smartResendValue}</Bold>
      from resend
    </>
  );

  return (
    <Tooltip title={title} placement="top" style={{ justifyContent: 'center' }}>
      <Container onClick={onClick}>
        <Title>{name}</Title>
        <Statistics>{isDataAvailable ? value : '---'}</Statistics>
        {isSmartResendAvailable && (
          <ResendStats>
            {isNotZero(smartResendValue) ? (
              showComparativeAnalytics ? (
                isIncreasedInValue ? (
                  renderIncrease
                ) : (
                  renderDecrease
                )
              ) : (
                <>
                  Resend: <Bold> {smartResendValue}</Bold>
                </>
              )
            ) : (
              <>no change from resend</>
            )}
          </ResendStats>
        )}
      </Container>
    </Tooltip>
  );
};

export default Card;
