import styled from 'styled-components';
import FolderOpenOutlined from '@ant-design/icons/FolderOpenOutlined';

const MoveToOtherOrgBtn = styled(FolderOpenOutlined)`
  &:hover {
    color: #007ade;
  }
`;

export default MoveToOtherOrgBtn;
