import { ShareRounded } from 'grommet-icons';
import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { toast } from '../../../../../redux/actions/UI';

const URLContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
`;

const PrefURL = ({ label, url }) => {
  const dispatch = useDispatch();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(encodeURI(url));
    dispatch(toast('success', 'Copied to clipboard'));
  };

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <ShareRounded
          color={'#007ade'}
          style={{ cursor: 'pointer' }}
          onClick={copyToClipboard}
        />

        <h1
          style={{
            display: 'inline-flex',
            color: '#007ade',
            paddingLeft: '10px',
            marginBottom: '3px',
          }}
        >
          {label}
        </h1>
      </div>

      <p
        style={{
          fontSize: '12px',
          marginBottom: '5px',
          color: 'rgba(0, 0, 0, 0.6)',
        }}
      >
        Help contacts visit their preference page by sharing this link on social
        media, your website, or as a dashboard widget. (Click to copy to
        clipboard)
      </p>

      <URLContainer onClick={copyToClipboard}>{url}</URLContainer>
    </div>
  );
};

export default PrefURL;
