import styled from 'styled-components';
import { LinearProgress } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const Text = styled.span`
  width: 56px;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.05em;
  text-align: left;
`;

export const Progress = styled(LinearProgress)`
  width: 120px;
  border-radius: 10px;
  height: 20px !important;
  background-color: #e3e6e8 !important;

  > .MuiLinearProgress-bar {
    background-color: ${({ color }) => color};
  }
`;
