import React, { useState } from 'react';
import { connect } from 'react-redux';
import MembershipAndStatus from '../MembershipAndStatus';
import MembershipAndType from '../MembershipAndType';
import CodeOfEthicsCompletion from '../CodeOfEthicsCompletion';
import GenerateRow from '../GenerateFilterRow';
import { getLabelBasedOnOrgType } from '../_shared/utils';
import {
  COMMITTEES,
  MEMBERSHIP_STATUS,
  MEMBERSHIP_TYPE,
  MEMBER_DESIGNATIONS,
  DESIGNATION_SHOULD_EXIST,
  CODE_OF_ETHICS_COMPLETION,
} from '../_shared/constants';
import { CommitteeMember } from '../CommitteeMember';
import MemberDesignation from '../MemberDesignation';

const ShowMembershipFilters = ({
  audienceFilters,
  filterTypes,
  selectedFilters,
  orgTypeName,
  committees,
}) => {
  const membershipFilterType = filterTypes.filter(
    filter => filter.name === 'Membership'
  )[0];
  const membershipFilters = audienceFilters.filter(
    filter => filter.filterType === membershipFilterType.id
  );

  const membershipFilterExists = name => {
    return membershipFilters.some(filter => {
      if (filter.name === name) {
        if (filter.name == COMMITTEES) {
          return !!committees?.length;
        }
        return filter.name === name;
      }
    });
  };

  const [checkedMembershipStatus, setMembershipStatus] = useState(
    !!selectedFilters[MEMBERSHIP_STATUS]
  );
  const [checkedMembershipType, setMembershipType] = useState(
    !!selectedFilters[MEMBERSHIP_TYPE]
  );
  const [checkedMemberDesignations, setMemberDesignations] = useState(
    !!selectedFilters[MEMBER_DESIGNATIONS] ||
      !!selectedFilters[DESIGNATION_SHOULD_EXIST]
  );

  const [checkCommitteeMember, setCommitteeMember] = useState(
    !!selectedFilters[COMMITTEES]
  );

  const [checkCodeOfEthicsCompletion, setCodeOfEthicsCompletion] = useState(
    !!selectedFilters[CODE_OF_ETHICS_COMPLETION]
  );

  const membershipFiltersList = [
    {
      filterName: MEMBERSHIP_STATUS,
      checked: checkedMembershipStatus,
      setChecked: setMembershipStatus,
      ComponentToRender: MembershipAndStatus,
    },
    {
      filterName: MEMBERSHIP_TYPE,
      checked: checkedMembershipType,
      setChecked: setMembershipType,
      ComponentToRender: MembershipAndType,
    },
    {
      filterName: MEMBER_DESIGNATIONS,
      checked: checkedMemberDesignations,
      setChecked: setMemberDesignations,
      ComponentToRender: MemberDesignation,
      allowAny: DESIGNATION_SHOULD_EXIST,
    },
    {
      filterName: COMMITTEES,
      checked: checkCommitteeMember,
      setChecked: setCommitteeMember,
      ComponentToRender: CommitteeMember,
    },
    {
      filterName: CODE_OF_ETHICS_COMPLETION,
      checked: checkCodeOfEthicsCompletion,
      setChecked: setCodeOfEthicsCompletion,
      ComponentToRender: CodeOfEthicsCompletion,
    },
  ];

  return (
    <>
      {membershipFiltersList.map(
        ({ filterName, checked, setChecked, ComponentToRender, allowAny }) =>
          membershipFilterExists(filterName) && (
            <GenerateRow
              key={filterName}
              check={checked}
              setCheck={setChecked}
              label={getLabelBasedOnOrgType(
                filterName,
                audienceFilters,
                orgTypeName
              )}
            >
              <ComponentToRender
                filterName={filterName}
                checked={checked}
                selectedFilters={selectedFilters[filterName]}
                allowAny={selectedFilters[allowAny]}
              />
            </GenerateRow>
          )
      )}
    </>
  );
};

const mapStateToProps = state => ({
  audienceFilters: state.audience.audienceFilters,
  filterTypes: state.audience.filterTypes,
  selectedFilters: state.audience.selectedFilters,
  orgTypeName: state.audience.orgTypeName,
  committees: state.committees.committees,
});

export default connect(mapStateToProps)(ShowMembershipFilters);
