// REDUCER FOR TASK LIST
export function privileges(state = {}, action) {
  switch (action.type) {
    case 'PRIVILEGE_DETAILS':
      return Object.assign({}, state, {
        list: action.detail,
      });

    case 'ROLE_DETAILS':
      return Object.assign({}, state, {
        roles: action.detail,
      });

    default:
      return state;
  }
}
