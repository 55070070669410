// REDUCER FOR TASK LIST
export function user(state = {}, action) {
  switch (action.type) {
    case 'LOGGED_USER_DETAILS':
      return Object.assign({}, state, {
        detail: action.detail,
      });
    case 'USER_DETAILS_ORGID':
      return Object.assign({}, state, {
        userlistbyorgid: action.detail,
      });
    case 'USER_LOGIN':
      return Object.assign({}, state, {
        userdetail: action,
        organizationlist: action.organizationId,
      });

    case 'USER_PRIVILEGE_VALIDATION':
      return Object.assign({}, state, {
        userPrivileges: { ...state.userPrivileges, ...action.detail },
      });

    default:
      return state;
  }
}
