import React from 'react';
import { useSelector } from 'react-redux';
import Container from './Components/container';
import Topics from './Components/Topics';
import Groups from './Components/Groups';
import Filters from './Components/Filters';
import Contacts from './Components/Contacts';
import { TOPICS, GROUPS, FILTERS, CONTACTS } from '../../constants';

const Content = () => {
  const { selectedMenuItemName } = useSelector(state => state.audience);

  const renderContent = () => {
    switch (selectedMenuItemName) {
      case TOPICS:
        return <Topics />;
      case GROUPS:
        return <Groups />;
      case FILTERS:
        return <Filters />;
      case CONTACTS:
        return <Contacts />;
      default:
        return <Topics />;
    }
  };

  return <Container>{renderContent()}</Container>;
};

export default Content;
