import { capitalize } from '../../../libs/js-utils';

class NotFoundError extends Error {
  constructor(entity, id) {
    super(`${capitalize(entity)} with id ${id} is not found`);
    this.name = this.constructor.name;
  }
}

export default NotFoundError;
