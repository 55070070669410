import React, { useState } from 'react';
import {
  NoteViewStyled,
  NoteViewHeader,
  NoteViewBody,
  EditBoxStyled,
} from './listContactNotes.styled';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDeleteNote, useUpdateNote } from '../../../redux/services/notes';
import Quilview from '../../Quilview/Quilview';
import { DeleteConfirmationModal } from '../../Modals';
import { RoundedButton } from '../../Buttons/RoundedButton';
import { Button } from '@material-ui/core';
import { useToast } from '../../../redux/action';

export const NoteView = ({ id, date, note }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [deleteConfirm, setDelete] = useState(false);
  const [edit, setEdit] = useState('');

  const { isDeleting, deleteNote } = useDeleteNote();
  const { updateState, updateNote } = useUpdateNote();
  const { errorToast } = useToast();

  const toggleEdit = () =>
    setIsEdit(edit => {
      if (!edit) setEdit(note);
      else setEdit('');
      return !edit;
    });
  const toggleDelete = () => setDelete(del => !del);

  const confirmDelete = () => {
    deleteNote(id);
    toggleDelete();
  };

  const finishEditing = () => {
    if (note?.match(/<img.+src=*/g)?.length) {
      errorToast('Cannot add media in notes');
    } else {
      updateNote(id, edit);
      toggleEdit();
    }
  };

  return (
    <NoteViewStyled>
      <NoteViewHeader>
        <p>{date}</p>
        <span>
          <EditRoundedIcon className="edit" onClick={toggleEdit} />
          <DeleteIcon className="delete" onClick={toggleDelete} />
        </span>
      </NoteViewHeader>
      {!isEdit ? (
        <NoteViewBody>
          <span className="cirlce" />
          <div dangerouslySetInnerHTML={{ __html: note }} />
        </NoteViewBody>
      ) : (
        <EditBoxStyled>
          <Quilview value={edit} ontextchange={setEdit} isSimpleEditor />
          <div className="btn-container">
            <Button className="cancel-btn" onClick={toggleEdit}>
              Cancel
            </Button>
            <RoundedButton
              disabled={updateState.loading}
              onClick={finishEditing}
              text="Update"
            />
          </div>
        </EditBoxStyled>
      )}
      <DeleteConfirmationModal
        isOpen={deleteConfirm}
        toggle={toggleDelete}
        confirmDelete={confirmDelete}
        disabled={isDeleting}
        content={
          <>
            <div className="date-blue">{date}</div>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: note }}
            />
          </>
        }
        description={note}
        actionName="note"
      />
    </NoteViewStyled>
  );
};
