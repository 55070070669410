import queryString from 'query-string';
import ReactGA from 'react-ga';
import _intersection from 'underscore-es/intersection';
import _uniq from 'underscore-es/uniq';

import * as services from '../../redux/services';

ReactGA.initialize(process.env.REACT_APP_gaKey);

const validateUserPrivileges = (props, user) => {
  services.getPrivilegesList().then(privileges => {
    props.actions.privilegesList(privileges);

    const queryInitObject = queryString.parse(props.location.search);
    const queryOrg = queryInitObject.org;
    const orgId = queryOrg ? queryOrg : props.organization.selected.id;
    const userId = user.id;

    const privilegesToCheck = [
      { preferences: privileges.MANAGE_PREFERENCES },
      { engagement: privileges.MANAGE_ENGAGEMENT },
      { orgHeader: privileges.MANAGE_ORG_HEADER },
      { editor: privileges.SEND_MESSAGE_TEST_ONLY },
      { analytics: privileges.MANAGE_ANALYTICS },
      { manageOrgAdmin: privileges.MANAGE_ORG_USERS_ADMIN },
      { manageAuthOrgs: privileges.MANAGE_AUTHORIZED_ORGS },
      { manageMembers: privileges.MANAGE_MEMBERS },
      { manageTags: privileges.MANAGE_TAGS },
      { messageApproval: privileges.SEND_MESSAGE_FULL_ACCESS },
      { isSU: privileges.MANAGE_EXECUTIVE_ADMINS },
      { isSA: privileges.MANAGE_COMPANY_ITEMS },
      { isSDA: privileges.MANAGE_SUPER_ADMINS },
      { manageUser_Admin: privileges.MANAGE_ORG_USERS_ADMIN },
      { manageUser_Exec: privileges.MANAGE_ORG_USERS_EXEC },
      { beeAdmin: privileges.nr_bee_admin },
    ];

    if (userId && orgId) {
      services
        .validatePrivilegeBatch(userId, orgId, privilegesToCheck)
        .then(async res => {
          if (!res.data.userPrivileges.isSU) {
            const userOrgList = await services.getMultiUserOrgList(
              props.user.detail.organizations
            );
            props.actions.organizationList(userOrgList);
          } else {
            const userOrgList = await services.getOrganizationList();
            props.actions.organizationList(userOrgList);
          }

          props.actions.userPrivileges(res.data.userPrivileges);
          props.actions.fullloader(false);
        })
        .catch(error => {
          console.log(error);
        });
    }
  });

  services.getRoles().then(roles => {
    props.actions.storeRoleData(roles);
    props.actions.fullloader(false);
  });
};

export function gAnalytics(type, object) {
  switch (type) {
    case 'pageview':
      ReactGA.pageview(object);
      break;
    case 'event':
      ReactGA.event(object);
      break;
    default:
      ReactGA.event(object);
  }
}

export function getQueryStringValue(key) {
  return decodeURIComponent(
    window.location.search.replace(
      new RegExp(
        '^(?:.*[&\\?]' +
          // eslint-disable-next-line
          encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') +
          '(?:\\=([^&]*))?)?.*$',
        'i'
      ),
      '$1'
    )
  );
}

export function wait(ms) {
  const start = Date.now();
  let now = start;
  while (now - start < ms) {
    now = Date.now();
  }
}

// "Administrator Settings"
export function userHasPrivilege(privilegeslist, userDetail, privlegeName) {
  if (!privilegeslist || !userDetail) {
    return false;
  }
  return (
    Object.keys(privilegeslist).length > 0 &&
    userDetail.privilege &&
    userDetail.privilege.indexOf(privilegeslist[privlegeName].toString()) > -1
  );
}

export async function authUserHelper(props, redirectUrl) {
  const queryInitObject = queryString.parse(props.location.search);

  if (props.user.detail && props.user.detail.id) return;
  if (!props.auth.isAuthenticated) {
    props.actions.fullloader(true, 'Authenticating...');
  }

  services.checkUserLoggedIn(async user => {
    if (user) {
      props.actions.authLogin();
      props.actions.userdetail(user);
      const queryOrg = queryInitObject.org;
      let userOrg;
      let userOrgList;
      if (user.role === 'admin') {
        userOrg = await services.getOrganization(
          queryOrg ? queryOrg : user.organizationId
        );
        userOrgList = await services.getOrganizationList();
      } else {
        userOrg = await services.getOrganization(
          queryOrg ? queryOrg : user.organizationId
        );
        userOrgList = [userOrg];
      }

      props.actions.userOrganization(userOrg);
      props.actions.organizationList(userOrgList);
      validateUserPrivileges(props, user);
      props.actions.fullloader(false);
    } else {
      props.actions.authLogoutAction();
      props.actions.fullloader(false);
      if (redirectUrl) {
        props.history.push(
          `/login?redirectUrl=${encodeURIComponent(redirectUrl)}`
        );
      } else {
        props.history.push('/login');
      }
    }
  });
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function validateEmail(email) {
  const re =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function uniqueEmailArray(array) {
  const flags = {};
  const output = [];
  const l = array.length;
  for (let i = 0; i < l; i++) {
    if (flags[array[i].email]) continue;
    flags[array[i].email] = true;
    output.push(array[i]);
  }
  return output;
}

export function uniqueIdArray(array) {
  let ids = array.map(element => element.noteRouterId);
  return _uniq(ids);
}

export function splitArray(inputArray, blockSize) {
  const perChunk = blockSize; // items per chunk
  const chunked = inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
  return chunked;
}

const formatRecipientObject = member => {
  let memberObj = {
    email: member.email ? member.email.trim() : null,
    noteRouterId: member.noteRouterId,
    phone: member.phoneNumber ? member.phoneNumber.trim() : null,
    firstName: member.firstName ? member.firstName.trim() : null,
    lastName: member.lastName ? member.lastName.trim() : null,
    fullName: `${member.firstName} ${member.lastName}`,
    balanceDue:
      member.balanceDue || member.balanceDue === 0 ? member.balanceDue : null,
    portalUsername: member.memberPortalUsername || null,
    memberNRDSID: member.memberNRDSID || '',
    invoices: member.invoices ? member.invoices : [],
  };
  if (member.birthdate) memberObj.birthday = member.birthdate.substring(0, 10);
  if (member.memberAssociationOrientationDate)
    memberObj.orientation = member.memberAssociationOrientationDate;
  if (member.memberAssociationJoinDate)
    memberObj.associationJoinDate = member.memberAssociationJoinDate;
  if (member.memberStateLicenseExpirationDate)
    memberObj.stateExpirationDate =
      member.memberStateLicenseExpirationDate || '';
  if (member.officeAreaCode) memberObj.officeZip = member.officeAreaCode;
  if (member.memberHomeAreaCode) memberObj.homeZip = member.memberHomeAreaCode;
  if (member.officeCity) memberObj.officeCity = member.officeCity.toUpperCase();
  if (member.officeState)
    memberObj.officeState = member.officeState.toUpperCase();
  if (member.homeCity) memberObj.homeCity = member.homeCity.toUpperCase();
  if (member.homeState) memberObj.homeState = member.homeState.toUpperCase();
  return memberObj;
};

export function formatMemberTargets(targetedMembers, tags, excluded) {
  let emailRecipients = [];
  let textRecipients = [];
  for (let i = 0; i < targetedMembers.length; i++) {
    let member = targetedMembers[i];
    let doSkip = [];
    // If need to filter out excluded
    if (excluded.length) {
      doSkip = _intersection(excluded, member.identifiers);
    }
    if (!doSkip.length) {
      let emailTags = _intersection(member.emailTags, tags).length; //Topic tags that the member has matching with targeted topics for email
      let textTags = _intersection(member.textTags, tags).length; //Topic tags that the member has matching with targeted topics for text
      if (emailTags > 0) {
        let memberData = formatRecipientObject(member);
        emailRecipients.push(memberData);
      }
      if (textTags > 0) {
        let memberData = formatRecipientObject(member);
        textRecipients.push(memberData);
      }
    }
  }

  return {
    emailRecipients: emailRecipients,
    textRecipients: textRecipients,
  };
}
