import '../../globalcss.css';
import './GroupingTool.css';
import 'antd/dist/antd.css';

import React, { useEffect, useRef, useState } from 'react';
import * as actioncreators from '../../redux/action';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Form, Row, Col, Input, Button, Select } from 'antd';
import { applyGroupToContacts } from '../../redux/services/groups';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as moment from 'moment';
import { connectClient } from '../../utils/socketio';
import {
  updateProgressMeterResults,
  updateNavProgressMeter,
} from '../../redux/action';
import { selectedOrganizationSelector } from '../../redux/selectors/organization';
import { userDetailSelector } from '../../redux/selectors/user';
const Option = Select.Option;

const GroupingTool = props => {
  const formRef = useRef(null);
  const [memberListInput, setMemberListInput] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [memberDataPoint, setMemberDataPoint] = useState('');
  const [targetValues] = useState([
    {
      displayName: 'Email',
      fieldValue: 'email',
      slowQuery: true,
    },
    {
      displayName: 'Member ID',
      fieldValue: 'memberKeyNumeric',
      slowQuery: false,
    },
    {
      displayName: 'NRDS ID',
      fieldValue: 'memberNRDSID',
      slowQuery: false,
    },
    {
      displayName: 'Phone Number',
      fieldValue: 'phoneNumber',
      slowQuery: true,
    },
  ]);
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const userDetail = useSelector(userDetailSelector);

  useEffect(() => {
    const socket = connectClient(
      userDetail.userId,
      'Grouping_Tool',
      selectedOrganization.id
    );

    socket.on('processUpdate', update => {
      dispatch(updateNavProgressMeter(update.progress));
    });

    socket.on('processComplete', update => {
      dispatch(
        updateProgressMeterResults({
          ...update,
          drawerTitle: 'Grouping Tool Results',
          filename: `${moment().format(
            'MM-DD-YYYY'
          )} Grouping Tool Exceptions Export.csv`,
        })
      );
      socket.disconnect();
    });
  }, [selectedOrganization.id]);

  const handleGroupingToolSubmit = async () => {
    const targetField =
      targetValues.find(value => value.fieldValue === memberDataPoint) || '';
    const organizationId = props.organization.selected.id || '';
    const group = selectedGroup || '';
    let groupName =
      props.type === 'topics'
        ? props.topics.length > 0
          ? props.topics.find(item => {
              return item.id === selectedGroup;
            })
          : ''
        : props.groups.length > 0
        ? props.groups.find(item => {
            return item.id === selectedGroup;
          })
        : '';
    if (memberListInput && organizationId && group && targetField) {
      const tagData = {
        rawUserInput: memberListInput,
        organizationId,
        groupToApply: group,
        targetFieldInfo: targetField,
        userId: userDetail.userId,
        groupName: groupName.name,
      };
      props.actions.fullloader(
        true,
        'Please wait, this may take a few minutes.'
      );
      const result = await applyGroupToContacts(tagData);
      if (!result.error) {
        props.actions.fullloader(false);
        const resultMessage = result.message || '';
        props.close();
        props.actions.toast('success', resultMessage);
        formRef.current.setFieldsValue({
          memberListInput: '',
          value: '',
          tag: '',
        });
        props.actions.setProgressType('Grouping_Tool');
        props.actions.updateNavProgressMeter(0.1); //To bring up progress bar
      } else {
        props.actions.fullloader(false);
        props.close();
        props.actions.toast(
          'error',
          'Error applying groups, please double check your input.'
        );
      }
    } else {
      props.actions.toast(
        'error',
        'Missing required parameters, please ensure you filled out the entire form.'
      );
    }
  };

  return (
    <div style={{ width: '100%', height: '100%', margin: '0' }}>
      <div className={'ApplyTagDrawerHeader'}>
        <IconButton
          onClick={() => {
            props.closeDrawer();
          }}
          style={{ color: 'white' }}
        >
          <CloseIcon />
        </IconButton>
        {props.drawerTitle}
        <Button
          onClick={() => handleGroupingToolSubmit()}
          variant="outlined"
          label="Save"
          type="submit"
          className={'ApplyTagSaveButtons'}
        >
          Apply
        </Button>
      </div>

      <Form
        layout="vertical"
        ref={formRef}
        style={{
          height: 'calc(100% - 60px)',
          padding: '12px 24px 24px 24px',
        }}
      >
        <Row gutter={16}>
          <Col span={24} className="apply-tag-helper-text">
            <span style={{ color: 'red' }}>*</span>{' '}
            {" Select the Group you'd like to apply:"}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} className="tagselect">
            <Form.Item
              name="tag"
              rules={[
                {
                  required: true,
                  message: 'Please select a group to apply.',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: 350 }}
                placeholder="Group to Apply"
                optionFilterProp="children"
                onChange={val => setSelectedGroup(val)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {props.type === 'topics'
                  ? props.topics.length > 0
                    ? props.topics.map(item => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })
                    : ''
                  : props.groups.length > 0
                  ? props.groups.map(item => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })
                  : ''}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} className="apply-tag-helper-text">
            <span style={{ color: 'red' }}>*</span>
            {
              ' Select which info you want NoteRouter to use for identifying which contacts to Group:'
            }
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} className="tagselect">
            <Form.Item
              name="value"
              rules={[
                {
                  required: true,
                  message:
                    'Please select the identifier you would like to target contacts based off.',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: 350 }}
                placeholder="Select Identifier"
                optionFilterProp="children"
                onChange={val => setMemberDataPoint(val)}
              >
                {targetValues.length > 0
                  ? targetValues.map((item, index) => {
                      return (
                        <Option key={index} value={item.fieldValue}>
                          {item.displayName}
                        </Option>
                      );
                    })
                  : ''}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} className="apply-tag-helper-text">
            <span style={{ color: 'red' }}>*</span>{' '}
            {
              ' Copy - paste your info list (like a spreadsheet column) into the box below, then click "Apply." Voila!'
            }
          </Col>
        </Row>
        <Row className="userlistapplyform" gutter={16}>
          <Col span={24}>
            <Form.Item
              name="memberListInput"
              rules={[
                {
                  required: true,
                  message: 'Please enter at least one contact identifier.',
                },
              ]}
            >
              <Input.TextArea
                autoSize={{ minRows: 20, maxRows: 25 }}
                type="text"
                placeholder="List of Identifiers"
                onChange={e => setMemberListInput(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actioncreators, dispatch),
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GroupingTool)
);
