import styled from 'styled-components';

const Header = styled.div`
  padding: 16px 18px;
  border-bottom: 1px solid #e0e0e0;
  background-color: #ededed;
  font-weight: bold;
`;

export default Header;
