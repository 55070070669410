import React from 'react';

import {
  formatDate,
  trimTextIfNeeded,
} from '../../../../../../Component/Drafts/Components/_utils';
import { Card } from '../../../../../../Component/Drafts/Components/_CardUI/Card.styled';
import SendIcon from '../../../../../../Component/Drafts/Components/_CardUI/SendIcon.styled';
import Subject from './TemplateCard.styled';

const TemplateCard = ({ onClick, template }) => {
  return (
    <Card>
      <Card.Body onClick={onClick}>
        <SendIcon />
        <Card.Date>{formatDate(template.saveTime)}</Card.Date>
        <Card.Name>{trimTextIfNeeded(template.name, 25)}</Card.Name>
        {template.subject && (
          <Subject>
            <b>Subject</b>
            <br />
            {trimTextIfNeeded(template.subject, 25)}
          </Subject>
        )}
      </Card.Body>
    </Card>
  );
};

export default TemplateCard;
