import React, { useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'antd';
import socketIOClient from 'socket.io-client';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectedOrganizationSelector,
  unreadTextMessagesCountSelector,
  userDetailSelector,
} from '../../../../redux/selector';
import {
  increaseUnreadCount,
  setLastUpdatedMessageConversationId,
  setLastMessageIdSentByOrg,
  setLastMessageIdSentByUser,
  setTextingHubViewers,
} from '../../../../redux/action';
import { isDefined } from '../../../../libs/js-utils';
import Sider from '../Sider';
import {
  FooterWrapper,
  Header,
  PrimaryMenu,
  SecondaryMenu,
  Wrapper,
} from './Sidenav.styled';
import NewItemLabel from './newItemLabel';
import Cookies from 'universal-cookie';
import store from '../../../../redux/store';

const SEEN_MENU_ITMES = 'seenMenuItems';

const useActiveMenu = menus => {
  const { pathname } = useLocation();
  const matchingMenu = menus.find(({ link, isNested = false }) => {
    if (isNested) {
      return pathname === link || pathname.startsWith(`${link}/`);
    }

    return pathname === link;
  });
  return matchingMenu?.key;
};

const useSocketConnection = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(selectedOrganizationSelector).id;
  const user = useSelector(userDetailSelector);
  const userId = user.id;

  useEffect(() => {
    if (orgId && userId) {
      const socket = socketIOClient(process.env.REACT_APP_andromedaAPI);
      socket.on('connected', () => {
        socket.emit('initializeUserSessionForTextingHub', {
          orgId,
          userId,
        });
        socket.emit('getConnectedUsers', {
          organizationId: orgId,
        });
      });

      socket.on('newMessageSentByOrg', data => {
        console.log('newMessageSentByOrg');
        if (data.senderId === user.id && !document.hidden) return; // if I am sending a message then I should not recieve it back again
        dispatch(setLastUpdatedMessageConversationId(data.conversationId));
        dispatch(setLastMessageIdSentByOrg(data._id));
      });

      socket.on('newTextMessage', data => {
        console.log('newTextMessage');
        dispatch(setLastUpdatedMessageConversationId(data.conversationId));
        dispatch(increaseUnreadCount());
        dispatch(setLastMessageIdSentByUser(data._id));
      });

      socket.on('connectedUsers', connectedUsers => {
        const conversationIds = Object.keys(connectedUsers);
        const userId = userDetailSelector(store.getState()).id;

        conversationIds.forEach(conversationId => {
          connectedUsers[conversationId] = connectedUsers[
            conversationId
          ].filter(elem => elem.userId !== userId);
          connectedUsers[conversationId] = connectedUsers[conversationId].map(
            elem => {
              elem.fullName = `${elem.firstName} ${elem.lastName}`;
              return elem;
            }
          );
          if (connectedUsers[conversationId]?.length === 0) {
            delete connectedUsers[conversationId];
          }
        });
        dispatch(setTextingHubViewers(connectedUsers));
      });

      return () => {
        socket.disconnect({ orgId, userId: user.id });
      };
    }
  }, [orgId]);
};

const Sidenav = ({ renderHeader, menus, secondaryMenus, renderFooter }) => {
  const activePrimaryMenu = useActiveMenu(menus);
  const cookies = useMemo(() => new Cookies(), []);
  const unreadTextCounter = useSelector(unreadTextMessagesCountSelector);
  useSocketConnection();

  // items in this array will have the new item label attached to it until it's interacted with
  const newMenuItems = useMemo(() => [], [menus]);

  // seenMenuItems are the items in the newMenuItems Array and have been interacted with by the user
  const seenMenuItems = useMemo(() => {
    return cookies.get(SEEN_MENU_ITMES) || [];
  }, [window.location.href]);

  // setting the seenMenuItems cookie on label click allows us to keep track of what menu items have been seen
  // thus, the new flag for that item can be dismissed
  const handleOnLabelClick = label => {
    // only add to seenMenuItems cookie if it is a menu item in the newMenuItems Array
    if (!newMenuItems.includes(label)) return;

    const seenMenuItemsUniq = [...new Set([...seenMenuItems, label])];
    cookies.set(SEEN_MENU_ITMES, seenMenuItemsUniq, {
      path: '/',
      encode: v => v,
    });
  };

  useEffect(() => {
    document.title =
      unreadTextCounter > 0
        ? `(${unreadTextCounter}) NoteRouter`
        : 'NoteRouter';
  }, [unreadTextCounter]);

  const hasHeader = isDefined(renderHeader);
  const hasFooter = isDefined(renderFooter);
  const hasSecondaryMenu = secondaryMenus.length > 0;

  const getNotifications = label => {
    if (label === 'Texting Hub') {
      return unreadTextCounter < 100 ? unreadTextCounter : '99+';
    }
  };

  return (
    <Sider>
      {isCollapsed => (
        <Wrapper>
          {hasHeader && (
            <Header $isCentered={isCollapsed}>
              {renderHeader({ isCollapsed })}
            </Header>
          )}

          <PrimaryMenu
            selectedKeys={activePrimaryMenu ? activePrimaryMenu : ''}
          >
            {menus.map(({ key, link, label, icon, hasNotifications }) => {
              const content = (
                <>
                  <PrimaryMenu.Item.Icon as={icon} />
                  {(hasNotifications && getNotifications(label) && (
                    <PrimaryMenu.Item.Notification>
                      {getNotifications(label)}
                    </PrimaryMenu.Item.Notification>
                  )) ||
                    ''}
                  <PrimaryMenu.Item.Label>{label}</PrimaryMenu.Item.Label>
                  {/*only show new item label if it has not been "seen"/interacted with*/}
                  {newMenuItems.includes(label) &&
                    !seenMenuItems.includes(label) && <NewItemLabel />}
                </>
              );

              return (
                <PrimaryMenu.Item
                  key={key}
                  onClick={() => handleOnLabelClick(label)}
                >
                  <PrimaryMenu.Item.Link to={link}>
                    {content}
                  </PrimaryMenu.Item.Link>
                </PrimaryMenu.Item>
              );
            })}
          </PrimaryMenu>

          {hasFooter && (
            <FooterWrapper>{renderFooter({ isCollapsed })}</FooterWrapper>
          )}

          {hasSecondaryMenu && (
            <SecondaryMenu>
              {secondaryMenus.map(
                ({
                  key,
                  label,
                  icon,
                  link = null,
                  action = null,
                  isDisabled = false,
                }) => {
                  const content = (
                    <>
                      <SecondaryMenu.Item.Icon as={icon} />
                      {!isCollapsed && (
                        <SecondaryMenu.Item.Label>
                          {label}
                        </SecondaryMenu.Item.Label>
                      )}
                    </>
                  );

                  let wrapped = null;
                  if (typeof link === 'string') {
                    wrapped = (
                      <SecondaryMenu.Item.LinkContainer
                        to={link}
                        disabled={isDisabled}
                      >
                        {content}
                      </SecondaryMenu.Item.LinkContainer>
                    );
                  } else if (typeof action === 'function') {
                    wrapped = (
                      <SecondaryMenu.Item.ButtonContainer
                        onClick={action}
                        disabled={isDisabled}
                      >
                        {content}
                      </SecondaryMenu.Item.ButtonContainer>
                    );
                  } else {
                    wrapped = (
                      <SecondaryMenu.Item.TextContainer>
                        {content}
                      </SecondaryMenu.Item.TextContainer>
                    );
                  }

                  return (
                    <Tooltip
                      key={key}
                      title={isCollapsed ? label : null}
                      placement="right"
                    >
                      <SecondaryMenu.Item>{wrapped}</SecondaryMenu.Item>
                    </Tooltip>
                  );
                }
              )}
            </SecondaryMenu>
          )}
        </Wrapper>
      )}
    </Sider>
  );
};

Sidenav.defaultProps = {
  renderHeader: null,
  renderFooter: null,
  secondaryMenus: [],
};

export default Sidenav;
