import moment from 'moment';

export const escapeRegExp = value => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const formatDate = (date, withTime = false) => {
  if (!date || date === 'No date found') return 'No date found';
  if (withTime) {
    return moment(date).format('MM/DD/YYYY h:mm A');
  }
  return moment(date).format('MM/DD/YYYY');
};

export const formatEventData = event => {
  return {
    id: event.eventId,
    name: event.name,
    description: event.description,
    date: formatDate(event.startDate),
    registrations: event.numRegistered,
    type: event.type,
    numRegistered: event.numRegistered,
    numGuestRegistered: event.numGuestRegistered,
    courseId: event.courseId,
    deadline: event.registrationDeadline || event.startDate,
    updatedAt: formatDate(event.updatedAt, true),
    attendedCount: event.attendedCount,
  };
};

export const formatEventsData = events => {
  return events?.map(event => formatEventData(event));
};
