import './CustomFields.css';

import { Button, IconButton, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const DeleteFieldModal = props => {
  const handleClose = () => props.closeModal();

  return (
    <div>
      <Modal open={true} onClose={handleClose}>
        <div className={'modalStyling'}>
          <div className={'modalHeader'}>
            <IconButton
              onClick={() => {
                handleClose();
              }}
              style={{ color: 'white' }}
            >
              <CloseIcon />
            </IconButton>
            <span style={{ fontSize: '16px' }}>Confirm Deletion</span>
          </div>
          <div className={'modalBody'}>
            <p>
              This action will delete the fields listed below and all data
              specific to these fields.{' '}
              <b>Once deleted, this data cannot be recovered.</b>
            </p>
            <div
              className={'modalList'}
              style={{
                overflowY: `${props.deleteList.length > 4 ? 'scroll' : ''}`,
              }}
            >
              {props.deleteList.map(field => {
                return (
                  <div className={'modalListItem'} key={field}>
                    {field}
                  </div>
                );
              })}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                size={'small'}
                style={{
                  backgroundColor: 'red',
                  color: 'white',
                  border: 'none',
                }}
                variant="outlined"
                onClick={() => {
                  handleClose();
                }}
              >
                No, cancel
              </Button>
              <Button
                size={'small'}
                style={{
                  backgroundColor: '#5EAB00',
                  color: 'white',
                  border: 'none',
                }}
                variant="outlined"
                onClick={() => {
                  handleClose();
                  props.deleteFields();
                }}
              >
                Yes, delete
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteFieldModal;
