import React, { useEffect, useCallback, useState } from 'react';
import { Modal, MenuItem } from '@material-ui/core';
import {
  BoxStyled,
  FilterStyled,
  ContainerStyled,
  TextStyled,
} from './requestPref.styled';
import { useSendPreferencePage } from '../../../redux/services/preferences';
import { RoundedButton } from '../../Buttons';
import { getQueryStringValue, validateEmail } from '../../../redux/helpers';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const contactFilters = ['Email', 'MLS/Member ID', 'NRDS #', 'Phone'];

const filterValues = {
  'MLS/Member ID': { placeholder: 'Member ID', value: 'source.memberId' },
  'NRDS #': { placeholder: 'NRDS #', value: 'organization.attributes' },
  Email: { placeholder: 'Email', value: 'email' },
  Phone: { placeholder: 'Phone Number', value: 'phoneNumber' },
};

export const RequestPreferenceModal = ({ setTabTitle, organization }) => {
  const [selectedOption, setSelectedOption] = useState('Email');
  const [contactUID, setContactUID] = useState('');
  const [validationError, setValidationError] = useState('');

  const {
    contact,
    loading,
    error,
    resetError,
    resetContact,
    sendPreferencePage,
  } = useSendPreferencePage();

  const { executeRecaptcha } = useGoogleReCaptcha();

  //Both check to make sure that the input is valid, and also do any sanitization/formatting necessary
  const validateAndFormatInput = (input, type) => {
    let errorText = '';
    if (!input || !type) {
      setValidationError(
        'An option must be selected and a value must be inputted.'
      );
      return null;
    }

    if (type === 'Email') {
      const isValidEmail = validateEmail(input);
      if (isValidEmail) {
        return input;
      } else
        errorText =
          'The email address you provided is not valid, please enter a valid email.';
    }

    if (type === 'Phone') {
      const cleanPhone = input.replace(/\D/g, ''); //Remove non-numeric characters
      if (cleanPhone.length !== 10) {
        errorText =
          'The phone number you provided is not valid, please enter a valid 10 digit phone number.';
      } else return cleanPhone;
    }

    if (type === 'NRDS #') {
      const cleanNRDS = input.replace(/\D/g, ''); //Remove non-numeric characters
      if (cleanNRDS.length !== input.length) {
        errorText =
          'The NRDS # provided contains non-numeric characters, please enter a valid NRDS #.';
      } else if (cleanNRDS.length !== 9)
        errorText =
          'The NRDS # provided is not 9 digits, please enter a valid NRDS #.';
      else return cleanNRDS;
    }

    //These IDs are not standardized at all, there is not much validation to look for.
    if (type === 'MLS/Member ID') return input;

    //Given any validation fails, setup validation error message and return null to represent no valid input.
    setValidationError(errorText);
    return null;
  };

  const submitRequest = useCallback(async () => {
    try {
      const token = await executeRecaptcha('preferencePage');
      const cleanInput = validateAndFormatInput(
        contactUID.trim(),
        selectedOption
      );
      if (!cleanInput) {
        //An error has already been displayed, so ignore the rest of the execution
        return;
      }

      //If there was a previous validation error, it will have been resolved to reach this point, so we can remove it.
      if (validationError) setValidationError('');
      resetError();

      await sendPreferencePage(
        organization.id,
        filterValues[selectedOption].value,
        cleanInput,
        token
      );
    } catch (error) {
      console.error('Error retrieving contact info via the UPP', error);
      setValidationError(
        `An unexpected error has occurred, please contact ${organization.organizationName} at ${organization.displayPhone} for help.`
      );
    }
  }, [contactUID, selectedOption, executeRecaptcha, organization.id]);

  const resetForm = () => {
    resetContact();
    setSelectedOption('Email');
    setContactUID('');
  };

  const changeSelectedValue = event => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    setTabTitle(`${organization.acronym ?? ''} Communication`);
  }, [organization]);

  return (
    <Modal open={!getQueryStringValue('id')}>
      {!contact ? (
        <BoxStyled>
          <h1>Hi there!</h1>
          <p>
            To view and update your preferences with {organization.acronym},
            please enter your info so we can confirm who you are.
          </p>
          <ContainerStyled>
            <FilterStyled
              placeholder="Identifier"
              defaultValue={'Email'}
              onChange={changeSelectedValue}
              value={selectedOption}
            >
              {contactFilters.map(filter => (
                <MenuItem key={filter} value={filter}>
                  {filter}
                </MenuItem>
              ))}
            </FilterStyled>
            <TextStyled
              placeholder={filterValues[selectedOption]?.placeholder}
              onChange={event => setContactUID(event.target.value)}
            />
          </ContainerStyled>
          {/*Modal validation error*/}
          <p className="error-msg">{validationError}</p>
          {/*API error*/}
          <p className="error-msg">{error}</p>
          <RoundedButton
            disabled={!contactUID || !selectedOption}
            align="center"
            text="Send me my preference page"
            onClick={submitRequest}
            loading={loading}
            style={{ marginTop: '25px' }}
          />
        </BoxStyled>
      ) : (
        <BoxStyled>
          <h1>
            Got it! Email sent to {contact?.firstName} {contact?.lastName}
          </h1>
          <p>
            {
              "We just sent you an email with a link to your preference page; you can close this page now. If you don't see an email soon, check your spam or promotions folder. If the name above isn't yours, click the \"back\" button and enter different info. If you have any questions, we're here to help:"
            }
          </p>

          <ul>
            <ol>{contact?.organization?.organizationName}</ol>
            <ol>{contact?.organization?.email}</ol>
            <ol>{contact?.organization?.phoneNumber}</ol>
          </ul>

          <RoundedButton align="center" text="Go back" onClick={resetForm} />
        </BoxStyled>
      )}
    </Modal>
  );
};
