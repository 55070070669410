import styled from 'styled-components';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

export const Small = styled.span`
  font-size: 12px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const Column = styled.span`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
