export const formCSVData = data => {
  const fieldsToGet = [
    {
      name: 'By Status',
      possibleFields: ['Association IDs', 'Membership Status', 'Memberships'],
    },
    { name: 'By Type', possibleFields: ['Member Types'] },
    {
      name: 'By Subtype',
      possibleFields: ['Bill Types', 'Subclass', 'Membership Types'],
    },
  ];

  const formedData = fieldsToGet.reduce((acc, curr) => {
    // the field in the AMS could be different from another AMS, so get the right one
    const amsFieldName = curr.possibleFields.find(key => key in data);

    if (!amsFieldName) {
      return [...acc];
    }
    const groupData = data[amsFieldName];
    const row = [];

    // create an initial matrix of data where each row has the field name followed by values
    groupData.forEach((mapping, i) => {
      const fieldNameSplit = mapping.fieldValue.split('_');
      const newFieldName = fieldNameSplit.join(': ');
      const prevMappingNameSplit = groupData[i - 1]?.fieldValue?.split('_');
      const prevNameIsTheSame = prevMappingNameSplit
        ? fieldNameSplit[0] === prevMappingNameSplit[0]
        : false;

      // adding a spacer between each name grouping, e.g. "---"
      if (!prevNameIsTheSame && i > 0) {
        row.push('---');
      }

      row.push(`${newFieldName} (${mapping.fieldValueCount})`);
    });
    return [...acc, [curr.name, ...row]];
  }, []);

  // get the largest row so that we have the right number of row during transposition
  const maxLen = formedData.reduce(
    (max, { length }) => Math.max(max, length),
    0
  );

  // transpose the matrix so that the first item in the row becomes the header for the column
  return Array.from({ length: maxLen }, (_, i) =>
    formedData.map(col => col[i])
  );
};
