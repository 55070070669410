import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { DeleteOutlined, AddOutlined } from '@material-ui/icons';
import { selectedOrganizationSelector } from '../../../redux/selectors/organization';
import {
  Group,
  Container,
  Tab,
  ExportBtn,
  DownloadIcon,
  Row,
  Button,
  AddButton,
} from './Tabs.styled';
import { GENERAL_INFO, USERS, AMS_SYNC, PREFERENCE_PAGE } from './constants';

const Tabs = ({
  selectedTab,
  onSelectTab,
  csvUserData,
  setDeleteUserModalVisibility,
  isDeleteUserBtnDisabledRef,
  setAddUserDrawerVisibility,
}) => {
  const TABS = [GENERAL_INFO, USERS, AMS_SYNC, PREFERENCE_PAGE];
  const selectedOrg = useSelector(selectedOrganizationSelector);

  const renderRightMenu = () => {
    switch (selectedTab) {
      case GENERAL_INFO:
        return <></>;
      case USERS:
        return (
          <Row>
            <CSVLink
              data={csvUserData}
              filename={`${
                selectedOrg.acronym
              } NR Users Export ${moment().format('MM-DD-YYYY')}`}
            >
              <ExportBtn>
                <DownloadIcon />
                Export
              </ExportBtn>
            </CSVLink>
            <Button
              onClick={() => setDeleteUserModalVisibility(true)}
              disabled={isDeleteUserBtnDisabledRef.current}
            >
              <DeleteOutlined />
            </Button>
            <AddButton
              onClick={() => {
                setAddUserDrawerVisibility(true);
              }}
            >
              <AddOutlined />
              Add
            </AddButton>
          </Row>
        );
      case AMS_SYNC:
        return <></>;
      case PREFERENCE_PAGE:
        return <></>;
      default:
        return null;
    }
  };

  return (
    <Container>
      <Group>
        {TABS.map((tab, index) => (
          <Tab
            isActive={selectedTab === tab}
            key={index}
            onClick={() => onSelectTab(tab)}
          >
            {tab}
          </Tab>
        ))}
      </Group>
      <Group>{renderRightMenu()}</Group>
    </Container>
  );
};

export default Tabs;
