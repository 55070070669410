import styled from 'styled-components';

const Wrap = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: ${({ alignTitleToLeft }) =>
    alignTitleToLeft ? 'flex-start' : 'space-between'};
  gap: ${({ alignTitleToLeft }) => (alignTitleToLeft ? '25px' : 0)};
  align-items: center;
  width: 440px;
  @media (max-width: ${props => props.width}) {
    width: 300px;
  }
`;

export default Wrap;
