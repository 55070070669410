export const isEqual = (arr1, arr2) => {
  if (arr1?.length !== arr2?.length) return false;
  const arrObj1 = {},
    arrObj2 = {};
  for (let i = 0; i < arr1.length; i++) {
    const elem1 = arr1[i];
    const elem2 = arr2[i];
    if (arrObj1[elem1]) arrObj1[elem1] += 1;
    else arrObj1[elem1] = 1;

    if (arrObj2[elem2]) arrObj2[elem2] += 1;
    else arrObj2[elem2] = 1;
  }

  for (const element of Object.keys(arrObj2)) {
    if (
      !arrObj1[element] ||
      !arrObj2[element] ||
      arrObj1[element] !== arrObj2[element]
    )
      return false;
  }
  return true;
};

export const isUnion = (arr1, arr2) => {
  const arrObj1 = {},
    arrObj2 = {};
  for (const elem of arr1) {
    arrObj1[elem] = elem;
  }

  for (const elem of arr2) {
    arrObj2[elem] = elem;
  }

  return Object.values(arr1).concat(Object.values(arr2));
};

// TODO: create a test for this function
// pre-supposes that data has id and name fields, returns empty object not
export const createIdNameMappingFromData = data => {
  if (!data) {
    return {};
  }
  return data.reduce((acc, curr) => {
    if (!curr.id || !curr.name) {
      return acc;
    }
    acc[curr.id] = curr.name;
    return acc;
  }, {});
};
