import { notification } from 'antd';

//LOGIN REDUCER FOR LOADER
export function UI(state = {}, action) {
  switch (action.type) {
    case 'FULL_PAGE_LOADER':
      return Object.assign({}, state, {
        fullloader: action.detail,
        loaderMsg: action.message,
      });
    case 'PROGRESS_TYPE':
      return Object.assign({}, state, {
        progressType: action.detail,
      });
    case 'UPDATE_PROGRESS_METER':
      return Object.assign({}, state, {
        progressMeter: action.detail,
      });
    case 'UPDATE_PROGRESS_RESULTS':
      return Object.assign({}, state, {
        progressResults: action.detail
          ? {
              ...state.progressResults,
              ...action.detail,
            }
          : {},
      });
    case 'UPDATE_PROGRESS_EXCEPTIONS':
      return Object.assign({}, state, {
        progressResults: {
          ...state.progressResults,
          exceptions: state.progressResults.exceptions
            ? action.detail.concat(state.progressResults.exceptions)
            : action.detail,
        },
      });
    case 'ERROR':
      return Object.assign({}, state, {
        error: action.detail,
      });
    case 'TOAST':
      // a fix to alleviate issues related to side effects used inside reducer
      setTimeout(() => {
        notification[action.toastype]({
          message: action.message,
          duration: action.duration || 2.7,
          cssClass: 'normalToast',
        });
      }, 0);

      return Object.assign({}, state, {
        lastToast: action,
      });
    default:
      return state;
  }
}
