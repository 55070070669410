// REDUCER FOR TASK LIST
export function organization(state = {}, action) {
  switch (action.type) {
    case 'ORGANISATION':
      return Object.assign({}, state, {
        selected: action.detail,
      });
    case 'ORG_USERS':
      return Object.assign({}, state, {
        users: action.detail,
      });
    case 'ORGANIZATION_LIST':
      return Object.assign({}, state, {
        list: action.detail,
      });
    case 'SET_ORG_TYPES':
      return Object.assign({}, state, {
        orgTypes: action.detail.reduce(
          (types, nextType) => ({ ...types, [nextType.id]: nextType.name }),
          {}
        ),
      });
    default:
      return state;
  }
}
