import styled from 'styled-components';

const AudienceIconStyle = styled.span`
  display: flex;
  flexdirection: row;
  @media (max-width: ${props => props.width}) {
    display: none !important;
  }
`;

export default AudienceIconStyle;
