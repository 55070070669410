import React, { useState, useEffect, useImperativeHandle, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
} from '@material-ui/core';
import { ArrowBackSharp, ArrowForward } from '@material-ui/icons';
import {
  userDetailSelector,
  selectedOrganizationSelector,
} from '../../../../redux/selector';
import {
  createSubscription,
  addSubscriptionToExistingPaymentMethod,
  getDefaultPaymentMethod,
} from '../../api';
import { Title, Button, BackButton } from './Step.styled';
import {
  Container,
  Form,
  Input,
  Wrap,
  ErrorMessage,
  Centered,
  Text,
  InputContainer,
  Wrapper,
} from './Checkout.styled';

const useStyles = makeStyles({
  input: {
    border: 'none',
    minWidth: '150px',
    borderRadius: '4px',
    padding: '5px 0',
    fontSize: '16px',
    lineHeight: '1.5',
    color: '#495057',
  },
});
const EXISTING_PAYMENT_METHOD = 'existingPaymentMethod';
const NEW_PAYMENT_METHOD = 'newPaymentMethod';

const StripeInput = props => {
  const { component: Component, inputRef, ...other } = props;
  const elementRef = useRef();

  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));

  return (
    <Component onReady={element => (elementRef.current = element)} {...other} />
  );
};

const Checkout = ({ orgId, returnBack, proceedToSuccessPage }) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const userDetails = useSelector(userDetailSelector);
  const selectedOrg = useSelector(selectedOrganizationSelector);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessingCheckout, setProcessingCheckout] = useState(false);
  const [selectedOption, setSelectedOption] = useState(EXISTING_PAYMENT_METHOD);
  const [isAgreementChecked, setAgreementChecked] = useState(false);
  const [lastFourDigits, setLastFourDigits] = useState(null);

  const getLastFourDigitsIfExist = async () => {
    const { lastFourDigits, type } = await getDefaultPaymentMethod();
    if (lastFourDigits) {
      const typeName = type === 'card' ? 'Card' : 'Bank';
      setLastFourDigits(`${typeName} ending in ${lastFourDigits}`);
    } else {
      setLastFourDigits(null);
    }
  };

  useEffect(() => {
    getLastFourDigitsIfExist();
  }, [orgId]);

  const handleAcceptBillingAgreement = event => {
    setAgreementChecked(event.target.checked);
  };
  const handleChange = event => {
    setSelectedOption(event.target.value);
  };

  const addSubscription = async (method, args = undefined) => {
    try {
      setErrorMessage(null);
      setProcessingCheckout(true);
      await method(args);
      proceedToSuccessPage();
    } catch (e) {
      console.log(e.message);
      setProcessingCheckout(false);
      setErrorMessage(`Something wrong has just happened with checkout process. Please try
      again later or don't hesitate to send us a note at
      support@noterouter.com if you need immediate help.`);
    }
  };

  const handleStripeElementChange = ({ complete, error }) => {
    if (error) {
      setErrorMessage(error.message);
    } else if (complete) {
      setErrorMessage(null);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (selectedOption === EXISTING_PAYMENT_METHOD) {
      return addSubscription(addSubscriptionToExistingPaymentMethod);
    }

    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: event.target.name.value,
        email: event.target.email.value,
        address: {
          line1: event.target.address.value,
          city: event.target.city.value,
          state: event.target.state.value,
          postal_code: event.target.postalCode.value,
          country: event.target.country.value,
        },
      },
    });

    if (error) {
      setErrorMessage(error.message);
      return;
    }
    return addSubscription(createSubscription, paymentMethod);
  };

  return (
    <Container>
      <BackButton onClick={returnBack}>
        <ArrowBackSharp style={{ color: '#6c7075' }} />
      </BackButton>
      <Title>Payment Method</Title>
      <RadioGroup
        aria-label="payment-method-options"
        name="Payment method options"
        value={selectedOption}
        onChange={handleChange}
      >
        <FormControlLabel
          value={EXISTING_PAYMENT_METHOD}
          control={<Radio style={{ color: '#007ADE' }} />}
          label={`Use Existing Payment Method (${
            lastFourDigits || 'Monthly Invoicing'
          })`}
        />
        <FormControlLabel
          value={NEW_PAYMENT_METHOD}
          control={<Radio style={{ color: '#007ADE' }} />}
          label="Add New Payment Method (Card)"
        />
      </RadioGroup>
      <br />
      <Form onSubmit={handleSubmit}>
        {selectedOption === NEW_PAYMENT_METHOD && (
          <>
            <Wrap>
              <Input
                label="Cardholder Name"
                placeholder="John Smith"
                name="name"
                defaultValue={`${userDetails.firstName} ${userDetails.lastName}`}
                required
              />
              <Input
                label="Billing Email"
                placeholder="john.smith@yourdomain.com"
                name="email"
                defaultValue={userDetails.email}
                required
              />
            </Wrap>
            <Input
              label="Billing Address"
              placeholder="185 Berry St"
              name="address"
              defaultValue={selectedOrg.address[0].address}
              required
            />
            <Wrap>
              <Input
                placeholder="San Francisco"
                label="City"
                name="city"
                defaultValue={selectedOrg.address[0].city}
                required
              />
              <Input
                label="State"
                placeholder="CA"
                name="state"
                defaultValue={selectedOrg.address[0].state}
                required
              />
            </Wrap>
            <Wrap>
              <Input
                label="Postal Code"
                placeholder="94107"
                name="postalCode"
                defaultValue={selectedOrg.address[0].zipcode}
                required
              />
              <Input
                label="Country"
                placeholder="US"
                name="country"
                defaultValue={'US'}
                required
              />
            </Wrap>
            <Wrap>
              <InputContainer>
                <TextField
                  label="Card Number"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleStripeElementChange}
                  InputProps={{
                    inputComponent: StripeInput,
                    inputProps: {
                      component: CardNumberElement,
                      options: {
                        style: {
                          base: {
                            fontSize: '16px',
                          },
                        },
                      },
                    },
                    className: classes.input,
                  }}
                />
              </InputContainer>
              <TextField
                label="Expiration"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleStripeElementChange}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardExpiryElement,
                    options: {
                      style: {
                        base: {
                          fontSize: '16px',
                        },
                      },
                    },
                  },
                  className: classes.input,
                }}
              />
              <TextField
                label="Card CVC"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleStripeElementChange}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardCvcElement,
                    options: {
                      style: {
                        base: {
                          fontSize: '16px',
                        },
                      },
                    },
                  },
                  className: classes.input,
                }}
              />
            </Wrap>
          </>
        )}
        <br />
        <Centered>
          <Wrapper>
            <Checkbox
              checked={isAgreementChecked}
              onChange={handleAcceptBillingAgreement}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <Text>
              In addition to any previously agreed upon charges for NoteRouter
              service, NoteRouter is authorized to bill my organization on the
              1st day of each month for all SMS segments (&quot;texts&quot;)
              sent out via the Texting Hub during the previous month (includes
              all conversation replies and custom keyword replies.) One SMS
              segment is sent per 153 - 160 characters. Some characters (e.g.
              emojis) count as two characters.
            </Text>
          </Wrapper>
          <Button
            width="350px"
            type="submit"
            disabled={!stripe || !isAgreementChecked}
          >
            {isProcessingCheckout ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <>
                Continue
                <ArrowForward />
              </>
            )}
          </Button>
        </Centered>
        <ErrorMessage>{errorMessage ? errorMessage : null}</ErrorMessage>
      </Form>
    </Container>
  );
};

export default Checkout;
