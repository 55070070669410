import React, { useContext, useState, useEffect } from 'react';
import {
  TextBoxStyled,
  AddNotesStyled,
  ButtonStyled,
} from './addContactNotes.styled';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Quilview from '../../Quilview/Quilview';
import { useCreateNotes } from '../../../redux/services/notes';
import { NotesContext } from '../../../redux/services/notes/notes.context';
import { IconHeading } from '../../Heading';
import { RoundedButton } from '../../Buttons/RoundedButton';
import { useToast } from '../../../redux/action';

export const AddContactNotes = () => {
  const { noteRouterId } = useContext(NotesContext);
  const [note, setNote] = useState('');
  const { createState, createNote } = useCreateNotes();
  const { errorToast } = useToast();

  useEffect(() => {
    if (!createState.loading && createState.success) setNote('');
  }, [createState]);

  const addNote = () => {
    if (note?.match(/<img.+src=*/g)?.length) {
      errorToast('Cannot add media in notes');
    } else {
      createNote(note, noteRouterId);
    }
  };

  return (
    <AddNotesStyled>
      <IconHeading icon={<AddCircleOutlineOutlinedIcon />} title="New Note" />
      <TextBoxStyled>
        <Quilview
          isSimpleEditor
          value={note}
          ontextchange={newContent => setNote(newContent)}
        />
      </TextBoxStyled>
      <ButtonStyled>
        <RoundedButton
          disabled={
            createState.loading ||
            !note?.replace(/<(.|\n)*?>/g, '').trim().length
          }
          onClick={addNote}
          text="Add"
        />
      </ButtonStyled>
    </AddNotesStyled>
  );
};
