import styled from 'styled-components';
import { Button as MuiButton } from '@material-ui/core';

const Button = styled(MuiButton).attrs({
  size: 'large',
})`
  grid-area: next;
  align-self: start;

  && {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    line-height: 1.4;
    color: #5eab00;
    border: 1px solid #5eab00;
    border-radius: 5px;
    background-color: #5eab0005;
    transition: background-color 0.3s;

    &:hover {
      background-color: #5eab000f;
    }

    // Override global default styles which set Poppins as font-family for everything (which is pretty bad!)
    > .MuiButton-label {
      font-family: inherit;
    }
  }
`;

export default Button;
