import { FormControl } from '@material-ui/core';
import styled from 'styled-components';

export const TimeZoneStyled = styled(FormControl)`
  .MuiSelect-select {
    padding-right: 0 !important;
    margin-left: 10px;
    font-family: Montserrat;
    color: #616161;

    &:focus {
      background-color: transparent;
    }
  }

  .MuiInputBase-root {
    max-height: 40px;
    justify-content: center;
  }
`;
