import styled from 'styled-components';
import { Select } from 'antd';

const createCTAControl = (BaseComponent, { color }) => {
  return styled(BaseComponent).attrs({ size: 'large' })`
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #${color};
    border: 1px solid #${color};
    border-radius: 5px;
    background-color: #${color}05;
    transition: background-color 0.3s;

    // overriding default antd styles for hovered state
    &,
    &:hover {
      border: 1px solid #${color};
      color: #${color};
    }

    &:hover {
      background-color: #${color}0f;
    }
  `;
};

const createCTASelect = options => {
  return styled(createCTAControl(Select, options))`
    height: 40px;

    // resetting default antd styles for select's search input container
    > .ant-select-selector {
      border: none !important;
      background-color: transparent !important;
    }

    // antd sets select's icon to a specific color, resetting it to inherit select's color
    > .ant-select-arrow {
      color: inherit;
    }
  `;
};

const PersonalizeSelect = styled(createCTASelect({ color: '007ade' }))`
  width: 180px;

  .ant-select-selection-placeholder {
    color: #007ade;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  :not(.ant-select-open) .ant-select-selection-placeholder {
    opacity: 1;
  }
`;

export default PersonalizeSelect;
