import styled, { css } from 'styled-components';
import React from 'react';
import { Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Color, Logo, Variant } from '../../infrastructure/ui/logo';

export const Container = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const ThumbnailButton = styled.button`
  border: none;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 50px;
  aspect-ratio: 1/1;
  background-color: #ffffff;
  border-radius: 50%;

  &:enabled {
    cursor: pointer;
  }

  & > * {
    transition: transform 250ms;
  }

  &:enabled:hover {
    & > * {
      transform: scale(150%);
    }
  }
`;

const baseButtonContentAlignStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const baseButtonContentSizingStyles = css`
  width: 32px;
  height: 32px;
`;

ThumbnailButton.Spinner = styled(Spin).attrs({
  indicator: <LoadingOutlined />,
})`
  ${baseButtonContentAlignStyles}
  ${baseButtonContentSizingStyles}
`;

ThumbnailButton.Image = styled.img`
  ${baseButtonContentSizingStyles};
  object-fit: contain;
`;

ThumbnailButton.NoLogoMessage = styled.span`
  ${baseButtonContentAlignStyles}
  ${baseButtonContentSizingStyles}
  
  font-size: 16px;
  color: #007ade;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
`;

export const OrganizationDisplay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: #ffffff;
  overflow: hidden;
`;

OrganizationDisplay.Header = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const clickableStyles = css`
  cursor: pointer;
  transition: transform 250ms;

  &:hover {
    transform: translateY(-2px);
  }
`;

OrganizationDisplay.Title = styled.span`
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${({ $isClickable }) => $isClickable && clickableStyles}
`;

const openStyles = css`
  transform: rotate(-90deg);
`;

OrganizationDisplay.OpenButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  padding: 4px;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: all 250ms;

  &:hover {
    background-color: #ffffff0f;
  }

  ${({ $isOpen }) => $isOpen && openStyles}
`;

OrganizationDisplay.Footer = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 10px;
  line-height: 1.25;
`;

OrganizationDisplay.NRLogo = styled(Logo).attrs({
  variant: Variant.PRIMARY,
  color: Color.LIGHT,
})`
  width: 70px;
  height: 10px;
`;

export const SearchTitle = styled.span`
  display: flex;
  margin: -5px -16px -4px; // remove default popover title padding
  padding: 8px 12px;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 500px;
  margin: -12px; // remove default popover content padding
`;

export const SearchInput = styled(Input.Search)`
  width: auto;
  margin: 8px 12px 0;
`;

export const ResultsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  overflow: hidden;
`;

export const OrganizationsList = styled.ul`
  padding: 0 8px;
  margin-bottom: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

OrganizationsList.Item = styled.li``;

OrganizationsList.Item.Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;

  width: 100%;
  padding: 8px;
  cursor: pointer;
  transition: color 250ms;
  text-align: left;

  &:hover {
    color: #007ade;
  }
`;
