export function audiences(state = {}, action) {
  switch (action.type) {
    case 'AUDIENCE_LIST':
      return Object.assign({}, state, {
        list: action.audiences,
      });
    default:
      return state;
  }
}
