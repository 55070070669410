import { css } from 'styled-components';

const baseInputStyles = css`
  color: #6c7075 !important;
  font-size: 14px !important;
  font-family: 'Montserrat', sans-serif !important;
  border: 1px solid #d4d7d9;
  border-radius: 4px;
  transition: border-color ease-in 250ms;

  &:hover:not(.Mui-disabled) {
    border-color: #90b9da;
    transition-timing-function: ease-out;
  }

  &:focus-within {
    border-color: #007ade;
    transition-timing-function: ease-out;
  }

  &::before,
  &::after {
    display: none;
  }

  & > .MuiInputBase-input {
    padding: 12px 16px;

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export default baseInputStyles;
