import React from 'react';
import {
  IconButton,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Clear as ClearIcon, Search as SearchIcon } from '@material-ui/icons';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import {
  HeaderContainer,
  DropDownContainer,
} from '../TextMessagesTable/TextMessagesTable.styled';
import { CONVERSATIONS } from '../utils/constants';

const defaultTheme = createTheme();
const useStyles = makeStyles(
  theme => ({
    textField: {
      [theme.breakpoints.down('xs')]: {
        width: '300px',
      },
      margin: theme.spacing(1, 0.5, 1.5),
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.5),
      },
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    formControl: {
      minWidth: 190,
      '& .MuiSelect-select': {
        paddingLeft: '8px',
      },
    },
  }),
  { defaultTheme }
);

const QuickSearch = ({
  value,
  onChange,
  clearSearch,
  status,
  setStatus,
  selectedMenuItemName,
}) => {
  const classes = useStyles();
  return (
    <HeaderContainer>
      <DropDownContainer>
        <FormControl size="small" className={classes.formControl}>
          {selectedMenuItemName === CONVERSATIONS ? (
            <Select
              labelId="conversation-select-label"
              id="conversation-select"
              value={status}
              onChange={e => {
                if (e.target.value !== status) {
                  setStatus(e.target.value);
                }
              }}
              label="Conversation Type"
            >
              <MenuItem value="active">Active Conversations</MenuItem>
              <MenuItem value="archived">Archived Conversations</MenuItem>
              <MenuItem value="spammed">Spam Conversations</MenuItem>
            </Select>
          ) : (
            <Select
              labelId="keyword-select-label"
              id="keyword-select"
              value={status}
              onChange={e => {
                if (e.target.value !== status) {
                  setStatus(e.target.value);
                }
              }}
              label="Keyword Type"
            >
              <MenuItem value="active">Active Keywords</MenuItem>
              <MenuItem value="archived">Archived Keywords</MenuItem>
            </Select>
          )}
        </FormControl>
      </DropDownContainer>
      <TextField
        variant="standard"
        value={value}
        onChange={onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? 'visible' : 'hidden' }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </HeaderContainer>
  );
};

export default QuickSearch;
