import React, { useState, useEffect } from 'react';
import withAuthentication from '../../../Component/withAuthentication';
import { Tooltip } from '@material-ui/core';
import { AddCircle, HelpOutline } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { selectedOrganizationSelector } from '../../../redux/selector';
import { getTextSentThisMonth } from '../api';
import { formatPhoneNumber } from '../utils';
import { CONVERSATIONS, KEYWORDS } from '../utils/constants';
import {
  Container,
  Row,
  Button,
  Group,
  TextGroup,
  MainText,
  Text,
  Tab,
} from './TextingHub.styled';
import Keywords from '../Keywords/Keywords';
import Conversations from '../Conversations';
import ArchiveBtnForText from './ArchiveBtnForText';
import ArchiveBtnForKeywords from './ArchiveBtnForKeywords';
import SpamBtn from './SpamBtn';
import StripeContext from '../Stripe/StripeContext';
import Stripe from '../Stripe';

const TextingHub = () => {
  const ACTIVE = 'active';
  const selectedOrg = useSelector(selectedOrganizationSelector);
  const [isTextingHubDisabled, setTextingHubEnabled] = useState(
    !selectedOrg.textingHubEnabled
  );
  const [textMessages, setTextMessages] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [status, setStatus] = useState(ACTIVE); //active, spammed or archived for text messages
  const [keywordStatus, setKeywordStatus] = useState(ACTIVE); //active or archived for keywords
  const [selectedIds, setSelectedIds] = useState([]);
  const [textCount, setTextCount] = useState({ inbound: 0, outbound: 0 });
  const [selectedMenuItemName, setSelectedMenuItemName] =
    useState(CONVERSATIONS);
  const [isNewKeywordDialogOpen, setNewKeywordDialogOpen] = useState(false);

  const getTextMessageCount = async () => {
    const textCount = await getTextSentThisMonth();
    setTextCount(textCount);
  };

  useEffect(() => {
    setTextingHubEnabled(!selectedOrg.textingHubEnabled);
  }, [selectedOrg.textingHubEnabled]);

  useEffect(() => {
    getTextMessageCount();
  }, [selectedOrg.id]);

  const resetSelectedIds = () => {
    setSelectedIds([]);
  };

  useEffect(() => {
    resetSelectedIds();
  }, [status]);

  useEffect(() => {
    resetSelectedIds();
    setStatus(ACTIVE);
    setKeywordStatus(ACTIVE);
  }, [selectedMenuItemName]);

  const handleSelectRow = rows => {
    setSelectedIds(rows);
  };

  const handleMenuItemClick = menuItemName => {
    if (isTextingHubDisabled) return;
    setSelectedMenuItemName(menuItemName);
  };

  const handleAddNewKeyword = () => {
    if (isTextingHubDisabled) return;
    setNewKeywordDialogOpen(true);
  };

  const renderMainContent = () => {
    if (selectedMenuItemName === KEYWORDS) {
      return (
        <Keywords
          onRowSelected={handleSelectRow}
          isNewKeywordDialogOpen={isNewKeywordDialogOpen}
          setNewKeywordDialogOpen={setNewKeywordDialogOpen}
          keywords={keywords}
          setKeywords={setKeywords}
          orgId={selectedOrg.id}
          keywordStatus={keywordStatus}
          setKeywordStatus={setKeywordStatus}
        />
      );
    } else {
      return (
        <Conversations
          onRowSelected={handleSelectRow}
          textMessages={textMessages}
          setTextMessages={setTextMessages}
          isTextingHubDisabled={isTextingHubDisabled}
          orgId={selectedOrg.id}
          status={status}
          setStatus={setStatus}
        />
      );
    }
  };

  return (
    <Container>
      {isTextingHubDisabled && (
        <StripeContext>
          <Stripe />
        </StripeContext>
      )}
      <Row>
        <Group>
          {[CONVERSATIONS, KEYWORDS].map(menuItemName => {
            return (
              <Tab
                key={menuItemName}
                onClick={() => handleMenuItemClick(menuItemName)}
                className={selectedMenuItemName === menuItemName ? ACTIVE : ''}
              >
                {menuItemName}
              </Tab>
            );
          })}
          <TextGroup>
            <MainText>
              {selectedOrg.acronym}:{' '}
              <b>{formatPhoneNumber(selectedOrg.fromPhone)}</b>
            </MainText>
            <Text>
              Texts received/sent this month:
              <b>
                {textCount.inbound}
                {' / '}
                {textCount.outbound}
              </b>
              <Tooltip title="This count includes automated texts sent via keywords. A single SMS text segment can fit 153 to 160 characters. However, special characters like emojis reduce the maximum size of a text segment to 67 characters.">
                <HelpOutline style={{ fontSize: '14px' }} />
              </Tooltip>
            </Text>
          </TextGroup>
        </Group>
        <Group>
          {selectedMenuItemName === KEYWORDS && (
            <ArchiveBtnForKeywords
              keywords={keywords}
              setKeywords={setKeywords}
              selectedIds={selectedIds}
              isTextingHubDisabled={isTextingHubDisabled}
              status={keywordStatus}
              resetSelectedIds={resetSelectedIds}
            />
          )}
          {selectedMenuItemName === CONVERSATIONS && (
            <>
              <ArchiveBtnForText
                textMessages={textMessages}
                setTextMessages={setTextMessages}
                selectedIds={selectedIds}
                isTextingHubDisabled={isTextingHubDisabled}
                status={status}
                resetSelectedIds={resetSelectedIds}
              />
              <SpamBtn
                textMessages={textMessages}
                setTextMessages={setTextMessages}
                selectedIds={selectedIds}
                isTextingHubDisabled={isTextingHubDisabled}
                status={status}
                resetSelectedIds={resetSelectedIds}
              />
            </>
          )}
          {selectedMenuItemName === KEYWORDS && (
            <Button color={'#5EAB00'} onClick={handleAddNewKeyword}>
              <AddCircle style={{ fontSize: '18px', marginRight: '5px' }} />
              New
            </Button>
          )}
        </Group>
      </Row>
      {renderMainContent()}
    </Container>
  );
};

export default withAuthentication(TextingHub);
