export const CONVERSATIONS = 'Conversations';
export const KEYWORDS = 'Keywords';

export const BLOCKED_KEYWORDS = [
  'HELP',
  'INFO',
  'START',
  'UNSTOP',
  'STOP',
  'STOPALL',
  'END',
  'CANCEL',
  'QUIT',
];
