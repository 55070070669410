import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

const PRIMARY = 'PRIMARY';
const WHITE = '#ffffff';
const GREEN = '#5eab02';
const GREY = '#888888';
const DISABLED_COLOR_PRIMARY_FONT = WHITE;
const DISABLED_COLOR_SECONDARY_FONT = GREY;
const DISABLED_COLOR_PRIMARY_BACKGROUND = GREY;
const DISABLED_COLOR_SECONDARY_BACKGROUND = WHITE;
const ACTIVE_COLOR_PRIMARY_FONT = WHITE;
const ACTIVE_COLOR_SECONDARY_FONT = GREY;
const ACTIVE_COLOR_PRIMARY_BACKGROUND = GREEN;
const ACTIVE_COLOR_SECONDARY_BACKGROUND = WHITE;

const getFontColor = props => {
  if (props.toggleEnabled) {
    return props.version === PRIMARY
      ? ACTIVE_COLOR_PRIMARY_FONT
      : ACTIVE_COLOR_SECONDARY_FONT;
  } else {
    return props.version === PRIMARY
      ? DISABLED_COLOR_PRIMARY_FONT
      : DISABLED_COLOR_SECONDARY_FONT;
  }
};

const getBackgroundColor = props => {
  if (props.toggleEnabled) {
    return props.version === PRIMARY
      ? ACTIVE_COLOR_PRIMARY_BACKGROUND
      : ACTIVE_COLOR_SECONDARY_BACKGROUND;
  } else {
    return props.version === PRIMARY
      ? DISABLED_COLOR_PRIMARY_BACKGROUND
      : DISABLED_COLOR_SECONDARY_BACKGROUND;
  }
};

const getActiveFontColor = props => {
  if (props.toggleEnabled) {
    return ACTIVE_COLOR_PRIMARY_FONT;
  }
};

const getActiveBackgroundColor = props => {
  if (props.toggleEnabled) {
    return ACTIVE_COLOR_PRIMARY_BACKGROUND;
  }
};

export const ToggleButtonLeft = styled.button`
  color: ${getFontColor};
  border: 1px solid #888888;
  font-size: 20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right-style: none;
  padding: 7px 10px;
  font-weight: bold;
  transition: all 0.3s ease-in;
  background-color: ${getBackgroundColor};
  margin: 0;
  &:hover,
  &.active {
    color: ${getActiveFontColor};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    background-color: ${getActiveBackgroundColor};
  }
`;

export const ToggleButtonRight = styled.button`
  color: ${getFontColor};
  border: 1px solid #888888;
  font-size: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 7px 10px;
  font-weight: bold;
  transition: all 0.3s ease-in;
  background-color: ${getBackgroundColor};
  margin: 0;
  &:hover,
  &.active {
    color: ${getActiveFontColor};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    background-color: ${getActiveBackgroundColor};
  }
`;

export const ToggleButtonGroup = styled.div`
  display: flex;
`;

export const Link = styled.a`
  font-size: 13px;
  position: relative;
  top: -7px;
  left: -2px;
  color: grey;
  &:hover {
    color: #007ade;
  }
`;
