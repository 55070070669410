import store from '../../../redux/store';
import { selectedOrganizationSelector } from '../../../redux/selectors/organization';
import api from '../../../utils/andromeda-api';

const getOrganizationId = () => {
  return selectedOrganizationSelector(store.getState()).id;
};

export const fetchEvents = async () => {
  const organizationId = getOrganizationId();

  const { data } = await api.get(`/eventsAndClasses/${organizationId}`);

  return data;
};

export const fetchEventRegistrations = async eventId => {
  const organizationId = getOrganizationId();

  const { data } = await api.get(`/eventsRegistrations/${organizationId}`, {
    params: {
      eventId,
    },
  });

  return data;
};

export const fetchEventIdsAndNamesService = async ({ page, searchValue }) => {
  const organizationId = getOrganizationId();

  const { data } = await api.get(
    `/eventsAndClasses/eventNames/${organizationId}`,
    {
      params: {
        page,
        searchValue,
      },
    }
  );

  return data.result;
};

export const fetchEventById = async eventId => {
  const organizationId = getOrganizationId();

  const {
    data: { result },
  } = await api.get(`/eventsAndClasses/event/${organizationId}`, {
    params: {
      eventId,
    },
  });
  return result;
};

export const fetchCourseIdsAndNamesService = async ({ page, searchValue }) => {
  const organizationId = getOrganizationId();

  const {
    data: { result },
  } = await api.get(`/eventsAndClasses/courseNames/${organizationId}`, {
    params: {
      page,
      searchValue,
    },
  });

  return result;
};

export const fetchContactEngagement = async ({ noteRouterId, eventId }) => {
  const organizationId = getOrganizationId();
  const {
    data: { result },
  } = await api.get(`/eventsRegistrations/engagement/${organizationId}`, {
    params: {
      noteRouterId,
      eventId,
    },
  });

  return result;
};
