import React, { useEffect, useState } from 'react';
import { Drawer, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Container, Header, Body, Row, Wrapper } from './ClickActivity.styled';
import ABToggleButton from './ABToggleButton';
import ClickActivityTable from './ClickActivityTable';
import InputSearch from './ClickActivityTable/InputSearch';
import ExportBtn from './ExportBtn/ExportBtn';

const ClickActivity = ({
  isClickActivityVisible,
  setClickActivityVisibility,
  initialMessage,
}) => {
  const [message, setMessage] = useState({});
  const [rows, setRows] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (initialMessage) {
      setMessage(initialMessage);
    }
  }, [initialMessage]);

  const closeDrawer = () => {
    setClickActivityVisibility(false);
  };

  const getVersionName = message => {
    if (!message?.AB || !message.AB.version) return '';

    return `(Version ${message.AB.version})`;
  };

  return (
    <Drawer open={isClickActivityVisible} onClose={closeDrawer} anchor="right">
      <Container>
        <Header>
          <IconButton size={'small'} style={{ color: 'white' }}>
            <Close size={'small'} onClick={closeDrawer} />
          </IconButton>
          Click Activity: {message?.draftName} {getVersionName(message)}
        </Header>
        <Body>
          <Row>
            <InputSearch
              messageId={message.id}
              setLoading={setLoading}
              setRows={setRows}
            />
            <Wrapper>
              {message?.AB && message?.AB.version && (
                <ABToggleButton message={message} setMessage={setMessage} />
              )}
              <ExportBtn selectedRows={selectedRows} message={message} />
            </Wrapper>
          </Row>
          <ClickActivityTable
            isLoading={isLoading}
            setLoading={setLoading}
            messageId={message.id}
            rows={rows}
            setRows={setRows}
            setSelectedRows={setSelectedRows}
          />
        </Body>
      </Container>
    </Drawer>
  );
};

export default ClickActivity;
