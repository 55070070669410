import styled from 'styled-components';

export const Container = styled.div`
  width: 750px;
`;

export const Header = styled.div`
  color: white;
  font-size: 15px;
  height: 60px;
  width: 100%;
  background: #005092;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 20px;
`;

export const SaveBtn = styled.button`
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: none;
  font-size: 14px;
  padding: 3px 20px;
  color: #005092;
  transition: all 0.3s ease-in;
  border-radius: 20px;
  &:hover,
  &.active {
    color: white;
    cursor: pointer;
    background-color: #238b22;
  }
`;

export const Group = styled.span`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Title = styled.span`
  color: white;
`;
