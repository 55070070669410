import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { AddCircleOutline, CancelOutlined } from '@material-ui/icons';
import SelectDropDown from '../_shared/selectDropdown';
import Container from '../_shared/container';
import Text from '../_shared/text';
import Wrap from '../_shared/wrap';
import Bold from '../_shared/bold';
import Content from '../../../_shared/content';
import FitContent from '../_shared/fitContent';
import Space from '../_shared/space';
import { attendedStatuses } from '../_shared/constants';
import { capitalizeFirstLetter } from '../_shared/utils';
import EventsAndClassesIdSelector from '../_shared/EventsAndClassesSelector';
import {
  addFilter,
  removeFilter,
  toast,
} from '../../../../../../../../../redux/action';
import { fetchEventIdsAndNamesService } from '../../../../../../../../../features/events-and-classes/api';

const EventsAndClassAttendance = ({ filterName, checked, selectedFilters }) => {
  const dispatch = useDispatch();
  const [attendanceStatus, setAttendanceStatus] = useState('');
  const [eventOrClassId, setEventOrClassId] = useState('');
  const [totalEventAndClassAttendances, setTotalEventAndClassAttendances] =
    useState(selectedFilters || []);

  useEffect(() => {
    if (totalEventAndClassAttendances.length) {
      dispatch(addFilter({ [filterName]: totalEventAndClassAttendances }));
    } else if (
      totalEventAndClassAttendances.length === 0 &&
      selectedFilters &&
      selectedFilters.length !== 0
    ) {
      dispatch(removeFilter(filterName));
    }
  }, [totalEventAndClassAttendances]);

  useEffect(() => {
    if (!checked && totalEventAndClassAttendances.length) {
      dispatch(removeFilter(filterName));
    }
  }, [checked]);

  const handleEventOrClassIdChange = eventId => {
    setEventOrClassId(eventId);
  };

  const handleAttendanceStatusChange = ({ target }) => {
    setAttendanceStatus(target.value);
  };

  const handleAddEventOrClassAttendance = () => {
    const errorMessage = [];
    if (!attendanceStatus) {
      errorMessage.push('Please select attendance status.');
    }
    if (!eventOrClassId) {
      errorMessage.push('Please add Event/Class ID.');
    }

    if (errorMessage.length) {
      dispatch(toast('error', errorMessage.join('\n')));
    } else {
      setTotalEventAndClassAttendances([
        ...totalEventAndClassAttendances,
        { attendanceStatus, eventOrClassId },
      ]);
      setAttendanceStatus('');
      setEventOrClassId('');
    }
  };

  const handleRemoveEventAndClassAttendance = index => {
    setTotalEventAndClassAttendances([
      ...totalEventAndClassAttendances.slice(0, index),
      ...totalEventAndClassAttendances.slice(index + 1),
    ]);
  };

  return (
    <Container>
      {(selectedFilters || []).map(
        ({ attendanceStatus, eventOrClassId }, index) => (
          <Wrap key={index}>
            <FitContent>
              {index > 0 ? (
                <Space>or {attendanceStatus}</Space>
              ) : (
                <Space>- {capitalizeFirstLetter(attendanceStatus)}</Space>
              )}
              Event
            </FitContent>
            <Bold>ID: {eventOrClassId}</Bold>
            <CancelOutlined
              fontSize="small"
              style={{ fill: '#E63946' }}
              onClick={() => handleRemoveEventAndClassAttendance(index)}
            />
          </Wrap>
        )
      )}
      <Content>
        <Text>{totalEventAndClassAttendances.length ? 'or' : ''}</Text>
        <SelectDropDown
          value={attendanceStatus}
          menuItemNames={attendedStatuses}
          style={{ width: 140 }}
          onChange={handleAttendanceStatusChange}
        />
        <FitContent>
          <Text>contacts for Event/Class</Text>
        </FitContent>
        <EventsAndClassesIdSelector
          eventId={eventOrClassId}
          setEventId={handleEventOrClassIdChange}
          placeholder="Event/Class ID"
          fetchDataFunc={fetchEventIdsAndNamesService}
        />
      </Content>
      <Button
        variant="contained"
        size="small"
        startIcon={<AddCircleOutline />}
        style={{
          borderRadius: 50,
          backgroundColor: '#007ADE',
          margin: '0 25px',
          padding: '5px 15px',
          color: 'white',
        }}
        onClick={handleAddEventOrClassAttendance}
      >
        Add
      </Button>
    </Container>
  );
};

export default EventsAndClassAttendance;
