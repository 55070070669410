import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  addFilter,
  removeFilter,
} from '../../../../../../../../../redux/action';
import Container from '../_shared/container';
import Text from '../_shared/text';
import Wrap from '../_shared/wrap';
import Bold from '../_shared/bold';
import Content from '../../../_shared/content';
import Button from '@material-ui/core/Button';
import { AddCircleOutline, CancelOutlined } from '@material-ui/icons';
import { Checkbox, Input, FormControlLabel } from '@material-ui/core';
import { DESIGNATION_SHOULD_EXIST } from '../_shared/constants';

const MemberDesignations = ({
  filterName,
  memberships,
  checked,
  selectedFilters,
  allowAny,
}) => {
  const dispatch = useDispatch();

  const [selectedDesignation, setDesignation] = useState('');
  const [memberDesignations, setMemberDesignations] = useState(
    selectedFilters ?? []
  );
  const [designationShouldExist, setAllowAnyDesignation] = useState(!!allowAny);

  useEffect(() => {
    if (designationShouldExist) {
      dispatch(addFilter({ designationShouldExist }));
      setAllowAnyDesignation(false);
    } else {
      dispatch(removeFilter({ designationShouldExist }));
    }
    if (memberDesignations.length) {
      dispatch(addFilter({ [filterName]: memberDesignations }));
      dispatch(removeFilter(DESIGNATION_SHOULD_EXIST));
    } else if (!memberDesignations?.length || !selectedFilters?.length) {
      dispatch(removeFilter(filterName));
    }
  }, [memberDesignations]);

  useEffect(() => {
    if (!checked) {
      dispatch(removeFilter(filterName, DESIGNATION_SHOULD_EXIST));
    }
  }, [checked]);

  const handleDesignationChange = ({ target }) => {
    setDesignation(target.value?.toUpperCase());
  };

  const addDesignation = () => {
    if (designationShouldExist) {
      setMemberDesignations([]);
    } 
    if (selectedDesignation?.trim()) {
      setMemberDesignations(designations => [
        ...designations,
        selectedDesignation,
      ]);
      setDesignation('');
    }
  };

  const removeDesignation = index => {
    if (index === -1) {
      dispatch(removeFilter(DESIGNATION_SHOULD_EXIST));
      setAllowAnyDesignation(false)
    } else {
      setMemberDesignations(des => [
        ...des.slice(0, index),
        ...des.slice(index + 1),
      ]);
    }
  };

  const handleCheck = event => {
    setAllowAnyDesignation(event.target.checked);
    setDesignation('');
  };

  return (
    <Container>
      {(selectedFilters || []).map((selectedMembership, index) => (
        <Wrap key={index}>
          {' - '}
          <Bold>{selectedMembership}</Bold>
          <CancelOutlined
            fontSize="small"
            disabled={designationShouldExist}
            style={{ fill: '#E63946' }}
            onClick={() => removeDesignation(index)}
          />
        </Wrap>
      ))}
      {allowAny && (
        <Wrap>
        {' - '}
        <Bold>Any/all Member Designations</Bold>
        <CancelOutlined
          fontSize="small"
          disabled={designationShouldExist}
          style={{ fill: '#E63946' }}
          onClick={() => removeDesignation(-1)}
        />
      </Wrap>
      )}
      <Content>
        <Text>{!!memberDesignations.length && 'or'} Designations</Text>
        <Input
          value={selectedDesignation}
          menuItemNames={memberships}
          onChange={handleDesignationChange}
          disabled={designationShouldExist}
        />
        <Text>{'or '}</Text>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={designationShouldExist}
              onChange={handleCheck}
              label="Any/all"
            />
          }
          label="Any/all"
        />
      </Content>
      <Button
        variant="contained"
        size="small"
        startIcon={<AddCircleOutline />}
        style={{
          borderRadius: 50,
          backgroundColor: '#007ADE',
          margin: '0 25px',
          padding: '5px 15px',
          color: 'white',
        }}
        onClick={addDesignation}
      >
        Add
      </Button>
    </Container>
  );
};

const mapStateToProps = state => ({
  memberships: [
    ...(state.organization.selected.associationIDs || []),
    ...(state.organization.selected.extraAssocIDs || []),
  ],
  orgTypeName: state.audience.orgTypeName,
});

export default connect(mapStateToProps)(MemberDesignations);
