import styled from 'styled-components';
import { Typography, Input, Badge, Button } from 'antd';

export const DefaultMessage = styled(Typography.Text)`
  color: #ffffff;
`;

DefaultMessage.Value = styled(Typography.Text).attrs({ underline: true })`
  color: inherit;
`;

export const FromFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  gap: 10px;
  align-items: center;
`;

export const RowWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  height: 32px;
  &:hover {
    cursor: pointer;
  }
`;

export const EventIdWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

export const FromInput = styled(Input)`
  text-align: right;
`;

export const Wrapper = styled.div`
  .ant-popover-inner {
    border-radius: 8px;
  }

  .ant-popover-inner-content {
    padding: 12px;
  }
`;

const LeftSideBadge = styled(Badge)`
  > .anticon {
    left: 0;
    right: auto;
    transform: translate(-50%, -50%);
  }
`;

export const ValidationStatusBadge = styled(LeftSideBadge)`
  color: #ff6161;
`;

export const HelpBadge = styled(LeftSideBadge)`
  color: #007ade;
`;

export const InputRow = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 10px;
  align-items: center;
`;

const createCTAControl = (BaseComponent, { color }) => {
  return styled(BaseComponent).attrs({ size: 'large' })`
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #${color};
    border: 1px solid #${color};
    border-radius: 5px;
    background-color: #${color}05;
    transition: background-color 0.3s;

    // overriding default antd styles for hovered state
    &,
    &:hover {
      border: 1px solid #${color};
      color: #${color};
    }

    &:hover {
      background-color: #${color}0f;
    }
  `;
};

const createCTAButton = options => {
  return styled(createCTAControl(Button, options))`
    > span + .anticon {
      font-size: 12px;
    }

    > .anticon + span,
    > span + .anticon {
      margin-left: 8px;
    }
  `;
};

export const MessageInfoButton = createCTAButton({ color: '007ade' });

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 650px;
  min-width: 500px;
`;

export const Overlay = styled.div`
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;
