import styled from 'styled-components';
import Bee from '../BeeFreeEditor/bee';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0 !important;
  overflow: hidden;
  height: 100%;
  position: relative;
`;

export const BeeEditor = styled(Bee)`
  width: 100%;
  height: 100%;
`;

BeeEditor.Wrapper = styled.div`
  position: relative;
  flex-grow: 1;
  width: 100%;
`;
