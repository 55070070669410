class BiMap {
  #forwardMap = new Map();
  #backwardMap = new Map();

  constructor(fromObject) {
    Object.entries(fromObject).forEach(([a, b]) => {
      this.set(a, b);
    });
  }

  set(a, b) {
    this.#forwardMap.delete(a);
    this.#forwardMap.delete(b);
    this.#backwardMap.delete(a);
    this.#backwardMap.delete(b);

    this.#forwardMap.set(a, b);
    this.#backwardMap.set(b, a);
  }

  get(a) {
    if (this.#forwardMap.has(a)) {
      return this.#forwardMap.get(a);
    }

    if (this.#backwardMap.has(a)) {
      return this.#backwardMap.get(a);
    }

    throw new Error(`No pair with "${a}" in it exists.`);
  }
}

export default BiMap;
