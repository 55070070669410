import React, { createContext, useContext, useEffect, useState } from 'react';
import { _mapObject } from 'underscore-es';
import { getDisplayName } from '../../react-utils';

const Context = createContext(null);

const Provider = ({ featureToggler, children }) => {
  return <Context.Provider value={featureToggler}>{children}</Context.Provider>;
};

export const useIsFeatureEnabled = feature => {
  const featureToggler = useContext(Context);

  const [isEnabled, setIsEnabled] = useState(featureToggler.isEnabled(feature));

  useEffect(() => {
    const unsubscribe = featureToggler.observe(feature, setIsEnabled);
    return unsubscribe;
  }, [feature, featureToggler]);

  return isEnabled;
};

export const withFeatures = (features, Component) => {
  const WrappedComponent = props => {
    const featureToggler = useContext(Context);
    const featureProps = _mapObject(features, feature => {
      return featureToggler.isEnabled(feature);
    });
    return <Component {...featureProps} {...props} />;
  };
  WrappedComponent.displayName = `withFeatures(${getDisplayName(Component)})`;
  return WrappedComponent;
};

export default Provider;
