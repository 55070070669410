import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Row, BoldTitle } from '../GeneralInfo.styled';
import { AUTHORIZED_SIGNER_CONTRACT } from './constants';

const useStyles = makeStyles(() => ({
  textField: {
    '& input:disabled': {
      color: '#212121',
    },
  },
}));

const Contacts = ({ title, contact }) => {
  const classes = useStyles();
  const [firstName, setFirstName] = useState(contact?.firstName || '');
  const [lastName, setLastName] = useState(contact?.lastName || '');
  const [contactTitle, setContactTitle] = useState(contact?.title || '');
  const [email, setEmail] = useState(contact?.email || '');
  const [phoneNumber, setPhoneNumber] = useState(contact?.phoneNumber || '');

  useEffect(() => {
    if (contact) {
      setFirstName(contact.firstName);
      setLastName(contact.lastName);
      setEmail(contact.email);
      setPhoneNumber(contact.phoneNumber);
      setContactTitle(contact.title);
    }
  }, [contact]);

  return (
    <>
      <BoldTitle>{title}</BoldTitle>
      <Row>
        <TextField
          className={classes.textField}
          label="First Name"
          variant="outlined"
          size="small"
          value={firstName}
          disabled
          style={{ width: '48%' }}
        />
        <TextField
          className={classes.textField}
          label="Last Name"
          variant="outlined"
          size="small"
          value={lastName}
          disabled
          style={{ width: '48%' }}
        />
      </Row>
      {title === AUTHORIZED_SIGNER_CONTRACT && (
        <TextField
          className={classes.textField}
          label="Title"
          variant="outlined"
          size="small"
          value={contactTitle}
          disabled
        />
      )}
      <Row>
        <TextField
          className={classes.textField}
          label="Email"
          variant="outlined"
          size="small"
          value={email}
          disabled
          style={{ width: '48%' }}
        />
        <TextField
          className={classes.textField}
          label="Phone"
          variant="outlined"
          size="small"
          value={phoneNumber}
          disabled
          style={{ width: '48%' }}
        />
      </Row>
    </>
  );
};

export default Contacts;
