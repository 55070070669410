import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useCampaignQuery } from '../queries';
import StatusPicker from '../StatusPicker';
import StagesTable from './StagesTable';
import {
  AudienceSection,
  Content,
  DetailsSection,
  Drawer,
  EditButton,
  ErrorMessage,
  FieldGroup,
  LoadingMessage,
  StagesSection,
  StatusSection,
  TextAreaDisplay,
  TextDisplay,
} from './CampaignView.styled';

const CampaignView = ({ id, onClose }) => {
  const { data: campaign, isLoading, isError } = useCampaignQuery(id);

  const history = useHistory();
  const routeMatch = useRouteMatch();

  const handleEdit = () => {
    history.push(`${routeMatch.url}/edit/${id}`);
  };

  return (
    <Drawer open onClose={onClose} anchor="right">
      <Drawer.Header>
        <Drawer.CloseButton onClick={onClose} />
        <Drawer.Title>Campaign Details</Drawer.Title>
        <EditButton onClick={handleEdit}>Edit Campaign</EditButton>
      </Drawer.Header>
      <Drawer.Body>
        {isError ? (
          <ErrorMessage />
        ) : (
          <Content>
            <StatusSection title="Status">
              <StatusPicker
                value={isLoading ? '' : campaign.status}
                isLoading={isLoading}
                isDisabled
              />
            </StatusSection>
            <DetailsSection title="Details">
              <FieldGroup>
                <TextDisplay
                  value={isLoading ? '' : campaign.name}
                  placeholder={isLoading ? 'Loading...' : 'Empty'}
                />
                <TextAreaDisplay
                  value={isLoading ? '' : campaign.description ?? ''}
                  placeholder={isLoading ? 'Loading...' : 'Empty'}
                />
              </FieldGroup>
            </DetailsSection>
            <AudienceSection title="Audience">
              <TextDisplay
                value={isLoading ? '' : campaign.audience.name}
                placeholder={isLoading ? 'Loading...' : 'Empty'}
              />
            </AudienceSection>
            <StagesSection title="Stages">
              {isLoading ? (
                <LoadingMessage />
              ) : (
                <StagesTable stages={campaign.stages} />
              )}
            </StagesSection>
          </Content>
        )}
      </Drawer.Body>
    </Drawer>
  );
};

export default CampaignView;
