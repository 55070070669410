import { audienceInitState } from './reducers/audience';

const state = {
  user: {
    detail: {}, // Basic details about the logged in user
    userPrivileges: {}, // Key value pairs mapping privileges to booleans for access
  },
  organization: {
    selected: {
      // Current selected organization of user
      timezone: {
        zone: null,
      },
    },
    list: [], // List of all organizations for admin accounts
    users: [], // Users within an organization,
    orgTypes: {},
  },
  UI: {
    fullloader: false, // Display full loading screen
    loaderMsg: null, // Display some text on full loading screen
    progressMeter: 0, //Navbar progress meter progress
    progressResults: {}, //Results returned when progress is finished
    progressType: '', //What is in progress
  },
  auth: {
    isAuthenticated: false, // Basic check if user is authenticated
  },
  privileges: {
    list: [], // Basic privileges list for a user
    roles: {}, // Key value pairs Key=ID, Value=Name
  },
  topics: [], // All topics within selected organization
  groupTypes: [], // All types of groups
  groups: [], // All groups within selected organization
  audience: audienceInitState,
  textMessages: {
    unreadCount: 0,
    lastMsgConversationId: null,
    lastMsgIdSentByOrg: null,
    lastMsgIdSentByUser: null,
  },
};

export default state;
