const generateRecipientTableCSV = ({ dataType, data }) => {
  const headers = [
    'AMS ID',
    'NRDS ID',
    'First Name',
    'Last Name',
    dataType === 'message' ? 'Email Delivery' : 'Email',
    dataType === 'message' ? 'Text Delivery' : 'Phone',
  ];
  return [
    headers,
    ...data.map(_ => [
      _.memberKeyNumeric,
      _.NRDS,
      _.firstName,
      _.lastName,
      dataType === 'message' ? _.contactMethods[0] : _.email,
      dataType === 'message' ? _.contactMethods[1] : _.phone,
    ]),
  ];
};

export default generateRecipientTableCSV;
