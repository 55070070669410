import api from '../../../utils/andromeda-api';

export const getOrganizationGroups = organizationId => {
  try {
    return api.get(`/groups/organizationlist/${organizationId}`);
  } catch (e) {
    console.error('Error getting groups [groups]', e);
    return { error: true };
  }
};
