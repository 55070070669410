import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Modal from '../Modal';
import { fullloader, toast } from '../../redux/actions/UI';
import { addMultipleGroupsToContacts } from '../../redux/services/contact';
import { selectedOrganizationSelector } from '../../redux/selectors/organization';
import styles from './AssignGroupsModal.module.css';

const useVisibility = () => {
  const [isVisible, setIsVisible] = useState(false);

  const close = useCallback(() => {
    setIsVisible(false);
  }, []);

  const set = useCallback(newIsVisible => {
    setIsVisible(newIsVisible);
  }, []);

  return { isVisible, close, set };
};

const useSearch = () => {
  const [value, setValue] = useState('');

  const clear = useCallback(() => {
    setValue('');
  }, []);

  return { value, setValue, clear };
};

const useSelection = ({ selected, groups, onSelectionChange }) => {
  const dispatch = useDispatch();

  const selectedGroups = useMemo(() => {
    return selected.map(groupId => groups.find(group => group.id === groupId));
  }, [groups, selected]);

  const notSelected = useMemo(() => {
    return groups.filter(group => !selected.includes(group.id));
  }, [groups, selected]);

  const add = useCallback(
    groupId => {
      dispatch(toast('success', 'Group selected'));
      onSelectionChange([...selected, groupId]);
    },
    [onSelectionChange, selected]
  );

  const remove = useCallback(
    groupId => {
      onSelectionChange(selected.filter(id => id !== groupId));
    },
    [onSelectionChange, selected]
  );

  return { selected: selectedGroups, notSelected, add, remove };
};

const AssignGroupsModal = ({
  memberIds,
  groups,
  selected,
  onSelectionChange,
  pickerDropdownFooter = null,
  onSuccess,
  onFailure,
  onClose,
}) => {
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(selectedOrganizationSelector);

  const dropdownVisibility = useVisibility();

  const [isLoading, setIsLoading] = useState(false);
  const handleConfirm = useCallback(async () => {
    setIsLoading(true);
    dispatch(fullloader(true, 'Assigning members to groups'));

    const { error } = await addMultipleGroupsToContacts({
      organizationId: selectedOrganization.id,
      contactIds: memberIds,
      groupIds: selected,
    });

    setIsLoading(false);
    dispatch(fullloader(false));

    if (error) {
      dispatch(toast('error', 'Failed to assign members to groups'));
      onFailure();
    } else {
      dispatch(toast('success', 'Successfully assigned members to groups'));
      onSuccess();
    }
  }, [
    dispatch,
    memberIds,
    onFailure,
    onSuccess,
    selected,
    selectedOrganization.id,
  ]);

  const selection = useSelection({ selected, groups, onSelectionChange });
  const search = useSearch();

  const handleDropdownVisibilityChange = isVisible => {
    dropdownVisibility.set(isVisible);
    if (!isVisible) {
      search.clear();
    }
  };

  const renderDropdown = menu => (
    <>
      {menu}
      <div className={styles.pickerDropdownFooter}>
        {pickerDropdownFooter ?? <div />}
        <button
          type="button"
          className={styles.closePickerDropdownButton}
          onClick={dropdownVisibility.close}
        >
          Done
        </button>
      </div>
    </>
  );

  return (
    <Modal
      title="Add Contacts to Group"
      onClose={onClose}
      primaryButton={{
        label: 'Add to Group(s)',
        onClick: handleConfirm,
        isDisabled: selected.length === 0,
        isLoading,
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: onClose,
      }}
    >
      <div>
        <p>
          {"Select the Group(s) you'd like to add the selected Contacts to:"}
        </p>
        <Select
          className={styles.select}
          mode="multiple"
          value={[]}
          onSelect={selection.add}
          placeholder="- Select Group -"
          showSearch
          showArrow
          optionFilterProp="children"
          open={dropdownVisibility.isVisible}
          onDropdownVisibleChange={handleDropdownVisibilityChange}
          searchValue={search.value}
          onSearch={search.setValue}
          notFoundContent={
            <span className={styles.noAvailableOptionsMessage}>
              No available options
            </span>
          }
          dropdownRender={renderDropdown}
        >
          {selection.notSelected.map(group => (
            <Select.Option key={group.id} value={group.id}>
              {group.name}
            </Select.Option>
          ))}
        </Select>
        <List
          className={`${styles.selectionList} ${
            selection.selected.length === 0 ? styles.isEmpty : ''
          }`}
          dense
        >
          {selection.selected.length > 0 ? (
            selection.selected.map(({ id, name }) => (
              <ListItem key={id} classes={{ container: styles.selectedOption }}>
                <ListItemText primary={name} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => selection.remove(id)}>
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          ) : (
            <span>Selected groups will appear here</span>
          )}
        </List>
      </div>
    </Modal>
  );
};

export default forwardRef(AssignGroupsModal);
