import axios from 'axios';
import andromedaAPI from '../../utils/andromeda-api';

const heliosEndpoint = process.env.REACT_APP_EC2API;

export const makeAMSSyncRequest = async apiPath => {
  try {
    const { data } = await axios.get(`${heliosEndpoint}/${apiPath}`);
    return { success: true, message: data };
  } catch ({ response }) {
    console.error(
      `Error making AMS Sync request for ${apiPath}`,
      response.data
    );
    return { success: false, message: response.data };
  }
};

export const makeLegacyAMSSyncRequest = async (apiPath, body) => {
  try {
    const { data } = await axios.post(`${heliosEndpoint}/${apiPath}`, body);
    return data;
  } catch (e) {
    return console.error(`Error making AMS Sync request for ${apiPath}`, e);
  }
};

export const makeRapGZAMSSyncRequest = async (apiPath, body) => {
  try {
    const { data } = await axios.post(`${heliosEndpoint}/${apiPath}`, body);
    return { success: true, message: data };
  } catch ({ response }) {
    console.error(
      `Error making AMS Sync request for ${apiPath}`,
      response.data
    );
    return { success: false, message: response.data };
  }
};

export const addBulkSyncItems = async operation => {
  try {
    const { data } = await andromedaAPI.post('sync/queue/bulk', { operation });
    return { success: true, message: data.message };
  } catch ({ response }) {
    console.error(
      `Error adding bulk sync items for ${operation}`,
      response.data
    );
    return { success: false, message: response.data.message };
  }
};

export const addSyncItem = async ({
  organizationId,
  syncType,
  source,
  type,
}) => {
  try {
    const operation = syncType.charAt(0).toLowerCase() + syncType.slice(1);
    const { data } = await andromedaAPI.post('sync/queue', {
      operation,
      organizationId,
      AMS: source,
      type,
    });
    return { success: true, message: data.message };
  } catch ({ response }) {
    console.error(
      `Error adding bulk sync items for ${syncType}`,
      response.data.message
    );
    return { success: false, message: response.data.message };
  }
};

export const fetchRAMCOLists = async (orgId, filter) => {
  try {
    const { data } = await andromedaAPI.get('ams/', {
      params: {
        orgId,
        filter,
      },
    });
    return { success: true, lists: data.result };
  } catch ({ response }) {
    console.error('Error adding bulk sync items for ', response.data);
    return { success: false, lists: [] };
  }
};
