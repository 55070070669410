import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { Row, BoldTitle } from '../GeneralInfo.styled';
import {
  isEmpty,
  isEmailValid,
  isValidPhoneNumber,
  getEmailErrorHelperText,
  getPhoneNumberErrorHelperText,
  allowOnlyNumericValues,
} from '../../utils';
import { AUTHORIZED_SIGNER_CONTRACT } from '../OrganizationContacts/constants';

const Contacts = ({
  title,
  contact,
  action,
  isSaveBtnClicked,
  setContactsInfo,
}) => {
  const [firstName, setFirstName] = useState(contact?.firstName || '');
  const [lastName, setLastName] = useState(contact?.lastName || '');
  const [contactTitle, setContactTitle] = useState(contact?.title || '');
  const [email, setEmail] = useState(contact?.email || '');
  const [phoneNumber, setPhoneNumber] = useState(contact?.phoneNumber || '');

  useEffect(() => {
    if (contact) {
      setFirstName(contact.firstName);
      setLastName(contact.lastName);
      setContactTitle(contact.title);
      setEmail(contact.email);
      setPhoneNumber(contact.phoneNumber);
    }
  }, [contact]);

  const shouldErrorOut = (value, resultOfSecondCondition) => {
    if (action === 'Edit') {
      return isEmpty(value) || resultOfSecondCondition;
    }
    return isSaveBtnClicked && (isEmpty(value) || resultOfSecondCondition);
  };

  const getHelperText = value => {
    if (action === 'Edit') {
      return isEmpty(value) ? 'Required field' : '';
    }
    return isSaveBtnClicked && isEmpty(value) ? 'Required field' : '';
  };

  const updateFirstName = ({ target: { value } }) => {
    setFirstName(value);
    setContactsInfo(prev => ({
      ...prev,
      [title]: { ...prev[title], firstName: value },
    }));
  };

  const updateLastName = ({ target: { value } }) => {
    setLastName(value);
    setContactsInfo(prev => ({
      ...prev,
      [title]: { ...prev[title], lastName: value },
    }));
  };

  const updateContactTitle = ({ target: { value } }) => {
    setContactTitle(value);
    setContactsInfo(prev => ({
      ...prev,
      [title]: { ...prev[title], title: value },
    }));
  };

  const updateEmail = ({ target: { value } }) => {
    setEmail(value);
    setContactsInfo(prev => ({
      ...prev,
      [title]: { ...prev[title], email: value },
    }));
  };

  const updatePhoneNumber = value => {
    setPhoneNumber(value);
    setContactsInfo(prev => ({
      ...prev,
      [title]: { ...prev[title], phoneNumber: value },
    }));
  };

  return (
    <>
      <BoldTitle>{title}</BoldTitle>
      <Row>
        <TextField
          label="First Name*"
          variant="outlined"
          size="small"
          value={firstName}
          error={shouldErrorOut(firstName)}
          helperText={getHelperText(firstName)}
          onChange={updateFirstName}
          style={{ width: '48%' }}
        />
        <TextField
          label="Last Name*"
          variant="outlined"
          size="small"
          value={lastName}
          error={shouldErrorOut(lastName)}
          helperText={getHelperText(lastName)}
          onChange={updateLastName}
          style={{ width: '48%' }}
        />
      </Row>
      {title === AUTHORIZED_SIGNER_CONTRACT && (
        <TextField
          label="Title*"
          variant="outlined"
          size="small"
          value={contactTitle}
          error={shouldErrorOut(contactTitle)}
          helperText={getHelperText(contactTitle)}
          onChange={updateContactTitle}
        />
      )}
      <Row>
        <TextField
          label="Email*"
          variant="outlined"
          size="small"
          value={email}
          error={shouldErrorOut(email, !isEmailValid(email))}
          helperText={
            (action === 'Edit' || isSaveBtnClicked) &&
            getEmailErrorHelperText(email)
          }
          onChange={updateEmail}
          style={{ width: '48%' }}
        />
        <TextField
          label="Phone"
          variant="outlined"
          size="small"
          value={phoneNumber}
          error={!isValidPhoneNumber(phoneNumber, true)}
          helperText={getPhoneNumberErrorHelperText(phoneNumber, true)}
          onChange={e =>
            updatePhoneNumber(allowOnlyNumericValues(e.target.value, 10))
          }
          style={{ width: '48%' }}
        />
      </Row>
    </>
  );
};

export default Contacts;
