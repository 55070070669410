import styled from 'styled-components';

export const Header = styled.div`
  background-color: #005092;
  color: white;
  padding: 10px;
  font-size: 18px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  width: ${props => props.width || '40vw'};
  min-width: 500px;
  height: 100%;
`;

export const Content = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow: auto;
`;

export const Title = styled.div`
  font-size: 16px;
`;

export const Box = styled.div`
  padding: 4px 12px;
  border: 1px solid #d5d8da;
  border-radius: 4px;
  width: ${props => props.width || '100%'};
  flex-grow: 1;
  font-size: 14px;
`;

export const Wrap = styled.div`
  display: flex;
  gap: 10px;
  padding: 4px 0;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 1800px) {
    gap: 14px;
  }
`;

export const Small = styled.span`
  font-size: 12px;
  color: #9e9e9e;
`;

export const Column = styled.span`
  dispay: flex;
  flex-direction: column;
`;

export const CheckboxContainer = styled.span`
  min-width: 170px;
  cursor: pointer;
`;

export const SyncDetails = styled.div`
  font-size: 75%;
  margin: '0';
`;

export const RightFloatedDiv = styled.div`
  float: right;
`;
