import './Analytics.css';
import '../../globalcss.css';

import React, { useEffect, useMemo, useState, useRef } from 'react';
import { authUserHelper } from '../../redux/helpers';
import {
  fetchOrgGroups,
  getOrganizationTopics,
} from '../../redux/services/groups';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../redux/action';
import * as moment from 'moment/moment';
import * as services from '../../redux/services';
import { createIdNameMappingFromData } from '../../utils';
import MessageTable from './Components/MessageTable';
import { addStatusesToMessage, sortMessages } from './utils';
import MessageDetails from './Components/MessageDetails';
import {
  Container,
  Column,
  Header,
  Menu,
  MenuItem,
  Wrapper,
} from './Analytics.styled';
import MessageAnalytics from './Components/MessageAnalytics';
import MessageAnalyticsABversion from './Components/MessageAnalyticsABversion';
import ExportBtn from './Components/ExportBtn';
import { ALL_TIME_VAL, SEVEN_DAYS_VAL } from './constants';

const Analytics = props => {
  const { organization } = props;
  const [groups, setGroups] = useState({});
  const [topics, setTopics] = useState({});
  const [isAllTime, setAllTime] = useState(false);
  const [orgMessages, setOrgMessages] = useState([]);
  const [isTableLoading, setTableLoading] = useState(true);
  const [isMessageDetailsLoading, setMessageDetailsLoading] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(undefined);
  const [hideScheduledMessages, setHideScheduledMessages] = useState(false);
  const [startDate, setStartDate] = useState(undefined);
  const [isMessageAnalyticsLoading, setMessageAnalyticsLoading] =
    useState(true);
  const [textEvents, setTextEvents] = useState({});
  const [emailEvents, setEmailEvents] = useState({});
  const [smartResendEmailEvents, setSmartResendEmailEvents] = useState({});
  const [spamReport, setSpamReport] = useState(0);
  const [errorLogs, setErrorLogs] = useState([]);
  const [isClickActivityVisible, setClickActivityVisibility] = useState(false);
  const [isSmartResendAvailable, setSmartResendAvailability] = useState(null);
  // have to use useRef to keep up with the up to date data
  const selectedMessageId = useRef(null);

  const orgId = useMemo(() => {
    return organization.selected.id;
  }, [organization]);
  const dispatch = useDispatch();
  const versionBmessageId =
    selectedMessage && selectedMessage.AB && selectedMessage.AB.linkedMessageId;

  const MessageAnalyticsComponentToRender = versionBmessageId
    ? MessageAnalyticsABversion
    : MessageAnalytics;

  const tableMessagesData = useMemo(() => {
    return sortMessages(orgMessages, hideScheduledMessages);
  }, [orgMessages, hideScheduledMessages]);

  // get & set the groups/topics
  const setGroupsAndTopicsForOrg = async orgId => {
    const orgGroups = await fetchOrgGroups(orgId);
    const orgTopics = await getOrganizationTopics(orgId);
    const groups = createIdNameMappingFromData(orgGroups);
    const topics = createIdNameMappingFromData(orgTopics);
    setGroups(groups);
    setTopics(topics);
  };

  // dependency array is empty in useEffect to ensure we authenticate just once after load
  useEffect(() => {
    const authenticateUser = async () => {
      await authUserHelper(props);
    };
    authenticateUser().catch(error => console.log(error));
  }, []);

  // update tags/topics/messages whenever orgId changes
  useEffect(() => {
    setGroupsAndTopicsForOrg(orgId);
    loadMessages(SEVEN_DAYS_VAL);
  }, [orgId]);

  const handleHideScheduledMessages = () => {
    setHideScheduledMessages(!hideScheduledMessages);
  };

  const handleSelectMessage = async ([id]) => {
    setMessageDetailsLoading(true);
    setMessageAnalyticsLoading(true);
    // set the selected message, including status
    const messageData = await services.getMsgBy_Id(id, true);
    selectedMessageId.current = messageData._id;
    setStartDate(moment.unix(messageData.sendTime).format('MM/DD/YYYY'));
    setSelectedMessage(addStatusesToMessage(messageData));
    dispatch(actions.addFilter(messageData.filterData));
    setMessageDetailsLoading(false);
  };

  const handleBackToTimeline = async timeBack => {
    setSelectedMessage(undefined);
    selectedMessageId.current = null;
    const date =
      timeBack === ALL_TIME_VAL
        ? await getEarliestOrgDate()
        : moment().subtract(timeBack, 'days').format('MM/DD/YYYY');
    setStartDate(date);
  };

  const getEarliestOrgDate = async () => {
    const earliestOrgDate = await services.getEarliestSummary(orgId);
    const timeDiff = new Date().getTime() - new Date(earliestOrgDate).getTime();
    const timeBack = Math.round(timeDiff / (1000 * 60 * 60 * 24));
    return moment().subtract(timeBack, 'days').format('MM/DD/YYYY');
  };

  const loadMessages = async days => {
    if (!orgId) return;
    setTableLoading(true);
    setSelectedMessage(undefined);
    selectedMessageId.current = null;

    const isAllTimeRange = !days || days < 0;
    setAllTime(isAllTimeRange);

    const eventDate = isAllTimeRange
      ? // get start date of earliest event during the lifetime of the org
        await getEarliestOrgDate()
      : // get date N days back
        moment().subtract(days, 'days').format('MM/DD/YYYY');
    setStartDate(eventDate);
    const eventDateAsUnixTimestamp = moment(eventDate).unix();
    const messages = await services.getAnalyticsMessages(
      orgId,
      eventDateAsUnixTimestamp
    );

    // if we don't have messages, there's nothing to do so return early
    if (!messages) {
      setTableLoading(false);
      return;
    }

    // add a status field to each of the messages before setting it to state
    const messagesWithStatusField = messages.map(addStatusesToMessage);
    setOrgMessages(messagesWithStatusField);
    setTableLoading(false);
  };

  const getDateRangeTimeSummaries = async () => {
    if (!startDate) return;
    setMessageAnalyticsLoading(true);
    const formattedDate = moment(startDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
    const { emailEvents, textEvents } = await services.getRangeCalculations(
      formattedDate,
      orgId
    );

    setEmailEvents(emailEvents);
    setTextEvents(textEvents);

    const summaries = await services.getRangeOfSummaries(formattedDate, orgId);
    if (summaries?.errors && summaries?.sumInfo) {
      setSpamReport(summaries.spamReports.length || 0);
      setErrorLogs(summaries.errors);
    }
    setMessageAnalyticsLoading(false);
  };

  const getMessageInfo = async message => {
    const { emailEvents, textEvents, smartResendEmailEvents } =
      await services.getMessageEventInfo({
        msgId: message.id,
        BmessageId: versionBmessageId,
        isSmartResend: message.smartResend?.isOriginalMsg,
      });

    // emailEventsA, emailEventsB
    setSmartResendEmailEvents(smartResendEmailEvents);

    setEmailEvents(emailEvents);
    setTextEvents(textEvents);
    setMessageAnalyticsLoading(false);
  };

  useEffect(() => {
    // if there is no message selected, get the summary for ALL time
    if (!selectedMessage && !selectedMessageId.current) {
      getDateRangeTimeSummaries();
      // make sure that only current selected message is being triggered
    } else if (selectedMessageId.current === selectedMessage?._id) {
      // get message info when there is a selected message
      getMessageInfo(selectedMessage);
    }
  }, [selectedMessage, orgId, startDate]);

  return (
    <Container>
      <Header>
        <Menu>
          <MenuItem className="active">Messages</MenuItem>
        </Menu>
        <ExportBtn
          scheduledMessagesHidden={hideScheduledMessages}
          isAllTime={isAllTime}
          selectedOrg={organization.selected}
          startDate={startDate}
          selectedMessage={selectedMessage}
          emailEvents={emailEvents}
          textEvents={textEvents}
          groups={groups}
          topics={topics}
        />
      </Header>
      <Wrapper>
        <MessageTable
          isLoadingData={isTableLoading}
          scheduledMessagesHidden={hideScheduledMessages}
          setHideScheduledMessages={handleHideScheduledMessages}
          generateTableMessageData={tableMessagesData}
          selectMessage={handleSelectMessage}
          selectedMessage={selectedMessage}
          handleBackToTimeline={handleBackToTimeline}
          loadMessages={loadMessages}
          orgId={orgId}
        />
        <Column>
          <MessageDetails
            isSuperUser={props.user.userPrivileges.isSU}
            message={selectedMessage}
            topics={topics}
            groups={groups}
            isLoading={isMessageDetailsLoading}
            isClickActivityVisible={isClickActivityVisible}
            setClickActivityVisibility={setClickActivityVisibility}
            setSelectedMessage={setSelectedMessage}
            addStatusesToMessage={addStatusesToMessage}
          />
          <MessageAnalyticsComponentToRender
            message={selectedMessage}
            startDate={startDate}
            isLoadingData={isMessageAnalyticsLoading}
            textEvents={textEvents}
            emailEvents={emailEvents}
            smartResendEmailEvents={smartResendEmailEvents}
            spamReport={spamReport}
            errorLogs={errorLogs}
            isDataAvailable={orgMessages.length}
            setClickActivityVisibility={
              selectedMessage ? setClickActivityVisibility : null
            }
            isSmartResendAvailable={isSmartResendAvailable}
            setSmartResendAvailability={setSmartResendAvailability}
          />
        </Column>
      </Wrapper>
    </Container>
  );
};

const mapStateToProps = state => {
  return state;
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
