import api from '../../utils/andromeda-api';

export async function getFilterTypes(orgId) {
  try {
    const response = await api.get(`/organization/filterTypes/${orgId}`);
    return response.data.result;
  } catch (e) {
    console.error('Error retrieving group type ids [getFilterTypes]', e);
    return { error: true };
  }
}

export async function getAudienceFilters(orgId) {
  try {
    const response = await api.get(`/organization/audienceFilters/${orgId}`);
    return response.data.result;
  } catch (e) {
    console.error('Error retrieving audience filters [getAudienceFilters]', e);
    return { error: true };
  }
}

export async function getOrgCommittees(orgId) {
  return api.get(`/committees/${orgId}`);
}
