import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const Tab = styled.button`
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  padding: 8px 22px;
  line-height: 14px;

  border-radius: 2px;
  text-align: center;
  color: ${props => (props.isActive ? 'white' : '#007ade')};
  border: 1px solid #509ee5;
  transition: all 0.3s ease-in;
  background-color: ${props => (props.isActive ? '#007ade' : '#f7f7f7')};
  &:hover,
  &.active {
    color: white;
    background-color: #007ade;
    border: 1px solid #007ade;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.disabled ? '#C2C2C2' : '#83878D')};
  border: none;
  font-size: 14px;
  padding: 3px 12px;
  gap: 5px;
  color: white;
  transition: all 0.3s ease-in;
  border-radius: 4px;
  &:hover,
  &.active {
    cursor: ${props => !props.disabled && 'pointer'};
    background-color: ${props => !props.disabled && '#525252'};
  }
`;

export const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5eab00;
  border: none;
  font-size: 14px;
  padding: 3px 12px;
  gap: 5px;
  color: white;
  transition: all 0.3s ease-in;
  border-radius: 4px;
  &:hover,
  &.active {
    cursor: pointer;
    background-color: #238b22;
  }
`;
