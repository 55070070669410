// Menu
export const TOPICS = 'Topics';
export const GROUPS = 'Groups';
export const FILTERS = 'Filters';
export const CONTACTS = 'Contacts';

// Filter submenu items
export const MEMBERSHIP = 'Membership';
export const BILLING = 'Billing';
export const DATES = 'Dates';
export const GEOGRAPHIC = 'Geographic';
export const FOR_MEMBERS = 'For Members';
export const FOR_NONMEMBERS = 'For Nonmembers';

export const SUB_MENU_CONFIG = {
  Topics: [FOR_MEMBERS, FOR_NONMEMBERS],
  Groups: [],
  Filters: [],
  Contacts: [CONTACTS],
};

// Action buttons
export const ADD = 'Add';
export const EXCLUDE = 'Exclude';
export const REQUIRE = 'Require';
export const REMOVE = 'Remove';

// Topics submenu to field map
export const TOPICS_SUBMENU_TO_FIELD_MAP = {
  [FOR_MEMBERS]: 'member',
  [FOR_NONMEMBERS]: 'nonMember',
};
