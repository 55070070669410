import { combineReducers } from 'redux';

import initState from './initState';
import { audiences } from './reducers/audiences';
import { auth } from './reducers/auth';
import { user } from './reducers/user';
import { groups } from './reducers/groups';
import { groupTypes } from './reducers/groupTypes';
import { topics } from './reducers/topics';
import { UI } from './reducers/UI';
import { organization } from './reducers/organisation';
import { privileges } from './reducers/privilege';
import { contentlist } from './reducers/contentlist';
import { audience } from './reducers/audience';
import { committees } from './reducers/committees';
import { textMessages } from './reducers/textMessages';

const appReducer = combineReducers({
  user,
  privileges,
  groups,
  groupTypes,
  topics,
  contentlist,
  UI,
  organization,
  auth,
  audiences,
  audience,
  committees,
  textMessages,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APPLICATION') return appReducer(initState, action);
  return appReducer(state, action);
};

export default rootReducer;
