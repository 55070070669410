import styled from 'styled-components';

const ContentLine = styled.p`
  font-size: 18px;
  margin: 0;
  color: #6c7075;
  font-family: Montserrat, sans-serif;
`;

export default ContentLine;
