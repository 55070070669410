import styled from 'styled-components';
import { AddCircle, DeleteOutline } from '@material-ui/icons';

import { ValidityIndicator } from '../../forms';
import BaseStep from '../Step';
import BaseStage from './Stage';

export const Step = styled(BaseStep)`
  width: 820px;
`;

export const AddButton = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 15px;
  color: ${({ isHighlighted }) => (isHighlighted ? '#007ade' : '#616161')};
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  background-color: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color ease-in 250ms;

  &:hover {
    background-color: #0000000d;
  }
`;

AddButton.Icon = styled(AddCircle)`
  font-size: 20px !important;
`;

AddButton.Label = styled.span`
  font-family: inherit;
`;

AddButton.Label.HelperText = styled.span`
  color: #616161;
  font-weight: 400;
  font-family: inherit;
`;

AddButton.ValidityIndicator = styled(ValidityIndicator)`
  margin-left: auto;
`;

export const StageList = styled.ul`
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

StageList.Item = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  gap: 18px;
`;

export const Stage = styled(BaseStage)`
  width: 100%;
`;

export const DeleteButton = styled(DeleteOutline)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 16px), -50%);
  color: #888888;
  font-size: 24px !important;
  cursor: pointer;
  transition: color ease-in 250ms !important;

  &:hover {
    color: #ff6161;
    transition: color ease-out 250ms !important;
  }
`;
