import axios from 'axios';
import { _defaults } from 'underscore-es';
import queryString from 'query-string';

import { BiMap } from '../../../libs/data-structures';
import store from '../../../redux/store';
import { userDetailSelector } from '../../../redux/selectors/user';
import { selectedOrganizationSelector } from '../../../redux/selectors/organization';
import UnexpectedError from './UnexpectedError';
import InvalidDataError from './InvalidDataError';
import NotFoundError from './NotFoundError';

const getUserId = () => {
  return userDetailSelector(store.getState()).id;
};

const getOrganizationId = () => {
  return selectedOrganizationSelector(store.getState()).id;
};

const http = axios.create({
  baseURL: process.env.REACT_APP_AUTOMATED_CAMPAIGNS_BASE_URL,
  paramsSerializer: params =>
    queryString.stringify(params, { arrayFormat: 'comma' }),
});

http.interceptors.request.use(request => {
  request.headers.userid = getUserId();
  request.headers.organizationid = getOrganizationId();
  return request;
});

const ApiUnit = {
  DAY: 'd',
  WEEK: 'w',
  MONTH: 'm',
  YEAR: 'y',
};

export const Unit = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

export const Order = {
  BEFORE: 'before',
  AFTER: 'after',
};

const MULTIPLIER_BY_ORDER = {
  [Order.BEFORE]: -1,
  [Order.AFTER]: 1,
};

const API_UNIT_TO_UNIT = new BiMap({
  [ApiUnit.DAY]: Unit.DAY,
  [ApiUnit.WEEK]: Unit.WEEK,
  [ApiUnit.MONTH]: Unit.MONTH,
  [ApiUnit.YEAR]: Unit.YEAR,
});

const transformDelayFromApi = rawDelay => {
  if (typeof rawDelay === 'number') {
    rawDelay = `${rawDelay}|d`;
  }

  const [rawValue, rawUnit = ApiUnit.DAY] = rawDelay.split('|');
  const orderedValue = Number(rawValue);
  const value = Math.abs(orderedValue);
  const order = orderedValue < 0 ? Order.BEFORE : Order.AFTER;
  const unit = API_UNIT_TO_UNIT.get(rawUnit);
  return { value, order, unit };
};

const transformDelayToApi = delay => {
  const orderedValue = delay.value * MULTIPLIER_BY_ORDER[delay.order];
  return `${orderedValue}|${API_UNIT_TO_UNIT.get(delay.unit)}`;
};

const transformCampaignFromApi = campaign => {
  return {
    ...campaign,
    stages: campaign.stages.map(stage => ({
      ...stage,
      analytics: _defaults(stage.analytics ?? {}, {
        sent: 0,
        opened: 0,
        clicked: 0,
      }),
      conditions: stage.conditions.map(condition => {
        return {
          ...condition,
          delay: transformDelayFromApi(condition.delay),
        };
      }),
    })),
    createdAt: campaign.createdAt ? new Date(campaign.createdAt) : null,
    updatedAt: campaign.updatedAt ? new Date(campaign.updatedAt) : null,
  };
};

const transformCampaignToApi = campaign => {
  const transformedCampaign = { ...campaign };

  if (transformedCampaign.stages?.length > 0) {
    transformedCampaign.stages = transformedCampaign.stages.map(stage => {
      const transformedStage = { ...stage };

      if (transformedStage.conditions?.length > 0) {
        transformedStage.conditions = transformedStage.conditions.map(
          condition => {
            const transformedCondition = { ...condition };

            if (transformedCondition.delay) {
              transformedCondition.delay = transformDelayToApi(
                transformedCondition.delay
              );
            }

            return transformedCondition;
          }
        );
      }

      return transformedStage;
    });
  }

  return transformedCampaign;
};

const ESCAPE_CHARACTER = '\\';
const escapeCharacter = (text, character) => {
  const characterRegexp = new RegExp(`\\${character}`, 'g');
  const escapedCharacter = ESCAPE_CHARACTER + character;
  return text.replace(characterRegexp, escapedCharacter);
};

const escapeCharacters = (text, characters) => {
  const withEscapeCharacterEscaped = escapeCharacter(text, ESCAPE_CHARACTER);
  return characters.reduce(escapeCharacter, withEscapeCharacterEscaped);
};

const FILTER_SPECIAL_CHARACTERS = ['|', '(', ')', ','];
const escapeFilterSegment = segment => {
  return escapeCharacters(segment, FILTER_SPECIAL_CHARACTERS);
};

const generateFilter = ({ field, value, operator }) => {
  const escapedField = escapeFilterSegment(field);
  const escapedValue = escapeFilterSegment(value);
  const escapedOperator = escapeFilterSegment(operator);
  return `(${escapedField}|${escapedValue}|${escapedOperator})`;
};

const generateFilters = filters => {
  const queries = filters.map(generateFilter);
  return queries.join(',');
};

export const fetchCampaigns = async ({
  page,
  pageSize,
  sortBy,
  sortDirection,
  search,
  statuses,
}) => {
  const params = {
    page,
    size: pageSize,
    sortBy: sortBy,
    sortDirection: sortDirection,
    filter: search
      ? generateFilters([
          { field: 'name', value: search, operator: 'contains' },
          { field: 'description', value: search, operator: 'contains' },
        ])
      : null,
    filterLogic: 'or',
    statuses,
  };
  const { data } = await http.get('/campaigns', { params });
  const campaigns = data.data.map(transformCampaignFromApi);

  // Unfortunately, FE needs somewhat total amount of campaigns, not just pages.
  // Note that this is just an estimated total, but that's fine for FE needs.
  //
  // Details:
  // MUI's <DataGrid> component has only `rowsCount` prop, and doesn't have anything like `pagesCount`.
  // Though we don't even render total amount of campaigns anywhere on UI. So, as it is practically
  // used by MUI only to calculate total amount of pages, we're fine with this "estimated" total
  // implementation.
  const getEstimatedTotal = () => {
    const totalPages = data.meta.pagination.total;
    const isLastPage = page === totalPages;
    const estimatedLastPageSize = isLastPage ? campaigns.length : pageSize;
    const totalWithoutLastPage = (totalPages - 1) * pageSize;
    return totalWithoutLastPage + estimatedLastPageSize;
  };

  return {
    total: getEstimatedTotal(),
    campaigns,
  };
};

export const fetchCampaign = async id => {
  try {
    const { data } = await http.get(`/campaigns/${id}`);
    return transformCampaignFromApi(data.data);
  } catch (error) {
    const response = error.response;
    if (!response) {
      throw new UnexpectedError();
    }

    if (response.status === 404) {
      throw new NotFoundError('campaign', id);
    }

    throw new UnexpectedError();
  }
};

export const updateCampaign = async (id, updates) => {
  try {
    await http.patch(`/campaigns/${id}`, transformCampaignToApi(updates));
  } catch (error) {
    const response = error.response;
    if (!response) {
      throw new UnexpectedError();
    }

    if (response.status === 400) {
      throw new InvalidDataError(response.data.message);
    }

    throw new UnexpectedError();
  }
};

export const createCampaign = async newCampaignData => {
  try {
    await http.post('/campaigns', transformCampaignToApi(newCampaignData));
  } catch (error) {
    const response = error.response;
    if (!response) {
      throw new UnexpectedError();
    }

    if (response.status === 400) {
      throw new InvalidDataError(response.data.message);
    }

    throw new UnexpectedError();
  }
};

export const archiveCampaigns = async ids => {
  try {
    await http.post('/campaigns/archive', { ids });
  } catch (error) {
    throw new UnexpectedError();
  }
};

export const fetchEngagement = async contactId => {
  try {
    const { data } = await http.get(`/events/engagement/${contactId}`);
    return data?.data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const fetchACEvent = async sgCompleteId => {
  try {
    const { data } = await http.get(
      `/events/engagement/events/${sgCompleteId}`
    );
    return data?.data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const fetchACMessage = async (acID, stageID) => {
  try {
    const { data } = await http.get(
      `/events/engagement/message/${acID}/${stageID}`
    );
    return data?.data;
  } catch (e) {
    console.error(e);
    return false;
  }
};
