import { TextField } from '@material-ui/core';
import styled from 'styled-components';

export const ListContactNotesStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;

  .header {
    margin-bottom: 10px;
  }

  .view-more {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Montserrat';
    color: #007ade;

    &:hover {
      background-color: transparent;
    }
  }
`;

export const DateStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .cancel-btn {
    width: 18px;
    height: 18px;
    color: #ff6161;
    margin-left: 10px;
    cursor: pointer;
  }
`;

export const NoteViewStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;

  &:before {
    content: '';
    width: 2px;
    height: 100%;
    background: #a6a6a6;
    position: absolute;
    left: -32px;
    top: 0;
    margin-top: 16px;
  }

  &:after {
    content: '';
    width: 2px;
    height: 100%;
    background: #a6a6a6;
    position: absolute;
    left: -32px;
    top: 50%;
    margin-top: 16px;
  }

  &:first-child {
    &:before {
      content: none;
    }
  }

  &:last-child {
    &:after {
      content: none;
    }

    &:before {
      height: 50%;
    }
  }

  &:nth-last-child(2) {
    &:after {
      height: 80%;
    }
  }

  * {
    font-size: 14px;
    font-family: Montserrat;
  }

  .cirlce {
    background-color: #a6a6a6;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    margin-top: auto;
    top: 0;
    bottom: 0;
    left: -39px;
    margin-bottom: auto;
    right: 0;
    border: 4px solid #fff;
    z-index: 1;
  }
`;

export const NoteViewHeader = styled.div`
  width: 100%;

  & p {
    color: #007ade;
    font-size: 14px;
    font-weight: 500;
    right: 0;
    margin: unset;
    margin-left: auto;
    @media screen and (min-width: 525px) {
      width: 65%;
    }
  }

  & span {
    float: right;
    margin-top: -22px;
    margin-right: 4px;

    svg {
      width: 14px;
      height: 14px;
      cursor: pointer;

      &.edit {
        margin-right: 10px;
        color: #007ade;
      }

      &.delete {
        color: #ff6161;
      }
    }
  }
`;

export const NoteViewBody = styled.div`
  position: relative;
  width: 100%;
  margin-top: 4px;

  & div {
    z-index: 1;
    background-color: #f7f7f7;
    padding: 16px;
    border-radius: 10px;
    width: 100%;
    position: relative;

    & p {
      margin: auto;
      font-weight: 500;
    }
  }
`;

export const EditBoxStyled = styled.div`
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  & .btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & .cancel-btn {
      color: #007ade;
      background-color: #fff;
      padding-right: 12px;

      &:hover {
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
  }
`;

export const NoteListStyled = styled.div`
  padding-left: 32px;
`;

export const TextFieldStyled = styled(TextField)`
  && {
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    margin-left: auto;
    padding: 0 8px;

    & button {
      width: 24px;
      height: 24px;

      & svg {
        color: rgba(0, 0, 0, 0.54);
        width: 100%;
        height: 100%;
      }
    }

    & svg {
      color: rgba(0, 0, 0, 0.54);
    }
  }
`;

export const ProgressBoxStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;

  & h3 {
    font-family: 'Montserrat';
  }
`;
