import React, { useEffect, useState } from 'react';
import {
  Container,
  ToggleButtonLeft,
  ToggleButtonRight,
  ToggleButtonGroup,
} from './ABToggleButton.styled';
import * as services from '../../../../../redux/services';

const VERSION_A = 'A';
const VERSION_B = 'B';

const ABToggleButton = ({ message, setMessage }) => {
  const [activeVersion, setActiveVersion] = useState(
    message?.AB?.version || 'A'
  );

  useEffect(() => {
    if (message?.AB?.version) {
      setActiveVersion(message.AB.version);
    }
  }, [message]);

  const handleVersionChange = async version => {
    if (version === activeVersion) return;
    setActiveVersion(version);
    const messageData = await services.getMsgById(message.AB.linkedMessageId);
    setMessage(messageData);
  };

  return (
    <Container>
      <ToggleButtonGroup>
        <ToggleButtonLeft
          isActive={activeVersion === VERSION_A}
          aria-label="A"
          onClick={() => handleVersionChange(VERSION_A)}
        >
          A
        </ToggleButtonLeft>
        <ToggleButtonRight
          isActive={activeVersion === VERSION_B}
          aria-label="B"
          onClick={() => handleVersionChange(VERSION_B)}
        >
          B
        </ToggleButtonRight>
      </ToggleButtonGroup>
    </Container>
  );
};

export default ABToggleButton;
