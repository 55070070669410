import styled from 'styled-components';

export const Container = styled.div`
  width: 790px;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px 25px;
  background: #005092;
  color: white;
  font-size: 16px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const Body = styled.div`
  padding: 15px 25px;
  overflow-y: auto;
`;
