import React, { useState } from 'react';
import { connect } from 'react-redux';
import BalanceByChargeCode from '../BalanceByChargeCode';
import TotalOfAllOutstandingBalances from '../TotalOfAllOutstandingBalances';
import MembershipAndBillingStatus from '../MembershipAndBillingStatus';
import GenerateRow from '../GenerateFilterRow';
import { getLabelBasedOnOrgType } from '../_shared/utils';
import {
  BALANCE_BY_CHARGE_CODE,
  TOTAL_OF_ALL_OUTSTANDING_BALANCES,
  MEMBERSHIP_AND_BILLING_STATUS,
} from '../_shared/constants';

const ShowBillingFilters = ({
  audienceFilters,
  filterTypes,
  selectedFilters,
  orgTypeName,
}) => {
  const billingFilterType = filterTypes.filter(
    filter => filter.name === 'Billing'
  )[0];

  const billingFilters = audienceFilters.filter(
    filter => filter.filterType === billingFilterType.id
  );

  const billingFilterExists = name => {
    return billingFilters.some(filter => filter.name === name);
  };

  const [checkedBalanceByChargeCode, setBalanceByChargeCode] = useState(
    !!selectedFilters[BALANCE_BY_CHARGE_CODE]
  );
  const [
    checkedTotalOfAllOutstandingBalances,
    setTotalOfAllOutstandingBalances,
  ] = useState(!!selectedFilters[TOTAL_OF_ALL_OUTSTANDING_BALANCES]);
  const [checkedMembershipAndBillingStatus, setMembershipAndBillingStatus] =
    useState(!!selectedFilters[MEMBERSHIP_AND_BILLING_STATUS]);

  const billingFiltersList = [
    {
      filterName: BALANCE_BY_CHARGE_CODE,
      checked: checkedBalanceByChargeCode,
      setChecked: setBalanceByChargeCode,
      ComponentToRender: BalanceByChargeCode,
    },
    {
      filterName: TOTAL_OF_ALL_OUTSTANDING_BALANCES,
      checked: checkedTotalOfAllOutstandingBalances,
      setChecked: setTotalOfAllOutstandingBalances,
      ComponentToRender: TotalOfAllOutstandingBalances,
    },
    {
      filterName: MEMBERSHIP_AND_BILLING_STATUS,
      checked: checkedMembershipAndBillingStatus,
      setChecked: setMembershipAndBillingStatus,
      ComponentToRender: MembershipAndBillingStatus,
    },
  ];

  return (
    <>
      {billingFiltersList.map(
        ({ filterName, checked, setChecked, ComponentToRender }) =>
          billingFilterExists(filterName) && (
            <GenerateRow
              key={filterName}
              check={checked}
              setCheck={setChecked}
              label={getLabelBasedOnOrgType(
                filterName,
                audienceFilters,
                orgTypeName
              )}
            >
              <ComponentToRender
                filterName={filterName}
                checked={checked}
                selectedFilters={selectedFilters[filterName]}
              />
            </GenerateRow>
          )
      )}
    </>
  );
};

const mapStateToProps = state => ({
  audienceFilters: state.audience.audienceFilters,
  filterTypes: state.audience.filterTypes,
  selectedFilters: state.audience.selectedFilters,
  orgTypeName: state.audience.orgTypeName,
});

export default connect(mapStateToProps)(ShowBillingFilters);
