import styled from 'styled-components';

export const Name = styled.span`
  color: #444444;
  font-size: 16px;
  line-height: 1.1875;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
`;

Name.Footer = styled(Name)`
  margin-left: -20px;
  color: #616161;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 400;
`;

export const Rate = styled.span`
  color: #000000;
  font-size: 15px;
  line-height: 1.2;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
`;
