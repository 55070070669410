import React from 'react';
import AMSSyncLogs from './AMSSyncLogs';
import { AMS_SYNC_LOGS, TAB_2, TAB_3, TAB_4 } from '../Tabs/constants';
import { Container } from './Content.styled';

const Content = ({
  selectedTab,
  isAddOrgDrawerVisible,
  setAddOrgDrawerVisibility,
}) => {
  const renderContent = () => {
    switch (selectedTab) {
      case AMS_SYNC_LOGS:
        return (
          <AMSSyncLogs
            isAddOrgDrawerVisible={isAddOrgDrawerVisible}
            setAddOrgDrawerVisibility={setAddOrgDrawerVisibility}
          />
        );
      case TAB_2:
      case TAB_3:
      case TAB_4:
        return <>Tab</>;
      default:
        return null;
    }
  };

  return <Container>{renderContent()}</Container>;
};

export default Content;
