import './memberList.css';
import 'antd/dist/antd.css';

import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Grid } from 'grommet';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as actioncreators from '../../redux/action';
import { authUserHelper, gAnalytics } from '../../redux/helpers';
import { getOrgContacts } from '../../redux/services/contact';
import { massApplyGroupsToContacts } from '../../redux/services/groups';

const MemberList = props => {
  const [tagDetail, setTagDetail] = useState([]);
  const [SelectedMemberNRIds, setSelectedMemberNRIds] = useState([]);
  const [memberlist, setMemberList] = useState([]);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    authUserHelper(props);
    gAnalytics('pageview', '/member-list');
    if (props.location.data) {
      setTagDetail(props.location.data);
      loadOrgInfo();
    }

    window.scrollTo(0, 0);
  }, [props.organization.selected.id]);

  const loadOrgInfo = async () => {
    await fetchMemberList(props.organization.selected.id);
  };

  const fetchMemberList = async id => {
    props.actions.fullloader(true, 'Fetching Contacts ...');
    const members = await getOrgContacts(id);
    if (!members.error) {
      const membersWithIDs = members.map(mem => {
        mem.id = mem.noteRouterId;
        return mem;
      });
      membersWithIDs.sort((a, b) => {
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return 1;
        if (b.firstName.toLowerCase() > a.firstName.toLowerCase()) return -1;
        return 0;
      });
      setMemberList(membersWithIDs);
    } else {
      setMemberList([]);
      props.actions.toast('error', 'Error retrieving contacts.');
    }
    props.actions.fullloader(false);
  };

  const applyTags = async () => {
    if (SelectedMemberNRIds.length < 1) {
      props.actions.toast('error', 'Select a contact');
      return;
    }
    props.actions.fullloader(true, 'Applying Group');
    const membersAndTag = {
      members: SelectedMemberNRIds,
      tag: tagDetail.id,
      type: tagDetail.groupTypeName ? tagDetail.groupTypeName : 'Topic',
    };
    const updatedMembers = await massApplyGroupsToContacts(membersAndTag);
    if (!updatedMembers.error) {
      props.actions.toast('success', 'Group applied to selected contact(s)');
    } else {
      props.actions.toast('error', 'Error applying your group.');
    }
    props.actions.fullloader(false);
    props.history.push({
      pathname: 'manage',
    });
  };

  const cancel = () => {
    props.history.push({
      pathname: 'manage',
    });
  };

  const columnData = [
    {
      headerName: 'First Name',
      field: 'firstName',
      minWidth: 110,
      flex: 1,
    },
    { headerName: 'Last Name', field: 'lastName', minWidth: 110, flex: 1 },
    {
      headerName: 'Phone',
      field: 'phoneNumber',
      minWidth: 125,
      flex: 0.65,
    },
    {
      headerName: 'Email',
      field: 'email',
      minWidth: 150,
      flex: 1.75,
    },
  ];

  return (
    <div className="Listcontainer">
      <Box>
        {/* MEMBER TAB secion start */}
        <Box overflow-x="hidden" overflow-y="auto" align="center">
          <div className="secondPart1 secondPart">
            <Grid
              columns={{
                size: 'auto',
              }}
              gap="small"
            >
              <Box className="spacebetween1 spacebetween">
                <Box className="secondSectionpart1">
                  <h3 className="m0">{tagDetail.name}</h3>
                </Box>
                <Box className="secondSectionpart1 applycancelsec">
                  <Button
                    className="applyButtonAddTag"
                    onClick={e => {
                      applyTags(e);
                    }}
                  >
                    Apply
                  </Button>
                  <Button
                    className="cancelButtonAddTag"
                    onClick={e => {
                      cancel(e);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Grid>
          </div>
          <div className="memberListTable">
            <Box>
              <span
                style={{
                  height: Math.floor(
                    window.innerHeight - window.innerHeight * 0.14
                  ),
                  background: 'white',
                }}
              >
                <DataGrid
                  stickyHeader
                  rows={memberlist}
                  columns={columnData}
                  sortingOrder={['asc', 'desc']}
                  pageSize={pageSize}
                  onPageSizeChange={size => setPageSize(size)}
                  checkboxSelection
                  disableSelectionOnClick
                  onSelectionModelChange={newSelectionModel => {
                    setSelectedMemberNRIds(newSelectionModel);
                  }}
                  selectionModel={SelectedMemberNRIds}
                />
              </span>
            </Box>
          </div>
        </Box>
        {/* MEMBER TAB secion end */}
      </Box>
    </div>
  );
};

const mapStateToProps = state => {
  return state;
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actioncreators, dispatch),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MemberList)
);
