export const EQUAL_TO = 'equal to';
export const GREATER_THAN = 'greater than';
export const LESS_THAN = 'less than';
export const BETWEEN_AMOUNT = 'between';
export const BETWEEN_DATE = 'Between';
export const ON_OR_BEFORE = 'On or before';
export const ON_OR_LATER = 'On or after';
export const NEVER = 'Never';
export const REGISTERED = 'registered';
export const UNREGISTERED = 'unregistered';
export const ATTENDED = 'attended';
export const DID_NOT_ATTEND = 'did not attend';
export const NO_DATE_GIVEN = 'No date given';
export const comparisonOperatorValues = [
  EQUAL_TO,
  GREATER_THAN,
  BETWEEN_AMOUNT,
  LESS_THAN,
];
export const registrationStatuses = [REGISTERED, UNREGISTERED];
export const attendedStatuses = [ATTENDED, DID_NOT_ATTEND];

export const MEMBERSHIP = 'Membership';
export const BILLING = 'Billing';
export const DATES = 'Dates';
export const GEOGRAPHIC = 'Geographic';
export const EVENT_AND_CLASSES = 'Events & Classes';

export const MEMBERSHIP_STATUS = 'Membership Status';
export const MEMBERSHIP_TYPE = 'Membership Type';
export const MEMBER_DESIGNATIONS = 'Member Designations';
export const DESIGNATION_SHOULD_EXIST = 'designationShouldExist';
export const BALANCE_BY_CHARGE_CODE = 'Balance by Charge Code';
export const TOTAL_OF_ALL_OUTSTANDING_BALANCES =
  'Total of All Outstanding Balances';
export const MEMBERSHIP_AND_BILLING_STATUS = 'Membership Billing Status';
export const BIRTHDATE = 'Birthdate';
export const JOIN_DATE = 'Join Date';
export const ADDED_DATE = 'Added Date';
export const ORIENTATION_DATE = 'Orientation Date';
export const LICENSE_EXPIRATION_DATE = 'License Expiration Date';
export const LAST_PREFERENCE_PAGE_VISIT = 'Last Preference Page Visit';
export const CREDIT_CARD_EXPIRATION_DATE = 'Credit Card Expiration Date';
export const HOME_CITY = 'Home City';
export const HOME_COUNTY = 'Home County';
export const HOME_ZIP_CODE = 'Home Zip Code';
export const HOME_STATE = 'Home State';
export const OFFICE_CITY = 'Office City';
export const OFFICE_COUNTY = 'Office County';
export const OFFICE_ZIP_CODE = 'Office Zip Code';
export const OFFICE_STATE = 'Office State';
export const COMMITTEES = 'Committees';
export const COMMITTEE_MEMBER = 'Committee Member';
export const CODE_OF_ETHICS_COMPLETION = 'Code of Ethics Completion';
export const EVENT_AND_CLASS_REGISTRATION = 'Event & Class Registration';
export const COURSE_REGISTRATION = 'Course Registration';
export const EVENT_AND_CLASS_ATTENDANCE = 'Event & Class Attendance';
export const COURSE_ATTENDANCE = 'Course Attendance';

export const comparisonDateValues = filterName => {
  const filtersWithNever = {
    [LAST_PREFERENCE_PAGE_VISIT]: LAST_PREFERENCE_PAGE_VISIT,
  };
  const filtersWithNoDateGiven = {
    [BIRTHDATE]: BIRTHDATE,
    [JOIN_DATE]: JOIN_DATE,
    [ORIENTATION_DATE]: ORIENTATION_DATE,
    [LICENSE_EXPIRATION_DATE]: LICENSE_EXPIRATION_DATE,
  };
  const dateComparators = [ON_OR_BEFORE, BETWEEN_DATE, ON_OR_LATER];
  if (filtersWithNever[filterName]) dateComparators.unshift(NEVER);
  if (filtersWithNoDateGiven[filterName]) dateComparators.push(NO_DATE_GIVEN);
  return dateComparators;
};
