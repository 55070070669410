import React from 'react';
import { IconButton, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  CancelButton,
  DeleteModalStyled,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  OkButton,
} from './modals.styled';

export const DeleteConfirmationModal = ({
  isOpen,
  toggle,
  confirmDelete,
  disabled,
  content,
  actionName = 'action',
}) => {
  return (
    <Modal open={isOpen} onClose={toggle}>
      <DeleteModalStyled>
        <ModalHeader>
          <IconButton onClick={toggle}>
            <CloseIcon />
          </IconButton>
          <span>Confirm Deletion</span>
        </ModalHeader>
        <ModalBody>
          <p>
            This action will delete the {actionName} listed below.{' '}
            <b>Once deleted, this {actionName} cannot be recovered.</b>
          </p>
          <ModalContent>{content}</ModalContent>
          <ModalFooter>
            <CancelButton
              size={'small'}
              variant="outlined"
              onClick={toggle}
              disabled={disabled}
            >
              No, cancel
            </CancelButton>
            <OkButton
              size={'small'}
              variant="outlined"
              onClick={confirmDelete}
              disabled={disabled}
            >
              Yes, delete
            </OkButton>
          </ModalFooter>
        </ModalBody>
      </DeleteModalStyled>
    </Modal>
  );
};
