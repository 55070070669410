import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { toast } from '../../../../../../redux/actions/UI';
import ClickDates from '../ClickDates';
import { ActivityContainer, Title } from './ClickPanel.styled';
import { fetchClickActivity } from '../../api';

const ClickPanel = ({ messageId, linkIndex }) => {
  const [isClickDetailsLoading, setClickDetailsLoading] = useState(true);
  const [clickData, setClickData] = useState([]);
  const dispatch = useDispatch();

  const fetchClickActivityData = async linkIndex => {
    try {
      setClickDetailsLoading(true);
      const result = await fetchClickActivity({
        messageId,
        index: linkIndex - 1,
      });
      setClickData(result);
      setClickDetailsLoading(false);
    } catch (e) {
      console.error(e);
      dispatch(toast('error', 'Failed to fetch click activity data'));
    }
  };

  useEffect(() => {
    fetchClickActivityData(linkIndex);
  }, []);

  return (
    <ActivityContainer>
      {isClickDetailsLoading && (
        <CircularProgress
          size={24}
          style={{ margin: 'auto', color: '#007ADE' }}
        />
      )}
      {!isClickDetailsLoading && (
        <>
          <Title>Click Activity</Title>
          {clickData.map(activity => (
            <ClickDates key={activity.email} activity={activity} />
          ))}
        </>
      )}
    </ActivityContainer>
  );
};

export default ClickPanel;
