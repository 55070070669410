import React, { useRef, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useFullLoader, useToast } from '../../../../redux/actions/UI';
import BaseEditor from '../../../../Component/Sendmessage/BaseEditor';
import useSaveStatus from '../../../../Component/Sendmessage/Components/SavingStatus';
import MenuContent from '../../../../Component/Sendmessage/Components/MenuContent';
import { useEditor } from '../CampaignEditor';
import { EditorHeader, EditorWrapper, Step } from './TemplateEditor.styled';
import { useQueryClient } from 'react-query';

const LEAVE_CONFIRMATION_MESSAGE =
  'Are you sure you want to leave? Any unsaved progress will be lost.';

const TemplateEditor = () => {
  const editor = useEditor();

  const haveActiveTemplate = editor.hasParam('template');
  const activeTemplate = haveActiveTemplate
    ? editor.getParam('template')
    : null;

  const [name, setName] = useState(() => {
    if (haveActiveTemplate) {
      return activeTemplate.name;
    }

    const currentStages = editor.getField('stages');
    const activeStageId = editor.getParam('stage');
    const activeStageIndex = currentStages.findIndex(stage => {
      return stage._id === activeStageId;
    });
    const activeStageNumber = activeStageIndex + 1;
    const activeStage = currentStages[activeStageIndex];

    const campaignName = editor.getField('name');
    const stageName = activeStage.name ?? `Stage ${activeStageNumber}`;
    return `${campaignName} ${stageName} Template`;
  });
  const [html, setHtml] = useState(activeTemplate?.html ?? '');
  const [design, setDesign] = useState(
    haveActiveTemplate ? JSON.parse(activeTemplate.design) : {}
  );
  const [messageInfo, setMessageInfo] = useState({
    subject: activeTemplate?.subject ?? '',
    replyTo: activeTemplate?.replyTo ?? '',
    sender: activeTemplate?.sender ?? '',
    senderName: activeTemplate?.senderName ?? '',
    preheader: activeTemplate?.preheader ?? '',
  });
  const [saveStatus, isTextStatusSaved, { onMarkChangesUnsavedStatus }] =
    useSaveStatus();

  const goBack = () => {
    const isConfirmed = window.confirm(LEAVE_CONFIRMATION_MESSAGE);
    if (isConfirmed) {
      const activeStageId = editor.getParam('stage');
      editor.goToStep('template/picker', { stage: activeStageId });
    }
  };

  const designEditorRef = useRef();
  const messageInfoRef = useRef();
  const fullLoader = useFullLoader();
  const { successToast, errorToast } = useToast();

  const handleConfirm = () => {
    if (!name) {
      errorToast('Name is required. Please, enter name.', 5);
      return;
    }

    const isValid = messageInfoRef.current.validate();
    if (!isValid) {
      return;
    }

    fullLoader.during('Applying new template to the stage', async () => {
      try {
        const newHtml = await designEditorRef.current.getHtml();
        const newTemplateData = {
          name,
          html: newHtml,
          design: JSON.stringify(design),
          ...messageInfo,
        };

        const currentStages = editor.getField('stages');
        const targetStageId = editor.getParam('stage');
        const newStages = currentStages.map(stage => {
          if (stage._id !== targetStageId) {
            return stage;
          }

          return {
            ...stage,
            template: newTemplateData,
          };
        });

        editor.setField('stages', newStages);
        editor.goToStep('stages');

        successToast('Template is applied successfully');
      } catch (error) {
        errorToast(
          'Failed to apply template. Please, try again later. If the issue persists, please contact us.',
          10
        );
      }
    });
  };

  const handleMessageInfoSave = newMessageInfo => {
    console.log('MESSAGE INFO SAVE >>>', newMessageInfo);
    setMessageInfo(newMessageInfo);
  };

  const handleEditorSave = newEditorData => {
    console.log('EDITOR SAVE >>>', newEditorData);
  };

  const queryClient = useQueryClient();
  const handleSaveAsTemplateSuccess = () => {
    queryClient.invalidateQueries(['templates', 'user', 'index']);
  };

  return (
    <>
      <Step
        title="Template"
        nextText="Confirm"
        onNext={handleConfirm}
        onBack={goBack}
        headerRightAddon={
          <EditorHeader
            menu={
              <MenuContent
                data={{
                  design: JSON.stringify(design),
                  html,
                  sender: messageInfo.sender,
                  senderName: messageInfo.senderName,
                  replyTo: messageInfo.replyTo,
                  preheader: messageInfo.preheader,
                  subject: messageInfo.subject,
                }}
                initialName={name}
                onSaveSuccess={handleSaveAsTemplateSuccess}
              />
            }
            isTitleHidden
            isSaveStatusHidden
            nameInputPlaceholder="Enter Template Name"
            name={name}
            onNameChange={setName}
            saveStatus={saveStatus}
            onUnsavedStatus={onMarkChangesUnsavedStatus}
            messageInfoRef={messageInfoRef}
            messageInfoData={messageInfo}
            onSave={handleMessageInfoSave}
            autosaveInterval={0}
          />
        }
        withoutVerticalPaddingInBody
        withoutHorizontalPaddingInBody
      >
        <EditorWrapper>
          <BaseEditor
            ref={designEditorRef}
            html={html}
            setHtml={setHtml}
            design={design}
            setDesign={design => {
              setDesign(JSON.parse(design));
            }}
            onUnsavedStatus={onMarkChangesUnsavedStatus}
            saveStatus={saveStatus}
            isTextStatusSaved={isTextStatusSaved}
            onSave={handleEditorSave}
          />
        </EditorWrapper>
      </Step>
      <Prompt message={LEAVE_CONFIRMATION_MESSAGE} />
    </>
  );
};

export default TemplateEditor;
