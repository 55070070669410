import moment from 'moment';
import {
  patchNoteAPI,
  postNoteAPI,
  deleteNoteAPI,
  getNotesAPI,
} from './notes.api';
import { useToast } from '../../actions/UI';
import { useSelector } from 'react-redux';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { NotesContext } from './notes.context';
import { callCT, setCT } from '../../../utils/andromeda-api';

moment.locale('en');

export const useGetNotes = () => {
  const actionName = 'GET_ALL_NOTES';
  const { noteRouterId, notes, setNotes, startDate, endDate, search } =
    useContext(NotesContext);
  const timeout = useRef();
  const limit = 10;
  const [skip, setSkip] = useState(0);
  const user = useSelector(state => state.user?.detail);
  const [isDisabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  setCT(actionName);

  const formatDate = date => moment(date, 'MM-DD-YYYY');

  const getNotes = useCallback(
    async (skip, startDate, endDate) => {
      setLoading(true);
      callCT(actionName);
      try {
        const params = {
          limit,
          skip,
        };
        setSkip(skip);
        params.organizationId = user.organizationId;
        const start = formatDate(startDate);
        const end = formatDate(endDate);
        if (start?.isValid()) {
          params.startDate = start.format('DD-MM-YYYY');
          if (end?.isValid()) params.endDate = end.format('DD-MM-YYYY');
        }
        if (search?.trim()) params.search = search;
        const data = await getNotesAPI(
          user.userId,
          noteRouterId,
          params,
          setCT(actionName)
        );
        const len = data?.data?.data?.length;
        if (!len || len < limit) setDisabled(true);
        if (skip) {
          setNotes(notes.concat(data?.data?.data));
        } else setNotes(data?.data?.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [noteRouterId, search]
  );

  const getNextNotes = async () => {
    setSkip(skip => skip + limit);
  };

  const getPrevNotes = async () => {
    setSkip(skip => (skip - limit > 0 ? skip - limit : 0));
  };

  useEffect(() => {
    if ((skip + 1) * limit > notes?.length) getNotes(skip, startDate, endDate);
  }, [noteRouterId, skip]);

  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      if (noteRouterId) getNotes(0, startDate, endDate);
    }, 500);

    return () => {
      clearTimeout(timeout.current);
    };
  }, [search]);

  return {
    loading,
    isDisabled,
    notes,
    getNotes,
    getNextNotes,
    getPrevNotes,
    setSkip,
  };
};

export const useCreateNotes = () => {
  const actionName = 'CREATE_NEW_NOTE';
  const { notes, setNotes } = useContext(NotesContext);
  const { successToast, errorToast } = useToast();
  const userId = useSelector(state => state.user?.detail?.userId);
  const [createState, setState] = useState({
    loading: false,
    success: false,
  });

  const createNote = async (content, noteRouterId) => {
    callCT(actionName);
    setState({ loading: true, success: false });
    try {
      const note = {
        userId,
        content,
        noteRouterId,
      };
      const newNote = await postNoteAPI(note, setCT(actionName));
      setNotes([newNote.data?.data, ...notes]);
      setState({ loading: false, success: true });
      successToast(newNote.message ?? 'Successfully saved the note.');
    } catch (error) {
      setState({ loading: false, success: true });
      errorToast(error?.message ?? 'Something went while creating note');
    }
  };

  return { createState, createNote };
};

export const useUpdateNote = () => {
  const actionName = 'UPDATE_NOTE_ACTION';
  const { successToast, errorToast } = useToast();
  const { notes, setNotes } = useContext(NotesContext);
  const [updateState, setState] = useState({
    loading: false,
    success: false,
  });

  const updateNote = async (noteId, updatedContent) => {
    callCT(actionName);
    try {
      setState({ loading: true, success: false });
      const updatedNote = await patchNoteAPI(
        noteId,
        { note: updatedContent },
        setCT(actionName)
      );

      updateNoteContext(noteId, updatedNote.data?.data);
      successToast(updatedNote.message ?? 'Successfully updated the note.');
    } catch (error) {
      setState({ loading: false, success: false });
      errorToast(error?.message ?? 'Something went while updating note');
    }
  };

  const updateNoteContext = (noteId, updatedNote) => {
    const index = notes.findIndex(note => note._id === noteId);
    notes[index] = updatedNote;
    setNotes(notes);
    setState({ loading: false, success: true });
  };

  return { updateState, updateNote };
};

export const useDeleteNote = () => {
  const { successToast, errorToast } = useToast();
  const { notes, setNotes } = useContext(NotesContext);
  const [isDeleting, setDelete] = useState(false);

  const deleteNote = async noteId => {
    setDelete(true);
    try {
      await deleteNoteAPI(noteId);
      successToast('Successfully deleted the note.');
      removeNoteFromContext(noteId);
    } catch (error) {
      errorToast(error?.message ?? 'Something went while deleting the note');
    } finally {
      setDelete(false);
    }
  };

  const removeNoteFromContext = noteId => {
    const updatedNotes = notes.filter(note => note._id !== noteId);
    setNotes(updatedNotes);
  };

  return { isDeleting, deleteNote };
};
