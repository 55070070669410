import React from 'react';
import { DateFieldStyled } from './dateField.styled';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

export const DateField = props => {
  const {
    value,
    onChange,
    KeyboardButtonProps,
    InputProps = {},
    startFromYear,
    format = 'MM-dd-yyyy',
    placeholder = 'mm-dd-yyyy',
  } = props;
  const yearStart = startFromYear
    ? { openTo: 'year', views: ['year', 'month', 'date'] }
    : {};

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateFieldStyled
        format={format}
        placeholder={placeholder}
        value={value}
        disableToolbar
        variant="inline"
        autoOk={true}
        onChange={onChange}
        InputProps={{ disableUnderline: true, ...InputProps }}
        KeyboardButtonProps={KeyboardButtonProps}
        {...props}
        {...yearStart}
      />
    </MuiPickersUtilsProvider>
  );
};
