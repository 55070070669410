import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAsync } from 'react-use';
import { Popover, Tooltip } from 'antd';
import { KeyboardArrowDown } from '@material-ui/icons';

import prefetchImage from '../../libs/prefetch-image';
import { useToggle } from '../../libs/react-use-extra';
import {
  organizationListSelector,
  selectedOrganizationSelector,
} from '../../redux/selectors/organization';
import { userDetailSelector } from '../../redux/selectors/user';
import { userOrganization } from '../../redux/actions/organizations';
import { getLogoUrl } from '../../redux/services/content';
import {
  Container,
  OrganizationDisplay,
  OrganizationsList,
  ResultsContainer,
  SearchContainer,
  SearchInput,
  SearchTitle,
  ThumbnailButton,
} from './OrganizationPicker.styled';

const ROUTES_WITH_DISABLED_ORGANIZATION_PICKER = [
  'sendmessage',
  'audience',
  'preview',
  'automation/new',
  'automation/edit',
];

const useIsChangeableForCurrentLocation = () => {
  const location = useLocation();
  return ROUTES_WITH_DISABLED_ORGANIZATION_PICKER.every(
    route => !location.pathname.includes(route)
  );
};

const useIsChangeableForCurrentUser = () => {
  const user = useSelector(userDetailSelector);
  return user.multiOrgUser;
};

const useIsChangeable = () => {
  const isChangeableForCurrentLocation = useIsChangeableForCurrentLocation();
  const isChangeableForCurrentUser = useIsChangeableForCurrentUser();
  return isChangeableForCurrentLocation && isChangeableForCurrentUser;
};

const useOrganizationLogoUrlQuery = organizationId => {
  const query = useAsync(async () => {
    const logoUrl = await getLogoUrl(organizationId);
    if (!logoUrl) {
      throw new Error("Logo for the organization doesn't exist");
    }
    await prefetchImage(logoUrl, { timeout: 2_000 });
    return logoUrl;
  }, [organizationId]);

  return {
    value: query.value,
    isLoading: query.loading,
    isError: !!query.error,
  };
};

const useOrganizationSearch = () => {
  const [value, setValue] = useState('');

  const organizations = useSelector(organizationListSelector);
  const matchingOrganizations = organizations.filter(({ organizationName }) =>
    organizationName.toLowerCase().includes(value.toLowerCase())
  );

  return { value, setValue, matchingOrganizations };
};

const OrganizationPicker = ({ isThumbnailOnly = false }) => {
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(selectedOrganizationSelector);

  const isChangeable = useIsChangeable();
  const logoUrlQuery = useOrganizationLogoUrlQuery(selectedOrganization.id);

  const [isOpen, { on: open, off: close, toggle: toggleIsOpen }] = useToggle();
  const organizationSearch = useOrganizationSearch();

  const content = (
    <Container>
      <ThumbnailButton onClick={toggleIsOpen} disabled={!isChangeable}>
        {logoUrlQuery.isLoading ? (
          <ThumbnailButton.Spinner />
        ) : logoUrlQuery.isError ? (
          <ThumbnailButton.NoLogoMessage>
            {selectedOrganization.organizationName?.[0]}
          </ThumbnailButton.NoLogoMessage>
        ) : (
          <ThumbnailButton.Image
            src={logoUrlQuery.value}
            alt={selectedOrganization.name}
          />
        )}
      </ThumbnailButton>
      {!isThumbnailOnly && (
        <OrganizationDisplay>
          <OrganizationDisplay.Header>
            <Tooltip
              title={
                isChangeable ? null : selectedOrganization.organizationName
              }
            >
              <OrganizationDisplay.Title
                $isClickable={isChangeable}
                onClick={isChangeable ? open : null}
              >
                {selectedOrganization.acronym}
              </OrganizationDisplay.Title>
            </Tooltip>
            {isChangeable && (
              <OrganizationDisplay.OpenButton $isOpen={isOpen} onClick={open}>
                <KeyboardArrowDown />
              </OrganizationDisplay.OpenButton>
            )}
          </OrganizationDisplay.Header>
          <OrganizationDisplay.Footer>
            <span>powered by</span>
            <OrganizationDisplay.NRLogo />
          </OrganizationDisplay.Footer>
        </OrganizationDisplay>
      )}
    </Container>
  );

  if (!isChangeable) {
    return content;
  }

  const searchContent = (
    <SearchContainer>
      <SearchInput
        value={organizationSearch.value}
        onChange={event => {
          organizationSearch.setValue(event.target.value);
        }}
        placeholder="Example organization name"
      />
      <ResultsContainer>
        {organizationSearch.matchingOrganizations.length > 0 ? (
          <OrganizationsList>
            {organizationSearch.matchingOrganizations.map(organization => (
              <OrganizationsList.Item key={organization.id}>
                <OrganizationsList.Item.Button
                  onClick={() => {
                    dispatch(userOrganization(organization));
                    close();
                  }}
                >
                  {organization.organizationName}
                </OrganizationsList.Item.Button>
              </OrganizationsList.Item>
            ))}
          </OrganizationsList>
        ) : (
          <span>No organizations found</span>
        )}
      </ResultsContainer>
    </SearchContainer>
  );

  return (
    <Popover
      title={
        <SearchTitle>
          Current organization: {selectedOrganization.organizationName} (
          {selectedOrganization.acronym})
        </SearchTitle>
      }
      content={searchContent}
      placement="rightTop"
      open={isOpen}
      onOpenChange={newIsVisible => {
        // Close if popover should be closed (e.g. when clicked outside of popover).
        // Note that opening is ignored, as it is fully managed by this component directly.
        if (!newIsVisible) {
          close();
        }
      }}
      trigger="click" // change trigger, so that auto-hiding is triggered properly (e.g. on click away, instead of on lose hover)
    >
      {content}
    </Popover>
  );
};

export default OrganizationPicker;
