import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, IconButton, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Select } from 'antd';
import {
  selectedOrganizationSelector,
  organizationListSelector,
} from '../../../../../redux/selector';
import { moveUserTemplateToOtherOrgAction } from '../../../../../redux/services';
import { toast, fullloader } from '../../../../../redux/action';
import { Container, Header, Wrap, Wrapper } from './UI.styled';
import { trimTextIfNeeded } from '../../_utils';

const { Option } = Select;

const MoveUserTemplateModal = ({
  isOpen,
  setOpen,
  template,
  removeTemplate,
}) => {
  const selectedOrg = useSelector(selectedOrganizationSelector);
  const orgList = useSelector(organizationListSelector);
  const dispatch = useDispatch();
  const [newOrgId, setNewOrgId] = useState('');

  const moveUserTemplateToOtherOrg = async () => {
    setOpen(false);
    if (newOrgId && newOrgId !== selectedOrg.id) {
      const newOrg = orgList.find(org => org.id === newOrgId) || '';
      dispatch(fullloader(true, 'Transferring teplate and refreshing list'));
      try {
        await moveUserTemplateToOtherOrgAction({
          ...template,
          newOrgId,
        });
        dispatch(
          toast(
            'success',
            `${template.name} has successfully been transferred to ${newOrg?.acronym}.`
          )
        );
        removeTemplate(template._id);
      } catch (e) {
        dispatch(
          toast(
            'error',
            `${template.name} was unable to be transferred to ${newOrg?.acronym}.`
          )
        );
      }
      dispatch(fullloader(false));
    }
  };

  return (
    <Modal open={isOpen} onClose={() => setOpen(false)}>
      <Container>
        <Header>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
            style={{ color: 'white' }}
          >
            <CloseIcon />
          </IconButton>
          <span style={{ fontSize: '15px' }}>
            Move user template {trimTextIfNeeded(template.name, 17)} to:
          </span>
        </Header>
        <Wrap>
          <Select
            defaultValue={selectedOrg.id}
            style={{ width: '320px' }}
            onChange={value => {
              setNewOrgId(value);
            }}
            showSearch
          >
            {orgList.length > 0 &&
              orgList.map(org => {
                return (
                  <Option key={org.id} value={org.id}>
                    {org.organizationName}
                  </Option>
                );
              })}
          </Select>
          <Wrapper>
            <Button
              size={'small'}
              style={{
                backgroundColor: '#FF6161',
                color: 'white',
                border: 'none',
              }}
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              size={'small'}
              style={{
                backgroundColor: '#5EAB00',
                color: 'white',
                border: 'none',
              }}
              variant="outlined"
              onClick={moveUserTemplateToOtherOrg}
            >
              Save
            </Button>
          </Wrapper>
        </Wrap>
      </Container>
    </Modal>
  );
};

export default MoveUserTemplateModal;
