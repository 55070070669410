const createCssPropertyName = name => `--${name.replaceAll(':', '\\:')}`;

const defineCssVariable = (name, value) => {
  return `${createCssPropertyName(name)}: ${value};`;
};

const defineCssVariables = variables => {
  const variableEntries = Object.entries(variables);
  const variableDefinitions = variableEntries.map(([name, value]) =>
    defineCssVariable(name, value)
  );
  return variableDefinitions.join('\n');
};

export default defineCssVariables;
