import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  setProgressType,
  updateNavProgressMeter,
  updateProgressMeterResults,
} from '../../../redux/action';
import { getAnalyticsCSVData } from '../../../redux/services';
import ExportClient from '../ExportSocketClient';
import Modal from '../../Modal';
import { ExportButton } from './ExportBtn.styled';

const ExportBtn = ({
  scheduledMessagesHidden,
  isAllTime,
  selectedOrg,
  startDate,
  selectedMessage,
  emailEvents,
  textEvents,
  groups,
  topics,
}) => {
  const dispatch = useDispatch();
  const [isExportModalVisible, setExportModalVisibility] = useState(false);
  const [exportSocketId, setExportSocketId] = useState('');

  const dateRange = useMemo(() => {
    if (selectedMessage) return startDate;
    if (isAllTime) return 'All Time';
    return `${startDate} - ${moment().format('MM/DD/YYYY')}`;
  }, [selectedMessage, startDate]);

  const generateCsvDataForSingleMsg = () => {
    const selectedTopics = [];
    const selectedGroups = [];
    for (const topicId of selectedMessage.topicIds) {
      selectedTopics.push(topics[topicId]);
    }
    for (const groupId of selectedMessage.selectedGroupIds) {
      selectedGroups.push(groups[groupId]);
    }

    const getRate = (numerator, denominator) => {
      return numerator > 0 && denominator > 0
        ? `${((numerator / denominator) * 100).toFixed(1)}%`
        : '0%';
    };

    const messageDetails = [
      {
        'Message ID': selectedMessage.id,
        'Message URL': `${process.env.REACT_APP_webUrl}/message?id=${selectedMessage.id}`,
        'Message Subject': selectedMessage.messageSubject,
        Date: moment
          .unix(selectedMessage.sendTime)
          .format('MM-DD-YYYY hh:mm A'),
        Topics: selectedTopics,
        Groups: selectedGroups,
        '# Email Deliveries': emailEvents.delivered,
        'Email Delivery Rate': getRate(emailEvents.delivered, emailEvents.sent),
        '# Opens': emailEvents.uniqueopen,
        'Open Rate': getRate(emailEvents.uniqueopen, emailEvents.delivered),
        '# Clickthroughs': emailEvents.uniqueclick,
        'Clickthrough Rate': getRate(
          emailEvents.uniqueclick,
          emailEvents.uniqueopen
        ),
        '# Email Permanent Bounces': emailEvents.permanentBounce,
        'Email Permanent Bounce Rate': getRate(
          emailEvents.permanentBounce,
          emailEvents.sent
        ),
        '# Email Temporary Bounces': emailEvents.temporaryBounce,
        'Email Temporary Bounce Rate': getRate(
          emailEvents.temporaryBounce,
          emailEvents.sent
        ),
        '# Email Drops': emailEvents.dropped,
        'Email Drop Rate': getRate(emailEvents.dropped, emailEvents.sent),
        '# Spam Reports': emailEvents.spamReport,
        'Spam Report Rate': getRate(emailEvents.spamReport, emailEvents.sent),
        '# Text Deliveries': textEvents.delivered,
        'Text Delivery Rate': getRate(textEvents.delivered, textEvents.sent),
        '# Text Bounces': textEvents.bounced,
        'Text Bounce Rate': getRate(textEvents.bounced, textEvents.sent),
      },
    ];

    const result = {
      messages: messageDetails,
      filename: `${selectedOrg.acronym} Analytics Export, '${selectedMessage.messageSubject}'.csv`,
    };
    setExportModalVisibility(false);
    dispatch(setProgressType('ANALYTICS_CSV'));
    dispatch(updateNavProgressMeter(100));
    dispatch(updateProgressMeterResults(result));
  };

  const generateCsvDataForDateRange = async () => {
    const payload = {
      socketId: exportSocketId,
      orgId: selectedOrg.id,
      messageDateRange: moment(startDate, 'MM/DD/YYYY').unix(),
      hideScheduled: scheduledMessagesHidden,
      filename: `${selectedOrg.acronym} Analytics Export, ${dateRange}.csv`,
    };
    setExportModalVisibility(false);

    await getAnalyticsCSVData(payload);
    dispatch(setProgressType('ANALYTICS_CSV'));
    dispatch(updateNavProgressMeter(0.1));
  };

  return (
    <>
      <ExportButton onClick={() => setExportModalVisibility(true)}>
        Export
      </ExportButton>
      {isExportModalVisible && (
        <>
          <Modal
            title="Confirm Export"
            onClose={() => setExportModalVisibility(false)}
            primaryButton={{
              label: 'Export',
              onClick: selectedMessage
                ? generateCsvDataForSingleMsg
                : generateCsvDataForDateRange,
            }}
            secondaryButton={{
              label: 'Cancel',
            }}
          >
            <p>
              {selectedMessage
                ? `This will export engagement data for selected message sent on: ${dateRange}`
                : `This will export engagement data for all messages sent in the range: ${dateRange}`}
            </p>
          </Modal>
          <ExportClient
            disconnectSocket={() => setExportSocketId('')}
            updateExportSocketId={socketId => setExportSocketId(socketId)}
          />
        </>
      )}
    </>
  );
};

export default ExportBtn;
