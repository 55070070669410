import React, { useEffect, useState } from 'react';
import { useGetNotes } from '../../../redux/services/notes';
import { ChildDrawer } from '../../ChildDrawer';
import { ContactNotesStyled } from './contactNotes.styled';
import { ContactNotesView } from './contactNotesView';

export const ContactNotesMain = ({ parentId, drawerTitle }) => {
  const [showNotes, setShowNotes] = useState(false);
  const [previewNote, setPreviewNote] = useState('');
  let isInitialRender = true;

  const toggleNotes = () => setShowNotes(note => !note);
  const { notes } = useGetNotes();

  useEffect(() => {
    if (notes?.length && isInitialRender) {
      isInitialRender = false;
      setPreviewNote(notes[0]?.note);
    }
  }, [notes]);

  return (
    <ContactNotesStyled>
      <div onClick={toggleNotes}>
        <h3>
          Notes <span>(click to expand)</span>
        </h3>
        <div className="tooltip">Click to manage notes</div>
        {previewNote ? (
          <div dangerouslySetInnerHTML={{ __html: previewNote }} />
        ) : (
          <div>No notes found!</div>
        )}
      </div>

      <ChildDrawer
        anchor="right"
        drawerTitle={drawerTitle || 'Notes'}
        parentId={parentId}
        open={showNotes}
        onClose={toggleNotes}
        Component={<ContactNotesView />}
      />
    </ContactNotesStyled>
  );
};
