import styled, { css } from 'styled-components';

const centerTabsStyle = css`
  margin-left: 100px;
  margin-right: auto;
`;

const Wrapper = styled.span`
  ${({ centerTabs }) => centerTabs && centerTabsStyle}

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 440px;
  @media (max-width: ${props => props.width}) {
    order: 3;
    margin-left: 30%;
  }
`;

export default Wrapper;
