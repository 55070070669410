export function textMessages(
  state = { unreadCount: 0, lastMsgConversationId: null },
  action
) {
  const { detail, type } = action;
  switch (type) {
    case 'INCREASE_UNREAD_COUNT':
      return {
        ...state,
        unreadCount: state.unreadCount + 1,
      };
    case 'DECREASE_UNREAD_COUNT':
      return {
        ...state,
        unreadCount: state.unreadCount - 1,
      };
    case 'RESET_UNREAD_COUNT':
      return {
        ...state,
        unreadCount: detail || 0,
      };
    case 'SET_LAST_MESSAGE_CONVERSATION_ID':
      return {
        ...state,
        lastMsgConversationId: detail,
      };
    case 'SET_LAST_MESSAGE_ID_SENT_BY_ORG':
      return {
        ...state,
        lastMsgIdSentByOrg: detail,
      };
    case 'SET_LAST_MESSAGE_ID_SENT_BY_USER':
      return {
        ...state,
        lastMsgIdSentByUser: detail,
      };
    case 'SET_TEXTING_HUB_VIEWERS':
      return {
        ...state,
        textingHubViewers: detail,
      };
    default:
      return state;
  }
}
