const mapKeys = (object, mapper) => {
  const originalEntries = Object.entries(object);
  const mappedEntries = originalEntries.map(([key, value]) => [
    mapper(key),
    value,
  ]);
  return Object.fromEntries(mappedEntries);
};

export default mapKeys;
