import styled from 'styled-components';

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 650px;
`;

export default Wrapper;
