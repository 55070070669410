import React from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import { useToggle } from '../../../libs/react-use-extra';
import { useFullLoader, useToast } from '../../../redux/actions/UI';
import { InvalidDataError } from '../api';
import { useCreateCampaignMutation } from '../queries';
import CampaignEditor from '../CampaignEditor';
import { Errors } from './CreateCampaign.styled';

const CreateCampaign = () => {
  const history = useHistory();
  const fullLoader = useFullLoader();
  const { successToast, errorToast } = useToast();
  const { mutateAsync: createCampaign } = useCreateCampaignMutation();
  const [
    shouldConfirmLeave,
    {
      on: preventLeavingWithoutConfirmation,
      off: allowLeavingWithoutConfirmation,
    },
  ] = useToggle();

  const handleConfirm = async campaignData => {
    try {
      await fullLoader.during('Creating a campaign...', async () => {
        await createCampaign(campaignData);
        allowLeavingWithoutConfirmation();
        successToast('Campaign successfully created');
        history.push('/dashboard/automation');
      });
    } catch (error) {
      console.error(error);
      if (error instanceof InvalidDataError) {
        errorToast(
          <Errors>
            <Errors.Title>Entered data is invalid:</Errors.Title>
            <Errors.List>
              {error.getValidationErrors().map((validationError, index) => (
                // Using index as key here is safe, as we're not dealing with stateful errors
                <Errors.Error key={index}>- {validationError}</Errors.Error>
              ))}
            </Errors.List>
          </Errors>,
          10
        );
      } else {
        errorToast(
          'Failed to create campaign. Please, try again later. If the issue persists, please contact us.',
          10
        );
      }
    }
  };

  return (
    <>
      <CampaignEditor
        initialData={{ status: 'active' }}
        confirmText="Confirm and Create"
        onConfirm={handleConfirm}
        onTouch={preventLeavingWithoutConfirmation}
      />
      <Prompt
        message="Are you sure you want to leave? Any unsaved progress will be lost."
        when={shouldConfirmLeave}
      />
    </>
  );
};

export default CreateCampaign;
