import styled from 'styled-components';
import { MenuItem, Select as BaseSelect } from '@material-ui/core';
import { UnfoldMore } from '@material-ui/icons';

import baseInputStyles from './baseInputStyles';

const Select = styled(BaseSelect).attrs({ IconComponent: UnfoldMore })`
  ${baseInputStyles}

  & > .MuiSelect-root:focus {
    background-color: transparent;
  }
`;

Select.Option = MenuItem;

export default Select;
