import React, { useState, useEffect } from 'react';
import './LastSyncs.css';
import '../../../../globalcss.css';
import { Container } from './SyncReport.styled';
import { TableWrapper } from '../../../../features/automated-campaigns/CampaignsIndex/CampaignsIndex.styled';
import SyncReportTable from './SyncReportTable';
import SyncReportDrawer from './SyncReportDrawer';
import { getAMSSyncReports } from '../../../../redux/services';
import AddOrgDrawer from './AddOrgDrawer';

const AMSSyncLogs = ({ isAddOrgDrawerVisible, setAddOrgDrawerVisibility }) => {
  const [AMSSyncReports, setAMSSyncReports] = useState(null);
  const [selectedOrgSync, setSelectedOrgSync] = useState(null);

  useEffect(() => {
    getTableReportData();
  }, []);

  const getTableReportData = async () => {
    const syncReportData = await getAMSSyncReports();
    setAMSSyncReports(syncReportData);
  };

  const openSyncReportDrawer = id => {
    //This fetches some of the report info that we already brought in. The AMSSyncReports contains both data on
    //delta syncs and full syncs. This grabs the data again so we can pass through the full sync related data.
    const manualSyncReport = AMSSyncReports.find(report => report.id === id);
    setSelectedOrgSync(manualSyncReport);
  };
  const closeSyncReportDrawer = () => {
    setSelectedOrgSync(null);
  };

  return (
    <Container>
      <TableWrapper>
        <TableWrapper.Content>
          <SyncReportTable
            data={AMSSyncReports || []}
            isLoading={!AMSSyncReports}
            onTableRowClick={openSyncReportDrawer}
          />
        </TableWrapper.Content>
      </TableWrapper>
      {selectedOrgSync && (
        <SyncReportDrawer
          report={selectedOrgSync}
          onClose={closeSyncReportDrawer}
        />
      )}
      {isAddOrgDrawerVisible && (
        <AddOrgDrawer
          isAddOrgDrawerVisible={isAddOrgDrawerVisible}
          setAddOrgDrawerVisibility={setAddOrgDrawerVisibility}
        />
      )}
    </Container>
  );
};

export default AMSSyncLogs;
