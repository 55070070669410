import 'antd/dist/antd.css';
import '../../globalcss.css';
import './Manage.css';

import { Button, IconButton } from '@material-ui/core';
import { Close, ArrowBackIos } from '@material-ui/icons';
import { Select } from 'antd';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import React, { useEffect, useState, useMemo } from 'react';
import ReactFileReader from 'react-file-reader';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import * as actioncreators from '../../redux/action';
import { csvImportMembers, getCustomFields } from '../../redux/services';
import CreateFieldModal from '../CustomFields/CreateFieldModal';
import StepTwo from './UploadContactDrawerStepTwo/StepTwo';
import Results from './Results';
import { Title, Row } from './UploadContactDrawerStepTwo/StepTwo.styled';
import { ButtonGroup, BackBtn, NextBtn } from './UploadContactsDrawer.styled';
const { Option } = Select;

const CSVUploadContact = props => {
  const [CSVFileName, setCSVFileName] = useState('');
  const [CSVFile, setCSVFile] = useState('');
  const [CSVColumns, setCSVColumns] = useState([]);
  const [CSVData, setCSVData] = useState([]);
  const [NRFields, setNRFields] = useState([]);
  const [filteredNRFields, setFilteredNRFields] = useState([]);
  const [defaultFilteredFields, setDefaultFilteredFields] = useState([]);
  const [CSVColumnMapping, setCSVColumnMapping] = useState({});
  const [newFieldMatchingColumn, setNewFieldMatchingColumn] = useState(null);

  const [allowDuplicateContacts, setAllowDuplicateContacts] = useState(false);
  const [shouldImportMembersOnly, setIfShouldImportMembersOnly] =
    useState(false);
  const [shouldChooseMembershipLater, setShouldChooseMembershipLater] =
    useState(false);
  const [shouldChooseTopicsLater, setShouldChooseTopicsLater] = useState(false);
  const [shouldNotChooseTopics, setShouldNotChooseTopics] = useState(false);
  const [shouldChooseGroupsLater, setShouldChooseGroupsLater] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [results, setResults] = useState({});

  useEffect(() => {
    props.setCSVUploadStep(1);
  }, []);

  const defaultSystemFields = [
    {
      key: '- Select Field -',
      value: '-',
      display: '- Select Field -',
    },
    {
      key: '+ Create New Field +',
      value: '+',
      display: '+ Create New Field +',
    },
    shouldChooseGroupsLater
      ? {
          key: 'identifiers',
          value: 'identifiers',
          display: 'Groups',
        }
      : null,
    !shouldNotChooseTopics && shouldChooseTopicsLater
      ? {
          key: 'emailTags',
          value: 'emailTags',
          display: 'Topics',
        }
      : null,
    shouldChooseMembershipLater
      ? {
          key: 'isMember',
          value: 'isMember',
          display: 'Contact Type (Member/Nonmember)',
        }
      : null,
  ].filter(Boolean);

  useEffect(() => {
    setDefaultFilteredFields(defaultSystemFields);
  }, [defaultSystemFields.length]);

  useEffect(() => {
    setSelectedTopics([]);
  }, [shouldImportMembersOnly]);

  useEffect(() => {
    if (!NRFields.length && props.organization.selected.id) {
      fetchOrgCustomFields();
    }
    window.scrollTo(0, 0);
  }, []);

  const fetchOrgCustomFields = async () => {
    const fields = await getCustomFields(
      props.organization.selected.id,
      props.organization.selected.orgType
    );
    setNRFields(fields);
    setFilteredNRFields(fields);
  };

  const finishFileUpload = async () => {
    if (
      shouldChooseMembershipLater &&
      !Object.values(CSVColumnMapping).includes('isMember')
    ) {
      return props.actions.toast(
        'error',
        'Please match "Contact Type (Member/Nonmember)" under relevant NoteRouter Field'
      );
    }
    if (
      !shouldNotChooseTopics &&
      shouldChooseTopicsLater &&
      !Object.values(CSVColumnMapping).includes('emailTags')
    ) {
      return props.actions.toast(
        'error',
        'Please match "Topics" under relevant NoteRouter Field'
      );
    }
    if (
      shouldChooseGroupsLater &&
      !Object.values(CSVColumnMapping).includes('identifiers')
    ) {
      return props.actions.toast(
        'error',
        'Please match "Groups" under relevant NoteRouter Field'
      );
    }

    let currentCount = 0;
    let chunkedMembers = [];
    let importResults = {
      totalAdded: 0,
      totalFailed: 0,
    };

    const options = {
      allowDuplicateContacts,
      ...(shouldNotChooseTopics || shouldChooseTopicsLater
        ? null
        : { selectedTopics }),
      ...(shouldChooseGroupsLater ? null : { selectedGroups }),
      ...(shouldChooseMembershipLater ? null : { shouldImportMembersOnly }),
    };

    for (let i = 0; i < CSVData.length; i += 2500) {
      chunkedMembers = CSVData.slice(i, i + 2500);
      currentCount += chunkedMembers.length;
      const csvOptions = {
        row: i,
        CSVData: chunkedMembers,
        CSVColumnMapping,
        orgId: props.organization.selected.id,
        ...options,
      };
      props.actions.fullloader(
        true,
        `Adding contacts => (${currentCount} / ${CSVData.length})`
      );
      const successImport = await csvImportMembers(csvOptions, CSVFile);

      if (successImport.error) {
        importResults.erroredImport = true;
        break;
      } else {
        importResults.totalAdded += successImport.result.success;
        importResults.totalFailed += successImport.result.failed;
        importResults.errorMsgs = successImport.result.errors;
      }
    }
    props.setCSVUploadStep(4);
    setResults(importResults);

    props.actions.fullloader(false);
  };

  const assignColumnsAndData = data => {
    const firstElement = data[0];
    let CSV_column_data = [];
    for (let columnName in firstElement) {
      let columnData = {
        name: columnName,
        value: firstElement[columnName],
      };
      if (!columnData.value) {
        columnData.value = data.find(row => row[columnName])
          ? data.find(row => row[columnName])[columnName]
          : '';
      }
      CSV_column_data.push(columnData);
    }
    setCSVColumns(CSV_column_data);
    setCSVData(data);
  };

  const handleFileUpload = files => {
    const uploadedFile = files[0];
    setCSVFileName(uploadedFile.name);
    setCSVFile(uploadedFile);

    if (
      uploadedFile.name.toLowerCase().endsWith('.xlsx') ||
      uploadedFile.name.toLowerCase().endsWith('.xls')
    ) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const xlsxData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          defval: '',
        });
        assignColumnsAndData(xlsxData);
      };

      reader.readAsBinaryString(uploadedFile);
    } else if (uploadedFile.name.toLowerCase().endsWith('.csv')) {
      Papa.parse(uploadedFile, {
        header: true,
        skipEmptyLines: 'greedy',
        complete: results => {
          assignColumnsAndData(results.data);
        },
      });
    }
    props.setCSVUploadStep(2);
  };

  const handleSearch = value => {
    if (value) {
      const filterFields = NRFields.filter(field => {
        return field.name.toLowerCase().includes(value.toLowerCase());
      });
      const defaultFilteredFields = defaultSystemFields.filter(field => {
        return field.display.toLowerCase().includes(value.toLowerCase());
      });
      setDefaultFilteredFields(defaultFilteredFields);
      setFilteredNRFields(filterFields);
    } else {
      setDefaultFilteredFields(defaultSystemFields);
      setFilteredNRFields(NRFields);
    }
  };

  const updateMapping = (columnName, NRFieldId) => {
    if (NRFieldId === '+') {
      //Open create modal and create a new modal
      setNewFieldMatchingColumn(columnName);
    }
    if (NRFields.length !== filteredNRFields.length) {
      setFilteredNRFields(NRFields); //Resets search options
      setDefaultFilteredFields(defaultSystemFields);
    }
    setCSVColumnMapping({
      ...CSVColumnMapping,
      [columnName]: NRFieldId,
    });
  };

  const updateSelection = newField => {
    updateMapping(newFieldMatchingColumn, newField.id);
    let updatedNRFields = [...NRFields];
    updatedNRFields.unshift(newField);
    setNRFields(updatedNRFields);
    setFilteredNRFields(updatedNRFields);
    setNewFieldMatchingColumn('');
  };

  const proceedToLastStep = () => {
    if (
      !shouldNotChooseTopics &&
      !shouldChooseTopicsLater &&
      !selectedTopics.length
    ) {
      return props.actions.toast(
        'error',
        'Please select topics to assign contacts to or click relevant checkbox to configure this later'
      );
    }
    props.setCSVUploadStep(3);
  };

  const ALL_FIELDS = useMemo(() => {
    const allFields = [
      ...defaultFilteredFields.map(({ key, value, display }) => {
        return {
          key,
          value,
          display,
        };
      }),
      ...filteredNRFields.map(field => {
        return {
          key: field.id,
          value: field.id,
          display: field.name,
        };
      }),
    ];
    return allFields.sort((a, b) => a.display.localeCompare(b.display));
  }, [defaultFilteredFields, filteredNRFields]);

  return (
    <div style={{ width: '100%', height: '100%', margin: '0' }}>
      <div
        className={'UploadContactDrawerHeader'}
        style={{
          justifyContent: props.CSVUploadStep === 1 ? 'flex-start' : '',
        }}
      >
        <span>
          <IconButton
            onClick={() => {
              props.closeDrawer();
            }}
            style={{ color: 'white' }}
          >
            <Close />
          </IconButton>
          {props.drawerTitle}
        </span>
        {props.CSVUploadStep === 2 && (
          <ButtonGroup>
            <BackBtn
              onClick={() => {
                props.setCSVUploadStep(1);
              }}
            >
              <ArrowBackIos style={{ fontSize: 16 }} />
              Back
            </BackBtn>
            <NextBtn onClick={proceedToLastStep}>Next</NextBtn>
          </ButtonGroup>
        )}
        {props.CSVUploadStep === 3 && (
          <ButtonGroup>
            <BackBtn
              onClick={() => {
                props.setCSVUploadStep(2);
              }}
            >
              <ArrowBackIos style={{ fontSize: 16 }} />
              Back
            </BackBtn>
            <NextBtn onClick={finishFileUpload}>Finish</NextBtn>
          </ButtonGroup>
        )}
        {props.CSVUploadStep === 4 && (
          <NextBtn onClick={() => props.closeAndRefresh()}>Finish</NextBtn>
        )}
      </div>
      <div
        style={{ height: 'calc(100% - 60px)', padding: '12px 24px 24px 24px' }}
      >
        {props.CSVUploadStep === 1 && (
          <>
            <h1 style={{ fontSize: '20px' }}>Upload Contacts from a File</h1>
            <br />
            <h4 style={{ fontSize: '16px' }}>
              Start by uploading the .CSV, .XLSX or .XLS file that contains the
              contacts you&apos;d like to add to NoteRouter.
            </h4>
            <p
              style={{
                fontSize: '15px',
                display: 'flex',
                alignItems: 'center',
                margin: '10px 0',
              }}
            >
              <InfoOutlinedIcon
                style={{
                  fontSize: '18px',
                  marginRight: '10px',
                  color: '#007ADE',
                }}
              />
              Note: If these contacts are already in NoteRouter and you just
              need to add them to a group, don&apos;t proceed here. Instead, go
              back and use the Grouping Tool on the Manage {'>'} Groups screen.
            </p>
            <div className={'UploadContactDrawerContainer'}>
              <ReactFileReader
                handleFiles={handleFileUpload}
                fileTypes={['.csv', '.xlsx', '.xls']}
              >
                <Button
                  className={'uploadCSVBtn'}
                  style={{
                    color: '#5EAB00',
                    backgroundColor: 'rgba(94, 171, 0, 0.05)',
                    borderColor: '#5EAB00',
                  }}
                  variant={'outlined'}
                >
                  Select File
                </Button>
              </ReactFileReader>
              <p style={{ fontSize: '15px' }}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#1890ff' }}
                  href={
                    'https://noterouter.freshdesk.com/support/solutions/articles/69000277002-upload-contacts-from-a-file'
                  }
                >
                  Learn more about uploading contacts from a file
                </a>
              </p>
            </div>
          </>
        )}
        {props.CSVUploadStep === 2 && (
          <StepTwo
            CSVFileName={CSVFileName}
            allowDuplicateContacts={allowDuplicateContacts}
            setAllowDuplicateContacts={setAllowDuplicateContacts}
            shouldImportMembersOnly={shouldImportMembersOnly}
            setIfShouldImportMembersOnly={setIfShouldImportMembersOnly}
            shouldChooseMembershipLater={shouldChooseMembershipLater}
            setShouldChooseMembershipLater={setShouldChooseMembershipLater}
            shouldChooseTopicsLater={shouldChooseTopicsLater}
            setShouldChooseTopicsLater={setShouldChooseTopicsLater}
            shouldNotChooseTopics={shouldNotChooseTopics}
            setShouldNotChooseTopics={setShouldNotChooseTopics}
            shouldChooseGroupsLater={shouldChooseGroupsLater}
            setShouldChooseGroupsLater={setShouldChooseGroupsLater}
            setSelectedTopics={setSelectedTopics}
            setSelectedGroups={setSelectedGroups}
            selectedTopics={selectedTopics}
            selectedGroups={selectedGroups}
          />
        )}
        {props.CSVUploadStep === 3 && (
          <div style={{ height: '100%' }}>
            <Row>
              <Title size="17px">Adding Data for New Contacts</Title>
              <span>
                File: <span style={{ color: '#007ADE' }}>[{CSVFileName}]</span>
              </span>
            </Row>
            <Row>
              Great! Let&apos;s get the data for your new contacts added
              correctly to NoteRouter. Below, the columns from your file are
              displayed on the left. Match these columns to fields in NoteRouter
              on the right side. At least one column needs to be matched to
              First Name, Last Name, Email, or Phone Number. No information is
              saved until you click &ldquo;Finish&ldquo;.
            </Row>
            <br />
            <div className={'CSVMatchingContainer'}>
              <div className={'CSVMatchingHeader'}>
                <span style={{ paddingLeft: '20px', width: '200px' }}>
                  CSV Column
                </span>
                <span style={{ paddingLeft: '19px', width: '230px' }}>
                  CSV Sample Value
                </span>
                <span style={{ paddingLeft: '34px', width: '250px' }}>
                  NoteRouter Field
                </span>
              </div>
              <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
                {CSVColumns.map(column => {
                  return (
                    <div
                      className={'CSVMatchingOptionBox'}
                      style={{ width: '100%', display: 'flex' }}
                      key={column.name}
                    >
                      <span style={{ paddingLeft: '20px', width: '200px' }}>
                        {column.name && column.name.length > 20
                          ? column.name.substring(0, 20) + '...'
                          : column.name}
                      </span>
                      <span style={{ paddingLeft: '20px', width: '230px' }}>
                        {column.value && column.value.length > 20
                          ? column.value.substring(0, 20) + '...'
                          : column.value}
                      </span>
                      <span style={{ fontSize: '18px', fontWeight: '500' }}>
                        =
                      </span>
                      <Select
                        value={CSVColumnMapping[column.name] || '-'}
                        style={{ paddingLeft: '20px', width: '250px' }}
                        onChange={value => {
                          updateMapping(column.name, value);
                        }}
                        filterOption={false}
                        showSearch
                        onSearch={handleSearch}
                      >
                        {ALL_FIELDS.map(({ key, value, display }) => (
                          <Option key={key} value={value}>
                            {display}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {props.CSVUploadStep === 4 && <Results results={results} />}
      </div>
      {newFieldMatchingColumn && (
        <CreateFieldModal
          addFieldToMatching={newField => {
            updateSelection(newField);
          }}
          clearModalValue={() => {
            const mapping = { ...CSVColumnMapping };
            delete mapping[newFieldMatchingColumn];
            setCSVColumnMapping(mapping);
            setNewFieldMatchingColumn('');
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actioncreators, dispatch),
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CSVUploadContact)
);
