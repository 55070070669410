import { createContext } from 'react';

export const PreferenceContext = createContext({
  member: {},
  setMember: member => {
    return member;
  },

  organization: null,
  setOrganization: organization => {
    return organization;
  },

  topics: [],
  setTopics: topics => {
    return topics;
  },

  newTextTopics: {},
  setTextTopics: topics => topics,

  newEmailTopics: {},
  setEmailTopics: topics => topics,

  loading: false,
  setLoading: loading => loading,
});
