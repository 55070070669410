import { ADD, REQUIRE, EXCLUDE } from '../Sendmessage/Audience/constants';

export const retrieveSpecificGroupsCategory = (
  selectedGroups = [],
  category
) => {
  return Object.keys(selectedGroups).filter(
    groupId => selectedGroups[groupId].action === category
  );
};

const retrieveSpecificMembersCategory = (selectedMembers = [], category) => {
  return Object.keys(selectedMembers).filter(
    memberId => selectedMembers[memberId].action === category
  );
};

export const convertAudienceDataForDraft = audience => {
  return {
    topicIds: audience.selectedTopicsList,
    selectedGroupIds: retrieveSpecificGroupsCategory(
      audience.selectedGroups,
      ADD
    ),
    requiredGroupIds: retrieveSpecificGroupsCategory(
      audience.selectedGroups,
      REQUIRE
    ),
    excludedGroupIds: retrieveSpecificGroupsCategory(
      audience.selectedGroups,
      EXCLUDE
    ),
    includedMemberIds: retrieveSpecificMembersCategory(
      audience.selectedMembers,
      ADD
    ),
    excludedMemberIds: retrieveSpecificMembersCategory(
      audience.selectedMembers,
      EXCLUDE
    ),
    filterData: audience.selectedFilters,
  };
};

export const convertAudienceDataForStandAloneAudience = (
  audience,
  organizationId
) => {
  return {
    name: audience.name,
    description: audience.description,
    ...convertAudienceDataForDraft(audience),
    ...{ organizationId },
  };
};

export const removeExtraFieldsFromStandAloneAudienceForDraft = audienceData => {
  return {
    excludedGroupIds: audienceData.excludedGroupIds,
    excludedMemberIds: audienceData.excludedMemberIds,
    filterData: audienceData.filterData,
    includedMemberIds: audienceData.includedMemberIds,
    requiredGroupIds: audienceData.requiredGroupIds,
    selectedGroupIds: audienceData.selectedGroupIds,
    topicIds: audienceData.topicIds,
  };
};
