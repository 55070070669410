import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useToast } from '../../actions/UI';
import { getOrganizationGroups } from './tags.api';
import { useSelector } from 'react-redux';

moment.locale('en');

export const useTags = () => {
  const { errorToast } = useToast();
  const organziation = useSelector(state => state.organization.selected);
  const [tags, setTags] = useState([]);

  const getAllTags = useCallback(async () => {
    try {
      const response = await getOrganizationGroups(organziation.id);
      setTags(response.data?.result.tags);
    } catch (error) {
      console.log('Error while fetching tag list', error);
      errorToast(error);
    }
  }, []);

  useEffect(() => {
    getAllTags();
  }, []);

  return { tags };
};
