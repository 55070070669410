import { Select } from 'antd';
import styled from 'styled-components';

const SelectStyled = styled(Select)`
  min-width: 150px;
  max-width: fit-content;
  background-color: inherit;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  color: #000;

  && .ant-select-selector {
    border: none;
    background-color: inherit;
  }

  .ant-select-item-option-content {
    white-space: break-spaces;
  }
`;

export default SelectStyled;
