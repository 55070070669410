export const customFonts = [
  {
    name: 'Permanent Marker',
    fontFamily: "'Permanent Marker', cursive",
    url: 'https://fonts.googleapis.com/css2?family=Permanent+Marker',
  },
  {
    name: 'Raleway',
    fontFamily: "'Raleway', sans-serif",
    url: 'https://fonts.googleapis.com/css2?family=Raleway',
  },
  {
    name: 'Noto Sans',
    fontFamily: "'Noto Sans', sans-serif",
    url: 'https://fonts.googleapis.com/css2?family=Noto+Sans',
  },
  {
    name: 'Oswald',
    fontFamily: "'Oswald', sans-serif",
    url: 'https://fonts.googleapis.com/css2?family=Oswald',
  },
  {
    name: 'Poppins',
    fontFamily: "'Poppins', sans-serif",
    url: 'https://fonts.googleapis.com/css2?family=Poppins',
  },
  {
    name: 'Merriweather',
    fontFamily: "'Merriweather', serif",
    url: 'https://fonts.googleapis.com/css2?family=Merriweather',
  },
  {
    name: 'Playfair Display',
    fontFamily: "'Playfair Display', serif",
    url: 'https://fonts.googleapis.com/css2?family=Playfair+Display',
  },
  {
    name: 'Nunito',
    fontFamily: "'Nunito', sans-serif",
    url: 'https://fonts.googleapis.com/css2?family=Nunito',
  },
  {
    name: 'Rubik',
    fontFamily: "'Rubik', sans-serif",
    url: 'https://fonts.googleapis.com/css2?family=Rubik',
  },
  {
    name: 'Lora',
    fontFamily: "'Lora', serif",
    url: 'https://fonts.googleapis.com/css2?family=Lora',
  },
  {
    name: 'Bitter',
    fontFamily: "'Bitter', serif",
    url: 'https://fonts.googleapis.com/css2?family=Bitter',
  },
  {
    name: 'Anton',
    fontFamily: "'Anton', sans-serif",
    url: 'https://fonts.googleapis.com/css2?family=Anton',
  },
  {
    name: 'Cabin',
    fontFamily: "'Cabin', sans-serif",
    url: 'https://fonts.googleapis.com/css2?family=Cabin',
  },
  {
    name: 'Dancing Script',
    fontFamily: "'Dancing Script', cursive",
    url: 'https://fonts.googleapis.com/css2?family=Dancing+Script',
  },
  {
    name: 'Antic Slab',
    fontFamily: "'Antic Slab', serif",
    url: 'https://fonts.googleapis.com/css2?family=Antic+Slab',
  },
  {
    name: 'Cairo',
    fontFamily: "'Cairo', sans-serif",
    url: 'https://fonts.googleapis.com/css2?family=Cairo',
  },
  {
    name: 'Indie Flower',
    fontFamily: "'Indie Flower', cursive",
    url: 'https://fonts.googleapis.com/css2?family=Indie+Flower',
  },
  {
    name: 'Comfortaa',
    fontFamily: "'Comfortaa', cursive",
    url: 'https://fonts.googleapis.com/css2?family=Comfortaa',
  },
  {
    name: 'Shadows Into Light',
    fontFamily: "'Shadows Into Light', cursive",
    url: 'https://fonts.googleapis.com/css2?family=Shadows+Into+Light',
  },
  {
    name: 'Play',
    fontFamily: "'Play', sans-serif",
    url: 'https://fonts.googleapis.com/css2?family=Play',
  },
  {
    name: 'Amatic SC',
    fontFamily: "'Amatic SC', cursive",
    url: 'https://fonts.googleapis.com/css2?family=Amatic+SC',
  },
];
