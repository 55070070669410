import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

export const ButtonStyled = styled.div`
  text-align: ${({ align }) => align ?? 'left'};

  & .MuiButton-root {
    padding: 4px 24px;
    border: 1px solid #007ade;
    border-radius: 16px !important;
    background-color: #007ade;
    color: #fff;

    &:disabled {
      color: #fff;
      background-color: #007adecc !important;
      border-color: transparent !important;
    }

    &:hover {
      color: #007ade !important;
      background-color: #fff !important;
    }
  }
`;

export const RoundedButton = props => {
  return (
    <ButtonStyled align={props.align}>
      <Button
        {...props}
        disabled={props.disabled || props.loading}
        onClick={props.onClick}
        className={props.className}
      >
        <span style={{ display: 'flex' }}>
          <p style={{ margin: 0 }}>{props.text}</p>
          {!!props.loading && (
            <CircularProgress
              style={{ marginLeft: '10px', marginTop: '3px' }}
              color="inherit"
              size={20}
            />
          )}
        </span>
      </Button>
    </ButtonStyled>
  );
};
