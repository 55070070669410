import { useEffect, useState } from 'react';
import { usePreviousDistinct } from 'react-use';

const useDebouncedValue = (value, timeout) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const previousValue = usePreviousDistinct(value);

  useEffect(() => {
    if (previousValue !== value) {
      const debounceTimeoutId = setTimeout(() => {
        setDebouncedValue(value);
      }, timeout);

      return () => {
        clearTimeout(debounceTimeoutId);
      };
    }
  }, [previousValue, timeout, value]);

  return debouncedValue;
};

export default useDebouncedValue;
