import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectedOrganizationSelector,
  privilegesRolesSelector,
  userDetailSelector,
} from '../../../../redux/selector';
import { getOrgUsers } from '../../../../redux/services';
import { orgUsers } from '../../../../redux/action';
import EditUserDrawer from './EditUserDrawer';
import AddUserDrawer from './AddUserDrawer';
import DeleteUserModal from './DeleteUserModal';
import { DataGrid } from './Users.styled';
import './Users.css';

const columns = [
  {
    headerName: 'First Name',
    field: 'firstName',
    minWidth: 110,
    flex: 1,
  },
  {
    headerName: 'Last Name',
    field: 'lastName',
    minWidth: 150,
    flex: 1,
  },
  {
    headerName: 'Role',
    field: 'role',
    minWidth: 150,
    flex: 1,
  },
  {
    headerName: 'Email',
    field: 'email',
    minWidth: 110,
    flex: 1,
  },
  {
    headerName: 'Phone',
    field: 'phoneNumber',
    minWidth: 125,
    flex: 0.65,
  },
];

const adminRolesHierarchy = [
  'Super Duper Admin',
  'Super Admin',
  'Super User',
  'Executive Admin',
  'Staff Admin',
  'Staff Editor',
];

const Users = ({
  setCsvUserData,
  isDeleteUserModalVisible,
  setDeleteUserModalVisibility,
  isAddUserDrawerVisible,
  setAddUserDrawerVisibility,
  isDeleteUserBtnDisabledRef,
}) => {
  const dispatch = useDispatch();
  const selectedOrg = useSelector(selectedOrganizationSelector);
  const privileges = useSelector(privilegesRolesSelector);
  const currentUser = useSelector(userDetailSelector);
  const currentUserRole = currentUser?.organizations.length
    ? privileges[
        currentUser.organizations.find(
          org => org.orgId === currentUser.organizationId
        ).roles[0]
      ]
    : '';
  const currentUserRoleIndex = adminRolesHierarchy.indexOf(currentUserRole);

  const [userlist, setUserlist] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [editUserVisibility, setEditUserVisibility] = useState(false);

  useEffect(() => {
    if (selectedUsers.length) {
      const updatedList = userlist.filter(user =>
        selectedUsers.includes(user.id)
      );
      setCsvUserData(updatedList);
      isDeleteUserBtnDisabledRef.current = false;
    } else {
      setCsvUserData(userlist);
      isDeleteUserBtnDisabledRef.current = true;
    }
  }, [selectedUsers]);

  const updateUserList = async () => {
    await getOrgUsers(selectedOrg.id, selectedOrg).then(organizationUsers => {
      const updatedUserList = organizationUsers
        .filter(user => {
          const role = user.organizations.length
            ? privileges[
                user.organizations.find(
                  org => org.orgId === user.organization.id
                ).roles[0]
              ]
            : '';
          const roleToCheckIndex = adminRolesHierarchy.indexOf(role);

          if (
            roleToCheckIndex !== -1 &&
            roleToCheckIndex < currentUserRoleIndex
          )
            return false;

          return true;
        })
        .map(user => {
          const role =
            privileges[
              user.organizations.find(org => org.orgId === user.organization.id)
                .roles[0]
            ];
          return {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            role,
            email: user.email,
            phoneNumber: user.phoneNumber,
            createdDate: user.createdDate,
            multiOrgUser: user.multiOrgUser,
            organizationId: user.organizationId,
            organizations: user.organizations,
          };
        });
      setCsvUserData(updatedUserList);
      setUserlist(updatedUserList);
      dispatch(orgUsers(organizationUsers));
    });
  };

  useEffect(() => {
    updateUserList();
  }, [selectedOrg.id]);

  const showEditUserDrawer = data => {
    setSelectedUser(data);
    setEditUserVisibility(true);
  };

  const closeEditUserDrawer = () => {
    setEditUserVisibility(false);
    setSelectedUser('');
  };

  return (
    <>
      <DataGrid
        stickyHeader
        rows={userlist}
        columns={columns}
        checkboxSelection
        sortingOrder={['asc', 'desc']}
        disableColumnMenu
        pageSize={pageSize}
        onPageSizeChange={size => setPageSize(size)}
        disableSelectionOnClick
        onSelectionModelChange={newSelectionModel => {
          setSelectedUsers(newSelectionModel);
        }}
        onRowClick={data => {
          showEditUserDrawer(data.row);
        }}
      />
      {selectedUser && (
        <EditUserDrawer
          selectedUser={selectedUser}
          isEditDetailsDrawerVisible={editUserVisibility}
          onClose={closeEditUserDrawer}
          updateUserList={updateUserList}
        />
      )}
      {isDeleteUserModalVisible && (
        <DeleteUserModal
          setDeleteUserModalVisibility={setDeleteUserModalVisibility}
          userIdsToDelete={selectedUsers}
          setSelectedUser={setSelectedUsers}
          selectedOrg={selectedOrg}
          updateUserList={updateUserList}
        />
      )}
      {isAddUserDrawerVisible && (
        <AddUserDrawer
          isAddUserDrawerVisible={isAddUserDrawerVisible}
          onClose={() => setAddUserDrawerVisibility(false)}
          updateUserList={updateUserList}
        />
      )}
    </>
  );
};

export default Users;
