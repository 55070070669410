import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Close, Help, HelpOutline } from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import { toast } from '../../../redux/action';
import {
  calculateSmsCharacterLength,
  calculateNumOfSmsTextMessages,
  calculateSmsMaxLength,
  isBlockedKeyword,
} from '../utils';
import { createNewKeywordMessage } from '../api';
import {
  Container,
  Header,
  Content,
  TextArea,
  ReplyActionArea,
  TextMetaData,
  Button,
  Input,
  Text,
  HelpLine,
  TextHelp,
  InfoContainer,
  Bold,
  ErrorMessage,
  Space,
} from './NewKeywordDrawer.styled';

const KEYWORD_MAX_LENGTH = 20;

const NewKeywordDrawer = ({ fetchData, onClose, existingKeywordsMap }) => {
  const dispatch = useDispatch();
  const [keywordResponse, setKeywordResponse] = useState('');
  const [keyword, setKeyword] = useState('');
  const [maxCharLength, setMaxCharLength] = useState(1530);
  const [numRows, setNumRows] = useState(3);
  const [isForbiddenKeyword, setIsForbiddenKeyword] = useState(false);
  const [isDuplicateKeyword, setIsDuplicateKeyword] = useState(false);

  const handleKeywordChange = event => {
    const keyword = event.target.value.toUpperCase().trim();

    if (isBlockedKeyword(keyword)) {
      setIsForbiddenKeyword(true);
    } else {
      setIsForbiddenKeyword(false);
    }

    if (existingKeywordsMap[keyword]) {
      setIsDuplicateKeyword(true);
    } else {
      setIsDuplicateKeyword(false);
    }

    if (keyword.length > KEYWORD_MAX_LENGTH) {
      setKeyword(keyword.substring(0, 30));
    } else {
      setKeyword(keyword);
    }
  };

  const handleKeywordResponseChange = event => {
    const keywordResponse = event.target.value;
    const maxLength = calculateSmsMaxLength(keywordResponse);
    setMaxCharLength(maxLength);

    if (calculateSmsCharacterLength(keywordResponse) > maxLength) {
      let endChar = maxLength;
      // in case of copy paste for example, we allow only to paste just enough keywordResponse text to reach the max limit
      while (
        calculateSmsCharacterLength(keywordResponse.substring(0, endChar)) >
        maxLength
      ) {
        endChar--;
      }
      setKeywordResponse(keywordResponse.substring(0, endChar));
    } else {
      setKeywordResponse(keywordResponse || '');
    }
  };

  const isMaxTextLengthReached = useMemo(() => {
    return calculateSmsCharacterLength(keywordResponse) >= maxCharLength;
  }, [keywordResponse, maxCharLength]);

  const textareaStyle = useMemo(() => {
    return isMaxTextLengthReached
      ? { borderColor: 'red', outline: 'none' }
      : {};
  }, [isMaxTextLengthReached]);

  useEffect(() => {
    const calculateNumRows = () => {
      const drawerWidth = window.innerWidth * 0.4;
      // the formula below is calculated through trial and error
      const calculatedNumRows = Math.floor(
        keywordResponse.length / (drawerWidth / 10)
      );

      const numRows =
        calculatedNumRows < 4
          ? 3
          : calculatedNumRows > 10
          ? 10
          : calculatedNumRows;
      setNumRows(numRows);
    };

    calculateNumRows();

    const handleResize = () => {
      calculateNumRows();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [keywordResponse]);

  const handleCreateNewKeyword = async () => {
    if (!keyword || !keywordResponse) {
      dispatch(toast('error', 'Please fill out both keyword and response.'));
      return;
    }

    if (keyword && isBlockedKeyword(keyword)) {
      dispatch(
        toast(
          'error',
          'The keyword is from the list of blocked keywords. Please use another keyword instead.'
        )
      );
      return;
    }

    if (keyword && isDuplicateKeyword) {
      dispatch(
        toast(
          'error',
          'The keyword is already in use. Please choose another keyword instead.'
        )
      );
      return;
    }

    try {
      const message = await createNewKeywordMessage({
        keyword,
        response: keywordResponse,
      });
      dispatch(toast('success', message));
      onClose();
      // refetching keyword table data
      fetchData();
    } catch (e) {
      console.log(e);
      dispatch(toast('error', 'Failed to save the keyword data.'));
    }
  };

  return (
    <Container>
      <Header>
        <span>
          <IconButton onClick={onClose}>
            <Close style={{ color: 'white' }} />
          </IconButton>
          New Keyword
        </span>
        <Button onClick={handleCreateNewKeyword}>Create</Button>
      </Header>
      <Content>
        <Text>Keyword</Text>
        <Input
          placeholder="Type your keyword here"
          maxLength={KEYWORD_MAX_LENGTH}
          value={keyword}
          onChange={handleKeywordChange}
        />
        {isForbiddenKeyword && (
          <ErrorMessage>
            For compliance reasons, we can&apos;t support the use of HELP, INFO,
            START, UNSTOP, STOP, STOPALL, END, UNSUBSCRIBE, CANCEL, or QUIT.
          </ErrorMessage>
        )}
        {isDuplicateKeyword && (
          <ErrorMessage>
            Keyword already in use - please pick a different keyword.
          </ErrorMessage>
        )}
        <Text>Automatic Reply</Text>
        <TextArea
          placeholder="Type your automatic reply here"
          rows={numRows}
          value={keywordResponse}
          onChange={handleKeywordResponseChange}
          maxLength={maxCharLength}
          style={textareaStyle}
        />
        <ReplyActionArea>
          <TextMetaData>
            <span>
              Characters: {calculateSmsCharacterLength(keywordResponse)}/
              {maxCharLength}
            </span>
            <TextHelp>
              Texts: {calculateNumOfSmsTextMessages(keywordResponse)}
              <Tooltip title="A single SMS text segment can fit 153 to 160 characters. However, special characters like emojis reduce the maximum size of a text segment to 67 characters.">
                <HelpOutline style={{ fontSize: '14px' }} />
              </Tooltip>
            </TextHelp>
          </TextMetaData>
        </ReplyActionArea>
        <HelpLine>
          <Help style={{ fontSize: '16px' }} />
          <span>How do keywords work?</span>
        </HelpLine>
        <Text>
          Pick a one-word keyword and give it an automatic reply. When a contact
          texts that keyword to your team, they’ll instantly receive your
          automatic reply.
        </Text>
        <InfoContainer>
          <Text>
            <Bold>Note:</Bold> Try to avoid using generic keywords like
            &quot;YES&quot; or &quot;OKAY.&quot; NoteRouter listens for keywords
            at all times, so if a contact sent a one-word reply that happens to
            match a custom keyword, they would be sent the auto-reply for that
            keyword.
          </Text>
          <Space />
          <Text>
            For compliance reasons, we can&apos;t support the use of HELP, INFO,
            START, UNSTOP, STOP, STOPALL, END, UNSUBSCRIBE, CANCEL, or QUIT.
          </Text>
        </InfoContainer>
      </Content>
    </Container>
  );
};

export default NewKeywordDrawer;
