import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  background-color: #ededed;
  border-radius: 5px;
  padding: 15px;
  margin: ${({ withoutPadding }) => (withoutPadding ? 0 : '0 15px 15px')};
  flex-grow: 1;
  max-height: 300px;
  overflow: auto;
`;

export const Wrap = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px 15px;
  line-height: 10px;
  font-size: 13px;
`;

export const Bold = styled.b`
  padding-left: 5px;
  padding-right: ${props => props.paddingRight || '0'};
`;

export const Underline = styled.span`
  margin: 0 10px;
  display: inline-block;
  width: 70px;
  border-bottom: 1px solid grey;
  height: 22px;
`;

export const Button = styled.button`
  font-size: 13px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  border: 1px solid #5eab00;
  padding: 5px 20px 5px 10px;
  margin: 0 6px;
  color: #5eab00;
  border-radius: 20px;
  transition: all 0.3s ease-in;
  background-color: ${props => props.backColor || '#e6eae2'};
  &:hover,
  &.active {
    color: white;
    cursor: pointer;
    background-color: #5eab00;
  }
`;

export const Highlight = styled.span`
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  border: 1px solid ${props => props.color || '#007ADE'};
  padding: ${props => props.padding || '5px 10px'};
  margin: 0 6px;
  color: ${props => props.color || '#007ADE'};
  background: ${props => props.backColor || '#E2E7EC'};
  border-radius: 20px;
`;

export const ModalBox = styled.div`
  position: absolute;
  min-width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 13px;
  font-weight: 400;
  display: inline-block;
  border: 1px solid silver;
  padding: 10px;
  background-color: #ededed;
  border-radius: 4px;
  flex: 1;
`;

export const Center = styled.div`
  text-align: center;
  padding: 7px;
`;

export const Box = styled.span`
  margin: 7px 10px 7px 7px;
  padding: 5px 10px;
  background-color: white;
  border-radius: 2px;
  text-align: left;
  flex: 1;
`;

export const ModalRow = styled.span`
  display: flex;
  flex-direction: row;
`;

export const Text = styled.span`
  padding: 13px 0 0 12px;
`;

export const AnalyticsContainer = styled.div`
  padding-top: 20px;
`;
