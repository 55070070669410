import api from '../../utils/andromeda-api';

export const getCustomFields = async (orgId, orgType) => {
  if (!orgId || !orgType) return [];
  try {
    const response = await api.get(
      `/customFields/getOrgCustomFields/${orgId}/${orgType}`
    );
    return response.data.result;
  } catch (e) {
    console.error(
      'Error fetching organization custom fields [getCustomFields]'
    );
    return { error: true };
  }
};

export const getCustomFieldsForMessageView = async (orgId, orgType) => {
  if (!orgId || !orgType) return [];
  try {
    const response = await api.get(
      `/preview/getOrgCustomFields/${orgId}/${orgType}`
    );
    return response.data.result;
  } catch (e) {
    console.error(
      'Error fetching organization custom fields [getCustomFields]'
    );
    return { error: true };
  }
};

export const getMemberCustomFieldData = async orgId => {
  if (!orgId) return [];
  try {
    const response = await api.get(
      `/customFields/orgContactCustomData/${orgId}`
    );
    return response.data.result;
  } catch (e) {
    console.error(
      'Error fetching organization custom fields [getMemberCustomFieldData]'
    );
    return { error: true };
  }
};

export const getSingleContactCustomFieldData = async (contactId, orgId) => {
  if (!contactId || !orgId) return [];
  try {
    const response = await api.get(
      `/customFields/contactCustomData/${contactId}/${orgId}`
    );
    return response.data.result;
  } catch (e) {
    console.error(
      'Error fetching contact custom fields [getSingleContactCustomFieldData]'
    );
    return { error: true };
  }
};

export const updateContactCustomFields = async customData => {
  if (!customData.noteRouterId) return;
  try {
    const response = await api.post(
      '/customFields/updateContactCustomFields',
      customData
    );
    return response.data.exportData;
  } catch (e) {
    console.error(
      'Error updating contacts custom fields [updateContactCustomFields]'
    );
    return { error: true };
  }
};

export const checkCSVUIDs = async CSVInfo => {
  if (!CSVInfo) return { error: true };
  const formData = new FormData();
  formData.append('CSVRowData', JSON.stringify(CSVInfo.CSVRowData));
  formData.append('CSV_UID_Col', CSVInfo.CSV_UID_Col);
  formData.append('file', CSVInfo.file);
  try {
    const response = await api.post('/customFields/auditCSVUpload', formData);
    return response.data;
  } catch (e) {
    console.error('Error validating CSV [checkCSVUIDs]');
    return { error: true };
  }
};

export const uploadCustomFieldCSV = async UploadInfo => {
  if (!UploadInfo) return { error: true };
  try {
    const response = await api.post('/customFields/CSVUpload', UploadInfo);
    return response.data;
  } catch (e) {
    console.error('Error validating CSV [uploadCustomFieldCSV]');
    return { error: true };
  }
};

export const formatFieldExportData = async exportData => {
  try {
    const response = await api.post(
      '/customFields/formatCSVExport',
      exportData
    );
    return response.data.exportData;
  } catch (e) {
    console.error('Error formatting CSV export data [formatFieldExportData]');
    return { error: true };
  }
};

export const deleteCustomFields = async deleteData => {
  try {
    const response = await api.post('/customFields/deleteFields', deleteData);
    return response.data.result;
  } catch (e) {
    console.error(
      'Error fetching organization custom fields [deleteCustomFields]'
    );
    return { error: true };
  }
};

export const addCustomFields = async customData => {
  try {
    const response = await api.post('/customFields/add', customData);
    return response.data.result;
  } catch (e) {
    console.error('Error adding new custom field [addCustomFields]');
    return { error: true };
  }
};

export const updateCustomFields = async customData => {
  try {
    const response = await api.post('/customFields/update', customData);
    return response.data.result;
  } catch (e) {
    console.error('Error updating custom field [updateCustomFields]');
    return { error: true };
  }
};
