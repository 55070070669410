import React, { useState } from 'react';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { userDetailSelector } from '../../../../redux/selector';
import { Tooltip } from 'antd';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { updateTemplate, getTemplateBy_id } from '../../../../redux/services';
import { toast } from '../../../../redux/action';
import { Card, CardHeader } from '../_CardUI/Card.styled';
import {
  FavoriteButton,
  EditButton,
  ArchiveButton,
  UnarchiveButton,
} from '../_CardUI/CardActionButtons.styled';
import SendIcon from '../_CardUI/SendIcon.styled';
import { formatDate, trimTextIfNeeded } from '../_utils';
import MoveToOtherOrgBtn from './UserTemplate.styled';
import MoveUserTemplateModal from './MoveUserTemplateModal';

const UserTemplateCard = ({
  template,
  archive,
  unarchive,
  onClick,
  removeTemplate,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const user = useSelector(userDetailSelector);
  const [isMoveUserTemplateModalVisible, setMoveUserTemplateModalVisibility] =
    useState(false);

  const applyTemplateToDraft = queryString.parse(location.search).apply === '1';

  const [starred, setStarred] = useState(template.starred);

  const toggleFavoriteButton = async isFavorite => {
    try {
      await updateTemplate({ ...template, starred: isFavorite });

      let text = '';
      if (isFavorite) {
        setStarred(true);
        text = 'added to';
      } else {
        setStarred(false);
        text = 'removed from';
      }

      dispatch(toast('success', `Template ${text} Favorites`));
    } catch (e) {
      dispatch(toast('error', `Failed to update template. ${e.message}`));
    }
  };

  const goToEdit = async () => {
    try {
      const templateData = await getTemplateBy_id(template._id);
      history.push(`/dashboard/sendmessage?template=${templateData._id}`);
    } catch (e) {
      dispatch(toast('error', 'Failed to fetch template'));
    }
  };

  const getActionIcons = () => {
    if (applyTemplateToDraft) return '';

    return (
      <>
        {template.archived ? (
          <>
            <CardHeader.Left />
            <CardHeader.Right>
              <UnarchiveButton onClick={unarchive} />
            </CardHeader.Right>
          </>
        ) : (
          <>
            <CardHeader.Left>
              <FavoriteButton
                as={starred ? StarFilled : StarOutlined}
                $starred={starred ? true : false}
                onClick={() => {
                  if (starred) {
                    toggleFavoriteButton(false);
                  } else {
                    toggleFavoriteButton(true);
                  }
                }}
              />
              {user.multiOrgUser && (
                <MoveToOtherOrgBtn
                  onClick={() => {
                    setMoveUserTemplateModalVisibility(true);
                  }}
                />
              )}
            </CardHeader.Left>
            <CardHeader.Right>
              <Tooltip title={`Edit: "${template.name}"`}>
                <EditButton onClick={goToEdit} />
              </Tooltip>
              <ArchiveButton onClick={archive} />
            </CardHeader.Right>
          </>
        )}
      </>
    );
  };

  return (
    <Card>
      <CardHeader>{getActionIcons()}</CardHeader>
      <Tooltip title={`Create a new Draft with Template: "${template.name}"`}>
        <Card.Body onClick={onClick}>
          <SendIcon />
          <Card.Date>{formatDate(template.saveTime)}</Card.Date>
          <Card.Name>{trimTextIfNeeded(template.name, 40)}</Card.Name>
        </Card.Body>
      </Tooltip>
      {isMoveUserTemplateModalVisible && (
        <MoveUserTemplateModal
          isOpen={isMoveUserTemplateModalVisible}
          setOpen={setMoveUserTemplateModalVisibility}
          template={template}
          removeTemplate={removeTemplate}
        />
      )}
    </Card>
  );
};

export default UserTemplateCard;
