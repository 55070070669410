import React from 'react';
import { Card } from '../_CardUI/Card.styled';
import { Title, AddIcon } from './NewCard.styled';

const NewCard = ({ onClick, title }) => {
  return (
    <Card onClick={onClick}>
      <Card.Body>
        <AddIcon />
        <Title>{title}</Title>
      </Card.Body>
    </Card>
  );
};

export default NewCard;
