import React, { useState, useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { AddCircleOutline, CancelOutlined } from '@material-ui/icons';
import moment from 'moment';
import SelectDropDown from '../_shared/selectDropdown';
import Container from '../_shared/container';
import Text from '../_shared/text';
import Wrap from '../_shared/wrap';
import Bold from '../_shared/bold';
import Content from '../../../_shared/content';
import FitContent from '../_shared/fitContent';
import Space from '../_shared/space';
import SimpleText from './Components/simpleText.styled';
import {
  BETWEEN_DATE,
  comparisonDateValues,
  NEVER,
  NO_DATE_GIVEN,
} from '../_shared/constants';
import {
  addFilter,
  removeFilter,
  toast,
} from '../../../../../../../../../redux/action';

const DateFilter = ({
  filterName,
  displayMembership,
  memberships,
  checked,
  selectedFilters,
  orgTypeName,
}) => {
  const dispatch = useDispatch();
  const [selectedMembership, setMembership] = useState('');

  const dateComparator = useMemo(
    () => comparisonDateValues(filterName),
    [filterName]
  );

  const [comparisonOperator, setComparisonOperator] = useState(
    dateComparator[0]
  );
  const [date, setDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [filterDates, setFilterDates] = useState(selectedFilters || []);

  useEffect(() => {
    if (filterDates.length) {
      dispatch(addFilter({ [filterName]: filterDates }));
    } else if (
      filterDates.length === 0 &&
      selectedFilters &&
      selectedFilters.length !== 0
    ) {
      dispatch(removeFilter(filterName));
    }
  }, [filterDates]);

  useEffect(() => {
    if (!checked && filterDates.length) {
      dispatch(removeFilter(filterName));
    }
  }, [checked]);

  const handleComparisonOperatorChange = ({ target }) => {
    setComparisonOperator(target.value);
  };

  const handleDateChange = datePicked => {
    const date = moment(datePicked);
    if (date.isValid()) {
      setDate(new Date(datePicked));
    } else {
      setDate(null);
    }
  };

  const handleMaxDateChange = maxDatePicked => {
    const date = moment(maxDatePicked);
    if (date.isValid()) {
      setMaxDate(new Date(maxDatePicked));
    } else {
      setMaxDate(null);
    }
  };

  const handleMembershipChange = ({ target }) => {
    setMembership(target.value);
  };

  const handleAddFilterDates = () => {
    const errorMessage = [];
    if (displayMembership && !selectedMembership) {
      errorMessage.push('Please select a membership.');
    }
    if (!comparisonOperator) {
      errorMessage.push('Please select comparison operator.');
    }
    if (
      !date &&
      comparisonOperator !== NEVER &&
      comparisonOperator !== NO_DATE_GIVEN
    ) {
      errorMessage.push('Please select a valid date.');
    }
    if (comparisonOperator === BETWEEN_DATE) {
      if (!maxDate) {
        errorMessage.push('Please add a valid end date.');
      }
      if (date && date >= maxDate) {
        errorMessage.push('End date should be higher than start date.');
      }
    }

    if (errorMessage.length) {
      dispatch(toast('error', errorMessage.join('\n')));
    } else {
      if (comparisonOperator === NEVER) {
        setFilterDates([
          {
            comparisonOperator,
            date,
            maxDate,
            ...(displayMembership && { selectedMembership }),
          },
        ]);
      } else {
        const newFilteredDates = filterDates.filter(
          filterDate => filterDate.comparisonOperator !== NEVER
        );
        newFilteredDates.push({
          comparisonOperator,
          date,
          maxDate,
          ...(displayMembership && { selectedMembership }),
        });
        setFilterDates(newFilteredDates);
      }
      setComparisonOperator(dateComparator[0]);
      setDate(null);
      setMaxDate(null);
      setMembership('');
    }
  };

  const handleRemoveFilterDates = index => {
    setFilterDates([
      ...filterDates.slice(0, index),
      ...filterDates.slice(index + 1),
    ]);
  };

  const renderDatePicker = (date, handleDateChange) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        style={{ width: '150px', top: '-4px' }}
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        value={date}
        disabled={comparisonOperator === NEVER}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        openTo={'year'}
        views={['year', 'month', 'date']}
        autoOk={true}
      />
    </MuiPickersUtilsProvider>
  );

  const getMembershipName = () => {
    return orgTypeName === 'magic' ? 'Association' : 'Membership';
  };

  return (
    <Container>
      {(selectedFilters || []).map(
        ({ comparisonOperator, date, maxDate, selectedMembership }, index) => (
          <Wrap key={index}>
            <FitContent>
              {index > 0 ? <Space>or</Space> : <Space>-</Space>}
              {comparisonOperator === NEVER ||
              comparisonOperator === NO_DATE_GIVEN ? (
                <Bold>{comparisonOperator}</Bold>
              ) : (
                comparisonOperator
              )}
            </FitContent>
            {comparisonOperator !== NEVER &&
              comparisonOperator !== NO_DATE_GIVEN && (
                <Bold>
                  {moment(date).format('MM/DD/YYYY')}{' '}
                  {maxDate
                    ? ` and ${moment(maxDate).format('MM/DD/YYYY')}`
                    : ''}
                </Bold>
              )}
            {selectedMembership ? (
              <>
                <FitContent> plus {getMembershipName()} </FitContent>
                <Bold>{selectedMembership}</Bold>
              </>
            ) : (
              ''
            )}
            <CancelOutlined
              fontSize="small"
              style={{ fill: '#E63946' }}
              onClick={() => handleRemoveFilterDates(index)}
            />
          </Wrap>
        )
      )}
      <Content>
        {filterDates.length ? (
          <Text>or</Text>
        ) : (
          <span style={{ width: '25px' }} />
        )}
        {displayMembership && (
          <>
            <SimpleText>{getMembershipName()}</SimpleText>
            <SelectDropDown
              value={selectedMembership}
              menuItemNames={memberships}
              onChange={handleMembershipChange}
            />
            <Space />
          </>
        )}
        <SelectDropDown
          value={comparisonOperator}
          menuItemNames={dateComparator}
          onChange={handleComparisonOperatorChange}
        />
        <Space />
        {comparisonOperator !== 'No date given' &&
          renderDatePicker(date, handleDateChange)}
        {comparisonOperator === 'Between' && (
          <>
            <Text>and</Text>
            {renderDatePicker(maxDate, handleMaxDateChange)}
          </>
        )}
      </Content>
      <Content>
        <Button
          variant="contained"
          size="small"
          startIcon={<AddCircleOutline />}
          style={{
            borderRadius: 50,
            backgroundColor: '#007ADE',
            margin: '0 25px',
            padding: '5px 15px',
            color: 'white',
          }}
          onClick={handleAddFilterDates}
        >
          Add
        </Button>
      </Content>
    </Container>
  );
};

const mapStateToProps = state => ({
  memberships: [
    ...(state.organization.selected.associationIDs || []),
    ...(state.organization.selected.extraAssocIDs || []),
  ],
  orgTypeName: state.audience.orgTypeName,
});

export default connect(mapStateToProps)(DateFilter);
