import { Drawer } from '@material-ui/core';
import styled from 'styled-components';

export const DrawerStyled = styled(Drawer)`
  .MuiDrawer-paperAnchorRight {
    width: 100%;
    @media screen and (min-width: 768px) {
      width: 720px;
    }
  }
`;

export const DrawerBody = styled.div`
  ${({ fixed }) => fixed && 'margin-top: 60px;'}
  height: 100%;
`;
