import React, { useMemo, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDebouncedValue, useToggle } from '../../../libs/react-use-extra';
import { useFullLoader, useToast } from '../../../redux/actions/UI';
import { useArchiveCampaignsMutation, useCampaignsQuery } from '../queries';
import CampaignView from '../CampaignView';
import CampaignsTable from '../CampaignsTable';
import {
  ArchiveButton,
  ArchiveList,
  CreateButton,
  Header,
  SearchInput,
  Tab,
  TableWrapper,
} from './CampaignsIndex.styled';
import ConfirmationDialog from '../ConfirmationDialog';
import StatusFilter from './StatusFilter';
import Campaign from '../domain/campaign';

const INITIAL_PAGINATION = {
  current: 1,
  size: CampaignsTable.DEFAULT_PAGE_SIZE,
};

const SEARCH_DEBOUNCE = 500;

const INITIAL_STATUSES_FILTER = ['active', 'paused'];

const DEFAULT_SORTING = {
  field: 'updatedAt',
  direction: 'desc',
};

const CampaignsIndex = () => {
  const { errorToast } = useToast();
  const fullLoader = useFullLoader();

  const [statuses, setStatuses] = useState(INITIAL_STATUSES_FILTER);

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebouncedValue(search, SEARCH_DEBOUNCE);
  const [pagination, setPagination] = useState(INITIAL_PAGINATION);
  const [sortBy, setSortBy] = useState(DEFAULT_SORTING);
  const [total, setTotal] = useState(0);

  const [openedCampaignId, setOpenedCampaignId] = useState(null);
  const [selectedCampaignIds, setSelectedCampaignIds] = useState([]);
  const [
    isArchiveConfirmationOpen,
    { on: openArchiveConfirmation, off: closeArchiveConfirmation },
  ] = useToggle();

  const haveVisibleStatuses = statuses.length > 0;
  const { data, isLoading, error } = useCampaignsQuery(
    {
      page: pagination.current,
      pageSize: pagination.size,
      sortBy: sortBy?.field,
      sortDirection: sortBy?.direction,
      search: debouncedSearch,
      statuses,
    },
    {
      enabled: haveVisibleStatuses,
      onSuccess(data) {
        setTotal(data.total);
      },
      onError() {
        errorToast('Failed to load campaigns. Please, try again later.');
      },
    }
  );

  const selectedCampaigns = useMemo(() => {
    if (!data) {
      return [];
    }

    return selectedCampaignIds.map(id =>
      data.campaigns.find(campaign => campaign._id === id)
    );
  }, [data, selectedCampaignIds]);

  const { mutateAsync } = useArchiveCampaignsMutation();

  const rows = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.campaigns.map(campaign => {
      return {
        id: campaign._id,
        name: campaign.name,
        description: campaign.description,
        openRate: Campaign.calculateAggregateOpenRate(campaign.stages),
        clickRate: Campaign.calculateAggregateClickRate(campaign.stages),
        status: campaign.status,
        updatedAt: campaign.updatedAt,
      };
    });
  }, [data]);

  const handleChange = ({ pagination, sortBy }) => {
    setPagination(pagination);
    setSortBy(previousSortBy => {
      if (sortBy) {
        if (typeof sortBy === 'function') {
          return sortBy(previousSortBy);
        } else {
          return sortBy;
        }
      }

      if (previousSortBy.field === 'updatedAt') {
        return {
          field: 'updatedAt',
          direction: previousSortBy.direction === 'asc' ? 'desc' : 'asc',
        };
      }

      return DEFAULT_SORTING;
    });
  };

  const handleSearch = event => {
    setSearch(event.target.value);
    setTotal(0);
  };

  const handleStatusChange = newStatuses => {
    setStatuses(newStatuses);
    setTotal(0);
  };

  const closeCampaign = () => {
    setOpenedCampaignId(null);
  };

  const hasNoSelectedCampaigns = selectedCampaignIds.length === 0;

  const archiveSelectedCampaigns = async () => {
    await fullLoader.during('Archiving selected campaigns...', () =>
      mutateAsync(selectedCampaignIds)
    );
    setSelectedCampaignIds([]);
  };

  const route = useRouteMatch();

  return (
    <>
      <Header>
        <Header.Left>
          <Tab>Campaigns</Tab>
        </Header.Left>
        <Header.Right>
          <ArchiveButton
            onClick={openArchiveConfirmation}
            disabled={hasNoSelectedCampaigns}
          >
            Archive
          </ArchiveButton>
          {isArchiveConfirmationOpen && (
            <ConfirmationDialog
              message={
                <>
                  <span>
                    Are you sure you want to archive all these campaigns?
                  </span>
                  <ArchiveList>
                    {selectedCampaigns.map(campaign => (
                      <ArchiveList.Item key={campaign._id}>
                        {campaign.name}
                      </ArchiveList.Item>
                    ))}
                  </ArchiveList>
                </>
              }
              onConfirm={archiveSelectedCampaigns}
              onClose={closeArchiveConfirmation}
            />
          )}
          <CreateButton to={`${route.url}/new`} component={Link}>
            New
          </CreateButton>
        </Header.Right>
      </Header>
      <TableWrapper>
        <TableWrapper.Toolbar>
          <StatusFilter value={statuses} onChange={handleStatusChange} />
          <SearchInput
            value={search}
            onChange={handleSearch}
            placeholder="Search"
          />
        </TableWrapper.Toolbar>
        <TableWrapper.Content>
          <CampaignsTable
            data={haveVisibleStatuses ? rows : []}
            total={haveVisibleStatuses ? total : 0}
            pagination={pagination}
            onChange={handleChange}
            isLoading={isLoading}
            error={error}
            sortBy={sortBy}
            onCampaignClick={setOpenedCampaignId}
            selected={selectedCampaignIds}
            onSelectionChange={setSelectedCampaignIds}
            noResultsText={
              haveVisibleStatuses
                ? 'No results'
                : 'You have not selected any status in the filter'
            }
          />
        </TableWrapper.Content>
      </TableWrapper>
      {openedCampaignId && (
        <CampaignView id={openedCampaignId} onClose={closeCampaign} />
      )}
    </>
  );
};

export default CampaignsIndex;
