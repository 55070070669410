import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 30px 50px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`;

export const FullLengthContainer = styled.div`
  padding-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ActiveAudience = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  line-height: 1;
`;

ActiveAudience.Label = styled.span`
  font-size: 13px;
  font-weight: 700;
`;

ActiveAudience.Name = styled.span`
  font-size: 15px;
`;

ActiveAudience.EditButton = styled.button`
  display: flex;
  align-items: center;
  gap: 3px;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #007ade;
  transition: 250ms color ease-in;
  font-size: 15px;
  font-weight: 600;

  &:hover {
    color: #005ca6;
    transition-timing-function: ease-out;
  }
`;
