import React from 'react';
import { _defaults, _identity } from 'underscore-es';

import Alignment from './Alignment';
import { Body, Cell, Container, Footer, Header, Row } from './Table.styled';
import { sum } from '../../../../libs/js-utils';

const Table = ({ columns, rows, getRowKey }) => {
  columns = columns.map(column => {
    return _defaults(column, {
      title: null,
      renderCell: _identity,
      hasFooterSeparator: false,
      alignment: Alignment.LEFT,
      span: 1,
    });
  });

  const spans = columns.map(column => column.span);
  const totalSpan = sum(spans);

  return (
    <Container totalSpan={totalSpan}>
      <Header>
        {columns.map(column => (
          <Header.Cell
            key={column.key}
            alignment={column.alignment}
            span={column.span}
          >
            {column.title}
          </Header.Cell>
        ))}
      </Header>
      <Body>
        {rows.map(row => (
          <Row key={getRowKey(row)}>
            {columns.map(column => (
              <Cell
                key={column.key}
                alignment={column.alignment}
                span={column.span}
              >
                {column.renderCell(column.getValue(row))}
              </Cell>
            ))}
          </Row>
        ))}
      </Body>
      <Footer>
        {columns.map(column => {
          const columnValues = rows.map(row => column.getValue(row));
          return (
            <Footer.Cell
              key={column.key}
              hasSeparator={column.hasFooterSeparator}
              alignment={column.alignment}
              span={column.span}
            >
              {column.renderFooter(columnValues, rows)}
            </Footer.Cell>
          );
        })}
      </Footer>
    </Container>
  );
};

Table.Alignment = Alignment;

export default Table;
