import api from '../../utils/andromeda-api';

export async function csvImportMembers(memberInfo, csvFile) {
  try {
    const formData = new FormData();

    const data = Object.keys(memberInfo);

    for (const key of data) {
      formData.append(key, JSON.stringify(memberInfo[key]));
    }
    formData.append('file', csvFile);
    const response = await api.post('/csv/v2/uploadContacts', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (e) {
    console.error('Error adding members via csv [csvImportMembers]');
    return { error: true };
  }
}
