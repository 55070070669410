import styled from 'styled-components';
import Info from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';

const StyledInfoIcon = withStyles({
  root: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#007ade',
  },
})(Info);

export const InfoIcon = styled(StyledInfoIcon)``;

export const Container = styled.div`
  width: 100%;
  min-width: 650px;
  background-color: #ededed;
  border-radius: 5px;
  height: calc((100vh - 90px) * 1 / 3);
  padding: 15px;
  overflow: hidden;
`;

export const Menu = styled.div`
  display: flex;
  gap: 8px;
  padding-bottom: 5px;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0px;
  gap: 10px;
  max-height: 90%;
  overflow: auto;
`;

export const MenuItem = styled.button`
  color: #007ade;
  font-size: 14px;
  min-width: 120px;
  border: 1px solid #d4d7d9;
  border-radius: 4px;
  height: 28px;
  transition: all 0.3s ease-in;
  background-color: #ffffff;
  &:hover,
  &.active {
    color: white;
    cursor: pointer;
    background-color: #007ade;
    border: 1px solid #2087e1;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  color: #707070;
  align-items: center;
`;

export const InfoText = styled.span`
  font-size: 12px;
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
  padding: 0 10px;
  line-height: 14px;
`;
