import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import baseInputStyles from './baseInputStyles';
import notchlessStyles from './notchlessStyles';

const TextInput = styled(TextField).attrs({ variant: 'outlined' })`
  > .MuiInputBase-root {
    ${baseInputStyles}
    ${notchlessStyles}

    &.Mui-disabled {
      color: #6c7075;
    }
  }
`;

export default TextInput;
