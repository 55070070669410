import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const ButtonStyled = styled(Button)`
  && {
    border-radius: 50px;
    background-color: #007ade;
    margin: 0 25px;
    padding: 5px 15px;
    color: white;

    &:disabled {
      color: #fff;
      background-color: #007adecc !important;
      border-color: transparent !important;
    }
  }
`;

export default ButtonStyled;
