import React, { useState } from 'react';
import * as moment from 'moment';
import { convertToCSVFormat } from '../../../utils';
import Card from './Card';
import FailedDeliveryReport from './FailedDeliveryReport';
import SpamReport from './SpamReport';

const Email = ({
  emailEvents,
  errorLogs,
  generateRatio,
  isLoadingData,
  message,
  startDate,
  spamReport,
  isDataAvailable,
  setClickActivityVisibility,
  isSmartResendAvailable,
  smartResendEmailEvents,
}) => {
  const [isFailedDeliveryDrawerVisible, setFailedDeliveryDrawerVisibility] =
    useState(false);
  const [isSpamDrawerVisible, setSpamDrawerVisibility] = useState(false);
  const [data, setData] = useState([]);
  const [csvData, setCSVData] = useState([]);
  const [failedDeliveryReportTitle, setFailedDeliveryReportTitle] =
    useState('');

  const handleEmailBounceRateClick = () => {
    const data = message
      ? // if a message is selected, use emailEvents bounce events
        emailEvents.bounceEvents.map(bounce => ({
          ...bounce,
          time: moment.unix(bounce.time).format('MM-DD-YYYY hh:mm A'),
          contact: bounce.email,
        }))
      : // else, get bounce events from errorlogs
        errorLogs.filter(
          log => log.contact?.includes('@') && log.eventName === 'bounce'
        );

    setData(data);
    setCSVData(convertToCSVFormat(data));
    setFailedDeliveryDrawerVisibility(true);
    setFailedDeliveryReportTitle('Bounce');
  };

  const handleEmailDropRateClick = () => {
    const data = message
      ? // if a message is selected, use emailEvents dropped events
        emailEvents.droppedEvents.map(dropped => ({
          ...dropped,
          time: moment.unix(dropped.time).format('MM-DD-YYYY hh:mm A'),
          contact: dropped.email,
        }))
      : // else, get dropped events from errorlogs
        errorLogs.filter(
          log => log.contact?.includes('@') && log.eventName === 'dropped'
        );

    setData(data);
    setCSVData(convertToCSVFormat(data));
    setFailedDeliveryDrawerVisibility(true);
    setFailedDeliveryReportTitle('Dropped Emails');
  };

  const handleSpamRateClick = () => {
    const data = message
      ? // if a message is selected, use emailEvents spam events
        emailEvents.spamEvents.map(bounce => ({
          ...bounce,
          time: moment.unix(bounce.time).format('MM-DD-YYYY hh:mm A'),
          contact: bounce.email,
        }))
      : // else, get spam events from errorlogs
        errorLogs.filter(
          errorData => errorData.eventName?.toLowerCase() === 'spamreport'
        );

    setData(data);
    setCSVData(convertToCSVFormat(data));
    setSpamDrawerVisibility(true);
  };

  const showClickActivity = () => {
    setClickActivityVisibility(true);
  };
  const calcSmartResendClickThroughRate = () => {
    const oldRatio = generateRatio(
      emailEvents.uniqueclick,
      emailEvents.uniqueopen
    );

    const newRatio = generateRatio(
      emailEvents.uniqueclick + smartResendEmailEvents.uniqueclick,
      emailEvents.uniqueopen + smartResendEmailEvents.uniqueopen
    );

    return parseInt(newRatio) - parseInt(oldRatio) + '%';
  };

  return (
    <>
      <Card
        name="Deliveries"
        value={emailEvents.delivered}
        smartResendValue={
          smartResendEmailEvents?.delivered
            ? smartResendEmailEvents.delivered
            : null
        }
        title="The number of unique contacts that successfully received their email. If multiple contacts have the same email address, only one of them will be emailed."
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
      />
      <Card
        name="Delivery Rate"
        value={generateRatio(emailEvents.delivered, emailEvents.sent)}
        smartResendValue={
          smartResendEmailEvents?.delivered || smartResendEmailEvents?.sent
            ? generateRatio(
                smartResendEmailEvents.delivered,
                smartResendEmailEvents.sent
              )
            : null
        }
        title="Contacts who were successfully delivered to."
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
      />
      <Card
        name="Open Rate"
        value={generateRatio(
          emailEvents.uniqueopen + (smartResendEmailEvents?.uniqueopen || 0),
          emailEvents.delivered
        )}
        smartResendValue={
          smartResendEmailEvents?.uniqueopen ||
          smartResendEmailEvents?.delivered
            ? generateRatio(
                smartResendEmailEvents.uniqueopen,
                emailEvents.delivered
              )
            : null
        }
        title="The number of contacts who opened your email at least
        once. (It's a great indicator of subject line quality.)"
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
        showComparativeAnalytics
      />
      <Card
        name="Click-Through Rate"
        value={generateRatio(
          emailEvents.uniqueclick + (smartResendEmailEvents?.uniqueclick || 0),
          emailEvents.uniqueopen + (smartResendEmailEvents?.uniqueopen || 0)
        )}
        smartResendValue={
          smartResendEmailEvents?.uniqueclick ||
          smartResendEmailEvents?.uniqueopen
            ? calcSmartResendClickThroughRate()
            : null
        }
        title="The number of contacts who clicked a link in your email after opening it. (It's a great indicator of content quality.)"
        isDataAvailable={isDataAvailable}
        onClick={setClickActivityVisibility ? showClickActivity : null}
        isSmartResendAvailable={isSmartResendAvailable}
        showComparativeAnalytics
      />
      <Card
        name="Bounce Rate"
        value={generateRatio(emailEvents.bounce, emailEvents.sent)}
        smartResendValue={
          smartResendEmailEvents?.bounce || smartResendEmailEvents?.sent
            ? generateRatio(
                smartResendEmailEvents.bounce,
                smartResendEmailEvents.sent
              )
            : null
        }
        title="Usually a result of sending to a bad email address."
        onClick={handleEmailBounceRateClick}
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
      />
      <Card
        name="Drop Rate"
        value={generateRatio(emailEvents.dropped, emailEvents.sent)}
        smartResendValue={
          smartResendEmailEvents?.dropped || smartResendEmailEvents?.sent
            ? generateRatio(
                smartResendEmailEvents.dropped,
                smartResendEmailEvents.sent
              )
            : null
        }
        title='"Dropped" means that an email was not sent because of an unresolved "Bounce" or "Spam Report" issue for that contact.'
        onClick={handleEmailDropRateClick}
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
      />
      <Card
        name="Spam Report Rate"
        value={
          !message
            ? generateRatio(spamReport, emailEvents.sent)
            : generateRatio(emailEvents.spamReport, emailEvents.sent)
        }
        smartResendValue={
          smartResendEmailEvents?.spamReport || smartResendEmailEvents?.sent
            ? generateRatio(
                smartResendEmailEvents.spamReport,
                smartResendEmailEvents.sent
              )
            : null
        }
        title="The number of contacts who reported your message as spam."
        onClick={handleSpamRateClick}
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
      />
      <FailedDeliveryReport
        onCloseDrawer={() => setFailedDeliveryDrawerVisibility(false)}
        isVisible={isFailedDeliveryDrawerVisible}
        isLoading={isLoadingData}
        message={message}
        startDate={startDate}
        data={data}
        csvData={csvData}
        title={failedDeliveryReportTitle}
      />
      <SpamReport
        onCloseDrawer={() => setSpamDrawerVisibility(false)}
        isVisible={isSpamDrawerVisible}
        isLoading={isLoadingData}
        message={message}
        startDate={startDate}
        data={data}
        csvData={csvData}
      />
    </>
  );
};

export default Email;
