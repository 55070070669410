import React, { memo, useContext } from 'react';
import { PreferenceContext } from '../../../redux/services.js';
import { MissingPhoneStyled } from './missingPhone.styled.js';

export const MissingPhone = memo(function MissingPhoneComponent() {
  const { organization, member } = useContext(PreferenceContext);
  const isMemberAcronym = member.organization?.attributes?.find(
    attr => attr.name === 'memberPortalUsername'
  )?.value;

  const { memberPortalURL, acronym, organizationName, displayPhone } =
    organization;

  const videoGuide = 'https://youtu.be/3t59sQfajXk';

  if (member?.phoneNumber?.length) return;

  return (
    <MissingPhoneStyled>
      No cell number found. If you would like critical information sent to you
      via text message,{' '}
      {memberPortalURL?.trim() ? (
        <>
          add a cell number to your profile here:{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="color-blue"
            href={`https://${memberPortalURL}`}
          >
            {memberPortalURL}
          </a>
          {isMemberAcronym && (
            <span>
              <br /> <i> (Hint: Your username is </i>{' '}
              <span className="color-blue">{isMemberAcronym}</span>)
            </span>
          )}
          {acronym === 'MetroTex' && (
            <span>
              <br />
              Helpful video guide:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="color-blue"
                href={videoGuide}
              >
                {videoGuide}
              </a>
            </span>
          )}
        </>
      ) : (
        <>
          add a cell number to your profile by contacting{' '}
          <b>{organizationName}</b> at <b>{displayPhone}.</b> Please allow up to
          24 hours for updates to reflect here.
        </>
      )}
    </MissingPhoneStyled>
  );
});
