import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Dialog, DialogContent } from '@material-ui/core';
import DialogTitle from './dialogComponents/dialogTitle.styled';
import DialogActions from './dialogComponents/dialogActions.styled';
import SoldiButton from './solidBtn';
import {
  saveNewStandAloneAuidenceAction,
  toast,
} from '../../../../../../redux/action';
import {
  audienceSelector,
  selectedOrganizationSelector,
} from '../../../../../../redux/selector';

function SaveCopyAudienceDialog({ isOpen, close }) {
  const dispatch = useDispatch();
  const organizationId = useSelector(selectedOrganizationSelector).id;
  const audience = useSelector(audienceSelector);

  const [name, setName] = useState('');
  const [error, setError] = useState(false);
  const [description, setDescription] = useState('');

  const handleNameChange = ({ target }) => {
    setName(target.value);
  };

  const handleDescriptionChange = ({ target }) => {
    setDescription(target.value);
  };

  const handleSaveDialog = () => {
    if (!name) {
      return setError(true);
    }
    setError(false);
    const newAudienceCopy = { ...audience };
    delete newAudienceCopy._id;

    dispatch(
      saveNewStandAloneAuidenceAction(
        {
          ...newAudienceCopy,
          name,
          description,
        },
        organizationId
      )
    );

    dispatch(toast('success', 'Successfuly saved a copy of audience.'));
    closeDialogBox();
  };

  const closeDialogBox = () => {
    close();
    setName('');
    setDescription('');
  };

  return (
    <>
      <Dialog open={isOpen} onClose={closeDialogBox}>
        <DialogTitle>Create New Saved Audience</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            inputProps={{ style: { fontSize: 13 } }}
            id="name"
            label="Audience Name"
            value={name}
            fullWidth
            required
            error={!name && error === true}
            helperText={!name && error && 'Please fill in audience name.'}
            onChange={handleNameChange}
          />
          <TextField
            margin="dense"
            inputProps={{ style: { fontSize: 13 } }}
            id="description"
            label="Audience Description"
            value={description}
            multiline
            rows={4}
            fullWidth
            onChange={handleDescriptionChange}
          />
        </DialogContent>
        <DialogActions>
          <SoldiButton onClick={closeDialogBox} color="grey">
            Close
          </SoldiButton>
          <SoldiButton onClick={handleSaveDialog}>Save</SoldiButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SaveCopyAudienceDialog;
