import './registration.css';

import { Button, FormField, Grommet, TextInput } from 'grommet';
import { grommet } from 'grommet/themes';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as actioncreators from '../../redux/action';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
    };
    this.updateInput = this.updateInput.bind(this);
    this.addUser = this.addUser.bind(this);
  }

  updateInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  // Adding user by the giving data
  addUser(e) {
    e.preventDefault();
    let data = {
      email: this.state.email,
      password: this.state.password,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phoneNumber: this.state.phoneNumber,
    };

    console.log(data);
    this.props.actions.register(data, this.props.history); //CREATE NEW PROJECT
  }

  render() {
    return (
      <Grommet theme={grommet}>
        <FormField
          htmlFor="text-input"
          onsubmit={e => {
            this.addUser(e);
          }}
        >
          <TextInput
            type="text"
            name="firstName"
            placeholder="First Name"
            onChange={this.updateInput}
            value={this.state.firstName}
          />
          <TextInput
            type="text"
            name="lastName"
            placeholder="Last Name"
            onChange={this.updateInput}
            value={this.state.lastName}
          />
          <TextInput
            type="Number"
            name="phoneNumber"
            placeholder="Phone Number"
            onChange={this.updateInput}
            value={this.state.phoneNumber}
          />
          <TextInput
            type="email"
            name="email"
            placeholder="email"
            onChange={this.updateInput}
            value={this.state.email}
          />
          <TextInput
            type="password"
            name="password"
            placeholder="password"
            onChange={this.updateInput}
            value={this.state.password}
          />
          <Button
            label="Submit"
            type="submit"
            onClick={e => {
              this.addUser(e);
            }}
          />
        </FormField>
      </Grommet>
    );
  }
}
const mapStateToProps = state => ({
  ...state,
});
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actioncreators, dispatch),
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Register)
);
