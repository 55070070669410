import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import { useIsFeatureEnabled } from '../../../../../libs/feature-toggles/react';
import Feature from '../../../../../infrastructure/features';
import {
  deleteVersionBDraft,
  getAVersionId,
} from '../../../../../redux/services';
import { toast } from '../../../../../redux/action';
import {
  Container,
  ToggleButtonLeft,
  ToggleButtonRight,
  ToggleButtonGroup,
  Link,
} from './ABToggleButton.styled';
import ConfirmDeleteABDraftModal from './ConfirmDeleteABDraftModal';

const VERSION_A = 'A';
const VERSION_B = 'B';
const PRIMARY = 'PRIMARY';

const ABToggleButton = ({
  AB,
  draftId,
  isSaving,
  isDesignLoading,
  isABToggleEnabled,
  setABToggleEnabled,
  isConfirmDeleteABDraftModalVisible,
  setConfirmDeleteABDraftModalVisible,
  handleVersionChange,
}) => {
  const isABFeatureEnabled = useIsFeatureEnabled(Feature.AB);
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeVersion, setActiveVersion] = useState(AB && AB.version);

  useEffect(() => {
    setTimeout(() => {
      setActiveVersion(AB && AB.version);
    }, 1000);
  }, [AB]);

  useEffect(() => {
    if (!isABToggleEnabled) {
      showDeleteConfirmationModal();
    }
  }, [isABToggleEnabled]);

  const deleteBVersion = async () => {
    try {
      setABToggleEnabled(false);
      hideDeleteConfirmationModal();
      const { _id } = await getAVersionId(draftId);
      await deleteVersionBDraft(draftId);
      dispatch(toast('success', 'Deleted B version of the draft'));
      window.open(`/dashboard/sendmessage?draft=${_id}`, '_self');
      setActiveVersion(null);
    } catch (e) {
      console.error(e);
      dispatch(toast('error', 'Error deleting B version of the draft'));
      history.replace('/dashboard/drafts');
    }
  };

  const showDeleteConfirmationModal = () => {
    setConfirmDeleteABDraftModalVisible(true);
  };

  const hideDeleteConfirmationModal = () => {
    setConfirmDeleteABDraftModalVisible(false);
  };

  const getVersion = version => {
    if (activeVersion) {
      return activeVersion === version && PRIMARY;
    } else {
      return version === VERSION_A && PRIMARY;
    }
  };

  const isDisabled = isSaving || isDesignLoading;
  const isActive = activeVersion && !isDisabled;

  return (
    <>
      {isABFeatureEnabled && (
        <Container>
          <Tooltip title="Learn about A/B Mode">
            <Link
              href="https://noterouter.freshdesk.com/en/support/solutions/articles/69000837580-how-to-a-b-messaging"
              target="_blank"
              rel="noopener noreferrer"
              onClick={e => e.stopPropagation()}
            >
              <HelpIcon style={{ fontSize: '18px' }} />
            </Link>
          </Tooltip>
          <ToggleButtonGroup>
            <ToggleButtonLeft
              toggleEnabled={isActive}
              version={getVersion(VERSION_A)}
              aria-label="A"
              onClick={() => handleVersionChange(VERSION_A)}
              disabled={isDisabled}
            >
              A
            </ToggleButtonLeft>
            <ToggleButtonRight
              toggleEnabled={isActive}
              version={getVersion(VERSION_B)}
              aria-label="B"
              onClick={() => handleVersionChange(VERSION_B)}
              disabled={isDisabled}
            >
              B
            </ToggleButtonRight>
          </ToggleButtonGroup>
          {isConfirmDeleteABDraftModalVisible && (
            <ConfirmDeleteABDraftModal
              onHide={hideDeleteConfirmationModal}
              confirm={deleteBVersion}
            />
          )}
        </Container>
      )}
    </>
  );
};

export default ABToggleButton;
