import * as Sentry from '@sentry/browser';
import api from '../../utils/andromeda-api';

export async function insertTextQueue(recipientsCount, messageData, options) {
  try {
    const response = await api.post('/queue/prepareMessageTexts', {
      recipientsCount,
      messageData,
      options,
    });
    return response.data;
  } catch (e) {
    Sentry.captureException(e);
    return console.error('Error inserting text queue [insertTextQueue]');
  }
}

export async function getTextUsage(orgId) {
  if (!orgId) return 'N/A';

  try {
    const response = await api.get(`/messages/v2/getTextUsage/${orgId}`);
    return response.data.result;
  } catch (err) {
    Sentry.captureException(err);
    console.error('Error fetching monthly text usage [getTextUsage]', err);
    return 'N/A';
  }
}
