import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 19px 25px;
  height: 100%;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
`;

export const Button = styled.button`
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  padding: 3px 0;
  margin-right: 6px;
  border-radius: 2px;
  text-align: center;
  width: 100px;
  color: white;
  border: 1px solid #509ee5;
  transition: all 0.3s ease-in;
  background-color: #007ade;
  &:hover,
  &.active {
    color: #007ade;
    background-color: #ebf0f5;
    border: 1px solid #007ade;
  }
`;

export const Group = styled.div`
  font-size: 14px;
`;
