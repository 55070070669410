import styled from 'styled-components';

const Button = styled.button`
  color: #6c7075;
  font-size: 14px;
  text-align: left;
  padding: 0px 20px;
  margin-bottom: 15px;
  border: 1px solid #d4d7d9;
  border-radius: 4px;
  height: 28px;
  transition: all 0.3s ease-in;
  background-color: #fefefe;
  &:hover:enabled,
  &.active:enabled {
    cursor: pointer;
    color: #007ade;
    font-weight: bold;
    border: 2px solid #2087e1;
  }
`;

export default Button;
