import React from 'react';
import { TextField } from '@material-ui/core';
import {
  Wrap,
  MessageTitle,
  MessageDetails,
  MessageId,
} from './MessageDetails.styled';

const ABmessageDetails = ({ message, classes, phoneNumber, isSuperUser }) => {
  return (
    <>
      <Wrap>
        <MessageDetails>
          <MessageTitle>Version A</MessageTitle>
          {isSuperUser && <MessageId>ID: {message.id}</MessageId>}
        </MessageDetails>
        <MessageDetails>
          <MessageTitle>Version B</MessageTitle>
          {isSuperUser && <MessageId>ID: {message.versionB.id}</MessageId>}
        </MessageDetails>
      </Wrap>
      <Wrap>
        <TextField
          className={classes.textField}
          disabled
          label="From Name"
          variant="outlined"
          size="small"
          value={message.sender || 'N/A'}
          style={{ width: '50%' }}
        />
        <TextField
          className={classes.textField}
          disabled
          label="From Name"
          variant="outlined"
          size="small"
          value={message.versionB.sender || 'N/A'}
          style={{ width: '50%' }}
        />
      </Wrap>
      <Wrap>
        <TextField
          className={classes.textField}
          disabled
          label="From Phone"
          variant="outlined"
          size="small"
          value={phoneNumber || 'Error - Invalid Phone Number'}
          inputProps={{
            style: {
              color: !phoneNumber && '#FF6161',
            },
          }}
          style={{ width: '50%' }}
        />
        <TextField
          className={classes.textField}
          disabled
          label="From Phone"
          variant="outlined"
          size="small"
          value={phoneNumber || 'Error - Invalid Phone Number'}
          inputProps={{
            style: {
              color: !phoneNumber && '#FF6161',
            },
          }}
          style={{ width: '50%' }}
        />
      </Wrap>
      <Wrap>
        <TextField
          className={classes.textField}
          disabled
          label="From Email"
          variant="outlined"
          size="small"
          value={message.from || 'N/A'}
          style={{ width: '50%' }}
        />
        <TextField
          className={classes.textField}
          disabled
          label="From Email"
          variant="outlined"
          size="small"
          value={message.versionB.from || 'N/A'}
          style={{ width: '50%' }}
        />
      </Wrap>
      <Wrap>
        <TextField
          className={classes.textField}
          disabled
          label="Subject"
          variant="outlined"
          size="small"
          value={message.messageSubject || 'N/A'}
          style={{ width: '50%' }}
        />
        <TextField
          className={classes.textField}
          disabled
          label="Subject"
          variant="outlined"
          size="small"
          value={message.versionB.messageSubject || 'N/A'}
          style={{ width: '50%' }}
        />
      </Wrap>
      <Wrap>
        <TextField
          className={classes.textField}
          disabled
          label="Preheader"
          variant="outlined"
          size="small"
          value={message.preheader || 'N/A'}
          style={{ width: '50%' }}
        />
        <TextField
          className={classes.textField}
          disabled
          label="Preheader"
          variant="outlined"
          size="small"
          value={message.versionB.preheader || 'N/A'}
          style={{ width: '50%' }}
        />
      </Wrap>
    </>
  );
};

export default ABmessageDetails;
