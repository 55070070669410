import styled from 'styled-components';
import { Edit } from '@material-ui/icons';

export const EditIcon = styled(Edit)`
  && {
    display: flex;
    font-size: 70px;
    margin: 32px auto 48px;
    color: #007ade;
  }
`;

export const Title = styled.h5`
  font-size: 22px;
  color: #007ade;
`;

export const TemplateName = styled.span`
  font-weight: 500;
  font-size: 14px;
`;
