import api from '../../utils/andromeda-api';

export async function updateTopicsSubscriberCounts(topicUpdates) {
  try {
    const response = await api.post(
      '/preference/updateTopicSubscriberCounts',
      topicUpdates
    );
    console.log('Updated topic subscriber counts successfully');
    return response.data.result;
  } catch (e) {
    console.error(
      'Error updating topic subscriber counts [updateTopicsSubscriberCounts]',
      e
    );
    return { error: true };
  }
}

export async function updateTagsSubscriberCounts(tagUpdates) {
  try {
    const response = await api.post(
      '/subscriberCounts/updateTagSubscriberCounts',
      tagUpdates
    );
    console.log('Updated tag subscriber counts successfully');
    return response.data.result;
  } catch (e) {
    console.error(
      'Error updating tag subscriber counts [updateTagsSubscriberCounts]',
      e
    );
    return { error: true };
  }
}

export async function refreshAllSubscriberCounts(orgId) {
  try {
    const response = await api.get(
      `/subscriberCounts/refreshAllSubscriberCounts/${orgId}`
    );
    console.log('Updated all topic and tag subscriber counts successfully');
    return response.data.result;
  } catch (e) {
    console.error(
      'Error updating all topic and tag subscriber counts [refreshAllSubscriberCounts]',
      e
    );
    return { error: true };
  }
}
