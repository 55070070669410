import { _without } from 'underscore-es';

class FeatureToggler {
  #featureToggles = new Map();
  #toggleObservers = new Map();

  enable(feature) {
    this.set(feature, true);
  }

  disable(feature) {
    this.set(feature, false);
  }

  set(feature, isEnabled) {
    if (this.#isSame(feature, isEnabled)) {
      return;
    }

    this.#featureToggles.set(feature, isEnabled);
    this.#notifyFeatureToggleChange(feature);
  }

  isEnabled(feature) {
    return this.#featureToggles.get(feature) ?? false;
  }

  observe(feature, observer) {
    observer(this.isEnabled(feature));

    this.#addObserver(feature, observer);
    return () => {
      this.#removeObserver(feature, observer);
    };
  }

  #isSame(feature, isEnabled) {
    const previousIsEnabled = this.isEnabled(feature);
    return isEnabled === previousIsEnabled;
  }

  #addObserver(feature, observer) {
    const currentObservers = this.#getFeatureObservers(feature);
    const newObservers = [...currentObservers, observer];
    this.#toggleObservers.set(feature, newObservers);
  }

  #removeObserver(feature, observer) {
    const currentObservers = this.#getFeatureObservers(feature);
    const newObservers = _without(currentObservers, observer);
    this.#toggleObservers.set(feature, newObservers);
  }

  #notifyFeatureToggleChange(feature) {
    const observers = this.#getFeatureObservers(feature);
    observers.forEach(observer => {
      observer(this.isEnabled(feature));
    });
  }

  #getFeatureObservers(feature) {
    return this.#toggleObservers.get(feature) ?? [];
  }
}

export default FeatureToggler;
