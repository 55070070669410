import React from 'react';
import { useSelector } from 'react-redux';
import { Tag, Tooltip } from 'antd';
import { _noop } from 'underscore-es';

import { groupsSelector, topicsSelector } from '../../redux/selectors/tags';
import {
  formatTimestampToDate,
  trimTextIfNeeded,
} from '../Drafts/Components/_utils';
import { Card, CardHeader } from '../Drafts/Components/_CardUI/Card.styled';
import {
  Container,
  Description,
  Name,
  Text,
  Wrapper,
} from './AudienceCard.styled';

const useTags = audience => {
  const topics = useSelector(topicsSelector);
  const groups = useSelector(groupsSelector);

  const selectedTopicsNames = audience.topicIds
    .map(topicId => {
      const selectedTopic = topics.find(topic => topic.id === topicId);
      return selectedTopic?.name;
    })
    .filter(name => name);

  const selectedGroupIds = [
    ...audience.selectedGroupIds,
    ...audience.requiredGroupIds,
    ...audience.excludedGroupIds,
  ];

  const selectedGroupsNames = selectedGroupIds.map(groupId => {
    const selectedGroup = groups.find(group => group.id === groupId);
    return selectedGroup?.name;
  });

  const selectedFiltersNames = audience.filterData
    ? Object.keys(audience.filterData)
    : [];

  return {
    topics: [...new Set(selectedTopicsNames)],
    groups: selectedGroupsNames,
    filters: selectedFiltersNames,
  };
};

const useLabels = audience => {
  const { topics, groups, filters } = useTags(audience);

  const topicLabels = topics.map(topicName => (
    <div key={`tag-${topicName}`}>
      <Tag color="blue">{trimTextIfNeeded(topicName, 20)}</Tag>
    </div>
  ));

  const groupLabels = groups.map(groupName => (
    <div key={`tag-${groupName}`}>
      <Tag>{trimTextIfNeeded(groupName, 20)}</Tag>
    </div>
  ));

  const filterLabels = filters.map(filterName => (
    <div key={`${audience.name}-${filterName}`}>
      <Tag color="orange">{trimTextIfNeeded(filterName, 20)}</Tag>
    </div>
  ));

  const labels = [...topicLabels, ...groupLabels, ...filterLabels];
  const visibleLabels = labels.slice(0, 4);
  const hiddenLabels = [...topics, ...groups, ...filters].slice(4).map(name => {
    return {
      name,
      id: `${audience.name}-${name}`,
    };
  });

  let tooltipText;

  if (hiddenLabels && hiddenLabels.length) {
    tooltipText = (
      <>
        {hiddenLabels.map(tag => (
          <div key={tag.id}>{tag.name}</div>
        ))}
      </>
    );
  }

  return { visibleLabels, tooltipText };
};

const AudienceCard = ({
  audience,
  onClick = _noop,
  header = null,
  tooltip = null,
}) => {
  const { visibleLabels, tooltipText } = useLabels(audience);

  return (
    <Card>
      <CardHeader>{header}</CardHeader>
      <Tooltip title={tooltip}>
        <Card.Body onClick={onClick}>
          <Container>
            <Name>{trimTextIfNeeded(audience.name, 25)}</Name>
            <Description>
              {trimTextIfNeeded(audience.description, 25)}
            </Description>
          </Container>
          <Wrapper>
            <Text>Groups:</Text>
            {visibleLabels}
            {tooltipText && (
              <Tooltip title={tooltipText}>
                <Tag>More...</Tag>
              </Tooltip>
            )}
          </Wrapper>
          <Card.Date>{formatTimestampToDate(audience.updatedAt)}</Card.Date>
        </Card.Body>
      </Tooltip>
    </Card>
  );
};

export default AudienceCard;
