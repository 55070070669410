// helper functions specific to Analytics Page
import * as moment from 'moment';

// add a status field to a message
export const addStatusesToMessage = message => {
  if (message.cancelled) {
    message.status = 'Cancelled';
  } else if (!message.cancelled && message.sendTime < moment().unix()) {
    message.status = 'Sent';
  } else if (!message.cancelled && message.sendTime > moment().unix()) {
    message.status = 'Scheduled';
  }
  return message;
};

export const sortMessages = (messages, hideScheduled) => {
  return hideScheduled
    ? messages.filter(_ => _.sendTime < moment().unix())
    : messages;
};

export const formatPhoneNumber = phoneNumber => {
  const phone = phoneNumber.replace(/\D/g, '');

  if (phone.length !== 10) return null;

  return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};

export const capitalizeFirstLetter = word => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const returnBounceType = bounceEvent => {
  const { bounceType } = bounceEvent;
  if (!bounceType) return capitalizeFirstLetter(bounceEvent.eventName);
  if (bounceType === 'bounce') return 'Permanent Bounce';
  return 'Temporary Bounce';
};

export const convertToCSVFormat = data => {
  const keys = ['contact', 'eventName', 'errorCode', 'errorMessage', 'time'];

  const csvData = [['Contact Info', 'Event', 'Code', 'Reason', 'Date/Time']];

  data.forEach(row => {
    const result = [];

    keys.forEach(key => {
      if (key === 'eventName' && row[key] === 'bounce') {
        result.push(returnBounceType(row));
      } else {
        result.push(
          typeof row[key] === 'string' && row[key].includes(',')
            ? `"${row[key]}"`
            : row[key]
        );
      }
    });

    csvData.push(result);
  });

  return csvData;
};
