/* eslint-disable no-case-declarations */
export const audienceInitState = {
  _id: null, // this is relevant only for standalone audience
  name: '', // this is relevant only for standalone audience
  description: '', // this is relevant only for standalone audience
  members: [], // list of members selected by topics
  selectedMenuItemName: 'Topics', // this is the first screen that is available to the end user.
  selectedSubMenuItemName: 'For Members', // topics has two submenus for members and nonMembers.
  selectedTopicsList: [],
  selectedMembers: {}, // example: { 'memberId': { action: 'Add', name: 'Jack'}, 'anotherMemberId': { action: 'Remove', name: 'Xxxx'} }, action values 'Add' and 'Require'
  selectedGroups: {}, // example: { 'groupId': { action: 'Add', name: 'Networking'}, 'anotherGroupId': { action: 'Exclude', name: 'Spam' }}, action values 'Add', 'Exclude' or 'Require'
  selectedFilters: {},
  targetedAudienceLength: 0, // targeted audience length calculated after applying all filters
  filterTypes: [],
  audienceFilters: [],
  orgTypeName: '',
  optimizations: {
    selectedTopicsList: [], // this helps in optimazation of reload logic of contacts
  },
  emailRecipients: [], // calculated before proceeding to preview page and used in preview stage
  textRecipients: [], // calculated before proceeding to preview page and used in preview stage
};

export function audience(state = audienceInitState, action) {
  const { detail } = action;
  switch (action.type) {
    case 'SELECT_AUDIENCE_MENU_ITEM':
      // when user clicks on any tab other than Contacts, old selected topics list should be saved
      // for later comparision if selected topics have changed
      // which helps optimizing performance of Contacts loading
      if (detail !== 'Contacts') {
        return {
          ...state,
          selectedMenuItemName: detail,
          optimizations: {
            ...state.optimizations,
            selectedTopicsList: state.selectedTopicsList || [],
          },
        };
      }
      return {
        ...state,
        selectedMenuItemName: detail,
      };
    case 'SELECT_AUDIENCE_SUBMENU_ITEM':
      return {
        ...state,
        selectedSubMenuItemName: detail,
      };
    case 'SELECT_TOPICS':
      return {
        ...state,
        selectedTopicsList: detail,
      };
    case 'SELECT_MEMBER':
      return {
        ...state,
        selectedMembers: { ...state.selectedMembers, ...detail },
      };
    case 'REMOVE_MEMBER':
      const updatedSelectedMembers = { ...state.selectedMembers };
      delete updatedSelectedMembers[detail];
      return {
        ...state,
        selectedMembers: updatedSelectedMembers,
      };
    case 'RESET_MEMBERS_LIST':
      return {
        ...state,
        selectedMembers: {},
      };
    case 'LOAD_MEMBERS_LIST':
      return {
        ...state,
        members: detail,
      };
    case 'SELECT_GROUP':
      return {
        ...state,
        selectedGroups: { ...state.selectedGroups, ...detail },
      };
    case 'REMOVE_GROUP':
      const updatedSelectedGroups = { ...state.selectedGroups };
      delete updatedSelectedGroups[detail];
      return {
        ...state,
        selectedGroups: updatedSelectedGroups,
      };
    case 'LOAD_TARGET_AUDIENCE':
      return {
        ...state,
        targetedAudienceLength: detail,
      };
    case 'ADD_FILTER':
      return {
        ...state,
        selectedFilters: { ...state.selectedFilters, ...detail },
      };
    case 'REMOVE_FILTER':
      const updatedSelectedFilters = { ...state.selectedFilters };
      for (const filter of detail) {
        delete updatedSelectedFilters[filter];
      }
      return {
        ...state,
        selectedFilters: updatedSelectedFilters,
      };
    case 'LOAD_FILTER_TYPES':
      return {
        ...state,
        filterTypes: detail,
      };
    case 'LOAD_AUDIENCE_FILTERS':
      return {
        ...state,
        audienceFilters: detail,
      };
    case 'LOAD_ORGTYPE_NAME':
      return {
        ...state,
        orgTypeName: detail,
      };
    case 'APPLY_AUDIENCE_TO_DRAFT':
    case 'APPLY_AUDIENCE_TO_NEW_DRAFT':
    case 'UPDATE_AUDIENCE_FROM_DRAFT':
      return {
        ...state,
        selectedTopicsList: detail.topicIds || [],
        selectedMembers: detail.selectedMembers || {},
        selectedGroups: detail.selectedGroups || {},
        selectedFilters: detail.filterData || {},
      };
    case 'LOAD_STANDALONE_AUDIENCE':
    case 'APPLY_EDIT_STANDALONE_AUDIENCE':
      return {
        ...state,
        selectedTopicsList: detail.topicIds || [],
        selectedMembers: detail.selectedMembers || {},
        selectedGroups: detail.selectedGroups || {},
        selectedFilters: detail.filterData || {},
        name: detail.name || '',
        description: detail.description || '',
        _id: detail._id,
      };
    case 'SAVE_NEW_STANDALONE_AUDIENCE_ID':
      return {
        ...state,
        _id: detail,
      };
    case 'SAVE_STANDALONE_AUDIENCE_NAME':
      return {
        ...state,
        name: detail,
      };
    case 'SAVE_STANDALONE_AUDIENCE_DESCRIPTION':
      return {
        ...state,
        description: detail,
      };
    case 'RESET_AUDIENCE_BUILDER':
      return audienceInitState;
    case 'UPDATE_EMAIL_RECIPIENTS':
      return {
        ...state,
        emailRecipients: detail,
      };

    case 'UPDATE_TEXT_RECIPIENTS':
      return {
        ...state,
        textRecipients: detail,
      };
    default:
      return state;
  }
}
