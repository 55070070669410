import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  selectedOrganizationSelector,
  userPrivilegesSelector,
} from '../../../../redux/selector';
import { getOrgTypes } from '../../../../redux/services/organization';
import { Container, ColumnContainer } from './AMSSync.styled';
import APIConnection from './APIConnection';
import SyncSettings from './SyncSettings';
import GroupMapping from './GroupMapping';

const AMSSync = () => {
  const userPrivileges = useSelector(userPrivilegesSelector);
  const organization = useSelector(selectedOrganizationSelector);
  const [orgTypes, setOrgTypes] = useState([]);
  const [selectedOrgType, setSelectedOrgType] = useState([]);

  useEffect(() => {
    const fetchOrgTypes = async () => {
      const allTypes = await getOrgTypes();
      setOrgTypes(allTypes);
      const orgType = allTypes?.find(type => type.id === organization.orgType);
      setSelectedOrgType(orgType.name);
    };
    fetchOrgTypes();
  }, []);

  return (
    <ColumnContainer>
      <Container>
        <APIConnection
          userPrivileges={userPrivileges}
          organization={organization}
          orgTypes={orgTypes}
          selectedOrgType={selectedOrgType}
          setSelectedOrgType={setSelectedOrgType}
        />
        <SyncSettings
          userPrivileges={userPrivileges}
          organization={organization}
          orgTypes={orgTypes}
        />
      </Container>
      <GroupMapping
        organization={organization}
        userPrivileges={userPrivileges}
      />
    </ColumnContainer>
  );
};

export default AMSSync;
