import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Add, Close } from '@material-ui/icons';
import {
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { groupsSelector, topicsSelector } from '../../../redux/selectors/tags';
import { loadGroups } from '../../../redux/actions/groups';
import { loadTopics } from '../../../redux/actions/topics';
import { toast } from '../../../redux/actions/UI';
import CreateGroupModal from '../../CreateGroupModal';
import CreateTopicModal from '../../CreateTopicModal';
import {
  StyledSelect,
  SelectDropDownFooter,
  CreateNewGroupButton,
  CloseDropdownButton,
  NoAvailableOptionsMessage,
  StyledList,
  StyledListItem,
} from './SelectDropDown.styled';

const useSelection = ({ selected, tags, onSelectionChange, name }) => {
  const dispatch = useDispatch();
  const selectedTags = useMemo(() => {
    return selected.map(tagId => tags.find(tag => tag.id === tagId));
  }, [tags, selected]);

  const notSelected = useMemo(() => {
    return tags.filter(tag => !selected.includes(tag.id));
  }, [tags, selected]);

  const add = useCallback(
    tagId => {
      dispatch(toast('success', `${name} selected`));
      onSelectionChange([...selected, tagId]);
    },
    [onSelectionChange, selected]
  );

  const remove = useCallback(
    tagId => {
      onSelectionChange(selected.filter(id => id !== tagId));
    },
    [onSelectionChange, selected]
  );

  return { selected: selectedTags, notSelected, add, remove };
};

const SelectDropDown = ({
  name,
  placeholder,
  shouldImportMembersOnly,
  setSelectedTagIds,
  selectedTagIds,
}) => {
  const dispatch = useDispatch();
  const initialTopics = useSelector(topicsSelector);
  const initialGroups = useSelector(groupsSelector);
  const [isDropDownVisible, setDropDownVisibility] = useState(false);
  const [isCreatingNewGroup, setIsCreatingNewGroup] = useState(false);
  const [isCreatingNewTopic, setIsCreatingNewTopic] = useState(false);
  const [groups, setGroups] = useState(initialGroups);
  const [topics, setTopics] = useState(
    initialTopics.filter(
      topic =>
        (shouldImportMembersOnly && topic.availableTo?.member) ||
        (!shouldImportMembersOnly && topic.availableTo?.nonMember)
    )
  );

  useEffect(() => {
    if (topics) {
      setTopics(
        initialTopics.filter(
          topic =>
            (shouldImportMembersOnly && topic.availableTo?.member) ||
            (!shouldImportMembersOnly && topic.availableTo?.nonMember)
        )
      );
    }
  }, [shouldImportMembersOnly]);

  useEffect(() => {
    // if a new group is added then we need to update the redux store
    if (groups.length !== initialGroups) {
      dispatch(loadGroups(groups));
    }
  }, [groups]);

  useEffect(() => {
    // if a new topic is added then we need to update the redux store
    if (topics.length !== initialTopics) {
      dispatch(loadTopics(topics));
    }
  }, [topics]);

  const startCreatingNewGroup = useCallback(() => {
    setDropDownVisibility(false);
    setIsCreatingNewGroup(true);
  }, []);

  const cancelCreatingNewGroup = useCallback(() => {
    setIsCreatingNewGroup(false);
  }, []);

  const startCreatingNewTopic = useCallback(() => {
    setDropDownVisibility(false);
    setIsCreatingNewTopic(true);
  }, []);

  const cancelCreatingNewTopic = useCallback(() => {
    setIsCreatingNewTopic(false);
  }, []);

  const selection = useSelection({
    selected: selectedTagIds,
    tags: name === 'Group' ? groups : topics,
    onSelectionChange: setSelectedTagIds,
    name,
  });

  const renderDropdown = menu => (
    <>
      {menu}
      <SelectDropDownFooter>
        <CreateNewGroupButton
          onClick={
            name === 'Group' ? startCreatingNewGroup : startCreatingNewTopic
          }
        >
          <Add fontSize="small" />
          Create New {name}
        </CreateNewGroupButton>
        <CloseDropdownButton
          onClick={() => {
            setDropDownVisibility(false);
          }}
        >
          Done
        </CloseDropdownButton>
      </SelectDropDownFooter>
    </>
  );

  const handleNewGroupCreation = useCallback(async newTag => {
    setGroups(previousGroups => [...previousGroups, newTag]);
    setSelectedTagIds(previousSelection => [...previousSelection, newTag.id]);

    setIsCreatingNewGroup(false);
  }, []);

  const handleNewTopicCreation = useCallback(async newTag => {
    setTopics(previousGroups => [...previousGroups, newTag]);
    setSelectedTagIds(previousSelection => [...previousSelection, newTag.id]);

    setIsCreatingNewTopic(false);
  }, []);

  return (
    <>
      <StyledSelect
        mode="multiple"
        value={[]}
        onSelect={selection.add}
        placeholder={placeholder}
        showSearch
        showArrow
        optionFilterProp="children"
        onDropdownVisibleChange={() => {
          setDropDownVisibility(!isDropDownVisible);
        }}
        open={isDropDownVisible}
        notFoundContent={
          <NoAvailableOptionsMessage>
            No available options
          </NoAvailableOptionsMessage>
        }
        dropdownRender={renderDropdown}
      >
        {selection.notSelected.map(tag => (
          <StyledSelect.Option key={tag.id} value={tag.id}>
            {tag.name}
          </StyledSelect.Option>
        ))}
      </StyledSelect>
      <StyledList dense>
        {selection.selected.length > 0
          ? selection.selected.map(({ id, name }) => (
              <StyledListItem key={id}>
                <ListItemText primary={name} />
                <ListItemSecondaryAction>
                  <IconButton
                    size="small"
                    edge="end"
                    onClick={() => selection.remove(id)}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </StyledListItem>
            ))
          : null}
      </StyledList>
      {name === 'Group' && isCreatingNewGroup && (
        <CreateGroupModal
          confirmText="Create Group and Select"
          cancelText="Back"
          onSuccess={handleNewGroupCreation}
          onClose={() => {
            setIsCreatingNewGroup(false);
          }}
          onCancel={cancelCreatingNewGroup}
        />
      )}
      {name === 'Topic' && isCreatingNewTopic && (
        <CreateTopicModal
          confirmText="Create Topic and Select"
          cancelText="Back"
          onSuccess={handleNewTopicCreation}
          onClose={() => {
            setIsCreatingNewTopic(false);
          }}
          onCancel={cancelCreatingNewTopic}
          availableTo={
            shouldImportMembersOnly
              ? { nonMember: false, member: true }
              : { nonMember: true, member: false }
          }
        />
      )}
    </>
  );
};

export default SelectDropDown;
