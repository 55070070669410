import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TextField, InputAdornment } from '@material-ui/core';
import { AddCircleOutline, CancelOutlined } from '@material-ui/icons';
import SelectDropDown from '../_shared/selectDropdown';
import Container from '../_shared/container';
import Text from '../_shared/text';
import Wrap from '../_shared/wrap';
import Bold from '../_shared/bold';
import Content from '../../../_shared/content';
import FitContent from '../_shared/fitContent';
import Space from '../_shared/space';
import { comparisonOperatorValues } from '../_shared/constants';
import {
  addFilter,
  removeFilter,
  toast,
} from '../../../../../../../../../redux/action';

const TotalOfAllOutstandingBalances = ({
  filterName,
  checked,
  selectedFilters,
}) => {
  const dispatch = useDispatch();

  const [comparisonOperator, setComparisonOperator] = useState('');
  const [amount, setAmount] = useState('');
  const [maxAmount, setMaxAmount] = useState('');
  const [totalOfAllOutstandingBalances, setTotalOfAllOutstandingBalances] =
    useState(selectedFilters || []);

  useEffect(() => {
    if (totalOfAllOutstandingBalances.length) {
      dispatch(addFilter({ [filterName]: totalOfAllOutstandingBalances }));
    } else if (
      totalOfAllOutstandingBalances.length === 0 &&
      selectedFilters &&
      selectedFilters.length !== 0
    ) {
      dispatch(removeFilter(filterName));
    }
  }, [totalOfAllOutstandingBalances]);

  useEffect(() => {
    if (!checked && totalOfAllOutstandingBalances.length) {
      dispatch(removeFilter(filterName));
    }
  }, [checked]);

  const handleComparisonOperatorChange = ({ target }) => {
    setComparisonOperator(target.value);
  };
  const handleAmountChange = ({ target }) => {
    if (parseInt(target.value)) {
      setAmount(target.value);
    } else {
      dispatch(toast('error', 'Amount value should be a number.'));
      setAmount('');
    }
  };
  const handleMaxAmountChange = ({ target }) => {
    if (parseInt(target.value)) {
      setMaxAmount(target.value);
    } else {
      dispatch(toast('error', 'Amount value should be a number.'));
      setMaxAmount('');
    }
  };

  const handleAddTotalOfAllOutstandingBalances = () => {
    const errorMessage = [];
    if (!comparisonOperator) {
      errorMessage.push('Please select comparison operator.');
    }
    if (!amount) {
      errorMessage.push('Please add amount.');
    }
    if (comparisonOperator === 'between') {
      if (!maxAmount) {
        errorMessage.push('Please add maximum amount.');
      }
      if (amount && +amount >= +maxAmount) {
        errorMessage.push('Max amount value should be higher than amount.');
      }
    }

    if (errorMessage.length) {
      dispatch(toast('error', errorMessage.join('\n')));
    } else {
      setTotalOfAllOutstandingBalances([
        ...totalOfAllOutstandingBalances,
        { comparisonOperator, amount, maxAmount },
      ]);
      setComparisonOperator('');
      setAmount('');
      setMaxAmount('');
    }
  };

  const handleRemoveTotalOfAllOutstandingBalances = index => {
    setTotalOfAllOutstandingBalances([
      ...totalOfAllOutstandingBalances.slice(0, index),
      ...totalOfAllOutstandingBalances.slice(index + 1),
    ]);
  };

  return (
    <Container>
      {(selectedFilters || []).map(
        ({ comparisonOperator, amount, maxAmount }, index) => (
          <Wrap key={index}>
            <FitContent>
              {index > 0 ? <Space>or a</Space> : <Space>- A</Space>} balance
            </FitContent>
            <Bold>
              {comparisonOperator} {amount}{' '}
              {maxAmount ? ` and ${maxAmount}` : ''}
            </Bold>
            <CancelOutlined
              fontSize="small"
              style={{ fill: '#E63946' }}
              onClick={() => handleRemoveTotalOfAllOutstandingBalances(index)}
            />
          </Wrap>
        )
      )}
      <Content>
        <Text>
          {totalOfAllOutstandingBalances.length ? 'or a' : 'A'} balance
        </Text>
        <SelectDropDown
          value={comparisonOperator}
          menuItemNames={comparisonOperatorValues}
          onChange={handleComparisonOperatorChange}
        />
        <Space />
        <TextField
          value={amount}
          onChange={handleAmountChange}
          style={{ width: 80 }}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        {comparisonOperator === 'between' && (
          <>
            <Text>and</Text>
            <TextField
              value={maxAmount}
              onChange={handleMaxAmountChange}
              style={{ width: 80 }}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </>
        )}
      </Content>
      <Button
        variant="contained"
        size="small"
        startIcon={<AddCircleOutline />}
        style={{
          borderRadius: 50,
          backgroundColor: '#007ADE',
          margin: '0 25px',
          padding: '5px 15px',
          color: 'white',
        }}
        onClick={handleAddTotalOfAllOutstandingBalances}
      >
        Add
      </Button>
    </Container>
  );
};

export default TotalOfAllOutstandingBalances;
