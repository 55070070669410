import React, { useCallback } from 'react';
import {
  Button,
  CircularProgress,
  IconButton,
  Modal as MuiModal,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from './Modal.module.css';

const Modal = ({
  className = null,
  title,
  primaryButton: {
    label: primaryLabel,
    onClick: onPrimaryClick,
    isLoading: isPrimaryLoading = false,
    isDisabled: isPrimaryDisabled = false,
  } = {},
  secondaryButton: { label: secondaryLabel, onClick: onSecondaryClick } = {},
  onClose,
  children,
}) => {
  const notifyClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handlePrimaryButtonClick = useCallback(() => {
    onPrimaryClick();
  }, [onPrimaryClick]);

  const handleSecondaryButtonClick = useCallback(() => {
    if (onSecondaryClick) {
      onSecondaryClick();
    } else {
      onClose();
    }
  }, [onClose, onSecondaryClick]);

  return (
    <MuiModal open onClose={notifyClose}>
      <div className={`${styles.wrapper} ${className ?? ''}`}>
        <div className={styles.header}>
          <IconButton
            className={styles.closeButton}
            onClick={notifyClose}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <span className={styles.title}>{title}</span>
        </div>
        <div className={styles.body}>{children}</div>
        <div className={styles.footer}>
          <Button
            className={`${styles.button} ${styles.secondaryButton}`}
            variant="outlined"
            onClick={handleSecondaryButtonClick}
          >
            {secondaryLabel ?? 'Cancel'}
          </Button>
          <Button
            className={`${styles.button} ${styles.primaryButton}`}
            variant="outlined"
            disabled={isPrimaryLoading || isPrimaryDisabled}
            onClick={handlePrimaryButtonClick}
            startIcon={
              isPrimaryLoading && <CircularProgress size={12} color="inherit" />
            }
          >
            {primaryLabel ?? 'Confirm'}
          </Button>
        </div>
      </div>
    </MuiModal>
  );
};

export default Modal;
