import { _uniq, _zip } from 'underscore-es';

const mergeProperties = (key, a, b) => {
  const existsInA = Object.prototype.hasOwnProperty.call(a, key);
  const existsInB = Object.prototype.hasOwnProperty.call(b, key);

  if (!existsInA) {
    return b[key];
  }

  if (!existsInB) {
    return a[key];
  }

  const aValue = a[key];
  const bValue = b[key];

  if (aValue === null || bValue === null) {
    return bValue;
  }

  const isAValueArray = Array.isArray(aValue);
  const isBValueArray = Array.isArray(bValue);
  if (isAValueArray && isBValueArray) {
    return mergeArrays(aValue, bValue);
  }

  const isOnlyOneOfThemArray = isAValueArray !== isBValueArray;
  if (isOnlyOneOfThemArray) {
    return bValue;
  }

  if (typeof aValue === 'object' && typeof bValue === 'object') {
    return mergeDeep(aValue, bValue);
  }

  return bValue;
};

const mergeObjects = (a, b) => {
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);

  const keys = _uniq([...aKeys, ...bKeys]);
  const values = keys.map(key => {
    return mergeProperties(key, a, b);
  });

  const mergedEntries = _zip(keys, values);
  return Object.fromEntries(mergedEntries);
};

const mergeArrays = (a, b) => {
  return new Array(Math.max(a.length, b.length)).fill(null).map((_, index) => {
    return mergeDeep(a[index], b[index]);
  });
};

const mergeDeep = (...objects) => {
  const [a, b, ...others] = objects;
  const merged = mergeObjects(a, b);
  if (others.length === 0) {
    return merged;
  } else {
    return mergeDeep(merged, ...others);
  }
};

export default mergeDeep;
