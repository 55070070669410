import axios from 'axios';
const heliosURL = process.env.REACT_APP_EC2API;

export async function getAMSSyncReports() {
  try {
    const response = await axios.get(`${heliosURL}/jobs/lastSync/`);
    if (response?.data?.results) {
      return response.data.results;
    }
    return [];
  } catch (e) {
    console.error('Error getting getAMSSyncReports', e);
    return [];
  }
}

export async function getActiveSyncReport(orgId) {
  try {
    const response = await axios.get(
      `${heliosURL}/jobs/lastSync/activeSyncReport/${orgId}`
    );
    if (response?.data) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error('Error getting getActiveSyncReport', e);
    return [];
  }
}

export async function getActiveQueueItem(orgId) {
  try {
    const response = await axios.get(`${heliosURL}/queue/active/${orgId}`);
    if (response?.data) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error('Error getting getActiveQueueItem', e);
    return [];
  }
}

export async function getSyncItems() {
  try {
    const response = await axios.get(`${heliosURL}/queue/active/`);
    if (response?.data) {
      return response.data;
    }
    return [];
  } catch (e) {
    console.error('Error getting getSyncItems', e);
    return [];
  }
}
