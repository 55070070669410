import React from 'react';

import { Tabs } from '../../../../infrastructure/ui/navigation';
import Step from '../Step';
import { useEditor } from '../CampaignEditor';
import SavedTemplates from './SavedTemplates';
import SharedTemplates from './SharedTemplates';

const TemplatePicker = () => {
  const editor = useEditor();

  const goToEditTemplate = template => {
    editor.goToStep('template/editor', {
      stage: editor.getParam('stage'),
      template,
    });
  };

  return (
    <Tabs
      tabs={[
        {
          key: 'saved-templates',
          label: 'Saved Templates',
          content: () => <SavedTemplates onEdit={goToEditTemplate} />,
        },
        {
          key: 'shared-templates',
          label: 'Shared Templates',
          content: () => <SharedTemplates onEdit={goToEditTemplate} />,
        },
      ]}
    >
      <Step
        title="Template"
        description="Choose a message template for this stage of your Campaign"
        headerRightAddon={<Tabs.List />}
        onBack={() => editor.goToStep('stages')}
      >
        <Tabs.Content />
      </Step>
    </Tabs>
  );
};

export default TemplatePicker;
