import styled from 'styled-components';

const SolidButton = styled.button`
  position: relative;
  color: white;
  font-size: 14px;
  padding: ${props => props.padding || '0px 20px'};
  border: 1px solid ${props => props.color || '#5EAB00'};
  border-radius: 4px;
  height: 28px;
  transition: all 0.3s ease-in;
  background-color: ${props => props.color || '#5EAB00'};
  &:hover:enabled,
  &.active:enabled {
    color: ${props => props.color || '#5EAB00'};
    cursor: pointer;
    background-color: white;
    border: 1px solid ${props => props.color || '#5EAB00'};
  }
  &:disabled {
    color: grey;
    border-color: grey;
    background-color: transparent;
  }
`;

export default SolidButton;
