import api from '../../utils/andromeda-api';

export async function getAlerts() {
  try {
    const response = await api.get('/alerts');
    console.log('Retrieved alerts');
    return response.data.result;
  } catch (e) {
    console.error('Error getting alerts [getAlerts]', e);
    return false;
  }
}

export async function addAlert(newAlert) {
  try {
    const response = await api.post('/alerts/add', newAlert);
    console.log('Added alert');
    return response.data.result;
  } catch (e) {
    console.error('Error adding alert', e);
    return false;
  }
}

export async function deleteAlert(alertId) {
  try {
    const response = await api.post('/alerts/delete', {
      id: alertId,
    });
    console.log('Deleted alert');
    return response.data.result;
  } catch (e) {
    console.error('Error deleting alert', e);
    return false;
  }
}
