import styled from 'styled-components';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';

export const DataGrid = styled(MuiDataGrid)`
  background: white;

  .MuiDataGrid-row {
    cursor: pointer;
  }

  .MuiDataGrid-row.archived-keywords {
    background-color: #f6f6f6;
    color: grey;
  }
`;

export const Semibold = styled.span`
  font-weight: 600;
`;
