import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Tooltip } from 'antd';
import { copyDraft } from '../../../../redux/services';
import { fullloader, toast } from '../../../../redux/action';
import { Card, CardHeader } from '../_CardUI/Card.styled';
import { formatDate, trimTextIfNeeded } from '../_utils';
import SendIcon from '../_CardUI/SendIcon.styled';
import {
  ArchiveButton,
  UnarchiveButton,
  CopyButton,
} from '../_CardUI/CardActionButtons.styled';
import Modal from '../../../Modal';
import { useIsFeatureEnabled } from '../../../../libs/feature-toggles/react';
import Feature from '../../../../infrastructure/features';

const DraftCard = ({ onClick, archive, unarchive, draft, afterCopy }) => {
  const dispatch = useDispatch();
  const [isCopyModalOpen, setCopyModalOpen] = useState(false);
  const [isCreatingCopy, setCreatingCopy] = useState(false);
  const [draftCopyName, setDraftCopyName] = useState('');

  const openCopyModal = () => {
    setCopyModalOpen(true);
  };

  const closeCopyModal = () => {
    setCopyModalOpen(false);
  };

  const handleDraftNameChange = event => {
    setDraftCopyName(event.target.value);
  };

  const createDraftCopy = async () => {
    dispatch(fullloader(true, 'Creating a copy of the draft'));
    setCreatingCopy(true);

    try {
      await copyDraft({
        draftId: draft._id,
        copiedDraftName: draftCopyName,
      });
      dispatch(toast('success', 'Draft copied.'));
      await afterCopy();
      closeCopyModal();
      setDraftCopyName('');
    } catch {
      dispatch(toast('error', 'Error copying the draft'));
    } finally {
      setCreatingCopy(false);
      dispatch(fullloader(false));
    }
  };

  const isABEnabled = useIsFeatureEnabled(Feature.AB);

  const getActionIcons = status => {
    return (
      <>
        <CardHeader.Left>
          {status === 'Active' && (
            <Tooltip title="Duplicate">
              <CopyButton onClick={openCopyModal} />
            </Tooltip>
          )}
          {status === 'Sent' && <UnarchiveButton onClick={unarchive} />}
        </CardHeader.Left>
        <CardHeader.Right>
          {status === 'Archived' ? (
            <UnarchiveButton onClick={unarchive} />
          ) : (
            <ArchiveButton onClick={archive} />
          )}
        </CardHeader.Right>
      </>
    );
  };

  return (
    <Card>
      <CardHeader>{getActionIcons(draft.status)}</CardHeader>
      <Card.Body onClick={onClick}>
        {draft.AB && isABEnabled && <Card.AB>{'(A/B)'}</Card.AB>}
        <SendIcon />
        <Card.Date>{formatDate(draft.saveTime)}</Card.Date>
        <Card.Name>{trimTextIfNeeded(draft.name, 40)}</Card.Name>
      </Card.Body>
      {isCopyModalOpen && (
        <Modal
          title="Duplicate Draft"
          primaryButton={{
            label: 'Save',
            isDisabled: draftCopyName.trim() === '',
            onClick: createDraftCopy,
            isLoading: isCreatingCopy,
          }}
          onClose={closeCopyModal}
        >
          <Input
            value={draftCopyName}
            onInput={handleDraftNameChange}
            placeholder="Draft Name"
          />
        </Modal>
      )}
    </Card>
  );
};

export default DraftCard;
