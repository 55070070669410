import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  min-width: 500px;
  height: 100%;
`;

export const Header = styled.div`
  background-color: #005092;
  color: white;
  padding: 10px;
  font-size: 18px;
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow: auto;
`;

export const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid #888;
  padding: 10px;
`;

export const ReplyActionArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextMetaData = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 10px;
  color: #555;
`;

export const Button = styled.button`
  background-color: #ffffff;
  color: #007ade;
  font-size: 14px;
  padding: 5px 12px;
  border: 1px solid #007ade;
  border-radius: 15px;
  width: 70px;
  display: flex;
  justify-content: center;
  gap: 5px;
  height: 32px;
  transition: all 0.3s ease-in;
  &:hover {
    color: white;
    font-weight: bold;
    cursor: pointer;
    background-color: #007ade;
    border: 1px solid #0176ff;
  }
`;

export const Input = styled.input`
  padding: 5px 10px;
  &:focus {
    border: 1px solid #555;
  }
`;

export const Text = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: #616161;
  line-height: 16px;
`;

export const HelpLine = styled.div`
  display: flex;
  gap: 10px;
  line-height: 14px;
  font-size: 14px;
  color: #616161;
  align-items: center;
`;

export const TextHelp = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const InfoContainer = styled.div`
  padding-top: 10px;
`;

export const Bold = styled.span`
  color: #555555;
  font-weight: 700;
`;

export const ErrorMessage = styled.div`
  color: #ff6161;
  font-size: 10px;
`;

export const Space = styled.div`
  padding-top: 10px;
`;
