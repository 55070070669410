import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const List = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`;

export const Tab = styled(Button)`
  padding: 10px 12px !important;
  font-size: 16px !important;
  line-height: 1.25 !important;
  color: ${({ isActive }) => (isActive ? '#ffffff' : '#007ade')} !important;
  background-color: ${({ isActive }) =>
    isActive ? '#007ade' : 'rgba(0, 122, 222, 0.05)'} !important;
  border: 1px solid #007ade !important;
  border-radius: 5px !important;

  &:disabled {
    color: #6c7075 !important;
    background-color: #cacaca !important;
  }

  // Override global default styles which set Poppins as font-family for everything (which is pretty bad!)
  .MuiButton-label {
    font-family: 'Montserrat', sans-serif !important;
  }
`;
