import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useAsync } from 'react-use';

import { getAudience } from '../../../../redux/services/audiences';
import { useAudiencesQuery } from '../../../../redux/services/queries/audiences';
import { selectedOrganizationSelector } from '../../../../redux/selectors/organization';
import AudienceCard from '../../../../Component/AudienceCard';
import NewCard from '../../../../Component/Drafts/Components/NewCard';
import {
  fetchOrgTopicsAndGroups,
  getGroupTypes,
} from '../../../../redux/services/groups';
import { loadGroups } from '../../../../redux/actions/groups';
import { loadTopics } from '../../../../redux/actions/topics';
import { loadGroupTypes } from '../../../../redux/actions/groupTypes';
import {
  useFullLoader,
  useFullScreenSpinner,
} from '../../../../redux/actions/UI';
import { useEditor } from '../CampaignEditor';
import Step from '../Step';
import {
  ActiveAudience,
  Container,
  FullLengthContainer,
} from './AudiencePicker.styled';

const CHUNK_SIZE = 30;

const useLoadTopicsAndGroups = () => {
  const dispatch = useDispatch();
  const activeOrganization = useSelector(selectedOrganizationSelector);

  const fetchGroupsAndTopics = useCallback(async () => {
    const { tags, topics } = await fetchOrgTopicsAndGroups(
      activeOrganization.id
    );
    dispatch(loadGroups(tags));
    dispatch(loadTopics(topics));
  }, [activeOrganization.id, dispatch]);

  const fetchGroupTypes = useCallback(async () => {
    const groupTypes = await getGroupTypes(activeOrganization.id);
    dispatch(loadGroupTypes(groupTypes));
  }, [activeOrganization.id, dispatch]);

  const status = useAsync(async () => {
    return Promise.all([fetchGroupsAndTopics(), fetchGroupTypes()]);
  }, [fetchGroupTypes, fetchGroupsAndTopics]);

  return !status.loading;
};

const AudiencePicker = () => {
  const editor = useEditor();

  const activeAudience = editor.getField('audience');
  const haveActiveAudience = !!activeAudience;

  const areTopicsAndGroupsLoaded = useLoadTopicsAndGroups();
  const {
    data,
    isLoading,
    isAnyPageLoaded,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useAudiencesQuery({
    getChunkSize: cursor => (cursor === 0 ? CHUNK_SIZE - 1 : CHUNK_SIZE),
  });

  useFullScreenSpinner(
    'Loading audiences...',
    !areTopicsAndGroupsLoaded || isLoading
  );

  const observer = useRef();
  const lastElemComponentRef = lastElement => {
    if (hasNextPage) {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          fetchNextPage();
        }
      });

      if (lastElement) observer.current.observe(lastElement);
    }
  };

  const handleBack = () => {
    editor.goToStep('overview');
  };

  const handleCreateNew = () => {
    editor.goToStep('audience/editor');
  };

  const fullLoader = useFullLoader();

  const goToEditAudience = audience => {
    editor.goToStep('audience/editor', { audience });
  };

  const handleEditActive = () => {
    goToEditAudience(activeAudience);
  };

  const handleEdit = audienceId => {
    fullLoader.during('Loading audience...', async () => {
      const audience = await getAudience(audienceId);
      goToEditAudience(audience);
    });
  };

  return (
    <Step
      title="Audience"
      description="Select the Audience that your new Campaign should watch"
      headerRightAddon={
        haveActiveAudience && (
          <ActiveAudience>
            <span>
              <ActiveAudience.Label>Active Audience: </ActiveAudience.Label>
              <ActiveAudience.Name>{activeAudience.name}</ActiveAudience.Name>
            </span>
            <ActiveAudience.EditButton onClick={handleEditActive}>
              <Edit fontSize="small" />
              Edit
            </ActiveAudience.EditButton>
          </ActiveAudience>
        )
      }
      onBack={handleBack}
    >
      <Container>
        <NewCard onClick={handleCreateNew} title="CREATE A NEW AUDIENCE" />
        {data.map(audience => (
          <AudienceCard
            key={audience._id}
            audience={audience}
            tooltip="Apply this audience to the campaign"
            onClick={() => {
              handleEdit(audience._id);
            }}
          />
        ))}
      </Container>
      {isAnyPageLoaded &&
        (isFetchingNextPage ? (
          <FullLengthContainer>
            <CircularProgress size={50} />
          </FullLengthContainer>
        ) : (
          <FullLengthContainer ref={lastElemComponentRef} />
        ))}
    </Step>
  );
};

export default AudiencePicker;
