// REDUCER FOR content list
export function contentlist(state = {}, action) {
  switch (action.type) {
    case 'CONTENT_DETAILS':
      return Object.assign({}, state, {
        content: action.detail,
      });
    default:
      return state;
  }
}
