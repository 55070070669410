import http from '../../../../utils/andromeda-api';

export const fetchEventsData = async ({ messageId, search }) => {
  const {
    data: { data },
  } = await http.get('/click/events', {
    params: { messageId, search },
  });

  return data;
};

export const fetchClickActivity = async ({ messageId, index }) => {
  const {
    data: { result },
  } = await http.get('/click/events/link', {
    params: { messageId, index },
  });

  return result;
};

export const exportEvents = async ({ messageId, urlIndexes }) => {
  const { data } = await http.post('/click/events/export', {
    messageId,
    urlIndexes,
  });

  return data;
};
