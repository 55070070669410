import { useEffect, useState } from 'react';

const useCountCardsPerRow = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const resetWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', resetWindowWidth);

    return () => {
      window.removeEventListener('resize', resetWindowWidth);
    };
  }, []);

  let numOfCardsPerRow = 2;
  if (windowWidth >= 1820) {
    numOfCardsPerRow = 7;
  } else if (windowWidth >= 1600) {
    numOfCardsPerRow = 6;
  } else if (windowWidth >= 1380) {
    numOfCardsPerRow = 5;
  } else if (windowWidth >= 1160) {
    numOfCardsPerRow = 4;
  } else if (windowWidth >= 940) {
    numOfCardsPerRow = 3;
  }

  return numOfCardsPerRow;
};

export default useCountCardsPerRow;
