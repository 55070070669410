import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { _has } from 'underscore-es';

import Sidenav from '../Sidenav';
import { Content, Wrapper } from './SidenavLayout.styled';

const SidenavLayout = ({
  menus,
  secondaryMenus,
  extraRoutes,
  renderHeader,
  renderFooter,
  renderContent,
}) => {
  const routes = useMemo(() => {
    const secondaryLinks = secondaryMenus.filter(menu => _has(menu, 'link'));
    const links = [...menus, ...secondaryLinks];
    const menuRoutes = links.map(({ link, isNested = false, content }) => {
      return { path: link, isExact: !isNested, content };
    });
    const extras = extraRoutes.map(({ path, isNested = false, content }) => {
      return { path, isExact: !isNested, content };
    });
    return [...menuRoutes, ...extras];
  }, [extraRoutes, menus, secondaryMenus]);

  return (
    <Wrapper>
      <Sidenav
        menus={menus}
        secondaryMenus={secondaryMenus}
        renderHeader={renderHeader}
        renderFooter={renderFooter}
      />
      <Content>
        {renderContent(
          <Switch>
            {routes.map(({ path, isExact, content }) => {
              return (
                <Route
                  key={path}
                  path={path}
                  component={content}
                  exact={isExact}
                />
              );
            })}
          </Switch>
        )}
      </Content>
    </Wrapper>
  );
};

SidenavLayout.defaultProps = {
  secondaryMenus: [],
  extraRoutes: [],
  renderHeader: undefined,
  renderFooter: undefined,
  renderContent: content => content,
};

export default SidenavLayout;
