import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as moment from 'moment';
import { Close, Chat, Archive, HelpOutline } from '@material-ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import { WechatOutlined } from '@ant-design/icons';
import { toast, resetUnreadCount } from '../../../redux/action';
import {
  lastTextMessageConversationIdSelector,
  lastMessageIdSentByOrg,
  lastMessageIdSentByUser,
  userDetailSelector,
  selectedOrganizationSelector,
  textingHubViewers,
} from '../../../redux/selector';
import {
  getFullNameOrPhoneNumber,
  calculateSmsCharacterLength,
  calculateNumOfSmsTextMessages,
  calculateSmsMaxLength,
  formatPhoneNumber,
} from '../utils';
import {
  sendTextMessage as sendTextMessageService,
  getMessageDataToSend,
  markMessageAsRead,
  fetchOrgTextMessages,
  getUnreadTextCount,
  fetchTextMessagesByConversationId,
} from '../api';
import {
  Container,
  Header,
  Content,
  Title,
  ChatHistory,
  ReplyButton,
  SendIcon,
  LeftChatMessage,
  RightChatMessage,
  Date,
  LeftSender,
  RightSender,
  MetaData,
  TextArea,
  ReplyText,
  ReplyActionArea,
  TextMetaData,
  ChatContainer,
  Line,
  TextHelp,
  SystemMessage,
  SystemSender,
  Italic,
  ErrorMsg,
  ReplyTextContainer,
  ViewedUsers,
  ViewedUserIcon,
  Bold,
} from './TextMessagesDrawer.styled';
import socketIOClient from 'socket.io-client';
import store from '../../../redux/store';

const TextMessagesDrawer = ({
  onClose,
  message,
  conversationMessages,
  setTextMessages,
  isDrawerOpen,
}) => {
  const dispatch = useDispatch();
  const lastUpdatedMsgConversationId = useSelector(
    lastTextMessageConversationIdSelector
  );
  const lastMsgIdSentByOrg = useSelector(lastMessageIdSentByOrg);
  const viewers = useSelector(textingHubViewers);
  const lastMessageIdSentByUserSelector = useSelector(lastMessageIdSentByUser);
  const lastElem = useRef(null);
  const errorMsgRef = useRef(null);
  const contact = {
    ...message.contact,
    contactPhone: message.contactPhone,
  };
  const [text, setText] = useState('');
  const [currentConversation, setCurrentConversation] =
    useState(conversationMessages);
  const [maxCharLength, setMaxCharLength] = useState(1530);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [numRows, setNumRows] = useState(3);
  const [errorMsg, setErrorMsg] = useState(null);
  const mounted = useRef(false);

  const loadMessages = async conversationId => {
    const messages = await fetchTextMessagesByConversationId(conversationId);
    setCurrentConversation(messages);
    // this is a hack to make sure that scrolling to bottom is triggered once the data is rerendered
    setScrollToBottom(false);
  };

  const getDrawerData = () => {
    const conversationId = conversationMessages[0]?.conversationId;
    const userId = userDetailSelector(store.getState()).id;
    const firstName = userDetailSelector(store.getState()).firstName || '';
    const lastName = userDetailSelector(store.getState()).lastName || '';
    const organizationId = selectedOrganizationSelector(store.getState()).id;
    return { conversationId, userId, organizationId, firstName, lastName };
  };

  useEffect(() => {
    const newSocket = socketIOClient(process.env.REACT_APP_andromedaAPI);
    newSocket.on('connect', () => {
      newSocket.emit('textDrawerOpened', getDrawerData());
    });

    mounted.current = true;
    return () => {
      mounted.current = false;
      newSocket.emit('textDrawerClosed', getDrawerData());
      newSocket.disconnect();
    };
  }, []);

  const getConversationViewers = () => {
    if (viewers && conversationMessages[0]?.conversationId in viewers) {
      const allViewers = viewers[conversationMessages[0]?.conversationId];
      return allViewers;
    }
    return [];
  };

  useEffect(() => {
    if (
      lastUpdatedMsgConversationId === conversationMessages[0].conversationId
    ) {
      loadMessages(lastUpdatedMsgConversationId);
    }
  }, [lastMessageIdSentByUserSelector]);

  useEffect(() => {
    if (
      lastMsgIdSentByOrg &&
      lastUpdatedMsgConversationId === conversationMessages[0].conversationId
    ) {
      loadMessages(lastUpdatedMsgConversationId);
    }
  }, [lastMsgIdSentByOrg]);

  const formatTime = date => {
    return moment(date).format('MMMM D, h:mm a');
  };

  const markUnreadMessagesIfAny = async lastMessage => {
    if (lastMessage.status === 'unread') {
      try {
        await markMessageAsRead(lastMessage.conversationId);
        const unreadCount = await getUnreadTextCount();
        dispatch(resetUnreadCount(unreadCount));
        const updatedMessage = { ...lastMessage, isRead: true, contact };
        setTextMessages(messages => [
          updatedMessage,
          ...messages.filter(
            msg => msg.conversationId !== updatedMessage.conversationId
          ),
        ]);

        setCurrentConversation(messages =>
          messages.map(msg => {
            msg.status = 'read';
            return msg;
          })
        );
      } catch (e) {
        console.log(e);
        dispatch(toast('error', 'Failed to mark latest messages as read'));
      }
    }
  };

  const renderChatHistory = conversationMessages => {
    const chatHistory = [];

    for (let i = 0; i < conversationMessages.length; i++) {
      if (conversationMessages[i].senderType === 'contact') {
        chatHistory.push(
          <ChatContainer key={conversationMessages[i]._id}>
            <MetaData>
              <LeftSender>
                {getFullNameOrPhoneNumber({
                  ...conversationMessages[i],
                  ...contact,
                })}
              </LeftSender>
              <Date>{formatTime(conversationMessages[i].createdAt)}</Date>
            </MetaData>
            <LeftChatMessage>{conversationMessages[i].body}</LeftChatMessage>
          </ChatContainer>
        );
      } else if (conversationMessages[i].keywordAutoreply) {
        chatHistory.push(
          <ChatContainer key={conversationMessages[i]._id}>
            <MetaData>
              <Date>{formatTime(conversationMessages[i].createdAt)}</Date>
              <SystemSender>NoteRouter System</SystemSender>
            </MetaData>
            <SystemMessage>
              {conversationMessages[i].systemMessage}
              <Italic>{conversationMessages[i].keywordAutoreply}</Italic>
            </SystemMessage>
          </ChatContainer>
        );
      } else if (conversationMessages[i].systemMessage) {
        chatHistory.push(
          <ChatContainer key={conversationMessages[i]._id}>
            <MetaData>
              <Date>{formatTime(conversationMessages[i].createdAt)}</Date>
              <SystemSender>NoteRouter System</SystemSender>
            </MetaData>
            <SystemMessage>
              {conversationMessages[i].systemMessage}
            </SystemMessage>
          </ChatContainer>
        );
      } else {
        chatHistory.push(
          <ChatContainer key={conversationMessages[i]._id}>
            <MetaData>
              <Date>{formatTime(conversationMessages[i].createdAt)}</Date>
              <RightSender>
                {getFullNameOrPhoneNumber({
                  ...conversationMessages[i],
                  ...contact,
                })}
              </RightSender>
            </MetaData>
            <RightChatMessage>{conversationMessages[i].body}</RightChatMessage>
          </ChatContainer>
        );
      }
    }
    chatHistory.push(<span key="lastElem" ref={lastElem} />);
    setTimeout(() => {
      setScrollToBottom(true);
    }, 1000);
    markUnreadMessagesIfAny(
      conversationMessages[conversationMessages.length - 1]
    );
    return chatHistory;
  };

  useEffect(() => {
    lastElem?.current?.scrollIntoView({ behavior: 'smooth' });
    if (errorMsg) {
      errorMsgRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToBottom]);

  const handleTextChange = event => {
    const text = event.target.value;
    const maxLength = calculateSmsMaxLength(text);
    setMaxCharLength(maxLength);

    if (calculateSmsCharacterLength(text) > maxLength) {
      let endChar = maxLength;
      // in case of copy paste for example, we allow only to paste just enough text to reach the max limit
      while (
        calculateSmsCharacterLength(text.substring(0, endChar)) > maxLength
      ) {
        endChar--;
      }
      setText(text.substring(0, endChar));
    } else {
      setText(text || '');
    }
  };

  const isMaxTextLengthReached = useMemo(() => {
    return calculateSmsCharacterLength(text) >= maxCharLength;
  }, [text, maxCharLength]);

  const textareaStyle = useMemo(() => {
    return isMaxTextLengthReached
      ? { borderColor: 'red', outline: 'none' }
      : {};
  }, [isMaxTextLengthReached]);

  const sendTextMessage = async () => {
    if (!text?.trim()) return;
    try {
      const { textMessage, user } = getMessageDataToSend({
        contactPhone: contact.contactPhone,
        body: text,
        conversationId: message._id,
      });
      setErrorMsg(null);
      setCurrentConversation(currentConversation => [
        ...currentConversation,
        // _id is just a temp value. I implemented it this way so that a person can see their sent message instantly
        { ...textMessage, user, _id: moment() },
      ]);
      setText('');
      // this is a hack to make sure that scrolling to bottom is triggered once the data is rerendered
      setScrollToBottom(false);
      await sendTextMessageService(textMessage);
      const [updatedMessage] = await fetchOrgTextMessages({
        conversationId: `${textMessage.conversationId}`,
      });
      setCurrentConversation(messages => [
        updatedMessage,
        ...messages.filter(
          msg => msg.conversationId !== updatedMessage.conversationId
        ),
      ]);
    } catch (error) {
      console.log('error', error);
      setScrollToBottom(false);
      setErrorMsg('Sorry, there was an error sending this reply.');
      setScrollToBottom(true);
    }
  };

  useEffect(() => {
    const savedText = localStorage.getItem('savedText');
    if (savedText?.trim()) {
      setText(savedText);
    }
  }, []);

  useEffect(() => {
    // every time the drawer is closed, trigger saving current text
    if (!isDrawerOpen) {
      localStorage.setItem('savedText', text.trim());
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    const calculateNumRows = () => {
      const drawerWidth = window.innerWidth * 0.4;
      // the formula below is calculated through trial and error
      const calculatedNumRows = Math.floor(text.length / (drawerWidth / 10));

      const numRows =
        calculatedNumRows < 4
          ? 3
          : calculatedNumRows > 10
          ? 10
          : calculatedNumRows;
      setNumRows(numRows);
    };

    calculateNumRows();

    const handleResize = () => {
      calculateNumRows();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [text]);

  const handleKeyPress = event => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      sendTextMessage();
    }
  };

  const getName = () => {
    const contact = message.contact;
    if (contact?.firstName || contact?.firstName) {
      return `${contact.firstName || ''} ${contact.lastName || ''}`;
    }
    return formatPhoneNumber(message?.contactPhone);
  };

  return (
    <Container>
      <Header>
        <span>
          <IconButton onClick={onClose}>
            <Close style={{ color: 'white' }} />
          </IconButton>
          Text conversation with {getName()}
        </span>
        {message.status === 'archived' ? (
          <Line>
            Archived
            <Archive style={{ fontSize: '18px' }} />
          </Line>
        ) : (
          ''
        )}
      </Header>
      <Content>
        <Title>
          <WechatOutlined style={{ color: '#007ADE', fontSize: '24px' }} />
          Conversation
        </Title>
        <ChatHistory>
          {renderChatHistory(currentConversation)}
          {errorMsg && <ErrorMsg ref={errorMsgRef}>{errorMsg}</ErrorMsg>}
        </ChatHistory>
        <ReplyTextContainer>
          <ReplyText>
            <Chat fontSize="small" />
            Reply
          </ReplyText>
          {getConversationViewers().length > 0 && (
            <ViewedUsers>
              <ViewedUserIcon />
              {'Currently being viewed by '}
              {getConversationViewers()[0]?.fullName}{' '}
              {getConversationViewers().length > 1
                ? ' and ' + getConversationViewers().length - 1 + ' user'
                : ''}
            </ViewedUsers>
          )}
        </ReplyTextContainer>
        {message.status === 'spammed' ? (
          <Bold>
            In order to send a reply, you must unspam the conversation first.
          </Bold>
        ) : (
          <>
            <TextArea
              placeholder="Type your reply here"
              rows={numRows}
              value={text}
              onChange={handleTextChange}
              maxLength={maxCharLength}
              style={textareaStyle}
              onKeyDown={handleKeyPress}
            />
            <ReplyActionArea>
              <TextMetaData>
                <span>
                  Characters: {calculateSmsCharacterLength(text)}/
                  {maxCharLength}
                </span>
                <TextHelp>
                  Texts: {calculateNumOfSmsTextMessages(text)}{' '}
                  <Tooltip title="A single SMS text segment can fit 153 to 160 characters. However, special characters like emojis reduce the maximum size of a text segment to 67 characters.">
                    <HelpOutline style={{ fontSize: '14px' }} />
                  </Tooltip>
                </TextHelp>
              </TextMetaData>
              <ReplyButton onClick={sendTextMessage} disabled={!text}>
                <SendIcon fontSize={'small'} /> Reply
              </ReplyButton>
            </ReplyActionArea>
          </>
        )}
      </Content>
    </Container>
  );
};

export default TextMessagesDrawer;
