import React, { useState } from 'react';
import { ArrowForward } from '@material-ui/icons';
import YoutubeVideo from './YoutubeVideo';
import {
  Container,
  Title,
  Text,
  VideoPlaceHolder,
  Column,
  Button,
  WatchDemoImg,
  TextingHubTourGif,
} from './Step.styled';
import watchDemo from './img/watch-demo.png';
import textingHubTourGif from './img/texting-hub-tour.gif';

const Welcome = ({ onClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Container>
      <Column>
        <br />
        <Title>Welcome to the Texting Hub!</Title>
        <br />
        <Text>
          With the Texting Hub, your members can finally text your team for
          support and reply back to your NoteRouter messages. Messages from
          contacts appear here in the Texting Hub so that your team can easily
          and collaboratively reply back. NoteRouter also remembers who&apos;s
          &ldquo;textable&ldquo;, so your text subscriber count for regular
          messages (e.g. dues reminders!) will increase significantly. Texts can
          now also be sent to/from your team&apos;s main office number, and you
          can also create unlimited custom text keywords to auto-reply to
          contacts as well.
          <br />
          <br />
          There&apos;s so much here and we&apos;re so excited for you to use it!
          Enable the Texting Hub for your account right here:
        </Text>
        <br />
        <Button onClick={onClick}>
          See pricing options <ArrowForward />
        </Button>
      </Column>
      <VideoPlaceHolder onClick={() => setIsModalOpen(true)}>
        <WatchDemoImg src={watchDemo} alt="watch the demo" />
        <TextingHubTourGif src={textingHubTourGif} alt="texting hub tour" />
      </VideoPlaceHolder>
      {isModalOpen && (
        <YoutubeVideo isModalOpen={isModalOpen} closeModal={closeModal} />
      )}
    </Container>
  );
};

export default Welcome;
