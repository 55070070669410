import styled from 'styled-components';
import { Done, Error } from '@material-ui/icons';

const ValidityIndicator = styled.span.attrs(({ isValid }) => ({
  as: isValid ? Done : Error,
}))`
  color: ${({ isValid }) => (isValid ? '#00cc8b' : '#ff6161')};
  font-size: 24px !important;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

export default ValidityIndicator;
