import { snakeCaseToKebabCase, mapKeys } from '../../../../libs/js-utils';
import {
  addStylesheet,
  defineCssVariables,
} from '../../../../libs/cssom-utils';
import { Color } from '../';

const NAMESPACE_SEPARATOR = ':';
const THEME_NAMESPACE = 'theme';
const COLOR_NAMESPACE = 'color';

const createThemeCssVariableName = (name, ...additionalNamespaces) => {
  const allNamespaces = [THEME_NAMESPACE, ...additionalNamespaces];
  const allSegments = [...allNamespaces, name];
  return allSegments.join(NAMESPACE_SEPARATOR);
};

const createCssColorName = standardColorNameInUpperSnakeCase => {
  return snakeCaseToKebabCase(standardColorNameInUpperSnakeCase.toLowerCase());
};

const createCssColorVariableName = colorName => {
  const cssColorName = createCssColorName(colorName);
  return createThemeCssVariableName(cssColorName, COLOR_NAMESPACE);
};

const collectCssColors = () => {
  return mapKeys(Color, createCssColorVariableName);
};

export const setup = () => {
  const cssColors = collectCssColors();
  const cssColorsDefinition = defineCssVariables(cssColors);

  addStylesheet(`
    :root {
      ${cssColorsDefinition}
    }
  `);
};
