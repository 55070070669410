import React, { useState } from 'react';
import { Drawer, Spin } from 'antd';
import { Box } from 'grommet';
import { CSVLink } from 'react-csv';
import MaterialTable, { MTableCell } from 'material-table';
import { LoadingOutlined } from '@ant-design/icons';

const SpamReport = ({
  onCloseDrawer,
  isVisible,
  isLoading,
  message,
  startDate,
  data,
  csvData,
}) => {
  const [pageSize, setPageSize] = useState(20);

  const antIcon = (
    <LoadingOutlined
      type="loading"
      style={{ fontSize: 24, float: 'right' }}
      spin
    />
  );

  return (
    <Drawer
      key={'spam report'}
      title="Spam Reports"
      width={735}
      onClose={onCloseDrawer}
      visible={isVisible}
      style={{
        overflow: 'scroll',
        paddingLeft: '0',
        paddingRight: '0',
      }}
    >
      <Spin
        spinning={isLoading}
        tip="Loading Spam Data ..."
        indicator={antIcon}
      >
        <div className="ManagePortalUserscomp ant-drawerpart">
          <Box overflow="auto">
            <div className="middlePart">
              <CSVLink
                className="export-drawer"
                data={csvData}
                filename={`Spam Reports ${
                  message
                    ? '- ' + message.messageSubject
                    : `${startDate} - ${new Date()}`
                }.csv`.replaceAll('/', '.')}
              >
                Export
              </CSVLink>
              <Box className="noterouter_managetable btnseparation">
                <MaterialTable
                  components={{
                    Cell: props => <MTableCell {...props} />,
                  }}
                  columns={[
                    {
                      title: 'Contact',
                      field: 'contact',
                      render: rowData => {
                        return <p>{rowData.contact}</p>;
                      },
                    },
                    {
                      title: 'Date',
                      field: 'time',
                      defaultSort: 'desc',
                      render: rowData => {
                        return <p>{rowData.time}</p>;
                      },
                    },
                    {
                      title: 'Event',
                      field: 'eventName',
                      render: rowData => {
                        return <p>{rowData.eventName}</p>;
                      },
                    },
                  ]}
                  data={data}
                  detailPanel={rowData => {
                    return (
                      <div className="detailPanel">
                        <p>
                          <b>Error Reason: </b>
                          {rowData.errorCode + ' ' + rowData.errorMessage}
                        </p>
                      </div>
                    );
                  }}
                  onRowClick={(event, rowData, togglePanel) => togglePanel()}
                  className="Manage_materialtable"
                  title=""
                  localization={{
                    body: {
                      emptyDataSourceMessage:
                        'No recorded spam reports for this time period',
                    },
                  }}
                  options={{
                    padding: 'dense',
                    search: true,
                    pageSize: pageSize,
                    pageSizeOptions: [10, 20, 50],
                  }}
                  onChangeRowsPerPage={e => {
                    localStorage.setItem('pageSize', e);
                    setPageSize(e);
                  }}
                />
              </Box>
            </div>
          </Box>
        </div>
      </Spin>
    </Drawer>
  );
};

export default SpamReport;
