import React, { useState } from 'react';
import { PreferenceContext } from '../../redux/services';
import { OptinMessage } from '../../Component/OptinMessage';

const OptinMessagePage = () => {
  const [topics, setTopics] = useState([]);
  const [member, setMember] = useState({});
  const [organization, setOrganization] = useState({});
  const [newTextTopics, setTextTopics] = useState({});
  const [newEmailTopics, setEmailTopics] = useState({});
  const [loading, setLoading] = useState(false);

  return (
    <PreferenceContext.Provider
      value={{
        member,
        setMember,
        organization,
        setOrganization,
        topics,
        setTopics,
        newTextTopics,
        setTextTopics,
        newEmailTopics,
        setEmailTopics,
        loading,
        setLoading,
      }}
    >
      <OptinMessage />
    </PreferenceContext.Provider>
  );
};

export default OptinMessagePage;
