import React, { useCallback, useRef, useState } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../Modal';
import { createNewCustomGroup } from '../../redux/services/groups';
import { selectedOrganizationSelector } from '../../redux/selectors/organization';
import styles from './CreateGroupModal.module.css';
import { fullloader, toast } from '../../redux/actions/UI';

const useTextInputState = () => {
  const [text, setText] = useState('');

  const handleChangeEvent = useCallback(event => {
    setText(event.target.value);
  }, []);

  return [text, handleChangeEvent];
};

const CreateGroupModal = ({
  title = 'Create New Group',
  confirmText = 'Create',
  cancelText = 'Cancel',
  onSuccess = () => {},
  onFailure = () => {},
  onClose,
  onCancel = onClose,
}) => {
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(selectedOrganizationSelector);

  const nameFieldRef = useRef(null);

  const [name, handleNameChange] = useTextInputState();
  const isNameValid = name.trim().length > 0;
  const [isNameFieldTouched, setIsNameFieldTouched] = useState(false);
  const markNameFieldTouched = useCallback(() => {
    setIsNameFieldTouched(true);
  }, []);
  const isNameFieldErrorVisible = isNameFieldTouched && !isNameValid;

  const [description, handleDescriptionChange] = useTextInputState();

  const [isLoading, setIsLoading] = useState(false);
  const handleConfirm = useCallback(async () => {
    if (!isNameValid) {
      markNameFieldTouched();
      nameFieldRef.current.focus();
      return;
    }

    setIsLoading(true);
    dispatch(fullloader(true, 'Creating a new group'));

    const normalizedDescription = description.trim();
    const { data, error } = await createNewCustomGroup({
      organizationId: selectedOrganization.id,
      name,
      description: normalizedDescription === '' ? null : normalizedDescription,
    });

    setIsLoading(false);
    dispatch(fullloader(false));

    if (error) {
      dispatch(toast('error', 'Failed to create a new group'));
      onFailure();
    } else {
      dispatch(toast('success', 'Successfully created a new group'));
      onSuccess(data);
    }
  }, [
    description,
    dispatch,
    isNameValid,
    markNameFieldTouched,
    name,
    onFailure,
    onSuccess,
    selectedOrganization.id,
  ]);

  return (
    <Modal
      title={title}
      onClose={onClose}
      primaryButton={{
        label: confirmText,
        onClick: handleConfirm,
        isLoading,
      }}
      secondaryButton={{
        label: cancelText,
        onClick: onCancel,
      }}
    >
      <div className={styles.form}>
        <TextField
          inputRef={nameFieldRef}
          placeholder="Name *"
          variant="outlined"
          size="small"
          value={name}
          onChange={handleNameChange}
          error={isNameFieldErrorVisible}
          helperText={isNameFieldErrorVisible ? 'Name is required' : ''}
          onBlur={markNameFieldTouched}
          fullWidth
        />
        <TextField
          placeholder="Description"
          variant="outlined"
          size="small"
          value={description}
          onChange={handleDescriptionChange}
          fullWidth
          multiline
          rows={3}
        />
        <TextField
          select
          variant="outlined"
          size="small"
          value="custom"
          fullWidth
          disabled
        >
          <MenuItem value="custom">Category: Custom</MenuItem>
        </TextField>
      </div>
    </Modal>
  );
};

export default CreateGroupModal;
