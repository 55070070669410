import React from 'react';
import YouTube from 'react-youtube';
import { Modal, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { YoutubeContainer, YoutubeBox, YoutubeHeader } from './Step.styled';

const YoutubeVideo = ({ isModalOpen, closeModal }) => {
  const opts = {
    height: '600',
    width: '1000',
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <Modal open={isModalOpen} onClose={closeModal}>
      <YoutubeContainer onClick={closeModal}>
        <YoutubeBox>
          <YoutubeHeader>
            <IconButton onClick={closeModal} aria-label="close">
              <Close />
            </IconButton>
          </YoutubeHeader>
          <YouTube videoId="ledax9u4ojc" opts={opts} />
        </YoutubeBox>
      </YoutubeContainer>
    </Modal>
  );
};

export default YoutubeVideo;
