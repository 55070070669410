import styled from 'styled-components';

export const MissingPhoneStyled = styled.p`
  color: #585858;
  background: rgba(255, 0, 0, 0.1);
  font-size: 14px;
  padding: 8px 15px;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 3px;
    height: 100%;
    left: 0;
    top: 0;
    background: red;
  }

  .color-blue {
    color: #1890ff;
  }
`;
