import { createContext } from 'react';

export const NotesContext = createContext({
  noteRouterId: null,
  organizationId: null,
  username: '',

  notes: [],
  setNotes: notes => {
    return notes;
  },

  loading: false,
  setLoading: loading => loading,

  startDate: null,
  setStartDate: date => {
    return date;
  },

  endDate: null,
  setEndDate: date => {
    return date;
  },

  search: null,
  setSearch: date => {
    return date;
  },
});
