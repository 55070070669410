import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MaterialTable, { MTableCell } from 'material-table';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import useWindowDimensions from '../../../../../features/events-and-classes/utils/useWindowDimensions';
import { fetchEventsData } from '../api';
import { toast } from '../../../../../redux/actions/UI';
import { Container } from './ClickActivityTable.styled';
import ClickPanel from './ClickPanel/ClickPanel';

const columns = [
  {
    field: 'linkIndex',
    title: '#',
    width: '5%',
    defaultSort: 'asc',
  },
  {
    field: 'link',
    title: 'Link URL',
    width: '65%',
    cellStyle: {
      'overflow-x': 'hidden',
      'text-overflow': 'ellipsis',
    },
  },
  {
    field: 'totalClicks',
    title: 'Total Clicks',
    width: '15%',
  },
  {
    field: 'uniqueClicks',
    title: 'Unique Clicks',
    width: '15%',
  },
];

const ClickActivityTable = ({
  messageId,
  rows,
  setRows,
  isLoading,
  setLoading,
  setSelectedRows,
}) => {
  const { height } = useWindowDimensions();
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedRows([]);
  }, []);

  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#1890FF',
      },
    },
  });

  useEffect(() => {
    fetchEvents();
  }, [messageId]);

  const fetchEvents = async () => {
    try {
      setLoading(true);

      const data = await fetchEventsData({ messageId });
      const rows = data?.activities?.reverse().map(clickActivity => ({
        _id: `${clickActivity.link}-${clickActivity.index}`,
        link: clickActivity.link,
        linkIndex: clickActivity.linkIndex + 1,
        totalClicks: clickActivity.totalClicks,
        uniqueClicks: clickActivity.uniqueClicks,
      }));
      setLoading(false);
      setRows(rows);
    } catch (e) {
      console.error(e);
      dispatch(toast('error', 'Failed to fetch events data'));
    }
  };

  const getEmptyMessage = () => {
    return isLoading ? (
      <>No records to display</>
    ) : (
      <>No click events found for this message.</>
    );
  };

  const detailPanel = ({ linkIndex }) => {
    return <ClickPanel linkIndex={linkIndex} messageId={messageId} />;
  };

  const handleRowSelection = selectedRows => {
    setSelectedRows(selectedRows);
  };

  return (
    <Container>
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          isLoading={isLoading}
          components={{
            Cell: props => <MTableCell {...props} />,
          }}
          detailPanel={data => detailPanel(data)}
          columns={columns}
          data={rows}
          options={{
            showTitle: false,
            search: false,
            pageSize: Math.round((height - 300) / 48),
            toolbar: false,
            selection: true,
            headerStyle: {
              fontWeight: 'bold',
            },
            pageSizeOptions: [],
            tableLayout: 'fixed',
          }}
          onRowClick={(e, data, togglePanel) => {
            togglePanel();
          }}
          localization={{
            body: {
              emptyDataSourceMessage: getEmptyMessage(),
            },
          }}
          onSelectionChange={handleRowSelection}
        />
      </MuiThemeProvider>
    </Container>
  );
};

export default ClickActivityTable;
