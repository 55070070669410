import React, { useState } from 'react';
import { Drawer, IconButton } from '@material-ui/core';
import { Close, NaturePeople } from '@material-ui/icons';
import { updateKeyOrgContacts } from '../../../../../redux/services';
import { isEmailValid } from '../../utils';
import {
  Container,
  Header,
  Content,
  SaveBtn,
  Title,
  Group,
} from './EditOrgContactsDrawer.styled';
import { Title as ContentTitle } from '../GeneralInfo.styled';
import { CONTACT_LIST } from '../OrganizationContacts/constants';
import Contacts from './Contacts';
import { useToast, useUpdateUIState } from '../../../../../redux/action';

const EditOrgContactsDrawer = ({
  isEditOrgContactsDrawerVisible,
  setEditOrgContactsDrawerVisibility,
  keyOrgContacts,
  keyOrgContactsObjTransformed,
  action,
  orgId,
}) => {
  const { errorToast, successToast } = useToast();
  const { fullLoader } = useUpdateUIState();
  const [isSaveBtnClicked, setSaveBtnClicked] = useState(false);
  const [contactsInfo, setContactsInfo] = useState({});

  const closeDrawer = () => {
    setEditOrgContactsDrawerVisibility(false);
  };

  const transformDataForSaving = () => {
    const keyRoles = Object.keys(contactsInfo);

    if (!keyRoles.length) return false;

    const allRequiredFieldsPresent = keyRoles.every(role => {
      // check if all required fields are there for a newly created contact
      if (
        !keyOrgContactsObjTransformed[role] &&
        (!contactsInfo[role].firstName ||
          !contactsInfo[role].lastName ||
          !contactsInfo[role].email ||
          !isEmailValid(contactsInfo[role].email))
      ) {
        return false;
      }

      // for an existing contact make sure that an updated field is valid
      if (
        keyOrgContactsObjTransformed[role] &&
        ((contactsInfo[role].firstName &&
          contactsInfo[role].firstName === '') ||
          (contactsInfo[role].lastName && contactsInfo[role].lastName === '') ||
          (contactsInfo[role].email && !isEmailValid(contactsInfo[role].email)))
      ) {
        return false;
      }

      return true;
    });

    if (!allRequiredFieldsPresent) {
      return false;
    }

    const result = keyRoles.map(role => {
      if (keyOrgContactsObjTransformed[role]) {
        return {
          _id: keyOrgContactsObjTransformed[role]._id,
          ...contactsInfo[role],
        };
      } else {
        return { ...contactsInfo[role], role, organizationId: orgId };
      }
    });

    return result;
  };

  const handleSubmit = async () => {
    setSaveBtnClicked(true);

    const dataToSave = transformDataForSaving();
    if (!dataToSave) {
      errorToast('Please fill out all required fields correctly.');
      return;
    }

    // 'ACTION_NAME' is just a part of a verb and there is no typo, as I am using it to create present continuous and past simple tenses.
    const ACTION_NAME = action === 'Add' ? 'Add' : 'Updat';

    fullLoader(true, `${ACTION_NAME}ing key organization users.`);
    const createdKeyContacts = await updateKeyOrgContacts(dataToSave);
    if (!createdKeyContacts) {
      errorToast(`Error ${ACTION_NAME}ing key contacts.`);
    } else {
      successToast(`${ACTION_NAME}ed key contacts successfully!`);
    }

    closeDrawer();
    fullLoader(false);
  };

  return (
    <Drawer
      anchor={'right'}
      onClose={closeDrawer}
      open={isEditOrgContactsDrawerVisible}
    >
      <Container>
        <Header>
          <Group>
            <IconButton onClick={closeDrawer} style={{ color: 'white' }}>
              <Close />
            </IconButton>
            <Title>{action}: Key Contacts</Title>
          </Group>
          <SaveBtn onClick={handleSubmit}>Save</SaveBtn>
        </Header>
        <Content>
          <ContentTitle>
            <NaturePeople size="small" />
            Key Contacts
          </ContentTitle>
          {CONTACT_LIST.map(title => (
            <Contacts
              key={title}
              action={action}
              isSaveBtnClicked={isSaveBtnClicked}
              title={title}
              contact={
                keyOrgContacts.filter(contact => contact.role === title)?.[0]
              }
              setContactsInfo={setContactsInfo}
            />
          ))}
        </Content>
      </Container>
    </Drawer>
  );
};

export default EditOrgContactsDrawer;
