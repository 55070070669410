import React, { useState, useRef } from 'react';
import Tabs from './Tabs';
import Content from './Content';
import { Container } from './Organization.styled';

const Organization = () => {
  const [selectedTab, setSelectedTab] = useState('General Info');
  const [csvUserData, setCsvUserData] = useState([]);
  const [isDeleteUserModalVisible, setDeleteUserModalVisibility] =
    useState(false);
  const [isAddUserDrawerVisible, setAddUserDrawerVisibility] = useState(false);
  const isDeleteUserBtnDisabledRef = useRef(true);

  const handleTabSelect = tab => {
    setSelectedTab(tab);
  };

  return (
    <Container>
      <Tabs
        onSelectTab={handleTabSelect}
        selectedTab={selectedTab}
        csvUserData={csvUserData}
        setDeleteUserModalVisibility={setDeleteUserModalVisibility}
        setAddUserDrawerVisibility={setAddUserDrawerVisibility}
        isDeleteUserBtnDisabledRef={isDeleteUserBtnDisabledRef}
      />
      <Content
        setAddUserDrawerVisibility={setAddUserDrawerVisibility}
        setDeleteUserModalVisibility={setDeleteUserModalVisibility}
        isDeleteUserModalVisible={isDeleteUserModalVisible}
        selectedTab={selectedTab}
        setCsvUserData={setCsvUserData}
        isDeleteUserBtnDisabledRef={isDeleteUserBtnDisabledRef}
        isAddUserDrawerVisible={isAddUserDrawerVisible}
      />
    </Container>
  );
};

export default Organization;
