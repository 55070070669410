import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 3;
  min-height: 250px;
`;

export const SpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Button = styled.button`
  font-size: 14px;
  border-radius: 4px;
  align-items: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-family: Poppins, sans-serif;
  padding: 3px 15px;
  color: #6c7075;
  border: 1px solid #6c7075;
  background-color: white;
  display: flex;
  gap: 7px;
  height: 32px;
  line-height: 16px;
  &:hover {
    color: white;
    background-color: #007ade;
    border: 1px solid #007ade;
    cursor: pointer;
  }
`;

export const DeleteBtn = styled.button`
  font-size: 14px;
  border-radius: 4px;
  align-items: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-family: Poppins, sans-serif;
  padding: 3px 10px;
  color: white;
  border: ${props =>
    props.disabled ? '1px solid #6c7075' : '1px solid #fa7470'};
  background-color: ${props => (props.disabled ? '#6c7075' : '#fa7470')};
  display: flex;
  height: 32px;
  line-height: 16px;
  &:hover {
    background-color: #fa7470;
    border: 1px solid #fa7470;
    cursor: pointer;
  }
  &:disabled {
    background-color: #6c7075;
    border: 1px solid #6c7075;
    cursor: not-allowed;
  }
`;

export const Group = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  gap: 15px;
`;
