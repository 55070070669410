import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import initState from './initState';
import rootReducer from './rootReducer';
import observeEnhancer from './observeEnhancer';

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  rootReducer,
  initState,
  composeEnhancers(observeEnhancer, applyMiddleware(thunk))
);

export default store;
