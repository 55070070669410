import styled from 'styled-components';

export const IconHeadingStyled = styled.div`
  display: flex;
  align-items: center;

  & svg {
    color: #007ade;
    margin-right: 8px;
    width: 18px;
    height: 18px;
  }

  & h3 {
    font-family: Montserrat;
    color: #007ade;
    margin-bottom: 0;
    font-weight: 600;
  }
`;
