import React, { useState, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import { REMOVE } from '../../../../constants';

const ActionIcon = ({ Icon, title, colorOnSelect, onSelected, selected }) => {
  const [active, setActive] = useState(selected);

  useEffect(() => {
    setActive(selected);
  }, [selected]);

  const handleClick = () => {
    if (!active) {
      onSelected(title);
    } else {
      onSelected(REMOVE);
    }
    setActive(!active);
  };

  return (
    <Tooltip title={title}>
      <Icon
        style={{ fill: active ? colorOnSelect : 'silver', marginRight: '10px' }}
        onClick={handleClick}
      />
    </Tooltip>
  );
};

export default ActionIcon;
