import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  AccountCircle,
  BarChart,
  Cake,
  Description,
  EventAvailable,
  Group,
  GroupWork,
  Help,
  Home,
  Mail,
  Message,
  PowerSettingsNew,
  RecordVoiceOver,
  ToggleOn,
} from '@material-ui/icons';

import { useIsFeatureEnabled } from '../../libs/feature-toggles/react';
import { SidenavLayout } from '../../infrastructure/ui/navigation';
import Feature from '../../infrastructure/features';
import {
  userDetailSelector,
  userPrivilegesSelector,
  selectedOrganizationSelector,
} from '../../redux/selector';
import { getUnreadTextCount } from '../../features/texting-hub/api';
import { logout, resetUnreadCount } from '../../redux/action';
import useUserAuth from '../../utils';
import OrganizationPicker from '../OrganizationPicker';
import View from '../View/View';
import Manage from '../Manage/Manage';
import Analytics from '../Analytics/Analytics';
import EngagementLogs from '../EngagementLogs/Engagement';
import { View as AutomationView } from '../../features/automated-campaigns';
import OptinlogsTable from '../OptinlogsTable/OptinlogsTable';
import Organization from '../../Pages/Organization';
import Drafts from '../Drafts';
import Events from '../../features/events-and-classes';
import TextingHub from '../../features/texting-hub';
import SuperuserDashboard from '../SuperuserDashboard';

const useHelpWidget = () => {
  const user = useSelector(userDetailSelector);

  const open = () => {
    window.FreshworksWidget('identify', 'ticketForm', {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
    });
    window.FreshworksWidget('open');
  };

  const close = () => {
    window.FreshworksWidget('close');
  };

  const toggle = () => {
    const isOpen = document.getElementById('freshworks-frame-wrapper') !== null;
    if (isOpen) {
      close();
    } else {
      open();
    }
  };

  return { open, close, toggle };
};

const usePrimaryMenus = () => {
  const isAutomatedCampaignsFeatureEnabled = useIsFeatureEnabled(
    Feature.AUTOMATED_CAMPAIGNS
  );
  const isEventsAndClassesFeatureEnabled = useIsFeatureEnabled(
    Feature.EVENTS_AND_CLASSES
  );
  const isTextingHubEnabled = useIsFeatureEnabled(Feature.TEXTING_HUB);
  const userPrivilegesData = useSelector(userPrivilegesSelector);

  return [
    {
      key: 'Dashboard',
      link: '/dashboard',
      icon: Home,
      label: 'Home',
      content: View,
    },
    userPrivilegesData.editor && {
      key: 'Send Message',
      link: '/dashboard/drafts',
      icon: Mail,
      label: 'Create & Send',
      content: Drafts,
    },
    isAutomatedCampaignsFeatureEnabled && {
      key: 'Automation',
      link: '/dashboard/automation',
      icon: Cake,
      label: 'Automation',
      isNested: true,
      content: AutomationView,
    },
    isTextingHubEnabled && {
      key: 'Texting Hub',
      link: '/dashboard/textinghub',
      icon: Message,
      label: 'Texting Hub',
      isNested: true,
      content: TextingHub,
      hasNotifications: true,
    },
    {
      key: 'Manage',
      link: '/dashboard/manage',
      icon: Group,
      label: 'Manage',
      content: Manage,
    },
    isEventsAndClassesFeatureEnabled && {
      key: 'Events',
      link: '/dashboard/events',
      icon: EventAvailable,
      label: 'Events',
      content: Events,
    },
    userPrivilegesData.analytics && {
      key: 'Analytics',
      link: '/dashboard/analytics',
      icon: BarChart,
      label: 'Analytics',
      content: Analytics,
    },
    userPrivilegesData.engagement && {
      key: 'Member Engagement',
      link: '/dashboard/engagementlogs',
      icon: RecordVoiceOver,
      label: 'Engagement',
      content: EngagementLogs,
    },
    userPrivilegesData.isSU && {
      key: 'Optin Logs',
      link: '/dashboard/optinlogs',
      icon: ToggleOn,
      label: '⁂ Preferences',
      content: OptinlogsTable,
    },
    userPrivilegesData.isSU && {
      key: 'Superuser Dashboard',
      link: '/dashboard/superuser',
      icon: Description,
      label: '⁂ Superuser Dashboard',
      content: SuperuserDashboard,
    },
  ].filter(item => typeof item === 'object');
};

const useSecondaryMenu = () => {
  useUserAuth();

  const dispatch = useDispatch();
  const history = useHistory();

  const userDetail = useSelector(userDetailSelector);
  const userPrivilegesData = useSelector(userPrivilegesSelector);

  const helpWidget = useHelpWidget();

  return [
    {
      key: 'User',
      icon: AccountCircle,
      label: `User (${userDetail.firstName ?? 'Unknown'})`,
    },
    {
      key: 'Help',
      icon: Help,
      label: 'Help',
      action: helpWidget.toggle,
    },
    {
      key: 'Organization',
      link: '/dashboard/organization',
      isNested: true,
      icon: GroupWork,
      label: 'Organization',
      isDisabled: !userPrivilegesData.manageOrgAdmin,
      content: Organization,
    },
    {
      key: 'Logout',
      action: () => {
        dispatch(logout(history));
      },
      icon: PowerSettingsNew,
      label: 'Logout',
    },
  ];
};

const Sidenav = ({ extraRoutes, renderContent, footer }) => {
  const primaryMenus = usePrimaryMenus();
  const secondaryMenus = useSecondaryMenu();
  const orgId = useSelector(selectedOrganizationSelector).id;
  const dispatch = useDispatch();

  const loadUnreadMessagesCount = async () => {
    const unreadCount = await getUnreadTextCount(orgId);
    dispatch(resetUnreadCount(unreadCount));
  };

  useEffect(() => {
    if (orgId) {
      loadUnreadMessagesCount();
    }
  }, [orgId]);

  return (
    <SidenavLayout
      menus={primaryMenus}
      secondaryMenus={secondaryMenus}
      renderHeader={({ isCollapsed }) => (
        <OrganizationPicker isThumbnailOnly={isCollapsed} />
      )}
      renderFooter={({ isCollapsed }) => !isCollapsed && footer}
      extraRoutes={extraRoutes}
      renderContent={renderContent}
    />
  );
};

export default Sidenav;
