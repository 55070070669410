import styled from 'styled-components';
import { Layout } from 'antd';

import { Color } from '../../theming';

export const BaseSider = styled(Layout.Sider).attrs({
  width: 260,
  collapsedWidth: 75,
})`
  position: relative;
  background-color: ${Color.PRIMARY};
  padding: 20px 10px;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    position: absolute;
    z-index: 9;
    left: 0;
    height: 100%;

    &.ant-layout-sider-collapsed {
      position: absolute;
      z-index: 9;
      left: -80px;
      height: 100%;
    }
  }
`;

BaseSider.Collapse = styled.div`
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 35px 2px 35px 0;
  font-size: 12px;
  color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: ${Color.PRIMARY};
  cursor: pointer;
  z-index: 999; // this specific value is chosen to be higher than loader overlay
`;
