import React from 'react';
import { ArrowForward, ArrowBackSharp } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import {
  Container,
  Title,
  FullWidthColumn,
  FullWidthText,
  Button,
  SubTitle,
  LineChartIcon,
  InfoIcon,
  HighlightedText,
  Warning,
  BackButton,
  Text,
  BulletPoint,
} from './Step.styled';
import { isUserActionAllowed } from '../../utils';

const Pricing = ({ onClick, userPrivilages, returnBack }) => {
  const isAuthorizedToCheckout = isUserActionAllowed({
    userPrivilages,
    role: 'Staff Admin',
  });

  return (
    <Container width="1000px">
      <BackButton onClick={returnBack}>
        <ArrowBackSharp style={{ color: '#6c7075' }} />
      </BackButton>
      <FullWidthColumn>
        <Title color="#007ade">Flex-Friendly</Title>
        <SubTitle>Simple and usage-based</SubTitle>
        <LineChartIcon />
        <FullWidthText>
          <BulletPoint /> Pay only for the text replies your team sends each
          month <br />
          <BulletPoint /> All incoming texts from contacts are free <br />
          <BulletPoint /> No credits or usage limits to manage <br />
          <BulletPoint /> Text replies can come from your team&apos;s office
          number
          <Tooltip
            title="Includes a done-for-you hosting process by the NoteRouter team that can take up to a few weeks."
            placement="top"
            style={{ justifyContent: 'center' }}
          >
            <InfoIcon />
          </Tooltip>
          <br />
          <BulletPoint />
          Unlimited custom keywords (for automated text replies)
          <br />
          <BulletPoint />
          &ldquo;Create & Send&ldquo; message texts are still included as always
        </FullWidthText>
        <HighlightedText>
          $0.04 per sent text segment
          <Tooltip
            title="Typically, one SMS text segment is sent per 153 - 160 characters, but certain characters like emojis use up to 2 characters."
            placement="top"
            style={{ justifyContent: 'center' }}
          >
            <InfoIcon />
          </Tooltip>
        </HighlightedText>
        <br />
        <Button
          width="160px"
          onClick={onClick}
          disabled={!isAuthorizedToCheckout}
        >
          Select <ArrowForward />
        </Button>
        <br />
        <Text size="13px">
          Have a question? Email us at support@noterouter.com
        </Text>
        {!isAuthorizedToCheckout && (
          <Warning>
            Sorry, only Staff Admin users can activate the Texting Hub.
          </Warning>
        )}
      </FullWidthColumn>
    </Container>
  );
};

export default Pricing;
