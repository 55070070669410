import styled from 'styled-components';

export const ExportButton = styled.button`
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  border-radius: 4px;
  text-align: center;
  width: 120px;
  height: 34px;
  color: #007ade;
  border: 1px solid #509ee5;
  transition: all 0.3s ease-in;
  background-color: white;
  &:hover,
  &.active {
    color: white;
    background-color: #007ade;
    border: 1px solid #007ade;
  }
  &:disabled {
    pointer-events: all;
    cursor: not-allowed;
    color: grey;
    border-color: grey;
    background-color: transparent;
  }
`;
