const SEVEN_DAYS = '7D';
const ONE_MONTH = '1M';
const THREE_MONTHS = '3M';
const SIX_MONTHS = '6M';
const ONE_YEAR = '1Y';
const ALL_TIME = 'All Time';

export const ALL_TIME_VAL = -1;
export const SEVEN_DAYS_VAL = 7;

export const DateFilterRanges = [
  SEVEN_DAYS,
  ONE_MONTH,
  THREE_MONTHS,
  SIX_MONTHS,
  ONE_YEAR,
  ALL_TIME,
];

export const TEXT_TO_DAYS = {
  [SEVEN_DAYS]: 7,
  [ONE_MONTH]: 30,
  [THREE_MONTHS]: 90,
  [SIX_MONTHS]: 180,
  [ONE_YEAR]: 365,
  [ALL_TIME]: -1,
};

export const DAYS_TO_TEXT = {
  7: SEVEN_DAYS,
  30: ONE_MONTH,
  90: THREE_MONTHS,
  180: SIX_MONTHS,
  365: ONE_YEAR,
  [-1]: ALL_TIME,
};
