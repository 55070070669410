import React from 'react';
import Users from './Users';
import GeneralInfo from './GeneralInfo';
import AMSSync from './AMSSync';
import PreferencePage from './PreferencePage';
import {
  GENERAL_INFO,
  USERS,
  AMS_SYNC,
  PREFERENCE_PAGE,
} from '../Tabs/constants';

const Content = ({
  selectedTab,
  setCsvUserData,
  isDeleteUserModalVisible,
  setDeleteUserModalVisibility,
  isAddUserDrawerVisible,
  setAddUserDrawerVisibility,
  isDeleteUserBtnDisabledRef,
}) => {
  const renderContent = () => {
    switch (selectedTab) {
      case GENERAL_INFO:
        return <GeneralInfo />;
      case USERS:
        return (
          <Users
            setCsvUserData={setCsvUserData}
            isAddUserDrawerVisible={isAddUserDrawerVisible}
            setAddUserDrawerVisibility={setAddUserDrawerVisibility}
            setDeleteUserModalVisibility={setDeleteUserModalVisibility}
            isDeleteUserModalVisible={isDeleteUserModalVisible}
            isDeleteUserBtnDisabledRef={isDeleteUserBtnDisabledRef}
          />
        );
      case AMS_SYNC:
        return <AMSSync />;
      case PREFERENCE_PAGE:
        return <PreferencePage />;
      default:
        return null;
    }
  };

  return <>{renderContent()}</>;
};

export default Content;
