import styled from 'styled-components';

export const Text = styled.span`
  font-size: 16px;
  color: #707070;
`;

export const SmallText = styled.span`
  font-size: 13px;
  color: #707070;
`;

export const AddBtn = styled.button`
  background-color: transparent;
  border: none;
  font-weight: bold;
  color: #007ade;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  width: fit-content;
`;

export const FitContent = styled.span`
  width: fit-content;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.gap || '10px'};
`;
