import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { LibraryBooks, People, Power } from '@material-ui/icons';

import { useToggle } from '../../../../libs/react-use-extra';
import { useToast } from '../../../../redux/actions/UI';
import { FormSection, TextAreaField, TextField } from '../../forms';
import StatusPicker from '../../StatusPicker';
import { useEditor } from '../CampaignEditor';
import Button from '../Button';
import {
  AudienceField,
  DescriptionArea,
  FieldGroup,
  Sections,
  Step,
} from './Overview.styled';

const useValidator = () => {
  const editor = useEditor();

  const isNameValid = validateName(editor.getField('name'));
  const isAudienceValid = validateAudience(editor.getField('audience'));
  const isValid = isNameValid && isAudienceValid;

  function validateName(value) {
    return typeof value === 'string' && value.trim().length > 0;
  }

  function validateAudience(value) {
    return typeof value === 'object' && value !== null;
  }

  return { isValid, name: validateName, audience: validateAudience };
};

const Overview = () => {
  const [isValidationVisible, { on: showValidation }] = useToggle();
  const validator = useValidator();

  const editor = useEditor();
  const { errorToast } = useToast();

  const audience = editor.getField('audience');

  const goToNextStep = useCallback(() => {
    if (!isValidationVisible) {
      showValidation();
    }

    if (validator.isValid) {
      editor.goToStep('stages');
    } else {
      errorToast(
        'Some fields are invalid. Please correct them and try again.',
        5
      );
    }
  }, [
    isValidationVisible,
    validator.isValid,
    showValidation,
    editor,
    errorToast,
  ]);

  const history = useHistory();
  const leave = () => {
    history.push('/dashboard/automation');
  };

  return (
    <>
      <Step
        title="Overview"
        description="General overview of your new campaign"
        nextText="Setup Stages"
        onBack={leave}
        onNext={goToNextStep}
      >
        <Sections>
          <FormSection IconComponent={Power} title="Status">
            <StatusPicker
              value={editor.getField('status')}
              onChange={newStatus => editor.setField('status', newStatus)}
            />
          </FormSection>
          <FormSection IconComponent={LibraryBooks} title="Details">
            <FieldGroup>
              <TextField
                value={editor.getField('name')}
                onChange={event => editor.setField('name', event.target.value)}
                placeholder="Campaign Name"
                isValidationVisible={isValidationVisible}
                validate={validator.name}
              />
              <TextAreaField
                value={editor.getField('description')}
                onChange={event =>
                  editor.setField('description', event.target.value)
                }
                placeholder="Description/Purpose (optional)"
                isValidationVisible={isValidationVisible}
              />
            </FieldGroup>
          </FormSection>
          <FormSection
            IconComponent={People}
            title="Audience"
            description="
              Select the Audience that your new Campaign should watch. Keep in
              mind that contacts who are not part of the Audience cannot receive
              messages from the Campaign.
            "
          >
            <AudienceField
              value={audience?.name ?? ''}
              placeholder="Selected Audience Name"
              disabled
              isValidationVisible={isValidationVisible}
              validate={() => validator.audience(audience)}
            />
            <Button onClick={() => editor.goToStep('audience/picker')}>
              {audience ? 'Change' : 'Select'} Audience
            </Button>
          </FormSection>
        </Sections>
        <DescriptionArea>
          <DescriptionArea.Icon />
          <DescriptionArea.Paragraph>
            {`
            Automated Campaigns enable you to send messages to your contacts
            automatically based on events. For example, fun messages that
            automatically send to contacts on their birthday; or send a series of
            welcome messages to someone after they've been added to your database.
          `}
          </DescriptionArea.Paragraph>
          <DescriptionArea.Paragraph>
            {`
            Campaigns are created using an Audience plus one or more Campaign
            stages. The Campaign's Audience determines who the Campaign should pay
            attention to, and the Campaign's stages control what message gets sent
            to contacts, and when.
          `}
          </DescriptionArea.Paragraph>
        </DescriptionArea>
      </Step>
    </>
  );
};

export default Overview;
