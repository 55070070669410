import React from 'react';

import { useUserTemplatesQuery } from '../../../../../redux/services/queries/templates';
import {
  useFullLoader,
  useFullScreenSpinner,
} from '../../../../../redux/actions/UI';
import {
  getCoreTemplateData,
  getTemplateBy_id,
} from '../../../../../redux/services/template';
import NewCard from '../../../../../Component/Drafts/Components/NewCard';
import { useEditor } from '../../CampaignEditor';
import TemplateList from '../TemplateList';
import TemplateCard from './TemplateCard';
import EditActiveCard from './EditActiveCard';

const CHUNK_SIZE = 30;

const SavedTemplates = ({ onEdit }) => {
  const editor = useEditor();

  const activeStageId = editor.getParam('stage');
  const activeStage = editor
    .getField('stages')
    .find(({ _id }) => _id === activeStageId);
  const activeTemplate = activeStage.template;
  const haveActiveTemplate = !!activeTemplate;

  const goToEditTemplate = template => {
    editor.goToStep('template/editor', {
      stage: editor.getParam('stage'),
      template,
    });
  };

  const handleEditActive = () => {
    goToEditTemplate(activeTemplate);
  };

  const handleCreateNew = () => {
    editor.goToStep('template/editor', { stage: editor.getParam('stage') });
  };

  const fixedCards = [
    <NewCard
      key="create-new"
      onClick={handleCreateNew}
      title="CREATE A NEW TEMPLATE"
    />,
  ];

  if (haveActiveTemplate) {
    fixedCards.unshift(
      <EditActiveCard
        key="edit-active"
        templateName={activeTemplate.name}
        onClick={handleEditActive}
      />
    );
  }

  const {
    data,
    isLoading,
    isAnyPageLoaded,
    isFetchingNextPage,
    fetchNextPage,
  } = useUserTemplatesQuery({
    getChunkSize(cursor) {
      const compensationForFixedCards = fixedCards.length;
      const isFirstPage = cursor === 0;
      if (isFirstPage) {
        return CHUNK_SIZE - compensationForFixedCards;
      }

      return CHUNK_SIZE;
    },
  });

  useFullScreenSpinner('Loading templates...', isLoading);

  const fullLoader = useFullLoader();

  const handleEdit = templateId => {
    fullLoader.during('Loading template...', async () => {
      const template = await getTemplateBy_id(templateId);
      onEdit(getCoreTemplateData(template));
    });
  };

  return (
    <TemplateList
      data={data}
      onLoadNext={fetchNextPage}
      TemplateCard={TemplateCard}
      fixedCards={fixedCards}
      onEdit={handleEdit}
      isLoaderVisible={isAnyPageLoaded && isFetchingNextPage}
    />
  );
};

export default SavedTemplates;
