import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TextField } from '@material-ui/core';
import { AddCircleOutline, CancelOutlined } from '@material-ui/icons';
import Container from '../_shared/container';
import Text from '../_shared/text';
import Wrap from '../_shared/wrap';
import Bold from '../_shared/bold';
import Content from '../../../_shared/content';
import FitContent from '../_shared/fitContent';
import {
  addFilter,
  removeFilter,
  toast,
} from '../../../../../../../../../redux/action';

const GeoFilter = ({ filterName, checked, selectedFilters }) => {
  const dispatch = useDispatch();

  const [value, setValue] = useState('');
  const [geoFilters, setGeoFilters] = useState(selectedFilters || []);

  useEffect(() => {
    if (geoFilters.length) {
      dispatch(addFilter({ [filterName]: geoFilters }));
    } else if (
      geoFilters.length === 0 &&
      selectedFilters &&
      selectedFilters.length !== 0
    ) {
      dispatch(removeFilter(filterName));
    }
  }, [geoFilters]);

  useEffect(() => {
    if (!checked && geoFilters.length) {
      dispatch(removeFilter(filterName));
    }
  }, [checked]);

  const handleTextChange = ({ target }) => {
    setValue(target.value);
  };

  const handleAddGeoFilter = () => {
    let errorMessage = '';
    if (!value) {
      errorMessage = `Please add ${filterName}.`;
    }

    if (errorMessage) {
      dispatch(toast('error', errorMessage));
    } else {
      setGeoFilters([...geoFilters, { value }]);
      setValue('');
    }
  };

  const handleRemoveFilterDates = index => {
    setGeoFilters([
      ...geoFilters.slice(0, index),
      ...geoFilters.slice(index + 1),
    ]);
  };

  return (
    <Container>
      {(selectedFilters || []).map(({ value }, index) => (
        <Wrap key={index}>
          <FitContent>{index === 0 ? '- Is in' : 'or'}</FitContent>
          <Bold>{value}</Bold>
          <CancelOutlined
            fontSize="small"
            style={{ fill: '#E63946' }}
            onClick={() => handleRemoveFilterDates(index)}
          />
        </Wrap>
      ))}
      <Content>
        <Text>{geoFilters.length === 0 ? 'In' : 'or in'}</Text>
        <TextField
          value={value}
          onChange={handleTextChange}
          style={{ width: 120 }}
        />
      </Content>
      <Button
        variant="contained"
        size="small"
        startIcon={<AddCircleOutline />}
        style={{
          borderRadius: 50,
          backgroundColor: '#007ADE',
          margin: '0 25px',
          padding: '5px 15px',
          color: 'white',
        }}
        onClick={handleAddGeoFilter}
      >
        Add
      </Button>
    </Container>
  );
};

export default GeoFilter;
