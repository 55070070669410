import React, { useState } from 'react';
import { KeyboardArrowRight, KeyboardArrowDown } from '@material-ui/icons';
import moment from 'moment';
import { Row, Date } from './ClickDates.styled';

const ClickDates = ({ activity }) => {
  const [isOpen, setOpen] = useState(false);
  const KeyboardArrow = isOpen ? KeyboardArrowDown : KeyboardArrowRight;
  const content = activity.dates.map(({ time }) => {
    return (
      <Date key={`${activity.email}-${time}`}>
        {moment(time * 1000).format('MM/DD/YYYY, h:mm a')}
      </Date>
    );
  });

  const totalClicks = activity.dates.length;

  const totalClicksText = totalClicks > 1 ? 'clicks' : 'click';

  return (
    <>
      <Row onClick={() => setOpen(!isOpen)}>
        <KeyboardArrow size="small" style={{ color: '#616161' }} />
        {activity.email} ({totalClicks} {totalClicksText})
      </Row>
      {isOpen && content}
    </>
  );
};

export default ClickDates;
