import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export const Container = styled.div`
  z-index: 20;
  position: relative;
  width: 1000px;
  min-height: 500px;
  background-color: white;
  opacity: 1;
  padding: 70px 70px 30px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
`;

export const Centered = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 25px;
  align-items: self-start;
  flex-direction: row;
`;

export const HalfWidthColumn = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

export const Wrap = styled.div`
  display: flex;
  justify-content: row;
  gap: 30px;
`;

export const InputContainer = styled.div`
  width: calc(100% - 360px);
`;

export const Input = styled(TextField)`
  margin-bottom: 15px !important;
  flex: ${props => props.flex || '1'};
`;

export const ErrorMessage = styled.p`
  width: 100%;
  text-align: center;
  color: #ff6161;
  font-size: 14px;
  padding: 15px;
  height: 24px;
`;

export const Text = styled.p`
  font-size: 14px;
`;
