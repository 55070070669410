import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { AddCircleOutline, CancelOutlined } from '@material-ui/icons';
import { getCoeDate } from '../../../../../_shared/api';
import SelectDropDown from '../_shared/selectDropdown';
import Container from '../_shared/container';
import Text from '../_shared/text';
import Wrap from '../_shared/wrap';
import Bold from '../_shared/bold';
import Content from '../../../_shared/content';
import FitContent from '../_shared/fitContent';
import Space from '../_shared/space';
import {
  addFilter,
  removeFilter,
  toast,
} from '../../../../../../../../../redux/action';

const CodeOfEthicsCompletion = ({ filterName, checked, selectedFilters }) => {
  const dispatch = useDispatch();
  const [tempCodeOfEthicsCompletion, setTempCodeOfEthicsCompletion] =
    useState('');
  const [codeOfEthicsCompletion, setCodeOfEthicsCompletion] = useState(
    selectedFilters || ''
  );
  const [coeDate, setCoeDate] = useState('');

  useEffect(() => {
    const getCodeOfEthicsDate = async () => {
      const date = await getCoeDate();
      setCoeDate(date);
    };
    getCodeOfEthicsDate();
  }, []);

  useEffect(() => {
    if (codeOfEthicsCompletion) {
      dispatch(addFilter({ [filterName]: codeOfEthicsCompletion }));
    } else if (!codeOfEthicsCompletion && selectedFilters) {
      dispatch(removeFilter(filterName));
    }
  }, [codeOfEthicsCompletion]);

  useEffect(() => {
    if (!checked && codeOfEthicsCompletion) {
      dispatch(removeFilter(filterName));
    }
  }, [checked]);

  const handleCodeOfEthicsCompletionChange = ({ target }) => {
    setTempCodeOfEthicsCompletion(target.value);
  };

  const handleAddCodeOfEthicsCompletion = () => {
    if (!tempCodeOfEthicsCompletion) {
      dispatch(toast('error', 'Please select Code of Ethics Completion Date.'));
      return;
    }
    setCodeOfEthicsCompletion(tempCodeOfEthicsCompletion);
  };

  const handleRemoveCodeOfEthicsCompletion = () => {
    setCodeOfEthicsCompletion('');
  };

  return (
    <Container>
      {codeOfEthicsCompletion ? (
        <Wrap>
          <FitContent>
            <Space>-</Space>Code of Ethics Completion
          </FitContent>{' '}
          <Bold>{codeOfEthicsCompletion}</Bold>
          <CancelOutlined
            fontSize="small"
            style={{ fill: '#E63946' }}
            onClick={() => handleRemoveCodeOfEthicsCompletion()}
          />
        </Wrap>
      ) : (
        ''
      )}
      <Content>
        <Text isDisabled={codeOfEthicsCompletion}>
          {codeOfEthicsCompletion ? 'or' : ''} Code of Ethics training for the
          current cycle (ending on {coeDate}) is
        </Text>
        <SelectDropDown
          value={tempCodeOfEthicsCompletion}
          menuItemNames={['not completed', 'completed']}
          onChange={handleCodeOfEthicsCompletionChange}
          disabled={!!codeOfEthicsCompletion}
        />
      </Content>
      <Button
        variant="contained"
        size="small"
        startIcon={<AddCircleOutline />}
        style={{
          borderRadius: 50,
          backgroundColor: codeOfEthicsCompletion ? 'grey' : '#007ADE',
          margin: '0 25px',
          padding: '5px 15px',
          color: 'white',
        }}
        onClick={handleAddCodeOfEthicsCompletion}
        disabled={codeOfEthicsCompletion}
      >
        Add
      </Button>
    </Container>
  );
};

export default CodeOfEthicsCompletion;
