import React, { useMemo } from 'react';
import Card from './Card';

const AllChannels = ({
  emailEvents,
  generateRatio,
  textEvents,
  isDataAvailable,
  isSmartResendAvailable,
  smartResendEmailEvents,
}) => {
  const totalEvents = useMemo(() => {
    return emailEvents.delivered >= 0 && textEvents.delivered >= 0
      ? emailEvents.delivered + textEvents.delivered
      : 0;
  }, [emailEvents, textEvents]);

  const calcSmartResendClickThroughRate = () => {
    const oldRatio = generateRatio(
      emailEvents.uniqueclick,
      emailEvents.uniqueopen
    );

    const newRatio = generateRatio(
      emailEvents.uniqueclick + smartResendEmailEvents.uniqueclick,
      emailEvents.uniqueopen + smartResendEmailEvents.uniqueopen
    );

    return parseInt(newRatio) - parseInt(oldRatio) + '%';
  };

  return (
    <>
      <Card
        name="Deliveries"
        value={totalEvents}
        smartResendValue={
          smartResendEmailEvents?.delivered
            ? smartResendEmailEvents.delivered
            : null
        }
        title="Number of unique recipients delivered to."
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
      />
      <Card
        name="Delivery Rate"
        value={generateRatio(
          emailEvents.delivered + textEvents.delivered,
          emailEvents.sent + textEvents.sent
        )}
        smartResendValue={
          smartResendEmailEvents?.delivered
            ? generateRatio(
                smartResendEmailEvents.delivered,
                smartResendEmailEvents.sent
              )
            : null
        }
        title="The average of your email and text successful deliveries."
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
      />
      <Card
        name="Open Rate"
        value={generateRatio(
          emailEvents.uniqueopen + (smartResendEmailEvents?.uniqueopen || 0),
          emailEvents.delivered
        )}
        smartResendValue={
          smartResendEmailEvents?.uniqueopen ||
          smartResendEmailEvents?.delivered
            ? generateRatio(
                smartResendEmailEvents.uniqueopen,
                emailEvents.delivered
              )
            : null
        }
        title="The average of your email unique open rates."
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
        showComparativeAnalytics
      />
      <Card
        name="Click-Through Rate"
        value={generateRatio(
          emailEvents.uniqueclick + (smartResendEmailEvents?.uniqueclick || 0),
          emailEvents.uniqueopen + (smartResendEmailEvents?.uniqueopen || 0)
        )}
        smartResendValue={
          smartResendEmailEvents?.uniqueclick ||
          smartResendEmailEvents?.uniqueopen
            ? calcSmartResendClickThroughRate()
            : null
        }
        title="The average of your email and text click-to-open rates."
        isDataAvailable={isDataAvailable}
        isSmartResendAvailable={isSmartResendAvailable}
        showComparativeAnalytics
      />
    </>
  );
};

export default AllChannels;
