import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DrawerHeaderStyled, IconButtonStyled } from './drawerHeader.styled';

export const DrawerHeader = memo(function DrawerHeader({
  onClose,
  title,
  onSave,
  fixed,
}) {
  return (
    <DrawerHeaderStyled fixed={fixed}>
      <div>
        {onClose && (
          <IconButtonStyled onClick={onClose}>
            <CloseIcon />
          </IconButtonStyled>
        )}
        {title}
      </div>
      {onSave && (
        <Button
          onClick={onSave}
          variant="outlined"
          label="Save"
          type="submit"
          className="save-btn"
        >
          Save
        </Button>
      )}
    </DrawerHeaderStyled>
  );
});
