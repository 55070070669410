import { Form, Input } from 'antd';
import React, { useState } from 'react';
import Modal from '../../Modal';

const SaveAsDesignModal = ({ initialName, onHide, saveAsNewTemplate }) => {
  const [saveAsName, setSaveAsName] = useState(initialName);
  const updateSaveAsName = event => setSaveAsName(event.target.value);

  return (
    <Modal
      className="templatemodal save-as-modal"
      title="Save as a New Template"
      onClose={onHide}
      primaryButton={{
        label: 'Save',
        onClick: () => saveAsNewTemplate(saveAsName),
      }}
    >
      <Form>
        <Form.Item className="m0">
          <Input
            type="text"
            placeholder="Your Template Name "
            defaultValue={saveAsName}
            value={saveAsName}
            onChange={updateSaveAsName}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SaveAsDesignModal;
