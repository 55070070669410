import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const DeleteModalStyled = styled.div`
  * {
    font-family: Montserrat;
  }
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 550px;
  background-color: #fff;
  border-radius: 5px;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  width: 550px;
  background-color: #005092;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  & span {
    font-size: 16px;
    color: #fff;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

export const ModalContent = styled.div`
  margin-bottom: 20px;

  & div {
    width: 100%;
    margin-bottom: 2px;
    padding: 5px 0 5px 10px;
  }

  .description {
    max-height: 200px;
    overflow-y: auto;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);

    & p {
      margin-bottom: auto;
    }
  }

  .date-blue {
    color: #007ade;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CancelButton = styled(Button)`
  background-color: #ff6161 !important;
  color: #fff !important;
  border: none !important;
`;

export const OkButton = styled(Button)`
  && {
    background-color: #5eab00;
    color: #fff;
    border: none;
  }

  &:disabled {
    background-color: #d1d1d1;
    border: none !important;
  }
`;
