import React, { useState } from 'react';
import { DataGrid } from './EventDetailsTable.styled';

const COLUMNS = [
  {
    field: 'firstName',
    headerName: 'First Name',
    flex: 1,
    minWidth: 70,
    sortable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    flex: 1,
    minWidth: 70,
    sortable: true,
  },
  {
    field: 'id',
    headerName: 'Member ID',
    flex: 1,
    minWidth: 60,
    sortable: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.9,
    minWidth: 50,
    sortable: true,
    renderCell: ({ value }) => {
      if (value === 'A') {
        // i.e. attended === true
        return 'Attended';
      } else if (value === 'R') {
        // status === registered
        return 'Registered';
      } else if (value === 'C') {
        // status === cancelled
        return 'Cancelled';
      } else {
        return '';
      }
    },
  },
];

const EventDetailsTable = ({ data, openDrawer, isLoading }) => {
  const [pageSize, setPageSize] = useState(25);

  const rows = data?.map(contact => ({
    id: contact.contactAMSID,
    firstName: contact.firstName,
    lastName: contact.lastName,
    status: contact.attended ? 'A' : contact.status,
  }));

  return (
    <DataGrid
      disableColumnSelector
      rows={rows}
      columns={COLUMNS}
      sortingOrder={['asc', 'desc']}
      pageSize={pageSize}
      onPageSizeChange={size => setPageSize(size)}
      loading={isLoading}
      onRowClick={data => {
        openDrawer(data.row);
      }}
    />
  );
};

export default EventDetailsTable;
