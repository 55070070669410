import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  font-size: 15px;
  color: #616161;
  &:hover {
    cursor: pointer;
  }
`;

export const Date = styled.div`
  padding-left: 40px;
  color: #616161;
`;
