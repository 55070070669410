import { selectedOrganizationSelector } from '../../../redux/selectors/organization';
import { userDetailSelector } from '../../../redux/selectors/user';
import store from '../../../redux/store';
import api from '../../../utils/andromeda-api';
const http = api;

const getOrganizationData = () => {
  return {
    orgId: selectedOrganizationSelector(store.getState()).id,
    fromPhone: selectedOrganizationSelector(store.getState()).fromPhone,
    twilioSID: selectedOrganizationSelector(store.getState()).twilioSID,
    twilioAuthToken: selectedOrganizationSelector(store.getState())
      .twilioAuthToken,
    stripeCustomerId: selectedOrganizationSelector(store.getState()).stripe
      ?.customerId,
    orgName: selectedOrganizationSelector(store.getState()).organizationName,
    orgEmail: selectedOrganizationSelector(store.getState()).email,
  };
};

const getSenderDetails = () => {
  return userDetailSelector(store.getState());
};

export const fetchOrgTextMessages = async query => {
  const { orgId } = getOrganizationData();

  const {
    data: { result },
  } = await http.get(`/texting/summary/${orgId}`, {
    params: query,
  });

  return result;
};

export const fetchKeywords = async query => {
  const { orgId } = getOrganizationData();

  const {
    data: { result },
  } = await http.get(`/keywords/${orgId}`, {
    params: query,
  });

  return result;
};

export const fetchTextMessagesByConversationId = async conversationId => {
  const {
    data: { result },
  } = await http.get(`/texting/${conversationId}`);

  return result;
};

export const getMessageDataToSend = data => {
  const { orgId, fromPhone, twilioSID, twilioAuthToken } =
    getOrganizationData();
  const sender = getSenderDetails();

  const textMessage = {
    ...data,
    senderType: 'org',
    organizationId: orgId,
    orgPhone: fromPhone,
    userId: sender.id,
    twilioSID,
    twilioAuthToken,
  };
  return {
    textMessage,
    user: { firstName: sender.firstName, lastName: sender.lastName },
  };
};

export const sendTextMessage = async data => {
  const {
    data: { result },
  } = await http.post('/texting/send', data);

  return result;
};

export const saveTextMessage = async data => {
  const {
    data: { message },
  } = await http.post('/texting/save', data);

  return message;
};

export const updateMessageStatus = async (conversationIds, status) => {
  if (!conversationIds?.length) throw new Error('No message ids provided');
  const {
    data: { message },
  } = await http.post('/texting/updateMsgStatus', { conversationIds, status });

  return message;
};

export const fetchContactsFullNameAndIds = async ({ page, searchValue }) => {
  const { orgId } = getOrganizationData();

  const { data } = await http.get(`/texting/contacts/${orgId}`, {
    params: {
      page,
      searchValue,
    },
  });

  return data.result;
};

export const matchConversationToContact = async ({
  noteRouterId,
  conversationId,
}) => {
  if (!noteRouterId || !conversationId)
    throw new Error('No contact or message id provided');

  const {
    data: { contact },
  } = await http.post('/texting/matchToContact', {
    noteRouterId,
    conversationId,
  });

  return contact;
};

export const getTextSentThisMonth = async () => {
  const { orgId } = getOrganizationData();

  const { data } = await http.get(`/texting/metrics/${orgId}`);

  return data.result.count;
};

export const markMessageAsRead = async conversationId => {
  if (!conversationId) throw new Error('No conversation id is provided');

  const {
    data: { result },
  } = await http.post('/texting/read', { conversationId });

  return result;
};

export const getUnreadTextCount = async () => {
  const { orgId } = getOrganizationData();

  const { data } = await http.get(`/texting/unreadCount/${orgId}`);

  return data.result;
};

export const updateKeywordStatus = async (keywordIds, status = 'archived') => {
  if (!keywordIds?.length) throw new Error('No keyword ids provided');
  const {
    data: { result },
  } = await http.post('/keywords/updateKeywordStatus', { keywordIds, status });

  return result;
};

export const updateMessageKeyword = async (id, payload) => {
  if (!id) throw new Error('Keword id is missing');

  const { orgId } = getOrganizationData();
  const { data } = await http.put(`/keywords/${id}`, {
    ...payload,
    organizationId: orgId,
  });

  return data.message;
};

export const createNewKeywordMessage = async payload => {
  if (!payload) throw new Error('Keword payload is missing');

  const { orgId } = getOrganizationData();
  const createdBy = getSenderDetails();

  const { data } = await http.post('/keywords', {
    ...payload,
    organizationId: orgId,
    createdBy: `${createdBy.firstName} ${createdBy.lastName}`,
  });

  return data.message;
};

export const createSubscription = async payload => {
  if (!payload) throw new Error('Payment data is missing');

  const { stripeCustomerId, orgId } = getOrganizationData();
  const { data } = await http.post('stripe/create-subscription', {
    stripeCustomerId,
    paymentMethodId: payload.id,
    organizationId: orgId,
    createdBy: payload.billing_details.name,
    items: [
      {
        price: process.env.REACT_APP_TEXTING_HUB_PRICE,
      },
    ],
    productId: process.env.REACT_APP_TEXTING_HUB_PRODUCT_ID,
  });

  return data.message;
};

export const addSubscriptionToExistingPaymentMethod = async () => {
  const { stripeCustomerId, orgId } = getOrganizationData();

  const { data } = await http.post('stripe/add-subscription', {
    stripeCustomerId,
    price: process.env.REACT_APP_TEXTING_HUB_PRICE,
    productId: process.env.REACT_APP_TEXTING_HUB_PRODUCT_ID,
    organizationId: orgId,
  });

  return data.message;
};

export const getDefaultPaymentMethod = async () => {
  const { stripeCustomerId } = getOrganizationData();
  const { data } = await http.get(
    `stripe/default-payment-method/${stripeCustomerId}`
  );

  return data;
};
