import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  Container,
  Menu,
  MenuItem,
  Content,
  Wrapper,
  FilterContainer,
} from './UI.styled';
import Drafts from '../DraftsPage';
import SavedTemplates from '../SavedTemplatesPage';
import SharedTemplates from '../SharedTemplatesPage';
import SavedAudiences from '../SavedAudiencesPage';
import {
  AUDIENCES_TAB,
  SHARED_TEMPLATES_TAB,
  SAVED_TEMPLATES_TAB,
  ACTIVE,
  DATE,
} from '../_utils/constants';
import FilterCardsSelectDropdown from '../_Filters/FilterCardsSelectDropdown';
import SortCardsSelectDropdown from '../_Filters/SortCardsSelectDropdown';

const Tabs = ({ content }) => {
  const history = useHistory();
  const location = useLocation();
  const [activeSortByValue, setSortByValue] = useState(DATE.value);
  const [activeFilter, setActiveFilter] = useState(ACTIVE.value);
  const { tab: currentTab, apply, draft } = queryString.parse(location.search);

  const handleTabClick = tab => {
    const tabStr = tab ? `?tab=${tab}` : '';
    const applyStr = apply ? '&apply=1' : '';
    const draftIdStr = draft ? `&draft=${draft}` : '';
    history.replace(`/dashboard/drafts${tabStr}${applyStr}${draftIdStr}`);
    setSortByValue(DATE.value);
    setActiveFilter(ACTIVE.value);
  };

  const renderContent = () => {
    switch (currentTab) {
      case SAVED_TEMPLATES_TAB:
        return (
          <SavedTemplates
            activeSortByValue={activeSortByValue}
            activeFilter={activeFilter}
          />
        );
      case SHARED_TEMPLATES_TAB:
        return <SharedTemplates />;
      case AUDIENCES_TAB:
        return (
          <SavedAudiences
            activeSortByValue={activeSortByValue}
            activeFilter={activeFilter}
          />
        );
      default:
        return (
          <Drafts
            activeSortByValue={activeSortByValue}
            activeFilter={activeFilter}
          />
        );
    }
  };

  const getFilterDropdowns = () => {
    const { sort, filter } = content.find(({ tab }) => {
      return tab === currentTab;
    });
    const isSortByValueAvailable = !!sort;
    const isFilterByValueAvailable = !!filter;

    return (
      <>
        {isSortByValueAvailable && (
          <SortCardsSelectDropdown
            sortBy={sort}
            activeSortByValue={activeSortByValue}
            setSortByValue={setSortByValue}
          />
        )}
        {isFilterByValueAvailable && (
          <FilterCardsSelectDropdown
            filterBy={filter}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
          />
        )}
      </>
    );
  };

  return (
    <Container>
      <Wrapper>
        <Menu>
          {content.map(({ menuItemName, tab }) => (
            <MenuItem
              key={menuItemName}
              onClick={() => handleTabClick(tab)}
              className={tab === currentTab ? 'active' : ''}
            >
              {menuItemName}
            </MenuItem>
          ))}
        </Menu>
        <FilterContainer>{getFilterDropdowns()}</FilterContainer>
      </Wrapper>
      <Content>{renderContent()}</Content>
    </Container>
  );
};

export default Tabs;
