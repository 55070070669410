import React from 'react';

import {
  formatTimestampToDate,
  trimTextIfNeeded,
} from '../../../../../../Component/Drafts/Components/_utils';
import { Card } from '../../../../../../Component/Drafts/Components/_CardUI/Card.styled';
import { Image, ImageHolder } from './TemplateCard.styled';

const TemplateCard = ({ onClick, template }) => {
  return (
    <Card pointer onClick={onClick}>
      <ImageHolder>
        <Image src={template.thumbnail} alt={template.name + ' thumbnail'} />
      </ImageHolder>
      <Card.Date>{formatTimestampToDate(template.saveTime)}</Card.Date>
      <Card.Name>{trimTextIfNeeded(template.name, 25)}</Card.Name>
    </Card>
  );
};

export default TemplateCard;
