import { Group } from '@material-ui/icons';
import { createTheme, ThemeProvider } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';

const theme = createTheme({
  palette: {
    primary: {
      main: '#007ade',
    },
  },
});

const EditableMemberId = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const MemberLabel = styled.span`
  color: #007ade;
  padding-left: 5px;
`;

export const Member = ({ memberId = '', enableEdit, amsContact }) => {
  const [editMode, setEditMode] = useState(false);

  const updateMode = () => {
    setEditMode(!editMode);
    enableEdit(!editMode);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Group color="primary" />{' '}
      </ThemeProvider>
      <MemberLabel>Member&nbsp;</MemberLabel>

      {amsContact && <span>(ID #{memberId})</span>}
      {!amsContact && (
        <EditableMemberId onClick={updateMode}>
          {editMode ? '' : `(ID #${memberId})`}
        </EditableMemberId>
      )}
    </>
  );
};
