import styled from 'styled-components';

export const Container = styled.div`
  width: 690px;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px 25px;
  background: #005092;
  color: white;
  font-size: 16px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 580px;
  align-items: center;
`;

export const Body = styled.div`
  padding: 15px 25px;
  overflow-y: auto;
`;

export const Grey = styled.span`
  color: #626262;
`;

export const Bold = styled.span`
  font-weight: 900;
  color: black;
`;

export const Small = styled.span`
  font-size: 12px;
  color: ${props => (props.isError ? '#FF6766' : '#626262')};
`;

export const Button = styled.button`
  margin: 10px 0;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  border-radius: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 34px;
  color: white;
  background-color: #007ade;
  border: 1px solid #007ade;
  transition: all 0.3s ease-in;
  &:hover,
  &.active {
    color: #007ade;
    border: 1px solid #509ee5;
    background-color: white;
    cursor: pointer;
  }
  &:disabled {
    pointer-events: all;
    cursor: not-allowed;
    color: grey;
    border-color: grey;
    background-color: transparent;
  }
`;
