import React from 'react';

const NewItemLabel = () => {
  return (
    <div
      style={{
        backgroundColor: '#FC9E3A',
        color: 'white',
        borderRadius: '20px',
        paddingLeft: '20px',
        paddingRight: '20px',
        lineHeight: '25px',
        fontSize: '10px',
      }}
    >
      New!
    </div>
  );
};

export default NewItemLabel;
