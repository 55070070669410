import React, { useState } from 'react';
import Tabs from './Tabs';
import Content from './Content';
import { Container } from './SuperuserDashboard.styled';

const SuperuserDashboard = () => {
  const [selectedTab, setSelectedTab] = useState('AMS Sync Logs');
  const [isAddOrgDrawerVisible, setAddOrgDrawerVisibility] = useState(false);
  const handleTabSelect = tab => {
    setSelectedTab(tab);
  };

  return (
    <Container>
      <Tabs
        onSelectTab={handleTabSelect}
        selectedTab={selectedTab}
        setAddOrgDrawerVisibility={setAddOrgDrawerVisibility}
      />
      <Content
        setAddOrgDrawerVisibility={setAddOrgDrawerVisibility}
        isAddOrgDrawerVisible={isAddOrgDrawerVisible}
        selectedTab={selectedTab}
      />
    </Container>
  );
};

export default SuperuserDashboard;
