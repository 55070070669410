import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  height: 160px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  background-color: #fff;
  border-radius: 8px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  width: 350px;
  height: 45px;
  background-color: #0e2d5c;
  border-radius: 8px 8px 0 0;
`;

export const Wrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
`;
