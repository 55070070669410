import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 30px;
`;

export const Wrapper = styled.span`
  display: flex;
  flex-direction: row;
  gap: 20px;
  min-width: 200px;
`;

export const RightTopContainer = styled.div`
  margin-top: -40px;
  min-width: 410px;
  @media (max-width: 1200px) {
    margin-top: 0;
  }
`;
