import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const RefreshButton = styled(Button).attrs({
  variant: 'contained',
})`
  && {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px 18px;

    &:hover {
      background-color: #eeeeee;
    }
  }

  & > .MuiButton-label {
    color: #007ade;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.25;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  background-color: #ffffff;
  overflow-y: auto;
`;

export const Title = styled.h3`
  font-weight: bold;
  text-decoration: underline;
`;
