import React, { useState } from 'react';
import {
  Drawer,
  IconButton,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { Close, Power, Description } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { updateOrganization } from '../../../../../redux/services';
import { toast, userOrganization } from '../../../../../redux/action';
import {
  Container,
  Header,
  Content,
  SaveBtn,
  Title,
  Group,
} from './EditAPIConnectionDrawer.styled';
import { Title as ContentTitle } from '../../GeneralInfo/GeneralInfo.styled';
import {
  NONE,
  RAPATTONI,
  RAMCO,
  TANGILLA,
  MAP_OF_AMS_NAMES,
  REVERSE_MAP_OF_AMS_NAMES,
  GROWTH_ZONE,
} from './constants';

const useStyles = makeStyles(() => ({
  selectLabel: {
    background: 'white',
  },
  selectField: {
    color: '#212121',
    '&:focus': {
      backgroundColor: 'transparent',
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
}));

const EditAPIConnectionDrawer = ({
  orgTypes,
  selectedOrg,
  isEditAMSDrawerVisible,
  setEditAMSDrawerVisibility,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedAMS, setSelectedAMS] = useState(
    orgTypes.find(({ id }) => id === selectedOrg.orgType)?.name
  );
  const [apiKey, setApiKey] = useState(selectedOrg.key || '');
  const [apiURL, setApiURL] = useState(selectedOrg.url || '');
  const [clientID, setClientID] = useState(selectedOrg.clientID || '');
  const currentAMS = REVERSE_MAP_OF_AMS_NAMES[selectedAMS];

  const closeDrawer = () => {
    setEditAMSDrawerVisibility(false);
  };

  const handleSubmit = async () => {
    try {
      const orgType = orgTypes?.find(type => type.name === selectedAMS);

      if (orgType) {
        // if no org type is selected then no need to save anything
        const date = new Date();
        const orgData = {
          id: selectedOrg.id,
          orgType: orgType.id,
          key: apiKey,
          url: apiURL,
          clientID,
          updatedDate: date.toString(),
          importMagic: true, //To ensure the AMS syncs will be enabled
        };
        if (currentAMS === RAMCO) {
          orgData.url = '';
          orgData.clientID = '';
        } else if (currentAMS === GROWTH_ZONE) {
          orgData.clientID = '';
        } else if (currentAMS === TANGILLA) {
          orgData.url = '';
        }

        const updatedOrg = await updateOrganization(orgData);
        dispatch(userOrganization(updatedOrg));
        dispatch(toast('success', 'Successfully Updated Organization'));
      }

      closeDrawer();
    } catch (e) {
      dispatch(toast('error', 'Failed Updating Organization.'));
    }
  };

  return (
    <Drawer
      anchor={'right'}
      onClose={closeDrawer}
      open={isEditAMSDrawerVisible}
    >
      <Container>
        <Header>
          <Group>
            <IconButton onClick={closeDrawer} style={{ color: 'white' }}>
              <Close />
            </IconButton>
            <Title>Editing: API Connection</Title>
          </Group>
          <SaveBtn onClick={handleSubmit}>Save</SaveBtn>
        </Header>
        <Content>
          <ContentTitle>
            <Power size="small" />
            Select AMS
          </ContentTitle>
          <FormControl variant="outlined" size="small">
            <InputLabel className={classes.selectLabel}>AMS</InputLabel>
            <Select
              className={classes.selectField}
              value={selectedAMS}
              onChange={e => {
                setSelectedAMS(e.target.value);
              }}
              inputProps={{ 'aria-label': 'Time Zone' }}
              style={{ width: '50%' }}
            >
              <MenuItem value={NONE}>{NONE}</MenuItem>
              {Object.entries(MAP_OF_AMS_NAMES).map(([name, value]) => (
                <MenuItem key={name} value={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {currentAMS && (
            <>
              <ContentTitle>
                <Description size="small" />
                Connection Details
              </ContentTitle>
              {currentAMS !== RAMCO && currentAMS !== TANGILLA && (
                <TextField
                  label="API URL"
                  variant="outlined"
                  size="small"
                  value={apiURL}
                  onChange={e => setApiURL(e.target.value)}
                />
              )}
              <TextField
                label="API Key"
                variant="outlined"
                size="small"
                value={apiKey}
                onChange={e => setApiKey(e.target.value)}
              />
              {(currentAMS === RAPATTONI || currentAMS === TANGILLA) && (
                <TextField
                  label="Client ID"
                  variant="outlined"
                  size="small"
                  value={clientID}
                  onChange={e => setClientID(e.target.value)}
                />
              )}
            </>
          )}
        </Content>
      </Container>
    </Drawer>
  );
};

export default EditAPIConnectionDrawer;
