import styled from 'styled-components';

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-height: 550px;
  height: calc(100vh - 110px);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex: 2;
  min-height: 250px;
  overflow: auto;
`;

export const LeftContainer = styled.div`
  width: 35%;
  min-width: 330px;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 4px;
  color: #6c7075;
  overflow: auto;
`;

export const RightContainer = styled.div`
  width: 65%;
  min-width: 350px;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 4px;
  color: #6c7075;
  overflow: auto;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
`;

export const ValueContainer = styled.span`
  max-width: calc(100% - 85px);
  word-wrap: break-word;
`;

export const Text = styled.h4`
  width: 80px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #007ade;
  font-weight: bold;
  font-size: 20px;
`;

export const EditBtn = styled.button`
  font-size: 14px;
  border-radius: 2px;
  align-items: center;
  transition: all 0.3s ease;
  font-family: Poppins, sans-serif;
  padding: 1px 25px;
  color: #6c7075;
  border: 1px solid #6c7075;
  background-color: white;
  display: flex;
  gap: 10px;
  &:hover {
    color: white;
    background-color: #007ade;
    border: 1px solid #007ade;
    stroke: white;
  }
`;
