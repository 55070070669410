import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import baseInputStyles from './baseInputStyles';
import notchlessStyles from './notchlessStyles';

const TextArea = styled(TextField).attrs({
  variant: 'outlined',
  multiline: true,
  minRows: 5,
  maxRows: 5,
})`
  > .MuiInputBase-root {
    ${baseInputStyles}
    ${notchlessStyles}
    padding: 0 !important;
  }
`;

export default TextArea;
