import React, { useState, useEffect, useMemo } from 'react';
import BaseHeader from './BaseHeader';
import MenuContent from '../MenuContent';
import useAutosave from '../Autosave';
import PreviewDesignModal from '../../Modals/PreviewDesignModal';
import { NextButton } from './Header.styled';

const TemplateHeader = ({
  isDesignLoading,
  beeEditor,
  data = {},
  saveStatus,
  isTextStatusSaved,
  onUnsavedStatus,
  html,
  onSave,
}) => {
  const [templateName, setTemplateName] = useState(data.name || '');
  const [isDesignPreviewModalVisible, setDesignPreviewModalVisiblity] =
    useState(false);

  useEffect(() => {
    if (data.name) {
      setTemplateName(data.name);
    }
  }, [data.name]);

  const saveTemplateName = async () => {
    await onSave({ name: templateName });
  };

  useAutosave({
    dependancyList: [templateName],
    saveFunc: saveTemplateName,
    onUnsavedStatus,
  });

  const menu = useMemo(
    () => (
      <MenuContent
        data={{
          design: data.design,
          ...(data.html && { html: data.html }),
          ...(data.sender && { sender: data.sender }),
          ...(data.senderName && { senderName: data.senderName }),
          ...(data.replyTo && { replyTo: data.replyTo }),
          ...(data.preheader && { preheader: data.preheader }),
          ...(data.subject && { subject: data.subject }),
        }}
        initialName={templateName}
        beeEditor={beeEditor}
      />
    ),
    [data]
  );

  const isPreviewBtnDisabled = isDesignLoading || !isTextStatusSaved;

  const nextButton = (
    <NextButton
      disabled={isPreviewBtnDisabled}
      onClick={() => {
        beeEditor.send();
        if (!isPreviewBtnDisabled) {
          setDesignPreviewModalVisiblity(true);
        }
      }}
    >
      Preview
    </NextButton>
  );

  return (
    <>
      <BaseHeader
        menu={menu}
        nextButton={nextButton}
        backBtnTargetUrl="/dashboard/drafts?tab=savedTemplates"
        nameInputPlaceholder="Enter Template Name"
        messageInfoData={data}
        saveStatus={saveStatus}
        onUnsavedStatus={onUnsavedStatus}
        onNameChange={setTemplateName}
        name={templateName}
        onSave={onSave}
      />
      <PreviewDesignModal
        html={html}
        name={templateName}
        isVisible={isDesignPreviewModalVisible}
        onHide={() => setDesignPreviewModalVisiblity(false)}
      />
    </>
  );
};

export default TemplateHeader;
