import moment from 'moment';
import React from 'react';
export const FieldColumns = [
  {
    headerName: 'Field Name',
    field: 'name',
    minWidth: 110,
    flex: 1,
  },
  {
    headerName: 'Last Updated',
    field: 'lastUpdatedTimestamp',
    minWidth: 110,
    flex: 1,
    // eslint-disable-next-line react/display-name
    renderCell: params => {
      return (
        <>{moment.unix(params.row.lastUpdatedTimestamp).format('M/D/YYYY')}</>
      );
    },
  },
  {
    headerName: 'Category',
    field: 'category',
    minWidth: 110,
    flex: 1,
  },
  {
    headerName: 'Description',
    field: 'description',
    minWidth: 110,
    flex: 2,
  },
];
