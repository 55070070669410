import styled from 'styled-components';

export const Container = styled.div`
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  color: #6c7075;
  padding: 5px;
  gap: 5px;
`;

export const Title = styled.span`
  font-size: ${props => props.size || '15px'};
  font-weight: 600;
  line-height: 22px;
  width: ${props => props.width || ''};
  color: #6c7075;
  padding-bottom: ${props => (props.paddingBottom ? '7px' : '')};
`;

export const FileName = styled.span`
  font-size: 12px;
  color: #007ade;
`;

export const DefaultChoice = styled.span`
  color: ${props => (props.enabled ? '#057cdf' : 'grey')};
  background-color: ${props => (props.enabled ? '#f2f9ff' : 'white')};
  border-radius: 10px;
  padding: 3px 10px;
  height: 30px;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 0;
`;

export const Small = styled.div`
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: ${props => (props.disabled ? '#ddd' : '#6c7075')};
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`;
