import styled from 'styled-components';

import BaseHeader from '../../../../Component/Sendmessage/Components/Header/BaseHeader';
import BaseStep from '../Step';

export const Step = styled(BaseStep)`
  height: 100%;
`;

export const EditorHeader = styled(BaseHeader)`
  padding: 0;
  border: none;
  margin-right: 20px;
`;

export const EditorWrapper = styled.div`
  padding-top: 10px;
  margin-left: -30px;
  margin-right: -30px;
  height: 100%;
`;

export const Errors = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

Errors.Title = styled.span`
  font-size: 14px;
`;

Errors.List = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
`;

Errors.Error = styled.li`
  font-size: 12px;
  line-height: 1.4;
`;
