import React, { memo } from 'react';
import { OptinFooterStyled } from './OptinFooter.styled';

export const OptinFooter = memo(function Footer({ organizationName }) {
  return (
    <OptinFooterStyled>
      By submitting your preferences, you are consenting to receive recurring
      messages via email and/or SMS text from <b>{organizationName}</b> via
      automated NoteRouter technology to the email addresses and/or mobile
      numbers provided. Messages will be sent to you periodically and may vary
      in volume and frequency. Message and data rates may apply. You can also
      reply &quot;STOP&quot; to block texts. Review the NoteRouter Terms and
      Conditions and Privacy Policy{' '}
      <a
        href="https://firebasestorage.googleapis.com/v0/b/noterouter-live.appspot.com/o/sample%2FNoteRouter%20Privacy%20Policy%202024.pdf?alt=media&token=7303a148-c26e-4e93-8b5c-02e9a4fbee7b"
        target="_blank"
        rel="noopener noreferrer"
      >
        here{' '}
      </a>{' '}
      at any time.
    </OptinFooterStyled>
  );
});
