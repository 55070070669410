import api from '../../utils/andromeda-api';

export async function getMessageEventInfo({
  msgId,
  BmessageId,
  isSmartResend,
}) {
  try {
    const response = await api.get(`/events/getMessageEventInfo/${msgId}`, {
      params: { BmessageId, isSmartResend },
    });
    console.log('Retrieved message event information', response);
    if (response.data?.result) {
      return response.data.result;
    }
    return null;
  } catch (e) {
    console.error(
      'Error getting message event information [getMessageEventInfo]',
      e
    );
    return null;
  }
}

export async function getMemberEventInfo(msgId, memberId, orgId) {
  try {
    const response = await api.get(
      `/events/getMemberEventInfo/${msgId}/${memberId}/${orgId}`
    );
    console.log('Retrieved member event info');
    if (response.data?.result) {
      return response.data.result;
    }
    return null;
  } catch (e) {
    console.error('Error getting member event info [getMemberEventInfo]', e);
    return null;
  }
}

export async function getRangeOfSummaries(date, orgId) {
  try {
    const response = await api.get(
      `/events/getRangeOfSummaries/${date}/${orgId}`
    );
    console.log('Retrieved daily global summary info', response);
    if (response.data.result) {
      return response.data.result;
    }
    return null;
  } catch (e) {
    console.error(
      'Error getting daily global summary info [getRangeOfSummaries]',
      e
    );
    return null;
  }
}

export async function getRangeCalculations(date, orgId) {
  try {
    const response = await api.get(
      `/events/getRangeCalculations/${date}/${orgId}`
    );
    if (response.data?.result) {
      return response.data.result;
    }
    return null;
  } catch (e) {
    console.error(
      'Error getting daily global summary calculations [getRangeCalculations]',
      e
    );
    return null;
  }
}

export async function getEarliestSummary(orgId) {
  try {
    const response = await api.get(`/events/getEarliestSummary/${orgId}`);
    console.log('Retrieved earliest known sumary for given org');
    if (response.data?.result) {
      return response.data.result;
    }
    return null;
  } catch (e) {
    console.error(
      'Error getting earliest known sumary for given org [getEarliestSummary]',
      e
    );
    return null;
  }
}

export async function getEmailEventCount(orgId, date) {
  try {
    const response = await api.get(
      `/events/getEmailEventCount/${orgId}/${date}`
    );
    console.log('Retrieved email event count');
    if (response.data?.result) {
      return response.data.result;
    }
    return 0;
  } catch (e) {
    console.error('Error getting email event count [getEmailEventCount]', e);
    return 0;
  }
}

export async function getUniqueEventCount(orgId, status, date) {
  try {
    const response = await api.get(
      `/events/getUniqueEventCount/${orgId}/${status}/${date}`
    );
    console.log('Retrieved unique email event count');
    if (response.data?.result) {
      return response.data.result;
    }
    return 0;
  } catch (e) {
    console.error(
      'Error getting unique email event count [getUniqueEventCount]',
      e
    );
    return 0;
  }
}

export async function getTextEventCount(orgId, date) {
  try {
    const response = await api.get(
      `/events/getTextEventCount/${orgId}/${date}`
    );
    console.log('Retrieved text event count');
    if (response.data?.result) {
      return response.data.result;
    }
    return 0;
  } catch (e) {
    console.error('Error getting text event count [getTextEventCount]', e);
    return 0;
  }
}

export async function fetchSuppressionDetails(orgId, contactNoteRouterId) {
  let response;
  try {
    response = await api.get(
      `/events/suppressedEmail/${orgId}/${contactNoteRouterId}`
    );
    console.log('Retrieved text event count');
  } catch (e) {
    console.error('Error getting text event count [getTextEventCount]', e);
  }
  return response.data?.result;
}

export async function removeFromSuppressionList(
  orgId,
  contactNoteRouterId,
  eventId
) {
  let response;
  try {
    response = await api.delete(
      `/events/suppressedEmail/${orgId}/${contactNoteRouterId}/${eventId}`
    );
    console.log('removed from suppression');
  } catch (e) {
    console.error('Error removing from suppression', e);
  }
  return response;
}
