import styled from 'styled-components';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const StyledArrowUpwardIcon = withStyles({
  root: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#69b34c',
  },
})(ArrowUpward);

const StyledArrowUpwardIconSmall = withStyles({
  root: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#69b34c',
  },
})(ArrowUpward);

export const ArrowUpIcon = styled(StyledArrowUpwardIcon)``;

export const ArrowUpIconSmall = styled(StyledArrowUpwardIconSmall)``;

const StyledArrowDownIcon = withStyles({
  root: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#ff6161',
  },
})(ArrowDownward);

const StyledArrowDownIconSmall = withStyles({
  root: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#ff6161',
  },
})(ArrowDownward);

export const ArrowDownIcon = styled(StyledArrowDownIcon)``;

export const ArrowDownIconSmall = styled(StyledArrowDownIconSmall)``;

export const Container = styled.div`
  transition: all 0.3s ease-in;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  padding: 12px 0;
  height: 125px;
  &:hover {
    cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
  @media screen and (min-width: 1800px) {
    width: 18%;
    height: 140px;
  }
  @media screen and (min-width: 2200px) {
    width: 15%;
  }
`;

export const ABwrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 80%;
  line-height: 16px;
  color: #555555;
  padding: 4px 0;
  @media screen and (min-width: 1800px) {
    padding: 6px 0;
  }
  @media screen and (min-width: 2200px) {
    padding: 8px 0;
  }
`;

export const ABaverage = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  padding: 5px 0 0;
  line-height: 14px;
`;

export const Line = styled.div`
  position: relative;
  padding: 0 0 5px;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 130px;
    height: 0.5px;
    background-color: #9f9f9f;
  }
`;

export const ABstatistics = styled.span`
  font-size: 12px;
`;

export const Underline = styled.span`
  position: relative;
  font-weight: 600;
  font-size: 13px;
  color: #555555;
  @media screen and (min-width: 1800px) {
    font-size: 15px;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 130px;
    height: 0.5px;
    background-color: #9f9f9f;
  }
`;

export const ResendStats = styled.span`
  font-size: 11px;
  color: #707070;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  padding: 3px 3px 0;
`;

export const Bold = styled.b`
  font-size: 12px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Resend = styled.span`
  font-size: 11px;
  font-weight: 600;
  gap: 5px;
  padding-left: 5px;
  display: flex;
  justify-content: center;
`;

export const Small = styled.span`
  font-size: 9px;
`;
