import styled from 'styled-components';
import { Send, Visibility } from '@material-ui/icons';

export const Header = styled.div`
  background-color: #005092;
  color: white;
  padding: 10px;
  font-size: 18px;
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Line = styled.div`
  min-width: 120px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  min-width: 500px;
  height: 100%;
`;

export const Content = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow: auto;
`;

export const Title = styled.div`
  font-size: 16px;
  display: flex;
  gap: 10px;
  color: #007ade;
  font-weight: bold;
`;

export const Bold = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: center;
  font-weight: bold;
`;

export const ChatHistory = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: calc(100% - 130px);
  margin: 15px 0 15px 15px;
  padding-right: 30px;
`;

export const LeftSender = styled.div`
  color: #0176ff;
  font-size: 12px;
  font-weight: bold;
  margin-right: auto;
`;

export const LeftChatMessage = styled.div`
  background: #f7f7f7;
  width: 80%;
  padding: 10px;
  border-radius: 5px;
  margin-right: auto;
  margin-bottom: 15px;
`;

export const RightSender = styled.div`
  color: #0176ff;
  font-size: 12px;
  font-weight: bold;
  margin-left: auto;
`;

export const SystemSender = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-left: auto;
`;

export const RightChatMessage = styled.div`
  background: #f2f9ff;
  width: 80%;
  padding: 10px;
  border-radius: 5px;
  margin-left: auto;
  margin-bottom: 15px;
`;

export const SystemMessage = styled.div`
  width: 80%;
  padding: 10px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  margin-left: auto;
  margin-bottom: 15px;
  font-size: 14px;
  color: #565656;
`;

export const Italic = styled.div`
  padding-top: 10px;
  font-style: italic;
`;

export const Date = styled.div`
  color: #555555;
  font-size: 12px;
  margin-left: calc(50% - 50px);
  position: absolute;
`;

export const ChatContainer = styled.div`
  width: 100%;
`;

export const MetaData = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const ReplyButton = styled.button`
  background-color: #0176ff;
  color: white;
  font-size: 13px;
  padding: 3px 10px;
  border: 1px solid #0176ff;
  border-radius: 15px;
  width: 100px;
  display: flex;
  justify-content: center;
  gap: 5px;
  height: 28px;
  transition: all 0.3s ease-in;
  &:hover {
    color: #0176ff;
    font-weight: bold;
    cursor: pointer;
    background-color: white;
    border: 1px solid #0176ff;
  }
  &:disabled {
    background-color: white;
    color: grey;
    cursor: auto;
    border: 1px solid grey;
  }
`;

export const SendIcon = styled(Send)`
  transform: rotate(-35deg);
  padding-bottom: 4px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid #888;
  padding: 10px;
`;

export const ReplyText = styled.div`
  color: #0176ff;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const ReplyTextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
`;

export const ViewedUsers = styled.div`
  font-size: 12px;
  color: orange;
  display: flex;
  align-items: center;
`;

export const ViewedUserIcon = styled(Visibility)`
  transform: scale(0.7);
`;

export const ReplyActionArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextHelp = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const TextMetaData = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 10px;
  color: #555;
`;

export const ErrorMsg = styled.div`
  color: #ff6161;
  font-size: 12px;
  width: 100%;
  text-align: right;
`;
