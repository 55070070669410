import styled from 'styled-components';

export const MessageDetailsContainer = styled.div`
  margin: 15px 25px;
  padding: 0 15px;
  border-left: 1px solid #b8b8b8;
  display: flex;
  flex-direction: column;
  max-height: 260px;
  overflow: auto;
`;

export const MessageDetailsTitle = styled.span`
  font-weight: 800;
  font-size; 16px;
  padding-top: ${props => props.paddingTop || '15px'};
  padding-bottom: 2px;
`;

export const Box = styled.div`
  display: flex;
  gap: 10px;
  padding: 5px 10px;
`;

export const MessageDetailsContent = styled.span`
  display: flex;
  gap: 5px;
  align-items: center;
  width: ${props => props.width || '50%'};
  color: ${props => props.color || '#616161'};
`;

export const Em = styled.span`
  color: ${props => props.color};
  font-weight: 600;
`;
