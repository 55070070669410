import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Drawer,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  FormHelperText,
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { Close, Person, DeleteOutlineTwoTone } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { userUpdate } from '../../../../../redux/services';
import {
  userPrivilegesSelector,
  organizationListSelector,
  privilegesRolesSelector,
  selectedOrganizationSelector,
} from '../../../../../redux/selector';
import { useToast, useUpdateUIState } from '../../../../../redux/action';
import {
  Container,
  Header,
  Content,
  SaveBtn,
  Title,
  Group,
  CreatedDate,
  UserRoleContainer,
} from './EditUserDrawer.styled';
import { Title as ContentTitle } from '../../GeneralInfo/GeneralInfo.styled';
import {
  allowOnlyNumericValues,
  isEmpty,
  isEmailValid,
  isValidPhoneNumber,
  getEmailErrorHelperText,
  getPhoneNumberErrorHelperText,
  reverseObject,
} from '../../utils';
import TextCopy from '../Shared/TextCopy';

const ROLES = [
  //All roles in order from high to low
  'Super Admin',
  'Super User',
  'In House Testing',
  'Executive Admin',
  'Staff Admin',
  'Staff Editor',
  'Staff Support',
];

const EditUserDrawer = ({
  selectedUser,
  isEditDetailsDrawerVisible,
  onClose,
  updateUserList,
}) => {
  const userPrivileges = useSelector(userPrivilegesSelector);
  const privileges = useSelector(privilegesRolesSelector);
  const reversedPrivileges = reverseObject(privileges);
  const organizationList = useSelector(organizationListSelector);
  const selectedOrg = useSelector(selectedOrganizationSelector);
  const rolesReversed = [...ROLES].reverse();
  const { fullLoader } = useUpdateUIState();
  const { errorToast, successToast } = useToast();
  const [firstName, setFirstName] = useState(selectedUser.firstName);
  const [lastName, setLastName] = useState(selectedUser.lastName);
  const [email, setEmail] = useState(selectedUser.email);
  const [phoneNumber, setPhoneNumber] = useState(selectedUser.phoneNumber);
  const [userRole, setUserRole] = useState(selectedUser.role);
  const [userRoleOrganization, setUserRoleOrganization] = useState(
    selectedUser.organizations || []
  );
  const [orgList, setOrgList] = useState({});
  const [selectedOrgID, setSelectedOrgID] = useState('');
  const [multiOrgUser, setMultiOrgUser] = useState(
    selectedUser.multiOrgUser || false
  );

  useEffect(() => {
    setFirstName(selectedUser.firstName);
    setLastName(selectedUser.lastName);
    setEmail(selectedUser.email);
    setPhoneNumber(selectedUser.phoneNumber);
    setUserRole(selectedUser.role);
    setUserRoleOrganization(selectedUser.organizations);
    loadOrgList();
  }, [selectedUser]);

  const convertUserRoleToUserRoleID = userRole => {
    return Object.keys(privileges).find(key => privileges[key] === userRole);
  };

  const handleDeleteUserOrg = orgId => {
    setUserRole('');
    const userOrgList = userRoleOrganization.filter(org => org.orgId !== orgId);
    setUserRoleOrganization(userOrgList);
  };

  const loadOrgList = () => {
    if (organizationList) {
      let orgMapping = {};

      for (const org of organizationList) {
        orgMapping[org.id] = org.organizationName;
      }

      setOrgList(orgMapping);
    }
  };

  const roleAccess = role => {
    const { isSDA, isSA, isSU, manageUser_Exec, manageUser_Admin } =
      userPrivileges;

    if (isSDA) return true; // Super Duper Admin can do anything

    const userRoles = [
      { privilege: isSA, allowedRoles: ROLES },
      { privilege: isSU, allowedRoles: ROLES.slice(1) },
      { privilege: manageUser_Exec, allowedRoles: ROLES.slice(3) },
      { privilege: manageUser_Admin, allowedRoles: ROLES.slice(4) },
    ];

    return userRoles.some(
      ({ privilege, allowedRoles }) => privilege && allowedRoles.includes(role)
    );
  };

  const pushUserOrganization = () => {
    if (!selectedOrgID || !userRole) {
      errorToast(
        'Please select both an organization and a corresponding user role.'
      );
      return;
    }
    const newUserRoleOrg = {
      orgId: selectedOrgID,
      roles: [convertUserRoleToUserRoleID(userRole)],
    };
    const newOrganizations = [...userRoleOrganization, newUserRoleOrg];

    setUserRoleOrganization(newOrganizations);
    setUserRole('');
    setSelectedOrgID('');
  };

  const generateMemberData = () => {
    const date = new Date().toString();

    const updatedUserData = {
      id: selectedUser.id,
      firstName,
      lastName,
      phoneNumber,
      email,
      updatedDate: date.toString(),
      enable: 1,
      organizationId: selectedOrg.id,
      organizations: multiOrgUser
        ? userRoleOrganization
        : [
            {
              orgId: selectedOrg.id,
              roles: [convertUserRoleToUserRoleID(userRole)],
            },
          ],
      multiOrgUser,
    };

    return updatedUserData;
  };

  const checkIfRequiredFieldsAreMissing = () => {
    const missingRequiredFields = [];

    if (!firstName) {
      missingRequiredFields.push('First Name');
    }
    if (!lastName) {
      missingRequiredFields.push('Last Name');
    }
    if (!email) {
      missingRequiredFields.push('Email');
    }
    if (!userRoleOrganization.length && !userRole) {
      missingRequiredFields.push('User Role');
    }
    if (!missingRequiredFields.length) return false;

    errorToast(
      `Please fill out all the required fields: ${missingRequiredFields.join(
        ', '
      )}`
    );

    return true;
  };

  const resetFields = () => {
    setUserRole('');
    setSelectedOrgID('');
  };

  const checkIfFieldsAreValid = () => {
    if (!isEmailValid(email)) {
      errorToast('Email is not valid.');
      return false;
    }

    if (!isValidPhoneNumber(phoneNumber, true)) {
      errorToast('Phone number is not valid.');
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (checkIfRequiredFieldsAreMissing()) return;
    if (!checkIfFieldsAreValid()) return;

    fullLoader(true, 'Updating user...');

    const userData = generateMemberData();

    const didUpdate = await userUpdate(userData);

    if (didUpdate) {
      await updateUserList();
      successToast('User updated successfully.');
    } else {
      errorToast('Error updating user.');
    }

    onClose();
    fullLoader(false);
    resetFields();
  };

  const columns = [
    { field: 'organization', headerName: 'Organization', flex: 1 },
    { field: 'role', headerName: 'Role', flex: 1 },
    {
      field: 'delete',
      headerName: 'Delete',
      flex: 0.5,
      renderCell: ({ id }) => (
        <DeleteOutlineTwoTone
          onClick={() => handleDeleteUserOrg(id)}
          style={{ fontSize: '18px', cursor: 'pointer' }}
        />
      ),
    },
  ];

  const rows = userRoleOrganization?.map(({ orgId, roles }) => {
    return {
      id: orgId,
      organization: orgList[orgId],
      role: roles
        ?.map(roleId =>
          privileges[roleId] !== 'SUPER_USER'
            ? privileges[roleId]
            : 'Super User'
        )
        .join(', '),
    };
  });

  const getUserSelect = () => (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel htmlFor="role">User Role*</InputLabel>
      <Select
        label="Role"
        name="role"
        value={userRole}
        onChange={event => {
          setUserRole(event.target.value);
        }}
        error={isEmpty(userRole) && !userRoleOrganization.length}
      >
        {privileges &&
          rolesReversed.map(role =>
            roleAccess(role) ? (
              <MenuItem key={reversedPrivileges[role]} value={role}>
                {role}
              </MenuItem>
            ) : null
          )}
      </Select>
      {isEmpty(userRole) && !userRoleOrganization.length && (
        <FormHelperText style={{ color: '#f44335' }}>
          Required field
        </FormHelperText>
      )}
    </FormControl>
  );

  return (
    <Drawer
      anchor={'right'}
      onClose={onClose}
      open={isEditDetailsDrawerVisible}
    >
      <Container>
        <Header>
          <Group>
            <IconButton onClick={onClose} style={{ color: 'white' }}>
              <Close />
            </IconButton>
            <Title>
              Edit user: {`${selectedUser.firstName} ${selectedUser.lastName}`}
            </Title>
          </Group>
          <SaveBtn onClick={handleSubmit}>Save</SaveBtn>
        </Header>
        <Content>
          <ContentTitle>
            <Person size="small" />
            User Details
          </ContentTitle>
          <CreatedDate>{`Created: ${moment(selectedUser.createdDate).format(
            'MM/DD/YY'
          )}`}</CreatedDate>
          <TextField
            label="First Name*"
            variant="outlined"
            size="small"
            value={firstName}
            error={isEmpty(firstName)}
            helperText={isEmpty(firstName) ? 'Required field' : ''}
            onChange={e => setFirstName(e.target.value)}
          />
          <TextField
            label="Last Name*"
            variant="outlined"
            size="small"
            value={lastName}
            error={isEmpty(lastName)}
            helperText={isEmpty(lastName) ? 'Required field' : ''}
            onChange={e => setLastName(e.target.value)}
          />
          <TextField
            label="Email*"
            variant="outlined"
            size="small"
            value={email}
            error={isEmpty(email) || !isEmailValid(email)}
            helperText={getEmailErrorHelperText(email)}
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            label="Phone"
            variant="outlined"
            size="small"
            value={phoneNumber}
            error={!isValidPhoneNumber(phoneNumber, true)}
            helperText={getPhoneNumberErrorHelperText(phoneNumber, true)}
            onChange={e =>
              setPhoneNumber(allowOnlyNumericValues(e.target.value, 10))
            }
          />
          {/* Only for not super admin users */}
          {userPrivileges.manageUser_Admin &&
            !userPrivileges.isSU &&
            !userPrivileges.manageAuthOrgs &&
            getUserSelect()}
          {/* Only for executive admins and up*/}
          {userPrivileges.manageUser_Exec && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={multiOrgUser}
                  onChange={event => {
                    setMultiOrgUser(event.target.checked);
                  }}
                  color="primary"
                />
              }
              label="Multi-Organization User"
              style={{ width: '250px' }}
            />
          )}
          {/* Only for super users and up users */}
          {(userPrivileges.isSU || userPrivileges.manageAuthOrgs) && (
            <UserRoleContainer>
              {multiOrgUser ? (
                <>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="organization">
                      Organization*
                    </InputLabel>
                    <Select
                      label="Organization"
                      name="organization"
                      value={selectedOrgID}
                      onChange={event => {
                        setSelectedOrgID(event.target.value);
                      }}
                      error={
                        isEmpty(selectedOrgID) && !userRoleOrganization.length
                      }
                    >
                      {orgList &&
                        Object.entries(orgList).map(([orgId, org]) => (
                          <MenuItem key={orgId} value={orgId}>
                            {org}
                          </MenuItem>
                        ))}
                    </Select>
                    {isEmpty(selectedOrgID) && !userRoleOrganization.length && (
                      <FormHelperText style={{ color: '#f44335' }}>
                        Required field
                      </FormHelperText>
                    )}
                  </FormControl>
                  {getUserSelect()}
                  <Button
                    variant="outlined"
                    style={{ height: '40px' }}
                    onClick={pushUserOrganization}
                  >
                    Add
                  </Button>
                </>
              ) : (
                getUserSelect()
              )}
            </UserRoleContainer>
          )}
          {multiOrgUser && userRoleOrganization.length > 0 && (
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              disableSelectionOnClick
              autoHeight
            />
          )}
          <TextCopy />
        </Content>
      </Container>
    </Drawer>
  );
};

export default EditUserDrawer;
