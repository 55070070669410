import styled from 'styled-components';

export const AddNotesStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const TextBoxStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ButtonStyled = styled.div`
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  display: flex;
`;
