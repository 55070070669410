import styled from 'styled-components';

export const TextBubble = styled.div`
  background: #fff;
  color: #444;
  font-size: 10pt;
  padding: 7px 15px;
  border-radius: 10px;
  width: 100%;
  min-width: 200px;
  position: relative;
  &:after {
    content: '';
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fff;
    bottom: -20px;
    left: 20px;
    position: absolute;
  }
`;

export const TableContainer = styled.div`
  height: ${props => props.height || '100%'};
  overflow: scroll;
  margin-bottom: 10px;
`;
