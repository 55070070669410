import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import TabList from './TabList';
import TabContent from './TabContent';

const TabsContext = createContext({
  getTabs: () => {
    throw new Error('Not implemented');
  },
  isActive: () => {
    throw new Error('Not implemented');
  },
  setActiveTab: () => {
    throw new Error('Not implemented');
  },
  getActiveContent: () => {
    throw new Error('Not implemented');
  },
});

export const useTabs = () => {
  return useContext(TabsContext);
};

const Tabs = ({ tabs, children }) => {
  const [activeTabKey, setActiveTabKey] = useState(tabs[0].key);
  const activeTabContent = useMemo(() => {
    const activeTab = tabs.find(tab => tab.key === activeTabKey);
    return activeTab.content();
  }, [activeTabKey, tabs]);

  const getTabs = useCallback(() => {
    return tabs;
  }, [tabs]);

  const isActive = useCallback(
    tabKey => {
      return tabKey === activeTabKey;
    },
    [activeTabKey]
  );

  const getActiveContent = useCallback(() => {
    return activeTabContent;
  }, [activeTabContent]);

  return (
    <TabsContext.Provider
      value={{
        getTabs,
        isActive,
        setActiveTab: setActiveTabKey,
        getActiveContent,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};

Tabs.List = TabList;
Tabs.Content = TabContent;

export default Tabs;
