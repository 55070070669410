import '../EngagementLogs/EngagementLogs.css';
import '../../globalcss.css';

import { Col, Drawer, Row, Spin } from 'antd';
import moment from 'moment';
import React from 'react';
import { capitalizeFirstLetter } from '../Analytics/utils';

const EngagementDetailDrawer = props => {
  const {
    setDetailsDrawerVisible,
    detailsDrawerVisible,
    memberMessageInfo,
    memberInfo,
    msgDetailsLoading,
    memberEngagement,
  } = props;

  const ondetailsDrawerClose = () => setDetailsDrawerVisible(false);
  const messageStatus = type => {
    let messageType;
    let messageError;
    if (type === 'email') {
      messageType = memberEngagement.recievedByEmail;
      messageError = memberEngagement.emailError;
    } else {
      messageType = memberEngagement.recievedByText;
      messageError = memberEngagement.textError;
    }

    if (messageType) {
      return (
        <span className="detailscontent" style={{ color: '#06c306' }}>
          Delivered
        </span>
      );
    } else if (messageError) {
      return (
        <span
          className="detailscontent"
          style={{ color: 'rgba(255, 0, 0, 0.7490196078431373)' }}
        >
          Not Delivered
        </span>
      );
    } else {
      return (
        <span className="detailscontent" style={{ color: 'rgb(0,0,0)' }}>
          None Sent
        </span>
      );
    }
  };

  const displayEngagementText = event => {
    let eventName = '';
    const { bounceType } = event;
    if (!bounceType) eventName = capitalizeFirstLetter(event.eventName);
    else if (bounceType === 'bounce') eventName = 'Permanent Bounce';
    else eventName = 'Temporary Bounce';
    return (
      event.eventType +
      ' ' +
      eventName +
      ' - ' +
      moment.unix(event.time).format('MM/DD/YY    hh:mm A')
    );
  };

  return (
    <Drawer
      title="Engagement Details"
      className="detailsSection"
      width={475}
      onClose={ondetailsDrawerClose}
      visible={detailsDrawerVisible}
    >
      {memberMessageInfo && memberInfo && (
        <Spin spinning={msgDetailsLoading} tip="Loading Message Details...">
          <div>
            {memberMessageInfo?.isAC && (
              <>
                <Row>
                  <p className="detailspart">
                    <span className="detailslabel">Campaign: </span>
                    <span className="detailscontent">
                      {memberMessageInfo.acName} {memberMessageInfo?.isAC}
                    </span>
                  </p>
                </Row>
                <Row>
                  <p className="detailspart">
                    <span className="detailslabel">Stage: </span>
                    <span className="detailscontent">
                      {memberMessageInfo.stageName}
                    </span>
                  </p>
                </Row>
              </>
            )}
            <Row>
              <p className="detailspart">
                <span className="detailslabel">Delivered Date: </span>
                <span className="detailscontent">
                  {memberMessageInfo.deliveryDate}
                </span>
              </p>
            </Row>
            <Row>
              <p className="detailspart">
                <span className="detailslabel">Sent From: </span>
                <span className="detailscontent">
                  {memberMessageInfo?.isAC ? (
                    memberMessageInfo.from
                  ) : (
                    <>
                      {memberMessageInfo.from}
                      <span>
                        {'  '}&#9679;{'  '}
                      </span>
                      {memberMessageInfo.orgPhone}
                    </>
                  )}
                </span>
              </p>
            </Row>
            <Row>
              <p className="detailspart">
                <span className="detailslabel">
                  {' '}
                  {memberMessageInfo?.isAC
                    ? 'Sent To:'
                    : 'Receiving Email:'}{' '}
                </span>
                <span className="detailscontent">
                  {memberMessageInfo.memberEmail}
                </span>
              </p>
            </Row>
            {!memberMessageInfo?.isAC && (
              <Row>
                <p className="detailspart">
                  <span className="detailslabel">Receiving Phone Number: </span>
                  <span className="detailscontent">
                    {memberMessageInfo.memberPhone}
                  </span>
                </p>
              </Row>
            )}
            <Row>
              <p className="detailspart">
                <span className="detailslabel">
                  <a
                    href={
                      memberMessageInfo?.isAC
                        ? `${window.location.origin}/message?acId=${memberMessageInfo.acId}|${memberMessageInfo.stageId}`
                        : `${window.location.origin}/message?id=${memberMessageInfo.id}`
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Message
                  </a>
                </span>
                <span className="detailscontent" />
              </p>
            </Row>

            <Col span={24} className="tagsec">
              <div className="labelHead">
                <span className="detailslabel taghead">
                  <b>{'Message Status:'}</b>
                </span>
              </div>
              <p className="boxDetails">
                <span
                  className="detailscontent"
                  style={{ color: 'rgb(0,0,0)' }}
                >
                  Email:{' '}
                </span>
                {messageStatus('email')}
                <span className="textpart">
                  <span
                    className="detailscontent"
                    style={{ color: 'rgb(0,0,0)' }}
                  >
                    Text:{' '}
                  </span>
                </span>
                {messageStatus('text')}
              </p>
            </Col>

            <Col span={24} className="tagsec">
              <div className="labelHead">
                <span className="detailslabel taghead">
                  <b>{'Engagement:'}</b>
                </span>
              </div>
              <p className="boxDetails">
                <span style={{ color: 'rgb(0,0,0)' }}>
                  <span className="detailslabel">
                    <span
                      className="detailscontent"
                      style={{ color: 'rgb(0,0,0)' }}
                    >
                      Opens:{' '}
                    </span>
                  </span>
                  <span
                    style={{
                      color:
                        memberEngagement.openCount > 0
                          ? '#06c306'
                          : 'rgb(0,0,0)',
                    }}
                  >
                    {memberEngagement.openCount}
                  </span>
                </span>

                <span className="textClick" style={{ color: 'rgb(0,0,0)' }}>
                  <span className="detailslabel">
                    <span
                      className="detailscontent"
                      style={{ color: 'rgb(0,0,0)' }}
                    >
                      Clicks:{' '}
                    </span>
                  </span>
                  <span
                    className="detailscontent"
                    style={{
                      color:
                        memberEngagement.clickCount > 0
                          ? '#06c306'
                          : 'rgb(0,0,0)',
                    }}
                  >
                    {memberEngagement.clickCount}
                  </span>
                </span>
              </p>
            </Col>

            <Col span={24} className="tagsec">
              <div className="labelHead">
                <span className="detailslabel taghead">
                  <b>{'Events:'}</b>
                </span>
              </div>
              {memberEngagement.events.map((event, i) => {
                return (
                  <li key={i}>
                    <span
                      style={{
                        color: 'rgb(0,0,0)',
                      }}
                    >
                      {displayEngagementText(event)}
                    </span>
                  </li>
                );
              })}
            </Col>
          </div>
        </Spin>
      )}
    </Drawer>
  );
};

export default EngagementDetailDrawer;
