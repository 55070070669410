import React, { useState, useEffect } from 'react';
import {
  Container,
  Menu,
  Content,
  MenuItem,
  Row,
  InfoText,
  InfoIcon,
} from '../MessageAnalytics/MessageAnalytics.styled';
import AllChannelsABversion from './Components/AllChannelsABversion';
import EmailABversion from './Components/EmailABversion';
import TextABversion from './Components/TextABversion';
import { CircularProgress } from '@material-ui/core';
import { ALL_CHANNELS, EMAIL, TEXT, STATISTICS_TABS } from '../constants';

const MessageAnalyticsABversion = ({
  textEvents,
  emailEvents,
  message,
  startDate,
  isLoadingData,
  setClickActivityVisibility,
  smartResendEmailEvents,
  isSmartResendAvailable,
  setSmartResendAvailability,
}) => {
  const [currentTab, setCurrentTab] = useState(STATISTICS_TABS[0]);

  useEffect(() => {
    setSmartResendAvailability(
      message && smartResendEmailEvents ? true : false
    );
  }, [message, smartResendEmailEvents]);

  const isLoading =
    isLoadingData || !(textEvents?.versionB && emailEvents?.versionB);
  const handleTabClick = tab => {
    setCurrentTab(tab);
  };

  const generateRatio = (numerator, denominator) => {
    if (isNaN(numerator) || isNaN(denominator) || !numerator || !denominator)
      return '0%';

    return `${((numerator / denominator) * 100).toFixed(1)}%`;
  };

  const getAverage = (A, B) => {
    return ((A + B) / 2).toFixed(1);
  };

  const generateAverage = (A, B) => {
    // if inputs are strings, then those numbers are percentages
    if (typeof A === 'string') {
      // convert string to number and then add percentage sign at the end
      return getAverage(parseFloat(A), parseFloat(B)) + '%';
    }

    // check if A and B exists and if they are numbers
    // if not then return 0
    if (isNaN(A) || isNaN(B) || !A || !B) return '0';

    return getAverage(A, B);
  };

  const renderContent = currentTab => {
    switch (currentTab) {
      case ALL_CHANNELS:
        return (
          <AllChannelsABversion
            emailEvents={emailEvents}
            smartResendEmailEvents={smartResendEmailEvents}
            generateRatio={generateRatio}
            generateAverage={generateAverage}
            textEvents={textEvents}
            isSmartResendAvailable={isSmartResendAvailable}
          />
        );
      case EMAIL:
        return (
          <EmailABversion
            emailEvents={emailEvents}
            smartResendEmailEvents={smartResendEmailEvents}
            generateRatio={generateRatio}
            generateAverage={generateAverage}
            isLoadingData={isLoading}
            message={message}
            startDate={startDate}
            setClickActivityVisibility={setClickActivityVisibility}
            isSmartResendAvailable={isSmartResendAvailable}
          />
        );
      case TEXT:
        return (
          <TextABversion
            generateRatio={generateRatio}
            generateAverage={generateAverage}
            isLoadingData={isLoading}
            message={message}
            startDate={startDate}
            textEvents={textEvents}
          />
        );
    }
  };

  return (
    <Container>
      <Row>
        <Menu>
          {STATISTICS_TABS.map(title => (
            <MenuItem
              onClick={() => handleTabClick(title)}
              key={title}
              className={title === currentTab && 'active'}
            >
              {title}
            </MenuItem>
          ))}
        </Menu>
        <InfoText>
          <InfoIcon />
          <>Click a stat for more details</>
        </InfoText>
      </Row>
      {isLoading ? (
        <Content style={{ height: '85%' }}>
          <CircularProgress
            style={{ margin: 'auto', alignItems: 'center', color: '#007ADE' }}
          />
        </Content>
      ) : (
        <Content>{renderContent(currentTab)}</Content>
      )}
    </Container>
  );
};

export default MessageAnalyticsABversion;
