import './CustomFields.css';
import 'antd/dist/antd.css';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectedOrganizationSelector } from '../../redux/selectors/organization';
import { getCustomFields } from '../../redux/services/customFields';
import FieldsTable from './FieldsTable';

const FieldManager = props => {
  const [CustomFields, setCustomFields] = useState([]);
  const selectedOrgnization = useSelector(selectedOrganizationSelector);

  useEffect(() => {
    if (!CustomFields.length && selectedOrgnization.id) {
      fetchOrgCustomFields();
    }
  }, [selectedOrgnization.id]);

  const fetchOrgCustomFields = async () => {
    const fields = await getCustomFields(
      selectedOrgnization.id,
      selectedOrgnization.orgType
    );
    setCustomFields(fields);
  };

  return (
    <FieldsTable
      contacts={props.contacts}
      tableData={CustomFields}
      refreshManagePage={() => props.refreshAll()}
    />
  );
};

export default FieldManager;
