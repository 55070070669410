import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export const DrawerHeaderStyled = styled.div`
  color: white !important;
  font-size: 16px;

  ${({ fixed }) =>
    fixed &&
    `
    position: fixed;
    z-index: 2;
  `}

  width: 100%;
  background: #005092;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;

  & div {
    align-items: center;
    text-transform: capitalize;
    font-family: 'Montserrat';
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .save-btn {
    width: 80px;
    background-color: #5eab00 !important;
    color: white !important;
    border: none !important;
    border-radius: 25px !important;
  }
`;

export const IconButtonStyled = styled(IconButton)`
  svg {
    fill: #fff;
  }
`;
