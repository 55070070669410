import './Manage.css';

import { Button, IconButton, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';

const DeleteTagModal = props => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleOpen = () => setModalVisible(true);
  const handleClose = () => {
    setModalVisible(false);
    props.setVisible(false);
  };

  useEffect(() => {
    handleOpen();
  }, []);

  return (
    <div>
      <Modal open={modalVisible} onClose={handleClose}>
        <div className={'modalStyling'}>
          <div className={'modalHeader'}>
            <IconButton
              onClick={() => {
                handleClose();
              }}
              style={{ color: 'white' }}
            >
              <CloseIcon />
            </IconButton>
            <span style={{ fontSize: '16px' }}>Confirm Deletion</span>
          </div>
          <div className={'modalBody'}>
            <p>
              This action will delete the tags listed below and all data
              specific to these tags.{' '}
              <b>Once deleted, this data cannot be recovered.</b>
            </p>
            <div
              className={'modalList'}
              style={{
                overflowY: `${props.deleteList.length > 4 ? 'scroll' : ''}`,
              }}
            >
              {props.deleteList.map(id => {
                let tag = props.tagList.find(tag => tag.id === id);

                if (!tag) {
                  tag = props.organizationTopics.find(topic => topic.id === id);
                }
                return (
                  <div className={'modalListItem'} key={tag.name}>
                    {tag.name}
                  </div>
                );
              })}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                size={'small'}
                style={{
                  backgroundColor: '#FF6161',
                  color: 'white',
                  border: 'none',
                }}
                variant="outlined"
                onClick={() => {
                  handleClose();
                }}
              >
                No, cancel
              </Button>
              <Button
                size={'small'}
                style={{
                  backgroundColor: '#5EAB00',
                  color: 'white',
                  border: 'none',
                }}
                variant="outlined"
                onClick={() => {
                  handleClose();
                  props.deleteTag();
                }}
              >
                Yes, delete
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteTagModal;
