import React, { useState } from 'react';
import { Drawer, Spin } from 'antd';
import { Box } from 'grommet';
import MaterialTable, { MTableCell } from 'material-table';
import { LoadingOutlined } from '@ant-design/icons';
import { capitalizeFirstLetter } from '../../../utils';
import { utils, writeFile } from 'xlsx';

const FailedDeliveryReport = ({
  onCloseDrawer,
  isVisible,
  isLoading,
  message,
  startDate,
  data,
  csvData,
  title = 'Bounce',
}) => {
  const [pageSize, setPageSize] = useState(20);
  const antIcon = (
    <LoadingOutlined
      type="loading"
      style={{ fontSize: 24, float: 'right' }}
      spin
    />
  );

  const displayBounceType = bounceEvent => {
    const { bounceType } = bounceEvent;
    if (!bounceType) return capitalizeFirstLetter(bounceEvent.eventName);
    if (bounceType === 'bounce') return 'Permanent Bounce';
    return 'Temporary Bounce';
  };

  const getFileName = () => {
    return `${title} Report ${
      message ? '- ' + message.messageSubject : `${startDate} - ${new Date()}`
    }.xls`.replaceAll('/', '.');
  };

  const exportFile = () => {
    const ws = utils.aoa_to_sheet(csvData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'SheetJS');
    writeFile(wb, getFileName());
  };

  return (
    <Drawer
      key={`${title} report`}
      title={`${title} Report`}
      width={735}
      onClose={onCloseDrawer}
      visible={isVisible}
      style={{
        overflow: 'scroll',
        paddingLeft: '0',
        paddingRight: '0',
      }}
    >
      <Spin
        spinning={isLoading}
        tip={`Loading ${title} Data ...`}
        indicator={antIcon}
      >
        <div className="ManagePortalUserscomp ant-drawerpart">
          <Box overflow="auto">
            <div className="middlePart">
              <button className="export-drawer" onClick={exportFile}>
                Export
              </button>
              <Box className="noterouter_managetable btnseparation">
                <MaterialTable
                  components={{
                    Cell: props => <MTableCell {...props} />,
                  }}
                  columns={[
                    {
                      title: 'Contact',
                      field: 'contact',
                      render: rowData => {
                        return <p>{rowData.contact}</p>;
                      },
                    },
                    {
                      title: 'Date',
                      field: 'time',
                      defaultSort: 'desc',
                      render: rowData => {
                        return <p>{rowData.time}</p>;
                      },
                    },
                    {
                      title: 'Event',
                      field: 'eventName',
                      render: rowData => {
                        return <p>{displayBounceType(rowData)}</p>;
                      },
                    },
                  ]}
                  data={data}
                  detailPanel={rowData => {
                    return (
                      <div className="detailPanel">
                        <p>
                          <b>Error Reason: </b>
                          {rowData.errorCode + ' ' + rowData.errorMessage}
                        </p>
                      </div>
                    );
                  }}
                  onRowClick={(event, rowData, togglePanel) => togglePanel()}
                  className="Manage_materialtable"
                  title=""
                  localization={{
                    body: {
                      emptyDataSourceMessage:
                        'No recorded bounces for this time period',
                    },
                  }}
                  options={{
                    padding: 'dense',
                    search: true,
                    pageSize: pageSize,
                    pageSizeOptions: [10, 20, 50],
                  }}
                  onChangeRowsPerPage={e => {
                    localStorage.setItem('pageSize', e);
                    setPageSize(e);
                  }}
                />
              </Box>
            </div>
          </Box>
        </div>
      </Spin>
    </Drawer>
  );
};

export default FailedDeliveryReport;
