import api from '../../../utils/andromeda-api';

export const getNotesAPI = (userId, contactId, params, cancelToken) => {
  return api.get(`/contactNotes/${userId}/contact/${contactId}`, {
    params,
    cancelToken,
  });
};

export const postNoteAPI = (data, cancelToken) => {
  return api.post('/contactNotes', data, {
    cancelToken,
  });
};

export const patchNoteAPI = (noteId, data, cancelToken) => {
  return api.patch(`/contactNotes/${noteId}`, data, {
    cancelToken,
  });
};

export const deleteNoteAPI = (noteId, cancelToken) => {
  return api.delete(`/contactNotes/${noteId}`, {
    cancelToken,
  });
};
