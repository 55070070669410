import styled from 'styled-components';
import { DatePicker as AntdDatePicker } from 'antd';
import { TextField } from '@material-ui/core';

const DatePickerStyled = styled(AntdDatePicker)`
  border: 1px solid #efefef;
  width: 100%;
  input {
    /* Had to remove input border inside datepicker otherwise it looks very odd */
    border: none;
  }
`;

export const TextFieldStyled = styled(TextField)`
  > .MuiInputBase-root {
    border: ${props => props.error && '1px solid red'};
    border-radius: 2px;
    color: ${props => props.error && 'red !important'};
  }
`;

export default DatePickerStyled;
