import React from 'react';
import styled from 'styled-components';
import {
  Button as BaseButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Add, Archive, Search } from '@material-ui/icons';

import { withProps } from '../../../libs/react-utils';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  margin-bottom: 25px;
`;

Header.Left = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

Header.Right = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Button = styled(BaseButton)`
  padding: 10px 12px !important;
  font-size: 16px !important;
  line-height: 1.25 !important;
  color: #ffffff !important;
  background-color: ${({ background }) => background} !important;
  border-radius: 5px !important;

  &:disabled {
    color: #6c7075 !important;
    background-color: #cacaca !important;
  }

  // Override global default styles which set Poppins as font-family for everything (which is pretty bad!)
  .MuiButton-label {
    font-family: 'Montserrat', sans-serif !important;
  }
`;

export const Tab = withProps({ background: '#007ade' }, Button);

export const CreateButton = withProps(
  {
    startIcon: <Add />,
    background: '#5eab00',
  },
  Button
);

export const ArchiveButton = styled(Button).attrs({
  startIcon: <Archive />,
  background: '#ff6161',
})`
  &:disabled {
    pointer-events: all !important;
    cursor: not-allowed !important;
  }
`;

export const ArchiveList = styled.ul`
  margin: 0;
  padding-left: 12px;
  max-height: 200px;
  overflow-y: auto;
`;

ArchiveList.Item = 'li';

export const SearchInput = styled(TextField).attrs({
  variant: 'outlined',
  size: 'small',
  InputProps: {
    startAdornment: (
      <InputAdornment position="start">
        <Search />
      </InputAdornment>
    ),
  },
})`
  width: 320px !important;

  .MuiInputBase-root {
    color: #6c7075;
    font-size: 14px;
    gap: 4px;
  }

  .MuiInputBase-input {
    &::placeholder {
      color: #c1c8c9;
    }
  }

  .MuiSvgIcon-root {
    font-size: 20px;
  }

  .MuiOutlinedInput-notchedOutline {
    transition: border-color 250ms ease-in;

    &:is(:hover, :focus, :active) {
      transition-timing-function: ease-out;
    }

    &:not(:hover, :focus, :active) {
      border-color: #d4d7d9;
    }
  }
`;

export const TableWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

TableWrapper.Toolbar = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 25px;
`;

TableWrapper.Content = styled.div`
  flex-grow: 1;
`;
