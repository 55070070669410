import Modal from '../../Modal';
import React from 'react';
import { fullloader, toast } from '../../../redux/actions/UI';
import { resetOrganizationGroup } from '../../../redux/services/groups';
import { useDispatch } from 'react-redux';

const ResetModal = props => {
  const { type, closeModal, refresh, name, id, orgId } = props;
  const dispatch = useDispatch();

  const reset = async () => {
    if (id) {
      dispatch(fullloader(true, `Resetting ${type}`));
      const resetOptions = {
        orgId,
        tagId: id,
        type,
      };
      const resetRes = await resetOrganizationGroup(resetOptions);
      if (!resetRes.error) {
        dispatch(fullloader(true, `Fetching ${type}s`));
        closeModal();
        refresh(orgId);
        dispatch(
          toast(
            'success',
            `Reset ${type} successfully, removed from ${resetRes.result} contacts`,
            5
          )
        );
      } else {
        dispatch(
          toast('error', `Something went wrong resetting your ${type}.`)
        );
      }
    } else {
      dispatch(toast('error', `Invalid ${type} to reset.`));
    }
  };

  return (
    <Modal
      title={`Reset ${type}: "${name || ''}"`}
      onClose={closeModal}
      primaryButton={{
        label: 'Reset',
        onClick: reset,
      }}
      secondaryButton={{
        label: 'Cancel',
        onClick: closeModal,
      }}
    >
      <div>
        <p>
          {`Are you sure you want to reset this ${type}? Resetting this ${type} will remove it from any contacts it is currently assigned to.`}
        </p>
      </div>
    </Modal>
  );
};
export default ResetModal;
