import { fetchTargetedAudience } from '../../../../../redux/services';
import {
  MEMBERSHIP_STATUS,
  MEMBERSHIP_TYPE,
  MEMBERSHIP_AND_BILLING_STATUS,
  BALANCE_BY_CHARGE_CODE,
  TOTAL_OF_ALL_OUTSTANDING_BALANCES,
  HOME_CITY,
  HOME_COUNTY,
  HOME_ZIP_CODE,
  HOME_STATE,
  OFFICE_CITY,
  OFFICE_COUNTY,
  OFFICE_ZIP_CODE,
  OFFICE_STATE,
  BIRTHDATE,
  JOIN_DATE,
  ORIENTATION_DATE,
  LICENSE_EXPIRATION_DATE,
  LAST_PREFERENCE_PAGE_VISIT,
  CREDIT_CARD_EXPIRATION_DATE,
  EQUAL_TO,
  GREATER_THAN,
  LESS_THAN,
  BETWEEN_AMOUNT,
  BETWEEN_DATE,
  ON_OR_BEFORE,
  ON_OR_LATER,
  NO_DATE_GIVEN,
  COMMITTEES,
  MEMBER_DESIGNATIONS,
  DESIGNATION_SHOULD_EXIST,
  NEVER,
  EVENT_AND_CLASS_REGISTRATION,
  COURSE_REGISTRATION,
  EVENT_AND_CLASS_ATTENDANCE,
  COURSE_ATTENDANCE,
  ADDED_DATE,
  CODE_OF_ETHICS_COMPLETION,
} from '../Content/Components/Filters/Components/_shared/constants';
import { ADD, EXCLUDE, REQUIRE } from '../../constants';
import { retrieveSpecificGroupsCategory } from '../../../../_shared/utils';

const getAmoutRange = ({ comparisonOperator, amount, maxAmount }) => {
  let amountRange;
  if (comparisonOperator === BETWEEN_AMOUNT) {
    amountRange = { lte: Number(maxAmount), gte: Number(amount) };
  } else if (comparisonOperator === EQUAL_TO) {
    amountRange = { eq: Number(amount) };
  } else if (comparisonOperator === GREATER_THAN) {
    amountRange = { gt: Number(amount) };
  } else if (comparisonOperator === LESS_THAN) {
    amountRange = { lt: Number(amount) };
  }

  return amountRange;
};

const getDateRange = ({ comparisonOperator, date, maxDate }) => {
  let dateRange;
  if (comparisonOperator === BETWEEN_DATE) {
    dateRange = { lte: new Date(maxDate), gte: new Date(date) };
  } else if (comparisonOperator === ON_OR_LATER) {
    dateRange = { gte: new Date(date) };
  } else if (comparisonOperator === ON_OR_BEFORE) {
    dateRange = { lte: new Date(date) };
  } else if (comparisonOperator === NEVER) {
    dateRange = { never: true };
  } else if (comparisonOperator === NO_DATE_GIVEN) {
    dateRange = { eq: '' };
  }

  return dateRange;
};

const addEqualOperator = data => {
  if (data) {
    return data.map(({ value }) => ({ eq: value }));
  }
  return [];
};

const aggregateMembersForQuery = members => {
  const keys = members ? Object.keys(members) : [];

  if (keys.length) {
    // using eq to store an array of included members for querying included members
    // using ne to store a hash map for filtering out the final list
    const query = { eq: [], ne: {} };
    for (let i = 0; i < keys.length; i++) {
      if (members[keys[i]].action === EXCLUDE) {
        query.ne[keys[i]] = true;
      } else if (members[keys[i]].action === ADD) {
        query.eq.push(keys[i]);
      }
    }
    return query;
  }

  return [];
};

const getMembers = async (
  audience,
  orgId,
  responseFilters,
  getUniqueValues = false
) => {
  if (audience.selectedTopicsList.length) {
    const audience_requirements = {
      responseFilters,
      tagData: {
        topics: audience.selectedTopicsList,
        // groups === identifiers
        identifiers: retrieveSpecificGroupsCategory(
          audience.selectedGroups,
          ADD
        ),
        excluded: retrieveSpecificGroupsCategory(
          audience.selectedGroups,
          EXCLUDE
        ),
        required: retrieveSpecificGroupsCategory(
          audience.selectedGroups,
          REQUIRE
        ),
      },
      members: aggregateMembersForQuery(audience.selectedMembers),
      homeCity: addEqualOperator(audience.selectedFilters?.[HOME_CITY]),
      homeCounty: addEqualOperator(audience.selectedFilters?.[HOME_COUNTY]),
      homeZipCode: addEqualOperator(audience.selectedFilters?.[HOME_ZIP_CODE]),
      homeState: addEqualOperator(audience.selectedFilters?.[HOME_STATE]),
      officeCity: addEqualOperator(audience.selectedFilters?.[OFFICE_CITY]),
      officeCounty: addEqualOperator(audience.selectedFilters?.[OFFICE_COUNTY]),
      officeZipCode: addEqualOperator(
        audience.selectedFilters?.[OFFICE_ZIP_CODE]
      ),
      officeState: addEqualOperator(audience.selectedFilters?.[OFFICE_STATE]),
      birthdate: [],
      membershipJoinDate: [],
      membershipOrientationDate: [],
      licenseExpirationDate: [],
      lastPreferencePageVisitedTime: [],
      creditCardExpirationDate: [],
      balanceByChargeCode: [],
      membershipAndStatus: audience.selectedFilters?.[MEMBERSHIP_STATUS] || [],
      codeOfEthicsCompletion:
        audience.selectedFilters?.[CODE_OF_ETHICS_COMPLETION] || '',
      memberDesignations: audience.selectedFilters?.[MEMBER_DESIGNATIONS] || [],
      designationShouldExist:
        audience.selectedFilters?.[DESIGNATION_SHOULD_EXIST] ?? false,
      membershipAndType: audience.selectedFilters?.[MEMBERSHIP_TYPE] || [],
      membershipAndBillingStatus:
        audience.selectedFilters?.[MEMBERSHIP_AND_BILLING_STATUS] || [],
      totalOfAllOutstandingBalances: [],
      orgId,
      getUniqueValues,
      committees: audience.selectedFilters?.[COMMITTEES] || [],
      eventAndClassRegistration:
        audience.selectedFilters?.[EVENT_AND_CLASS_REGISTRATION] || [],
      courseRegistration: [],
      eventAndClassAttendance:
        audience.selectedFilters?.[EVENT_AND_CLASS_ATTENDANCE] || [],
      courseAttendance: [],
      addedDate: [],
    };

    audience.selectedFilters?.[COURSE_REGISTRATION]?.forEach(
      ({ registrationStatus, courseId, startDate, endDate }) => {
        audience_requirements.courseRegistration.push({
          registrationStatus,
          courseId,
          ...getDateRange({
            comparisonOperator: BETWEEN_DATE,
            date: startDate,
            maxDate: endDate,
          }),
        });
      }
    );

    audience.selectedFilters?.[COURSE_ATTENDANCE]?.forEach(
      ({ attendanceStatus, courseId, startDate, endDate }) => {
        audience_requirements.courseAttendance.push({
          attendanceStatus,
          courseId,
          ...getDateRange({
            comparisonOperator: BETWEEN_DATE,
            date: startDate,
            maxDate: endDate,
          }),
        });
      }
    );

    audience.selectedFilters?.[BALANCE_BY_CHARGE_CODE]?.forEach(
      ({ chargeCode, balanceType, comparisonOperator, amount, maxAmount }) => {
        audience_requirements.balanceByChargeCode.push({
          itemName: chargeCode,
          amount: getAmoutRange({
            comparisonOperator,
            amount,
            maxAmount,
          }),
          paid: balanceType === 'paid' ? true : false,
        });
      }
    );

    audience.selectedFilters?.[TOTAL_OF_ALL_OUTSTANDING_BALANCES]?.forEach(
      ({ comparisonOperator, amount, maxAmount }) => {
        audience_requirements.totalOfAllOutstandingBalances.push(
          getAmoutRange({
            comparisonOperator,
            amount,
            maxAmount,
          })
        );
      }
    );

    const convertDateToMongodbComparatorOperator = (
      data,
      propertyName,
      withMembership
    ) => {
      if (data) {
        data.forEach(
          ({ comparisonOperator, date, maxDate, selectedMembership }) => {
            audience_requirements[propertyName].push({
              ...getDateRange({
                comparisonOperator,
                date,
                maxDate,
              }),
              ...(withMembership && { membershipName: selectedMembership }),
            });
          }
        );
      }
    };

    convertDateToMongodbComparatorOperator(
      audience.selectedFilters?.[BIRTHDATE],
      'birthdate'
    );
    convertDateToMongodbComparatorOperator(
      audience.selectedFilters?.[JOIN_DATE],
      'membershipJoinDate',
      true
    );
    convertDateToMongodbComparatorOperator(
      audience.selectedFilters?.[ORIENTATION_DATE],
      'membershipOrientationDate',
      true
    );
    convertDateToMongodbComparatorOperator(
      audience.selectedFilters?.[LICENSE_EXPIRATION_DATE],
      'licenseExpirationDate'
    );
    convertDateToMongodbComparatorOperator(
      audience.selectedFilters?.[LAST_PREFERENCE_PAGE_VISIT],
      'lastPreferencePageVisitedTime'
    );
    convertDateToMongodbComparatorOperator(
      audience.selectedFilters?.[CREDIT_CARD_EXPIRATION_DATE],
      'creditCardExpirationDate'
    );
    convertDateToMongodbComparatorOperator(
      audience.selectedFilters?.[ADDED_DATE],
      'addedDate'
    );

    const { emailRecipients, textRecipients, uniqueValuesCount } =
      await fetchTargetedAudience(audience_requirements);
    return { emailRecipients, textRecipients, uniqueValuesCount };
  }
};

export default getMembers;
