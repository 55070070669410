import './ResetPassword.css';
import 'antd/dist/antd.css';

import * as Icon from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { Box, Grommet } from 'grommet';
import { grommet } from 'grommet/themes';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as actioncreators from '../../redux/action';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.updateInput = this.updateInput.bind(this);
    this.loginUser = this.loginUser.bind(this);
    this.forgotpassword = this.forgotpassword.bind(this);
    this.compareToFirstPassword = this.compareToFirstPassword.bind(this);
    this.validateToNextPassword = this.validateToNextPassword.bind(this);
    this.handleConfirmBlur = this.handleConfirmBlur.bind(this);
  }
  componentDidMount() {
    this.props.actions.gAnalytics('pageview', '/reset-password');
  }

  handleCancel() {
    if (typeof this.props.handleCancel === 'function') {
      this.props.handleCancel();
    }
  }

  updateInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  loginUser(e) {
    e.preventDefault();
    console.log(this.props.actions);
    let data = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.actions.login(data, this.props.history); //CREATE NEW PROJECT
  }

  forgotpassword() {
    this.props.history.push({
      pathname: '/forgotpass',
    });
  }

  compareToFirstPassword(rule, value, callback) {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  }

  validateToNextPassword(rule, value, callback) {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  }

  handleConfirmBlur(e) {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formLayout } = this.state;
    const formItemLayout =
      formLayout === 'vertical'
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : null;

    return (
      <div>
        <Grommet theme={grommet} className="resetsec ">
          <Box className="logoimg"> </Box>
          <Box className="resetfirst">
            <p> Reset Password </p>
            {/* <FormField htmlFor="text-input" >
                            <Box className="inputbox">
                                <Secure />
                                <TextInput
                                    type="password"
                                    name="password"
                                    placeholder="Old Password"
                                    onChange={this.updateInput}
                                    value={this.state.password}
                                />
                            </Box>
                            <Box className="inputbox">
                                <Secure />
                                <TextInput
                                    type="password"
                                    name="password1"
                                    placeholder="New Password"
                                    onChange={this.updateInput}
                                    value={this.state.password1}
                                />
                            </Box>
                            <Box className="inputbox">
                                <Secure />
                                <TextInput
                                    type="password"
                                    name="password2"
                                    placeholder="Confirm Password"
                                    onChange={this.updateInput}
                                    value={this.state.password2}
                                />
                            </Box>
                            <Button label="Save" href="/login" type="submit" 
                            />
                        </FormField> */}
            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item label="Old Password" {...formItemLayout}>
                {getFieldDecorator('oldpassword', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your old password!',
                    },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    type="password"
                  />
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="New Password">
                {getFieldDecorator('newpassword', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      validator: this.validateToNextPassword,
                    },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    type="password"
                  />
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Confirm Password">
                {getFieldDecorator('confirm', {
                  rules: [
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    type="password"
                    onBlur={this.handleConfirmBlur}
                  />
                )}
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  SUBMIT
                </Button>
              </Form.Item>
            </Form>
          </Box>
        </Grommet>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state,
});
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actioncreators, dispatch),
  };
}
const WrappedResetPassword = ResetPassword;
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WrappedResetPassword)
);
