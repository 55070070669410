import { toast } from './UI';
import {
  convertAudienceDataForStandAloneAudience,
  removeExtraFieldsFromStandAloneAudienceForDraft,
} from '../../Component/_shared/utils';
import api from '../../utils/andromeda-api';

export function selectMenuItem(detail) {
  return {
    type: 'SELECT_AUDIENCE_MENU_ITEM',
    detail,
  };
}

export function selectSubMenuItem(detail) {
  return {
    type: 'SELECT_AUDIENCE_SUBMENU_ITEM',
    detail,
  };
}

export function selectTopics(detail) {
  return {
    type: 'SELECT_TOPICS',
    detail,
  };
}

export function selectMember(detail) {
  return {
    type: 'SELECT_MEMBER',
    detail,
  };
}

export function removeMember(detail) {
  return {
    type: 'REMOVE_MEMBER',
    detail,
  };
}

export function resetMembersList(detail) {
  return {
    type: 'RESET_MEMBERS_LIST',
    detail,
  };
}

export function loadMembers(detail) {
  return {
    type: 'LOAD_MEMBERS_LIST',
    detail,
  };
}

export function selectGroup(detail) {
  return {
    type: 'SELECT_GROUP',
    detail,
  };
}

export function removeGroup(detail) {
  return {
    type: 'REMOVE_GROUP',
    detail,
  };
}

export function loadTargetAudience(detail) {
  return {
    type: 'LOAD_TARGET_AUDIENCE',
    detail,
  };
}

export function addFilter(detail) {
  return {
    type: 'ADD_FILTER',
    detail,
  };
}

export function removeFilter(...detail) {
  return {
    type: 'REMOVE_FILTER',
    detail,
  };
}

export function resetAudienceBuilder() {
  return {
    type: 'RESET_AUDIENCE_BUILDER',
  };
}

export function loadFilterTypes(detail) {
  return {
    type: 'LOAD_FILTER_TYPES',
    detail,
  };
}

export function loadAudienceFilters(detail) {
  return {
    type: 'LOAD_AUDIENCE_FILTERS',
    detail,
  };
}

export function applyAudienceToNewDraftAction(detail) {
  return {
    type: 'APPLY_AUDIENCE_TO_NEW_DRAFT',
    detail,
  };
}

export function applyEditStandAloneAudienceAction(detail) {
  return {
    type: 'APPLY_EDIT_STANDALONE_AUDIENCE',
    detail,
  };
}

export function saveStandAloneAudienceDescription(detail) {
  return {
    type: 'SAVE_STANDALONE_AUDIENCE_DESCRIPTION',
    detail,
  };
}

export function saveStandAloneAudienceName(detail) {
  return {
    type: 'SAVE_STANDALONE_AUDIENCE_NAME',
    detail,
  };
}

// detail could be either draftData or draftIdentifier depending on where the function is called
export const updateAudienceFromDraft = detail => async dispatch => {
  try {
    let draftData = detail;

    if (typeof detail === 'string') {
      const response = await api.get(`/drafts/getDraftBy_id/${detail}`);
      draftData = response.data.result;
    }

    dispatch({
      type: 'UPDATE_AUDIENCE_FROM_DRAFT',
      detail: draftData,
    });
  } catch (e) {
    await dispatch(toast('error', 'Error saving draft.'));
  }
};

export const saveNewStandAloneAuidenceAction =
  (detail, organizationId) => async dispatch => {
    try {
      const audience = convertAudienceDataForStandAloneAudience(
        detail,
        organizationId
      );
      const { data } = await api.post('/audiences', audience);

      dispatch({
        type: 'SAVE_NEW_STANDALONE_AUDIENCE_ID',
        detail: data.audience._id,
      });
    } catch (e) {
      dispatch(toast('error', 'Error saving new audience.'));
    }
  };

export const updateStandAloneAudienceAction =
  (id, detail) => async dispatch => {
    const audience = convertAudienceDataForStandAloneAudience(detail);
    try {
      await api.put(`/audiences/${id}`, audience);
    } catch (e) {
      dispatch(toast('error', 'Error updating audience.'));
    }
  };

export const setAudienceData = audience => ({
  type: 'LOAD_STANDALONE_AUDIENCE',
  detail: audience,
});

export const loadStandAloneAudienceAction = id => async dispatch => {
  try {
    const { data } = await api.get(`/audiences/${id}`);

    dispatch(setAudienceData(data.audience));
  } catch (e) {
    dispatch(toast('error', 'Error loading an audience.'));
  }
};

export const applyAudienceToExistingDraftAction =
  (draftId, audienceData) => async dispatch => {
    try {
      await api.post('/drafts/updateDraft', {
        _id: draftId,
        ...removeExtraFieldsFromStandAloneAudienceForDraft(audienceData),
      });

      dispatch({
        type: 'APPLY_AUDIENCE_TO_DRAFT',
        detail: removeExtraFieldsFromStandAloneAudienceForDraft(audienceData),
      });
    } catch (e) {
      dispatch(
        toast('error', 'Error applying standalone audience to existing draft.')
      );
    }
  };

export const loadOrgTypeName = orgTypeId => async dispatch => {
  try {
    if (!orgTypeId) return;

    const { data } = await api.get('/organization/getOrgTypes');

    const orgTypeName = data.result.filter(
      orgType => orgType.id === orgTypeId
    )[0].name;

    dispatch({
      type: 'LOAD_ORGTYPE_NAME',
      detail: orgTypeName,
    });
  } catch (e) {
    dispatch(toast('error', 'Error loading orgTypes.'));
  }
};

export function updateEmailRecipients(detail) {
  return {
    type: 'UPDATE_EMAIL_RECIPIENTS',
    detail,
  };
}

export function updateTextRecipients(detail) {
  return {
    type: 'UPDATE_TEXT_RECIPIENTS',
    detail,
  };
}

export function loadCommittees(detail) {
  return {
    type: 'LOAD_COMMITTEES',
    detail,
  };
}
