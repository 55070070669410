//function for task details
export function userdetailbyorgId(detail) {
  return {
    type: 'USER_DETAILS_ORGID',
    detail,
  };
}

//Dispatch logged in user details to reducers
export function userdetail(detail) {
  return {
    type: 'LOGGED_USER_DETAILS',
    detail,
  };
}

export function userPrivileges(detail) {
  return {
    type: 'USER_PRIVILEGE_VALIDATION',
    detail,
  };
}
