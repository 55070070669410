import { Select, TextField } from '@material-ui/core';
import styled from 'styled-components';

export const BoxStyled = styled.form`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  background-color: #fff;
  padding: 16px 24px;
  border-radius: 10px;
  color: #000;
  font-size: 16px;

  * {
    font-family: 'Montserrat';
  }

  .centered {
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .error-msg {
    font-size: 12px;
    color: red;
  }

  & h1 {
    font-weight: 600;
  }

  & ul,
  ol {
    margin-block-start: 0;
    padding-left: 0;
    font-weight: 600;
  }

  @media (min-width: 525px) {
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 60px 100px 60px 100px;
    width: 800px;
  }
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  margin-bottom: 16px;
`;

export const ContainerStyled = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const FilterStyled = styled(Select)`
  min-width: 100px;
  margin-right: 16px;
`;

export const TextStyled = styled(TextField)`
  width: 100%;
`;
