import axios, { CancelToken } from 'axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import { getAuth, signOut } from 'firebase/auth';

const cookie = new Cookies();
const CancellationTokens = {};

const api = axios.create({
  baseURL: process.env.REACT_APP_andromedaAPI,
  paramsSerializer: params => queryString.stringify(params),
});

const logOut = async () => {
  cookie.remove('fbAccess');
  window.location.href = '/login';
  const auth = getAuth();
  await signOut(auth);
};

api.interceptors.request.use(
  config => {
    config.headers = {
      currentUser: getAuth().currentUser?.uid || '', //Added a fallback for screens that do not have user sessions (like the text message preview)
      Authorization: cookie.get('fbAccess'),
    };

    return config;
  },
  error => {
    return console.error(error);
  }
);

api.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const config = error?.config;
    if (error.response) {
      if (error.response.status === 401 && !config?.sent) {
        if (config.sent) {
          await logOut();
        }
        config.sent = true;
        try {
          const newToken = await getAuth().currentUser.getIdToken(true);
          cookie.set('fbAccess', newToken);
          config.headers = {
            ...config.headers,
            Authorization: newToken,
          };
        } catch (err) {
          console.error('token renewal', err);
          await logOut();
        }
        return api(config);
      }
    }
    return Promise.reject(error);
  }
);

export const setCT = actionName => {
  return new CancelToken(ct => (CancellationTokens[actionName] = ct));
};

export const callCT = actionName => {
  if (CancellationTokens[actionName]) {
    CancellationTokens[actionName]();
  }
};

export default api;
