import * as Sentry from '@sentry/browser';
import api from '../../utils/andromeda-api';
import { fetchEngagement } from '../../features/automated-campaigns/api/api';

export async function insertMessage(message) {
  try {
    const response = await api.post('/messages/message', message);
    return response.data?.result;
  } catch (e) {
    console.error('Error inserting message [insertMessage]', e);
    return null;
  }
}

export async function insertEmailQueue(recipientsCount, messageData, options) {
  try {
    const response = await api.post('/queue/prepareMessageEmails', {
      recipientsCount,
      messageData,
      options,
    });
    return response.data;
  } catch (e) {
    Sentry.captureException(e);
    return console.error(
      'Error inserting instant message queue [instantSendEmails]',
      e
    );
  }
}

export async function updateMsg(updateData) {
  try {
    const response = await api.post('/messages/cancelMessage', updateData);
    return response.data;
  } catch (e) {
    return console.error(
      'Error retrieving message progress [getMsgProgress]',
      e
    );
  }
}

export async function getMsgById(msgId) {
  if (!msgId) return null;
  try {
    const response = await api.get(`/preview/getMessageById/${msgId}`);
    if (response.data?.result) {
      return { ...response.data.result, id: msgId };
    }
  } catch (e) {
    return console.error('Error retrieving message progress [getMsgById]', e);
  }
}

export async function getMsgBy_Id(msgId, getExtraData = false) {
  if (!msgId) return null;
  try {
    // getExtraData includes membersList which is used for Targeted Audience summary in analytics page
    // and B version of the message if it exists
    const query = getExtraData ? '?extraData=true' : '';
    const response = await api.get(
      `/messages/getMessageBy_Id/${msgId}${query}`
    );
    return { ...response.data.result, membersList: response.data.membersList };
  } catch (e) {
    return console.error('Error retrieving message progress [getMsgById]', e);
  }
}

export async function getAnalyticsMessages(orgId, date) {
  if (!orgId) return null;
  try {
    const response = await api.get(
      `/messages/getAnalyticsMsgs/${orgId}/${date}`
    );
    if (response.data?.result) {
      return response.data.result;
    }
  } catch (e) {
    return console.error(
      'Error retrieving message progress [getAnalyticsMessages]',
      e
    );
  }
}

export async function getAnalyticsCSVData(data) {
  if (!data.orgId || !data.socketId) return null;

  try {
    const response = await api.post('/messages/getAnalyticsCSVData', data);
    if (response.data?.id) {
      return response.data.id;
    }
  } catch (e) {
    return console.error('Error retrieving CSV data [getAnalyticsCSVData]', e);
  }
}

export const getMessagesByOrg = async (orgId, page = 1, size = 50, text) => {
  if (!orgId) return null;
  try {
    let url = `/messages/paginated/${orgId}?page=${page}&size=${size}`;
    if (text) url += `&search=${text}`;
    const res = await api.get(url);
    const response = {
      result: res.data.result,
      pagination: res.data.pagination,
    };
    return response;
  } catch (e) {
    console.error('Error retrieving messages by orgId', e);
    return {
      result: [],
      pagination: { page: 0, size: 0, total: 0 },
    };
  }
};

export async function getEngagementMessages(memberId, orgId) {
  if (!memberId) return null;
  try {
    const response = await api.get(
      `/messages/getEngagementMessages/${memberId}/${orgId}`
    );
    const ACEngagements = await fetchEngagement(memberId);
    if (response.data?.result) {
      ACEngagements.forEach(e => {
        e.isAC = true;
      });
      response.data.result.forEach(e => {
        e.isAC = false;
      });
      return [...response.data.result, ...ACEngagements].sort(
        (prev, next) => next.sendTime - prev.sendTime
      );
    }
  } catch (e) {
    return console.error(
      'Error retrieving message progress [getAnalyticsMessages]',
      e
    );
  }
}

export async function getCancelledMessages(orgId) {
  if (!orgId) return null;
  try {
    const response = await api.get(`/messages/getCancelledMessages/${orgId}`);
    if (response.data?.result) {
      return response.data.result;
    }
  } catch (e) {
    return console.error(
      'Error retrieving message progress [getCancelledMessages]',
      e
    );
  }
}

export async function cancelEmails(msgId) {
  if (!msgId) return null;
  try {
    const response = await api.post('/queue/cancelEmailMessages', {
      id: msgId,
    });
    if (response.data?.result) {
      return response.data.result;
    }
  } catch (e) {
    return console.error('Error cancelling emails [cancelEmails]', e);
  }
}

export async function cancelTexts(msgId) {
  if (!msgId) return null;
  try {
    const response = await api.post('/queue/cancelTextMessages', { id: msgId });
    if (response.data?.result) {
      return response.data.result;
    }
  } catch (e) {
    return console.error('Error cancelling texts [cancelTexts]', e);
  }
}

export async function getQueueLength(orgId) {
  if (!orgId) return null;
  try {
    const response = await api.get(`/queue/getQueueLength/${orgId}`);
    if (response.data?.result) {
      return response.data.result;
    }
  } catch (e) {
    return console.error('Error retrieving queue length [getQueueLength]', e);
  }
}

export async function findMessagePreviewInfo(msgId, memId, orgId) {
  try {
    const response = await api.get(
      `/preview/getMessagePreviewData/${msgId}/${memId}/${orgId}`
    );
    if (response?.data?.message === 'Grabbed the message information') {
      return response.data.result;
    }
  } catch (e) {
    console.error(
      'Error getting message preview info [findMessagePreviewInfo]',
      e
    );
  }
}

export async function addEventId(messageId, eventId) {
  try {
    const response = await api.put(`/messages/message/${messageId}`, {
      eventId,
    });
    return response.data;
  } catch (e) {
    return console.error('Error adding EventId to message [addEventId]', e);
  }
}

export async function getAVersionMessageId(msgId) {
  if (!msgId) throw new Error('Missing message id');
  const {
    data: { result },
  } = await api.get(`/messages/getAVersionId/${msgId}`);
  return result;
}
