import { Modal } from 'antd';
import React from 'react';

import PreviewDesign from '../../PreviewDesign/PreviewDesign';

const PreviewDesignModal = ({ isVisible, onHide, html, name }) => {
  return (
    <Modal
      visible={isVisible}
      className="preview-design-modal"
      centered
      onCancel={onHide}
      footer={[]}
    >
      <PreviewDesign close={onHide} html={html} name={name} />
    </Modal>
  );
};

export default PreviewDesignModal;
