import api from '../../utils/andromeda-api';

export async function createNewCustomGroup({
  organizationId,
  name,
  description,
}) {
  try {
    const response = await api.post('/groups/v2/create', {
      organizationId,
      name,
      description,
    });
    return { data: response.data.data, error: false };
  } catch (error) {
    return { data: null, error: true };
  }
}
export async function createGroup(data) {
  try {
    const response = await api.post('/groups/addGroup', data);
    return { data: response.data.result, error: false };
  } catch (e) {
    console.error('Error creating groups [createGroup]', e);
    return { error: true };
  }
}
export async function fetchOrgGroups(orgId) {
  if (!orgId) return [];
  try {
    const response = await api.get(`/groups/organization/${orgId}`);
    console.log('Received organization groups');
    return response.data.result;
  } catch (e) {
    console.error('Error retrieving organization groups [fetchOrgGroups]', e);
    return { error: true };
  }
}
export async function deleteGroupMaps(data) {
  try {
    const response = await api.post('/tagmapping/v2/deleteTagMaps', data);
    console.log('Deleted group maps');
    return response.data;
  } catch (e) {
    console.error('Error deleting tag maps [deleteGroupMaps]', e);
    return { error: true };
  }
}
export async function getOrgGroupMaps(orgId) {
  try {
    const response = await api.get(`/tagmapping/orgTagMaps/${orgId}`);
    console.log('Received org group maps');
    return response.data.result;
  } catch (e) {
    console.error('Error retrieving group maps [getOrgGroupMaps]', e);
    return { error: true };
  }
}
export async function refreshAllContactGroups(data) {
  try {
    const response = await api.post(
      '/tagmapping/v2/refreshAllMemberTags',
      data
    );
    console.log('Refreshed orgs contacts groups');
    return response.data.count;
  } catch (e) {
    console.error(
      'Error refreshing contact groups [refreshAllContactGroups]',
      e
    );
    return { error: true };
  }
}
export async function addGroupsToContacts(data) {
  try {
    const response = await api.post('/tagmapping/v2/updateTagMap', data);
    console.log('Added groups to contacts');
    return response.data.count;
  } catch (e) {
    console.error('Error adding groups to contacts [addGroupsToContacts]', e);
    return { error: true };
  }
}
export async function getOrganizationTopics(orgId) {
  if (!orgId) return [];
  try {
    const response = await api.get(`/groups/getOrganizationTopics/${orgId}`);
    console.log('Received Org Topics');
    return response.data.result;
  } catch (e) {
    console.error('Error retrieving topics [getOrganizationTopics]', e);
    return { error: true };
  }
}

export async function fetchOrgTopicsAndGroups(orgId) {
  try {
    const response = await api.get(`/groups/organizationlist/${orgId}`);
    console.log('Received organization groups and topics');
    return response.data.result;
  } catch (e) {
    console.error(
      'Error retrieving group and topic information [fetchOrgTopicsAndGroups]'
    );
    return { error: true };
  }
}
export async function getTagTypes() {
  try {
    const response = await api.get('/groups/types');
    console.log('Received group types');
    return response.data.result;
  } catch (e) {
    console.error('Error retrieving group type information [getTagTypes]', e);
    return { error: true };
  }
}

export async function getGroupTypes(orgId) {
  try {
    const response = await api.get(`/organization/groupTypes/${orgId}`);
    console.log('Received group types');
    return response.data.result;
  } catch (e) {
    console.error('Error retrieving group types [getGroupTypes]', e);
    return { error: true };
  }
}

export async function updateMongoGroup(data) {
  try {
    const response = await api.post('/groups/updateGroup', data);
    console.log('Updated group');
    return response.data.result;
  } catch (e) {
    console.error('Error updating group information [updateMongoGroup]', e);
    return { error: true };
  }
}
export async function updateGroup(data) {
  try {
    const response = await api.post('/groups/v2/update', data);
    return response.data.message;
  } catch (err) {
    console.error('Error updating group [updateGroup]', data);
    if (err.response?.data?.error) console.error(err.response.data.error);
    else console.error(err);
    return { error: true };
  }
}
export async function updateContactsWithMandatoryTopic(data) {
  try {
    const response = await api.post(
      '/groups/updateContactsWithMandatoryTopic',
      data
    );
    console.log('Updated contacts with mandatory topic');
    return response.data.result;
  } catch (e) {
    console.error(
      'Error updating contacts with mandatory topic [updateContactsWithMandatoryTopic]',
      e
    );
    return { error: true };
  }
}
export async function deleteSelectedGroups(data) {
  try {
    const response = await api.post('/groups/deleteGroups', data);
    console.log('Deleted group(s)');
    return response.data;
  } catch (e) {
    console.error(
      'Error retrieving group information [deleteSelectedGroups]',
      e
    );
    return { error: true };
  }
}
export async function resetOrganizationGroup(data) {
  try {
    const response = await api.post('/groups/resetOrganizationGroup', data);
    console.log('Reset group');
    return response.data;
  } catch (e) {
    console.error(
      'Error resetting organization group [resetOrganizationGroup]',
      e
    );
    return { error: true };
  }
}
export async function applyGroupToContacts(data) {
  try {
    const response = await api.post('/groups/v2/applyGroupToContactList', data);
    console.log('Applied groups to given contacts');
    return response.data;
  } catch (e) {
    console.error('Error applying groups [applyGroupToContacts]', e);
    return { error: true };
  }
}
export async function massApplyGroupsToContacts(data) {
  try {
    const response = await api.post('/groups/massApplyGroups', data);
    console.log('Applied groups to given contacts');
    return response.data;
  } catch (e) {
    console.error('Error mass applying groups [massApplyGroupsToContacts]', e);
    return { error: true };
  }
}
