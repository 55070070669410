import React from 'react';
import styled from 'styled-components';
import { ArrowForwardSharp } from '@material-ui/icons';
import { Button } from '@material-ui/core';

import BackButton from '../BackButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.header`
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 20px;
`;

Header.Left = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

Header.Center = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

Header.Right = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
`;

Header.TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

Header.BackButton = styled(BackButton)``;

Header.Title = styled.h1`
  margin-bottom: 0;

  color: #555555;
  font-size: 22px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1.2;
`;

Header.Title.Step = styled.span`
  font-weight: 600;
  font-family: inherit;
`;

Header.Description = styled.p`
  margin-bottom: 0;

  color: #616161;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.2;
`;

const NextIcon = styled(ArrowForwardSharp)`
  font-size: 16px !important;
`;

Header.NextButton = styled(Button).attrs({
  size: 'large',
  endIcon: <NextIcon />,
})`
  align-self: start;

  && {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    line-height: 1.4;
    color: #5eab00;
    border: 1px solid #5eab00;
    border-radius: 5px;
    background-color: #5eab0005;
    transition: background-color 0.3s;

    &:hover {
      background-color: #5eab000f;
    }

    &:disabled {
      pointer-events: all;
      cursor: not-allowed;
    }

    // Override global default styles which set Poppins as font-family for everything (which is pretty bad!)
    > .MuiButton-label {
      font-family: inherit;
    }
  }
`;

export const Body = styled.div`
  flex-grow: 1;
  padding: ${({ withoutVerticalPadding }) =>
      withoutVerticalPadding ? 0 : '35px'}
    ${({ withoutHorizontalPadding }) => (withoutHorizontalPadding ? 0 : '10px')};
`;
