import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateNavProgressMeter,
  updateProgressMeterResults,
} from '../../redux/actions/UI';
import {
  userDetailSelector,
  selectedOrganizationSelector,
} from '../../redux/selector';
import { connectClient } from '../../utils/socketio';

const ExportClient = ({ updateExportSocketId, disconnectSocket }) => {
  const dispatch = useDispatch();
  const user = useSelector(userDetailSelector);
  const selectedOrg = useSelector(selectedOrganizationSelector);

  useEffect(() => {
    const socket = connectClient(user.id, 'Analytics_Export', selectedOrg.id);
    socket.on('connect', () => {
      updateExportSocketId(socket.id);
    });
    socket.on('processUpdate', update => {
      if (update.progress && update.progress > 0)
        dispatch(updateNavProgressMeter(update.progress));
    });

    socket.on('processComplete', update => {
      dispatch(updateProgressMeterResults(update));
      disconnectSocket();
      socket.disconnect();
    });

    socket.on('connect_error', err => {
      console.log(`connect_error due to ${err.message}`);
    });
  }, []);
  return null;
};

export default ExportClient;
