import styled from 'styled-components';

const ItalicText = styled.span`
  font-size: 14px;
  font-style: italic;
  padding-right: 15px;
  color: #6c7075;
  line-height: 14px;
  text-align: right;
`;

export default ItalicText;
