import { Button, Drawer, IconButton, Tooltip } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as actioncreators from '../../redux/action';
import { escapeRegExp } from '../../redux/helpers/helpers';
import { refreshAllSubscriberCounts } from '../../redux/services/subscriberCount';
import ApplyTagToMemberList from '../GroupingTool/GroupingTool';
import CreateTag from '../CreateTag/CreateTag';
import GroupDetails from './GroupDetails/GroupDetails';
import RecipientTable from '../RecipientTable/RecipientTable';
import SearchBar from './SearchBar';
import { TagColummns } from './TagColumns';
import { TopicColumns } from './TopicColumns';
import AMSSync from './AMSSync';

const TagsTable = props => {
  const [ApplyTagToMemberListVisibility, setApplyTagToMemberListVisibility] =
    useState(false);
  const [AddTagVisibility, setAddTagVisibility] = useState(false);
  const [SelectedTag, setSelectedTag] = useState(null);
  const [EditTagVisibility, setEditTagVisibility] = useState(false);
  const [TagDetail, setTagDetail] = useState(props.tagDetail);
  const [filteredGroups, setFilteredGroups] = useState(props.tagDetail);
  const [PageSize, setPageSize] = useState(props.pageSize);
  const [TableType, setTableType] = useState(props.tableType);
  const [recipientTable, setRecipientTable] = useState({
    visible: false,
    tag: {},
  });
  const [SelectedIds, setSelectedIds] = React.useState([]);
  const [quickSearchText, setQuickSearchText] = useState('');

  useEffect(() => {
    setTagDetail(props.tagDetail);
    setFilteredGroups(props.tagDetail);
  }, [TagDetail, props.tagDetail]);
  useEffect(() => {
    setTableType(props.tableType);
  }, [TableType, props.tableType]);

  const showAddTagDrawer = (e, data) => {
    if (e) {
      setAddTagVisibility(true);
      setSelectedTag(data);
    }
  };

  const showEditTagDrawer = (e, data) => {
    if (e) {
      setEditTagVisibility(true);
      setSelectedTag(data);
    }
  };

  const toggleRecipientList = (e, tagData) => {
    e.stopPropagation();
    setRecipientTable({ visible: !recipientTable.visible, tag: tagData });
  };

  const refreshSubCounts = async () => {
    props.actions.fullloader(true, 'Refreshing Susbcriber Counts');
    await refreshAllSubscriberCounts(props.organization.selected.id);
    props.organizationtaglist(props.organization.selected.id);
    props.actions.fullloader(false);
  };

  const requestSearch = searchValue => {
    setQuickSearchText(searchValue);
    if (!searchValue) setFilteredGroups(TagDetail);
    else {
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = TagDetail.filter(row => {
        const searchableData = {
          name: row.name || '',
          description: row.description || '',
          count: row.subscribedMembers || '',
        };
        return Object.keys(searchableData).some(field => {
          return searchRegex.test(searchableData[field].toString());
        });
      });
      setFilteredGroups(filteredRows);
    }
  };

  const defaultTheme = createTheme();
  const useStyles = makeStyles(
    () => {
      return {
        root: {
          '& .super-app-theme--general': {
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
      };
    },
    { defaultTheme }
  );
  const classes = useStyles();

  return (
    <>
      <Row style={{ padding: '0 30px' }}>
        <Col span={12}>
          <div className={'AMSSyncRow'}>
            {props.user.userPrivileges.isSU &&
              props.organization.selected.importMagic && (
                <AMSSync
                  orgDetails={props.organization.selected}
                  userId={props.user.detail.id}
                  refreshManageScreen={orgId => props.loadOrgInfo(orgId, true)}
                />
              )}
          </div>
        </Col>
        <Col span={12} style={{ textAlign: 'right', marginTop: '-38px' }}>
          {props.user.userPrivileges.isSA && (
            <Button
              style={{
                color: '#6C7075',
                background: '#fff',
                border: '1px solid #D4D7D9',
                marginRight: '15px',
              }}
              size={'small'}
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={() => {
                refreshSubCounts();
              }}
            >
              Refresh
            </Button>
          )}
          {props.user.userPrivileges.manageTags && (
            <Tooltip
              title={
                <span>
                  Got an external list of members you want to assign a Tag to?
                  This tool is perfect for when you have:
                  <ul>
                    <li>
                      {
                        "An export from your membership database (use Member ID's or NRDS ID's)"
                      }
                    </li>
                    <li>{"A report from Aristotle (use NRDS ID's)"}</li>
                    <li>A list of nonmembers (use email addresses)</li>
                  </ul>
                </span>
              }
            >
              <Button
                style={{
                  color: '#6C7075',
                  background: '#fff',
                  border: '1px solid #D4D7D9',
                  marginRight: '15px',
                }}
                size={'small'}
                variant="outlined"
                startIcon={<GroupAddIcon />}
                onClick={() => {
                  setApplyTagToMemberListVisibility(true);
                }}
              >
                Grouping Tool
              </Button>
            </Tooltip>
          )}
          {props.user.userPrivileges.manageTags && (
            <Button
              size={'small'}
              variant="contained"
              startIcon={<DeleteIcon />}
              onClick={() => props.showModalTag()}
              disabled={SelectedIds.length <= 0}
              style={{
                background: SelectedIds?.length > 0 ? '#FF6161' : '#CACACA',
                marginRight: '16px',
                color: SelectedIds?.length > 0 ? '#fff' : '',
              }}
            >
              Delete
            </Button>
          )}

          {props.user.userPrivileges.manageTags && (
            <Button
              size={'small'}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={e => showAddTagDrawer(e)}
              style={{ background: '#5EAB00', color: '#fff' }}
            >
              Add
            </Button>
          )}
        </Col>
      </Row>
      <div className="DataGridContainer ManageTableContainer">
        <div className={'ManageTagsDataGrid'}>
          <DataGrid
            className={classes.root}
            stickyHeader
            rows={filteredGroups}
            columns={
              TableType === 'topics'
                ? TopicColumns(toggleRecipientList)
                : TagColummns(toggleRecipientList)
            }
            pageSize={PageSize}
            checkboxSelection
            disableSelectionOnClick
            getRowClassName={() => {
              return 'super-app-theme--general';
            }}
            onRowClick={(data, e) => {
              showEditTagDrawer(e, data.row);
            }}
            onPageSizeChange={size => setPageSize(size)}
            onSelectionModelChange={newSelectionModel => {
              setSelectedIds(newSelectionModel);
              props.setTagIDs(newSelectionModel, TableType);
            }}
            selectionModel={SelectedIds}
            components={{ Toolbar: SearchBar }}
            componentsProps={{
              toolbar: {
                value: quickSearchText,
                onChange: event => requestSearch(event.target.value),
                clearSearch: () => requestSearch(''),
              },
            }}
          />
        </div>
        <Drawer
          anchor={'right'}
          className={
            TableType === 'topics' ? 'RecipDrawerLarge' : 'RecipDrawer'
          }
          onClose={() => setRecipientTable({ visible: false, tag: {} })}
          open={recipientTable.visible}
        >
          <div style={{ width: '100%', height: '100%', margin: '0' }}>
            <div className={'RecipDrawerHeader'}>
              <IconButton
                onClick={() => {
                  setRecipientTable({ visible: false, tag: {} });
                }}
                style={{ color: 'white' }}
              >
                <CloseIcon />
              </IconButton>
              {TableType === 'topics'
                ? `Contacts Receiving Topic: ${recipientTable.tag.name}`
                : `Contacts With Group: ${recipientTable.tag.name}`}
            </div>
            <div
              style={{
                height: 'calc(100% - 60px)',
                padding: '12px 24px 24px 24px',
              }}
            >
              <RecipientTable
                tag={recipientTable.tag}
                actions={props.actions}
                organization={props.organization.selected}
                memberList={props.memberList}
              />
            </div>
          </div>
        </Drawer>
        <Drawer
          anchor={'right'}
          open={AddTagVisibility}
          className={'GroupDrawers'}
          onClose={() => setAddTagVisibility(false)}
        >
          {TableType === 'groups' ? (
            <GroupDetails
              drawerTitle={'Create a new Group'}
              updateTable={id => {
                props.loadOrgInfo(id);
              }}
              closeDrawer={() => {
                setAddTagVisibility(false);
              }}
            />
          ) : (
            <CreateTag
              drawerTitle={`Create a new ${
                TableType === 'topics' ? 'Topic' : 'Group'
              }`}
              organizationId={props.organization.selected.id}
              update={id => {
                props.organizationtaglist(id);
              }}
              closeDrawer={() => setAddTagVisibility(false)}
              creationType={TableType}
            />
          )}
        </Drawer>
        {SelectedTag && (
          <Drawer
            anchor={'right'}
            className={'GroupDrawers'}
            onClose={() => {
              setEditTagVisibility(false);
              setSelectedTag(null);
            }}
            open={EditTagVisibility}
          >
            {TableType === 'groups' ? (
              <GroupDetails
                existingGroup={SelectedTag}
                updateTable={id => {
                  props.loadOrgInfo(id);
                }}
                closeDrawer={() => {
                  setEditTagVisibility(false);
                  setSelectedTag(null);
                }}
              />
            ) : (
              <CreateTag
                drawerTitle={`${
                  TableType === 'topics' ? 'Topic' : 'Group'
                } Details: ${
                  SelectedTag.name.length > 22
                    ? SelectedTag.name.substring(0, 22) + '...'
                    : SelectedTag.name
                }`}
                data={SelectedTag}
                update={id => {
                  props.loadOrgInfo(id);
                }}
                closeDrawer={() => {
                  setEditTagVisibility(false);
                  setSelectedTag(null);
                }}
                creationType={TableType}
              />
            )}
          </Drawer>
        )}
        <Drawer
          anchor={'right'}
          className={'GroupDrawers'}
          onClose={() => setApplyTagToMemberListVisibility(false)}
          open={ApplyTagToMemberListVisibility}
        >
          <ApplyTagToMemberList
            closeDrawer={() => setApplyTagToMemberListVisibility(false)}
            drawerTitle="Apply Group to a List of Contacts"
            organization={props.organization.selected.id}
            close={() => setApplyTagToMemberListVisibility(false)}
            type={TableType}
          />
        </Drawer>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return state;
};
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actioncreators, dispatch),
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TagsTable)
);
