import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
  IconButton,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close, Info } from '@material-ui/icons';
import { smartResend } from './api';
import { selectedOrganizationSelector } from '../../../../redux/selectors/organization';
import { toast } from '../../../../redux/actions/UI';
import {
  Container,
  Header,
  Body,
  Grey,
  Bold,
  Section,
  Small,
  Wrapper,
  Button,
} from './SmartResendDrawer.styled';

const useStyles = makeStyles(theme => ({
  textField: {
    width: 270,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const SmartResendDrawer = ({
  setDrawerdVisibility,
  isDrawerVisible,
  message,
  setSelectedMessage,
  addStatusesToMessage,
}) => {
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const [resendDate, setResendDate] = useState(null);
  const [messageSubject, setMessageSubject] = useState(message.messageSubject);
  const [isResendingInProgress, setResendingProgress] = useState(false);
  const classes = useStyles();
  const closeDrawer = () => {
    setDrawerdVisibility(false);
  };

  useEffect(() => {
    if (message) {
      const threeDaysFromSendTime = moment(
        (message.sendTime + 3 * 24 * 60 * 60) * 1000
      ).format('YYYY-MM-DDTHH:mm');
      if (moment() > moment(threeDaysFromSendTime)) {
        setResendDate(addFiveMinutesToNow());
      } else {
        setResendDate(threeDaysFromSendTime);
      }
    }
  }, [message]);

  const addFiveMinutesToNow = () => {
    return moment().add(5, 'minutes').format('YYYY-MM-DDTHH:mm');
  };

  let minDate = moment((message.sendTime + 24 * 60 * 60) * 1000).format(
    'YYYY-MM-DDTHH:mm'
  );

  // if minDate is in the past then make it equal to 5 minutes later than now
  if (moment() > moment(minDate)) {
    minDate = addFiveMinutesToNow();
  }

  const maxDate = moment((message.sendTime + 10 * 24 * 60 * 60) * 1000).format(
    'YYYY-MM-DDTHH:mm'
  );

  const getFormattedTime = timestamp =>
    moment(timestamp * 1000).format('MM/DD/YY [at] hh:mm a');

  const formatdDate = date => moment(date).format('MMMM D, YYYY [at] h:mma');

  const handleDateChange = event => {
    setResendDate(event.target.value);
  };

  const handleMessageSubjectChange = event => {
    setMessageSubject(event.target.value);
  };

  const resendToNonOpeners = async () => {
    if (!messageSubject) {
      dispatch(
        toast('error', 'A subject line is required to resend a message.')
      );
      return;
    }
    try {
      setResendingProgress(true);
      const { updatedMsg } = await smartResend({
        messageId: message._id,
        scheduleTime: moment(resendDate).valueOf() / 1000,
        messageSubject,
        selectedOrganization,
      });

      setSelectedMessage(addStatusesToMessage(updatedMsg));
      setDrawerdVisibility(false);
      dispatch(toast('success', 'Successfully scheduled a smart resend.'));
    } catch (e) {
      dispatch(toast('error', 'Error scheduling a smart resend.'));
    }
    setResendingProgress(false);
  };

  const isResendBtnDisabled = !(
    moment(resendDate)?.isSameOrAfter(minDate) &&
    moment(resendDate)?.isSameOrBefore(maxDate) &&
    messageSubject
  );

  return (
    <Drawer open={isDrawerVisible} onClose={closeDrawer} anchor="right">
      <Container>
        <Header>
          <IconButton size={'small'} style={{ color: 'white' }}>
            <Close size={'small'} onClick={closeDrawer} />
          </IconButton>
          Resend Message to Non-Openers
        </Header>
        <Body>
          <div>
            <Grey>Resending Message:</Grey> <Bold>{message.draftName}</Bold>
          </div>
          <div>
            <Grey>Originally Sent:</Grey>{' '}
            <Bold>{getFormattedTime(message.sendTime)}</Bold>
          </div>
          <Section>
            <Bold>Schedule Resend to Non-Openers</Bold>
            <Small>
              Messages can be resent to non-openers 1 - 10 days after original
              send date.
            </Small>
            <TextField
              id="datetime-local"
              type="datetime-local"
              variant="outlined"
              size="small"
              value={resendDate}
              onChange={handleDateChange}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: minDate,
                max: maxDate,
              }}
            />
            <Wrapper>
              <Info style={{ color: '#F28501' }} />
              <Small>
                Tip: Try to schedule the resend <Bold>2 - 3 days</Bold> after
                the original message was sent. Scheduling a resend too soon, or
                too late, may result in a higher spam report rate.
              </Small>
            </Wrapper>
          </Section>
          <Section>
            <Bold>Subject line for resent message:</Bold>
            <TextField
              size="small"
              variant="outlined"
              defaultValue={message.messageSubject}
              style={{ width: '80%', paddingBottom: '10px' }}
              id="subject-line"
              onChange={handleMessageSubjectChange}
              error={!messageSubject}
              helperText={!messageSubject && 'Subject line is required.'}
            />
            <Wrapper>
              <Info style={{ color: '#F28501' }} />
              <Small>
                Tip: Try to keep your new subject line <Bold>the same</Bold> as
                the original message&apos;s subject line. The more you change
                the subject line for a resend, the more it will be reported as
                spam.
              </Small>
            </Wrapper>
          </Section>
          {message.preheader && (
            <Section>
              <Wrapper>
                <Grey>Preheader:</Grey> <Bold>{message.preheader}</Bold>
              </Wrapper>
            </Section>
          )}
          <Section>
            <Button onClick={resendToNonOpeners} disabled={isResendBtnDisabled}>
              {isResendingInProgress ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                'Confirm Resend'
              )}
            </Button>
            <Small isError={isResendBtnDisabled}>
              {isResendBtnDisabled
                ? !messageSubject
                  ? 'A subject line is required to resend a message.'
                  : 'Messages can only be resent to non-openers between 1 - 10 days after the original message was sent.'
                : `Message will resend to non-openers on ${formatdDate(
                    resendDate
                  )}`}
            </Small>
          </Section>
        </Body>
      </Container>
    </Drawer>
  );
};

export default SmartResendDrawer;
