import styled from 'styled-components';
import BaseSelect from '../forms/Select';

export const Select = styled(BaseSelect)`
  width: 170px !important;
  font-size: 16px !important;

  > .MuiSelect-root {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 15px;
  }
`;
