import React, { useEffect } from 'react';
import { Switch, Checkbox, FormControlLabel } from '@material-ui/core';
import {
  Container,
  Row,
  Title,
  FileName,
  DefaultChoice,
  Options,
  Column,
  Small,
} from './StepTwo.styled';
import SelectDropDown from './SelectDropDown';

const StepTwo = ({
  CSVFileName,
  allowDuplicateContacts,
  setAllowDuplicateContacts,
  shouldImportMembersOnly,
  setIfShouldImportMembersOnly,
  shouldChooseMembershipLater,
  setShouldChooseMembershipLater,
  shouldChooseTopicsLater,
  setShouldChooseTopicsLater,
  shouldNotChooseTopics,
  setShouldNotChooseTopics,
  shouldChooseGroupsLater,
  setShouldChooseGroupsLater,
  setSelectedTopics,
  setSelectedGroups,
  selectedTopics,
  selectedGroups,
}) => {
  const handleChangeDuplicateContacts = () => {
    setAllowDuplicateContacts(!allowDuplicateContacts);
  };

  const handleChangeShouldImportMembersOnly = () => {
    setIfShouldImportMembersOnly(!shouldImportMembersOnly);
  };

  const handleChangeShouldChooseMembershipLater = () => {
    setShouldChooseTopicsLater(!shouldChooseMembershipLater);
    setShouldChooseMembershipLater(!shouldChooseMembershipLater);
  };

  const handleChangeShouldChooseTopicsLater = () => {
    if (!shouldChooseMembershipLater) {
      setShouldChooseTopicsLater(!shouldChooseTopicsLater);
    }
  };

  const handleChangeShouldNotChooseTopics = () => {
    setShouldNotChooseTopics(!shouldNotChooseTopics);
  };

  const handleChangeShouldChooseGroupsLater = () => {
    setShouldChooseGroupsLater(!shouldChooseGroupsLater);
  };

  useEffect(() => {
    if (shouldChooseMembershipLater) {
      setSelectedTopics([]);
    }
  }, [shouldChooseMembershipLater]);

  return (
    <Container>
      <Row>
        <Title width="350px" size="17px">
          Organizing Your New Contacts
        </Title>
        <span>
          File: <FileName>[{CSVFileName}]</FileName>
        </span>
      </Row>
      <Row>
        Let&apos;s make sure your new contacts are automatically grouped and
        subscribed the way you&apos;d like
      </Row>
      <Column>
        <Title paddingBottom>
          Do you want to skip uploading a contact if a contact with their email
          address already exists in NoteRouter? This can help prevent
          duplicates.
        </Title>
        <Options>
          <DefaultChoice enabled={!allowDuplicateContacts}>
            Yes, skip them
          </DefaultChoice>
          <Switch
            checked={allowDuplicateContacts}
            onChange={handleChangeDuplicateContacts}
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <DefaultChoice enabled={allowDuplicateContacts}>
            No, upload possible duplicates
          </DefaultChoice>
        </Options>
      </Column>
      <Column>
        <Title>What kind of contacts are you uploading in this file?</Title>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              size="small"
              checked={shouldChooseMembershipLater}
              onChange={handleChangeShouldChooseMembershipLater}
            />
          }
          label={
            <Small>
              I used a column in my file to configure this. (Advanced)
            </Small>
          }
        />
        {!shouldChooseMembershipLater && (
          <Options>
            <DefaultChoice enabled={!shouldImportMembersOnly}>
              Nonmembers
            </DefaultChoice>
            <Switch
              checked={shouldImportMembersOnly}
              onChange={handleChangeShouldImportMembersOnly}
              color="primary"
              name="membership"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <DefaultChoice enabled={shouldImportMembersOnly}>
              Members
            </DefaultChoice>
          </Options>
        )}
      </Column>
      <Column>
        <Title>What Topics should these contacts be added to?</Title>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              size="small"
              checked={shouldChooseMembershipLater || shouldChooseTopicsLater}
              disabled={shouldNotChooseTopics}
              onChange={handleChangeShouldChooseTopicsLater}
            />
          }
          label={
            <Small disabled={shouldNotChooseTopics}>
              I used a column in my file to configure this. (Advanced)
            </Small>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              size="small"
              checked={shouldNotChooseTopics}
              disabled={shouldChooseTopicsLater}
              onChange={handleChangeShouldNotChooseTopics}
            />
          }
          label={
            <Small disabled={shouldChooseTopicsLater}>
              I do not want to add these contacts to a Topic right now. (Not
              recommended!)
            </Small>
          }
        />
        {!shouldNotChooseTopics &&
          !shouldChooseMembershipLater &&
          !shouldChooseTopicsLater && (
            <>
              <Small>
                Select the Topic(s) you&apos;d like to add selected Contacts to:
              </Small>
              <SelectDropDown
                shouldImportMembersOnly={shouldImportMembersOnly}
                name="Topic"
                placeholder="- Select Topic -"
                setSelectedTagIds={setSelectedTopics}
                selectedTagIds={selectedTopics}
              />
            </>
          )}
      </Column>
      <Column>
        <Title>What Groups should these contacts be subscribed to?</Title>
        <Small onClick={handleChangeShouldChooseGroupsLater}>
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            size="small"
            checked={shouldChooseGroupsLater}
          />
          I used a column in my file to configure this. (Advanced)
        </Small>
        {!shouldChooseGroupsLater && (
          <>
            <Small>
              Select the Group(s) you&apos;d like to add selected Contacts to:
            </Small>
            <SelectDropDown
              name="Group"
              placeholder="- Select Group -"
              setSelectedTagIds={setSelectedGroups}
              selectedTagIds={selectedGroups}
            />
          </>
        )}
      </Column>
    </Container>
  );
};

export default StepTwo;
