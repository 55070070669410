import React, { useState } from 'react';
import { connect } from 'react-redux';
import GenerateRow from '../GenerateFilterRow';
import DateFilter from '../DateFilter';
import {
  BIRTHDATE,
  JOIN_DATE,
  ORIENTATION_DATE,
  LICENSE_EXPIRATION_DATE,
  LAST_PREFERENCE_PAGE_VISIT,
  ADDED_DATE,
  // once credit card expiration date is ready to be used, uncomment related lines
  // CREDIT_CARD_EXPIRATION_DATE,
} from '../_shared/constants';

const ShowDateFilters = ({ audienceFilters, filterTypes, selectedFilters }) => {
  const dateFilterType = filterTypes.filter(
    filter => filter.name === 'Dates'
  )[0];
  const dateFilters = audienceFilters.filter(
    filter => filter.filterType === dateFilterType.id
  );

  const dateFilterExists = name => {
    return dateFilters.some(filter => filter.name === name);
  };

  const [checkedBirthdate, setBirthdate] = useState(
    !!selectedFilters[BIRTHDATE]
  );
  const [checkedJoinDate, setJoinDate] = useState(!!selectedFilters[JOIN_DATE]);
  const [checkedOrientationDate, setOrientationDate] = useState(
    !!selectedFilters[ORIENTATION_DATE]
  );
  const [checkedLicenseExpirationDate, setLicenseExpirationDate] = useState(
    !!selectedFilters[LICENSE_EXPIRATION_DATE]
  );
  const [checkedLastPreferencePageVisit, setLastPreferencePageVisit] = useState(
    !!selectedFilters[LAST_PREFERENCE_PAGE_VISIT]
  );
  const [checkedAddedDate, setAddedDate] = useState(
    !!selectedFilters[ADDED_DATE]
  );
  // const [checkedCreditCardExpirationDate, setCreditCardExpirationDate] =
  //   useState(!!selectedFilters[CREDIT_CARD_EXPIRATION_DATE]);

  const dateFiltersList = [
    {
      filterName: BIRTHDATE,
      checked: checkedBirthdate,
      setChecked: setBirthdate,
    },
    {
      filterName: JOIN_DATE,
      checked: checkedJoinDate,
      setChecked: setJoinDate,
      displayMembership: true,
    },
    {
      filterName: ORIENTATION_DATE,
      checked: checkedOrientationDate,
      setChecked: setOrientationDate,
      displayMembership: true,
    },
    {
      filterName: LICENSE_EXPIRATION_DATE,
      checked: checkedLicenseExpirationDate,
      setChecked: setLicenseExpirationDate,
    },
    {
      filterName: LAST_PREFERENCE_PAGE_VISIT,
      checked: checkedLastPreferencePageVisit,
      setChecked: setLastPreferencePageVisit,
    },
    {
      filterName: ADDED_DATE,
      checked: checkedAddedDate,
      setChecked: setAddedDate,
    },
    // {
    //   filterName: CREDIT_CARD_EXPIRATION_DATE,
    //   checked: checkedCreditCardExpirationDate,
    //   setChecked: setCreditCardExpirationDate,
    // },
  ];
  return (
    <>
      {dateFiltersList.map(
        ({ filterName, checked, setChecked, displayMembership }) =>
          dateFilterExists(filterName) && (
            <GenerateRow
              key={filterName}
              check={checked}
              setCheck={setChecked}
              label={filterName}
            >
              <DateFilter
                filterName={filterName}
                checked={checked}
                selectedFilters={selectedFilters[filterName]}
                displayMembership={displayMembership}
              />
            </GenerateRow>
          )
      )}
    </>
  );
};

const mapStateToProps = state => ({
  audienceFilters: state.audience.audienceFilters,
  filterTypes: state.audience.filterTypes,
  selectedFilters: state.audience.selectedFilters,
});

export default connect(mapStateToProps)(ShowDateFilters);
