import styled from 'styled-components';

const PrimaryBtn = styled.button`
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  border: 1px solid ${props => props.color || '#09AB00'};
  padding: 0 25px;
  background-color: ${props => props.backColor || '#09AB00'};
  color: ${props => props.color || 'white'};
  transition: all 0.3s ease-in;
  border-radius: 20px;
  height: 28px;
  &:hover,
  &.active {
    color: white;
    cursor: pointer;
    background-color: ${props => props.colorOnHover || '#238B22'};
  }
  &:disabled {
    cursor: not-allowed;
    background-color: transparent;
  }
`;

export default PrimaryBtn;
