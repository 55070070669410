import styled from 'styled-components';
import { LineChartOutlined, GiftOutlined } from '@ant-design/icons';
import { Info } from '@material-ui/icons';

export const Container = styled.div`
  z-index: 20;
  position: relative;
  width: ${props => props.width || '1200px'};
  min-width: 1000px;
  min-height: 500px;
  background-color: white;
  opacity: 1;
  padding: 50px 50px 50px 70px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
`;

export const YoutubeContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const YoutubeBox = styled.div`
  width: 1000px;
  height: 630px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: black;
`;

export const YoutubeHeader = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  background-color: white;
  padding: 2px 5px;
`;

export const BackButton = styled.span`
  position: absolute;
  top: 30px;
  left: 30px;
  border-radius: 50%;
  background-color: #d4d7d9;
  height: 30px;
  width: 30px;
  transition: all 0.3s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    background-color: #e8e9ea;
  }
`;

export const Column = styled.div`
  min-width: 200px;
  height: 100%;
`;

export const FullWidthColumn = styled.div`
  width: 530px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  border: 1px solid #007ade;
  padding: 15px 35px 0;
  border-radius: 20px;
`;

export const VideoPlaceHolder = styled.div`
  width: 380px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-left: 30px;
  &:hover {
    cursor: pointer;
  }
`;

export const Title = styled.h2`
  font-weight: bold;
  line-height: 40px;
  color: ${props => props.color || '#141414'};
`;

export const Text = styled.p`
  font-size: ${props => props.size || '16px'};
`;

export const FullWidthText = styled.p`
  width: 100%;
  font-size: 14px;
  line-height: 23px;
  font-weight: bold;
`;

export const Button = styled.button`
  gap: 10px;
  width: ${props => props.width || '100%'};
  background-color: #007ade;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 8px 26px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in;
  border: 1px solid #007ade;
  &:hover {
    color: #007ade;
    cursor: pointer;
    background-color: white;
  }
  &:disabled {
    color: grey;
    border-color: grey;
    background-color: transparent;
    cursor: default;
  }
`;

export const SubTitle = styled.h5`
  font-style: italic;
  color: #616161;
`;

export const StyledIcon = icon => styled(icon)`
  font-size: 50px;
  color: #666666;
  padding: 10px;
`;

export const LineChartIcon = StyledIcon(LineChartOutlined);
export const GiftIcon = StyledIcon(GiftOutlined);

export const InfoIcon = styled(Info)`
  color: #007ade;
  font-size: 16px !important;
  margin-left: 3px;
`;

export const HighlightedText = styled.h4`
  font-weight: bold;
`;

export const Warning = styled.p`
  color: #ff6161;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
`;

export const CelebrationGif = styled.img`
  display: block;
  -webkit-user-select: none;
  margin: auto;
  margin-top: 30px;
`;

export const WatchDemoImg = styled.img`
  display: block;
  -webkit-user-select: none;
  width: 200px;
  margin: 50px 0 0 20px;
`;

export const TextingHubTourGif = styled.img`
  display: block;
  -webkit-user-select: none;
  width: 380px;
  margin-top: 40px;
`;

export const BulletPoint = styled.span`
  height: 10px;
  width: 10px;
  margin-right: 10px;
  background-color: #007ade;
  border-radius: 50%;
  display: inline-block;
`;
