import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const ResetButton = styled(Button)`
  background-color: #000e1e85 !important;
  border: none !important;
  color: white !important;

  &:hover {
    border-color: lightgray !important;
    background-color: lightgray !important;
    color: #000e1e85 !important;
  }
`;

export const AddContactsButton = styled(Button)`
  background-color: #000e1e85 !important;
  border: none;
  color: white !important;
  margin-right: 15px !important;

  &:hover {
    border-color: lightgray !important;
    background-color: lightgray !important;
    color: #000e1e85 !important;
  }
`;
