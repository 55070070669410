import styled from 'styled-components';

export const ActivityContainer = styled.div`
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-size: 16px;
`;
