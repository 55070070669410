import '../EngagementLogs/EngagementLogs.css';
import '../../globalcss.css';

import { Drawer, Spin } from 'antd';
import { Box } from 'grommet';
import MaterialTable, { MTableCell } from 'material-table';
import React, { useState } from 'react';
import { Cake } from '@material-ui/icons';

import EngagementDetailDrawer from '../EngagementDetailDrawer/EngagementDetailDrawer';
import { Tooltip } from '@material-ui/core';

const MemberMessagesDrawer = props => {
  const [pageSize, setPageSize] = useState(50);
  const {
    msgsLoading,
    memberInfo,
    setVisible,
    visible,
    messages,
    showdetailsDrawer,
    setDetailsDrawerVisible,
    detailsDrawerVisible,
    memberMessageInfo,
    msgDetailsLoading,
    memberEngagement,
  } = props;

  /*
        Look into contexting all the things
        Parent State
        
        const [visible, setVisible] = useState(false); 
        const [messages, setMessages] = useState([]);

                            setDetailsDrawerVisible={setDetailsDrawer}
                            detailsDrawerVisible={detailsDrawer}
                            memberMessageInfo={memberMessageInfo}
                            memberInfo={memberInfo}
                            msgDetailsLoading={msgDetailsLoading}
                            memberEngagement={memberEngagement}
    */

  const onClose = () => setVisible(false);

  return (
    <Drawer
      title={
        !msgsLoading
          ? `Messages sent to ${memberInfo.firstName} ${
              memberInfo.lastName
            } (Member #${
              memberInfo.source?.memberId || memberInfo.noteRouterId
            }) `
          : 'Loading Contact Messages'
      }
      width={800}
      onClose={onClose}
      visible={visible}
      style={{
        overflow: 'auto',
        paddingLeft: '0',
        paddingRight: '0',
      }}
    >
      <div className="ManagePortalUserscomp ant-drawerpart">
        <Box overflow="auto">
          <div className="middlePart">
            <Box className="noterouter_managetable btnseparation">
              <Spin
                spinning={msgsLoading}
                tip="Loading Message Sent to User..."
              >
                <MaterialTable
                  components={{
                    // eslint-disable-next-line react/display-name
                    Cell: props => <MTableCell {...props} />,
                  }}
                  columns={[
                    {
                      title: 'Date/Time ',
                      field: 'date',
                      defaultSort: 'desc',
                      // eslint-disable-next-line react/display-name
                      render: rowData => {
                        return <p>{rowData.date}</p>;
                      },
                    },
                    {
                      title: '',
                      field: 'cake',
                      // eslint-disable-next-line react/display-name
                      render: rowData => {
                        if (rowData.isAC)
                          return (
                            <Tooltip
                              title="Automated Campaign Message"
                              placement="top"
                              style={{ justifyContent: 'center' }}
                            >
                              <Cake
                                style={{ float: 'right', color: '#777777' }}
                              />
                            </Tooltip>
                          );
                      },
                    },
                    {
                      title: 'Subject',
                      field: 'messageSubject',
                      // eslint-disable-next-line react/display-name
                      render: rowData => {
                        return <p>{rowData.messageSubject}</p>;
                      },
                    },
                  ]}
                  data={messages}
                  className="Manage_materialtable"
                  title=""
                  actions={[]}
                  options={{
                    search: true,
                    sorting: true,
                    pageSize: pageSize,
                    pageSizeOptions: [10, 20, 50],
                  }}
                  onChangeRowsPerPage={e => {
                    localStorage.setItem('pageSize', e);
                    setPageSize(e);
                  }}
                  onRowClick={(e, data) => showdetailsDrawer(data)}
                />
              </Spin>
              <EngagementDetailDrawer
                setDetailsDrawerVisible={setDetailsDrawerVisible}
                detailsDrawerVisible={detailsDrawerVisible}
                memberMessageInfo={memberMessageInfo}
                memberInfo={memberInfo}
                msgDetailsLoading={msgDetailsLoading}
                memberEngagement={memberEngagement}
              />
            </Box>
          </div>
        </Box>
      </div>
    </Drawer>
  );
};

export default MemberMessagesDrawer;
