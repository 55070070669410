import React, { useState, useEffect } from 'react';
import { DataGrid, Semibold } from './KeywordsTable.styled';
import { makeStyles } from '@material-ui/styles';
import { createTheme } from '@material-ui/core';
import QuickSearch from '../QuickSearch';
import { escapeRegExp, capitalizeFirstLetter, formatDate } from '../utils';
import { KEYWORDS } from '../utils/constants';

const defaultTheme = createTheme();
const useStyles = makeStyles(
  () => ({
    root: {
      '& .MuiDataGrid-columnHeaderWrapper > :first-child': {
        visibility: 'hidden',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: '600',
      },
      '& .MuiDataGrid-columnHeaderWrapper': {
        backgroundColor: '#EDEDED',
      },
      '& .MuiDataGrid-footerContainer': {
        justifyContent: 'center',
      },
      '& .MuiDataGrid-row': {
        cursor: 'pointer',
      },
    },
    subjectHeader: {
      paddingTop: '5px',
    },
  }),
  { defaultTheme }
);

const columns = [
  {
    field: 'keyword',
    headerName: 'Keyword',
    width: 150,
    flex: 1,
    renderCell: params => <Semibold>{params.value}</Semibold>,
  },
  {
    field: 'autoReply',
    headerName: 'Automatic Reply',
    width: 300,
    flex: 2.5,
  },
  {
    field: 'allTimeUsage',
    headerName: 'Usage (All Time)',
    minWidth: 150,
    flex: 1.5,
  },
  {
    field: 'thisMonthUsage',
    headerName: 'Usage (This Month)',
    minWidth: 150,
    flex: 1.5,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'created',
    headerName: 'Created',
    minWidth: 150,
    flex: 1,
  },
];

const KeywordsTable = ({
  onEventClick,
  keywords,
  isLoading,
  onRowSelected,
  status,
  setStatus,
}) => {
  const [pageSize, setPageSize] = useState(25);
  const [rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);

  const formatKeywords = keywords => {
    return keywords?.map(keyword => ({
      id: keyword._id,
      keyword: keyword.keyword,
      autoReply: keyword.response,
      allTimeUsage: keyword.totalCount,
      thisMonthUsage: keyword.monthlyCount,
      status:
        (keyword.status && capitalizeFirstLetter(keyword.status)) || 'Active',
      created: formatDate(keyword.createdAt),
      createdBy: keyword.createdBy,
    }));
  };

  useEffect(() => {
    const rows = formatKeywords(keywords);
    setRows(rows);
  }, [keywords]);

  const requestSearch = searchValue => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const rows = formatKeywords(keywords);
    const filteredRows = rows.filter(row => {
      // we need to search only by visible fields in the table
      return [
        'keyword',
        'autoReply',
        'allTimeUsage',
        'thisMonthUsage',
        'status',
        'created',
      ].some(field => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setRows(filteredRows);
  };

  const classes = useStyles();

  const handleRowSelection = selectedRow => {
    setSelectedRows(selectedRow);
    onRowSelected(selectedRow);
  };

  return (
    <DataGrid
      loading={isLoading}
      className={classes.root}
      rows={rows}
      columns={columns}
      checkboxSelection
      disableSelectionOnClick
      pageSize={pageSize}
      selectionModel={selectedRows}
      onSelectionModelChange={handleRowSelection}
      onRowClick={onEventClick}
      onPageSizeChange={size => setPageSize(size)}
      components={{ Toolbar: QuickSearch }}
      componentsProps={{
        toolbar: {
          value: searchText,
          onChange: event => requestSearch(event.target.value),
          clearSearch: () => requestSearch(''),
          status,
          setStatus,
          selectedMenuItemName: KEYWORDS,
        },
      }}
      getRowClassName={params => {
        if (params.row.status === 'Archived') return 'archived-keywords';
      }}
    />
  );
};

export default KeywordsTable;
