import React from 'react';
import { useDispatch } from 'react-redux';
import {
  setProgressType,
  updateNavProgressMeter,
  updateProgressMeterResults,
} from '../../../redux/action';
import { ExportButton } from './ExportBtn.styled';

const ExportBtn = ({ eventsAndClassesData }) => {
  const dispatch = useDispatch();

  const generateCsvDataForEvents = () => {
    const eventDetails = eventsAndClassesData.map(event => ({
      'Event Name': event.name,
      'Event Description': event.description,
      'Event Date': event.date,
      'Event ID': event.id,
      'Total Registrations': event.numRegistered + event.numGuestRegistered,
    }));
    const result = {
      messages: eventDetails,
      filename: 'events_export.csv',
    };
    dispatch(setProgressType('ANALYTICS_CSV'));
    dispatch(updateNavProgressMeter(100));
    dispatch(updateProgressMeterResults(result));
  };

  return <ExportButton onClick={generateCsvDataForEvents}>Export</ExportButton>;
};

export default ExportBtn;
