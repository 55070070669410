import styled from 'styled-components';

export const Container = styled.div`
  height: 80px;
`;

export const Description = styled.p`
  font-size: 12px;
  margin: 0;
`;

export const Name = styled.h4`
  font-size: 18px;
  margin: 0;
`;

export const Text = styled.h4`
  font-size: 16px;
  text-align: center;
`;

export const Wrapper = styled.div`
  text-align: left;
  padding-left: 10%;
  height: 170px;
`;
