import './ViewMessage.css';
import 'antd/dist/antd.css';
import '../../globalcss.css';

import {
  LaptopOutlined,
  LeftOutlined,
  MobileOutlined,
  TabletOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { CircularProgress } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Popover,
  Radio,
  Row,
  TimePicker,
} from 'antd';
import moment from 'moment-timezone';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import ReactCountdownClock from 'react-countdown-clock';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import _union from 'underscore-es/union';
import { v4 as uuidv4 } from 'uuid';

import { gAnalytics } from '../../redux/helpers/index';
import * as services from '../../redux/services';
import { insertTextQueue } from '../../redux/services/text';
import RecipientTable from '../RecipientTable/RecipientTable';
import {
  userDetailSelector,
  userPrivilegesSelector,
} from '../../redux/selectors/user';
import { audienceSelector } from '../../redux/selectors/audience';
import { selectedOrganizationSelector } from '../../redux/selectors/organization';
import { fullloader, toast } from '../../redux/actions/UI';
import useUserAuth from '../../utils/useUserAuth';
import { convertAudienceDataForDraft } from '../_shared/utils';

import styles from './ViewMessage.module.css';
import Modal from '../Modal';
import * as Handlebars from 'handlebars/dist/cjs/handlebars';
import { DisplayAlert } from './viewMessage.styled';
import ABToggleButton from './ABToggleButton';
import { useIsFeatureEnabled } from '../../libs/feature-toggles/react';
import Feature from '../../infrastructure/features';
import SendDraft from './SendDraft';

export const ViewMessage = () => {
  const canSendHealthCheckTestMessages = [
    'noterouter@getnada.com',
    'nick@noterouter.com',
    'jonathan@noterouter.com',
    'bernard@noterouter.com',
    'adrian@noterouter.com',
    'lauren@noterouter.com',
    'fi95tt6@mtsg.me',
    '4q8cvc7@promail9.net',
    'gough.nicholas@yahoo.com',
    'jonjongough@yahoo.com',
    'dijipom174@zik2zik.com',
    'z6lag@tempmailapp.com',
    'q3ezlgb@jancloud.net',
    '0m8y4@bizsearch.info',
    'wmpx5@email-server.info',
    'natim70367@ddwfzp.com',
    'sevi@noterouter.com',
  ];

  Handlebars.registerHelper('if', function (context, options) {
    return `<div style="border: 3px solid #FC9E3A;">${options.fn(
      context
    )}</div>`;
  });

  Handlebars.registerHelper('subscribedToGroups', function () {
    return;
  });

  const iframeFormatting =
    '<style type="text/css">body { padding: 0 12px 0 0 !important; background-color: white !important; } body::-webkit-scrollbar { width: 8px; } body::-webkit-scrollbar-track { background: rgba(255, 255, 255, 0.1); border-radius: 5px } body::-webkit-scrollbar-thumb { background-color: #DFDFDF; border-radius: 5px; }</style></head>';

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const userPrivileges = useSelector(userPrivilegesSelector);
  const userDetail = useSelector(userDetailSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const audience = useSelector(audienceSelector);

  const [device, setDevice] = useState('laptop');
  const [laptop, setLaptop] = useState('activetog');
  const [visible, setVisible] = useState(false);
  const [textLoader, setTextLoader] = useState(false);
  const [emailLoader, setEmailLoader] = useState(false);
  const [draftData, setDraftData] = useState({});
  const [scheduleTime, setScheduleTime] = useState(null);

  const [iframeRef, setIframeRef] = useState(React.createRef());
  const [mobile, setMobile] = useState('');
  const [tablet, setTablet] = useState('');
  const [isSendingTestMessages, setIsSendingTestMessages] = useState(false);
  const [sendingStatus, setSendingStatus] = useState('');
  const [messageId, setMessageId] = useState('');
  const [isSendDraftModalOpen, setIsSendDraftModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState({ value: false, text: '' });
  const [previewMsg, setPreviewMsg] = useState('');

  const { draft: draftId } = queryString.parse(location.search);
  const isABFeatureEnabled = useIsFeatureEnabled(Feature.AB);

  useUserAuth();
  useEffect(() => {
    if (draftData.sendTime) {
      setScheduleTime(draftData.sendTime);
    }
  }, [draftData.sendTime]);

  useEffect(() => {
    // if it is an AB message and if scheduleTime changes update both versions
    if (draftData.AB) {
      services.updateDraft({ _id: draftData._id, sendTime: scheduleTime });
    }
  }, [scheduleTime]);

  useEffect(() => {
    dispatch(fullloader(true, 'Loading Preview...'));
    gAnalytics('pageview', '/preview');
    loadDraft();

    dispatch(fullloader(false));

    let iframeToUpdate = iframeRef;
    iframeToUpdate.current.contentWindow.document.body.style.height = '67.5vh';
    setIframeRef(iframeToUpdate);
  }, [draftId]);

  useEffect(() => {
    if (emailLoader === true) send();
  }, [emailLoader]);

  const beforeUnloadListen = useCallback(event => {
    if (event) event.preventDefault();
    let confirmationMessage = 'o/';
    (event || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Webkit, Safari, Chrome
  }, []);

  useEffect(() => {
    if (sendingStatus !== 'finished' || sendingStatus !== 'failed') {
      window.addEventListener('beforeunload', beforeUnloadListen);
    } else {
      window.removeEventListener('beforeunload', beforeUnloadListen);
    }
    return () => window.removeEventListener('beforeunload', beforeUnloadListen);
  }, [sendingStatus, beforeUnloadListen]);

  const loadDraft = async () => {
    try {
      const draft = await services.getDraftBy_id(draftId);
      setDraftData(draft);

      if (draft.editMsgId) {
        const editedMessage = await services.getMsgById(draft.editMsgId);
        setScheduleTime(editedMessage.sendTime);
      }
    } catch (e) {
      dispatch(toast('error', 'Error loading draft'));
      history.push('/dashboard/drafts');
    }
  };

  useEffect(() => {
    if (!draftData.html) {
      setIsLoading({ value: true, text: 'Loading design...' });
      return;
    } else {
      setIsLoading({ value: false, text: '' });
    }
    try {
      const template = Handlebars.compile(
        (' ' + draftData.html).slice(1).replace(/<\/head>/g, iframeFormatting)
      );
      setPreviewMsg(template());
      return;
    } catch (e) {
      dispatch(
        toast(
          'error',
          'Error - please check that all personalization inserts are enclosed with double curly brackets {{like_this}}'
        )
      );
    }
  }, [draftData.html]);

  const displayConditionMsg = useMemo(
    () => draftData.html?.toString().includes('subscribedToGroups'),
    [draftData.html]
  );

  const backToEdit = () => {
    history.push(`/dashboard/audience${location.search}`);
  };

  const hideSchedule = () => {
    if (scheduleTime < moment().unix()) {
      return dispatch(
        toast('error', 'Please select a date/time in the future!')
      );
    }
    setVisible(false);
  };

  const toggleMobile = () => {
    setDevice('mobile');
    setLaptop('');
    setMobile('activetog');
    setTablet('');
  };

  const toggleTablet = () => {
    setDevice('tablet');
    setLaptop('');
    setMobile('');
    setTablet('activetog');
  };

  const toggleLaptop = () => {
    setDevice('laptop');
    setLaptop('activetog');
    setMobile('');
    setTablet('');
  };

  const sendTestMessageAlert = async formattedMessageId => {
    fetch(
      'https://hooks.slack.com/services/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',
      {
        method: 'POST',
        mode: 'no-cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          blocks: [
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `Hello, ${userDetail.firstName}. I have detected a New Send Message Health Check Test.\nSee below for preflight specifications. Please note that text configs are shown here but will not be considered for final analysis:`,
              },
            },
            {
              type: 'section',
              fields: [
                {
                  type: 'mrkdwn',
                  text: `*Email Recipients:*\n${audience.emailRecipients.length}`,
                },
                {
                  type: 'mrkdwn',
                  text: `*Text Recipients (Will not be reflected in final analysis):*\n${audience.textRecipients.length}`,
                },
                {
                  type: 'mrkdwn',
                  text: `*Estimated number of email batches:*\n${Math.ceil(
                    audience.emailRecipients.length / 750
                  )}`,
                },
                {
                  type: 'mrkdwn',
                  text: `*Estimated number of text batches (Will not be reflected in final analysis):*\n${Math.ceil(
                    audience.textRecipients.length / 15
                  )}`,
                },
                {
                  type: 'mrkdwn',
                  text: `*Message Payload ID:*\n${formattedMessageId}`,
                },
                {
                  type: 'mrkdwn',
                  text: '*Pod Bay Doors Status:*\nSealed and LOCKED',
                },
              ],
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: "Messages have been sent to their mock destintations (not to real people) and I will report back with the status of batch payloads as they're received on the other side. God speed.",
              },
            },
          ],
        }),
      }
    );
  };

  const send = async () => {
    dispatch(
      toast(
        'info',
        'Please do not leave the page until message has finished queuing.',
        4
      )
    );
    try {
      if (!emailLoader) {
        Sentry.captureMessage('Email loader is false');
        return;
      }
      if (
        (audience.emailRecipients.length > 0 ||
          audience.textRecipients.length > 0) &&
        draftData.subject !== ''
      ) {
        const formattedMessage = formatMessageData();
        formattedMessage.hashId = formattedMessage.id.substring(0, 5);
        console.log('FORMATTED MSG: ', formattedMessage);

        if (isSendingTestMessages) {
          await sendTestMessageAlert(formattedMessage.id);
        }

        const totalTxts = audience.textRecipients.length;
        const totalEmails = audience.emailRecipients.length;

        setSendingStatus('preparing');
        // Insert base message to send out
        const messagePost = await services.insertMessage(formattedMessage);
        if (!messagePost) {
          setSendingStatus('failed');
          Sentry.captureMessage(
            `Failed to insert message ${formattedMessage.messageSubject}`
          );
          dispatch(
            toast(
              'error',
              'Unable to send message. Please go back to the editor screen and double-check the "Message Info" fields.',
              10
            )
          );
          return;
        }

        if (messagePost.messageA && messagePost.messageB) {
          // prior to creating an AB message we only had access to linkedDraftIds
          // if an AB message is created, then we have to overwrite AB field to have linkedMessageIds instead
          formattedMessage.AB =
            formattedMessage.AB.version === 'A'
              ? messagePost.messageA.AB
              : messagePost.messageB.AB;
        }
        setMessageId(formattedMessage.id);

        const messageQOptions = {
          isHealthCheckTestMessage: isSendingTestMessages,
          organizationDetail: selectedOrganization,
        };
        console.log('MSG QUEUE OPTIONS: ', messageQOptions);

        // Base cases for no recipients
        if (totalTxts <= 0) {
          setTextLoader(false);
        }
        if (totalEmails <= 0) {
          setEmailLoader(false);
        }
        // Case when have txt recipients to send
        if (totalTxts > 0) {
          setSendingStatus('queuing');

          const textQueuePost = await insertTextQueue(
            audience.textRecipients.length,
            formattedMessage,
            messageQOptions
          );
          if (textQueuePost && textQueuePost.success) {
            let successMessage;
            if (textQueuePost.uniqueTextCount > 1)
              successMessage = `${textQueuePost.uniqueTextCount} texts are queued`;
            else
              successMessage = `${textQueuePost.uniqueTextCount} text is queued`;
            dispatch(
              toast(
                'success',
                `${successMessage} and will be sent out ${
                  formattedMessage.scheduled ? 'at scheduled time' : 'shortly'
                }.`,
                5
              )
            );
          } else {
            Sentry.captureMessage(
              `Failed to insert text queue ${formattedMessage.id}`
            );
            dispatch(
              toast(
                'error',
                'Message processed. Note: There was an error queuing one or more texts.',
                5
              )
            );
          }
        }

        // Case when have email recipients to send
        if (totalEmails > 0) {
          try {
            setSendingStatus('queuing');
            const emailQueuePost = await services.insertEmailQueue(
              audience.emailRecipients.length,
              formattedMessage,
              messageQOptions
            );
            console.log('EMAIL Q POST: ', emailQueuePost);
            if (emailQueuePost && emailQueuePost.success) {
              let successMessage;
              if (emailQueuePost.uniqueEmailCount > 1)
                successMessage = `${emailQueuePost.uniqueEmailCount} emails are queued`;
              else
                successMessage = `${emailQueuePost.uniqueEmailCount} email is queued`;
              setSendingStatus('finished');
              dispatch(
                toast(
                  'success',
                  `${successMessage} and will be sent out ${
                    formattedMessage.scheduled ? 'at scheduled time' : 'shortly'
                  }.`,
                  5
                )
              );
            } else {
              Sentry.captureMessage(
                `Failed to insert email queue ${formattedMessage.id}`
              );
              Sentry.captureException(emailQueuePost);
              setSendingStatus('failed');
              dispatch(
                toast(
                  'error',
                  'Message processed. Note: There was an error queuing one or more emails.',
                  5
                )
              );
            }
            window.removeEventListener('beforeunload', beforeUnloadListen);
          } catch (err) {
            console.error(err);
            Sentry.captureException(err);
          }
        }

        let versionADraftId;
        if (draftData.AB && draftData.AB.version === 'B') {
          const { _id } = await services.getAVersionId(draftData._id);
          versionADraftId = _id;
        }
        await services.sendDraft(versionADraftId || draftData._id);

        const userOptions = {
          lastUsedSender: draftData.sender,
          id: userDetail.id,
        };
        await services.updateUserSenderName(userOptions);
      } else {
        window.removeEventListener('beforeunload', beforeUnloadListen);
        Sentry.captureMessage('Message missing recipients or subject');
        dispatch(toast('error', 'Select recipients or enter the subject.'));
        setSendingStatus('failed');
      }
    } catch (err) {
      window.removeEventListener('beforeunload', beforeUnloadListen);
      dispatch(toast('error', 'Error sending message.'));
      Sentry.captureException(err);
      setSendingStatus('failed');
    }
  };

  const formatMessageData = () => {
    let emailRecipientId = audience.emailRecipients.map(
      recip => recip.noteRouterId
    );
    let textRecipientId = audience.textRecipients.map(
      recip => recip.noteRouterId
    );
    let recipientIds = _union(emailRecipientId, textRecipientId);

    const audienceData = convertAudienceDataForDraft(audience);
    const result = {
      ...audienceData,
      organizationId: selectedOrganization.id,
      messageSubject: draftData.subject,
      draftName: draftData.name,
      messageBody: draftData.html,
      messageDesign: draftData.design,
      from: draftData.sender,
      sender: draftData.senderName,
      replyTo: draftData.replyTo,
      preheader: draftData.preheader,
      draftIdentifier: draftData._id,
      draftId: draftData._id,
      ...(draftData.AB && { AB: draftData.AB }),
      ...(draftData.eventId && { eventId: draftData.eventId }),
      savedTime: moment().unix(),
      sendTime: scheduleTime || moment().unix(),
      scheduled: !!scheduleTime,
      id: uuidv4(),
      cancelled: false,
      date: moment().format('YYYY-MM-DD'),
      recipientMemberIds: recipientIds,
      totalTargeted:
        audience.emailRecipients.length + audience.textRecipients.length,
      totalEmailTargeted: audience.emailRecipients.length,
      totalTextTargeted: audience.textRecipients.length,
      totalUniqueTargeted: recipientIds.length,
    };

    return result;
  };

  const sendDelayed = () => {
    setEmailLoader(true);
  };

  const handleTimeChange = newDateTime => {
    if (!newDateTime) {
      setScheduleTime(null);
    } else {
      const newTimestamp = newDateTime?.unix();
      setScheduleTime(newTimestamp);
    }
  };

  const handleCancel = async msgId => {
    if (!msgId || msgId === '') {
      setTextLoader(false);
      setEmailLoader(false);
      dispatch(
        toast(
          'error',
          'Message not created yet, please use dashboard to cancel the message after it queues.'
        )
      );
    } else {
      dispatch(
        toast(
          'success',
          'Cancelling message, this may take a minute. Do not leave this screen.'
        )
      );

      let versionAMessageId;
      if (draftData.AB && draftData.AB.version === 'B') {
        const _id = await services.getAVersionMessageId(msgId);
        versionAMessageId = _id;
      }

      await services.updateMsg({
        msgId: versionAMessageId || msgId,
        cancelled: true,
      });

      setSendingStatus('cancelled');

      await services.cancelEmails(msgId);
      dispatch(toast('success', 'Emails successfully cancelled.'));
      await services.cancelTexts(msgId);
      dispatch(toast('success', 'Texts successfully cancelled.'));
    }
  };

  const handleSendStatus = sendingStatus => {
    //Changes the text on the load screen for sending emails, depending on whether it is a scheduled message or instant.
    switch (sendingStatus) {
      case 'preparing':
        return 'Preparing your message, about to start queuing.';
      case 'queuing':
        return 'Queuing up your message, this may take a few minutes to complete. Please stay on this page.';
      case 'finished':
        return scheduleTime
          ? 'Finished queuing. You may cancel your message or edit the content from the NoteRouter dashboard.'
          : 'Finished queuing. For non-scheduled messages, you have a 5 minute grace period to cancel your message from here or the dashboard.';
      case 'sent':
        return 'Your message is now on the way to your recipients!';
      case 'cancelled':
        return 'Your message is being cancelled.';
      case 'failed':
        return 'Message processed. Note: There was an error queuing one or more emails.';
      default:
        return '';
    }
  };

  return (
    <div className="SendMessagecomp">
      {(textLoader || emailLoader) && (
        <div className="spindiv">
          {sendingStatus && (
            <h3 className="finishedQueued">
              {handleSendStatus(sendingStatus)}
            </h3>
          )}
          {(sendingStatus === 'preparing' || sendingStatus == 'queuing') && (
            <CircularProgress
              style={{
                margin: 'auto',
                alignItems: 'center',
                color: '#007ADE',
              }}
            />
          )}
          <div className="">
            <p>File: {draftData.name ? draftData.name : ''}</p>
            <p>
              Subject:{' '}
              {draftData.subject ? draftData.subject : 'No subject provided'}
            </p>
          </div>
          {scheduleTime && (
            <div className="">
              <p>
                Sending at:
                <br />{' '}
                {
                  moment
                    .unix(scheduleTime)
                    .format('MM-DD-YYYY hh:mm a')
                    .split(' ')[0]
                }
                <br />{' '}
                {
                  moment
                    .unix(scheduleTime)
                    .format('MM-DD-YYYY hh:mm a')
                    .split(' ')[1]
                }
              </p>
            </div>
          )}

          {sendingStatus === 'finished' && !scheduleTime && (
            <div className="countdownclock">
              <ReactCountdownClock
                seconds={300}
                color="#e7972d"
                alpha={0.9}
                size={100}
                onComplete={() => {
                  setSendingStatus('sent');
                }}
              />
            </div>
          )}

          {(sendingStatus === 'finished' || sendingStatus === 'queuing') && (
            <div className="cancelDiv">
              <Button //Emergency cancel button
                onClick={() => {
                  handleCancel(messageId);
                }}
                className="cancelbutton"
                type="submit"
              >
                Emergency Cancel
              </Button>
            </div>
          )}
          <div className="exitDiv">
            <Link to="/dashboard">
              <Button className="exitButton">Back To Dashboard</Button>
            </Link>
          </div>
        </div>
      )}
      {!textLoader && !emailLoader && (
        <>
          <Row gutter={16} className="previewTopBar">
            <div className="desk_tab_mobicon">
              <Button
                icon={<LeftOutlined />}
                onClick={backToEdit}
                className="backtoedit"
              >
                Back to Audience
              </Button>

              <div className="message-preview-container">
                <h5>Message Preview</h5>

                {draftData._id && <p>({draftData.name})</p>}
              </div>
            </div>

            <div className="message-preview-btn-container">
              {userPrivileges.messageApproval && (
                <>
                  <Button
                    label="Save"
                    type="submit"
                    className="btnofcnfrm"
                    disabled={!!visible}
                    onClick={() => {
                      setIsConfirmModalOpen(true);
                    }}
                  >
                    Confirm
                  </Button>
                  {isConfirmModalOpen && (
                    <Modal
                      title="Confirm Send"
                      primaryButton={{
                        label: scheduleTime
                          ? 'Confirm (' +
                            moment
                              .unix(scheduleTime)
                              .format('MM-DD-YYYY hh:mm A') +
                            ')'
                          : 'Confirm',
                        onClick: () => {
                          setIsConfirmModalOpen(false);
                          sendDelayed();
                        },
                      }}
                      onClose={() => {
                        setIsConfirmModalOpen(false);
                      }}
                    >
                      {`Please confirm you're ready to send this message. Messages
                    meant to be sent right away will have a 5 minute grace period
                    before actually being delivered. You may cancel your message
                    here or on the dashboard. Please do not leave this screen
                    until the message has queued.`}

                      {userDetail.email &&
                        canSendHealthCheckTestMessages.includes(
                          userDetail.email.toLowerCase()
                        ) && (
                          <div
                            style={{ padding: '15px 5px', textAlign: 'center' }}
                          >
                            <Radio.Group
                              onChange={event =>
                                setIsSendingTestMessages(event.target.value)
                              }
                              value={isSendingTestMessages}
                              defaultValue={false}
                            >
                              <Radio value={false}>Send Real Messages</Radio>
                              <Radio value={true}>
                                Send as Health Check Test
                              </Radio>
                            </Radio.Group>
                          </div>
                        )}
                    </Modal>
                  )}
                </>
              )}
              <Button
                label="Save"
                type="submit"
                className="btnoftest"
                onClick={() => {
                  setIsSendDraftModalOpen(true);
                }}
              >
                Send Draft
              </Button>
              {isSendDraftModalOpen && (
                <SendDraft
                  setIsSendDraftModalOpen={setIsSendDraftModalOpen}
                  draftData={draftData}
                />
              )}
              {userPrivileges.messageApproval && (
                <Popover
                  content={
                    <Row>
                      <Card className="RightCard schedulelater">
                        <>
                          <Row gutter={14}>
                            <Col span={20}>
                              <p className="datestatemnt">
                                Send at this date and time
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={20}>
                              <p className="datetimehead">Date</p>
                            </Col>
                            <Col className="gutter-row" span={24}>
                              <DatePicker
                                className={styles.scheduleInput}
                                // showTime
                                value={
                                  scheduleTime
                                    ? moment.unix(scheduleTime)
                                    : null
                                }
                                format="MM-DD-YYYY"
                                disabledDate={current => {
                                  // don't allow dates in the past
                                  const customDate =
                                    moment().format('YYYY-MM-DD');
                                  return (
                                    current &&
                                    current < moment(customDate, 'YYYY-MM-DD')
                                  );
                                }}
                                onChange={handleTimeChange}
                              />
                            </Col>
                          </Row>
                          <Row className={styles.scheduleTimeFieldWrapper}>
                            <Col span={24}>
                              <p className="datetimehead">Time</p>
                            </Col>
                            <Col className="gutter-row" span={24}>
                              <TimePicker
                                className={styles.scheduleInput}
                                value={
                                  scheduleTime
                                    ? moment.unix(scheduleTime)
                                    : null
                                }
                                onSelect={handleTimeChange}
                                format="hh:mm A"
                                minuteStep={5}
                              />
                            </Col>
                            <span className="timeZoneCopy">{`Time Zone: ${moment
                              .tz(moment.tz.guess(true))
                              .zoneAbbr()}`}</span>
                          </Row>
                          <Row style={{ paddingTop: '10px' }}>
                            <Button className="savebtn" onClick={hideSchedule}>
                              Okay
                            </Button>

                            <a
                              style={{
                                textDecoration: 'underline',
                                padding: '10px',
                              }}
                              onClick={() => {
                                setScheduleTime(null);
                                setVisible(false);
                              }}
                            >
                              Cancel
                            </a>
                          </Row>
                        </>
                      </Card>
                    </Row>
                  }
                  trigger="click"
                  open={visible}
                >
                  <Button
                    className="schedulebtn"
                    onClick={() => {
                      setVisible(true);
                    }}
                  >
                    Schedule Later
                  </Button>
                  {/*The popup is for the final confirmation before emails begin sending out */}
                </Popover>
              )}
              {!userPrivileges.messageApproval && (
                <div>
                  <p
                    style={{
                      float: 'right',
                      paddingTop: '6px',
                      fontWeight: 800,
                    }}
                  >
                    Insufficient Privileges For Sending
                  </p>
                </div>
              )}
            </div>
          </Row>
          <ABToggleButton draftId={draftId} AB={draftData?.AB} />
          <Row gutter={16} style={{ padding: '0px' }}>
            <Col span={18} className="gutter-row">
              <div
                className="infoFrame"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                <div className="messageInfoText">
                  <p>
                    <b>Subject:</b> {draftData.subject}
                  </p>
                  <p>
                    <b>From:</b> {draftData.sender}
                  </p>
                </div>
                <div className="messageInfoText device-switcher">
                  <Button
                    onClick={() => {
                      toggleLaptop();
                    }}
                    className={'resolutionbtn ' + laptop}
                  >
                    <LaptopOutlined />
                  </Button>
                  <Button
                    onClick={() => {
                      toggleTablet();
                    }}
                    className={'resolutionbtn ' + tablet}
                  >
                    <TabletOutlined />
                  </Button>
                  <Button
                    onClick={() => {
                      toggleMobile();
                    }}
                    className={'resolutionbtn ' + mobile}
                  >
                    <MobileOutlined />
                  </Button>
                </div>
              </div>

              <DisplayAlert isVisible={displayConditionMsg}>
                Message sections highlighted with a orange border contain
                display conditions and will only be visible to certain
                recipients.
              </DisplayAlert>

              <div className="previewiframe">
                <div className={`${device} iframe-container`}>
                  <iframe
                    title={' '}
                    ref={iframeRef}
                    id={device}
                    className="preview-message-iframe"
                    srcDoc={previewMsg}
                  />
                </div>
              </div>

              {!userPrivileges.messageApproval && (
                <div>
                  <p
                    style={{
                      float: 'right',
                      paddingTop: '6px',
                      fontWeight: 800,
                    }}
                  >
                    Insufficient Privileges For Sending
                  </p>
                </div>
              )}
            </Col>
            <Col span={6} className="gutter-row">
              <div className="infoFrame">
                <RecipientTable
                  message={{
                    ...draftData,
                    emailRecipients: audience.emailRecipients,
                    textRecipients: audience.textRecipients,
                  }}
                  organization={selectedOrganization}
                  isABFeatureEnabled={isABFeatureEnabled}
                />
              </div>
            </Col>
          </Row>
        </>
      )}
      <div
        className="ContactFieldsLoading"
        style={{ display: isLoading.value ? 'flex' : 'none' }}
      >
        <div className="contentDiv">
          <LoadingOutlined />
          <p>{isLoading.text}</p>
        </div>
      </div>
    </div>
  );
};

export default ViewMessage;
