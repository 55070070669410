import React from 'react';
import { useSelector } from 'react-redux';

import { getDisplayName } from '../libs/react-utils';
import { isAuthenticatedSelector } from '../redux/selectors/auth';
import useUserAuth from '../utils';
import { selectedOrganizationSelector } from '../redux/selectors/organization';

const withAuthentication = Component => {
  const WrappedComponent = props => {
    useUserAuth();

    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const hasOrganization =
      typeof useSelector(selectedOrganizationSelector).id === 'string';
    return isAuthenticated && hasOrganization ? <Component {...props} /> : null;
  };

  WrappedComponent.displayName = `withAuthentication(${getDisplayName(
    Component
  )})`;

  return WrappedComponent;
};

export default withAuthentication;
