import styled from 'styled-components';

export const LogoStyled = styled.span`
  display: flex;
  width: 100%;
  border-radius: 4px;

  & img {
    padding: 40px 0;
    max-width: 80%;
    max-height: 20vw;
    margin: auto;
    object-fit: contain;
  }
`;

export const HeaderMsgStyled = styled.div`
  margin: 24px 0;

  p {
    font-size: 16px;
    font-weight: 500;
  }

  .greetings {
    font-size: 22px;
    font-weight: 600;
  }
`;
