import './Manage.css';

import { Button, IconButton, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';

const DeleteMemberModal = props => {
  const [modalVisible, setModalVisible] = useState(false);
  const handleOpen = () => setModalVisible(true);
  const handleClose = () => {
    setModalVisible(false);
    props.hideMemberDelModal();
  };

  useEffect(() => {
    handleOpen();
  }, []);

  return (
    <div>
      <Modal open={modalVisible} onClose={handleClose}>
        <div className={'manageMembersModalStyling'}>
          <div className={'manageModalHeader'}>
            <IconButton
              onClick={() => {
                handleClose();
              }}
              style={{ color: 'white' }}
            >
              <CloseIcon />
            </IconButton>
            <span style={{ fontSize: '16px' }}>Confirm Deletion</span>
          </div>
          <div className={'manageMembersModalBody'}>
            <p>
              This action will delete {props.numberOfUsers} contacts?
              <b> Once deleted, this data cannot be recovered.</b>
            </p>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                size={'small'}
                style={{
                  backgroundColor: '#FF6161',
                  color: 'white',
                  border: 'none',
                }}
                variant="outlined"
                onClick={() => {
                  handleClose();
                }}
              >
                No, cancel
              </Button>
              <Button
                size={'small'}
                style={{
                  backgroundColor: '#5EAB00',
                  color: 'white',
                  border: 'none',
                }}
                variant="outlined"
                onClick={() => {
                  props.deleteMember();
                }}
              >
                Yes, delete
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteMemberModal;
