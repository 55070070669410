import styled from 'styled-components';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';

export const DataGrid = styled(MuiDataGrid)`
  background: white;

  .MuiDataGrid-row {
    cursor: pointer;
  }
`;
