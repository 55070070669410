import api from '../../utils/andromeda-api';

export async function getOrgContacts(orgId) {
  if (!orgId) return;
  try {
    const response = await api.get(`/contacts/v2/${orgId}`);
    console.log('Received org contacts');
    return response.data.result.orgContacts;
  } catch (e) {
    console.error('Error retrieving contacts [getOrgContacts]', e);
    return { error: true };
  }
}

export async function getManageTableContacts(orgId) {
  if (!orgId) return;
  try {
    const response = await api.get(`/contacts/v2/manage/${orgId}`);
    console.log('Received org contacts for manage table');
    return response.data.result;
  } catch (e) {
    console.error(
      'Error retrieving contacts for manage table [getManageTableContacts]',
      e
    );
    return { error: true };
  }
}

export async function getAllOrgNotes(orgId) {
  if (!orgId) return;
  try {
    const response = await api.get(`/contactNotes/allOrgNotes/${orgId}`);
    console.log('Received all organization notes.');
    return response.data.data;
  } catch (e) {
    console.error(
      'Error retrieving all organization notes [getAllOrgNotes]',
      e
    );
    return { error: true };
  }
}

export const getContactAttributes = async orgId => {
  try {
    const response = await api.get(`/contacts/v2/fullOrgAttributes/${orgId}`);
    console.log('Grabbed all contacts attributes');
    return response.data.result;
  } catch (e) {
    console.error(
      'Error grabbing org contacts attributes [getContactAttributes]'
    );
    return { error: true };
  }
};

export async function getOrgAPIFields(orgInfo) {
  if (!orgInfo.orgId) return;
  try {
    const response = await api.get(
      `/tagmapping/v2/tagmapInfo/${orgInfo.orgId}/${orgInfo.orgType}`,
      orgInfo
    );
    console.log('Received org fields');
    return response.data.result;
  } catch (e) {
    console.error('Error retrieving fields [getOrgAPIFields]', e);
    return { error: true };
  }
}

export async function engagementContacts(orgInfo, isArchived) {
  try {
    const response = await api.get(
      `/contacts/v2/${orgInfo.orgId}${isArchived ? '?deleted=1' : ''}`
    );
    console.log('Received org contacts for engagement details');
    return response.data.result.orgContacts;
  } catch (e) {
    console.error('Error retrieving contacts [engagementContacts]', e);
    return { error: true };
  }
}

export async function contactsWithGroups(orgInfo) {
  try {
    const response = await api.post(
      `/contacts/v2/groups/${orgInfo.orgId}`,
      orgInfo
    );
    console.log('Received org contacts for preference table');
    return response.data.result.orgContacts;
  } catch (e) {
    console.error('Error retrieving contacts [contactsWithGroups]', e);
    return { error: true };
  }
}

export async function fetchTargetedAudience(audience_information) {
  try {
    console.log(audience_information);
    const response = await api.post(
      '/messageTargetAudience/v2',
      audience_information
    );
    console.log('Received org contacts for audience builder');
    return response.data.members;
  } catch (e) {
    console.error(
      'Error retrieving contacts for audience builder[fetchTargetedAudience]',
      e
    );
    return { error: true };
  }
}

export async function getContactById(data) {
  try {
    const response = await api.post(`/contacts/v2/id/${data.orgId}`, data);
    return response.data.result;
  } catch (e) {
    console.error('Error retrieving contact [getContactById]', e);
    return { error: true };
  }
}

export async function getContactByIdPaginated({
  orgId,
  page,
  size,
  search,
  messageId,
}) {
  try {
    const response = await api.get(
      `/contacts/v2/id/paginated/${orgId}/${messageId}?page=${page || 1}&size=${
        size || 50
      }&search=${search || ''}`
    );
    console.log('Received org contact(s)');
    return {
      result: response.data.result,
      pagination: response.data.pagination,
    };
  } catch (e) {
    console.error('Error retrieving contact [getContactById]', e);
    return { error: true };
  }
}

export async function fetchContactPrefPageData(nrId) {
  try {
    const response = await api.get(`preference/contacts/${nrId}`);
    console.log('Received contact info for pref page');
    return response.data.result;
  } catch (e) {
    console.error(
      'Error retrieving contact info for pref page [fetchContactPrefPageData]',
      e
    );
    return { error: true };
  }
}

export async function fetchPrefPageData(nrId) {
  try {
    const response = await api.get(`preference/details/${nrId}`);
    return response.data.result;
  } catch (e) {
    console.error(
      'Error retrieving contact info for pref page [fetchContactPrefPageData]',
      e
    );
    return { error: true };
  }
}

export async function updateContactById(data) {
  try {
    const response = await api.post('/contacts/v2/update', data);
    console.log('Updated contact info');
    return response.data.result;
  } catch (e) {
    console.error('Error updating contact info [updateContactById]', e);
    return { error: true };
  }
}

export async function updateContactByIdForPreference(data) {
  try {
    const response = await api.put('/preference/contact', data);
    console.log('Updated contact info');
    return response.data.result;
  } catch (e) {
    console.error('Error updating contact info [updateContactById]', e);
    return { error: true };
  }
}

export async function updateContactByIdForPreferenceVisited(data) {
  try {
    const response = await api.post('/preference/visit/contact', data);
    console.log('Updated contact info');
    return response.data.result;
  } catch (e) {
    console.error('Error updating contact info [updateContactById]', e);
    return { error: true };
  }
}

export async function addContactToOrg(data) {
  try {
    const response = await api.post('/contacts/v2/add', data);
    console.log('Added contact to org');
    return response.data.result;
  } catch (e) {
    console.error('Error adding contact [addContactToOrg]', e);
    return { error: true };
  }
}

export async function deleteContactsFromOrg(data) {
  try {
    const response = await api.post('/contacts/v2/delete', data);
    console.log('Deleted contacts from org');
    return response;
  } catch (e) {
    console.error(
      'Error deleting contacts from org [deleteContactsFromOrg]',
      e
    );
  }
}

export async function getContactsByGroup(groupId, type) {
  try {
    const data = { groupId, type };
    const response = await api.post('/contacts/v2/byGroup', data);

    return response.data.contacts;
  } catch (e) {
    console.error('Error retrieving contacts [getContactsByGroup]');
    return [];
  }
}

export async function addMultipleGroupsToContacts({
  organizationId,
  contactIds,
  groupIds,
}) {
  try {
    await api.post('/contacts/v2/assignGroups', {
      organizationId,
      contactIds,
      groupIds,
    });

    return { error: false };
  } catch (e) {
    return { error: true };
  }
}

export async function fetchContactFullNames(ids, { organizationId }) {
  const { data } = await api.post(
    '/contacts/v2/getFullNames',
    { ids },
    { headers: { organizationid: organizationId } }
  );
  return data;
}
