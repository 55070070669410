import React from 'react';
import { useTabs } from '../Tabs';
import { List, Tab } from './TabList.styled';

const TabList = () => {
  const tabs = useTabs();

  return (
    <List>
      {tabs.getTabs().map(tab => {
        return (
          <Tab
            key={tab.key}
            isActive={tabs.isActive(tab.key)}
            onClick={() => tabs.setActiveTab(tab.key)}
          >
            {tab.label}
          </Tab>
        );
      })}
    </List>
  );
};

export default TabList;
