import React from 'react';
import cloudSyncedSVG from '../../Images/cloud-upload.svg';

export const AmsSyncedItem = ({ text, color }) => {
  return (
    <div style={{ display: 'flex', gap: '5px' }}>
      <img
        alt="amsSynced"
        src={cloudSyncedSVG}
        style={{ width: '22px', height: '22px' }}
      />
      <span style={{ color }}>{text}</span>
    </div>
  );
};
