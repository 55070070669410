import React from 'react';
import './Quilview.css';
import '../../globalcss.css';
import { Box } from 'grommet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Quilview = ({ isSimpleEditor, value, ontextchange }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ align: [] }],
      ['clean'],
    ],
  };

  const simpleModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
    ],
  };
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'background',
    'font',
    'align',
    'clean',
  ];

  const isExpanded = value?.replace(/<(.|\n)*?>/g, '').trim().length;

  return (
    <Box className="quilsec1">
      <ReactQuill
        className={isExpanded ? 'ql-editor-expanded' : ''}
        value={value}
        onChange={newValue => ontextchange(newValue)}
        modules={isSimpleEditor ? simpleModules : modules}
        formats={formats}
      />
    </Box>
  );
};

export default Quilview;
