import React from 'react';
import Modal from '../../../Component/Modal';

const ConfirmationModal = ({
  title = 'Confirm',
  message = 'Are you sure?',
  confirmationText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm,
  onClose,
}) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal
      title={title}
      primaryButton={{ label: confirmationText, onClick: handleConfirm }}
      secondaryButton={{ label: cancelText }}
      onClose={onClose}
    >
      {message}
    </Modal>
  );
};

export default ConfirmationModal;
