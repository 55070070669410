import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  userPrivilegesSelector,
  selectedOrganizationSelector,
} from '../../../redux/selector';
import { userOrganization } from '../../../redux/actions/organizations';
import { getOrganization } from '../../../redux/services/organization';

import { Container } from './Stripe.styled';
import { Welcome, Pricing, Checkout, Success, Failure } from './Step';

const Stripe = () => {
  const dispatch = useDispatch();
  const userPrivileges = useSelector(userPrivilegesSelector);
  const orgId = useSelector(selectedOrganizationSelector).id;

  const [step, setStep] = useState(1);

  const enableTextingHub = async () => {
    const userOrg = await getOrganization(orgId);
    dispatch(userOrganization(userOrg));
  };

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <Welcome
            onClick={() => {
              setStep(2);
            }}
          />
        );
      case 2:
        return (
          <Pricing
            onClick={() => {
              setStep(3);
            }}
            returnBack={() => {
              setStep(1);
            }}
            userPrivilages={userPrivileges}
          />
        );
      case 3:
        return (
          <Checkout
            orgId={orgId}
            returnBack={() => {
              setStep(2);
            }}
            proceedToSuccessPage={() => {
              setStep(4);
            }}
          />
        );
      case 4:
        return <Success onClick={enableTextingHub} />;
      case 5:
        return (
          <Failure
            onClick={() => {
              setStep(2);
            }}
          />
        );
      default:
        return (
          <Welcome
            onClick={() => {
              setStep(2);
            }}
          />
        );
    }
  };

  return <Container>{renderContent()}</Container>;
};

export default Stripe;
