import { getOrgTypes } from '../../redux/services/organization';
import { makeLegacyAMSSyncRequest } from '../../redux/services/import';

export const fetchOrganizationTypeName = async orgTypeId => {
  if (!orgTypeId) return;

  const allTypes = await getOrgTypes();
  const orgType = allTypes.find(type => type.id === orgTypeId);
  return orgType?.name;
};

export const handleSyncRequest = async (apiPath, requestData, syncMessage) => {
  const { result, message } = await makeLegacyAMSSyncRequest(
    apiPath,
    requestData
  );
  if (!result || !message) {
    return {
      success: false,
      finished: true,
      message: 'Error, sync could not be completed',
    };
  }
  if (message === 'Done') {
    return {
      success: true,
      finished: true,
      message: 'Sync finished successfully',
      result,
    };
  } else if (
    message === 'Next Link' ||
    result.count < result.totalImportCount
  ) {
    return {
      success: true,
      finished: false,
      message: `${result.count} / ${result.totalImportCount}  ${syncMessage} synced.`,
      result,
    };
  } else {
    return {
      success: true,
      finished: false,
      message: `${result.count} / ${result.totalImportCount}  ${syncMessage} synced.`,
      result,
    };
  }
};

export const syncTypes = [
  { name: 'Membership', display: 'Contacts', enabledOrgs: '*' },
  {
    name: 'Billing',
    display: 'Billing',
    enabledOrgs: ['magic', 'ramco', 'gz'],
  },
  {
    name: 'Offices',
    display: 'Offices',
    enabledOrgs: ['magic', 'ramco'],
  },
  {
    name: 'Committees',
    display: 'Committees',
    enabledOrgs: ['magic', 'ramco'],
  },
  {
    name: 'Events',
    display: 'Events',
    enabledOrgs: ['magic'],
  },
  {
    name: 'List',
    display: 'List',
    enabledOrgs: ['magic'],
  },
];
