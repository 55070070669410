import React, { useLayoutEffect } from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';

import { useToggle } from '../../../libs/react-use-extra';
import { useFullLoader, useToast } from '../../../redux/actions/UI';
import { InvalidDataError } from '../api';
import { useCampaignQuery, useUpdateCampaignMutation } from '../queries';
import CampaignEditor from '../CampaignEditor';
import { ErrorMessage, Errors, LoadingMessage } from './EditCampaign.styled';
import NotFoundError from '../api/NotFoundError';

const useFullScreenSpinner = isVisible => {
  const fullLoader = useFullLoader();
  useLayoutEffect(() => {
    if (isVisible) {
      fullLoader.show('Loading campaign...');
      return fullLoader.hide;
    }
  }, [fullLoader, isVisible]);
};

const EditCampaign = () => {
  const { id } = useParams();
  const history = useHistory();
  const fullLoader = useFullLoader();
  const { successToast, errorToast } = useToast();
  const { data, isLoading, isError, error } = useCampaignQuery(id);
  const { mutateAsync: updateCampaign } = useUpdateCampaignMutation();
  const [
    shouldConfirmLeave,
    {
      on: preventLeavingWithoutConfirmation,
      off: allowLeavingWithoutConfirmation,
    },
  ] = useToggle();

  useFullScreenSpinner(isLoading);

  const handleConfirm = async campaignData => {
    try {
      await fullLoader.during('Updating the campaign...', async () => {
        await updateCampaign({ id, updates: campaignData });
        allowLeavingWithoutConfirmation();
        successToast('Campaign successfully updated');
        history.push('/dashboard/automation');
      });
    } catch (error) {
      console.error(error);
      if (error instanceof InvalidDataError) {
        errorToast(
          <Errors>
            <Errors.Title>Entered data is invalid:</Errors.Title>
            <Errors.List>
              {error.getValidationErrors().map((validationError, index) => (
                // Using index as key here is safe, as we're not dealing with stateful errors
                <Errors.Error key={index}>- {validationError}</Errors.Error>
              ))}
            </Errors.List>
          </Errors>,
          10
        );
      } else {
        errorToast(
          'Failed to update campaign. Please, try again later. If the issue persists, please contact us.',
          10
        );
      }
    }
  };

  if (isError) {
    if (error instanceof NotFoundError) {
      return (
        <ErrorMessage>
          Campaign with id #{id} is not found. Please, make sure that you are
          using the valid URL. If the issue persists, please contact us.
        </ErrorMessage>
      );
    }

    return (
      <ErrorMessage>
        Failed to load the campaign. Please, try again later. If the issue,
        persists, please contact us.
      </ErrorMessage>
    );
  }

  if (isLoading) {
    return <LoadingMessage>Loading...</LoadingMessage>;
  }

  return (
    <>
      <CampaignEditor
        initialData={{
          status: data.status,
          name: data.name,
          description: data.description,
          audience: data.audience,
          stages: data.stages,
        }}
        confirmText="Confirm and Update"
        onConfirm={handleConfirm}
        onTouch={preventLeavingWithoutConfirmation}
      />
      <Prompt
        message="Are you sure you want to leave? Any unsaved progress will be lost."
        when={shouldConfirmLeave}
      />
    </>
  );
};

export default EditCampaign;
