import styled from 'styled-components';

const Text = styled.span`
  font-size: 16px;
  margin: 0 15px 0 25px;
  line-height: 30px;

  ${({ isDisabled }) => isDisabled && 'color: rgba(0, 0, 0, 0.38);'}
`;

export default Text;
