import queryString from 'query-string';
import * as services from '../../redux/services';
import { organizationList, userOrganization } from './organizations';
import { fullloader, privilegesList, storeRoleData, toast } from './UI';
import { userdetail, userPrivileges } from './user';

//Dispatch user list to reducers
export function authLogin() {
  return {
    type: 'AUTH_LOGIN',
  };
}

export const authLogoutAction = () => ({ type: 'AUTH_LOGOUT' });

export const logout = history => async dispatch => {
  services.logoutUser(didSucceed => {
    if (didSucceed) {
      dispatch(authLogoutAction());
      history.push('/login');
      dispatch(toast('success', 'Logged Out Successfully.'));
      dispatch(resetApplication());
    } else {
      dispatch(toast('error', 'Could not logout please try again.'));
    }
  });
};

export function resetApplication() {
  return {
    type: 'RESET_APPLICATION',
  };
}

const validateUserPrivileges =
  ({ user, location }) =>
  async (dispatch, getStore) => {
    const store = getStore();
    services.getPrivilegesList().then(privileges => {
      dispatch(privilegesList(privileges));

      const queryInitObject = queryString.parse(location.search);
      const queryOrg = queryInitObject.org;
      const orgId = queryOrg ? queryOrg : store.organization.selected.id;
      const userId = user.id;

      const privilegesToCheck = [
        { preferences: privileges.MANAGE_PREFERENCES },
        { engagement: privileges.MANAGE_ENGAGEMENT },
        { orgHeader: privileges.MANAGE_ORG_HEADER },
        { editor: privileges.SEND_MESSAGE_TEST_ONLY },
        { analytics: privileges.MANAGE_ANALYTICS },
        { manageOrgAdmin: privileges.MANAGE_ORG_USERS_ADMIN },
        { manageAuthOrgs: privileges.MANAGE_AUTHORIZED_ORGS },
        { manageMembers: privileges.MANAGE_MEMBERS },
        { manageTags: privileges.MANAGE_TAGS },
        { messageApproval: privileges.SEND_MESSAGE_FULL_ACCESS },
        { isSU: privileges.MANAGE_EXECUTIVE_ADMINS },
        { isSA: privileges.MANAGE_COMPANY_ITEMS },
        { isSDA: privileges.MANAGE_SUPER_ADMINS },
        { manageUser_Admin: privileges.MANAGE_ORG_USERS_ADMIN },
        { manageUser_Exec: privileges.MANAGE_ORG_USERS_EXEC },
        { beeAdmin: privileges.nr_bee_admin },
      ];

      if (userId && orgId && privilegesToCheck) {
        services
          .validatePrivilegeBatch(userId, orgId, privilegesToCheck)
          .then(async res => {
            if (!res.data.userPrivileges.isSU) {
              const userOrgList = await services.getMultiUserOrgList(
                store.user.detail.organizations
              );
              dispatch(organizationList(userOrgList));
            } else {
              const userOrgList = await services.getOrganizationList();
              dispatch(organizationList(userOrgList));
            }
            dispatch(userPrivileges(res.data.userPrivileges));
            dispatch(fullloader(false));
          })
          .catch(error => {
            console.log(error);
          });
      }
    });

    services.getRoles().then(roles => {
      dispatch(storeRoleData(roles));
      dispatch(fullloader(false));
    });
  };

export const authUserCheck =
  ({ location, history, redirectUrl }) =>
  async (dispatch, getStore) => {
    const store = getStore();
    const queryInitObject = queryString.parse(location.search);

    if (store.user.detail && store.user.detail.id) return;
    if (!store.auth.isAuthenticated) {
      dispatch(fullloader(true, 'Authenticating...'));
    }

    services.checkUserLoggedIn(async user => {
      if (user) {
        dispatch(authLogin());
        dispatch(userdetail(user));
        const queryOrg = queryInitObject.org;
        let userOrg;
        let userOrgList;
        if (user.role === 'admin') {
          userOrg = await services.getOrganization(
            queryOrg ? queryOrg : user.organizationId
          );
          userOrgList = await services.getOrganizationList();
        } else {
          userOrg = await services.getOrganization(
            queryOrg ? queryOrg : user.organizationId
          );
          userOrgList = [userOrg];
        }

        dispatch(userOrganization(userOrg));
        dispatch(organizationList(userOrgList));
        dispatch(validateUserPrivileges({ user, location }));
        dispatch(fullloader(false));
      } else {
        dispatch(authLogoutAction());
        dispatch(fullloader(false));
        if (redirectUrl) {
          history.push(`/login?redirectUrl=${encodeURIComponent(redirectUrl)}`);
        } else {
          history.push('/login');
        }
      }
    });
  };
