import styled from 'styled-components';
import { Input } from '@material-ui/core';

import baseInputStyles from './baseInputStyles';

const NumberInput = styled(Input).attrs({ type: 'number' })`
  ${baseInputStyles}
`;

export default NumberInput;
