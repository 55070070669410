import React from 'react';
import { Tooltip } from '@material-ui/core';
import {
  Container,
  ABwrapper,
  ABaverage,
  ABstatistics,
  Underline,
  ResendStats,
  Bold,
  ArrowUpIcon,
  Line,
  Row,
  Resend,
  ArrowUpIconSmall,
  Small,
  ArrowDownIconSmall,
  ArrowDownIcon,
} from './CardABversion.styled';

const CardABversion = ({
  name,
  valueA,
  valueB,
  generateAverage,
  smartResendValueA,
  smartResendValueB,
  title,
  onClick = null,
  isSmartResendAvailable,
  showComparativeAnalytics,
}) => {
  let average;
  const isNotZero = value => {
    return value !== '0%' && value !== '0.0%' && value !== 0 && value !== null;
  };

  if (smartResendValueA || smartResendValueB) {
    average = generateAverage(smartResendValueA, smartResendValueB);
  }

  const isIncreasedInValue = val => {
    return val && !String(val).startsWith('-');
  };

  const renderIncrease = val => {
    return (
      <>
        <ArrowUpIcon />
        <Bold>+{val}</Bold> from resend
      </>
    );
  };

  const renderDecrease = val => {
    return (
      <>
        <ArrowDownIcon />
        <Bold>{val}</Bold> from resend
      </>
    );
  };

  const renderIncreaseSmall = val => {
    return (
      <>
        <ArrowUpIconSmall /> {val}
      </>
    );
  };

  const renderDecreaseSmall = val => {
    return (
      <>
        <ArrowDownIconSmall /> {val}
      </>
    );
  };

  return (
    <Tooltip title={title} placement="top" style={{ justifyContent: 'center' }}>
      <Container onClick={onClick} width="130px">
        <Underline>{name}</Underline>
        <ABaverage>Average: {generateAverage(valueA, valueB)}</ABaverage>
        {isSmartResendAvailable && (
          <ResendStats>
            {isNotZero(average) ? (
              showComparativeAnalytics ? (
                isIncreasedInValue(average) ? (
                  renderIncrease(average)
                ) : (
                  renderDecrease(average)
                )
              ) : (
                <>
                  Resend:<Bold> {average}</Bold>
                </>
              )
            ) : (
              'no change from resend'
            )}
          </ResendStats>
        )}
        <Line />
        <ABwrapper>
          <Row>
            <ABstatistics>A: {valueA}</ABstatistics>
            {(isSmartResendAvailable && (
              <Resend>
                {isNotZero(smartResendValueA) ? (
                  showComparativeAnalytics ? (
                    isIncreasedInValue(smartResendValueA) ? (
                      renderIncreaseSmall(smartResendValueA)
                    ) : (
                      renderDecreaseSmall(smartResendValueA)
                    )
                  ) : (
                    <Small> Resend: {smartResendValueA}</Small>
                  )
                ) : (
                  '- no change'
                )}
              </Resend>
            )) ||
              null}
          </Row>
          <Row>
            <ABstatistics>B: {valueB}</ABstatistics>
            {(isSmartResendAvailable && (
              <Resend>
                {isNotZero(smartResendValueB) ? (
                  showComparativeAnalytics ? (
                    isIncreasedInValue(smartResendValueB) ? (
                      renderIncreaseSmall(smartResendValueB)
                    ) : (
                      renderDecreaseSmall(smartResendValueB)
                    )
                  ) : (
                    <Small> Resend: {smartResendValueB}</Small>
                  )
                ) : (
                  '- no change'
                )}
              </Resend>
            )) ||
              null}
          </Row>
        </ABwrapper>
      </Container>
    </Tooltip>
  );
};

export default CardABversion;
