import React from 'react';
import Modal from '../../../../Modal';

const ConfirmDeleteABDraftModal = ({ confirm, onHide }) => {
  return (
    <Modal
      title="Are you sure you want to remove A/B mode?"
      primaryButton={{ onClick: confirm }}
      onClose={onHide}
    >
      Confirming this action will permanently delete the B version of your A/B
      message. If you might want it in the future, you should click
      &quot;cancel&quot; and save version B as a template before deleting it.
    </Modal>
  );
};

export default ConfirmDeleteABDraftModal;
