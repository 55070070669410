import React from 'react';
import {
  Container,
  Header,
  Icon,
  Title,
  Description,
} from './InputGroup.styled';

const FormSection = ({
  className,
  IconComponent,
  title,
  description = null,
  children,
}) => {
  return (
    <Container className={className}>
      <Header>
        <Icon as={IconComponent} />
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </Header>
      {children}
    </Container>
  );
};

export default FormSection;
