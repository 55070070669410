import React, { useCallback, useRef, useState } from 'react';
import { SettingsInputComposite } from '@material-ui/icons';
import { _partial, _uniqueId } from 'underscore-es';

import { useToggle } from '../../../../libs/react-use-extra';
import ConfirmationDialog from '../../ConfirmationDialog';
import { useEditor } from '../CampaignEditor';
import { FormSection } from '../../forms';
import {
  AddButton,
  DeleteButton,
  Stage,
  StageList,
  Step,
} from './Stages.styled';
import { useToast } from '../../../../redux/actions/UI';
import { Order, Unit } from '../../api';

const DEFAULT_STAGES = [];
const REQUIRED_STAGES_COUNT = 1;

const Stages = ({ confirmText, onConfirm }) => {
  const [isValidationVisible, { on: showValidation }] = useToggle();
  const stagesRef = useRef({});

  const editor = useEditor();
  const { errorToast } = useToast();

  const goToPreviousStep = useCallback(() => {
    editor.goToStep('overview');
  }, [editor]);

  const [
    isDeleteConfirmationOpen,
    { on: openDeleteConfirmation, off: closeDeleteConfirmation },
  ] = useToggle();

  const stages = editor.getField('stages') ?? DEFAULT_STAGES;

  const [stageIdToDelete, setStageIdToDelete] = useState(null);
  const stageToDelete = stages.find(stage => stage._id === stageIdToDelete);

  const haveStages = stages.length > 0;

  const handleCreate = useCallback(async () => {
    if (!isValidationVisible) {
      showValidation();
    }

    const stageRefs = Object.values(stagesRef.current);
    const areAllStagesValid = stageRefs.every(stage => stage.isValid());
    if (areAllStagesValid && haveStages) {
      const data = editor.getData();
      const campaignData = {
        status: data.status,
        name: data.name,
        description: data.description,
        audience: data.audience,
        stages: stages.map(stage => ({
          _id: stage._id,
          name: stage.name,
          conditions: stage.conditions,
          template: stage.template,
        })),
      };
      onConfirm(campaignData);
    } else if (!haveStages) {
      errorToast(
        "This campaign doesn't have any stages. Please add one and try again.",
        5
      );
    } else if (!areAllStagesValid) {
      errorToast(
        'Some stages are invalid. Please correct them and try again.',
        5
      );
    }
  }, [
    editor,
    errorToast,
    haveStages,
    isValidationVisible,
    onConfirm,
    showValidation,
    stages,
  ]);

  const shouldAllowDeletingStages = stages.length > REQUIRED_STAGES_COUNT;

  const addStage = useCallback(() => {
    const newStage = {
      _id: _uniqueId('stage'),
      name: `Stage ${stages.length + 1}`,
      conditions: [
        {
          _id: _uniqueId('condition'),
          name: null,
          delay: { value: 0, order: Order.AFTER, unit: Unit.DAY },
        },
      ],
    };

    editor.setField('stages', [...stages, newStage]);
  }, [editor, stages]);

  const updateStage = useCallback(
    (id, newStage) => {
      const newStages = stages.map(_stage => {
        return _stage._id === id ? newStage : _stage;
      });
      editor.setField('stages', newStages);
    },
    [editor, stages]
  );

  const createStageUpdater = useCallback(
    id => _partial(updateStage, id),
    [updateStage]
  );

  const deleteStage = useCallback(() => {
    const newStages = stages.filter(_stage => _stage._id !== stageIdToDelete);
    editor.setField('stages', newStages);
  }, [editor, stageIdToDelete, stages]);

  const createStageDeleter = useCallback(
    id => {
      return () => {
        setStageIdToDelete(id);
        openDeleteConfirmation();
      };
    },
    [openDeleteConfirmation]
  );

  const createStageRef = useCallback(id => {
    return stage => {
      if (stage) {
        stagesRef.current[id] = stage;
      } else {
        delete stagesRef.current[id];
      }
    };
  }, []);

  return (
    <Step
      title="Stages"
      description="Stages determine when and what gets sent to contacts."
      nextText={confirmText}
      onBack={goToPreviousStep}
      onNext={handleCreate}
    >
      <FormSection IconComponent={SettingsInputComposite} title="Stages">
        {haveStages && (
          <StageList>
            {stages.map(stage => {
              return (
                <StageList.Item key={stage._id}>
                  <Stage
                    ref={createStageRef(stage._id)}
                    value={stage}
                    onChange={createStageUpdater(stage._id)}
                    isValidationVisible={isValidationVisible}
                  />
                  {shouldAllowDeletingStages && (
                    <DeleteButton onClick={createStageDeleter(stage._id)} />
                  )}
                </StageList.Item>
              );
            })}
          </StageList>
        )}
        <AddButton onClick={addStage} isHighlighted={!haveStages}>
          <AddButton.Icon />
          <AddButton.Label>
            Add Stage{' '}
            <AddButton.Label.HelperText>
              (
              {haveStages
                ? 'optional'
                : 'click to add your first stage to this Campaign'}
              )
            </AddButton.Label.HelperText>
          </AddButton.Label>
          {!haveStages && (
            <AddButton.ValidityIndicator
              isVisible={isValidationVisible}
              isValid={haveStages}
            />
          )}
        </AddButton>
        {isDeleteConfirmationOpen && (
          <ConfirmationDialog
            message={`Are you sure you want to delete the stage "${stageToDelete.name}"? Its data, including its engagement history, will be permanently deleted from the campaign.`}
            onConfirm={deleteStage}
            onClose={closeDeleteConfirmation}
          />
        )}
      </FormSection>
    </Step>
  );
};

export default Stages;
