import { v4 as uuidv4 } from 'uuid';
import { getTimeZoneObject } from './utils';
import api from '../../../../../../utils/andromeda-api';

export default class PaperForm {
  constructor(paperFormData) {
    const newOrgId = uuidv4(); // create a new orgId
    this.orgId = newOrgId;
    this.orgData = this.transformOrgData({ paperFormData, newOrgId });
    this.keyContacts = this.transformOrgDataForKeyContacts({
      paperFormData,
      newOrgId,
    });
    this.groupsData = this.transformGroupsData({ paperFormData, newOrgId });
    this.topicsData = this.transformTopicsData({ paperFormData, newOrgId });
  }

  transformOrgDataForKeyContacts = ({ paperFormData, newOrgId }) => {
    const keyContacts = [];

    // first check if all required fields for a key contact exists, and if so then add it to org
    if (
      paperFormData.primaryContactFirstName &&
      paperFormData.primaryContactLastName &&
      paperFormData.primaryContactEmail
    ) {
      keyContacts.push({
        role: 'Primary Account Contact',
        firstName: paperFormData.primaryContactFirstName,
        lastName: paperFormData.primaryContactLastName,
        email: paperFormData.primaryContactEmail,
        organizationId: newOrgId,
        phoneNumber: paperFormData.primaryContactPhone,
      });
    }

    if (
      paperFormData.membershipContactFirstName &&
      paperFormData.membershipContactLastName &&
      paperFormData.membershipContactEmail
    ) {
      keyContacts.push({
        role: 'Membership Contact',
        firstName: paperFormData.membershipContactFirstName,
        lastName: paperFormData.membershipContactLastName,
        email: paperFormData.membershipContactEmail,
        organizationId: newOrgId,
        phoneNumber: paperFormData.membershipContactPhone,
      });
    }

    if (
      paperFormData.billingContactFirstName &&
      paperFormData.billingContactLastName &&
      paperFormData.billingContactEmail
    ) {
      keyContacts.push({
        role: 'Billing Contact',
        firstName: paperFormData.billingContactFirstName,
        lastName: paperFormData.billingContactLastName,
        email: paperFormData.billingContactEmail,
        organizationId: newOrgId,
        phoneNumber: paperFormData.billingContactPhone,
      });
    }

    if (
      paperFormData.itContactFirstName &&
      paperFormData.itContactLastName &&
      paperFormData.itContactEmail
    ) {
      keyContacts.push({
        role: 'IT Contact',
        firstName: paperFormData.itContactFirstName,
        lastName: paperFormData.itContactLastName,
        email: paperFormData.itContactEmail,
        organizationId: newOrgId,
        phoneNumber: paperFormData.itContactPhone,
      });
    }

    if (
      paperFormData.authorizedSignerContactFirstName &&
      paperFormData.authorizedSignerContactLastName &&
      paperFormData.authorizedSignerContactEmail &&
      paperFormData.authorizedSignerContactTitle
    ) {
      keyContacts.push({
        role: 'Authorized Signer Contact',
        firstName: paperFormData.authorizedSignerContactFirstName,
        lastName: paperFormData.authorizedSignerContactLastName,
        email: paperFormData.authorizedSignerContactEmail,
        title: paperFormData.authorizedSignerContactTitle,
        organizationId: newOrgId,
        phoneNumber: paperFormData.authorizedSignerContactPhone,
      });
    }

    return keyContacts;
  };

  transformOrgData = ({ paperFormData, newOrgId }) => {
    const fromEmail = paperFormData.organizationDefaultFromEmail.split('@');
    return {
      acronym: paperFormData.organizationAcronym,
      address: [
        {
          address: paperFormData.street,
          city: paperFormData.suburb,
          state: paperFormData.state,
          zipcode: paperFormData.postcode,
        },
      ],
      federalEIN: paperFormData.EIN,
      website: paperFormData.organizationWebsite,
      corporationType: paperFormData.organizationType,
      delete: false,
      domains: [fromEmail[1]],
      editTextPreferences: false,
      email: paperFormData.organizationPublicEmail,
      enable: 1,
      fromEmail: fromEmail[0], // poorly named field. this is actually from email 'prefix'
      fromPhone: null,
      id: newOrgId,
      hashId: newOrgId.substring(0, 5),
      importMagic: false,
      memberPortalURL: paperFormData.organizationMemberPortalURL
        ? paperFormData.organizationMemberPortalURL.replace(
            /^(https?:\/\/)?(www\.)?/,
            ''
          )
        : '', // strip 'https://' and 'www.'
      organizationName: paperFormData.organizationName,
      orgType: null,
      phoneNumber: parseInt(
        paperFormData.organizationPublicPhone.split('-').join('')
      ),
      QBCustomer: false,
      senderName: paperFormData.organizationName,
      timezone: getTimeZoneObject(paperFormData.organizationTimeZone),
      updatedDate: new Date().toString(),
      logoData: paperFormData.organizationLogo?.url && {
        organizationId: newOrgId,
        organizationImageUrl: paperFormData.organizationLogo.url,
      },
    };
  };

  transformGroupsData = async ({ paperFormData, newOrgId }) => {
    // slick way to create an array of group keynames up to 20 e.g. ['group1', 'group2'...'group20']
    const groupKeyNames = Array.from({ length: 20 }, (_, i) => i + 1).map(
      _ => `group${_}`
    );

    const response = await api.get('/groups/types');

    const groupTypes = response.data?.result;
    const membershipGroupId = groupTypes?.find(
      _ => _.name === 'Membership'
    )?.id;

    // membershipGroupId is needed to avoid issues further down the line. so if we can't find it, don't create groups
    if (!membershipGroupId) {
      return [];
    }

    // filter out groups that weren't entered
    const filteredGroupNames = groupKeyNames.filter(keyName => {
      return paperFormData[keyName];
    });

    return filteredGroupNames.map(keyName => {
      const groupName = paperFormData[keyName];
      const date = new Date();
      return {
        listTag: false,
        organizationId: newOrgId,
        delete: false,
        listCategory: '',
        listCode: '',
        name: groupName.trim(),
        groupTypeId: membershipGroupId,
        description: '',
        createdDate: date.toString(),
        updatedDate: date.toString(),
        enable: 1,
        id: uuidv4(),
        subscribedMembers: 0,
      };
    });
  };

  transformTopicsData = ({ paperFormData, newOrgId }) => {
    // create an array of topic names and desc, e.g. [['optionalTopic1Name', 'optionalTopic1Description']]
    const optionalTopicKeyNames = Array.from(
      { length: 10 },
      (_, i) => i + 1
    ).map(_ => [`optionalTopic${_}Name`, `optionalTopic${_}Description`]);

    // add the mandatoryTopic keyname and description
    const topicKeyNames = [
      ...optionalTopicKeyNames,
      ['mandatoryTopicName', 'mandatoryTopicDescription'],
    ];

    // filter out the keynames we don't have data for
    const filteredTopicKeyNames = topicKeyNames.filter(_ => {
      return paperFormData[_[0]];
    });

    const date = new Date();
    // loop through the filtered topic keynames to create topics
    const topics = filteredTopicKeyNames.reduce((acc, curr) => {
      const isMandatoryTopic = curr[0] === 'mandatoryTopicName';
      const isNonMemberTopic =
        paperFormData[curr[0]].trim().toLowerCase() === 'nonmember information';
      return [
        ...acc,
        {
          organizationId: newOrgId,
          delete: false,
          name: paperFormData[curr[0]].trim(),
          description: paperFormData[curr[1]],
          createdDate: date.toString(),
          updatedDate: date.toString(),
          enable: 1,
          id: uuidv4(),
          subscribedMembers: 0,
          availableTo: {
            member: !isNonMemberTopic,
            nonMember: isNonMemberTopic,
          },
          textSubscribedCount: 0,
          emailSubscribedCount: 0,
          editEmailPreference: true,
          editTextPreference: true,
          mandatory: isMandatoryTopic,
          highlightTopic: false,
        },
      ];
    }, []);

    return topics;
  };
}
