import React from 'react';
import { FormControl, Select, MenuItem } from '@material-ui/core';

const SelectDropDown = ({
  value,
  defaultValue,
  menuItemNames,
  onChange,
  size,
  style = {},
}) => {
  return (
    <FormControl size={size ? size : 'medium'}>
      <Select
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        displayEmpty
        style={style}
      >
        {menuItemNames.map((menuItemName, idx) => (
          <MenuItem key={`${menuItemName}-${idx}`} value={menuItemName}>
            {menuItemName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectDropDown;
