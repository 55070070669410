import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-left: 20px;
  border-radius: 5px;
`;

export default Container;
