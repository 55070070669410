import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { saveNewTemplate } from '../../../../redux/services';
import {
  selectedOrganizationSelector,
  userDetailSelector,
} from '../../../../redux/selector';
import { Button, Menu } from './MenuContent.styled';
import { toast, fullloader } from '../../../../redux/action';
import SaveAsDesignModal from '../../Modals/SaveAsDesignModal';

const MenuContent = ({
  data = {},
  initialName,
  onSaveSuccess,
  shouldIncludeABToggle,
  isABToggleEnabled,
  setABToggleEnabled,
  setConfirmDeleteABDraftModalVisible,
  handleVersionChange,
  beeEditor,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const organizationId = useSelector(selectedOrganizationSelector).id;
  const userId = useSelector(userDetailSelector).id;
  const { draft: draftId } = queryString.parse(location.search);

  const [isSaveAsDesignModalVisible, setSaveAsDesignModalVisible] =
    useState(false);
  const [isToggleStructureOn, setToggleStructure] = useState(true);

  const saveAsNewTemplate = async name => {
    try {
      dispatch(fullloader(true, 'Saving a new template...'));
      const template = {
        archived: false,
        name: name || 'Untitled',
        organizationId,
        userId,
        draftId,
        ...data,
      };

      await saveNewTemplate(template);
      onSaveSuccess();
      setSaveAsDesignModalVisible(false);
      dispatch(fullloader(false));
      dispatch(toast('success', `${template.name} saved`));
    } catch (e) {
      dispatch(toast('error', 'Error saving Template.'));
    }
  };

  const getABText = () => {
    return isABToggleEnabled ? 'Remove A/B Mode' : 'Activate A/B Mode';
  };

  const toggleBeeEditorSturcture = async () => {
    setToggleStructure(!isToggleStructureOn);

    await beeEditor.toggleStructure();
  };

  const fileMenuContent = (
    <Menu>
      <Menu.Item key="save" onClick={() => setSaveAsDesignModalVisible(true)}>
        Save Template
      </Menu.Item>
      {shouldIncludeABToggle && (
        <Menu.Item
          key="enableAB"
          onClick={async () => {
            if (isABToggleEnabled) {
              setConfirmDeleteABDraftModalVisible(true);
            } else {
              setABToggleEnabled(true);
              await handleVersionChange('A');
            }
          }}
        >
          {getABText()}
        </Menu.Item>
      )}
      <Menu.Item key="toggleStructure" onClick={toggleBeeEditorSturcture}>
        {isToggleStructureOn ? 'Hide Structure' : 'Show Structure'}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={fileMenuContent}>
        <Button style={{ width: '110px' }}>
          Actions <DownOutlined type="down" />
        </Button>
      </Dropdown>
      {isSaveAsDesignModalVisible && (
        <SaveAsDesignModal
          initialName={`${initialName} Template`}
          onHide={() => setSaveAsDesignModalVisible(false)}
          saveAsNewTemplate={saveAsNewTemplate}
        />
      )}
    </>
  );
};

MenuContent.defaultProps = {
  onSaveSuccess: () => {},
};

export default MenuContent;
