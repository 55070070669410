import api from '../../utils/andromeda-api';

export async function saveOptinLog(data) {
  try {
    const response = await api.post('/organization/saveOptinLog', {
      newContent: data,
    });
    console.log('Saved optin log');
    if (response?.data?.result) {
      return true;
    }
  } catch (e) {
    console.error('Error saving optin log [saveOptinLog]', e);
    return false;
  }
}

export async function saveOptinContent({ id, data }) {
  try {
    const response = await api.post('/organization/saveOptinContent', {
      orgId: id.toString(),
      newContent: data,
    });
    console.log('Saved optin content');
    if (response?.data?.result) {
      return true;
    }
  } catch (e) {
    console.error('Error saving optin content [saveOptinContent]', e);
    return false;
  }
}

export async function getOptinLogs(id) {
  try {
    const response = await api.get(`/organization/getOptinLogs/${id}`);
    console.log('Retrieved optin logs');
    if (response?.data?.result) {
      return response.data.result;
    }
  } catch (e) {
    console.error('Error getting optin logs [getOptinLogs]', e);
    return { error: true };
  }
}
