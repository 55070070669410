import { useEffect } from 'react';
import { useStableCallback } from '../react-use-extra';

const useIntersectionObserver = (target, onIntersect) => {
  onIntersect = useStableCallback(onIntersect);

  useEffect(() => {
    if (!target) {
      return;
    }

    const observer = new IntersectionObserver(() => {
      onIntersect();
    });

    observer.observe(target);

    return () => {
      observer.disconnect();
    };
  }, [onIntersect, target]);
};

export default useIntersectionObserver;
