import React, { useEffect, useMemo, useState } from 'react';
import Upload from '../../../../Component/upload/upload';
import { Trophy } from 'grommet-icons';
import OptInEditor from './Components/OptInEditor';
import { Button } from 'grommet';
import { gAnalytics } from '../../../../redux/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { selectedOrganizationSelector } from '../../../../redux/selectors/organization';
import * as services from '../../../../redux/services';
import { fullloader, toast } from '../../../../redux/actions/UI';
import { Col, Row } from 'antd';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { uploadLogoDoc } from '../../../../redux/services';
import { userDetailSelector } from '../../../../redux/selectors/user';
import PrefURL from './Components/PrefURL';

const PreferencePage = () => {
  const [file, setFile] = useState(); // the logo file
  const [madeEdits, setMadeEdits] = useState(false);
  const [changedLogo, setChangedLogo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [memberOptinHeaderMsg, setMemberOptinHeaderMsg] = useState('');
  const [nonMemberOptinHeaderMsg, setNonMemberOptinHeaderMsg] = useState('');

  const selectedOrg = useSelector(selectedOrganizationSelector);
  const user = useSelector(userDetailSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fullloader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    gAnalytics('pageview', '/optin-content');
    window.scrollTo(0, 0);
    if (selectedOrg?.id) {
      getOrgContent();
    }
  }, [selectedOrg]);

  const buildOptinData = ({ content, contactType }) => {
    return {
      organizationId: selectedOrg.id,
      content,
      contactType,
    };
  };

  const getOptInContent = async ({ contactType }) =>
    await services.orgContent({
      orgId: selectedOrg.id,
      contactType,
    });

  const saveOptInContent = async ({ data }) =>
    await services.saveOptinContent({
      id: selectedOrg.id,
      data,
    });

  const getOrgContent = async () => {
    const content = ['member', 'nonMember'].map(type =>
      getOptInContent({ contactType: type })
    );
    const [memberOrgContent, nonMemberOrgContent] = await Promise.all(content);
    setMemberOptinHeaderMsg(memberOrgContent?.content || '');
    setNonMemberOptinHeaderMsg(nonMemberOrgContent?.content || '');

    // reset the loading and edit states whenever we change to a new org
    setMadeEdits(false);
    setIsLoading(false);
  };

  const uploadLogo = async () => {
    const storage = getStorage();
    await getDownloadURL(ref(storage, `images/${file}`))
      .then(async url => {
        const docData = {
          userId: user.id,
          organizationId: selectedOrg.id,
          organizationImageUrl: url,
        };
        await uploadLogoDoc(docData);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleSubmit = async () => {
    if (!selectedOrg.id) {
      dispatch(toast('error', 'Organization not found'));
      return;
    }

    // build optin data for member and nonMember headers
    const [memberOptinData, nonMemberOptinData] = ['member', 'nonMember'].map(
      type =>
        buildOptinData({
          content:
            type === 'member' ? memberOptinHeaderMsg : nonMemberOptinHeaderMsg,
          contactType: type,
        })
    );

    // save both the member and nonmember optin data objects
    const saveOptinPromises = [memberOptinData, nonMemberOptinData].map(
      optinData => saveOptInContent({ data: optinData })
    );

    setIsLoading(true);
    const [didSaveMember, didSaveNonMember] = await Promise.all(
      saveOptinPromises
    );

    if (!didSaveMember || !didSaveNonMember) {
      dispatch(toast('error', 'Error saving content'));
      setIsLoading(false);
      return;
    }

    await uploadLogo();

    // reset the save button after saving
    setMadeEdits(false);
    setChangedLogo(false);
    setFile('');
    setIsLoading(false);
    dispatch(toast('success', 'Org Preferences Saved'));
  };

  const saveShouldBeActive = useMemo(() => {
    // make sure that we have time for the file snapshot to come back before allowing save
    return madeEdits || (changedLogo && file);
  }, [madeEdits, changedLogo, file]);

  return (
    <>
      <Row>
        <Col span={15}>
          <div>
            <div>
              <PrefURL
                label={`${selectedOrg.acronym} Universal Preference Page URL`}
                url={`${process.env.REACT_APP_webUrl}/optinmsg/${selectedOrg.acronym}?org=${selectedOrg.hashId}`}
              />
            </div>
            <div style={{ paddingTop: '45px' }}>
              <OptInEditor
                optinMessage={nonMemberOptinHeaderMsg}
                label={'Welcome Header (Nonmember Version)'}
                onTextChange={newContent => {
                  if (!isLoading) {
                    setNonMemberOptinHeaderMsg(newContent);
                    setMadeEdits(true);
                  }
                }}
              />
            </div>
            <div style={{ paddingTop: '30px' }}>
              <OptInEditor
                optinMessage={memberOptinHeaderMsg}
                label={'Welcome Header (Member Version)'}
                onTextChange={newContent => {
                  if (!isLoading) {
                    setMemberOptinHeaderMsg(newContent);
                    setMadeEdits(true);
                  }
                }}
              />
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div style={{ paddingTop: '50px', paddingLeft: '50px' }}>
            <Trophy style={{ height: '15px' }} color={'#007ade'} />
            <h1
              style={{
                display: 'inline-flex',
                paddingLeft: '10px',
                color: '#007ade',
              }}
            >
              Logo
            </h1>
            <Upload
              setIsLoading={setIsLoading}
              setChangedLogo={setChangedLogo}
              organization={selectedOrg}
              user={user}
              file={file}
              setFile={setFile}
            />
          </div>
        </Col>
      </Row>
      <div style={{ paddingTop: '30px' }}>
        <Button
          label="Save Changes"
          style={{
            backgroundColor: !saveShouldBeActive ? 'gray' : '#5eab00',
            color: saveShouldBeActive && 'white',
            border: 'none',
            padding: '10px',
          }}
          disabled={!saveShouldBeActive}
          type="submit"
          onClick={() => handleSubmit()}
        />
      </div>
    </>
  );
};

export default PreferencePage;
