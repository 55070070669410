import { Color, Variant } from './types';
import logoInPrimaryVariantInPrimaryColor from './logo.v-primary.c-primary.svg';
import logoInPrimaryVariantInLightColor from './logo.v-primary.c-light.svg';
import logoInIconVariantInPrimaryColor from './logo.v-icon.c-primary.svg';
import logoInIconVariantInLightColor from './logo.v-icon.c-light.svg';

const LOGO_URLS = {
  [Variant.PRIMARY]: {
    [Color.PRIMARY]: logoInPrimaryVariantInPrimaryColor,
    [Color.LIGHT]: logoInPrimaryVariantInLightColor,
  },
  [Variant.ICON]: {
    [Color.PRIMARY]: logoInIconVariantInPrimaryColor,
    [Color.LIGHT]: logoInIconVariantInLightColor,
  },
};

const getLogoUrl = ({ variant, color }) => LOGO_URLS[variant][color];

export { getLogoUrl };
