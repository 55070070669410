import styled from 'styled-components';

const Wrapper = styled.div`
  height: calc(100% - 60px);
  padding: 12px 24px 24px 24px;
  display: flex;
  flex-flow: column nowrap;
`;

export default Wrapper;
