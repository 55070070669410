import './forgotpass.css';
import 'antd/dist/antd.css';

// import { User } from "grommet-icons";
import { Button, Form, Input } from 'antd';
import { Box, Grommet } from 'grommet';
import { grommet } from 'grommet/themes';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getLogoUrl, Variant, Color } from '../../infrastructure/ui/logo';
import * as actions from '../../redux/action';
import { gAnalytics } from '../../redux/helpers/index';
import * as services from '../../redux/services';

// const FormItem = Form.Item;
class Forgetpwd extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleFinish.bind(this);
  }

  componentDidMount() {
    gAnalytics('pageview', '/forgot-password');
  }

  handleFinish = async values => {
    // eslint-disable-next-line import/namespace
    services.forgotPassword(values.email, didSendReset => {
      if (didSendReset) {
        this.props.actions.toast(
          'success',
          'Password reset sent to this email.'
        );
        this.props.history.push('/login');
      } else {
        this.props.actions.toast('error', 'Sorry, an error occurred.');
      }
    });
  };

  render() {
    return (
      <div>
        <Grommet theme={grommet} className="loginsec">
          <div>
            <img
              className="nrLogo"
              src={getLogoUrl({ variant: Variant.PRIMARY, color: Color.LIGHT })}
              alt="Noterouter logo"
            />
          </div>
          <Box className="forgotfirst">
            <p className="firsttext"> Forgot Password </p>
            <p className="secondtext">
              {' '}
              Please enter your email address you registered with to resend your
              password:
            </p>
            <Form
              onFinish={this.handleFinish}
              className="login-form loginFormNew"
            >
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your registered E-mail!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <div className="textcenter">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  SEND
                </Button>
              </div>
              <Link to="/login">
                <p className="textcenter frgetpwd">
                  Back to Login ?<span>Click here</span>
                </p>
              </Link>
            </Form>
          </Box>
        </Grommet>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

const WrappedForgot = Forgetpwd;

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForgot);
