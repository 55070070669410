import React, { useMemo } from 'react';

import { round } from '../../../libs/js-utils';
import { Container, Progress, Text } from './RateMeter.styled';

const chooseBreakpoint = (value, breakpoints) => {
  const valueProgress = value * 100;
  const matchingBreakpoints = breakpoints.filter(
    breakpoint => breakpoint <= valueProgress
  );

  if (matchingBreakpoints.length === 0) {
    throw new Error(
      "No matching breakpoint is found. Every breakpoint is higher than the provided value. Please, don't forget to provide '0' breakpoint."
    );
  }

  return Math.max(...matchingBreakpoints);
};

const RateMeter = ({
  value,
  breakpoints: breakpointColors,
  targetValue = 1,
}) => {
  const formattedValue = useMemo(() => {
    if (value === null) {
      return '-';
    }

    const rateInPercents = value * 100;
    const roundedRateInPercents = round(rateInPercents, 1);
    return `${roundedRateInPercents}%`;
  }, [value]);

  const progressInPercents = useMemo(() => {
    if (value >= targetValue) {
      return 100;
    }

    return (value / targetValue) * 100;
  }, [targetValue, value]);

  const color = useMemo(() => {
    const breakpoints = Object.keys(breakpointColors).map(breakpointKey =>
      Number(breakpointKey)
    );
    const matchingBreakpoint = chooseBreakpoint(value, breakpoints);
    return breakpointColors[matchingBreakpoint];
  }, [breakpointColors, value]);

  return (
    <Container>
      <Text>{formattedValue}</Text>
      <Progress
        variant="determinate"
        value={progressInPercents}
        color={color}
      />
    </Container>
  );
};

export default RateMeter;
