const timezoneConfigs = [
  {
    zone: 'HST',
    vsUTC: 10,
    region: 'Pacific/Honolulu',
    entry: 'Hawaiian Time',
  },
  {
    zone: 'PST',
    vsUTC: 8,
    region: 'America/Los_Angeles',
    entry: 'Pacific Time',
  },
  { zone: 'MST', vsUTC: 7, region: 'America/Denver', entry: 'Mountain Time' },
  {
    zone: 'MST',
    vsUTC: 7,
    region: 'America/Phoenix',
    entry: 'Mountain Time (No Daylight Saving)',
  },
  { zone: 'CST', vsUTC: 6, region: 'America/Chicago', entry: 'Central Time' },
  { zone: 'EST', vsUTC: 5, region: 'America/New_York', entry: 'Eastern Time' },
  { zone: 'PST', vsUTC: 8, region: 'America/Los_Angeles', entry: 'Other' },
];

export const getTimeZoneObject = zoneString => {
  const timezoneConfig = timezoneConfigs.find(zone => {
    return zone.entry === zoneString;
  });

  // default to Los Angeles
  if (!timezoneConfig) {
    return {
      region: 'America/Los_Angeles',
      vsUTC: 8,
      zone: 'PST',
    };
  }
  const { region, vsUTC, zone } = timezoneConfig;
  return {
    region,
    vsUTC,
    zone,
  };
};
