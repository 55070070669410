import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { Select } from 'antd';

export const Container = styled.div`
  width: inherit;
`;

export const Header = styled.div`
  color: white;
  font-size: 15px;
  height: 60px;
  width: inherit;
  background: #005092;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 9;
  padding-right: 20px;
`;

export const SaveBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: none;
  font-size: 14px;
  padding: 3px 20px;
  color: #005092;
  transition: all 0.3s ease-in;
  border-radius: 20px;
  &:hover,
  &.active {
    color: white;
    cursor: pointer;
    background-color: #238b22;
  }
`;

export const Group = styled.span`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const HeaderButtonGroup = styled.span`
  display: flex;
  align-items: center;
`;

export const Title = styled.span`
  color: white;
`;

export const Content = styled.div`
  width: inherit;
  padding: 20px;
  margin-top: 60px;
`;

export const SubHeader = styled.div`
  height: 20px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const HeaderActionButton = styled(IconButton)`
  color: white !important; //Important is needed to override MUI styles with styled-components
  padding: 3px !important;
  margin-right: 10px !important;
  border: 1px solid white !important;
`;

export const ResyncButton = styled.span`
  color: #007ade;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
`;

export const ListSelect = styled(Select)`
  width: 90%;
  border-radius: 4px;
`;
