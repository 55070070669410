import styled from 'styled-components';
import Alignment from './Alignment';

const JUSTIFY_CONTENT_BY_ALIGNMENT = {
  [Alignment.CENTER]: 'center',
  [Alignment.LEFT]: 'flex-start',
  [Alignment.RIGHT]: 'flex-end',
};

const BaseCell = styled.td`
  display: flex;
  justify-content: ${({ alignment }) =>
    JUSTIFY_CONTENT_BY_ALIGNMENT[alignment]};
  grid-column: span ${({ span }) => span};
`;

const BaseRow = styled.tr`
  display: grid;
  grid-template-columns: repeat(var(--total-span), 1fr);
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid transparent;
`;

export const Container = styled.table`
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;

  --total-span: ${({ totalSpan }) => totalSpan};
`;

export const Header = styled(BaseRow).attrs({ as: 'thead' })`
  margin-bottom: 8px;
`;

Header.Cell = styled(BaseCell)`
  color: #616161;
  font-size: 14px;
  line-height: 1.3;
  font-family: 'Montserrat', sans-serif;
`;

export const Body = styled.tbody`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Row = styled(BaseRow)`
  padding-top: 20px;
  padding-bottom: 20px;
  border-color: #d1d1d1;
  border-radius: 5px;
`;

export const Cell = styled(BaseCell)``;

export const Footer = styled(BaseRow).attrs({ as: 'tfoot' })`
  margin-top: 24px;
`;

Footer.Cell = styled(BaseCell)`
  padding: 10px 5px;
  border-top: 1px solid
    ${({ hasSeparator }) => (hasSeparator ? '#a6a6a6' : 'transparent')};
`;
