import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  width: 580px;
  height: 550px;
  border-radius: 32% 68% 72% 28% / 30% 41% 59% 70%;
`;

export const Title = styled.span`
  font-size: 30px;
  margin: 20px 0;
`;

export const Text = styled.span`
  font-size: 16px;
  width: 440px;
  margin-bottom: 15px;
`;

export const Button = styled.button`
  color: #007ade;
  font-size: 14px;
  padding: 4px 20px;
  margin: 30px 0 60px;
  border: 1px solid #2087e1;
  border-radius: 4px;
  transition: all 0.3s ease-in;
  background-color: #e6f5fd;
  &:hover {
    color: white;
    cursor: pointer;
    background-color: #007ade;
    border: 1px solid #2087e1;
  }
`;
