import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TextField } from '@material-ui/core';
import { AddCircleOutline, CancelOutlined } from '@material-ui/icons';
import SelectDropDown from '../_shared/selectDropdown';
import Container from '../_shared/container';
import Text from '../_shared/text';
import Wrap from '../_shared/wrap';
import Bold from '../_shared/bold';
import Content from '../../../_shared/content';
import FitContent from '../_shared/fitContent';
import Space from '../_shared/space';
import {
  addFilter,
  removeFilter,
  toast,
} from '../../../../../../../../../redux/action';

const BalanceByChargeCode = ({ filterName, checked, selectedFilters }) => {
  const dispatch = useDispatch();

  const [chargeCode, setChargeCode] = useState('');
  const [balanceType, setBalanceType] = useState('');
  const [balanceByChargeCode, setBalanceByChargeCode] = useState(
    selectedFilters || []
  );

  useEffect(() => {
    if (balanceByChargeCode.length) {
      dispatch(addFilter({ [filterName]: balanceByChargeCode }));
    } else if (
      balanceByChargeCode.length === 0 &&
      selectedFilters &&
      selectedFilters.length !== 0
    ) {
      dispatch(removeFilter(filterName));
    }
  }, [balanceByChargeCode]);

  useEffect(() => {
    if (!checked && balanceByChargeCode.length) {
      dispatch(removeFilter(filterName));
    }
  }, [checked]);

  const handleChargeCodeChange = ({ target }) => {
    setChargeCode(target.value);
  };
  const handleBalanceTypeChange = ({ target }) => {
    const parsedBalanceType = target.value === 'an unpaid' ? 'unpaid' : 'paid';
    setBalanceType(parsedBalanceType);
  };

  const handleAddBalanceByChargeCode = () => {
    const errorMessage = [];
    if (!chargeCode) {
      errorMessage.push('Please add charge code.');
    }
    if (!balanceType) {
      errorMessage.push('Please select balance type.');
    }

    if (errorMessage.length) {
      dispatch(toast('error', errorMessage.join('\n')));
    } else {
      setBalanceByChargeCode([
        ...balanceByChargeCode,
        {
          chargeCode,
          balanceType,
        },
      ]);
      setChargeCode('');
      setBalanceType('');
    }
  };

  const handleRemoveBalanceByChargeCode = index => {
    setBalanceByChargeCode([
      ...balanceByChargeCode.slice(0, index),
      ...balanceByChargeCode.slice(index + 1),
    ]);
  };

  return (
    <Container>
      {(selectedFilters || []).map(({ chargeCode, balanceType }, index) => (
        <Wrap key={index}>
          <FitContent>
            {index > 0 ? <Space>or</Space> : <Space>-</Space>} Charge Code
          </FitContent>
          <Bold>{chargeCode}</Bold>
          <FitContent>with {balanceType === 'unpaid' ? 'an' : 'a'}</FitContent>
          <Bold>{balanceType}</Bold>
          <FitContent>balance</FitContent>
          <CancelOutlined
            fontSize="small"
            style={{ fill: '#E63946' }}
            onClick={() => handleRemoveBalanceByChargeCode(index)}
          />
        </Wrap>
      ))}
      <Content>
        <Text>{balanceByChargeCode.length ? 'or' : ''} Charge Code</Text>
        <TextField
          value={chargeCode}
          onChange={handleChargeCodeChange}
          style={{ width: 60 }}
        />
      </Content>
      <Content>
        <Text>with</Text>
        <SelectDropDown
          value={(() => {
            if (!balanceType) return balanceType;
            return balanceType === 'unpaid' ? 'an unpaid' : 'a paid';
          })()}
          menuItemNames={['an unpaid', 'a paid']}
          onChange={handleBalanceTypeChange}
        />
        <Text>balance</Text>
      </Content>
      <Button
        variant="contained"
        size="small"
        startIcon={<AddCircleOutline />}
        style={{
          borderRadius: 50,
          backgroundColor: '#007ADE',
          margin: '0 25px',
          padding: '5px 15px',
          color: 'white',
        }}
        onClick={handleAddBalanceByChargeCode}
      >
        Add
      </Button>
    </Container>
  );
};

export default BalanceByChargeCode;
