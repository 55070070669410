import { Button, Col, Input, Row } from 'antd';
import { Box } from 'grommet';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  fullloader,
  toast,
  userOrganization,
} from '../../../../../../redux/action';
import PaperForm from './PaperForm';
import {
  saveOptinContent,
  uploadLogoDoc,
  createOrganization,
  createGroup,
  updateKeyOrgContacts,
} from '../../../../../../redux/services';
import { userDetailSelector } from '../../../../../../redux/selectors/user';

const PaperFormSubmission = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [paperFormSubmissionId, setPaperFormSubmissionId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const user = useSelector(userDetailSelector);

  const convertPhoneStringToNumber = phone =>
    parseInt(phone.replace(/\D/g, ''));

  useEffect(() => {
    dispatch(fullloader(isLoading, loadingMessage));
  }, [isLoading, loadingMessage]);

  const createOrgFromPaperFormData = async () => {
    setIsLoading(true);
    setLoadingMessage('(1/7) fetching data...');

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_paperformAPI}?submissionId=${paperFormSubmissionId}`
      );
      const data = response.data;
      const orgName = data.organizationName;

      // create the organization
      setLoadingMessage(`(2/7) ${orgName}: uploading...ORG DATA`);
      const paperForm = new PaperForm(data);
      const newOrg = await createOrganization(paperForm.orgData);
      const keyContacts = paperForm.keyContacts.map(contact => ({
        ...contact,
        phoneNumber: convertPhoneStringToNumber(contact.phoneNumber),
      }));
      //upload the logo for the new org if user uploaded one
      if (paperForm.orgData.logoData) {
        await uploadLogoDoc({ ...paperForm.orgData.logoData, userId: user.id });
      }
      setLoadingMessage(`(2/7) ${orgName}: ORG CREATION SUCCESS!`);

      // upload groups data
      setLoadingMessage(`(3/7) ${orgName}:  uploading...GROUPS DATA`);
      const groupsData = await paperForm.groupsData;
      const createGroupPromises = groupsData.map(async _ => {
        await createGroup(_);
      });
      await Promise.all(createGroupPromises);
      setLoadingMessage(`(3/7) ${orgName}: GROUPS DATA CREATION SUCCESS!`);

      // upload topics data
      setLoadingMessage(`(4/7) ${orgName}:  uploading...TOPICS DATA`);
      const createTopicsPromises = paperForm.topicsData.map(async _ => {
        await createGroup(_);
      });
      await Promise.all(createTopicsPromises);
      setLoadingMessage(`(4/7) ${orgName}: TOPICS DATA CREATION SUCCESS!`);

      // set preference defaults
      setLoadingMessage(`(5/7) ${orgName}:  setting up...PREFERENCE DEFAULTS`);
      const optinContents = [
        {
          content:
            "Use this page to pick the industry information you'd like to stay current on - and thank you for being a proactive member with us!",
          contactType: 'member',
        },
        {
          content:
            'Use this page to tell us what you need from our organization and how you want it sent to you. For more valuable and privileged industry information, including for our local market, contact us about becoming a full member today!',
          contactType: 'nonMember',
        },
      ];

      const saveOptinPromises = optinContents.map(async content => {
        await saveOptinContent({
          id: paperForm.orgId,
          data: { ...content, organizationId: paperForm.orgId },
        });
      });

      const createdKeyContacts = await updateKeyOrgContacts(keyContacts);

      if (createdKeyContacts) {
        setLoadingMessage('(6/7) Added key contacts successfully!');
      }

      await Promise.all(saveOptinPromises);
      setLoadingMessage(`(7/7) ${orgName}: DONE!`);
      setIsLoading(false);
      dispatch(userOrganization(newOrg));
      history.push('/dashboard/organization');
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      dispatch(toast('error', 'Check Submission Id'));
    }
  };

  return (
    <Row style={{ marginBottom: '20px' }}>
      <Col>
        <Box direction="row" gap="medium">
          <Input
            onChange={e => {
              setPaperFormSubmissionId(e.target.value);
            }}
            onReset={() => setPaperFormSubmissionId('')}
            id="text-input-id"
            placeholder="Enter Submission ID"
          />
          <Button
            disabled={!paperFormSubmissionId}
            onClick={() => {
              createOrgFromPaperFormData(paperFormSubmissionId);
            }}
          >
            Submit
          </Button>
        </Box>
      </Col>
    </Row>
  );
};

export default PaperFormSubmission;
