import styled from 'styled-components';

const DialogTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  color: #fff;
  width: 550px;
  background-color: #005092;
  padding: 20px 24px;
  font-size: 16px;
`;

export default DialogTitle;
