import React, { useState } from 'react';
import {
  Drawer,
  IconButton,
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { _union } from 'underscore-es';
import { v4 as uuidv4 } from 'uuid';
import { useToast, useUpdateUIState } from '../../../../../redux/action';
import { addGroupsToContacts } from '../../../../../redux/services/groups';
import {
  Container,
  Header,
  SaveBtn,
  Title,
  Group,
} from '../EditAPIConnectionDrawer/EditAPIConnectionDrawer.styled';
import {
  Title as ContentTitle,
  Center,
} from '../../GeneralInfo/GeneralInfo.styled';
import { HalfContainer, Content } from './GroupMappingDrawer.styled';

const GroupMappingDrawer = ({
  data = {},
  closeDrawer,
  isGroupMappingDrawerOpen,
  setGroupMappingDrawerOpen,
  apiFields,
  groupList,
  topics,
  orgId,
  fetchGroupMaps,
}) => {
  const { fullLoader } = useUpdateUIState();
  const { errorToast, successToast } = useToast();
  const [APIFieldValues, setAPIFieldValues] = useState(data?.values || []);
  const [selectedTopicIds, setSelectedTopicIds] = useState(
    data?.emailTags || []
  );
  const [selectedGroupIds, setSelectedGroupIds] = useState(
    data?.identifiers || []
  );

  const handleAPIFieldCheckboxChange = (isChecked, data) => {
    let updatedValues;
    if (isChecked) {
      updatedValues = _union(APIFieldValues, [data]);
    } else {
      updatedValues = APIFieldValues.filter(
        value => value.fieldValue !== data.fieldValue
      );
    }
    setAPIFieldValues(updatedValues);
  };

  const handleTopicCheckboxChange = (isChecked, topicId) => {
    let topicIds = [...selectedTopicIds];
    if (isChecked) {
      topicIds.push(topicId);
    } else {
      topicIds = topicIds.filter(id => id !== topicId);
    }
    setSelectedTopicIds(topicIds);
  };

  const handleGroupCheckboxChange = (isChecked, groupId) => {
    let groupIds = [...selectedGroupIds];
    if (isChecked) {
      groupIds.push(groupId);
    } else {
      groupIds = groupIds.filter(id => id !== groupId);
    }
    setSelectedGroupIds(groupIds);
  };

  const removeUnnecessaryAPIFields = () => {
    return APIFieldValues.map(field => ({
      fieldValue: field.fieldValue,
      fieldName: field.fieldName,
      displayName: field.displayName,
    }));
  };

  const applyGroups = async () => {
    setGroupMappingDrawerOpen(false);
    fullLoader(true, 'Applying and saving group map.');

    const applyGroupOptions = {
      orgId,
      id: data?.id || uuidv4(),
      values: removeUnnecessaryAPIFields(),
      selectedTags: selectedGroupIds,
      selectedTopics: selectedTopicIds,
      updated: data?.id ? true : false,
      type: 'Membership',
    };
    const updatedMembers = await addGroupsToContacts(applyGroupOptions);
    if (!updatedMembers.error) {
      successToast(
        data?.id
          ? `Map applied successfully to ${updatedMembers} contact(s)`
          : 'Map updated and refreshed all contact groups'
      );
      fetchGroupMaps();
    } else {
      errorToast('Error adding groups to contacts');
    }
    fullLoader(false);
  };

  return (
    <Drawer
      anchor={'right'}
      onClose={closeDrawer}
      open={isGroupMappingDrawerOpen}
    >
      <Container>
        <Header>
          <Group>
            <IconButton onClick={closeDrawer} style={{ color: 'white' }}>
              <Close />
            </IconButton>
            <Title>{data?.id ? 'Editing' : 'Creating'}: Group Mappings</Title>
          </Group>
          <SaveBtn onClick={applyGroups}>Apply</SaveBtn>
        </Header>
        {!Object.keys(apiFields || {}).length && (
          <Center>
            <CircularProgress
              style={{
                margin: 'auto',
                alignItems: 'center',
                color: '#007ADE',
              }}
            />
          </Center>
        )}
        {apiFields && Object.keys(apiFields).length && (
          <Content>
            <HalfContainer>
              <ContentTitle>API Data Points</ContentTitle>
              {Object.keys(apiFields).map((field, i) => (
                <FormControl
                  key={i}
                  component="fieldset"
                  style={{ marginTop: '10px' }}
                >
                  <FormLabel
                    component="legend"
                    style={{ textDecoration: 'underline' }}
                  >
                    {field}
                  </FormLabel>
                  <FormGroup>
                    {apiFields[field].map(value => {
                      const isChecked = APIFieldValues.some(
                        ({ fieldValue }) => fieldValue === value.fieldValue
                      );

                      return (
                        <FormControlLabel
                          key={value.key}
                          control={
                            <Checkbox
                              size="small"
                              color="primary"
                              value={value}
                              onChange={event =>
                                handleAPIFieldCheckboxChange(
                                  event.target.checked,
                                  value
                                )
                              }
                              checked={isChecked}
                            />
                          }
                          label={`${value.fieldValue} => (${value.fieldValueCount})`}
                        />
                      );
                    })}
                  </FormGroup>
                </FormControl>
              ))}
            </HalfContainer>
            <HalfContainer>
              <ContentTitle>Organization Groups</ContentTitle>
              <FormControl
                component="fieldset"
                style={{ marginTop: '10px' }}
                key={'topics'}
              >
                {topics && topics.length > 0 && (
                  <>
                    <FormLabel
                      component="legend"
                      style={{ textDecoration: 'underline' }}
                    >
                      Topic
                    </FormLabel>
                    <FormGroup>
                      {topics.map(topic => (
                        <FormControlLabel
                          key={topic.id}
                          control={
                            <Checkbox
                              size="small"
                              color="primary"
                              checked={selectedTopicIds.includes(topic.id)}
                              onChange={event =>
                                handleTopicCheckboxChange(
                                  event.target.checked,
                                  topic.id
                                )
                              }
                            />
                          }
                          label={topic.name}
                        />
                      ))}
                    </FormGroup>
                  </>
                )}
              </FormControl>
              {groupList &&
                Object.keys(groupList).map((type, i) => {
                  if (type !== 'Custom' && type !== 'Billing') {
                    return (
                      <FormControl
                        component="fieldset"
                        key={i}
                        style={{ marginTop: '10px' }}
                      >
                        <FormLabel
                          component="legend"
                          style={{ textDecoration: 'underline' }}
                        >
                          {type}
                        </FormLabel>
                        <FormGroup>
                          {groupList[type].map(group => (
                            <FormControlLabel
                              key={group.id}
                              control={
                                <Checkbox
                                  size="small"
                                  color="primary"
                                  value={group.id}
                                  checked={selectedGroupIds.includes(group.id)}
                                  onChange={event =>
                                    handleGroupCheckboxChange(
                                      event.target.checked,
                                      group.id
                                    )
                                  }
                                />
                              }
                              label={group.name}
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    );
                  }
                })}
            </HalfContainer>
          </Content>
        )}
      </Container>
    </Drawer>
  );
};

export default GroupMappingDrawer;
