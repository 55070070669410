export const unreadTextMessagesCountSelector = state =>
  state.textMessages.unreadCount;

export const lastTextMessageConversationIdSelector = state =>
  state.textMessages.lastMsgConversationId;

export const lastMessageIdSentByOrg = state =>
  state.textMessages.lastMsgIdSentByOrg;

export const lastMessageIdSentByUser = state =>
  state.textMessages.lastMsgIdSentByUser;

export const textingHubViewers = state => state.textMessages.textingHubViewers;
