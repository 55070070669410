import { Popover, Tag } from 'antd';
import React from 'react';

export const TagColummns = toggleUniqueMemberDrawer => {
  return [
    {
      headerName: 'Group',
      field: 'name',
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: 'Description',
      field: 'description',
      minWidth: 275,
      flex: 3,
    },
    {
      headerName: 'Group Type',
      field: 'groupTypeName',
      minWidth: 100,
      flex: 0.7,
      // eslint-disable-next-line react/display-name
      renderCell: params => {
        return <>{params.row.groupTypeName}</>;
      },
    },
    {
      headerName: 'Unique Contacts',
      field: 'subscribedMembers',
      minWidth: 100,
      flex: 0.75,
      // eslint-disable-next-line react/display-name
      renderCell: params => {
        return (
          <Popover
            placement="left"
            className=""
            content={<div>Click for full member list</div>}
          >
            <Tag
              className="manage-tags-count-container"
              color="blue"
              onClick={e => toggleUniqueMemberDrawer(e, params.row)}
            >
              {params.row.subscribedMembers}
            </Tag>
          </Popover>
        );
      },
    },
  ];
};
