import React from 'react';
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Collapse,
} from '@material-ui/core';
import Row from '../Table/row';

const GenerateRow = ({ check, setCheck, label, children }) => (
  <Row>
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={check}
            onChange={() => setCheck(!check)}
            label={label}
          />
        }
        label={label}
      />
    </FormGroup>
    <Collapse in={check} timeout="auto" unmountOnExit>
      {children}
    </Collapse>
  </Row>
);

export default GenerateRow;
