import React from 'react';

import Wrapper from './Components/Wrapper';
import Summary from './Components/Summary';
import LineHeader from './Components/LineHeader';
import ContentLine from './Components/ContentLine';
import ExportButton from './Components/ExportButton';
import ExceptionWrap from './Components/ExceptionWrap';
import ExceptionsTable from './Components/ExceptionsTable';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { CSVLink } from 'react-csv';
import { progressTypeSelector } from '../../../../redux/selectors/ui';
import { useSelector } from 'react-redux';
import { CSV_HEADERS } from './Components/Summary/constants';

const Container = ({ results }) => {
  const progressType = useSelector(progressTypeSelector);
  const getCSVHeaders = () => {
    return CSV_HEADERS[progressType];
  };

  return (
    <Wrapper>
      <Summary results={results} />
      <br />
      {results?.exceptions?.length > 0 && ( //Only display the exceptions section if there are any exceptions
        <ExceptionWrap>
          <LineHeader style={{ color: '#FF6161' }}>Exceptions:</LineHeader>
          <ContentLine>
            {results.exceptions.length}{' '}
            {results.exceptions.length === 1 ? 'item' : 'items'} had issues that
            are noted below.
          </ContentLine>
          <span>
            <CSVLink
              headers={getCSVHeaders()}
              data={results.exceptions || []}
              filename={results.filename || 'ErrorExport.csv'}
            >
              <ExportButton>
                <CloudDownloadIcon /> Export
              </ExportButton>
            </CSVLink>
          </span>

          <ExceptionsTable exceptions={results.exceptions || []} />
        </ExceptionWrap>
      )}
    </Wrapper>
  );
};

export default Container;
