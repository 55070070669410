import React from 'react';
import { connect } from 'react-redux';
import Header from './Components/Table/header';
import Container from './Components/Table/container';
import ShowMembershipFilters from './Components/ShowMembershipFilters';
import ShowBillingFilters from './Components/ShowBillingFilters';
import ShowDateFilters from './Components/ShowDateFilters';
import ShowGeoFilters from './Components/ShowGeoFilters';
import ShowEventsAndClassesFilters from './Components/ShowEventsAndClassesFilters';
import {
  MEMBERSHIP,
  BILLING,
  DATES,
  GEOGRAPHIC,
  EVENT_AND_CLASSES,
} from './Components/_shared/constants';

const Filters = ({ selectedSubMenuItemName }) => {
  return (
    <Container>
      <Header>{selectedSubMenuItemName} filters</Header>
      {selectedSubMenuItemName === MEMBERSHIP && <ShowMembershipFilters />}
      {selectedSubMenuItemName === BILLING && <ShowBillingFilters />}
      {selectedSubMenuItemName === DATES && <ShowDateFilters />}
      {selectedSubMenuItemName === GEOGRAPHIC && <ShowGeoFilters />}
      {selectedSubMenuItemName === EVENT_AND_CLASSES && (
        <ShowEventsAndClassesFilters />
      )}
    </Container>
  );
};

const mapStateToProps = state => ({
  selectedSubMenuItemName: state.audience.selectedSubMenuItemName,
});

export default connect(mapStateToProps)(Filters);
