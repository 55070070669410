import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Header = styled.div`
  display: grid;
  align-items: center;
  grid-template-areas: 'icon title';
  grid-template-columns: auto 1fr;
  grid-auto-rows: auto;
  gap: 15px 10px;
  color: #007ade;
`;

export const Icon = styled.span`
  grid-area: icon;
`;

export const Title = styled.b`
  grid-area: title;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
`;

export const Description = styled.span`
  grid-column: 1 / -1;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #616161;
`;
