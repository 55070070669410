import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import Overview from './Overview';
import Stages from './Stages';
import AudiencePicker from './AudiencePicker';
import AudienceEditor from './AudienceEditor';
import TemplatePicker from './TemplatePicker';
import TemplateEditor from './TemplateEditor';

const Step = {
  OVERVIEW: 'overview',
  STAGES: 'stages',
  AUDIENCE_PICKER: 'audience/picker',
  AUDIENCE_EDITOR: 'audience/editor',
  TEMPLATE_PICKER: 'template/picker',
  TEMPLATE_EDITOR: 'template/editor',
};

const COMPONENTS_BY_STEP = {
  [Step.OVERVIEW]: Overview,
  [Step.STAGES]: Stages,
  [Step.AUDIENCE_PICKER]: AudiencePicker,
  [Step.AUDIENCE_EDITOR]: AudienceEditor,
  [Step.TEMPLATE_PICKER]: TemplatePicker,
  [Step.TEMPLATE_EDITOR]: TemplateEditor,
};

const Context = createContext({
  getField() {
    throw new Error('Not implemented');
  },
  setField() {
    throw new Error('Not implemented');
  },
  goToStep() {
    throw new Error('Not implemented');
  },
  hasParam() {
    throw new Error('Not implemented');
  },
  getParam() {
    throw new Error('Not implemented');
  },
  getData() {
    throw new Error('Not implemented');
  },
});

const useSteps = initialStep => {
  const [activeStepContext, setActiveStepContext] = useState({
    step: initialStep,
    params: {},
  });

  const goToStep = useCallback((step, params) => {
    setActiveStepContext({ step, params: params ?? {} });
  }, []);

  const hasParam = useCallback(
    name => {
      return Object.prototype.hasOwnProperty.call(
        activeStepContext.params,
        name
      );
    },
    [activeStepContext.params]
  );

  const getParam = useCallback(
    name => {
      if (!hasParam(name)) {
        throw new Error(`Param with name '${name}' is not provided`);
      }

      return activeStepContext.params[name];
    },
    [activeStepContext.params, hasParam]
  );

  return {
    step: activeStepContext.step,
    goToStep,
    hasParam,
    getParam,
  };
};

const CampaignEditor = ({
  initialData = {},
  confirmText,
  onConfirm,
  onTouch,
}) => {
  const { step, goToStep, getParam, hasParam } = useSteps(Step.OVERVIEW);
  const [data, setData] = useState(initialData);
  const editor = useMemo(
    () => ({
      goToStep,
      setField: (name, value) => {
        onTouch();
        setData(prevData => ({ ...prevData, [name]: value }));
      },
      getField: name => data[name],
      hasParam,
      getParam,
      getData: () => data,
    }),
    [data, getParam, goToStep, hasParam, onTouch]
  );

  const StepComponent = COMPONENTS_BY_STEP[step];
  const PROPS_BY_STEP = {
    [Step.STAGES]: { confirmText, onConfirm },
  };
  return (
    <Context.Provider value={editor}>
      <StepComponent {...PROPS_BY_STEP[step]} />
    </Context.Provider>
  );
};

export const useEditor = () => {
  return useContext(Context);
};

export default CampaignEditor;
