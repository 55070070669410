import React, { useState } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import { useTags } from '../../../redux/services/tags/tags.service';
import {
  ListStyled,
  ModalStyled,
  PermissionStyled,
  ModalFooter,
  ListItemSubText,
} from './permissions.styled';
import { SearchBox } from '../../_shared';
import { CancelButton, OkButton } from '../../Modals/modals.styled';

export const GroupPermissionModal = ({ isOpen, handleClose, onSave }) => {
  const [checked, setChecked] = useState([]);
  const [groupNames, setGroupNames] = useState([]);
  const { tags } = useTags();
  const [search, setSearch] = useState('');

  const handleToggle = (value, name) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    const newNames = [...groupNames];
    if (currentIndex === -1) {
      newChecked.push(value);
      newNames.push(name);
    } else {
      newChecked.splice(currentIndex, 1);
      newNames.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    setGroupNames(newNames);
  };

  const onClose = () => {
    setChecked([]);
    setGroupNames([]);
    handleClose();
  }

  const handleSave = () => {
    onSave(checked, groupNames);
    setGroupNames([]);
    setChecked([])
  }

  return (
    <ModalStyled open={isOpen} onClose={onClose} closeAfterTransition>
      <PermissionStyled>
        <h2>Display Conditions - Groups</h2>
        <p>Select the Groups that should see the content.</p>
        <SearchBox search={search} setSearch={setSearch} />
        <ListStyled>
          {tags
            ?.filter(value =>
              !search
                ? value
                : value.name.toLowerCase().includes(search.trim().toLowerCase())
            )
            .sort((prev, curr) => {
              if (prev.name < curr.name) {
                return -1;
              }
              if (prev.name > curr.name) {
                return 1;
              }
              return 0;
            })
            .map(({ id, name, description }) => {
              const labelId = `checkbox-list-label-${id}`;
              return (
                <ListItem
                  key={id}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(id, name)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      color="primary"
                      checked={checked.indexOf(id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <Tooltip placement="top" arrow title={description}>
                    <div>
                      <ListItemText id={labelId} primary={name} />
                      <ListItemSubText secondary={description} />
                    </div>
                  </Tooltip>
                </ListItem>
              );
            })}
        </ListStyled>
        <ModalFooter>
          <CancelButton size="medium" variant="outlined" onClick={onClose}>
            Back
          </CancelButton>
          <OkButton
            size="medium"
            variant="outlined"
            disabled={!checked?.length}
            onClick={handleSave}
          >
            Select
          </OkButton>
        </ModalFooter>
      </PermissionStyled>
    </ModalStyled>
  );
};
