import styled from 'styled-components';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';

export const AddIcon = styled(PlusCircleOutlined)`
  && {
    font-size: 70px;
    padding: 20% 20px 30%;
  }
`;

export const Title = styled.h5`
  font-size: 22px;
`;
