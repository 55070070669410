import { useMutation, useQuery } from 'react-query';
import { queryClient } from '../../infrastructure/queries';
import {
  createCampaign,
  archiveCampaigns,
  fetchCampaign,
  fetchCampaigns,
  updateCampaign,
} from './api';
import { _defaults, _uniqueId } from 'underscore-es';

export const useCampaignsQuery = (params, options) => {
  return useQuery(
    ['campaigns', 'index', params],
    () => fetchCampaigns(params),
    options
  );
};

export const useCampaignQuery = (id, options) => {
  return useQuery(['campaigns', id], () => fetchCampaign(id), options);
};

export const useUpdateCampaignMutation = options => {
  return useMutation(({ id, updates }) => updateCampaign(id, updates), {
    ...options,
    onMutate({ id, updates }) {
      const campaignQueryKey = ['campaigns', id];

      const previousCampaign = queryClient.getQueryData(campaignQueryKey);
      const normalizedUpdates = { ...updates };
      if (updates.stages) {
        normalizedUpdates.stages = updates.stages.map(stage => ({
          _id: _uniqueId('stage'),
          ...stage,
          analytics: _defaults(stage.analytics ?? {}, {
            sent: 0,
            opened: 0,
            clicked: 0,
          }),
        }));
      }
      const newCampaign = { ...previousCampaign, ...normalizedUpdates };
      queryClient.setQueryData(['campaigns', id], newCampaign);

      return { previousCampaign };
    },
    onSuccess(_, { id }) {
      queryClient.invalidateQueries(['campaigns', id]);
      queryClient.invalidateQueries(['campaigns', 'index']);
    },
    onError(_, { id }, { previousCampaign }) {
      queryClient.setQueryData(['campaigns', id], previousCampaign);
    },
  });
};

export const useCreateCampaignMutation = options => {
  return useMutation(createCampaign, {
    ...options,
    onSuccess() {
      queryClient.invalidateQueries('campaigns');
    },
  });
};

export const useArchiveCampaignsMutation = options => {
  return useMutation(archiveCampaigns, {
    ...options,
    onSuccess() {
      queryClient.invalidateQueries('campaigns');
    },
  });
};
