import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { useIsFeatureEnabled } from '../../../libs/feature-toggles/react';
import Feature from '../../../infrastructure/features';
import { getIdOfAnotherVersion } from '../../../redux/services';
import { fullloader } from '../../../redux/action';
import {
  ToggleButtonLeft,
  ToggleButtonRight,
  ToggleButtonGroup,
  EmptySpace,
} from './ABToggleButton.styled';

const VERSION_A = 'A';
const VERSION_B = 'B';
const PRIMARY = 'PRIMARY';

const ABToggleButton = ({ draftId, AB }) => {
  const dispatch = useDispatch();
  const isABFeatureEnabled = useIsFeatureEnabled(Feature.AB);
  const history = useHistory();
  const [activeVersion, setActiveVersion] = useState(AB?.version || VERSION_A);

  useEffect(() => {
    setActiveVersion(AB?.version || VERSION_A);
  }, [AB]);
  const handleVersionChange = async version => {
    dispatch(fullloader(true, 'Switching versions'));
    setActiveVersion(version);
    const { _id } = await getIdOfAnotherVersion(draftId);
    history.replace(`/dashboard/preview?draft=${_id}`);
  };

  const getVersion = version => {
    return activeVersion === version && PRIMARY;
  };

  return (
    <>
      {(isABFeatureEnabled && AB && (
        <Row gutter={16} style={{ padding: '0px' }}>
          <Col span={18} className="gutter-row">
            <ToggleButtonGroup>
              <ToggleButtonLeft
                version={getVersion(VERSION_A)}
                aria-label="A"
                onClick={() => handleVersionChange(VERSION_A)}
              >
                A
              </ToggleButtonLeft>
              <ToggleButtonRight
                version={getVersion(VERSION_B)}
                aria-label="B"
                onClick={() => handleVersionChange(VERSION_B)}
              >
                B
              </ToggleButtonRight>
            </ToggleButtonGroup>
          </Col>
        </Row>
      )) || <EmptySpace />}
    </>
  );
};

export default ABToggleButton;
