import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { AddCircleOutline, CancelOutlined } from '@material-ui/icons';
import SelectDropDown from '../_shared/selectDropdown';
import Container from '../_shared/container';
import Text from '../_shared/text';
import Wrap from '../_shared/wrap';
import Bold from '../_shared/bold';
import Content from '../../../_shared/content';
import FitContent from '../_shared/fitContent';
import Space from '../_shared/space';
import { registrationStatuses } from '../_shared/constants';
import { capitalizeFirstLetter } from '../_shared/utils';
import EventsAndClassesIdSelector from '../_shared/EventsAndClassesSelector';
import {
  addFilter,
  removeFilter,
  toast,
} from '../../../../../../../../../redux/action';
import { fetchEventIdsAndNamesService } from '../../../../../../../../../features/events-and-classes/api';

const EventsAndClassRegistrants = ({
  filterName,
  checked,
  selectedFilters,
}) => {
  const dispatch = useDispatch();
  const [registrationStatus, setRegistrationStatus] = useState('');
  const [eventOrClassId, setEventOrClassId] = useState('');
  const [totalEventAndClassRegistrations, setTotalEventAndClassRegistrations] =
    useState(selectedFilters || []);

  useEffect(() => {
    if (totalEventAndClassRegistrations.length) {
      dispatch(addFilter({ [filterName]: totalEventAndClassRegistrations }));
    } else if (
      totalEventAndClassRegistrations.length === 0 &&
      selectedFilters &&
      selectedFilters.length !== 0
    ) {
      dispatch(removeFilter(filterName));
    }
  }, [totalEventAndClassRegistrations]);

  useEffect(() => {
    if (!checked && totalEventAndClassRegistrations.length) {
      dispatch(removeFilter(filterName));
    }
  }, [checked]);

  const handleEventOrClassIdChange = eventId => {
    setEventOrClassId(eventId);
  };

  const handleRegistrationStatusChange = ({ target }) => {
    setRegistrationStatus(target.value);
  };

  const handleAddEventOrClassRegistration = () => {
    const errorMessage = [];
    if (!registrationStatus) {
      errorMessage.push('Please select registration status.');
    }
    if (!eventOrClassId) {
      errorMessage.push('Please add Event/Class ID.');
    }

    if (errorMessage.length) {
      dispatch(toast('error', errorMessage.join('\n')));
    } else {
      setTotalEventAndClassRegistrations([
        ...totalEventAndClassRegistrations,
        { registrationStatus, eventOrClassId },
      ]);
      setRegistrationStatus('');
      setEventOrClassId('');
    }
  };

  const handleRemoveEventAndClassRegistration = index => {
    setTotalEventAndClassRegistrations([
      ...totalEventAndClassRegistrations.slice(0, index),
      ...totalEventAndClassRegistrations.slice(index + 1),
    ]);
  };

  return (
    <Container>
      {(selectedFilters || []).map(
        ({ registrationStatus, eventOrClassId }, index) => (
          <Wrap key={index}>
            <FitContent>
              {index > 0 ? (
                <Space>or {registrationStatus}</Space>
              ) : (
                <Space>- {capitalizeFirstLetter(registrationStatus)}</Space>
              )}
              for Event
            </FitContent>
            <Bold>ID: {eventOrClassId}</Bold>
            <CancelOutlined
              fontSize="small"
              style={{ fill: '#E63946' }}
              onClick={() => handleRemoveEventAndClassRegistration(index)}
            />
          </Wrap>
        )
      )}
      <Content>
        <Text>{totalEventAndClassRegistrations.length ? 'or' : ''}</Text>
        <SelectDropDown
          value={registrationStatus}
          menuItemNames={registrationStatuses}
          style={{ width: 140 }}
          onChange={handleRegistrationStatusChange}
        />
        <FitContent>
          <Text>contacts for Event/Class</Text>
        </FitContent>
        <EventsAndClassesIdSelector
          eventId={eventOrClassId}
          setEventId={handleEventOrClassIdChange}
          placeholder="Event/Class ID"
          fetchDataFunc={fetchEventIdsAndNamesService}
        />
      </Content>
      <Button
        variant="contained"
        size="small"
        startIcon={<AddCircleOutline />}
        style={{
          borderRadius: 50,
          backgroundColor: '#007ADE',
          margin: '0 25px',
          padding: '5px 15px',
          color: 'white',
        }}
        onClick={handleAddEventOrClassRegistration}
      >
        Add
      </Button>
    </Container>
  );
};

export default EventsAndClassRegistrants;
