import styled from 'styled-components';

export const ExportButton = styled.button`
  color: #f97f23;
  font-size: 14px;
  padding: 0px 20px;
  border: 1px solid #fa9546;
  border-radius: 15px;
  height: 28px;
  transition: all 0.3s ease-in;
  background-color: #fff8f2;
  &:hover {
    color: white;
    cursor: pointer;
    background-color: #fa9546;
  }
`;
