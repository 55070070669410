import styled from 'styled-components';
import { Select } from 'antd';

export const CustomSelect = styled(Select)`
  .ant-select-selection-placeholder {
    opacity: 1;
    color: #007ade;
  }
  width: 165px;
  color: #007ade;
  margin-left: 10px;
  opacity: 1;

  .ant-select-selector.ant-select-selector {
    border-radius: 5px;
  }
`;
