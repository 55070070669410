import React from 'react';
import styled, { css } from 'styled-components';
import { Edit, KeyboardArrowDown } from '@material-ui/icons';
import { IconButton, TextField as MuiTextField } from '@material-ui/core';
import { Select, NumberInput, TextField } from '../../../forms';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  padding: 12px 12px;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const EditButton = styled(IconButton).attrs({
  size: 'small',
  children: <Edit fontSize="small" />,
})`
  transition-property: all;

  &:not(:hover) {
    color: #999999;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
  padding: 0 15px;
`;

export const Section = styled.div`
  display: grid;
  gap: 12px 16px;
  grid-template-areas:
    'icon title'
    '-    content';
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(2, auto);
  align-items: center;
`;

Section.Icon = styled.span`
  grid-area: icon;
  color: #007ade;
  font-size: 28px !important;
`;

Section.Title = styled.span`
  grid-area: title;
  color: #616161;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
`;

Section.Title.Highlight = styled.span`
  font-weight: 700;
  font-family: inherit;
`;

Section.Content = styled.div`
  grid-area: content;
`;

const expandedCss = css`
  transform: rotate(-90deg);
`;

export const ExpandButton = styled(KeyboardArrowDown)`
  color: #888888;
  cursor: pointer;
  transition: transform 250ms !important;

  ${({ isExpanded }) => !isExpanded && expandedCss}
`;

export const NameInput = styled(MuiTextField)`
  > .MuiInputBase-root {
    color: #444444;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;

    &::before {
      border: none !important;
    }
  }
`;

export const ConditionPickerField = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 20px;
`;

export const ConditionPicker = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

ConditionPicker.DelayInput = styled(NumberInput)`
  width: 80px;
`;

ConditionPicker.UnitSelect = styled(Select)`
  margin-left: 10px;
  width: 130px;
`;

ConditionPicker.OrderSelect = styled(Select)`
  margin-left: 15px;
  width: 120px;
`;

ConditionPicker.EventSelect = styled(Select)`
  margin-left: 5px;
  width: 150px;
`;

ConditionPicker.OptionSeparator = styled.span`
  margin: 0 8px;
  color: #616161;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
`;

ConditionPicker.OptionSelect = styled(Select)`
  width: 120px;
`;

export const TemplatePicker = styled.div`
  margin-top: 20px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TemplateName = styled(TextField).attrs({ disabled: true })`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};

  .MuiInputBase-root {
    color: #333333 !important;
    font-weight: 600;
  }
`;

export const TemplateButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
