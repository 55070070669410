import React from 'react';
import { Table, Cell } from './DateFilters.styled';
import { DateFilterRanges, DAYS_TO_TEXT, TEXT_TO_DAYS } from '../constants';

const DatesFilter = ({ selectRange, selectedRange }) => {
  return (
    <Table>
      {DateFilterRanges.map(value => (
        <Cell
          key={value}
          className={DAYS_TO_TEXT[selectedRange] === value && 'active'}
          onClick={() => {
            selectRange(TEXT_TO_DAYS[value]);
          }}
        >
          {value}
        </Cell>
      ))}
    </Table>
  );
};

export default DatesFilter;
