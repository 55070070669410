import React, { useState } from 'react';
import SearchBar from './SearchBar';
import { DataGrid } from './EventsTable.styled';

const COLUMNS = [
  {
    field: 'name',
    headerName: 'Event Name',
    flex: 0.85,
    width: 60,
    sortable: true,
  },
  {
    field: 'description',
    headerName: 'Event Description',
    flex: 1,
    minWidth: 70,
    sortable: true,
  },
  {
    field: 'date',
    headerName: 'Event Date',
    flex: 0.5,
    sortComparator: (v1, v2, param1, param2) => {
      if (!param1.value.split('/')?.[2]) return -1;
      if (!param2.value.split('/')?.[2]) return 1;

      return (
        [
          ...param1.value.split('/')[2],
          ...param1.value.split('/').slice(0, 2),
        ].join('') -
        [
          ...param2.value.split('/')[2],
          ...param2.value.split('/').slice(0, 2),
        ].join('')
      );
    },
    minWidth: 30,
    sortable: true,
  },
  {
    field: 'id',
    headerName: 'Event/Class ID',
    flex: 0.5,
    minWidth: 30,
    sortable: true,
  },
  {
    field: 'courseId',
    headerName: 'Course ID',
    flex: 0.5,
    minWidth: 30,
    sortable: true,
  },
  {
    field: 'registrations',
    headerName: 'Registrations',
    flex: 0.5,
    minWidth: 30,
    sortable: true,
  },
];

const EventsTable = ({
  data,
  isLoading,
  onEventClick,
  requestSearch,
  searchText,
}) => {
  const [pageSize, setPageSize] = useState(25);

  const handleRowClick = event => {
    onEventClick(event);
  };

  return (
    <DataGrid
      stickyHeader
      disableColumnSelector
      rows={data}
      columns={COLUMNS}
      sortingOrder={['asc', 'desc']}
      pageSize={pageSize}
      onPageSizeChange={size => setPageSize(size)}
      loading={isLoading}
      checkboxSelection
      disableSelectionOnClick
      onRowClick={handleRowClick}
      components={{ Toolbar: SearchBar }}
      componentsProps={{
        toolbar: {
          value: searchText,
          onChange: ({ target }) => requestSearch(target.value),
          clearSearch: () => requestSearch(''),
        },
      }}
    />
  );
};

export default EventsTable;
