import React, { useContext } from 'react';
import {
  Table,
  TableCell,
  TableRow,
  CellContent,
} from './preferenceTable.styled';
import { Switch } from 'antd';
import { PreferenceContext } from '../../redux/services';

export const PreferenceTable = ({
  header,
  data,
  handleChange,
  handleTextChange,
}) => {
  const { newEmailTopics, newTextTopics, member } =
    useContext(PreferenceContext);
  const firstCell = ({ name, mandatory, description }) => (
    <TableCell isColHeader>
      <p>
        {name}
        {mandatory && <span>*</span>}
      </p>
      <p className="subHeader">{description}</p>
    </TableCell>
  );

  if (!data?.length) return <></>;

  return (
    <Table>
      <TableRow isHeader>
        {header?.map(({ title, icon }, idx) => (
          <TableCell key={`${title}-${idx}`} isHeader>
            <CellContent isCenter={!!idx}>
              {icon}
              {title}
            </CellContent>
          </TableCell>
        ))}
      </TableRow>
      {data?.map((item, index) => (
        <TableRow key={index}>
          {firstCell(item)}
          <TableCell isAction isHidden={item.editEmailPreference === false}>
            <CellContent isCenter>
              <label>Email</label>
              <Switch
                disabled={item.mandatory}
                checked={newEmailTopics[item?.id]}
                checkedChildren="On"
                unCheckedChildren="Off"
                onChange={e => handleChange(e, item.id)}
              />
            </CellContent>
          </TableCell>
          <TableCell isAction isHidden={item.editTextPreference === false}>
            <CellContent isCenter>
              <label>Text</label>
              <Switch
                disabled={!member.phoneNumber?.length}
                checked={newTextTopics[item?.id]}
                checkedChildren="On"
                unCheckedChildren="Off"
                onChange={e => handleTextChange(e, item.id)}
              />
            </CellContent>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};
