import { Popover, Tag } from 'antd';
import React from 'react';

export const TopicColumns = toggleUniqueMemberDrawer => {
  return [
    {
      headerName: 'Topic',
      field: 'name',
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: 'Description',
      field: 'description',
      minWidth: 150,
      flex: 3,
    },
    {
      headerName: 'Unique Contacts',
      field: 'members',
      minWidth: 150,
      flex: 0.75,
      // eslint-disable-next-line react/display-name
      renderCell: params => {
        return (
          <span>
            <Popover
              placement="left"
              className=""
              content={<div>Click for full member list</div>}
            >
              <Tag
                className="manage-tags-count-container"
                color="blue"
                onClick={e => toggleUniqueMemberDrawer(e, params.row)}
              >
                {params.row.subscribedMembers}
              </Tag>
            </Popover>
          </span>
        );
      },
    },
  ];
};
