import React from 'react';
import ValidityIndicator from '../ValidityIndicator';
import { Container, Input } from './Field.styled';

const Field = ({
  className,
  Input: _Input,
  value,
  validate = () => true,
  isValidationVisible = false,
  ...otherProps
}) => {
  const isValid = validate(value);

  return (
    <Container className={className}>
      <Input as={_Input} value={value} {...otherProps} />
      <ValidityIndicator isVisible={isValidationVisible} isValid={isValid} />
    </Container>
  );
};

export default Field;
