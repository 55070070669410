import styled from 'styled-components';

const Container = styled.main`
  display: flex;
  flex-direction: row;
  padding: ${({ withoutPadding }) => (withoutPadding ? '15px 0' : '15px')};
  min-width: 850px;
  height: 70vh;
`;

export default Container;
