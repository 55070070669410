import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  browserSessionPersistence,
  getAuth,
  setPersistence,
} from 'firebase/auth';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Dashboard from './Pages/Dashboard/Dashboard';
import forgotpass from './Pages/forgotpass/forgotpass';
import login from './Pages/login/login';
import MessagePreview from './Pages/MessagePreview/MessagePreview';
import OptinMessage from './Pages/OptinMessage';
import registration from './Pages/registration/registration';
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import store from './redux/store';

const App = () => {
  useEffect(() => {
    const auth = getAuth();
    setPersistence(auth, browserSessionPersistence);
  }, []);

  return (
    <Provider store={store}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
        useRecaptchaNet
        useEnterprise
        scriptProps={{ async: true, defer: true, appendTo: 'body' }}
      >
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={login} />
            <Route path="/registration" component={registration} />
            <Route path="/login" component={login} />
            <Route path="/optinmsg" component={OptinMessage} />
            <Route path="/forgotpass" component={forgotpass} />
            <Route path="/ResetPassword" component={ResetPassword} />
            <Route path="/dashboard" component={Dashboard} />
            <Route exact path="/message" component={MessagePreview} />
          </Switch>
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    </Provider>
  );
};

export default App;
