import styled from 'styled-components';

export const ContactNotesStyled = styled.div`
  * {
    font-family: Montserrat;
  }
  width: 100%;
  padding: 8px;
  position: relative;

  .tooltip {
    display: none;
  }

  &:hover {
    div:nth-child(3) {
      * {
        color: rgb(182 186 188) !important;
        background-color: #fff !important;
      }
    }

    .tooltip {
      position: absolute;
      display: inline-flex;
      background-color: rgba(97, 97, 97, 0.92);
      color: #fff;
      padding: 2px 8px;
      border-radius: 4px;
      font-size: 10px;
      font-weight: 500;
      vertical-align: middle;
      top: 55%;
      left: 50%;
      margin-top: auto;
      margin-bottom: auto;
      transform: translate(-50%);
    }
  }

  h3 {
    color: #6c7075;
    font-size: 14px;
    cursor: pointer;

    span {
      font-weight: 400;
    }
  }

  div:nth-child(3) {
    padding: 16px;
    font-size: 14px;
    border: 1px solid #d4d7d9;
    border-radius: 4px;
    cursor: pointer;
    color: #555555;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 120px;

    & p {
      margin-bottom: auto;
    }
  }
`;

export const ContactNotesViewStyled = styled.div`
  @media screen and (min-width: 425px) {
    padding: 24px;
    padding-top: 16px;
  }
`;
