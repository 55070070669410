import React from 'react';
import { Container, Title, Text, Button, Box } from './ErrorPage.styled';
import WarningIcon from '@material-ui/icons/Warning';

const ErrorPage = () => {
  const goBackToDashboard = () => {
    window.open('/dashboard', '_self');
  };

  return (
    <Container>
      <Box>
        <WarningIcon style={{ color: '#048FF2', fontSize: '64px' }} />
        <Title>Well, this is awkward.</Title>
        <Text>
          An error has occurred, and you&apos;ve been brought here instead of an
          ugly blank error screen.
        </Text>
        <Text>
          We&apos;re so sorry - our team has just been alerted, and we&apos;re
          probably already working on it.
        </Text>
        <Text>
          Don&apos;t hesitate to send us a note at support@noterouter.com if you
          need immediate help.
        </Text>
        <Button onClick={goBackToDashboard}>Return to dashboard</Button>
      </Box>
    </Container>
  );
};

export default ErrorPage;
