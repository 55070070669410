import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { ArrowForwardSharp } from '@material-ui/icons';
import SolidButton from './solidBtn';
import {
  selectedOrganizationSelector,
  audienceSelector,
} from '../../../../../../redux/selector';
import {
  fullloader,
  toast,
  loadTargetAudience,
  updateEmailRecipients,
  updateTextRecipients,
} from '../../../../../../redux/action';
import getMembers from '../../_shared/getMembers';

const ProceedToPreview = ({ saveAudienceToDraft, isDisabled }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const audience = useSelector(audienceSelector);
  const orgId = useSelector(selectedOrganizationSelector).id;
  const { draft: draftId } = queryString.parse(location.search);

  const getTargetAudience = async () => {
    const { emailRecipients, textRecipients, uniqueValuesCount } =
      await getMembers(audience, orgId, { preview: true });

    dispatch(loadTargetAudience(uniqueValuesCount));
    dispatch(updateEmailRecipients(emailRecipients));
    dispatch(updateTextRecipients(textRecipients));

    return uniqueValuesCount;
  };
  const proceedToPreview = async () => {
    dispatch(fullloader(true, 'Recalculating target audience'));
    const uniqueValuesCount = await getTargetAudience();
    dispatch(fullloader(false));
    saveAudienceToDraft();

    if (!uniqueValuesCount) {
      dispatch(
        toast('error', 'Target audience should have at least one recipient.')
      );
    } else {
      if (draftId) {
        history.push(`/dashboard/preview?draft=${draftId}`);
      } else {
        history.push('/dashboard/drafts');
      }
    }
  };

  return (
    <SolidButton
      padding="0 28px 0 18px"
      disabled={isDisabled || audience.selectedTopicsList.length === 0}
      onClick={proceedToPreview}
    >
      Preview
      <ArrowForwardSharp
        style={{ fontSize: 12, position: 'absolute', top: 7, right: 10 }}
      />
    </SolidButton>
  );
};

export default ProceedToPreview;
