export function increaseUnreadCount() {
  return {
    type: 'INCREASE_UNREAD_COUNT',
  };
}

export function decreaseUnreadCount() {
  return {
    type: 'DECREASE_UNREAD_COUNT',
  };
}

export function resetUnreadCount(detail) {
  return {
    type: 'RESET_UNREAD_COUNT',
    detail,
  };
}

export function setLastUpdatedMessageConversationId(detail) {
  return {
    type: 'SET_LAST_MESSAGE_CONVERSATION_ID',
    detail,
  };
}

export function setTextingHubViewers(detail) {
  return {
    type: 'SET_TEXTING_HUB_VIEWERS',
    detail,
  };
}

export function setLastMessageIdSentByOrg(detail) {
  return {
    type: 'SET_LAST_MESSAGE_ID_SENT_BY_ORG',
    detail,
  };
}

export function setLastMessageIdSentByUser(detail) {
  return {
    type: 'SET_LAST_MESSAGE_ID_SENT_BY_USER',
    detail,
  };
}
