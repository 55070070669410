import styled from 'styled-components';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const StyledArrowUpwardIcon = withStyles({
  root: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#69b34c',
  },
})(ArrowUpward);

const StyledArrowDownwardIcon = withStyles({
  root: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#ff6161',
  },
})(ArrowDownward);

export const ArrowUpIcon = styled(StyledArrowUpwardIcon)``;

export const ArrowDownIcon = styled(StyledArrowDownwardIcon)``;

export const Container = styled.div`
  transition: all 0.3s ease-in;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 90px;
  &:hover {
    cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }
  @media screen and (min-width: 1800px) {
    width: 18%;
    height: 110px;
  }
  @media screen and (min-width: 2200px) {
    width: 15%;
  }
`;

export const Title = styled.div`
  font-size: 13px;
  color: #6e6e6e
  font-weight: 600;
  padding-bottom: 4px;
  @media screen and (min-width: 1800px) {
    font-size: 14px;
  }
`;

export const Statistics = styled.span`
  font-size: 24px;
  color: #555555;
  font-weight: 600;
  @media screen and (min-width: 1800px) {
    font-size: 26px;
  }
`;

export const ResendStats = styled.span`
  font-size: 11px;
  color: #707070;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  padding: 3px 3px 0;
`;

export const Bold = styled.b`
  font-size: 12px;
`;
