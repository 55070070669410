import styled from 'styled-components';

export const Table = styled.div`
  display: flex;
`;

export const Cell = styled.span`
  display: flex;
  align-items: center;
  padding: 7px 15px;
  border: 1px solid #d7d7d7;
  margin-left: -1px;
  font-weight: 500;
  line-height: 14px;
  transition: all 0.3s ease-in;
  &:hover,
  &.active {
    color: white;
    cursor: pointer;
    background-color: #2b3c58;
    border: 1px solid #2b3c58;
  }
`;
