import React from 'react';
import LineHeader from '../../LineHeader';
import ContentLine from '../../ContentLine';

const GroupingTool = ({ results }) => {
  const isOne = num => {
    //Used for changing grammar based on number
    return num === 1;
  };
  return (
    <span>
      <LineHeader>Results:</LineHeader>
      <ContentLine>
        <span>{results.totalViewed || 0} Contact(s)</span>{' '}
        {isOne(results.totalSkipped) ? 'was' : 'were'} found in NoteRouter using
        the provided list of identifiers.
      </ContentLine>
      <ContentLine>
        <span style={{ color: '#5EAB00' }}>
          {results.totalModified || 0} Contact(s)
        </span>{' '}
        were added to the Group &quot;{results.groupName}&quot;
      </ContentLine>
      <ContentLine>
        <span style={{ color: '#F28500' }}>
          {results.totalSkipped || 0} Contact(s)
        </span>{' '}
        already belonged to the Group &quot;{results.groupName}&quot;
      </ContentLine>
      <ContentLine>
        <span style={{ color: '#FF6161' }}>
          {results.totalNotFound || 0} Identifier(s)
        </span>{' '}
        {isOne(results.totalSkipped) ? 'was' : 'were'} not able to be matched to
        an existing contact in NoteRouter
      </ContentLine>
    </span>
  );
};
export default GroupingTool;
