export const getLabelBasedOnOrgType = (
  filterName,
  audienceFilters,
  orgTypeName
) => {
  return (
    audienceFilters.find(audienceFilter => audienceFilter.name === filterName)
      .inputConfig?.[orgTypeName]?.[filterName]?.name || filterName
  );
};

export const capitalizeFirstLetter = word => {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
};
