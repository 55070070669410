import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 500px;
  margin-bottom: 10px;
  background-color: #ededed;
  border-radius: 5px;
  height: calc((100vh - 90px) * 2 / 3);
  padding: 12px 15px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ededed;
  padding: 0 6px 8px 15px;
  height: 36px;
`;

export const Title = styled.div`
  font-size: 14px;
`;

export const Wrapper = styled.div`
  max-height: calc(100% - 10px);
  padding 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 13px;
  overflow: auto;
  @media screen and (min-width: 1800px) {
    gap: 14px;
  }
`;

export const Background = styled.div`
  height: calc(100% - 40px);
  background-color: #ffffff;
`;

export const Center = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
`;

export const Wrap = styled.div`
  display: flex;
  gap: 10px;
  @media screen and (min-width: 1800px) {
    gap: 14px;
  }
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 690px;
`;

export const Menu = styled.nav`
  display: flex;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  min-width: fit-content;
  align-items: center;
  gap: 8px;
  color: #007ade;
  font-size: 14px;
  padding: 0px 15px;
  border: 1px solid #2087e1;
  border-radius: 4px;
  height: 28px;
  transition: all 0.3s ease-in;
  background-color: #e6f5fd;
  &:hover {
    color: white;
    cursor: pointer;
    background-color: #007ade;
    border: 1px solid #2087e1;
  }
  &:disabled {
    color: #737373;
    border-color: #737373;
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
`;

export const MenuItem = styled.button`
  color: #1a84e0;
  font-size: 14px;
  min-width: 110px;
  border: 1px solid #53a0e7;
  border-radius: 4px;
  height: 28px;
  transition: all 0.3s ease-in;
  background-color: #f3f8fd;
  &:hover,
  &.active {
    color: white;
    background-color: #007ade;
    border: 1px solid #007ade;
  }
`;

export const EventIdMessageWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

export const MessageDetails = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
`;

export const MessageTitle = styled.span`
  font-size: 15px;
  font-weight: bold;
`;

export const MessageId = styled.span`
  padding: 0px 10px;
  font-size: 12px;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
`;

export const Highlight = styled.span`
  color: #5eab00;
  font-size: 15px;
  font-weight: bold;
`;

export const ScheduledResend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  min-width: 600px;
`;

export const Underline = styled.span`
  text-decoration: underline;
`;

export const CancelBtn = styled.span`
  color: #1683e0;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
