import { List, ListItemText, Modal } from '@material-ui/core';
import styled from 'styled-components';

export const PermissionStyled = styled.div`
  background-color: #fafafa;
  padding: 20px;
  border-radius: 5px;
  min-height: 380px;
  margin: auto;
  max-height: 630px;

  & h2 {
    color: #444444;
    margin-bottom: 0;
  }
`;

export const ModalStyled = styled(Modal)`
  display: flex;
  justify-content: center;
  align-content: center;
  @media screen and (min-width: 525px) {
    margin-left: 200px;
  }
`;

export const ListStyled = styled(List)`
  backgroung-color: #fff;
  overflow-y: auto;
  margin-bottom: 16px;
  max-height: 400px;
  min-height: 150px;

  &.MuiList-padding {
    margin-top: 16px;
  }

  & > div {
    border: 1px solid #d1d1d1;
    padding-top: 2px;
    padding-bottom: 2px;
    border-bottom: none;

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom: 1px solid #d1d1d1;
    }
  }
`;

export const ListItemSubText = styled(ListItemText)`
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 300px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 0 16px;
`;
