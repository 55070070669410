import { QueryClient } from 'react-query';
import store from '../../redux/store';
import { userDetailSelector } from '../../redux/selectors/user';
import { selectedOrganizationSelector } from '../../redux/selectors/organization';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      refetchInterval: false,
      retry: false,
    },
  },
});

const getUserId = () => {
  return userDetailSelector(store.getState()).id;
};

const getOrganizationId = () => {
  return selectedOrganizationSelector(store.getState()).id;
};

let lastUserId = getUserId();
let lastOrganizationId = getOrganizationId();

store.subscribe(() => {
  const currentOrganizationId = getOrganizationId();
  const currentUserId = getUserId();

  if (
    lastOrganizationId === currentOrganizationId &&
    lastUserId === currentUserId
  ) {
    return;
  }

  lastOrganizationId = currentOrganizationId;
  lastUserId = currentUserId;
  queryClient.clear();
});
