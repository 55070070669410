import React from 'react';
import LineHeader from '../../LineHeader';
import ContentLine from '../../ContentLine';

const ContactUpload = ({ results }) => {
  return (
    <span>
      <LineHeader style={{ color: '#007ADE' }}>Results:</LineHeader>
      <ContentLine>
        {results.totalSuccess || 0} contacts were successfully updated.
      </ContentLine>
      {results.fieldsAdded?.length > 0 && (
        <>
          <ContentLine>
            {results.fieldsAdded.length}{' '}
            {results.fieldsAdded.length > 1 ? 'fields were' : 'field was'}{' '}
            successfully added:
          </ContentLine>

          {results.fieldsAdded.map((field, i) => {
            return (
              <div
                key={i}
                style={{
                  marginLeft: '20px',
                  fontFamily: 'Montserrat, sans-serif',
                }}
              >
                - {field}
              </div>
            );
          })}
        </>
      )}
    </span>
  );
};
export default ContactUpload;
