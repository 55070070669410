import React, { useState, useEffect, useRef } from 'react';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import {
  IconButton,
  Checkbox,
  TextField,
  SwipeableDrawer,
  Box,
  LinearProgress,
} from '@material-ui/core';
import ContactDetailsDrawer from '../ContactDetailsDrawer';
import EventDetailsTable from './EventDetailsTable';
import {
  Container,
  Header,
  Content,
  Title,
  Wrap,
  Small,
  Column,
  CheckboxContainer,
  SyncDetails,
  RightFloatedDiv,
} from './EventDetailsDrawer.styled';
import { formatDate, formatEventData } from '../utils';
import { Row } from '../Events/Events.styled';
import { Col } from 'antd';
import { fetchEventById, getEventResyncStatus, resyncEvent } from '../api';
import { useDispatch } from 'react-redux';
import { toast } from '../../../redux/action';

const useStyles = makeStyles(() => ({
  textField: {
    '& input:disabled': {
      color: '#212121',
    },
  },
}));

const resyncStatusEnum = {
  pending: 'pending',
  none: 'none',
  progress: 'progress',
};

const EventDetailsDrawer = params => {
  const { onClose, contacts, isDrawerLoading } = params;
  const [event, setEvent] = useState(params.event);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [showCancelled, setShowCancelled] = useState(true);
  const [filteredContacts, setFilteredContacts] = useState(contacts);
  const [cancelledContactsCount, setCancelledContactsCount] = useState(0);
  const [containerWidth, setContainerWidth] = useState('40vw');
  const [selectedContact, setSelectedContact] = useState(null);
  const [resyncStatus, setResyncStatus] = useState(null);
  const [resyncRequested, setResyncRequested] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const mounted = useRef(false);

  useEffect(() => {
    setFilteredContacts(contacts);
    setCancelledContactsCount(
      contacts.filter(contact => contact.status === 'C').length
    );
  }, [contacts]);

  useEffect(() => {
    if (showCancelled) {
      setFilteredContacts(contacts);
    } else {
      setFilteredContacts(contacts.filter(contact => contact.status !== 'C'));
    }
  }, [showCancelled]);

  useEffect(() => {
    fetchResyncStatus();
    fetchEvent();
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    setResyncRequested(true);
    return () => {
      setResyncRequested(false);
    };
  }, [resyncRequested]);

  const openDrawer = () => {
    setDrawerOpen(true);
    enlargeCurrentDrawer();
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    setContainerWidth(null);
  };

  const enlargeCurrentDrawer = () => {
    setContainerWidth('55vw');
  };

  const openContactDetailsDrawer = contact => {
    openDrawer();
    enlargeCurrentDrawer();
    const contactDetails = contacts.find(
      currContact => currContact.contactAMSID === contact.id
    );
    setSelectedContact(contactDetails);
  };

  const getTotalRegistrants = event => {
    return (+event.numRegistered || 0) + (+event.numGuestRegistered || 0);
  };
  const startResyncJob = () => {
    resyncEvent(event)
      .then(data => {
        setResyncRequested(true);
        setResyncStatus({
          total: data?.totalImportCount,
          done: data?.skipCount,
          status: resyncStatusEnum[data?.status],
        });
        dispatch(toast('success', 'Successfully created resync job'));
        setTimeout(() => {
          fetchResyncStatus().then();
        }, 10 * 1000);
      })
      .catch(() => {
        dispatch(
          toast(
            'error',
            <>
              Sorry, there was an error resyncing Event <u>{event.name}</u> -{' '}
              <u>ID {event.id}</u>
            </>
          )
        );
      });
  };

  const wait = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  const fetchEvent = async () => {
    const eventFromAPI = await fetchEventById(event.id);
    setEvent(formatEventData(eventFromAPI));
  };

  const fetchResyncStatus = async () => {
    const data = await getEventResyncStatus(event);
    const { progress, pending, none } = resyncStatusEnum;
    if ([progress, pending].includes(data?.status)) {
      setResyncStatus({
        total: data?.totalImportCount,
        done: data?.skipCount,
        status: resyncStatusEnum[data?.status],
      });

      await wait(10 * 1000);
      if (mounted.current) return fetchResyncStatus();
    } else {
      if (resyncRequested && data?.status === none) {
        await fetchEvent();
        dispatch(
          toast(
            'success',
            <>
              Event <u>{event.name}</u> - <u>ID {event.id}</u> has resynced
              successfully.
            </>,
            5
          )
        );
      }
      setResyncStatus({
        status: resyncStatusEnum[data?.status],
      });
    }
  };

  const StyledTextField = ({ label, width, defaultValue }) => {
    return (
      <TextField
        className={classes.textField}
        disabled
        label={label}
        variant="outlined"
        size="small"
        defaultValue={defaultValue}
        style={{ width }}
      />
    );
  };

  const eventFields = (
    <>
      <StyledTextField
        label="Event Type"
        defaultValue={event.type}
        width="50%"
      />
      <StyledTextField label="Event ID" defaultValue={event.id} width="50%" />
    </>
  );

  const classFields = (
    <>
      <StyledTextField
        label="Event Type"
        defaultValue={event.type}
        width="33%"
      />
      <StyledTextField label="Class ID" defaultValue={event.id} width="33%" />
      <StyledTextField
        label="Course ID"
        defaultValue={event.courseId}
        width="33%"
      />
    </>
  );

  const description = (
    <StyledTextField
      label="Description"
      defaultValue={event.description || 'N/A'}
      width="100%"
    />
  );

  const descWithAttendance = (
    <Wrap>
      <StyledTextField
        label="Description"
        defaultValue={event.description || 'N/A'}
        width="70%"
      />
      <StyledTextField
        label="Attended"
        defaultValue={event.attendedCount}
        width="30%"
      />
    </Wrap>
  );

  return (
    <Container width={containerWidth}>
      <Header>
        <IconButton onClick={onClose}>
          <Close style={{ color: 'white' }} />
        </IconButton>
        {event.name}: Details
      </Header>
      <Content>
        <Row>
          <Col>
            <Title>Details</Title>
          </Col>
          <Col>
            <SyncDetails>
              <div>Last Synced: {event.updatedAt}</div>
              {resyncStatus?.status === resyncStatusEnum.none && (
                <RightFloatedDiv>
                  <a onClick={startResyncJob}>Resync Event</a>
                </RightFloatedDiv>
              )}
              {(resyncStatus?.status === resyncStatusEnum.pending ||
                resyncStatus?.status === resyncStatusEnum.progress) && (
                <RightFloatedDiv>
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress /> Resyncing {resyncStatus?.done}/
                    {resyncStatus?.total}
                  </Box>
                </RightFloatedDiv>
              )}
            </SyncDetails>
          </Col>
        </Row>
        <Wrap>
          <StyledTextField label="Name" defaultValue={event.name} width="70%" />
          <StyledTextField
            label="Event Date"
            defaultValue={formatDate(event.date)}
            width="30%"
          />
        </Wrap>
        {event.attendedCount ? descWithAttendance : description}
        <Wrap>
          <StyledTextField
            label="Registrants + Guests"
            defaultValue={getTotalRegistrants(event)}
            width="50%"
          />
          <StyledTextField
            label="Registration Deadline"
            defaultValue={formatDate(event.deadline)}
            width="50%"
          />
        </Wrap>
        <Wrap>{event.type == 'E' ? eventFields : classFields}</Wrap>
        <Wrap>
          <Column>
            <Title>
              Registrations: {filteredContacts.length}
              {showCancelled &&
                ` (including ${cancelledContactsCount} cancellations)`}
            </Title>
            <Small>
              Click a registrant to view their engagement for this event
            </Small>
          </Column>
          <CheckboxContainer onClick={() => setShowCancelled(!showCancelled)}>
            <Checkbox
              checked={showCancelled}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            Show Cancelled
          </CheckboxContainer>
        </Wrap>
        <EventDetailsTable
          data={filteredContacts}
          openDrawer={openContactDetailsDrawer}
          isLoading={isDrawerLoading}
        />
      </Content>
      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={closeDrawer}
        onOpen={openDrawer}
      >
        <ContactDetailsDrawer
          event={event}
          onClose={closeDrawer}
          contact={selectedContact}
        />
      </SwipeableDrawer>
    </Container>
  );
};

export default EventDetailsDrawer;
