import styled from 'styled-components';
import img from '../../Images/home/pp-background.png';

export const MainContainer = styled.div`
  display: grid;
  background-color: #e5f1fc;

  ${({ hasUserInfo }) =>
    !hasUserInfo
      ? `
    background-image: url(${img});
    height: unset !important;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100vh !important;
    background-position: center;
    `
      : `
    height: unset !important;
  `};

  * {
    font-family: 'Montserrat';
  }
`;

export const FormContainer = styled.div`
  max-width: 866px;
  margin: 0;
  background: #ffffff;
  border-radius: 10px;
  padding: 16px;
  ${({ isVisble }) => isVisble && 'visibility: hidden;'}

  ${({ hasUserInfo }) => !hasUserInfo && 'z-index: -10;'};

  @media screen and (min-width: 525px) {
    padding: 16px 60px;
  }

  @media screen and (min-width: 769px) {
    margin: 32px auto;
  }

  @media screen and (min-width: 1440px) {
    max-width: 950px;
  }
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledHeader = styled.div`
  display: inline-flex;
  color: #616161;
`;

export const StyledContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0;

  & div {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    width: 100%;

    .MuiTextField-root > div {
      padding: 0 10px;
    }
  }

  @media screen and (min-width: 525px) {
    flex-direction: row;
  }
`;

export const SaveBtnStyled = styled.div`
  text-align: center;
  margin: 16px 0;

  .success-msg {
    margin: 8px 0;
    text-align: center;
    background: rgba(0, 128, 0, 0.72);
    color: white;
    font-size: 20px;
    border-radius: 4px;
    padding: 0 5px;

    & .roundcheck {
      margin-right: 7px;
    }
  }

  .MuiButton-root {
    max-width: 300px;
    background-color: #0076ff;
    border-radius: 8px !important;
    font-size: 18px;

    &:hover {
      background-color: #0076ff;
    }
  }

  @media screen and (min-width: 525px) {
    margin-bottom: 16px;
    margin-top: 32px;
  }
`;
