import React, { useState } from 'react';
import * as moment from 'moment';
import { convertToCSVFormat } from '../../../utils';
import CardABversion from './CardABversion';
import FailedDeliveryReport from '../../MessageAnalytics/Components/FailedDeliveryReport';

const Text = ({
  generateRatio,
  generateAverage,
  isLoadingData,
  message,
  startDate,
  textEvents,
  isDataAvailable,
}) => {
  const [isBounceDrawerVisible, setBounceDrawerVisibility] = useState(false);
  const [data, setData] = useState([]);
  const [csvData, setCSVData] = useState([]);

  const handleTextBounceRateClick = () => {
    const dataVersionA = textEvents.bounceEvents.map(bounce => ({
      ...bounce,
      time: moment.unix(bounce.time).format('MM-DD-YYYY hh:mm A'),
      contact: bounce.phone,
    }));

    const dataVersionB = textEvents.versionB.bounceEvents.map(bounce => ({
      ...bounce,
      time: moment.unix(bounce.time).format('MM-DD-YYYY hh:mm A'),
      contact: bounce.phone,
    }));

    const data = [...dataVersionA, ...dataVersionB];

    setData(data);
    setCSVData(convertToCSVFormat(data));
    setBounceDrawerVisibility(true);
  };

  return (
    <>
      <CardABversion
        name="Deliveries"
        valueA={textEvents.delivered}
        valueB={textEvents.versionB.delivered}
        generateAverage={generateAverage}
        title="The number of unique members that received a text."
        isDataAvailable={isDataAvailable}
      />
      <CardABversion
        name="Delivery Rate"
        valueA={generateRatio(textEvents.delivered, textEvents.sent)}
        valueB={generateRatio(
          textEvents.versionB.delivered,
          textEvents.versionB.sent
        )}
        generateAverage={generateAverage}
        title="The % of members successfully delivered to (Delivered / Recipients)"
        isDataAvailable={isDataAvailable}
      />
      <CardABversion
        name="Bounce Rate"
        valueA={generateRatio(textEvents.bounced, textEvents.sent)}
        valueB={generateRatio(
          textEvents.versionB.bounced,
          textEvents.versionB.sent
        )}
        generateAverage={generateAverage}
        title="Usually a result of sending to a bad phone number."
        onClick={handleTextBounceRateClick}
        isDataAvailable={isDataAvailable}
      />
      <FailedDeliveryReport
        onCloseDrawer={() => setBounceDrawerVisibility(false)}
        isVisible={isBounceDrawerVisible}
        isLoading={isLoadingData}
        message={message}
        startDate={startDate}
        data={data}
        csvData={csvData}
      />
    </>
  );
};

export default Text;
