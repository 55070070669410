import { Form, Input } from 'antd';
import React, { useEffect, useRef } from 'react';

const TestEmailForm = props => {
  let draftCache = JSON.parse(
    localStorage.getItem(`table-${window.location.href.split('?draft=')[1]}`)
  );
  const { updateParentState } = props;
  const testEmailRef = useRef(null);

  const updateCacheAndForm = async updatedTestEmail => {
    try {
      const draftId = window.location.href.split('?draft=')[1];

      if (!draftCache) {
        draftCache = { draftId, testEmail: updatedTestEmail };

        localStorage.setItem(`table-${draftId}`, JSON.stringify(draftCache));
      } else {
        draftCache.testEmail = updatedTestEmail;

        localStorage.setItem(`table-${draftId}`, JSON.stringify(draftCache));
      }

      testEmailRef.current.setFieldsValue({ testEmail: updatedTestEmail });
      await updateParentState(updatedTestEmail);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (draftCache && draftCache.testEmail) {
      updateParentState(draftCache.testEmail);

      if (testEmailRef.current) {
        testEmailRef.current.setFieldsValue({
          testEmail: draftCache.testEmail,
        });
      }
    }
    // eslint-disable-next-line
  }, [draftCache?.testEmail]);

  return (
    <Form ref={testEmailRef}>
      <Form.Item name="testEmail">
        <Input
          type="text"
          style={{ maxWidth: '300px', textAlign: 'center', marginTop: '12px' }}
          onChange={async e => await updateCacheAndForm(e.target.value)}
          placeholder="Enter your email here"
        />
      </Form.Item>
    </Form>
  );
};

export default TestEmailForm;
