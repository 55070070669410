import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import useUserAuth from '../../utils';
import { gAnalytics } from '../../redux/helpers';
import DraftEditor from './DraftEditor';
import TemplateEditor from './TemplateEditor';

const Sendmessage = () => {
  const location = useLocation();
  const history = useHistory();
  const { draft, template } = queryString.parse(location.search);

  useUserAuth();
  useEffect(() => {
    gAnalytics('pageview', '/send-message');
  }, []);

  const renderContent = () => {
    if (draft) {
      return <DraftEditor />;
    } else if (template) {
      return <TemplateEditor />;
    } else {
      history.push('/dashboard/drafts');
    }
  };

  return renderContent();
};

export default Sendmessage;
