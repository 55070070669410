import './MessagePreview.css';
import 'antd/dist/antd.css';

import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Handlebars from 'handlebars/dist/cjs/handlebars';

import * as actioncreators from '../../redux/action';
import { gAnalytics, getQueryStringValue } from '../../redux/helpers/index';
import * as services from '../../redux/services';
import { getCustomFieldsForMessageView } from '../../redux/services';
import { DisplayAlert } from '../../Component/ViewMessage/viewMessage.styled';
import { fetchACMessage } from '../../features/automated-campaigns/api';

const MessagePreview = props => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [hasDisplayConditions, setDisplayCondition] = useState(false);

  const grabContactDetails = async hashParam => {
    Handlebars.registerHelper(
      'subscribedToGroups',
      function (arrayStr, options) {
        const array = arrayStr.split(',');
        const hasCommon = array.filter(
          groupId => options?.data?.root?.memberGroups.indexOf(groupId) !== -1
        );
        return !!hasCommon.length;
      }
    );

    const msgId = hashParam.substring(0, 5);
    const memId = hashParam.substring(5, 10);
    const orgId = hashParam.substring(10);
    const previewInfo = await services.findMessagePreviewInfo(
      msgId,
      memId,
      orgId
    );
    if (previewInfo) {
      const phoneAsString = previewInfo.orgData.phoneNumber.toString();
      const phoneWithDashes =
        phoneAsString.slice(0, 3) +
        '-' +
        phoneAsString.slice(3, 6) +
        '-' +
        phoneAsString.slice(6);
      let addresses = previewInfo.orgData.address.map(address => {
        return `<p style="text-align: center; font-size: 75%">
            ${address.description ? address.description + ' |' : ''} ${
          address.address ? address.address : ''
        } |
            ${address.city ? address.city : ''},
            ${address.state ? address.state : ''} ${
          address.zipcode ? address.zipcode : ''
        }
            </p>`;
      });

      let messageContent =
        previewInfo.messageData.messageBody +
        `</br> 
            <center>
            <hr style="width: 75%; margin-top: 10px; margin-bottom: 10px; border-width: .5px" noshade>
              <p style="text-align: center; font-size: 75%">
                  This message was sent from the ${
                    previewInfo.orgData.organizationName
                  }. To change your preferences or opt out, 
                  <a target="_top" clicktracking=off href='${
                    process.env.REACT_APP_webUrl
                  }/optinmsg?id=${
          previewInfo.memberData.noteRouterId
        }'><b>click here</b></a>
              </p>
              <p style="text-align: center; font-size: 75%">
                  ${previewInfo.orgData.organizationName}
              </p>
              <p style="text-align: center; font-size: 75%">
                  <span>${
                    previewInfo.orgData.email
                  }</span> | <span>Tel: ${phoneWithDashes} ${
          previewInfo.orgData.acronym === 'SRA' ? 'or 306-791-2700' : ''
        }</span>
              </p>
              ${addresses.join('')}
            </center>`;

      const { fieldMapping, personalizeOptionsToSet, contactDataMapping } =
        await checkForPersonalization(
          messageContent,
          'meep',
          previewInfo.orgData,
          previewInfo.memberData
        );

      const substitutions = createRecipientSubstitutions(
        previewInfo.memberData,
        personalizeOptionsToSet,
        contactDataMapping,
        fieldMapping,
        previewInfo.orgData
      );

      for (let value in substitutions) {
        const val = `#${value}`;
        let sortingExpression = new RegExp(val, 'g');
        messageContent = messageContent.replace(
          sortingExpression,
          substitutions[value]
        );
      }

      const template = Handlebars.compile(messageContent);
      const populatedTemplate = template({
        memberGroups: previewInfo.memberData.identifiers,
        ...substitutions,
      });

      setContent(populatedTemplate);
      setLoading(false);
    } else {
      props.actions.toast('error', 'Unable to retrieve message');
      setLoading(false);
    }
  };

  const checkForPersonalization = async (
    messageContent,
    subjectContent,
    orgInfo,
    contactInfo
  ) => {
    const fieldMapping = {};
    const personalizeOptionsToSet = {};
    let customPersonalizations = false;
    const contactDataMapping = {};

    const orgFields = await getCustomFieldsForMessageView(
      orgInfo.id,
      orgInfo.orgType
    );
    orgFields.forEach(field => {
      fieldMapping[field.id] = field;
      if (
        (messageContent && messageContent.includes(field.personalizeDisplay)) ||
        (subjectContent && subjectContent.includes(field.personalizeDisplay))
      ) {
        personalizeOptionsToSet[field.id] = field.personalizeDisplay;
        if (field.category === 'Custom' && !customPersonalizations)
          customPersonalizations = true;
      }
    });

    if (customPersonalizations) {
      contactInfo.customData.forEach(field => {
        contactDataMapping[field.id] = field.value;
      });
    }
    return { fieldMapping, personalizeOptionsToSet, contactDataMapping };
  };

  const formatDate = date => {
    let formattedDate = '';
    if (date) {
      if (date === '00000000') formattedDate = 'N/A';
      else {
        const tmp = new Date(date);
        formattedDate = tmp
          .toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' })
          .replace(new RegExp('/', 'g'), '-');
      }
    }
    return formattedDate;
  };

  const createRecipientSubstitutions = (
    contact, //The contact data
    personalizations, //All the personalizations in the message
    contactDataMapping, //Custom fields the contact may have and the values
    fieldMapping, // {'fieldId': {field data}}
    orgInfo
  ) => {
    const formattedOrgPhone =
      orgInfo.phoneNumber && orgInfo.phoneNumber.length === 10
        ? `(${orgInfo.phoneNumber.slice(0, 3)}) ${orgInfo.phoneNumber.slice(
            3,
            6
          )}-${orgInfo.phoneNumber.slice(6)}`
        : '';
    //Keep track of the substitutions this contact has for this message.
    const substitutions = {
      preference_form_url:
        `${process.env.REACT_APP_webUrl}/optinmsg?id=${contact.noteRouterId}` ||
        '',
      full_name: `${contact.firstName} ${contact.lastName}`,
      organization_name: orgInfo.organizationName,
      organization_acronym: orgInfo.acronym,
      organization_phone: formattedOrgPhone,
      organization_email: orgInfo.email,
      organization_website: orgInfo.website,
    };
    //Go through all personalizations in message
    for (const option in personalizations) {
      const { category, personalizeDisplay, fieldName } = fieldMapping[option]; //Grab the record data for the personalization
      if (!category) continue; //Skip if no category

      //This covers all 'basic' fields and some others like office data. Just top level fields.
      if (contact[fieldName]) {
        substitutions[personalizeDisplay] = contact[fieldName];
        continue;
      }

      if (category === 'Custom') {
        //If it is a user made field
        substitutions[personalizeDisplay] = contactDataMapping[option] || '';
        continue;
      }

      //Integrated usually means it relates to an AMS organization
      if (category === 'Integrated') {
        if (fieldName === 'memberId') {
          substitutions[personalizeDisplay] = contact?.source?.memberId || '';
        } else if (contact?.organization?.attributes) {
          const attrValue = contact.organization.attributes.find(
            //Grab the value from attributes array
            attr => attr.name === fieldName
          );
          if (fieldName === 'memberStateLicenseExpirationDate')
            //If the field is this, format the value into a date
            substitutions[personalizeDisplay] = attrValue?.value
              ? formatDate(attrValue.value)
              : '';
          else substitutions[personalizeDisplay] = attrValue?.value || '';
        }
        continue;
      }
      substitutions[personalizeDisplay] = '';
    }
    return substitutions;
  };

  const getContactByOldId = async idPlaceholder => {
    //Deprecated long ago but still here for backwards compatibility temporarily
    const data = await services.fetchContactPrefPageData(idPlaceholder);
    return data || {};
  };

  const displayMessage = message => {
    Handlebars.registerHelper('if', function (context, options) {
      return `<div style="border: 3px solid #FC9E3A;">${options.fn(
        context
      )}</div>`;
    });

    Handlebars.registerHelper('subscribedToGroups', function () {
      return;
    });

    setDisplayCondition(
      message.messageBody.toString().includes('subscribedToGroups')
    );
    setContent(Handlebars.compile(message.messageBody)());
    setLoading(false);
  };

  const getMessageContent = async () => {
    const id = getQueryStringValue('id');
    const message = await services.getMsgById(id);
    if (!message) {
      props.actions.toast(
        'error',
        'Sorry, we are unable to display this message.'
      );
      console.error(`No message found for message id ${id}`);
      setLoading(false);
      return;
    }

    displayMessage(message);
  };

  const getACMessageContent = async () => {
    const parsed = getQueryStringValue('acId').split('|');
    const acId = parsed[0];
    const stageId = parsed[1];
    if (!acId || !stageId) {
      if (!message) {
        props.actions.toast(
          'error',
          'Sorry, we are unable to display this message.'
        );
        console.error('acId or stageId is not validly parsed');
        setLoading(false);
        return;
      }
    }
    const message = await fetchACMessage(acId, stageId);
    if (!message) {
      props.actions.toast(
        'error',
        'Sorry, we are unable to display this message.'
      );
      console.error(
        `No message found for AC with id ${acId} and stage id ${stageId}`
      );
      setLoading(false);
      return;
    }
    displayMessage(message);
  };

  useEffect(() => {
    gAnalytics('message', '/message');
    const hashParam = getQueryStringValue('nrmg');
    if (hashParam) {
      grabContactDetails(hashParam);
    } else {
      let idPlaceholder = getQueryStringValue('auth');
      if (idPlaceholder) {
        getContactByOldId(idPlaceholder);
      }
      if (getQueryStringValue('id')) {
        getMessageContent();
      }
      if (getQueryStringValue('acId')) {
        getACMessageContent();
      }
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="optinmsglogocontent">
      <DisplayAlert isVisible={hasDisplayConditions}>
        Message sections highlighted with a orange border contain display
        conditions and will only be visible to certain recipients.
      </DisplayAlert>
      <Spin spinning={loading} />
      <div className="load_optin">
        <iframe
          srcDoc={content}
          title="Message Display"
          height="100vh"
          width="100%"
          id="message-display-iframe"
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return state;
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actioncreators, dispatch),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MessagePreview)
);
