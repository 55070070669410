import React, { useEffect } from 'react';
import Container from './Components/container';
import Button from './Components/btn';
import Text from './Components/text.styled';
import { SUB_MENU_CONFIG, GROUPS, FILTERS } from '../../constants';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  toast,
  loadGroupTypes,
  loadFilterTypes,
  loadAudienceFilters,
  selectSubMenuItem,
  loadCommittees,
} from '../../../../../redux/action';
import {
  getGroupTypes,
  getFilterTypes,
  getAudienceFilters,
  getOrgCommittees,
} from '../../../../../redux/services.js';

const SubMenu = ({
  selectedMenuItemName,
  selectedSubMenuItemName,
  actions,
  orgId,
}) => {
  const handleLoadGroupTypes = async () => {
    const groupTypes = await getGroupTypes(orgId);

    if (groupTypes.error) {
      actions.toast('error', 'Error retrieving group types');
    } else {
      actions.loadGroupTypes(groupTypes);
      SUB_MENU_CONFIG[GROUPS] = groupTypes.map(groupType => groupType.name);
    }
  };

  const handleLoadFilterTypes = async () => {
    const filterTypes = await getFilterTypes(orgId);

    if (filterTypes.error) {
      actions.toast('error', 'Error retrieving filter types');
    } else {
      actions.loadFilterTypes(filterTypes);
      SUB_MENU_CONFIG[FILTERS] = filterTypes.map(filterType => filterType.name);
    }
  };

  const handleLoadAudienceFilters = async () => {
    const audienceFilters = await getAudienceFilters(orgId);

    if (audienceFilters.error) {
      actions.toast('error', 'Error retrieving audience filters');
    } else {
      actions.loadAudienceFilters(audienceFilters);
      if (audienceFilters?.find(filter => filter.name === 'Committees')) {
        loadCommittees();
      }
    }
  };

  const loadCommittees = async () => {
    try {
      const committees = await getOrgCommittees(orgId);
      actions.loadCommittees(committees.data.data);
    } catch (error) {
      console.error(
        'Error retrieving committees filters [getOrgCommittees]',
        error
      );
      actions.toast('error', 'Error retrieving committees');
    }
  };

  useEffect(() => {
    if (orgId) {
      handleLoadGroupTypes();
      handleLoadFilterTypes();
      handleLoadAudienceFilters();
    }
  }, [orgId]);

  return (
    <Container>
      {SUB_MENU_CONFIG[selectedMenuItemName].map(text => (
        <Button
          key={text}
          disabled={SUB_MENU_CONFIG[selectedMenuItemName].length === 1}
          className={selectedSubMenuItemName === text ? 'active' : ''}
          onClick={() => actions.selectSubMenuItem(text)}
        >
          {text}
        </Button>
      ))}
      {selectedMenuItemName === 'Contacts' ? (
        <Text>
          Note: Only displaying contacts opted into one or more of the selected
          Topics.
        </Text>
      ) : (
        ''
      )}
    </Container>
  );
};

const mapStateToProps = state => ({
  selectedMenuItemName: state.audience.selectedMenuItemName,
  selectedSubMenuItemName: state.audience.selectedSubMenuItemName,
  selectedTopicsList: state.audience.selectedTopicsList,
  orgId: state.organization.selected.id,
  groupTypes: state.groupTypes,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      toast,
      loadGroupTypes,
      loadFilterTypes,
      loadAudienceFilters,
      selectSubMenuItem,
      loadCommittees,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubMenu);
