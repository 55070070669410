import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import './index.css';
import Wrapper from './Components/wrapper';
import Container from './Components/container';
import Menu from './Components/Menu';
import SubMenu from './Components/SubMenu';
import Content from './Components/Content';
import Footer from './Components/Footer';
import {
  authUserCheck,
  loadOrgTypeName,
  loadStandAloneAudienceAction,
  updateAudienceFromDraft,
} from '../../../redux/action';
import { selectedOrganizationSelector } from '../../../redux/selector';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
});

const Audience = ({
  withoutPadding = false,
  withoutTitle = false,
  withoutPreview = false,
  withoutFileMenu = false,
  withoutApplyFileMenu = false,
  alignTitleToLeft = false,
  centerTabs = false,
  isStandaloneAudience = false,
  disableAutosave = false,
}) => {
  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const dispatch = useDispatch();
  const { id: selectedOrgId, orgType: orgTypeId } = useSelector(
    selectedOrganizationSelector
  );

  useEffect(() => {
    dispatch(authUserCheck({ location, history }));
    dispatch(loadOrgTypeName(orgTypeId));

    if (query.draft) {
      dispatch(updateAudienceFromDraft(query.draft));
    } else if (query.audienceId) {
      dispatch(loadStandAloneAudienceAction(query.audienceId));
    }
  }, [selectedOrgId]);

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Menu
          withoutPadding={withoutPadding}
          withoutTitle={withoutTitle}
          withoutPreview={withoutPreview}
          withoutFileMenu={withoutFileMenu}
          withoutApplyFileMenu={withoutApplyFileMenu}
          alignTitleToLeft={alignTitleToLeft}
          centerTabs={centerTabs}
          isStandAloneAudience={isStandaloneAudience}
          disableAutosave={disableAutosave}
        />
        <Container withoutPadding={withoutPadding}>
          <SubMenu />
          <Content />
        </Container>
        <Footer withoutPadding={withoutPadding} />
      </Wrapper>
    </ThemeProvider>
  );
};

export default Audience;
