import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from '../../../redux/action';
import { Archive, SettingsBackupRestoreOutlined } from '@material-ui/icons';
import { updateKeywordStatus } from '../api';
import { Button } from './TextingHub.styled';

const ArchiveBtnForKeywords = ({
  keywords,
  setKeywords,
  selectedIds,
  isTextingHubDisabled,
  status,
  resetSelectedIds,
}) => {
  const dispatch = useDispatch();
  const ACTIVE = 'active';
  const ARCHIVED = 'archived';
  const handleKeywordsActionBtn = async () => {
    if (isTextingHubDisabled) return;
    try {
      const newStatus = status == ACTIVE ? ARCHIVED : ACTIVE;

      await updateKeywordStatus(selectedIds, newStatus);

      const updatedKeywords = keywords.filter(keyword => {
        if (selectedIds.includes(keyword._id)) {
          return false;
        }
        return true;
      });

      setKeywords(updatedKeywords);
      const successStatusText =
        status == ACTIVE
          ? 'Successfully archived keywords'
          : 'Successfully unarchived keywords';
      dispatch(toast('success', successStatusText));
      resetSelectedIds();
    } catch (error) {
      console.error(error);
      const failedStatusText =
        status == ACTIVE
          ? 'Failed to archive keywords'
          : 'Failed to unarchive keywords';
      dispatch(toast('error', failedStatusText));
    }
  };

  return (
    <>
      {status === ARCHIVED && (
        <Button
          color={'#FC9E3A'}
          disabled={!selectedIds.length}
          onClick={handleKeywordsActionBtn}
        >
          <SettingsBackupRestoreOutlined
            style={{ fontSize: '18px', marginRight: '5px' }}
          />
          Unarchive
        </Button>
      )}
      {status === ACTIVE && (
        <Button
          color={'#ff6161'}
          disabled={!selectedIds.length}
          onClick={handleKeywordsActionBtn}
        >
          <Archive style={{ fontSize: '18px', marginRight: '5px' }} />
          Archive
        </Button>
      )}
    </>
  );
};

export default ArchiveBtnForKeywords;
