import moment from 'moment';

export const formatDate = saveTime => {
  if (!saveTime) return '';
  return 'Updated: ' + moment.unix(saveTime).format('MMM DD, YYYY');
};

export const formatTimestampToDate = timestamp => {
  if (!timestamp) return '';
  const timestampInSeconds = Math.floor(new Date(timestamp).getTime() / 1000);
  return 'Updated: ' + moment.unix(timestampInSeconds).format('MMM DD, YYYY');
};

export const trimTextIfNeeded = (text, maxLength) => {
  if (!text) return '';
  return text.length > maxLength
    ? text.substring(0, maxLength - 3) + '...'
    : text;
};
