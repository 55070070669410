import React, { useState } from 'react';

import { useToast, useUpdateUIState } from '../../redux/action';
import { Button, Menu, MenuItem } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { addBulkSyncItems } from '../../redux/services/import';

const syncTypes = {
  membership: 'Membership',
  billing: 'Billing',
  offices: 'Offices',
  committees: 'Committees',
  committeeMemberships: 'Committee Members',
  eventsAndClasses: 'Events and Classes',
  eventRegistration: 'Event and Class Registrations',
  course: 'Courses',
  list: 'List',
};

const AMSSyncAll = () => {
  const [syncMenuAnchor, setSyncMenuAnchor] = useState(false);

  const { fullLoader } = useUpdateUIState();
  const { errorToast, successToast } = useToast();

  const handleSyncActivation = async type => {
    try {
      fullLoader(true, 'Initializing AMS Sync for all orgs');
      await addBulkSyncItems(type);
      fullLoader(false);
      successToast('Done initializing AMS Sync for all orgs', 10);
    } catch (e) {
      fullLoader(false);
      console.error(`Error initializing AMS Sync for all orgs- ${e}`);
      errorToast('AMS sync initialization for all orgs failed');
    }
  };

  return (
    <>
      <Button
        id="sync-activation-menu-button"
        aria-controls="sync-menu"
        aria-haspopup="true"
        aria-expanded={syncMenuAnchor ? 'true' : undefined}
        size={'small'}
        variant="outlined"
        startIcon={<CloudDownloadIcon />}
        onClick={event => setSyncMenuAnchor(event.currentTarget)}
        style={{ background: '#007ade', color: '#fff', borderColor: '#007ade' }}
      >
        Sync All
      </Button>
      <Menu
        className={'syncMenuContainer'}
        id="sync-menu"
        aria-labelledby="sync-activation-menu-button"
        anchorEl={syncMenuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(syncMenuAnchor)}
        onClose={() => setSyncMenuAnchor(null)}
      >
        {Object.keys(syncTypes).map(sync => {
          return (
            <MenuItem
              onClick={() => {
                setSyncMenuAnchor(null);
                handleSyncActivation(sync);
              }}
              key={sync}
            >
              {syncTypes[sync]}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default AMSSyncAll;
