import React from 'react';
import { ArrowForward } from '@material-ui/icons';
import {
  Container,
  Title,
  Text,
  VideoPlaceHolder,
  Column,
  Button,
} from './Step.styled';

const Failure = ({ onClick }) => {
  return (
    <Container>
      <Column>
        <br />
        <Title>Whoops!</Title>
        <br />
        <br />
        <Text>
          Your payment for your most recent Texting Hub charges was not able to
          be completed.
          <br />
          <br />
          Please have one of your team&apos;s Admin users click the button below
          to review your payment method on file and make sure it&apos;s
          up-to-date.
        </Text>
        <br />
        <br />
        <Button onClick={onClick}>
          Review payment method <ArrowForward />
        </Button>
      </Column>
      <VideoPlaceHolder />
    </Container>
  );
};

export default Failure;
