import styled from 'styled-components';

const PRIMARY = 'PRIMARY';
const WHITE = '#ffffff';
const GREEN = '#5eab02';
const GREY = '#888888';

const getFontColor = props => {
  return props.version === PRIMARY ? WHITE : GREY;
};

const getBackgroundColor = props => {
  return props.version === PRIMARY ? GREEN : WHITE;
};

export const ToggleButtonLeft = styled.button`
  color: ${getFontColor};
  border: 1px solid ${GREY};
  font-size: 16px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right-style: none;
  padding: 3px 10px;
  font-weight: bold;
  transition: all 0.3s ease-in;
  background-color: ${getBackgroundColor};
  margin: 0;
  &:hover,
  &.active {
    color: ${WHITE};
    cursor: pointer;
    background-color: ${GREEN};
  }
`;

export const ToggleButtonRight = styled.button`
  color: ${getFontColor};
  border: 1px solid ${GREY};
  font-size: 16px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 3px 10px;
  font-weight: bold;
  transition: all 0.3s ease-in;
  background-color: ${getBackgroundColor};
  margin: 0;
  &:hover,
  &.active {
    color: ${WHITE};
    cursor: pointer;
    background-color: ${GREEN};
  }
`;

export const ToggleButtonGroup = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

export const EmptySpace = styled.div`
  margin-bottom: 15px;
`;
