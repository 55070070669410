import React, { useCallback, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';

import AssignTagsModal from '../AssignGroupsModal';
import CreateGroupModal from '../CreateGroupModal';
import styles from './AddToGroupModal.module.css';

const AddToGroupModal = ({
  initialGroups,
  memberIds,
  onAssignGroups = () => {},
  onClose,
}) => {
  const [hasCreatedNewGroup, setHasCreatedNewGroup] = useState(false);
  const [isCreatingNewGroup, setIsCreatingNewGroup] = useState(false);

  const startCreatingNewGroup = useCallback(() => {
    setIsCreatingNewGroup(true);
  }, []);

  const cancelCreatingNewGroup = useCallback(() => {
    setIsCreatingNewGroup(false);
  }, []);

  const notifyClose = useCallback(() => {
    onClose({ hasCreatedNewGroup });
  }, [hasCreatedNewGroup, onClose]);

  const [groups, setGroups] = useState(initialGroups);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);

  const handleGroupsAssignment = useCallback(() => {
    onAssignGroups();
  }, [onAssignGroups]);

  const handleNewGroupCreation = useCallback(async newTag => {
    setGroups(previousGroups => [...previousGroups, newTag]);
    setSelectedGroupIds(previousSelection => [...previousSelection, newTag.id]);

    setIsCreatingNewGroup(false);
    setHasCreatedNewGroup(true);
  }, []);

  return isCreatingNewGroup ? (
    <CreateGroupModal
      confirmText="Create Group and Select"
      cancelText="Back"
      onSuccess={handleNewGroupCreation}
      onClose={notifyClose}
      onCancel={cancelCreatingNewGroup}
    />
  ) : (
    <AssignTagsModal
      memberIds={memberIds}
      groups={groups}
      selected={selectedGroupIds}
      onSelectionChange={setSelectedGroupIds}
      pickerDropdownFooter={
        <button
          type="button"
          className={styles.createNewGroupButton}
          onClick={startCreatingNewGroup}
        >
          <AddIcon fontSize="small" />
          Create New Group
        </button>
      }
      onSuccess={handleGroupsAssignment}
      onClose={notifyClose}
    />
  );
};

export default AddToGroupModal;
