import styled from 'styled-components';

const ExportButton = styled.button`
  width: 110px;
  height: 35px;
  color: #6c7075;
  background: #fff;
  border: 1px solid #d4d7d9;
  border-radius: 3px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 1%;
  float: right;
  &:hover,
  &.active {
    cursor: pointer;
  }
`;

export default ExportButton;
