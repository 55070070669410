import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { _identity } from 'underscore-es';

import OpenRate from '../OpenRate';
import ClickRate from '../ClickRate';
import Toolbar from './Toolbar';
import { DataGrid } from './CampaignsTable.styled';

const ROW_HEIGHT = 70;

const STATUS_NAME_BY_VALUE = {
  active: 'Active',
  paused: 'Paused',
  archived: 'Archived',
};

const COLUMNS = [
  {
    field: 'name',
    headerName: 'Campaign Name',
    flex: 20,
    minWidth: 200,
    renderCell: ({ value }) => <DataGrid.Name>{value}</DataGrid.Name>,
    filterable: false,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 25,
    minWidth: 300,
    cellClassName: 'descriptionCell',
    renderCell: ({ value }) => (
      <DataGrid.Description>{value}</DataGrid.Description>
    ),
    filterable: false,
  },
  {
    field: 'openRate',
    headerName: 'Open Rate',
    flex: 22,
    minWidth: 200,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ value }) => (
      <DataGrid.Rate>
        <OpenRate value={value} />
      </DataGrid.Rate>
    ),
    filterable: false,
  },
  {
    field: 'clickRate',
    headerName: 'Click Rate',
    flex: 22,
    minWidth: 200,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ value }) => (
      <DataGrid.Rate>
        <ClickRate value={value} />
      </DataGrid.Rate>
    ),
    filterable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 12,
    minWidth: 110,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: ({ value }) => (value ? STATUS_NAME_BY_VALUE[value] : null),
    renderCell: ({ formattedValue }) => (
      <DataGrid.Status>{formattedValue}</DataGrid.Status>
    ),
    filterable: false,
  },
  {
    field: 'updatedAt',
    headerName: 'Last Modified',
    flex: 14,
    minWidth: 130,
    valueFormatter: ({ value }) => (value ? format(value, 'MM/dd/yyyy') : null),
    renderCell: ({ formattedValue }) => (
      <DataGrid.Date>{formattedValue}</DataGrid.Date>
    ),
    filterable: false,
  },
];

const PAGE_SIZES = [10, 25, 50, 100];

const CampaignsTable = ({
  data,
  total,
  isLoading,
  error,
  pagination,
  onChange,
  sortBy,
  onCampaignClick,
  selected,
  onSelectionChange,
  noResultsText = 'No rows',
}) => {
  const update = updates => {
    onChange({ pagination: _identity, sortBy, ...updates });
  };

  const handlePageChange = newPage => {
    update({
      pagination: prevPagination => ({
        ...prevPagination,
        current: newPage + 1,
      }),
    });
  };

  const handlePageSizeChange = newPageSize => {
    update({
      pagination: {
        current: 1,
        size: newPageSize,
      },
    });
  };

  const sortModel = useMemo(() => {
    if (!sortBy) {
      return [];
    }

    return [
      {
        field: sortBy.field,
        sort: sortBy.direction,
      },
    ];
  }, [sortBy]);

  const handleSortModelChange = sortModel => {
    if (sortModel.length === 0) {
      update({ sortBy: null });
    } else {
      const { field, sort } = sortModel[0];
      update({
        sortBy: {
          field,
          direction: sort,
        },
      });
    }
  };

  const handleRowClick = ({ id }) => {
    onCampaignClick(id);
  };

  return (
    <DataGrid
      columns={COLUMNS}
      rows={data}
      paginationMode="server"
      rowsPerPageOptions={PAGE_SIZES}
      rowCount={total}
      page={pagination.current - 1}
      pageSize={pagination.size}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      loading={isLoading}
      error={error}
      rowHeight={ROW_HEIGHT}
      disableSelectionOnClick
      disableColumnMenu
      onRowClick={handleRowClick}
      components={{ Toolbar }}
      checkboxSelection
      selectionModel={selected}
      onSelectionModelChange={onSelectionChange}
      localeText={{
        noRowsLabel: noResultsText,
      }}
    />
  );
};

CampaignsTable.DEFAULT_PAGE_SIZE = PAGE_SIZES[0];

export default CampaignsTable;
