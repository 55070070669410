import { IconButton, TextField } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { createStyles, makeStyles } from '@material-ui/styles';
import {
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import React from 'react';

const QuickSearchToolbar = props => {
  const defaultTheme = createTheme();
  const useStyles = makeStyles(
    theme =>
      createStyles({
        root: {
          padding: theme.spacing(0.5, 0.5, 0),
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
        },
        textField: {
          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
          margin: theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5),
          },
          '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.divider}`,
          },
        },
      }),
    { defaultTheme }
  );
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Quick Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
};

export default QuickSearchToolbar;
