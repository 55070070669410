import { average } from '../../../libs/js-utils';

class Campaign {
  static calculateAggregateOpenRate(stages) {
    return Campaign.#calculateAggregateMessageEfficiencyRate(stages, 'open');
  }

  static calculateAggregateClickRate(stages) {
    return Campaign.#calculateAggregateMessageEfficiencyRate(stages, 'click');
  }

  static #calculateAggregateMessageEfficiencyRate(stages, metric) {
    const FIELDS_BY_METRIC = {
      open: 'opened',
      click: 'clicked',
    };

    const metricField = FIELDS_BY_METRIC[metric];
    const sentStages = stages.filter(stage => stage.analytics.sent > 0);

    if (sentStages.length === 0) {
      return null;
    }

    const ratesForSentStages = sentStages.map(
      stage => stage.analytics[metricField] / stage.analytics.sent
    );
    return average(ratesForSentStages);
  }
}

export default Campaign;
