import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  padding: 15px;
  height: 100%;
  width: 205px;
  min-width: 205px;
  background-color: #ededed;
  border-radius: 5px;
`;

export default Container;
