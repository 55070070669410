import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from '../../redux/actions/UI';
import { validateEmail } from '../../redux/helpers/index';
import { selectedOrganizationSelector } from '../../redux/selectors/organization';
import * as services from '../../redux/services';
import Modal from '../Modal';
import styles from './ViewMessage.module.css';
import TestEmailForm from './TestEmailForm';

const SendDraft = ({ setIsSendDraftModalOpen, draftData }) => {
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(selectedOrganizationSelector);

  const [testEmail, setTestEmail] = useState(
    JSON.parse(
      localStorage.getItem(`table-${window.location.href.split('?draft=')[1]}`)
    )?.testEmail || ''
  );

  const sendTestMessage = async () => {
    const toArray = testEmail?.split(/,/g);
    if (!toArray || toArray.length === 0) {
      return dispatch(toast('error', 'Not a valid email'));
    }
    for (let i = 0; i < toArray.length; i++) {
      let eEmail = toArray[i].trim();
      if (!validateEmail(eEmail)) {
        return dispatch(toast('error', 'Invalid email in list'));
      }
    }
    const emailArray = toArray.map(e => {
      return { email: e.trim() };
    });

    let testMessageData = {
      to: emailArray,
      options: {
        sender: draftData.sender,
        senderName: draftData.senderName,
        replyTo: draftData.replyTo,
        preheader: draftData.preheader,
        html: draftData.html,
        subject: `DRAFT VERSION - ${draftData.subject}`,
        organizationDetail: {
          email: selectedOrganization.email,
          organizationName: selectedOrganization.organizationName,
          phoneNumber: selectedOrganization.phoneNumber,
        },
        address: selectedOrganization.address.map(address => {
          return `<p style="text-align: center; font-size: 75%">
            ${address.description ? address.description + ' |' : ''} ${
            address.address ? address.address : ''
          } |
            ${address.city ? address.city : ''},
            ${address.state ? address.state : ''} ${
            address.zipcode ? address.zipcode : ''
          }
            </p>`;
        }),
      },
    };
    const mailRes = await services.sendTestMessage(testMessageData);
    if (mailRes) {
      dispatch(toast('success', 'Test message(s) successfully sent.'));
    } else {
      dispatch(toast('error', 'Failed to send all test messages.'));
    }
  };

  return (
    <Modal
      title="Send Draft"
      primaryButton={{
        label: 'Send',
        onClick: () => {
          setIsSendDraftModalOpen(false);
          sendTestMessage();
        },
      }}
      onClose={() => {
        setIsSendDraftModalOpen(false);
      }}
    >
      Enter one or more emails to send a draft email version of your message.
      Separate multiple email addresses with commas.
      <div className={styles.testEmailFormWrapper}>
        <TestEmailForm
          testEmail={testEmail}
          updateParentState={async updatedTestEmail =>
            await setTestEmail(updatedTestEmail)
          }
        />
      </div>
    </Modal>
  );
};

export default SendDraft;
