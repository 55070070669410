import 'antd/dist/antd.css';
import '../../globalcss.css';

import { LoadingOutlined } from '@ant-design/icons';
import { Button, IconButton, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Select } from 'antd';
import Papa from 'papaparse';
import React, { useEffect, useState } from 'react';
import ReactFileReader from 'react-file-reader';
import { useDispatch, useSelector } from 'react-redux';
import { selectedOrganizationSelector } from '../../redux/selectors/organization';
import { userDetailSelector } from '../../redux/selectors/user';
import { connectClient } from '../../utils/socketio';

// eslint-disable-next-line import/no-unresolved
import _ from 'underscore-es';

import {
  updateNavProgressMeter,
  updateProgressMeterResults,
  setProgressType,
  toast,
  updateResultsExceptions,
} from '../../redux/actions/UI';

import {
  checkCSVUIDs,
  getMemberCustomFieldData,
  uploadCustomFieldCSV,
} from '../../redux/services';
import CreateFieldModal from './CreateFieldModal';
import * as moment from 'moment';
const { Option } = Select;

const CSVUploadDrawer = props => {
  const [CSVUploadStep, setCSVUploadStep] = useState(1);
  const [CSVFileName, setCSVFileName] = useState('');
  const [CSVColumns, setCSVColumns] = useState([]);
  const [CSVRowData, setCSVRowData] = useState([]);
  const [CSVFile, setCSVFile] = useState();
  const [formattedCSVData, setFormattedCSVData] = useState({});
  const [OrgFieldMapping, setOrgFieldMapping] = useState(props.tableData);
  const [OrgContactFieldMapping, setOrgContactFieldMapping] = useState({});
  const [NRFields, setNRFields] = useState([]);
  const [filteredNRFields, setFilteredNRFields] = useState([]);
  const [CSV_UID_Col, setCSV_UID_Col] = useState('');
  const [NR_UID_Col, setNR_UID_Col] = useState('');
  const [CSVColumnMapping, setCSVColumnMapping] = useState({});
  const [newFieldMatchingColumn, setNewFieldMatchingColumn] = useState(null);
  const [CSVColExampleValue, setCSVColExampleValue] = useState('');
  const [isLoading, setIsLoading] = useState({ value: false, text: '' });
  const [UIDConflictModal, setUIDConflictModal] = useState([]);
  const [contactSourceMapping, setContactSourceMapping] = useState({});
  const [newFieldsList, setNewFieldsList] = useState([]);
  const [ContactFieldMapping, setContactFieldMapping] = useState({});
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const userDetail = useSelector(userDetailSelector);

  useEffect(() => {
    if (props.NRFields.length) {
      setNRFields(props.NRFields);
      setFilteredNRFields(props.NRFields);
      let fieldMapping = {};
      props.NRFields.forEach(field => {
        fieldMapping[field.id] = field;
      });
      setOrgFieldMapping(fieldMapping);
    }
    if (props.contactGenericData.length) {
      const sourceMap = {};
      props.contactGenericData.forEach(contact => {
        sourceMap[contact.noteRouterId] =
          contact.source?.name === 'nr' || contact.source?.name === 'csv';
      });
      setContactSourceMapping(sourceMap);
    }
    getContactCustomFields();

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const socket = connectClient(
      userDetail.userId,
      'Contact_Upload',
      selectedOrganization.id
    );

    socket.on('processUpdate', update => {
      dispatch(updateNavProgressMeter(update.progress));
    });

    socket.on('processComplete', update => {
      const resultDrawerInfo = {
        totalSuccess: update.numUpdated,
        drawerTitle: (
          <span style={{ paddingLeft: '10px' }}>
            Fields & Data: &nbsp;&nbsp;Add from CSV - Result
          </span>
        ),
        type: 'updated',
        filename: `${moment().format(
          'MM-DD-YYYY'
        )} Fields & Data Exceptions Export.csv`,
      };
      dispatch(updateProgressMeterResults(resultDrawerInfo));
      dispatch(updateResultsExceptions(update.exceptions));
      socket.disconnect();
    });
  }, [selectedOrganization.id]);

  const finishFileUpload = async () => {
    setIsLoading({ value: true, text: 'Uploading CSV' });
    const integratedUID =
      OrgFieldMapping[NR_UID_Col].category === 'Integrated' &&
      (OrgFieldMapping[NR_UID_Col].fieldName !== 'memberId' ||
        OrgFieldMapping[NR_UID_Col].fieldName !== 'memberNRDSID');
    const payload = {
      formattedCSVData,
      CSVColumnMapping,
      NR_UID_Col,
      OrgFieldMapping,
      integratedUID,
      orgId: selectedOrganization.id,
      userId: userDetail.userId,
    };
    await uploadCustomFieldCSV(payload);
    setIsLoading({ value: false, text: '' });
    dispatch(setProgressType('Contact_Upload'));
    dispatch(updateNavProgressMeter(0.1)); //To bring up progress bar
    dispatch(updateProgressMeterResults({ fieldsAdded: newFieldsList }));
    props.closeAndRefresh();
    dispatch(
      toast('info', 'Processing. Progress bar added to bottom-left of screen.')
    );
  };

  const getContactCustomFields = async () => {
    //This is a semi-hacky workaround to fix an edge case duplication bug that happens when a user attempts to upload the same/similar CSV without leaving the F&D page.
    //Just forces the data to refresh to eliminate duplicates anytime a user goes to upload a CSV.
    setIsLoading({
      value: true,
      text: 'Preparing for CSV Upload, this may take a minute.',
    });
    const contactData = await getMemberCustomFieldData(selectedOrganization.id);
    let fieldMapping = {};
    contactData.forEach(contact => {
      fieldMapping[contact.id] = contact.customData;
    });
    setContactFieldMapping(fieldMapping);
    setIsLoading({
      value: false,
      text: '',
    });
  };

  //Our file upload can handle multiple files but we only use one at a time.
  const handleFileUpload = files => {
    setCSVFileName(files[0].name);
    setCSVFile(files);
    Papa.parse(files[0], {
      header: true,
      skipEmptyLines: 'greedy',
      complete: results => {
        //Grab column names to have them ready to display
        const firstElement = results.data[0];
        let CSV_column_data = [];
        for (let columnName in firstElement) {
          let columnData = {
            name: columnName,
            value: firstElement[columnName],
          };
          if (!columnData.value) {
            columnData.value = results.data.find(row => row[columnName])
              ? results.data.find(row => row[columnName])[columnName]
              : '';
          }
          CSV_column_data.push(columnData);
        }
        setCSVColumns(CSV_column_data);
        setCSVRowData(results.data);

        let customDataWithIds = {};
        for (let data in ContactFieldMapping) {
          if (data) {
            customDataWithIds[data] = {
              fields: ContactFieldMapping[data],
              ids: ContactFieldMapping[data].map(field => field.id) || [],
            };
          }
        }
        setOrgContactFieldMapping(customDataWithIds);
      },
    });
    setCSVUploadStep(2);
    props.updateUploadStep('Select UID');
  };

  const auditCSVUpload = async () => {
    setIsLoading({
      value: true,
      text: 'Processing CSV. This may take a few seconds.',
    });
    const results = await checkCSVUIDs({
      CSVRowData,
      CSV_UID_Col,
      file: CSVFile,
    });
    if (!results.error) {
      if (results.badDupes.length) {
        setUIDConflictModal(_.uniq(results.badDupes));
        setIsLoading({ value: false, text: '' });
      } else {
        await mapCSVRowsToContacts();
      }
    } else
      dispatch(
        toast('error', 'Error validating CSV data, please check CSV content.')
      );
  };

  const mapCSVRowsToContacts = async badDupes => {
    const NRUIDField = OrgFieldMapping[NR_UID_Col];
    const easyMapping =
      NRUIDField.category === 'Custom' ||
      NRUIDField.category === 'Basic' ||
      (NRUIDField.category === 'Integrated' &&
        (NRUIDField.fieldName === 'memberId' ||
          NRUIDField.fieldName === 'memberNRDSID'));
    const UIDsToSkip = {};
    const exceptions = [];
    if (badDupes?.length) {
      badDupes.forEach(dupe => {
        UIDsToSkip[dupe] = true;
        exceptions.push({
          //To be displayed on the results page
          UID: dupe,
          notes: 'Duplicate UID',
          code: 1000,
          id: `1000 - ${dupe}`,
        });
      });
      setUIDConflictModal([]);
    }
    const CSVUIDs = {};
    for (let csvRow of CSVRowData) {
      const contactUID = csvRow[CSV_UID_Col];
      const cleanUID =
        contactUID && typeof contactUID === 'string'
          ? contactUID.toLowerCase().trim()
          : contactUID;
      if (cleanUID && !UIDsToSkip[cleanUID]) CSVUIDs[cleanUID] = true;
    }
    const OrgContacts = easyMapping ? mapOrgContactsUID(CSVUIDs) : {}; //All org contacts organized into key value pairs. Key is the contacts UID and the value is all contacts with that UID
    const updatedCSVData = {};
    const exceptionTracking = {};
    for (let newContactData of CSVRowData) {
      //Go through each row of the CSV
      let existingRecords; //Contacts that exist in NR with the specific UID. Can be one or multiple.
      const contactUID = newContactData[CSV_UID_Col];
      const cleanUID =
        contactUID && typeof contactUID === 'string'
          ? contactUID.toLowerCase().trim()
          : contactUID;
      if (easyMapping && cleanUID && OrgContacts[cleanUID]) {
        existingRecords = OrgContacts[cleanUID] || {};
        updatedCSVData[cleanUID] = {
          newData: newContactData,
          oldData: existingRecords,
        };
      } else if (!easyMapping && cleanUID) {
        updatedCSVData[cleanUID] = {
          newData: newContactData,
        };
      } else {
        if (!exceptionTracking[`1010 - ${cleanUID}`]) {
          exceptions.push({
            //To be displayed on the results page
            UID: cleanUID,
            notes: 'No UID Match',
            code: 1010,
            id: `1010 - ${cleanUID}`,
          });
          exceptionTracking[`1010 - ${cleanUID}`] = true; //To make sure there are no duplicate exceptions
        }
      }
    }
    setFormattedCSVData(updatedCSVData);
    dispatch(updateResultsExceptions(exceptions));
    if (NRFields.length !== filteredNRFields.length)
      setFilteredNRFields(NRFields);
    setIsLoading({ value: false, text: '' });
    setCSVUploadStep(3);
    props.updateUploadStep('Matching Fields');
  };

  const mapOrgContactsUID = CSVUIDs => {
    const OrgContacts = {};
    const NRUIDField = OrgFieldMapping[NR_UID_Col];
    if (
      NRUIDField.category === 'Basic' ||
      (NRUIDField.category === 'Integrated' &&
        (NRUIDField.fieldName === 'memberId' ||
          NRUIDField.fieldName === 'memberNRDSID'))
    ) {
      props.contactGenericData.forEach(contact => {
        let contactUID = '';
        if (NRUIDField.category === 'Basic')
          contactUID = contact[NRUIDField.fieldName] || '';
        else if (
          NRUIDField.category === 'Integrated' &&
          NRUIDField.fieldName === 'memberId'
        )
          contactUID = contact.source?.memberId || '';
        else if (
          NRUIDField.category === 'Integrated' &&
          NRUIDField.fieldName === 'memberNRDSID'
        ) {
          const NRDSId = contact.organization?.attributes.find(field => {
            return field.name === 'memberNRDSID';
          });
          contactUID = NRDSId ? NRDSId.value : '';
        }
        if (contactUID && CSVUIDs[contactUID]) {
          const availableContactCustomData =
            OrgContactFieldMapping[contact.noteRouterId];
          const contactDetails = {
            noteRouterId: contact.noteRouterId,
            email: contact.email,
            phoneNumber: contact.phoneNumber,
            customData: availableContactCustomData
              ? availableContactCustomData.fields
              : null,
            hasCustomFields: !!availableContactCustomData,
            canUpdate:
              contact.source?.name === 'nr' || contact.source?.name === 'csv',
          };
          if (OrgContacts[contactUID]) {
            if (Array.isArray(OrgContacts[contactUID]))
              OrgContacts[contactUID].push(contactDetails);
            else
              OrgContacts[contactUID] = [
                contactDetails,
                OrgContacts[contactUID],
              ];
          } else {
            OrgContacts[contactUID] = contactDetails;
          }
        }
      });
    } else if (NRUIDField.category === 'Custom') {
      for (let contactData in OrgContactFieldMapping) {
        if (OrgContactFieldMapping[contactData].ids.includes(NR_UID_Col)) {
          const UIDValue = OrgContactFieldMapping[contactData].fields.find(
            val => val.id === NR_UID_Col
          ).value;
          const contactDeets = {
            noteRouterId: contactData,
            customData: OrgContactFieldMapping[contactData].fields,
            hasCustomFields: true,
            canUpdate: contactSourceMapping[contactData],
          };
          if (OrgContacts[UIDValue]) {
            if (Array.isArray(OrgContacts[UIDValue]))
              OrgContacts[UIDValue].push(contactDeets);
            else OrgContacts[UIDValue] = [contactDeets, OrgContacts[UIDValue]];
          } else {
            OrgContacts[UIDValue] = contactDeets;
          }
        }
      }
    }
    return OrgContacts;
  };

  const handleSearch = value => {
    if (value) {
      let filterFields = NRFields.filter(field => {
        return field.name.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredNRFields(filterFields);
    } else {
      setFilteredNRFields(NRFields);
    }
  };

  const updateMapping = (columnName, NRFieldId) => {
    if (NRFieldId === '+') {
      //Open create modal and create a new modal
      setNewFieldMatchingColumn(columnName);
    }
    if (NRFields.length !== filteredNRFields.length)
      setFilteredNRFields(NRFields); //Resets search options
    if (NRFieldId === '-') {
      const removedField = { ...CSVColumnMapping };
      delete removedField[columnName];
      setCSVColumnMapping(removedField);
    } else {
      setCSVColumnMapping({
        ...CSVColumnMapping,
        [columnName]: NRFieldId,
      });
    }

    if (props.singleField) {
      const colData =
        columnName === '- Select Column -'
          ? ''
          : CSVRowData.find(row => row[columnName])
          ? CSVRowData.find(row => row[columnName])[columnName]
          : '' || '';
      setCSVColExampleValue(colData);
    }
  };

  const updateSelection = newField => {
    updateMapping(newFieldMatchingColumn, newField.id);
    let updatedNRFields = [...NRFields];
    updatedNRFields.unshift(newField);
    setNRFields(updatedNRFields);
    setOrgFieldMapping({
      ...OrgFieldMapping,
      [newField.id]: newField,
    });
    setNewFieldsList([...newFieldsList, newField.name]); //Keeping track of new fields a user creates.
    setFilteredNRFields(updatedNRFields);
    setNewFieldMatchingColumn('');
  };

  return (
    <div style={{ width: '100%', height: '100%', margin: '0' }}>
      <div
        className={'FieldDrawerHeader'}
        style={{ justifyContent: CSVUploadStep === 1 ? 'flex-start' : '' }}
      >
        <IconButton
          onClick={() => {
            props.closeDrawer();
          }}
          style={{ color: 'white' }}
        >
          <CloseIcon />
        </IconButton>
        {props.drawerTitle}
        {CSVUploadStep === 2 && (
          <Button
            onClick={() => auditCSVUpload()}
            className={'FieldSaveButtons'}
            variant="outlined"
            disabled={
              !CSV_UID_Col ||
              CSV_UID_Col === '- Select Field -' ||
              !NR_UID_Col ||
              NR_UID_Col === '- Select Field -'
            }
          >
            Next
          </Button>
        )}
        {CSVUploadStep === 3 && (
          <Button
            className={'FieldSaveButtons'}
            variant="outlined"
            onClick={() => {
              finishFileUpload();
            }}
          >
            Finish
          </Button>
        )}
      </div>
      <div
        style={{ height: 'calc(100% - 60px)', padding: '12px 24px 24px 24px' }}
      >
        {CSVUploadStep === 1 && (
          <>
            <h1 style={{ fontSize: '24px' }}>File Upload</h1>
            <br />
            <h4
              style={{ fontSize: '16px' }}
            >{`Start by uploading the .CSV file that contains the fields and data you'd like to add in NoteRouter.
                     Don't worry if your column names don't match NoteRouter's field names, 
                     or if the fields don't exist in NoteRouter just yet - you'll have a tool for all of that momentarily.`}</h4>
            <p
              style={{
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                margin: '10px 0',
              }}
            >
              <InfoOutlinedIcon
                style={{
                  fontSize: '18px',
                  marginRight: '10px',
                  color: '#007ADE',
                }}
              />
              {
                'This process will only add data to existing contacts in NoteRouter. If a contact in your CSV does not exist in NoteRouter, no action will be taken for that record.'
              }
            </p>
            <div className={'fileUploadContainer'}>
              <ReactFileReader
                handleFiles={handleFileUpload}
                fileTypes={['.csv', '.xlsx']}
              >
                <Button
                  className={'uploadCSVBtn'}
                  style={{
                    color: '#5EAB00',
                    backgroundColor: 'rgba(94, 171, 0, 0.05)',
                    borderColor: '#5EAB00',
                  }}
                  variant={'outlined'}
                >
                  Upload CSV
                </Button>
              </ReactFileReader>
              <p style={{ fontSize: '15px' }}>
                Guide:{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#1890ff' }}
                  href={
                    'https://noterouter.freshdesk.com/a/solutions/articles/69000790377'
                  }
                >
                  {' '}
                  {'Uploading Fields Guide'}{' '}
                </a>
              </p>
            </div>
          </>
        )}
        {CSVUploadStep === 2 && (
          <div>
            <div>
              <Button
                onClick={() => {
                  setCSVUploadStep(1);
                  props.updateUploadStep('File Upload');
                  setCSVFileName('');
                  setCSVColumns([]);
                }}
                variant={'outlined'}
                style={{
                  backgroundColor: 'rgba(248, 111, 0, 0.05)',
                  color: '#F86F00',
                  borderColor: '#F86F00',
                  width: '100px',
                  marginRight: '25px',
                }}
              >
                Back
              </Button>
              <span>
                File: <span style={{ color: '#007ADE' }}>{CSVFileName}</span>
              </span>
            </div>
            <div
              style={{
                marginTop: '25px',
                marginBottom: '35px',
                fontSize: '16px',
              }}
            >
              <p style={{ fontSize: '15px' }}>
                {
                  "Perfect! Now, you'll need to tell NoteRouter what field it should use to match up contacts in your CSV file to contact records in NoteRouter. This field will be called the Unique Identifier, or UID."
                }
              </p>
              <p style={{ fontSize: '15px' }}>
                {
                  "Try to use a field in which contacts won't share the same info, such as an ID - otherwise, multiple contact records with the same info might not be able to be told apart."
                }
              </p>
            </div>
            <div className={'UIDSelectionContainer'}>
              <p style={{ fontSize: '16px' }}>Select Unique Identifier</p>
              <div className={'UIDSelectionHeader'}>
                <span>CSV Column</span>
                <span>NoteRouter Field</span>
              </div>
              <div className={'UIDSelectionOptions'}>
                <Select
                  defaultValue={CSV_UID_Col ? CSV_UID_Col : '- Select Field -'}
                  style={{ width: '250px' }}
                  onChange={value => {
                    setCSV_UID_Col(value);
                  }}
                  showSearch
                >
                  <Option key={'- Select Field -'} value={'- Select Field -'}>
                    {'- Select Field -'}
                  </Option>
                  {CSVColumns.map(column => {
                    return (
                      <Option key={column.name} value={column.name}>
                        {column.name}
                      </Option>
                    );
                  })}
                </Select>
                <span style={{ fontSize: '18pt', fontWeight: '700' }}>=</span>
                <Select
                  defaultValue={NR_UID_Col ? NR_UID_Col : '- Select Field -'}
                  style={{ width: '250px' }}
                  onChange={value => {
                    setNR_UID_Col(value);
                    setFilteredNRFields(NRFields);
                  }}
                  filterOption={false}
                  showSearch
                  onSearch={handleSearch}
                >
                  <Option key={'- Select Field -'} value={'- Select Field -'}>
                    {'- Select Field -'}
                  </Option>
                  {filteredNRFields.map(field => {
                    return (
                      <Option key={field.id} value={field.id}>
                        {field.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </div>
        )}
        {CSVUploadStep === 3 && (
          <div style={{ height: '100%' }}>
            <div>
              <Button
                onClick={() => {
                  setCSVUploadStep(2);
                  props.updateUploadStep('Select UID');
                }}
                variant={'outlined'}
                style={{
                  backgroundColor: 'rgba(248, 111, 0, 0.05)',
                  color: '#F86F00',
                  borderColor: '#F86F00',
                  width: '100px',
                  marginRight: '25px',
                }}
              >
                Back
              </Button>
              <span>
                File: <span style={{ color: '#007ADE' }}>{CSVFileName}</span>
              </span>
            </div>
            <div
              style={{
                marginTop: '25px',
                marginBottom: '25px',
                fontSize: '16px',
              }}
            >
              <p style={{ fontSize: '17px' }}>
                {
                  'Lastly, we need to match up your CSV data to NoteRouter fields. You can also create new NoteRouter fields where needed, or "ignore" fields you don\'t want to update or use. The columns from your CSV are displayed on the left, and that data will update any matched NoteRouter fields on the right.'
                }
              </p>
              <p
                style={{
                  fontSize: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  margin: '10px 0',
                }}
              >
                <InfoOutlinedIcon
                  style={{
                    fontSize: '18px',
                    marginRight: '10px',
                    color: '#007ADE',
                  }}
                />
                {
                  'If a contact already has information saved for a given field, new information being uploaded into that field for the contact (including blanks) will update and overwrite the old info.'
                }
              </p>
            </div>
            <div className={'CSVMatchingContainer'}>
              <div className={'CSVMatchingHeader'}>
                <span
                  style={{
                    paddingLeft: '20px',
                    width: props.singleField ? '250px' : '200px',
                  }}
                >
                  CSV Column
                </span>
                <span style={{ paddingLeft: '19px', width: '230px' }}>
                  CSV Sample Value
                </span>
                <span
                  style={{
                    paddingLeft: '26px',
                    width: props.singleField ? '200px' : '250px',
                  }}
                >
                  NoteRouter Field
                </span>
              </div>
              <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
                {!props.singleField ? (
                  CSVColumns.map(column => {
                    return (
                      <div
                        className={'CSVMatchingOptionBox'}
                        style={{ width: '100%', display: 'flex' }}
                        key={column.name}
                      >
                        <span style={{ paddingLeft: '20px', width: '200px' }}>
                          {column.name && column.name.length > 20
                            ? column.name.substring(0, 20) + '...'
                            : column.name}
                        </span>
                        <span style={{ paddingLeft: '20px', width: '230px' }}>
                          {column.value && column.value.length > 20
                            ? column.value.substring(0, 20) + '...'
                            : column.value}
                        </span>
                        <span style={{ fontSize: '18px', fontWeight: '500' }}>
                          =
                        </span>
                        <Select
                          value={CSVColumnMapping[column.name] || '-'}
                          style={{ paddingLeft: '13px', width: '235px' }}
                          onChange={value => {
                            updateMapping(column.name, value);
                          }}
                          filterOption={false}
                          showSearch
                          onSearch={handleSearch}
                        >
                          {filteredNRFields.length === NRFields.length && (
                            <Option key={'- Ignore -'} value={'-'}>
                              {'- Ignore -'}
                            </Option>
                          )}
                          {filteredNRFields.length === NRFields.length && (
                            <Option key={'+ Create New Field +'} value={'+'}>
                              {'+ Create New Field +'}
                            </Option>
                          )}
                          {filteredNRFields
                            .filter(field => field.category !== 'Integrated')
                            .map(field => {
                              return (
                                <Option key={field.id} value={field.id}>
                                  {field.name}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className={'CSVMatchingOptionBox'}
                    style={{ width: '100%', display: 'flex' }}
                    key={props.singleField.id}
                  >
                    <Select
                      defaultValue={'- Select Column -'}
                      style={{ paddingLeft: '15px', width: '250px' }}
                      onChange={value => {
                        updateMapping(value, props.singleField.id);
                      }}
                      showSearch
                    >
                      <Option
                        key={'- Select Column -'}
                        value={'- Select Column -'}
                      >
                        {'- Select Column -'}
                      </Option>
                      {CSVColumns.map(column => {
                        return (
                          <Option key={column.name} value={column.name}>
                            {column.name}
                          </Option>
                        );
                      })}
                    </Select>
                    <span style={{ paddingLeft: '20px', width: '230px' }}>
                      {CSVColExampleValue}
                    </span>
                    <span style={{ fontSize: '18px', fontWeight: '500' }}>
                      =
                    </span>
                    <span style={{ paddingLeft: '20px', width: '200px' }}>
                      {props.singleField.name &&
                      props.singleField.name.length > 20
                        ? props.singleField.name.substring(0, 20) + '...'
                        : props.singleField.name}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {newFieldMatchingColumn && (
        <CreateFieldModal
          addFieldToMatching={newField => {
            updateSelection(newField);
          }}
          clearModalValue={() => {
            const mapping = { ...CSVColumnMapping };
            delete mapping[newFieldMatchingColumn];
            setCSVColumnMapping(mapping);
            setNewFieldMatchingColumn('');
          }}
        />
      )}
      <div
        className="ContactFieldsLoading"
        style={{ display: isLoading.value ? 'flex' : 'none' }}
      >
        <div className="contentDiv">
          <LoadingOutlined />
          <p>{isLoading.text}</p>
        </div>
      </div>
      <Modal
        open={!!UIDConflictModal.length}
        onClose={() => setUIDConflictModal([])}
      >
        <div className={'modalStyling'} style={{ height: '370px' }}>
          <div className={'modalHeader'}>
            <IconButton
              onClick={() => {
                setUIDConflictModal([]);
              }}
              style={{ color: 'white' }}
            >
              <CloseIcon />
            </IconButton>
            <span style={{ fontSize: '16px' }}>Conflicts Found</span>
          </div>
          <div className={'modalBody'}>
            {!props.singleField ? (
              <p>
                One or more contacts have an identical <b>{CSV_UID_Col}</b>, and
                those contacts have conflicting values for one or more fields.
                Duplicate contact records with conflicting field values cannot
                be updated.
              </p>
            ) : (
              <p>
                One or more contacts have an identical <b>{CSV_UID_Col}</b>, and
                those contacts have conflicting values for the{' '}
                {props.singleField.name} field. Conflicting contact records
                cannot be updated.
              </p>
            )}
            <div
              className={'modalList'}
              style={{
                overflowY: `${UIDConflictModal.length > 4 ? 'scroll' : ''}`,
              }}
            >
              {UIDConflictModal.map(name => {
                return (
                  <div className={'modalListItem'} key={name}>
                    {name}
                  </div>
                );
              })}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                size={'small'}
                style={{
                  backgroundColor: '#FF6161',
                  color: 'white',
                  border: 'none',
                }}
                variant="outlined"
                onClick={() => {
                  setUIDConflictModal([]);
                }}
              >
                Back
              </Button>
              <Button
                size={'small'}
                style={{
                  backgroundColor: '#5EAB00',
                  color: 'white',
                  border: 'none',
                }}
                variant="outlined"
                onClick={() => {
                  mapCSVRowsToContacts(UIDConflictModal);
                }}
              >
                Skip Conflicts
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CSVUploadDrawer;
