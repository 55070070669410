import styled from 'styled-components';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import { Visibility } from '@material-ui/icons';

export const DataGrid = styled(MuiDataGrid)`
  background: white;

  .MuiDataGrid-row {
    cursor: pointer;
  }

  .MuiDataGrid-row.greyed-out-message {
    background-color: #f6f6f6;
    color: grey;
  }

  .MuiDataGrid-row.unread-message {
    background-color: #d6f0ff;
    color: #0c0c0c;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;

export const DropDownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-left: 6px;
`;

export const CellContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-left: 5px;
`;

export const Line = styled.div`
  color: grey;
  display: flex;
  align-items: center;
`;

export const EditMatchedContact = styled.div`
  margin-left: 10px;
  color: #007ade;
  opacity: ${props => props.opacity};
  transition: opacity 0.3s ease-in;
`;

export const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UnreadLabel = styled.div`
  width: 15px;
  height: 15px;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  background-color: #007ade;
  margin-left: -15px;
  margin-right: 10px;
`;

export const UnreadLabels = styled.div`
  position: absolute;
  left: -10px;
  top: 20px;
  height: 10px;
  width: 10px;
  background-color: #007ade;
  border-radius: 50%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 18px;
`;

export const SmallText = styled.div`
  font-size: 11px;
  color: grey;
`;

export const ViewedIcon = styled(Visibility)`
  color: orange;
  transform: scale(0.7);
`;
