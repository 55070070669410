import './EngagementLogs.css';
import '../../globalcss.css';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Box, Grommet, Tab, Tabs } from 'grommet';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import MaterialTable, { MTableCell } from 'material-table';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as actioncreators from '../../redux/action';
import { authUserHelper } from '../../redux/helpers/index';
import * as services from '../../redux/services';
import { engagementContacts } from '../../redux/services/contact';
import MemberMessagesDrawer from '../MemberMessagesDrawer/MemberMessagesDrawer';
import MsgTable from './MsgTable';
import { fetchACEvent } from '../../features/automated-campaigns/api/api';

const EngagementLogs = props => {
  moment.locale('en');
  const [pageSize, setPageSize] = useState(50);
  const [visible, setVisible] = useState(false);
  const [detailsDrawer, setDetailsDrawer] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [memberInfo, setMemberInfo] = useState({});
  const [messages, setMessages] = useState([]);
  const [msgsLoading, setMsgsLoading] = useState(false);
  const [msgDetailsLoading, setMsgDetailsLoading] = useState(false);
  const [selectedUser] = useState({});
  const [selectedMember, setSelectedMember] = useState({});
  const [memberMessageInfo, setMemberMessageInfo] = useState({});
  const [memberEngagement, setMemberEngagement] = useState({ events: [] });
  const [indy, setIndex] = useState(0);
  const [orgId, setOrdId] = useState('');
  const [loadArchivedContacts, setLoadContactsData] = useState(false);
  const [checkBoxVisible, setCheckBoxVisible] = useState(true);

  useEffect(() => {
    setLoadContactsData(localStorage.getItem('archived-engagement'));
    authUserHelper(props);
    setOrdId(props.organization.selected.id);
    if (props.organization.selected && props.organization.selected.id) {
      setOrdId(props.organization.selected.id);
      fetchContacts(
        props.organization.selected.id,
        localStorage.getItem('archived-engagement')
      );
    }
  }, []);

  const fetchContacts = async (orgId, isArchived) => {
    if (orgId) {
      props.actions.fullloader(true, 'Loading Contacts...');
      setMemberList([]);
      const contactOptions = {
        orgId,
      };
      const orgContacts = await engagementContacts(contactOptions, isArchived);
      if (!orgContacts.error) {
        if (orgContacts.length < 1) {
          props.actions.fullloader(false);
          return;
        }
        const contactsWithEngagementScore = orgContacts.map(contact => {
          contact.engagementScore = Math.floor(Math.random() * (100 + 1));
          contact.memberId = contact.source.memberId;
          contact.memberNRDSID = contact.organization?.attributes?.find(
            attr => attr.name === 'memberNRDSID'
          )?.value;
          return contact;
        });
        setMemberList(contactsWithEngagementScore);
        props.actions.fullloader(false);
      }
    }
  };

  const onActive = index => {
    setCheckBoxVisible(index === 0 ? true : false);
    setIndex(index);
  };

  const contactMessageCheck = async data => {
    setMsgsLoading(true);
    let memberObject = {
      noteRouterId: data.noteRouterId,
      email: data.email,
      phone: data.phoneNumber,
    };
    const messageRecipientsArray = await services.getEngagementMessages(
      data.noteRouterId,
      props.organization.selected.id
    );
    let perMemberMsgArray = [];
    for (let msgData of messageRecipientsArray) {
      msgData.date = moment.unix(msgData.sendTime).format('YYYY-MM-DD hh:mm A');
      perMemberMsgArray.push(msgData);
    }
    setMessages(perMemberMsgArray);
    setMemberInfo(data);
    setSelectedMember(memberObject);
    setMsgsLoading(false);
  };

  const showpersonaltestDrawer = data => {
    contactMessageCheck(data);
    setVisible(true);
  };

  const showdetailsDrawer = async data => {
    setDetailsDrawer(true);
    setMemberEngagement({ events: [] });
    setMsgDetailsLoading(true);

    const info = data?.isAC
      ? await fetchACEvent(data.sgCompleteId)
      : await services.getMemberEventInfo(
          data.id,
          selectedMember.noteRouterId,
          props.organization.selected.id,
          data
        );
    if (info) {
      if (data?.isAC) {
        data.stageName = info.messageInfo.stageName;
        data.acName = info.messageInfo.acName;
        data.stageId = info.messageInfo.stageId;
        data.acId = info.messageInfo.acId;
      }
      data.memberEmail = info.messageInfo.memberEmail;
      data.memberPhone = info.messageInfo.memberPhone;
      data.deliveryDate = info.messageInfo.deliveryDate;
      data.orgPhone = info.messageInfo.orgPhone;
      data.from = info.messageInfo.from;
      setMemberMessageInfo(data);
      setMemberEngagement(info.memberEngagement);
      setMsgDetailsLoading(false);
    } else {
      setMemberMessageInfo(data);
      setMemberEngagement({});
      setMsgDetailsLoading(false);
    }
  };

  const canSeeEnagementScores = [
    'nick@noterouter.com',
    '32jk0h5@mail3tech.com',
    'rton6m4@dfb55.com',
    'noterouter@getnada.com',
    'gough.nicholas@yahoo.com',
  ];

  const columnsData = [
    {
      title: 'First Name',
      field: 'firstName',
      defaultSort: 'asc',
    },
    { title: 'Last Name', field: 'lastName' },
    {
      title: '',
      field: 'smsAlert',
      cellStyle: {
        paddingLeft: '0px',
        paddingRight: '0px',
      },
      align: 'right',
      // eslint-disable-next-line
      render: rowData => {
        if (rowData.smsAlert) {
          return (
            <>
              <Tooltip title="This phone number is experiencing delivery issues. Check Analytics for more details.">
                <ExclamationCircleOutlined
                  style={{
                    color: 'red',
                  }}
                />
              </Tooltip>
            </>
          );
        } else {
          return '';
        }
      },
    },
    {
      title: 'Phone Number',
      field: 'phoneNumber',
      align: 'left',
      cellStyle: {
        paddingLeft: '5px',
      },
      headerStyle: {
        paddingLeft: '5px',
      },
    },
    {
      title: '',
      field: 'spamAlert',
      align: 'right',
      cellStyle: {
        paddingLeft: '0px',
        paddingRight: '0px',
        maxWidth: '25px',
        minWidth: '25px',
      },
      headerStyle: {
        maxWidth: '25px',
        minWidth: '25px',
      },
      // eslint-disable-next-line
      render: rowData => {
        if (rowData.spamAlert || rowData.bounceAlert) {
          return (
            <>
              <Tooltip title="This email is experiencing delivery issues. Check Analytics for more details.">
                <ExclamationCircleOutlined
                  style={{
                    color: 'red',
                  }}
                />
              </Tooltip>
            </>
          );
        } else {
          return '';
        }
      },
    },
    {
      title: 'Email',
      field: 'email',
      align: 'left',
      cellStyle: {
        paddingLeft: '5px',
      },
      headerStyle: {
        paddingLeft: '5px',
      },
    },
    {
      title: 'Member #',
      // eslint-disable-next-line
      field: 'memberId',
    },
    {
      title: 'NRDS #',
      // eslint-disable-next-line
      field: 'memberNRDSID',
    },
    {
      title: 'Last Preference Page Visit',
      field: 'lastPreferencePageVisited',
      defaultSort: 'desc',
      render: rowData =>
        rowData.lastPreferencePageVisited ? (
          <p>
            {moment
              .unix(rowData.lastPreferencePageVisited)
              .format('MM/DD/YYYY')}
          </p>
        ) : null,
    },
  ];

  if (canSeeEnagementScores.includes(props.user.detail.email)) {
    columnsData.push({
      title: 'Engagement Score',
      field: 'engagementScore',
      // eslint-disable-next-line
      render: rowData => {
        let textColor = '#A12601';
        const enagegementScore = rowData.engagementScore;
        if (enagegementScore >= 66) {
          textColor = '#83BE7D';
        } else if (enagegementScore < 66 && enagegementScore >= 33) {
          textColor = '#EFB460';
        }
        return <p style={{ color: textColor }}>{rowData.engagementScore}</p>;
      },
    });
  }

  const onCheckBoxChanged = event => {
    setLoadContactsData(event.target.checked);
    if (event.target.checked) {
      localStorage.setItem('archived-engagement', event.target.checked);
    } else {
      localStorage.removeItem('archived-engagement');
    }
    fetchContacts(orgId, event.target.checked).then();
  };

  return (
    <Grommet className="Managecomp ManagePortalUserscomp">
      <div className="ManagePortalUserscomp">
        <Tabs className="topTabs" activeIndex={indy} onActive={onActive} flex>
          <Tab title="By Contact" className="engagementTab">
            <div className="engTable">
              <Box className="noterouter_managetable btnseparation">
                <MaterialTable
                  components={{
                    // eslint-disable-next-line
                    Cell: props => <MTableCell {...props} />,
                  }}
                  columns={columnsData}
                  data={memberList}
                  className="Manage_materialtable"
                  title=""
                  options={{
                    pageSize: pageSize,
                    pageSizeOptions: [10, 20, 50],
                    rowStyle: rowData => ({
                      backgroundColor:
                        rowData.bounceAlert ||
                        rowData.spamAlert ||
                        rowData.smsAlert
                          ? '#ff4d4f69'
                          : 'transparent',
                    }),
                  }}
                  onRowClick={(e, data) => showpersonaltestDrawer(data)}
                  onRowsPerPageChange={e => {
                    localStorage.setItem('pageSize', e);
                    setPageSize(e);
                  }}
                />
                {selectedUser && (
                  <MemberMessagesDrawer
                    setVisible={setVisible}
                    visible={visible}
                    messages={messages}
                    msgsLoading={msgsLoading}
                    setDetailsDrawerVisible={setDetailsDrawer}
                    detailsDrawerVisible={detailsDrawer}
                    showdetailsDrawer={showdetailsDrawer}
                    memberMessageInfo={memberMessageInfo}
                    memberInfo={memberInfo}
                    msgDetailsLoading={msgDetailsLoading}
                    memberEngagement={memberEngagement}
                  />
                )}
              </Box>
            </div>
          </Tab>
          <Tab
            title="By Message"
            className="engagementTab"
            style={{ position: 'relative', top: '39px' }}
          >
            <MsgTable props={props} orgId={orgId} />
          </Tab>
          {checkBoxVisible && (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ marginLeft: '10px' }}
                    color="primary"
                    checked={loadArchivedContacts}
                    onChange={onCheckBoxChanged}
                    label="archived"
                  />
                }
                label="archived"
              />
            </div>
          )}
        </Tabs>
      </div>
    </Grommet>
  );
};

const mapStateToProps = state => ({
  ...state,
});
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actioncreators, dispatch),
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EngagementLogs)
);
