import './login.css';
import 'antd/dist/antd.css';

import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { Box, Grommet } from 'grommet';
import { grommet } from 'grommet/themes';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import { getLogoUrl, Variant, Color } from '../../infrastructure/ui/logo';
import { gAnalytics, getQueryStringValue } from '../../redux/helpers/index';
import * as services from '../../redux/services';
import {
  errorMessage,
  fullloader,
  toast,
  privilegesList,
} from '../../redux/actions/UI';
import { authLogin } from '../../redux/actions/auth';
import { userdetail, userPrivileges } from '../../redux/actions/user';
import {
  organizationList,
  userOrganization,
} from '../../redux/actions/organizations';
import {
  fullloaderSelector,
  loaderMsgSelector,
} from '../../redux/selectors/ui';
import Cookies from 'universal-cookie';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      register: false,
      authId: '',
      redirectUrl: getQueryStringValue('redirectUrl'),
    };
    this.cookie = new Cookies();
    this.formRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // if we have these cookies set, we're potentially already logged in
    const fbAccessToken = this.cookie.get('fbAccess');
    const authEmail = this.cookie.get('authEmail');

    if (fbAccessToken && authEmail) {
      // if we're already logged in and cookies are still valid, then redirect to the dashboard
      services.checkUserLoggedIn(userData => {
        if (userData && userData.enable === 1) {
          this.props.authLogin();
          this.props.history.push('/dashboard');
        }
      });
    }

    gAnalytics('pageview', '/login');

    // If Registering
    let id = getQueryStringValue('id');
    let redirectUrl = getQueryStringValue('redirectUrl');

    if (redirectUrl) {
      this.setState({ redirectUrl: redirectUrl });
    }

    this.setState({ authId: id });
    if (id) {
      this.setState({ register: true });
      services.getUserByAuthId(id).then(userRes => {
        if (userRes?.email) {
          this.setState({ register: true });
          this.formRef.current.setFieldsValue({
            email: userRes.email,
          });
        } else {
          console.log('Error');
        }
      });
    }
  }

  async handleSubmit(values) {
    this.props.errorMessage(null);
    this.props.fullloader(true, 'Authenticating...');

    let loginData = {
      email: values.email,
      password: values.password,
      confirmPassword: this.state.register ? values.confirmPassword : null,
      authId: this.state.register ? this.state.authId : null,
    };

    let userData;
    if (this.state.register) {
      if (loginData.password !== loginData.confirmPassword) {
        this.props.toast('error', 'Passwords do not match.');

        this.props.fullloader(false);
        return;
      } else if (loginData.confirmPassword.length <= 6) {
        this.props.toast('error', 'Password must be more than 6 characters.');
        this.props.fullloader(false);
        return;
      } else if (!loginData.confirmPassword || !loginData.password) {
        this.props.toast('error', 'Please provide a password.');

        this.props.fullloader(false);
        return;
      }
      userData = await services.createNewRegFromEmail(loginData);
      if (userData?.code === 'auth/email-already-in-use') {
        return this.props.toast(
          'error',
          'This account already exists, try the forgot password link below.'
        );
      }
    } else {
      userData = await services.login(loginData);
    }

    if (userData && userData.enable === 1) {
      this.props.authLogin();
      this.props.userdetail(userData);

      const privileges = await services.getPrivilegesList();
      this.props.privilegesList(privileges);

      const privilegesToCheck = [
        { isSU: privileges.MANAGE_EXECUTIVE_ADMINS },
        { isSA: privileges.MANAGE_COMPANY_ITEMS },
        { isSDA: privileges.MANAGE_SUPER_ADMINS },
      ];

      const userRoleCheckResponse = await services.validatePrivilegeBatch(
        userData.id,
        userData.organizationId,
        privilegesToCheck
      );
      const userRoleCheck = userRoleCheckResponse?.data?.userPrivileges;

      this.props.userPrivileges(userRoleCheck);

      let userOrg = await services.getOrganization(userData.organizationId);
      let userOrgList;

      if (!userRoleCheck?.isSU) {
        userOrgList = await services.getMultiUserOrgList(
          userData.organizations
        );
      } else {
        userOrgList = await services.getOrganizationList();
      }

      this.props.userOrganization(userOrg);
      this.props.organizationList(userOrgList);

      this.props.toast('success', 'Login successful!');
      this.props.history.push(
        this.state.redirectUrl
          ? decodeURIComponent(this.state.redirectUrl)
          : '/dashboard'
      );
    } else {
      this.props.toast('error', 'Could not login with those credentials', 4);
    }
    this.props.fullloader(false);
  }

  render() {
    const { register } = this.state;
    return (
      <div>
        <div
          className="fullLoader"
          style={{
            display: this.props.fullloaderData ? 'flex' : 'none',
          }}
        >
          <div className="contentDiv">
            <LoadingOutlined />
            <p>{this.props.loaderMsg}</p>
          </div>
        </div>
        <Grommet theme={grommet} className="loginsec">
          <div>
            <img
              className="nrLogo"
              alt="nrLogo"
              src={getLogoUrl({ variant: Variant.PRIMARY, color: Color.LIGHT })}
            />
          </div>
          <Box className="loginfirst">
            {register && (
              <div className="text-center">
                <span className="welcomemsg text-center">
                  {' '}
                  Welcome to NoteRouter!{' '}
                </span>
                <p className="welcomemsginfo">
                  Please create and confirm a password for your new account
                </p>
              </div>
            )}

            <p className="loginheading"> {register ? 'Sign Up' : 'Login'}</p>

            <Form
              onFinish={this.handleSubmit}
              ref={this.formRef}
              className="login-form loginFormNew"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Please input your username!' },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: 'Please input your Password!' },
                ]}
              >
                <Input type="password" />
              </Form.Item>
              {register && (
                <Form.Item label="Confirm Password" name="confirmPassword">
                  <Input type="password" />
                </Form.Item>
              )}
              {this.state.passwordInvalid && (
                <p>Password must contains at least 6 characters.</p>
              )}
              <div className="textcenter">
                <Button
                  type="primary"
                  loading={this.props.fullloaderData}
                  htmlType="submit"
                  className="login-form-button"
                >
                  LOGIN
                </Button>
              </div>
              <Link to="/forgotpass">
                <p className="textcenter frgetpwd">Forgot Password?</p>
              </Link>
              {this.state.register && (
                <p
                  className="textcenter frgetpwd"
                  onClick={() => {
                    this.props.history.push('/login');
                    this.setState({ register: false });
                  }}
                >
                  Back to Login
                </p>
              )}
            </Form>
          </Box>
          <p className="logo">
            Copyright © {moment().year()}. All Rights Reserved | Designed and
            Developed by NoteRouter
          </p>
        </Grommet>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fullloaderData: fullloaderSelector(state),
  loaderMsg: loaderMsgSelector(state),
});

const mapDispatchToProps = {
  authLogin,
  errorMessage,
  fullloader,
  organizationList,
  privilegesList,
  toast,
  userdetail,
  userOrganization,
  userPrivileges,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
