import React, { Fragment } from 'react';
import { IntersectionTarget } from '../react-intersection';

const InfiniteScroll = ({
  data,
  getKey,
  renderItem,
  renderList,
  onLoadNext,
}) => {
  return (
    <>
      {renderList(
        data.map(item => {
          return <Fragment key={getKey(item)}>{renderItem(item)}</Fragment>;
        })
      )}
      <IntersectionTarget onIntersect={onLoadNext} />
    </>
  );
};

InfiniteScroll.defaultProps = {
  renderList: () => <></>,
};

export default InfiniteScroll;
