const prefetchImage = (url, { timeout = 5_000 }) => {
  const image = document.createElement('img');
  image.src = url;

  return new Promise((resolve, reject) => {
    const loadListener = () => {
      resolve();
    };

    const errorListener = () => {
      reject();
    };

    image.addEventListener('load', loadListener);
    image.addEventListener('error', errorListener);

    setTimeout(() => {
      image.removeEventListener('load', loadListener);
      image.removeEventListener('error', errorListener);
      reject();
    }, timeout);
  });
};

export default prefetchImage;
