import 'antd/dist/antd.css';
import '../../globalcss.css';

import { LoadingOutlined } from '@ant-design/icons';
import { Button, Drawer, IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DataGrid } from '@mui/x-data-grid';
import { Col, Form, Input, Row, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import _union from 'underscore-es/union';
import { v4 as uuidv4 } from 'uuid';

import { toast } from '../../redux/actions/UI';
import * as services from '../../redux/services';
import {
  formatFieldExportData,
  getContactAttributes,
  updateContactCustomFields,
  updateContactById,
  getOrgOffices,
} from '../../redux/services';
import CSVUploadDrawer from './CSVUploadDrawer';
import DeleteFieldModal from './DeleteFieldModal';
import { selectedOrganizationSelector } from '../../redux/selectors/organization';
import BulkGroupAssignmentButton from '../BulkGroupAssignmentButton';

const { TextArea } = Input;
const { Option } = Select;

const CustomFieldDrawer = props => {
  const exportLink = useRef();
  const [FieldName, setFieldName] = useState('');
  const [FieldDescription, setFieldDescription] = useState('');
  const [FieldCategory, setFieldCategory] = useState('Custom');
  const [FieldDataType, setFieldDataType] = useState('General');
  const [SelectedContacts, setSelectedContacts] = useState([]);
  const [FieldExportData, setFieldExportData] = useState([]);
  const [OpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [ContactCustomData, setContactCustomData] = useState([]);
  const [isLoading, setIsLoading] = useState({ value: false, text: '' });
  const [CSVUploadVisibility, setCSVUploadVisibility] = useState(false);
  const [CSVUploadStep, setCSVUploadStep] = useState('File Upload');
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const [ContactFieldUpdates, setContactFieldUpdates] = useState({});
  const [pageSize, setPageSize] = useState(20);
  const dispatch = useDispatch();

  const basicContactFieldCategories = ['Basic', 'Custom'];
  const uniqueContactFields = ['memberId', 'memberNRDSID'];
  const contactOfficeFields = [
    'officeCity',
    'officeState',
    'officeCounty',
    'officeZipCode',
  ];

  const handleGroupAssignmentModalClose = async ({ hasCreatedNewGroup }) => {
    if (hasCreatedNewGroup) {
      await props.refreshManage();
    }
  };

  useEffect(() => {
    if (props.FieldData?.name) {
      setFieldName(props.FieldData.name || '');
      setFieldDescription(props.FieldData.description || '');
      setFieldCategory(props.FieldData.category || '');
      setFieldDataType(props.FieldData.dataType || '');
    }
  }, [props.FieldData]);

  useEffect(() => {
    let customData = [];
    if (props.ExistingField) {
      if (
        //Really silly way to make this work properly. Will refactor with F&D overhual
        basicContactFieldCategories.includes(props.FieldData.category) ||
        uniqueContactFields.includes(props.FieldData.fieldName)
      ) {
        customData = handleGeneralFields();
      } else if (contactOfficeFields.includes(props.FieldData.fieldName)) {
        setIsLoading({ value: true, text: 'Loading field data.' });
        handleOfficeFields().then(officeData => {
          setContactCustomData(officeData);
          setIsLoading({ value: false, text: '' });
        });
      } else if (props.FieldData.category === 'Integrated') {
        setIsLoading({ value: true, text: 'Loading field data.' });
        getContactAttributes(selectedOrganization.id).then(attributeContent => {
          customData = handleAttributeField(attributeContent);
          setContactCustomData(customData);
          setIsLoading({ value: false, text: '' });
        });
      }
    }
    setContactCustomData(customData);
  }, [props.contactCustomData, props.contactList]);

  const handleGeneralFields = () => {
    return props.contactList.map(contactInfo => {
      const contactCustomInfo =
        props.contactCustomData[contactInfo.noteRouterId] || [];
      let fieldValue = '';
      if (props.FieldData.category === 'Basic') {
        fieldValue = contactInfo[props.FieldData.fieldName];
        if (
          props.FieldData.fieldName === 'lastPreferencePageVisited' &&
          fieldValue
        )
          fieldValue = moment.unix(fieldValue).format('M/D/YYYY');
      } else if (props.FieldData.category === 'Integrated') {
        if (
          contactInfo.organization?.attributes &&
          props.FieldData.fieldName !== 'memberId'
        ) {
          const attributeVal = contactInfo.organization?.attributes.find(
            attr => attr.name === props.FieldData.fieldName
          );
          fieldValue = attributeVal ? attributeVal.value : '';
        } else if (props.FieldData.fieldName === 'memberId') {
          fieldValue = contactInfo.source?.memberId
            ? contactInfo.source.memberId
            : '';
        }
      } else {
        const fieldDetail = contactCustomInfo.find(
          field => field.id === props.FieldData.id
        );
        fieldValue = fieldDetail ? fieldDetail.value : '';
      }
      return {
        ...contactInfo,
        customData: contactCustomInfo,
        value: fieldValue?.value ? fieldValue.value : fieldValue || '',
        canEdit: checkIfEditableCell(contactInfo),
      };
    });
  };

  const handleOfficeFields = async () => {
    const orgOffices = await getOrgOffices(selectedOrganization.id);
    return props.contactList.map(contactInfo => {
      const contactCustomInfo =
        props.contactCustomData[contactInfo.noteRouterId] || [];

      const officeData = orgOffices[contactInfo.officeId] || {};

      let fieldValue = '';
      if (officeData[props.FieldData.fieldName]) {
        fieldValue = officeData[props.FieldData.fieldName];
      }
      return {
        ...contactInfo,
        customData: contactCustomInfo,
        value: fieldValue || '',
        canEdit: checkIfEditableCell(contactInfo),
      };
    });
  };
  const handleAttributeField = attributeContent => {
    return props.contactList.map(contactInfo => {
      const contactCustomInfo =
        props.contactCustomData[contactInfo.noteRouterId] || [];
      const contactAttributes =
        attributeContent.find(
          contact => contact.noteRouterId === contactInfo.noteRouterId
        ) || {};
      let fieldValue;
      const attributeVal = contactAttributes.organization?.attributes.find(
        attr => attr.name === props.FieldData.fieldName
      );
      fieldValue = attributeVal ? attributeVal.value : '';
      return {
        ...contactInfo,
        customData: contactCustomInfo,
        value:
          fieldValue && fieldValue.value ? fieldValue.value : fieldValue || '',
        canEdit: checkIfEditableCell(contactInfo),
      };
    });
  };

  const checkIfEditableCell = contact => {
    return (
      props.FieldData.category === 'Custom' ||
      (props.FieldData.category === 'Basic' &&
        (contact.source?.name.toLowerCase() === 'nr' ||
          contact.source?.name.toLowerCase() === 'csv' ||
          !contact.source))
    );
  };

  const handleSubmit = async () => {
    const customFieldData = {
      category: FieldCategory,
      dataType: FieldDataType,
      description: FieldDescription,
      id:
        props.ExistingField && props.FieldData.id
          ? props.FieldData.id
          : uuidv4(),
      lastUpdatedTimestamp: moment().unix(),
      name: FieldName,
      organizationId: selectedOrganization.id,
    };

    if (props.FieldData?.category === 'Custom' || !props.ExistingField) {
      if (props.ExistingField) {
        const updatedField = await services.updateCustomFields(customFieldData);
        if (!updatedField || !updatedField.id) {
          dispatch(toast('error', `Error updating ${customFieldData.name}.`));
        } else {
          dispatch(
            toast('success', `Successfully updated ${customFieldData.name}.`)
          );
        }
      } else {
        customFieldData.createdTimestamp = moment().unix();
        const newField = await services.addCustomFields(customFieldData);
        if (!newField || !newField.id) {
          dispatch(
            toast('error', `Error adding new field: ${customFieldData.name}.`)
          );
        } else {
          dispatch(
            toast('success', `Successfully added ${customFieldData.name}.`)
          );
        }
      }
    }
    if (props.ExistingField && Object.keys(ContactFieldUpdates).length) {
      //Update the contacts with new values
      await handleContactFieldUpdates();
      if (props.FieldData?.category === 'Basic') {
        props.refreshManage(); //Refresh members/manage screen to get the updated info only if updating a basic field
      }
    }
    props.closeAndRefresh();
  };

  const handleContactFieldUpdates = async () => {
    setIsLoading({ value: true, text: 'Updating contacts' });
    //Split into 3 updates. Custom integrated and basic.
    //Custom just update the array but basic and integrated have to be manually found and updated
    const customFieldDetails = {};
    const basicFieldDetails = {};
    const fieldId = props.FieldData.id;

    for (let contactId in ContactFieldUpdates) {
      if (props.FieldData.category === 'Custom') {
        const contactCustomData = props.contactCustomData[contactId] || [];
        for (let field of contactCustomData) {
          customFieldDetails[field.id] = field;
        }
        if (customFieldDetails[fieldId]) {
          //If there is already an existing value, just replace it.
          customFieldDetails[fieldId].value = ContactFieldUpdates[contactId];
        } else {
          //Otherwise just add it
          customFieldDetails[fieldId] = {
            value: ContactFieldUpdates[contactId],
            id: fieldId,
            lastUpdated: moment().unix(),
          };
        }
        await updateContactCustomFields({
          noteRouterId: contactId,
          orgId: selectedOrganization.id,
          existingData: !!props.contactCustomData[contactId],
          updatedFields: Object.values(customFieldDetails),
        });
      } else if (props.FieldData.category === 'Basic') {
        basicFieldDetails[props.FieldData.fieldName] =
          ContactFieldUpdates[contactId];
        await updateContactById({
          ...basicFieldDetails,
          noteRouterId: contactId,
          organizationId: selectedOrganization.id,
          updatedDate: moment().unix(),
        });
      } else {
        if (props.FieldData.fieldName === 'memberId') {
          basicFieldDetails['source.memberId'] = ContactFieldUpdates[contactId];
          await updateContactById({
            ...basicFieldDetails,
            noteRouterId: contactId,
            organizationId: selectedOrganization.id,
            updatedDate: moment().unix(),
          });
        }
      }
    }

    setIsLoading({ value: false });
    props.closeDrawer();
  };

  const handleFieldExport = async () => {
    await formatFieldExportData({}); //Literally does nothing and is stupid and useless but keep it
    let exportInfo = [];
    let exportAll =
      SelectedContacts.length === ContactCustomData.length ||
      !SelectedContacts.length;
    ContactCustomData.forEach(contactData => {
      let selected = exportAll || SelectedContacts.includes(contactData.id);
      if (contactData && selected) {
        let NRDS = '';
        if (contactData.organization && contactData.organization.attributes) {
          NRDS = contactData.organization.attributes.find(
            attr => attr.name === 'memberNRDSID'
          );
        }
        exportInfo.push({
          'First Name': contactData.firstName || '',
          'Last Name': contactData.lastName || '',
          Email: contactData.email || '',
          'Mobile Number': contactData.phoneNumber || '',
          'Member #':
            contactData.source && contactData.source.memberId
              ? contactData.source.memberId
              : '',
          'NRDS #': NRDS ? NRDS.value : '',
          'Topic IDs':
            _union(contactData.emailTags, contactData.textTags) || '',
          'Group IDs': contactData.identifiers || '',
          [props.FieldData.name]: contactData.value || '',
        });
      }
    });
    setFieldExportData(exportInfo);
    exportLink.current.link.click();
    setIsLoading({ value: false, text: '' });
  };

  const handleFieldUpdate = ({ value, id }) => {
    setContactFieldUpdates({
      ...ContactFieldUpdates,
      [id]: value,
    });
  };

  const tableColumns = [
    {
      headerName: 'First Name',
      field: 'firstName',
      minWidth: 90,
      flex: 1,
    },
    { headerName: 'Last Name', field: 'lastName', minWidth: 90, flex: 1 },
    {
      headerName: `${props?.FieldData?.name || 'Value'}`,
      field: 'value',
      minWidth: 125,
      flex: 1.3,
      editable: props.FieldData?.category !== 'Integrated',
      renderCell: params => {
        return params.row.canEdit ? (
          <Tooltip title={'Double click to edit'}>
            <div
              style={{ border: '1px solid lightgray' }}
              className={'tableCellBorder'}
            >
              {params.row.value}
            </div>
          </Tooltip>
        ) : (
          <div style={{ border: '' }} className={'tableCellBorder'}>
            {params.row.value}
          </div>
        );
      },
    },
  ];
  //Custom or basic and non-ams
  return (
    <div style={{ width: '100%', height: '100%', margin: '0' }}>
      <div
        className={'FieldDrawerHeader'}
        style={{
          justifyContent:
            props.FieldData?.category === 'Integrated' ? 'flex-start' : '',
        }}
      >
        <IconButton
          onClick={() => {
            props.closeDrawer();
          }}
          style={{ color: 'white' }}
        >
          <CloseIcon />
        </IconButton>
        {props.drawerTitle}
        {(props.FieldData?.category !== 'Integrated' ||
          !props.ExistingField) && (
          <Button
            onClick={() => handleSubmit()}
            variant="outlined"
            label="Save"
            type="submit"
            disabled={false}
            className={'FieldSaveButtons'}
          >
            Save
          </Button>
        )}
      </div>
      <Form
        layout="vertical"
        style={{ height: 'calc(100% - 60px)', padding: '12px 24px 24px 24px' }}
        initialValues={{
          updatedDate: props.ExistingField
            ? moment
                .unix(props.FieldData.lastUpdatedTimestamp)
                .format('MMMM D, YYYY')
            : '',
          createdTimestamp: props.ExistingField
            ? moment
                .unix(props.FieldData.createdTimestamp)
                .format('MMMM D, YYYY')
            : '',
          fieldCategory: props.ExistingField
            ? props.FieldData.category
            : 'Custom',
          fieldDataType: props.ExistingField
            ? props.FieldData.dataType
            : 'General',
          fieldName: props && props.FieldData ? props.FieldData.name || '' : '',
          fieldDescription:
            props && props.FieldData ? props.FieldData.description || '' : '',
        }}
      >
        <Row gutter={24} style={{ height: '70px' }}>
          <Col span={18}>
            <Form.Item
              label="Name"
              name="fieldName"
              rules={[{ required: true, message: 'Please enter name' }]}
            >
              <Input
                onChange={event => {
                  setFieldName(event.target.value);
                }}
                disabled={
                  props.ExistingField && props.FieldData.category !== 'Custom'
                }
                type={'text'}
                style={{ padding: '5px 9px' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Tooltip
              placement={'left-start'}
              title={
                // <p>{`Yes/No: Also known as boolean values, use this option to boost performance if this field's only values will only be yes/no, true/false, etc.`}</p>
                <span>
                  <p>
                    {
                      'General: Also known as string values, this data type works for just about everything.'
                    }
                  </p>
                  <p>
                    {
                      'Number: When a field will only be populated with numbers (no letters or other characters), use this option to increase performance.'
                    }
                  </p>
                </span>
              }
            >
              <Form.Item
                label="Data Type"
                name="fieldDataType"
                rules={[{ required: true, message: 'Please enter data type' }]}
                className={'dataTypeSelect'}
              >
                <Select
                  value={FieldDataType}
                  onChange={value => {
                    setFieldDataType(value);
                  }}
                  disabled={
                    props.ExistingField && props.FieldData.category !== 'Custom'
                  }
                  label="Data Type"
                  style={{ width: '100%' }}
                  //<Option value={"Boolean"}>Yes/No</Option>
                >
                  <Option value={'General'}>General</Option>
                  <Option value={'Number'}>Number</Option>
                </Select>
              </Form.Item>
            </Tooltip>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Notes" name="fieldDescription">
              <TextArea
                onChange={event => {
                  setFieldDescription(event.target.value);
                }}
                disabled={
                  props.ExistingField && props.FieldData.category !== 'Custom'
                }
                rows="3"
                cols="25"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} style={{ height: '70px' }}>
          <Col span={24}>
            <Form.Item
              label="Category"
              name="fieldCategory"
              rules={[{ required: true, message: 'Please select a category' }]}
            >
              <Select
                value={FieldCategory}
                label="Category"
                onChange={value => {
                  setFieldCategory(value);
                }}
                style={{ width: '100%' }}
                disabled={
                  (props.ExistingField &&
                    props.FieldData.category === 'System') ||
                  true
                }
              >
                <Option value={'Custom'}>Custom</Option>
                <Option value={'Basic'}>Basic</Option>
                <Option value={'Integrated'}>Integrated</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Created" name="createdTimestamp">
              <Input
                disabled={
                  (props.ExistingField &&
                    props.FieldData.category === 'System') ||
                  true
                }
                type="text"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last Updated" name="updatedDate">
              <Input
                type="text"
                disabled={
                  (props.ExistingField &&
                    props.FieldData.category === 'System') ||
                  true
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            {props.ExistingField && (
              <>
                <Button
                  size={'small'}
                  style={{
                    backgroundColor: 'rgba(0, 122, 222, 0.05)',
                    color: '#007ADE',
                    borderColor: '#007ADE',
                  }}
                  variant="outlined"
                  disabled={props.FieldData.category === 'Integrated'}
                  className={`${
                    props.FieldData.category === 'Integrated'
                      ? 'disabledButtonStyle'
                      : ''
                  }`}
                  onClick={() => {
                    //props.closeDrawer();
                    setCSVUploadVisibility(true);
                  }}
                >
                  Update via CSV
                </Button>
                <Button
                  onClick={() => {
                    setIsLoading({
                      value: true,
                      text: 'Exporting your CSV, this may take a moment.',
                    });
                    handleFieldExport();
                  }}
                  size={'small'}
                  style={{
                    backgroundColor: 'rgba(212, 215, 217, 0.05)',
                    color: '#6C7075',
                    borderColor: '#6C7075',
                    marginLeft: '25px',
                  }}
                  variant="outlined"
                >
                  Export
                </Button>
                <CSVLink
                  data={FieldExportData}
                  filename={`${moment().format('MM.DD.YYYY hh.mm A')} - ${
                    props.FieldData.name
                  } Data Export.csv`}
                  className="hidden"
                  ref={exportLink}
                  target="_blank"
                />
                <BulkGroupAssignmentButton
                  className="fieldDrawerBulkGroupAssignmentButton"
                  selectedMemberIds={SelectedContacts}
                  onAssignGroups={props.refreshManage}
                  onClose={handleGroupAssignmentModalClose}
                />
                <Button
                  onClick={() => {
                    setOpenDeleteModal(true);
                  }}
                  size={'small'}
                  style={{
                    backgroundColor: 'rgba(255, 97, 97, 0.05)',
                    color: '#FF6161',
                    borderColor: '#FF6161',
                    marginLeft: '25px',
                  }}
                  variant="outlined"
                  disabled={props.FieldData.category !== 'Custom'}
                  className={`${
                    props.FieldData.category !== 'Custom'
                      ? 'disabledButtonStyle'
                      : ''
                  }`}
                >
                  Delete Field
                </Button>
              </>
            )}
          </Col>
        </Row>
        {props.ExistingField && (
          <Row>
            <div className={'createFieldDatagridContainer'}>
              <DataGrid
                columns={tableColumns}
                rows={ContactCustomData}
                sortingOrder={['asc', 'desc']}
                pageSize={pageSize}
                onPageSizeChange={size => setPageSize(size)}
                rowsPerPageOptions={[10, 20, 50]}
                checkboxSelection
                disableSelectionOnClick
                onSelectionModelChange={newSelectionModel => {
                  setSelectedContacts(newSelectionModel);
                }}
                selectionModel={SelectedContacts}
                onCellEditCommit={editedCellDetail => {
                  handleFieldUpdate(editedCellDetail);
                }}
                isCellEditable={params =>
                  params.row.canEdit && props.FieldData?.name !== 'Notes'
                }
                componentsProps={{ panel: { disablePortal: true } }} //There is a weird issue where the filter text field will not work if this is not present for datagrids in drawers.
              />
            </div>
          </Row>
        )}
        {OpenDeleteModal && (
          <DeleteFieldModal
            closeModal={() => setOpenDeleteModal(false)}
            deleteList={[props.FieldData.name]}
            deleteFields={() => {
              props.deleteField(props.FieldData.id);
            }}
          />
        )}
      </Form>
      <Drawer
        anchor={'right'}
        onClose={() => {
          setCSVUploadVisibility(false);
        }}
        open={CSVUploadVisibility}
        className={'fieldDrawer'}
      >
        <CSVUploadDrawer
          closeDrawer={() => {
            setCSVUploadVisibility(false);
          }}
          drawerTitle={
            <span style={{ paddingLeft: '10px' }}>
              Fields & Data: &nbsp;&nbsp;Add from CSV - {CSVUploadStep}
            </span>
          }
          closeAndRefresh={() => {
            setCSVUploadVisibility(false);
            props.closeAndRefresh();
          }}
          updateUploadStep={step => {
            setCSVUploadStep(step);
          }}
          singleField={props.FieldData}
          NRFields={props.NRFields}
          contactGenericData={props.contactList}
          contactCustomData={props.contactCustomData}
          refreshManage={() => props.refreshManage()}
        />
      </Drawer>

      <div
        className="ContactFieldsLoading"
        style={{ display: isLoading.value ? 'flex' : 'none' }}
      >
        <div className="contentDiv">
          <LoadingOutlined />
          <p>{isLoading.text}</p>
        </div>
      </div>
    </div>
  );
};

export default CustomFieldDrawer;
