import React from 'react';
import { ArrowBackSharp } from '@material-ui/icons';
import { Button } from './BackButton.styled';
import { Link } from 'react-router-dom';

const BackButton = ({ className, to, onClick }) => {
  return (
    <Button
      className={className}
      to={to}
      component={to ? Link : undefined}
      onClick={onClick}
    >
      <ArrowBackSharp />
    </Button>
  );
};

export default BackButton;
